import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "react-modal";
import "react-datepicker/dist/react-datepicker.css";

import { dispatchToProps as moDP } from "../../../store/modal-actions";
import { dispatchToProps as ledgDP } from "../../../store/ledger-actions";
import { dispatchToProps as statDP } from "../../../store/statements-actions";
import { dispatchToProps as manDP } from "../../../store/manage-actions";
import { dispatchToProps as networkDP } from "../../../store/network-actions";
import { dispatchToProps as caDP } from "../../../store/company-actions";

import { isEmpty } from "../../../lib/utils";

import Resources from "../../../lib/resources";
import IconClose from "../../library/icons/iconClose";
import MainLoader from "../../mainLoader";

import TextInput from "../../library/textInput";
import HtmlEditor from "../../htmlEditor";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...ledgDP(dispatch),
  ...statDP(dispatch),
  ...caDP(dispatch),
  ...manDP(dispatch),
  ...networkDP(dispatch)
});

class ShareFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: "",
      to: "",
      htmlBody: "",
      showFlyout: true
    };
  }

  handleSelect = (input) => {
    input.defaultChecked = true;
  }

  hideFlyout = () => {
    this.setState({ showFlyout: false });
  }

  sendMessage = () => {
    const companyInfo = this.props.getCompanyInfo(this.props.companyId);
    const cAddress = (((companyInfo || {}).connectorEmail || {}).synthetic || {})[this.props.perspectiveId];
    const companyEmail = isEmpty(cAddress) ? undefined : { name: companyInfo.companyName, address: cAddress };

    // For some reason an extra paragraph tag with a line break tag inside it is being added to the HTML editor when it's populated with content
    // from the sharing API response. Since it causes unnecessary blank lines in messages, we simply remove that combination of markup. If we ever
    // upgrade the version of react-quill we are using, this logic may need to change.
    let request = {
      from: [{ address: companyEmail.address, name: this.props.myProfile.contactName }],
      to: [{ address: this.state.to, name: "" }],
      cc: [],
      bcc: [],
      subject: this.state.subject,
      textBody: "",
      htmlBody: this.state.htmlBody.replace(/<p><br><\/p>/g, "")
    };

    this.props.displayNotification("sendingMessageNotification");

    this.props.sendShareFlyout(this.props.perspectiveId, this.props.companyId, request).finally(() => {
      this.hideFlyout();
    });
  }

  componentDidMount = () => {
    let mode = this.props.mode === "1" ? "ap" : "ar";

    this.props.getShareFlyoutMessage(mode, this.props.entityGlobalKey).then(response => {
      this.setState({ subject: response.subject });
      this.setState({ htmlBody: response.htmlBody });
    });

    this.props.getCompanyInfo(this.props.companyId);
  }

  render() {
    const disableSend = isEmpty(this.state.to);

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={!this.state.showFlyout ? this.props.hideFlyout : null}
        closeTimeoutMS={400}
        contentLabel="Example Modal"
        className="flyout share-profile-flyout"
        overlayClassName="flyout-overlay"
      >
        {this.props.networkStore.gettingShareFlyoutMessage ? (
          <MainLoader fullScreen={true} />
        ) : (
          <React.Fragment>
            <div className="flyout-heading">
              <p>{Resources.SendAMessage}</p>
              <div onClick={this.props.hideFlyout} className="flyout-heading-close">
                <IconClose />
              </div>
            </div>
            <div className="flyout-content">
              <TextInput
                labelInline={false}
                label={Resources.To}
                textValue={this.state.to}
                inputOnChange={e => {
                  let value = e.target.value;
                  this.setState({ to: value });
                }}
              />
              <TextInput
                labelInline={false}
                label={Resources.Subject}
                textValue={this.state.subject}
                inputOnChange={e => {
                  let value = e.target.value;
                  this.setState({ subject: value });
                }}
              />
              <HtmlEditor
                onFocus={() => this.setState({ focusedField: "message" })}
                hideToolbar={true}
                htmlContent={this.state.htmlBody}
                defaultHtmlContent={this.state.htmlBody}
                updateHtmlContent={htmlBody => this.setState({ htmlBody })}
                updateTextContent={textBody => this.setState({ textBody })}
              />
              <button
                onClick={() => this.sendMessage()}
                disabled={disableSend}
                className="portal-button-green"
              >
                {Resources.Send}
              </button>
            </div>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    manageStore: store.manage,
    contactList: store.network.contactList,
    accountList: store.network.accountList,
    pageRowCount: store.general.pageRowCount,
    networkStore: store.network,
    searchResults: store.network.searchResults,
    isShowingSearchResult: store.gotNetworkAccountSearchResults,
    gettingNetworkAccountList: store.network.gettingNetworkAccountList,
    gettingNetworkContactList: store.network.gettingNetworkContactList
  };
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(ShareFlyout)
);
