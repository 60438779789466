import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import Resources from "../../../lib/resources";
import { dispatchToProps as netDP } from "../../../store/network-actions";
import { dispatchToProps as modDP } from "../../../store/modal-actions";
import { dispatchToProps as persDP } from "../../../store/perspectives-actions";

const dispatchToProps = dispatch => ({
  ...netDP(dispatch),
  ...modDP(dispatch),
  ...persDP(dispatch)
});

class DeleteDocumentConfirmDialog extends Component {
  render() {
    const { modalStore } = this.props;
    return (
      <Modal
        isOpen={modalStore.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="primary-contact-archive-modal"
        overlayClassName="custom-modal-overlay"
      >
        <p className="header">{Resources.AreYouSure}</p>
        <div className="">
          <p>{Resources.OnceYouDeleteDocumentYouCannotRecover}</p>
        </div>
        <div className="button-container">
          <button onClick={() => this.props.hideModal()} className="button-secondary-square">
            {Resources.Cancel}
          </button>
          <button
            onClick={() => {
              let body = {
                fileKeys: this.props.selectedDocuments,
                newType: 0
              };
              this.props.attachments
                ? this.props.deleteAttachments(this.props.perspectiveId, this.props.companyId, body).then(response => {
                    this.props.fetchEmailAttachments(
                      this.props.perspectiveId,
                      this.props.companyId,
                      this.props.accountId
                    );
                  })
                : this.props.deleteDocuments(this.props.perspectiveId, this.props.accountId, body).then(response => {
                    this.props.fetchAccountDocuments(this.props.perspectiveId, this.props.accountId);
                  });
              this.props.hideModal();
              this.props.displayNotification("deletingDocumentNotification");
            }}
            className="button-primary-square"
          >
            {Resources.YesIAmSure}
          </button>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    networkStore: store.network,
    perspectiveStore: store.perspectives
  };
};

export default connect(storeToProps, dispatchToProps)(DeleteDocumentConfirmDialog);
