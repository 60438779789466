import React, { Component } from "react";
import { connect } from "react-redux";
import LoginForm from "./loginForm";
import Resources from "../lib/resources";
import { dispatchToProps } from "../store/user-actions";

import LockstepLogo from "./library/lockstepLogo";
import { getQueryParameters } from "../lib/utils";
import MainLoader from "./mainLoader";

class Login extends Component {
  componentDidMount() {
    window.Intercom("shutdown");
    window.Intercom("boot");
    window.localStorage.removeItem("is_registering");
  }

  signUp = () => {
    window.localStorage.setItem("is_registering", "true");
    window.localStorage.setItem("registration-stage", "COMPANY_INFO");
  };

  isValid = () => {
    if (this.resetEmail === "") {
      this.setState({ isValid: false });
    }
  };

  render() {
    const loginFooter = login => {
      return (
        <div id="login-login-footer">
          <div id="login-save-signin-container">
            <input type="checkbox" /> <span>{Resources.RememberMeFor30Days}</span>
          </div>
          <div className="flex-center">
            <button className="button-green d-block mt-4">{Resources.LoginTitle}</button>
          </div>
          <a className="login-forgot-password blue-font-white-bg" href="/#/reset-password">
            {Resources.ForgotPassword}
          </a>
        </div>
      );
    };

    if (this.props.userStore.isLoggingIn && this.props.userStore.isSamlLogin) {
      return (
        <div className="flex-center flex-align-center" style={{ height: "100vh" }}>
          <MainLoader fullScreen />
        </div>
      );
    }

    let nextPath = getQueryParameters(this.props.location.search).next;
    return (
      <div className="login-container">
        <div className="login-container-logo">
          <LockstepLogo inverse height={57} />
        </div>
        <div id="login-subcontainer" className="clearfix">
          <div>
            <div id="login-left-container">
              <div className="login-blurb">{Resources.LoginBlurb1}</div>
              <div>{Resources.LoginBlurb2}</div>
              {/* <p id="login-signup-container">{Resources.DontHaveAnAccount}{' '} <NavLink to="/register" onClick={this.signUp} id="login-signup-link">{Resources.SignUp}</NavLink></p> */}
            </div>
            <div className="headerDivider" />
            <div id="login-right-container">
              <h3 className="login-title fw-500">{Resources.LoginTitle}</h3>
              {this.props.registration.doesAccountExists ? (
                <h5 className="warning-color-font">{Resources.YouAlreadyHaveAnAccount}</h5>
              ) : (
                ""
              )}
              <LoginForm loginFooter={loginFooter} history={this.props.history} loginSuccessPath={nextPath} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    registration: store.registration,
    userStore: store.user
  };
};

export default connect(storeToProps, dispatchToProps)(Login);
