import { actions } from "./network-actions";
import { isEmpty } from "../lib/utils";

const defaultNetworkSettings = {
  accountList: { value: [] },
  accountsMap: {},
  accountContactsMap: {},
  accountArchivedContactsMap: {},
  accountDocumentsMap: {},

  loadedAccountListPage: 1,
  gettingNetworkAccountList: false,
  gotNetworkAccountList: false,
  failedGetNetworkAccountList: false,

  contactList: { value: [] },
  contactsMap: {},
  loadedContactListPage: 1,
  gettingNetworkContactList: false,
  gotNetworkContactList: false,
  failedGetNetworkContactList: false,

  gettingCustContact: false,
  gotCustContact: false,
  failedGetCustContact: false,
  custContact: {},

  postingCustContact: false,
  postedCustContact: false,
  failedPostCustContact: false,

  postingCustContactPicture: false,
  postedCustContactPicture: false,
  failedPostCustContactPicture: false,

  gettingCustAccount: false,
  gotCustAccount: false,
  failedGetCustAccount: false,
  custAccount: { value: [] },

  gettingCustAccountDocuments: false,
  gotCustAccountDocuments: false,
  failedGetCustAccountDocuments: false,
  custAccountDocuments: { value: [] },

  gettingCustAccountContacts: false,
  gotCustAccountContacts: false,
  failedGetCustAccountContacts: false,
  custAccountContacts: { value: [] },

  postingCustAccount: false,
  postedCustAccount: false,
  failedPostCustAccount: false,

  postingCustAccountPicture: false,
  postedCustAccountPicture: false,
  failedPostCustAccountPicture: false,
  custAccountPicture: "",

  openOnboardingRequests: {},
  gettingOpenOnboardingRequests: false,
  gotOpenOnboardingRequests: false,
  failedGetOpenOnboardingRequests: false,

  openProfileUpdateRequests: {},
  gettingOpenProfileUpdateRequests: false,
  gotOpenProfileUpdateRequests: false,
  failedGetOpenProfileUpdateRequests: false,

  gettingAccountProfiles: {},
  gotCustomerAccounts: {},
  failedGetCustomerAccounts: {},

  gettingAccountContacts: {},
  gotAccountContacts: {},
  failedGetAccountContacts: {},

  gettingAccountArchivedContacts: {},
  gotAccountArchivedContacts: {},
  failedGetAccountArchivedContacts: {},

  gettingAccountDocuments: {},
  gotAccountDocuments: {},
  failedGetAccountDocuments: {},

  gettingContactProfiles: {},
  gotCustomerContacts: {},
  failedGetCustomerContacts: {},

  postingAccountProfileUpdateRequest: false,
  postedAccountProfileUpdateRequest: false,
  failedPostAccountProfileUpdateRequest: false,

  postingContactProfileUpdateRequest: false,
  postedContactProfileUpdateRequest: false,
  failedPostContactProfileUpdateRequest: false,

  gettingHeaderInfo: false,
  gotHeaderInfo: false,
  failedGetHeaderInfo: false,
  headerInfo: {},

  uploadingAccountDocument: false,
  uploadAccountDocument: false,
  failedUploadAccountDocument: false,
  uploadedAccountDocument: "",

  gettingNetworkAccountSearchResults: false,
  gotNetworkAccountSearchResults: false,
  failedGetNetworkAccountSearchResults: false,
  accountSearchResults: { value: [] },
  accountSearchTerm: "",

  gettingNetworkContactSearchResults: false,
  gotNetworkContactSearchResults: false,
  failedGetNetworkContactSearchResults: false,
  contactSearchResults: { value: [] },
  contactSearchTerm: "",

  gettingCustAccountSettings: false,
  gotCustAccountSettings: false,
  failedGetCustAccountSettings: false,
  custAccountSettings: {},

  postingCustAccountSettings: false,
  postedCustAccountSettings: false,
  failedPostCustAccountSettings: false,

  gettingNetworkActivityList: false,
  gotNetworkActivityList: false,
  failedGetNetworkActivityList: false,
  activityList: { value: [] },

  gettingEmailAttachments: false,
  gotEmailAttachments: false,
  failedGetEmailAttachments: false,
  emailAttachments: {},

  updatingDocumentType: false,
  updatedDocumentType: false,
  failedUpdateDocumentType: false,

  deletingDocument: false,
  deletedDocument: false,
  failedDeleteDocument: false,

  downloadingAttachment: false,
  downloadedAttachment: false,
  failedDownloadAttachment: false,
  attachmentDownload: "",

  gettingNotes: false,
  gotNotes: false,
  failedGetNotes: false,
  notes: {},

  postingNotes: false,
  postedNotes: false,
  failedPostNotes: false,

  deletingNote: false,
  deletedNote: false,
  failedDeleteNote: false,

  gettingShareMessage: false,
  gotShareMessage: false,
  failedGetShareMessage: false,
  shareMessage: "",

  gettingShareLink: false,
  gotShareLink: false,
  failedGetShareLink: false,
  shareLink: "",

  postingShareFlyout: false,
  postedShareFlyout: false,
  failedPostShareFlyout: false,
  session: "",

  postingMyProfilePicture: false,
  postedMyProfilePicture: false,
  failedPostMyProfilePicture: false,
  myProfilePicture: "",

  gettingSubsidiaryList: false,
  gotSubsidiaryList: false,
  failedGetSubsidiaryList: false,
  subsidiaryList: [],

  updatingContactPrivacy: false,
  updatedContactPrivacy: false,
  failedUpdateContactPrivacy: false,
  makePrivate: "",

  addingContact: false,
  addedContact: false,
  failedAddContact: false,
  addedContactValue: {},

  markingContactPrimary: false,
  markedContactPrimary: false,
  failedMarkContactPrimary: false,
  setAsPrimary: {},

  archivingAccount: false,
  archivedAccount: false,
  failedArchiveAccount: false,

  gotArchivedList: false,
  gettingArchivedList: false,
  failedGetArchivedList: false,
  archivedList: {},

  gotArchivedNetworkContactList: false,
  gettingArchivedNetworkContactList: false,
  failedGetArchivedNetworkContactList: false,
  archivedContactList: {},

  archivingContact: false,
  archivedContact: false,
  failedArchiveContact: false,

  claimingProfile: false,
  claimedProfile: false,
  failedClaimProfile: false,

  gettingSelfServiceStatus: false,
  gotSelfServiceStatus: false,
  failedGetSelfServiceStatus: false,
  selfServiceStatus: {}
};

function networkReducer(state = defaultNetworkSettings, action) {
  switch (action.type) {
    case actions.GETTING_NETWORK_ACCOUNT_LIST:
      return {
        ...state,
        gettingNetworkAccountList: true,
        gotNetworkAccountList: false,
        failedGetNetworkAccountList: false
      };
    case actions.GOT_NETWORK_ACCOUNT_LIST:
      let accountsMap = { ...state.accountsMap };
      action.accountList.value.forEach(account => {
        let updatedAccount = { ...state.accountsMap[account.entityGlobalKey] };
        for (let property in account) {
          if (!isEmpty(account[property])) {
            updatedAccount[property] = account[property];
          }
        }
        accountsMap[account.entityGlobalKey] = updatedAccount;
      });
      return {
        ...state,
        gettingNetworkAccountList: false,
        gotNetworkAccountList: true,
        failedGetNetworkAccountList: false,
        accountsMap,
        accountList: action.accountList
      };
    case actions.FAILED_GET_NETWORK_ACCOUNT_LIST:
      return {
        ...state,
        gettingNetworkAccountList: false,
        gotNetworkAccountList: false,
        failedGetNetworkAccountList: true
      };
    case actions.SET_NETWORK_ACCOUNT_LIST_LOADED_PAGE:
      return { ...state, loadedAccountListPage: action.selectedPage };
    case actions.GETTING_NETWORK_CONTACT_LIST:
      return {
        ...state,
        gettingNetworkContactList: true,
        gotNetworkContactList: false,
        failedGetNetworkContactList: false
      };
    case actions.GOT_NETWORK_CONTACT_LIST:
      let contactsMap = { ...state.contactsMap };
      action.contactList.value.forEach(contact => {
        let updatedContact = { ...state.contactsMap[contact.entityGlobalKey] };
        for (let property in contact) {
          if (!isEmpty(contact[property])) {
            updatedContact[property] = contact[property];
          }
        }
        contactsMap[contact.entityGlobalKey] = updatedContact;
      });
      return {
        ...state,
        gettingNetworkContactList: false,
        gotNetworkContactList: true,
        failedGetNetworkContactList: false,
        contactList: action.contactList,
        contactsMap
      };
    case actions.FAILED_GET_NETWORK_CONTACT_LIST:
      return {
        ...state,
        gettingNetworkContactList: false,
        gotNetworkContactList: false,
        failedGetNetworkContactList: true
      };
    case actions.SET_NETWORK_CONTACT_LIST_LOADED_PAGE:
      return { ...state, loadedContactListPage: action.selectedPage };
    case actions.GETTING_CUST_CONTACT:
      return { ...state, gettingCustContact: true, gotCustContact: false, failedGetCustContact: false };
    case actions.GOT_CUST_CONTACT:
      return {
        ...state,
        gettingCustContact: false,
        gotCustContact: true,
        failedGetCustContact: false,
        custContact: action.custContact
      };
    case actions.FAILED_GET_CUST_CONTACT:
      return { ...state, gettingCustContact: false, gotCustContact: false, failedGetCustContact: true };
    case actions.POSTING_CUST_CONTACT:
      return { ...state, postingCustContact: true, postedCustContact: false, failedPostCustContact: false };
    case actions.POSTED_CUST_CONTACT:
      return {
        ...state,
        postingCustContact: false,
        postedCustContact: true,
        failedPostCustContact: false,
        custContact: {
          ...action.custContact
        }
      };
    case actions.FAILED_POST_CUST_CONTACT:
      return { ...state, postingCustContact: false, postedCustContact: false, failedPostCustContact: true };
    case actions.POSTING_CUST_CONTACT_PICTURE:
      return {
        ...state,
        postingCustContactPicture: true,
        postedCustContactPicture: false,
        failedPostCustContactPicture: false
      };
    case actions.POSTED_CUST_CONTACT_PICTURE:
      let custContact = { ...state.custContact };
      custContact.profilePictureURL = action.custContactPicture;
      return {
        ...state,
        postingCustContactPicture: false,
        postedCustContactPicture: true,
        failedPostCustContactPicture: false,
        custContact
      };
    case actions.FAILED_POST_CUST_CONTACT_PICTURE:
      return {
        ...state,
        postingCustContactPicture: false,
        postedCustContactPicture: false,
        failedPostCustContactPicture: true
      };
    case actions.GETTING_CUST_ACCOUNT:
      return { ...state, gettingCustAccount: true, gotCustAccount: false, failedGetCustAccount: false };
    case actions.GOT_CUST_ACCOUNT:
      return {
        ...state,
        gettingCustAccount: false,
        gotCustAccount: true,
        failedGetCustAccount: false,
        custAccount: action.custAccount
      };
    case actions.FAILED_GET_CUST_ACCOUNT:
      return { ...state, gettingCustAccount: false, gotCustAccount: false, failedGetCustAccount: true };
    case actions.GETTING_CUST_ACCOUNT_DOCUMENTS:
      return {
        ...state,
        gettingCustAccountDocuments: true,
        gotCustAccountDocuments: false,
        failedGetCustAccountDocuments: false
      };
    case actions.GOT_CUST_ACCOUNT_DOCUMENTS:
      return {
        ...state,
        gettingCustAccountDocuments: false,
        gotCustAccountDocuments: true,
        failedGetCustAccountDocuments: false,
        custAccountDocuments: action.custAccountDocuments
      };
    case actions.FAILED_GET_CUST_ACCOUNT_DOCUMENTS:
      return {
        ...state,
        gettingCustAccountDocuments: false,
        gotCustAccountDocuments: false,
        failedGetCustAccountDocuments: true
      };
    case actions.GETTING_CUST_ACCOUNT_CONTACTS:
      return {
        ...state,
        gettingCustAccountContacts: true,
        gotCustAccountContacts: false,
        failedGetCustAccountContacts: false
      };
    case actions.GOT_CUST_ACCOUNT_CONTACTS:
      return {
        ...state,
        gettingCustAccountContacts: false,
        gotCustAccountContacts: true,
        failedGetCustAccountContacts: false,
        custAccountContacts: action.custAccountContacts
      };
    case actions.FAILED_GET_CUST_ACCOUNT_CONTACTS:
      return {
        ...state,
        gettingCustAccountContacts: false,
        gotCustAccountContacts: false,
        failedGetCustAccountContacts: true
      };
    case actions.GETTING_OPEN_ONBOARDING_REQUESTS:
      return {
        ...state,
        gettingOpenOnboardingRequests: true,
        gotOpenOnboardingRequests: false,
        failedGetOpenOnboardingRequests: false
      };
    case actions.GOT_OPEN_ONBOARDING_REQUESTS:
      return {
        ...state,
        gettingOpenOnboardingRequests: false,
        gotOpenOnboardingRequests: true,
        failedGetOpenOnboardingRequests: false,
        openOnboardingRequests: action.data
      };
    case actions.FAILED_GET_OPEN_ONBOARDING_REQUESTS:
      return {
        ...state,
        gettingOpenOnboardingRequests: false,
        gotOpenOnboardingRequests: false,
        failedGetOpenOnboardingRequests: true
      };
    case actions.GETTING_OPEN_PROFILE_UPDATE_REQUESTS:
      return {
        ...state,
        gettingOpenProfileUpdateRequests: true,
        gotOpenProfileUpdateRequests: false,
        failedGetOpenProfileUpdateRequests: false
      };
    case actions.GOT_OPEN_PROFILE_UPDATE_REQUESTS:
      return {
        ...state,
        gettingOpenProfileUpdateRequests: false,
        gotOpenProfileUpdateRequests: true,
        failedGetOpenProfileUpdateRequests: false,
        openProfileUpdateRequests: action.data
      };
    case actions.FAILED_GET_OPEN_PROFILE_UPDATE_REQUESTS:
      return {
        ...state,
        gettingOpenProfileUpdateRequests: false,
        gotOpenProfileUpdateRequests: false,
        failedGetOpenProfileUpdateRequests: true
      };
    case actions.POSTING_CUST_ACCOUNT:
      return { ...state, postingCustAccount: true, postedCustAccount: false, failedPostCustAccount: false };
    case actions.POSTED_CUST_ACCOUNT:
      return {
        ...state,
        postingCustAccount: false,
        postedCustAccount: true,
        failedPostCustAccount: false,
        custAccount: {
          ...state.custAccount,
          ...action.custAccount
        }
      };
    case actions.FAILED_POST_CUST_ACCOUNT:
      return { ...state, postingCustAccount: false, postedCustAccount: false, failedPostCustAccount: true };
    case actions.POSTING_CUST_ACCOUNT_PICTURE:
      return {
        ...state,
        postingCustAccountPicture: true,
        postedCustAccountPicture: false,
        failedPostCustAccountPicture: false
      };
    case actions.POSTED_CUST_ACCOUNT_PICTURE:
      let custAccount = { ...state.custAccount };
      custAccount.logoURL = action.custAccountPicture;
      return {
        ...state,
        postingCustAccountPicture: false,
        postedCustAccountPicture: true,
        failedPostCustAccountPicture: false,
        custAccount
      };
    case actions.FAILED_POST_CUST_ACCOUNT_PICTURE:
      return {
        ...state,
        postingCustAccountPicture: false,
        postedCustAccountPicture: false,
        failedPostCustAccountPicture: true
      };
    case actions.GETTING_ACCOUNT_PROFILE:
      return {
        ...state,
        gettingAccountProfiles: { ...state.gettingAccountProfiles, [action.accountKey]: true },
        gotCustomerAccounts: { ...state.gotCustomerAccounts, [action.accountKey]: false },
        failedGetCustomerAccounts: { ...state.failedGetCustomerAccounts, [action.accountKey]: false }
      };
    case actions.GOT_ACCOUNT_PROFILE:
      let accountProfile = {
        ...action.accountProfile.companyARDetails,
        ...action.accountProfile.companyAPDetails,
        emailAddr:
          (action.accountProfile.accountingGroupARDetails || {}).emailAddr ||
          (action.accountProfile.accountingGroupAPDetails || {}).emailAddr,
        lockstepVerified: action.accountProfile.isLockstepVerified
      };
      return {
        ...state,
        gettingAccountProfiles: { ...state.gettingAccountProfiles, [action.accountKey]: false },
        gotCustomerAccounts: { ...state.gotCustomerAccounts, [action.accountKey]: true },
        failedGetCustomerAccounts: { ...state.failedGetCustomerAccounts, [action.accountKey]: false },
        accountsMap: { ...state.accountsMap, [action.accountKey]: accountProfile }
      };
    case actions.GET_ACCOUNT_PROFILE_FAILED:
      return {
        ...state,
        gettingAccountProfiles: { ...state.gettingAccountProfiles, [action.accountKey]: false },
        gotCustomerAccounts: { ...state.gotCustomerAccounts, [action.accountKey]: false },
        failedGetCustomerAccounts: { ...state.failedGetCustomerAccounts, [action.accountKey]: true }
      };
    case actions.GETTING_ACCOUNT_DOCUMENTS:
      return {
        ...state,
        gettingAccountDocuments: { ...state.gettingAccountDocuments, [action.accountKey]: true },
        gotAccountDocuments: { ...state.gotAccountDocuments, [action.accountKey]: false },
        failedGetAccountDocuments: { ...state.failedGetAccountDocuments, [action.accountKey]: false }
      };
    case actions.GOT_ACCOUNT_DOCUMENTS:
      return {
        ...state,
        gettingAccountDocuments: { ...state.gettingAccountDocuments, [action.accountKey]: false },
        gotAccountDocuments: { ...state.gotAccountDocuments, [action.accountKey]: true },
        failedGetAccountDocuments: { ...state.failedGetAccountDocuments, [action.accountKey]: false },
        accountDocumentsMap: {
          ...state.accountDocumentsMap,
          [action.accountKey]: [...action.accountDocuments]
        }
      };
    case actions.GET_ACCOUNT_DOCUMENTS_FAILED:
      return {
        ...state,
        gettingAccountDocuments: { ...state.gettingAccountDocuments, [action.accountKey]: false },
        gotAccountDocuments: { ...state.gotAccountDocuments, [action.accountKey]: false },
        failedGetAccountDocuments: { ...state.failedGetAccountDocuments, [action.accountKey]: true }
      };
    case actions.GETTING_ACCOUNT_CONTACTS:
      return {
        ...state,
        gettingAccountContacts: { ...state.gettingAccountContacts, [action.accountKey]: true },
        gotAccountContacts: { ...state.gotAccountContacts, [action.accountKey]: false },
        failedGetAccountContacts: { ...state.failedGetAccountContacts, [action.accountKey]: false }
      };
    case actions.GOT_ACCOUNT_CONTACTS:
      return {
        ...state,
        gettingAccountContacts: { ...state.gettingAccountContacts, [action.accountKey]: false },
        gotAccountContacts: { ...state.gotAccountContacts, [action.accountKey]: true },
        failedGetAccountContacts: { ...state.failedGetAccountContacts, [action.accountKey]: false },
        accountContactsMap: {
          ...state.accountContactsMap,
          [action.accountKey]: [...action.accountContacts]
        }
      };
    case actions.GET_ACCOUNT_CONTACTS_FAILED:
      return {
        ...state,
        gettingAccountContacts: { ...state.gettingAccountContacts, [action.accountKey]: false },
        gotAccountContacts: { ...state.gotAccountContacts, [action.accountKey]: false },
        failedGetAccountContacts: { ...state.failedGetAccountContacts, [action.accountKey]: true }
      };
    case actions.GETTING_ACCOUNT_ARCHIVED_CONTACTS:
      return {
        ...state,
        gettingAccountArchivedContacts: { ...state.gettingAccountArchivedContacts, [action.accountKey]: true },
        gotAccountArchivedContacts: { ...state.gotAccountArchivedContacts, [action.accountKey]: false },
        failedGetAccountArchivedContacts: { ...state.failedGetAccountArchivedContacts, [action.accountKey]: false }
      };
    case actions.GOT_ACCOUNT_ARCHIVED_CONTACTS:
      return {
        ...state,
        gettingAccountArchivedContacts: { ...state.gettingAccountArchivedContacts, [action.accountKey]: false },
        gotAccountArchivedContacts: { ...state.gotAccountArchivedContacts, [action.accountKey]: true },
        failedGetAccountArchivedContacts: { ...state.failedGetAccountArchivedContacts, [action.accountKey]: false },
        accountArchivedContactsMap: {
          ...state.accountArchivedContactsMap,
          [action.accountKey]: [...action.accountArchivedContacts]
        }
      };
    case actions.GET_ACCOUNT_ARCHIVED_CONTACTS_FAILED:
      return {
        ...state,
        gettingAccountArchivedContacts: { ...state.gettingAccountArchivedContacts, [action.accountKey]: false },
        gotAccountArchivedContacts: { ...state.gotAccountArchivedContacts, [action.accountKey]: false },
        failedGetAccountArchivedContacts: { ...state.failedGetAccountArchivedContacts, [action.accountKey]: true }
      };
    case actions.GETTING_CONTACT_PROFILE:
      return {
        ...state,
        gettingContactProfiles: { ...state.gettingContactProfiles, [action.contactKey]: true },
        gotCustomerContacts: { ...state.gotCustomerContacts, [action.contactKey]: false },
        failedGetCustomerContacts: { ...state.failedGetCustomerContacts, [action.contactKey]: false }
      };
    case actions.GOT_CONTACT_PROFILE:
      return {
        ...state,
        gettingContactProfiles: { ...state.gettingContactProfiles, [action.contactKey]: false },
        gotCustomerContacts: { ...state.gotCustomerContacts, [action.contactKey]: true },
        failedGetCustomerContacts: { ...state.failedGetCustomerContacts, [action.contactKey]: false },
        contactsMap: { ...state.contactsMap, [action.contactKey]: action.customerContact }
      };
    case actions.GOT_CONTACT_PROFILE_FAILED:
      return {
        ...state,
        gettingContactProfiles: { ...state.gettingContactProfiles, [action.contactKey]: false },
        gotCustomerContacts: { ...state.gotCustomerContacts, [action.contactKey]: false },
        failedGetCustomerContacts: { ...state.failedGetCustomerContacts, [action.contactKey]: true }
      };
    case actions.POSTING_ACCOUNT_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        postingAccountProfileUpdateRequest: true,
        postedAccountProfileUpdateRequest: false,
        failedPostAccountProfileUpdateRequest: false
      };
    case actions.POSTED_ACCOUNT_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        postingAccountProfileUpdateRequest: false,
        postedAccountProfileUpdateRequest: true,
        failedPostAccountProfileUpdateRequest: false
      };
    case actions.FAILED_POST_ACCOUNT_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        postingAccountProfileUpdateRequest: false,
        postedAccountProfileUpdateRequest: false,
        failedPostAccountProfileUpdateRequest: true
      };
    case actions.POSTING_CONTACT_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        postingContactProfileUpdateRequest: true,
        postedContactProfileUpdateRequest: false,
        failedPostContactProfileUpdateRequest: false
      };
    case actions.POSTED_CONTACT_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        postingContactProfileUpdateRequest: false,
        postedContactProfileUpdateRequest: true,
        failedPostContactProfileUpdateRequest: false
      };
    case actions.FAILED_POST_CONTACT_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        postingContactProfileUpdateRequest: false,
        postedContactProfileUpdateRequest: false,
        failedPostContactProfileUpdateRequest: true
      };
    case actions.GETTING_HEADER_INFO:
      return {
        ...state,
        gettingHeaderInfo: true,
        gotHeaderInfo: false,
        failedGetHeaderInfo: false
      };
    case actions.GOT_HEADER_INFO:
      return {
        ...state,
        gettingHeaderInfo: false,
        gotHeaderInfo: true,
        failedGetHeaderInfo: false,
        headerInfo: {
          ...action.headerInfo
        }
      };
    case actions.FAILED_GET_HEADER_INFO:
      return {
        ...state,
        gettingHeaderInfo: false,
        gotHeaderInfo: false,
        failedGetHeaderInfo: true
      };
    case actions.GETTING_NETWORK_ACCOUNT_SEARCH_RESULTS:
      return {
        ...state,
        gettingNetworkAccountSearchResults: true,
        gotNetworkAccountSearchResults: false,
        failedGetNetworkAccountSearchResults: false,
        accountSearchTerm: action.searchTerm
      };
    case actions.GOT_NETWORK_ACCOUNT_SEARCH_RESULTS:
      return {
        ...state,
        gettingNetworkAccountSearchResults: false,
        gotNetworkAccountSearchResults: true,
        failedGetNetworkAccountSearchResults: false,
        accountSearchResults: {
          ...action.searchResults
        }
      };
    case actions.FAILED_GET_NETWORK_ACCOUNT_SEARCH_RESULTS:
      return {
        ...state,
        gettingNetworkAccountSearchResults: false,
        gotNetworkAccountSearchResults: false,
        failedGetNetworkAccountSearchResults: true
      };
    case actions.GETTING_NETWORK_CONTACT_SEARCH_RESULTS:
      return {
        ...state,
        gettingNetworkContactSearchResults: true,
        gotNetworkContactSearchResults: false,
        failedGetNetworkContactSearchResults: false,
        contactSearchTerm: action.searchTerm
      };
    case actions.GOT_NETWORK_CONTACT_SEARCH_RESULTS:
      return {
        ...state,
        gettingNetworkContactSearchResults: false,
        gotNetworkContactSearchResults: true,
        failedGetNetworkContactSearchResults: false,
        contactSearchResults: {
          ...action.searchResults
        }
      };
    case actions.FAILED_GET_NETWORK_CONTACT_SEARCH_RESULTS:
      return {
        ...state,
        gettingNetworkContactSearchResults: false,
        gotNetworkContactSearchResults: false,
        failedGetNetworkContactSearchResults: true
      };
    case actions.GETTING_CUST_ACCOUNT_SETTINGS:
      return {
        ...state,
        gettingCustAccountSettings: true,
        gotCustAccountSettings: false,
        failedGetCustAccountSettings: false
      };
    case actions.GOT_CUST_ACCOUNT_SETTINGS:
      return {
        ...state,
        gettingCustAccountSettings: false,
        gotCustAccountSettings: true,
        failedGetCustAccountSettings: false,
        custAccountSettings: action.custAccountSettings
      };
    case actions.FAILED_GET_CUST_ACCOUNT_SETTINGS:
      return {
        ...state,
        gettingCustAccountSettings: false,
        gotCustAccountSettings: false,
        failedGetCustAccountSettings: true
      };
    case actions.GETTING_NETWORK_ACTIVITIES:
      let newActivityList = state.activityList;
      if (!action.skip) {
        newActivityList = { value: [] };
      }
      return {
        ...state,
        gettingNetworkActivityList: true,
        gotNetworkActivityList: false,
        failedGetNetworkActivityList: false,
        activityList: newActivityList
      };
    case actions.GOT_NETWORK_ACTIVITIES:
      return {
        ...state,
        gettingNetworkActivityList: false,
        gotNetworkActivityList: true,
        failedGetNetworkActivityList: false,
        activityList: { ...action.activityList, value: [...state.activityList.value, ...action.activityList.value] }
      };
    case actions.FAILED_GET_NETWORK_ACTIVITIES:
      return {
        ...state,
        gettingNetworkActivityList: false,
        gotNetworkActivityList: false,
        failedGetNetworkActivityList: true
      };
    case actions.POSTING_CUST_ACCOUNT_SETTINGS:
      return {
        ...state,
        postingCustAccountSettings: true,
        postedCustAccountSettings: false,
        failedPostCustAccountSettings: false
      };
    case actions.POSTED_CUST_ACCOUNT_SETTINGS:
      return {
        ...state,
        postingCustAccountSettings: false,
        postedCustAccountSettings: true,
        failedGetCustAccountSettings: false
      };
    case actions.FAILED_POST_CUST_ACCOUNT_SETTINGS:
      return {
        ...state,
        postingCustAccountSettings: false,
        postedCustAccountSettings: false,
        failedGetCustAccountSettings: true
      };
    case actions.SELECT_COMPANY:
      return { ...defaultNetworkSettings, custContact: state.custContact };
    case actions.GETTING_NOTES:
      return {
        ...state,
        gettingNotes: true,
        gotNotes: false,
        failedGetNotes: false
      };
    case actions.GOT_NOTES:
      return {
        ...state,
        gettingNotes: false,
        gotNotes: true,
        failedGetNotes: false,
        notes: { ...state.notes, [action.entityGlobalKey]: action.notes }
      };
    case actions.FAILED_GET_NOTES:
      return {
        ...state,
        gettingNotes: false,
        gotNotes: false,
        failedGetNotes: true
      };
    case actions.POSTING_NOTES:
      return {
        ...state,
        postingNotes: true,
        postedNotes: false,
        failedPostNotes: false
      };
    case actions.POSTED_NOTES:
      return {
        ...state,
        postingNotes: false,
        postedNotes: true,
        failedPostNotes: false
      };
    case actions.FAILED_POST_NOTES:
      return {
        ...state,
        postingNotes: false,
        postedNotes: false,
        failedPostNotes: true
      };
    case actions.DELETING_NOTE:
      return {
        ...state,
        deletingNote: true,
        deletedNote: false,
        failedDeleteNote: false
      };
    case actions.DELETED_NOTE:
      return {
        ...state,
        deletingNote: false,
        deletedNote: true,
        failedDeleteNote: false
      };
    case actions.FAILED_DELETE_NOTE:
      return {
        ...state,
        deletingNote: false,
        deletedNote: false,
        failedDeleteNote: true
      };
    case actions.GETTING_SHARE_FLYOUT_MESSAGE:
      return {
        ...state,
        gettingShareFlyoutMessage: true,
        gotShareFlyoutMessage: false,
        failedGetShareFlyoutMessage: false
      };
    case actions.GOT_SHARE_FLYOUT_MESSAGE:
      return {
        ...state,
        gettingShareFlyoutMessage: false,
        gotShareFlyoutMessage: true,
        failedGetShareFlyoutMessage: false,
        shareMessage: action.session
      };
    case actions.FAILED_GET_SHARE_FLYOUT_MESSAGE:
      return {
        ...state,
        gettingShareFlyoutMessage: false,
        gotShareFlyoutMessage: false,
        failedGetShareFlyoutMessage: true
      };
    case actions.GETTING_SHARE_LINK:
      return {
        ...state,
        gettingShareLink: true,
        gotShareLink: false,
        failedGetShareLink: false
      };
    case actions.GOT_SHARE_LINK:
      return {
        ...state,
        gettingShareLink: false,
        gotShareLink: true,
        failedGetShareLink: false,
        shareLink: action.shareLink
      };
    case actions.FAILED_GET_SHARE_LINK:
      return {
        ...state,
        gettingShareLink: false,
        gotShareLink: false,
        failedGetShareLink: true
      };
    case actions.POSTING_SHARE_FLYOUT:
      return {
        ...state,
        postingShareFlyout: true,
        postedShareFlyout: false,
        failedPostShareFlyout: false
      };
    case actions.POSTED_SHARE_FLYOUT:
      return {
        ...state,
        postingShareFlyout: false,
        postedShareFlyout: true,
        failedPostShareFlyout: false,
        session: action.session
      };
    case actions.FAILED_POST_SHARE_FLYOUT:
      return {
        ...state,
        postingShareFlyout: false,
        postedShareFlyout: false,
        failedPostShareFlyout: true
      };
    case actions.UPLOADING_ACCOUNT_DOCUMENT:
      return {
        ...state,
        uploadingAccountDocument: true,
        uploadAccountDocument: false,
        failedUploadAccountDocument: false
      };
    case actions.UPLOADED_ACCOUNT_DOCUMENT:
      return {
        ...state,
        uploadingAccountDocument: false,
        uploadAccountDocument: true,
        failedUploadAccountDocument: false,
        uploadedAccountDocument: action.uploadedAccountDocument
      };
    case actions.FAILED_UPLOAD_ACCOUNT_DOCUMENT:
      return {
        ...state,
        uploadingAccountDocument: false,
        uploadAccountDocument: false,
        failedUploadAccountDocument: true
      };
    case actions.POSTING_MY_PROFILE_PICTURE:
      return {
        ...state,
        postingMyProfilePicture: true,
        postedMyProfilePicture: false,
        failedPostMyProfilePicture: false
      };
    case actions.POSTED_MY_PROFILE_PICTURE:
      return {
        ...state,
        postingMyProfilePicture: false,
        postedMyProfilePicture: true,
        failedPostMyProfilePicture: false,
        myProfilePicture: action.myProfilePicture
      };
    case actions.FAILED_POST_MY_PROFILE_PICTURE:
      return {
        ...state,
        postingMyProfilePicture: false,
        postedMyProfilePicture: false,
        failedPostMyProfilePicture: true
      };
    case actions.GETTING_SUBSIDIARY_LIST:
      return {
        ...state,
        gettingSubsidiaryList: true,
        gotSubsidiaryList: false,
        failedGetSubsidiaryList: false
      };
    case actions.GOT_SUBSIDIARY_LIST:
      return {
        ...state,
        gettingSubsidiaryList: false,
        gotSubsidiaryList: true,
        failedGetSubsidiaryList: false,
        subsidiaryList: action.subsidiaryList
      };
    case actions.FAILED_GET_SUBSIDIARY_LIST:
      return {
        ...state,
        gettingSubsidiaryList: false,
        gotSubsidiaryList: false,
        failedGetSubsidiaryList: true
      };
    case actions.UPDATING_CONTACT_PRIVACY:
      return {
        ...state,
        updatingContactPrivacy: true,
        updatedContactPrivacy: false,
        failedUpdateContactPrivacy: false
      };
    case actions.UPDATED_CONTACT_PRIVACY:
      return {
        ...state,
        updatingContactPrivacy: false,
        updatedContactPrivacy: true,
        failedUpdateContactPrivacy: false,
        makePrivate: action.makePrivate
      };
    case actions.FAILED_UPDATE_CONTACT_PRIVACY:
      return {
        ...state,
        updatingContactPrivacy: false,
        updatedContactPrivacy: false,
        failedUpdateContactPrivacy: true
      };
    case actions.ADDING_CONTACT:
      return {
        ...state,
        addingContact: true,
        addedContact: false,
        failedAddContact: false
      };
    case actions.ADDED_CONTACT:
      return {
        ...state,
        addingContact: false,
        addedContact: true,
        failedAddContact: false,
        addedContactValue: action.addedContact
      };
    case actions.FAILED_ADD_CONTACT:
      return {
        ...state,
        addingContact: false,
        addedContact: false,
        failedAddContact: true
      };
    case actions.MARKING_CONTACT_AS_PRIMARY:
      return {
        ...state,
        markedContactPrimary: false,
        markingContactPrimary: true,
        failedMarkContactPrimary: false
      };
    case actions.MARKED_CONTACT_AS_PRIMARY:
      return {
        ...state,
        markedContactPrimary: true,
        markingContactPrimary: false,
        failedMarkContactPrimary: false,
        setAsPrimary: action.setAsPrimary
      };
    case actions.FAILED_MARK_CONTACT_AS_PRIMARY:
      return {
        ...state,
        markedContactPrimary: false,
        markingContactPrimary: false,
        failedMarkContactPrimary: true
      };
    case actions.ARCHIVING_ACCOUNT:
      return {
        ...state,
        archivingAccount: true,
        archivedAccount: false,
        failedArchiveAccount: false
      };
    case actions.ARCHIVED_ACCOUNT:
      return {
        ...state,
        archivedAccount: true,
        archivingAccount: false,
        failedArchiveAccount: false
      };
    case actions.FAILED_ARCHIVE_ACCOUNT:
      return {
        ...state,
        archivedAccount: false,
        archivingAccount: false,
        failedArchiveAccount: true
      };
    case actions.GETTING_ARCHIVED_LIST:
      return {
        ...state,
        gettingArchivedList: true,
        gotArchivedList: false,
        failedGetArchivedList: false
      };
    case actions.GOT_ARCHIVED_LIST:
      return {
        ...state,
        gotArchivedList: true,
        gettingArchivedList: false,
        failedGetArchivedList: false,
        archivedList: action.archivedList
      };
    case actions.FAILED_GET_ARCHIVED_LIST:
      return {
        ...state,
        gotArchivedNetworkContactList: false,
        gettingArchivedList: false,
        failedGetArchivedList: true
      };
    case actions.GETTING_ARCHIVED_CONTACT_LIST:
      return {
        ...state,
        gettingArchivedNetworkContactList: true,
        gotArchivedList: false,
        failedGetArchivedNetworkContactList: false
      };
    case actions.GOT_ARCHIVED_CONTACT_LIST:
      return {
        ...state,
        gotArchivedNetworkContactList: true,
        gettingArchivedNetworkContactList: false,
        failedGetArchivedNetworkContactList: false,
        archivedContactList: action.archivedContactList
      };
    case actions.FAILED_GET_ARCHIVED_CONTACT_LIST:
      return {
        ...state,
        gotArchivedNetworkContactList: false,
        gettingArchivedNetworkContactList: false,
        failedGetArchivedNetworkContactList: true
      };
    case actions.ARCHIVING_CONTACT:
      return {
        ...state,
        archivingContact: true,
        archivedContact: false,
        failedArchiveContact: false
      };
    case actions.ARCHIVED_CONTACT:
      return {
        ...state,
        archivingContact: false,
        archivedContact: true,
        failedArchiveContact: false
      };
    case actions.FAILED_ARCHIVE_CONTACT:
      return {
        ...state,
        archivingContact: false,
        archivedContact: false,
        failedArchiveContact: true
      };
    case actions.CLAIMING_PROFILE:
      return {
        ...state,
        claimingProfile: true,
        claimedProfile: false,
        failedClaimProfile: false
      };
    case actions.CLAIMED_PROFILE:
      return {
        ...state,
        claimingProfile: false,
        claimedProfile: true,
        failedClaimProfile: false
      };
    case actions.FAILED_CLAIM_PROFILE:
      return {
        ...state,
        claimingProfile: false,
        claimedProfile: false,
        failedClaimProfile: true
      };
    case actions.GETTING_SELF_SERVICE_STATUS:
      return {
        ...state,
        gettingSelfServiceStatus: true,
        gotSelfServiceStatus: false,
        failedGetSelfServiceStatus: false
      };
    case actions.GOT_SELF_SERVICE_STATUS:
      return {
        ...state,
        gettingSelfServiceStatus: false,
        gotSelfServiceStatus: true,
        failedGetSelfServiceStatus: false,
        selfServiceStatus: action.selfServiceStatus
      };
    case actions.FAILED_GET_SELF_SERVICE_STATUS:
      return {
        ...state,
        gettingSelfServiceStatus: false,
        gotSelfServiceStatus: false,
        failedGetSelfServiceStatus: true
      };
    case actions.GETTING_EMAIL_ATACHMENTS:
      return {
        ...state,
        gettingEmailAttachments: true,
        gotEmailAttachments: false,
        failedGetEmailAttachments: false
      };
    case actions.GOT_EMAIL_ATACHMENTS:
      return {
        ...state,
        gettingEmailAttachments: false,
        gotEmailAttachments: true,
        failedGetEmailAttachments: false,
        emailAttachments: action.emailAtachments
      };
    case actions.FAILED_GET_EMAIL_ATACHMENTS:
      return {
        ...state,
        gettingEmailAttachments: false,
        gotEmailAttachments: false,
        failedGetEmailAttachments: true
      };
    case actions.UPDATING_DOCUMENT_TYPE:
      return {
        ...state,
        updatingDocumentType: true,
        updatedDocumentType: false,
        failedUpdateDocumentType: false
      };
    case actions.UPDATED_DOCUMENT_TYPE:
      return {
        ...state,
        updatingDocumentType: false,
        updatedDocumentType: true,
        failedUpdateDocumentType: false
      };
    case actions.FAILED_UPDATE_DOCUMENT_TYPE:
      return {
        ...state,
        updatingDocumentType: false,
        updatedDocumentType: false,
        failedUpdateDocumentType: true
      };
    case actions.DELETING_DOCUMENT:
      return {
        ...state,
        deletingDocument: true,
        deletedDocument: false,
        failedDeleteDocument: false
      };
    case actions.DELETED_DOCUMENT:
      return {
        ...state,
        deletingDocument: false,
        deletedDocument: true,
        failedDeleteDocument: false
      };
    case actions.FAILED_DELETE_DOCUMENT:
      return {
        ...state,
        deletingDocument: false,
        deletedDocument: false,
        failedDeleteDocument: true
      };
    case actions.DOWNLOADING_ATTACHMENT:
      return {
        ...state,
        downloadingAttachment: true,
        downloadedAttachment: false,
        failedDownloadAttachment: false
      };
    case actions.DOWNLOADED_ATTACHMENT:
      return {
        ...state,
        downloadingAttachment: false,
        downloadedAttachment: true,
        failedDownloadAttachment: false,
        attachmentDownload: action.attachmentDownload
      };
    case actions.FAILED_DOWNLOAD_ATTACHMENT:
      return {
        ...state,
        downloadingAttachment: false,
        downloadedAttachment: false,
        failedDownloadAttachment: true
      };
    default:
      return state;
  }
}

export default networkReducer;
