import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";

import { hideModal } from "../store/modal-actions";

import Resources from "../lib/resources";
import { isEmpty } from "../lib/utils";
import IconAlertCircle from "./library/icons/iconAlertCircle";

function MissingPaymentMethodModal({ errorMessage, customContent, validationErrors, title = Resources.MissingPaymentMethod }) {
  const showModal = useSelector(store => store.modal.showModal);
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => dispatch(hideModal())}
      className="custom-modal-safari"
      overlayClassName="custom-modal-overlay"
      width="600px"
    >
      <div className="custom-modal-header-safari">
        <IconAlertCircle className="unapplied-alert-icon" />
        <div>{title}</div>
      </div>
      <div className="custom-modal-divider" />
      <div className="custom-modal-content">
        <div className="mt-4">{title}</div>
        {!isEmpty(customContent) && <React.Fragment>{customContent}</React.Fragment>}
        {!isEmpty(validationErrors) && (
          <div className="mt-4">
            {Object.keys(validationErrors).map(key => (
              <div>
                {key}: {validationErrors[key]}
              </div>
            ))}
          </div>
        )}
        <div className="flex-row modal-buttons-safari mt-4">
          <button className="btn button-green-safari" onClick={() => dispatch(hideModal())}>
            {Resources.Ok}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default MissingPaymentMethodModal;
