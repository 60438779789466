import { Component } from "react";
import { generatePath, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React from "react";
import Card from "../../../library/card";
import IconUpload from "../../../library/icons/iconUpload";
import UserAvatar from "../../../library/userAvatar";
import AutoCompleteInput from "../../../library/autoCompleteInput";

// import IconHelp from "../../../library/icons/iconHelp";
import IconInfo from "../../../library/icons/iconHelp";

import { dispatchToProps as networkDP } from "../../../../store/network-actions";
import { dispatchToProps as moDP } from "../../../../store/modal-actions";
import { dispatchToProps as persDP } from "../../../../store/perspectives-actions";

import MainLoader from "../../../mainLoader";
import Dropdown from "../../../library/dropdown";
import SelectInput from "../../../library/selectInput";
import Resources from "../../../../lib/resources";
import TextInput from "../../../library/textInput";
import TextArea from "../../../library/textAreaInput";
import Avatar from "../../../library/avatar";
import moment from "moment";
import { isEmail, isLongerThan } from "../../../../lib/validation";
import { find } from "../../../../lib/utils";

const dispatchToProps = dispatch => ({
  ...networkDP(dispatch),
  ...moDP(dispatch),
  ...persDP(dispatch)
});

class ContactUpdateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custContact: {},
      dropdownActive: false,
      updatedPicture: false,
      searchTerm: this.props.selectedCompany
    };
    this.submitForm = this.submitForm.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidMount() {
    if (this.props.small || this.props.empty) {
    } else {
      let accessId = this.props.match.params.accessId;
      this.props.fetchCustContact(accessId);
    }
  }

  uploadFile() {
    window.document.getElementById("uploadFile").click();
  }

  checkForInvalidInputs(postRequest) {
    let value = isEmail(postRequest.emailAddr);
    return value;
  }

  submitForm() {
    let custContact = this.props.edit
      ? { ...this.props.networkStore.contactsMap[this.props.match.params.contactId], ...this.state.custContact }
      : { ...this.props.networkStore.custContact, ...this.state.custContact };
    let postRequest = {
      accountKey: custContact.accountKey,
      accountRole: custContact.accountRole,
      accountRoleId: custContact.accountRoleId,
      addrLn1: custContact.addrLn1,
      city: custContact.city,
      contactName: custContact.contactName,
      contactRole: custContact.contactRole,
      contactRoleId: custContact.contactRoleId,
      country: custContact.country,
      emailAddr: custContact.emailAddr,
      insertDate: custContact.insertDate,
      isLockstepVerified: true,
      linkedInProfile: custContact.linkedInProfile,
      lockstepContactKey: custContact.lockstepContactKey,
      phone: custContact.phone,
      fax: custContact.fax,
      phoneExt: custContact.phoneExt,
      postalcode: custContact.postalcode,
      profilePictureURL: !!this.props.networkStore.myProfilePicture
        ? this.props.networkStore.myProfilePicture
        : custContact.profilePictureURL,
      state: custContact.state,
      title: custContact.title,
      updateByuserId: custContact.updateByuserId,
      updateDate: moment().format(),
      notes: custContact.notes
    };
    let essentialForms = [
      { property: custContact.contactName, form: "Name" },
      { property: custContact.emailAddr, form: "Email" }
    ];
    let empty = essentialForms.filter(i => i.property === undefined || i.property === "");
    if (empty.length !== 0) {
      let formText = "";
      formText = empty.map(i => (formText = " " + i.form));
      let errorMessage =
        empty.length === 1
          ? Resources.PleaseEnsureFormFilledOut(formText)
          : Resources.PleaseEnsureFormsFilledOut(formText);
      this.props.displayModal("errorModal", { errorMessage });
    } else {
      if (this.props.edit) {
        this.props
          .updateCustContact(
            this.props.match.params.perspectiveId,
            this.props.match.params.contactId,
            this.props.mode,
            postRequest
          )
          .then(response => {
            this.props.toggleEditMode();
          });
      } else if (this.props.small) {
        this.props.postMyProfile(postRequest).then(response => {
          this.props.toggleEditMode();
          this.props.fetchActivityList(this.props.perspectiveId, {
            top: 100,
            skip: 0
          });
        });
      } else {
        let accessId = this.props.match.params.accessId;
        this.props.postCustContact(accessId, postRequest).then(response => {
          this.props.history.push(
            generatePath(this.props.route, {
              accessId: this.props.match.params.accessId,
              view: this.props.view,
              type: "success",
              accountingGroupKey: this.props.match.params.accountingGroupKey,
              companyGlobalKey: this.props.match.params.companyGlobalKey,
              mode: this.props.match.params.mode
            })
          );
        });
      }
    }
  }

  handleDropdown() {
    this.setState({ dropdownActive: !this.state.dropdownActive });
  }

  render() {
    let custContact = this.props.edit
      ? { ...this.props.networkStore.contactsMap[this.props.match.params.contactId], ...this.state.custContact }
      : { ...this.props.networkStore.custContact, ...this.state.custContact };

    if (this.props.empty && this.props.signup) {
      custContact = this.props.currentContact;
    }

    let roles = [
      { value: 1, role: Resources.CFO },
      { value: 2, role: Resources.Controller },
      {
        value: 3,
        role:
          this.props.match.params.mode === "ar" || this.props.perspectivesStore.selectedPerspectiveType !== "vendors"
            ? Resources.AR + " " + Resources.Manager
            : Resources.AP + " " + Resources.Manager
      },
      {
        value: 4,
        role:
          this.props.match.params.mode === "ar" || this.props.perspectivesStore.selectedPerspectiveType !== "vendors"
            ? Resources.AR + " " + Resources.Specialist
            : Resources.AP + " " + Resources.Specialist
      },
      { value: 5, role: Resources.SalesRepresentitive },
      { value: 6, role: Resources.Other }
    ];

    let selectedPerspectiveType = this.props.mode === "ap" ? "customers" : "vendors";

    if (this.props.networkStore.gotCustContact || this.props.empty) {
      return (
        <div>
          <div className={this.props.small ? null : "lockstep-view"}>
            {this.props.small || this.props.empty ? null : (
              <h2 className="first-time-title-contact">{Resources.ProfileUpdateWelcome}</h2>
            )}
            <Card
              className={
                this.props.edit
                  ? "first-time-form-card edit"
                  : this.props.small
                  ? "first-time-form-card"
                  : "first-time-form-card center"
              }
            >
              {/* {this.props.small ? null : (
                <Dropdown
                  disabled
                  buttonContent={
                    <React.Fragment>
                      <IconHelp height={16} className="button-secondary-icon" />
                      {Resources.HowIsMyDataUsed}
                    </React.Fragment>
                  }
                  buttonClassName="button-secondary contact-form-dropdown"
                >
                  <div>Don't worry about it</div>
                </Dropdown>
              )} */}
              <div className="first-time-card-form">
                <div className="first-time-card-column">
                  {this.props.small || this.props.empty ? (
                    <div className="first-time-card-cell">
                      <TextInput
                        textValue={custContact.contactName}
                        width="23.2rem"
                        labelInline={false}
                        label={this.props.add ? `${Resources.Name} (${Resources.Required})` : Resources.Name}
                        isValid={isLongerThan(custContact.contactName || "", 100)}
                        invalidText={Resources.FieldMustBeLessThanN(100)}
                        inputOnChange={e => {
                          let value = e.target.value;
                          if (this.props.empty) {
                            this.props.contactFormOnChange("contactName", value);
                          }
                          this.setState(prevState => {
                            return { custContact: { ...prevState.custContact, contactName: value } };
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <div className="first-time-card-cell">
                      <div className="profile-invariant-container">
                        <label className="input-label">{Resources.Email}</label>
                        <Dropdown
                          buttonContent={
                            <React.Fragment>
                              <IconInfo height={8} className="profile-update-invariant-field__icon" />
                            </React.Fragment>
                          }
                          buttonClassName="profile-update-invariant-button"
                        >
                          <div className="profile-update-dropdown-content">
                            {Resources.IfYourVARIABLEIsDifferentContactUsAtEMAIL(
                              "email address",
                              "NEED A FETCH FOR THIS EMAIL"
                            )}
                          </div>
                        </Dropdown>
                      </div>
                      <p className={"profile-update-invariant-field"}>{custContact.emailAddr}</p>
                    </div>
                  )}
                </div>
                <div className="first-time-card-column">
                  <div className="first-time-card-cell">
                    {this.props.small || this.props.empty ? (
                      <TextInput
                        textValue={custContact.emailAddr}
                        width="23.2rem"
                        labelInline={false}
                        label={this.props.add ? `${Resources.Email} (${Resources.Required})` : Resources.Email}
                        inputOnChange={e => {
                          let value = e.target.value;
                          if (this.props.empty) {
                            this.props.contactFormOnChange("emailAddr", value);
                          }
                          this.setState(prevState => {
                            return { custContact: { ...prevState.custContact, emailAddr: value } };
                          });
                        }}
                      />
                    ) : (
                      <TextInput
                        textValue={custContact.contactName}
                        width="23.2rem"
                        labelInline={false}
                        label={Resources.Name}
                        isValid={isLongerThan(custContact.contactName || "", 100)}
                        invalidText={Resources.FieldMustBeLessThanN(100)}
                        inputOnChange={e => {
                          let value = e.target.value;
                          this.setState(prevState => {
                            return { custContact: { ...prevState.custContact, contactName: value } };
                          });
                        }}
                      />
                    )}
                  </div>
                  {this.props.add ? (
                    <div className="first-time-card-cell">
                      <div className="contact-update-autofill-container">
                        <label>
                          {this.props.add ? `${Resources.Company} (${Resources.Required})` : Resources.Company}
                        </label>
                        <AutoCompleteInput
                          loading={this.props.networkStore.gettingNetworkAccountSearchResults}
                          className="auto-complete-input__table-data-search"
                          placeholder={Resources.Search}
                          onChange={e => {
                            const searchTerm = e.target.value;
                            this.setState({ searchTerm });
                            this.props.debouncedFetchAccountSearchResults(this.props.perspectiveId, {
                              searchTerm
                            });
                          }}
                          callToActionButton={
                            <div
                              className="dropdown-item-clean dropdown-call-to-action"
                              onClick={() => this.setState({ displayAccountSearchResults: true })}
                            >
                              {Resources.SeeAllResultsFor(this.props.networkStore.accountSearchTerm)}
                            </div>
                          }
                          isShowingSearchResult={this.state.displayAccountSearchResults}
                          handleClearResult={() => {
                            this.setState({ searchTerm: "", displayAccountSearchResults: false });
                          }}
                          label={Resources.Company}
                          text={this.state.searchTerm}
                          noResultsMessage={Resources.NoResultsFound}
                          showNoResultsMessage={this.props.networkStore.gotNetworkAccountSearchResults}
                          maxOptions={7}
                          handleSelectOption={option => {
                            if (this.props.empty) {
                              this.props.contactFormOnChange("companyKey", option.entityGlobalKey);
                            }
                            this.setState({
                              searchTerm: selectedPerspectiveType === "vendors" ? option.custName : option.vendorName,
                              displayAccountSearchResults: true
                            });
                            this.props.fetchAccountSearchResults(this.props.perspectiveId, {
                              searchTerm: selectedPerspectiveType === "customers" ? option.custName : option.vendorName
                            });
                          }}
                          renderOption={option =>
                            `${
                              selectedPerspectiveType === "vendors"
                                ? option.displayName || option.custName
                                : option.displayName || option.vendorName
                            }`
                          }
                          options={this.props.networkStore.accountSearchResults.value}
                          width="300px"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="first-time-card-column">
                  <div className="first-time-card-cell">
                    <TextInput
                      width="23.2rem"
                      labelInline={false}
                      label={Resources.Phone}
                      textValue={custContact.phone}
                      invalidText={Resources.InvalidPhoneNumber}
                      inputOnChange={e => {
                        let value = e.target.value;
                        if (this.props.empty) {
                          this.props.contactFormOnChange("phone", value);
                        }
                        this.setState(prevState => {
                          return { custContact: { ...prevState.custContact, phone: value } };
                        });
                      }}
                    />
                  </div>
                  <div className="first-time-card-cell">
                    <TextInput
                      textValue={custContact.fax}
                      label={Resources.Fax}
                      invalidText={Resources.InvalidFaxNumber}
                      width="23.2rem"
                      labelInline={false}
                      inputOnChange={e => {
                        let value = e.target.value;
                        if (this.props.empty) {
                          this.props.contactFormOnChange("fax", value);
                        }
                        this.setState(prevState => {
                          return { custContact: { ...prevState.custContact, fax: value } };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="first-time-card-column">
                  <div className="first-time-card-cell">
                    <SelectInput
                      className="field"
                      value={
                        find(roles, role => role.value === custContact.contactRoleId) === undefined
                          ? null
                          : custContact.contactRoleId
                      }
                      displayValue={(find(roles, role => role.value === custContact.contactRoleId) || {}).role}
                      optionValueKey="value"
                      optionDisplayKey="role"
                      options={roles}
                      labelInline={false}
                      label={Resources.Role}
                      width={"23.2rem"}
                      onSelectOption={option => {
                        let custContactUpdate = { ...custContact, contactRoleId: option.value };
                        if (this.props.empty) {
                          this.props.contactFormOnChange("contactRoleId", option.value);
                        }
                        this.setState({ custContact: custContactUpdate });
                      }}
                    />
                  </div>
                  <div className="first-time-card-cell">
                    <TextInput
                      label={Resources.Title}
                      width="23.2rem"
                      labelInline={false}
                      textValue={custContact.title}
                      inputOnChange={e => {
                        let value = e.target.value;
                        if (this.props.empty) {
                          this.props.contactFormOnChange("title", value);
                        }
                        this.setState(prevState => {
                          return { custContact: { ...prevState.custContact, title: value } };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="first-time-card-column">
                  <div className="first-time-card-cell">
                    <TextInput
                      label={Resources.LinkedIn}
                      // isValid={isDomain(custContact.linkedInProfile)}
                      // invalidText={Resources.InvalidDomain}
                      width="32.46rem"
                      labelInline={false}
                      textValue={custContact.linkedInProfile}
                      inputOnChange={e => {
                        let value = e.target.value;
                        if (this.props.empty) {
                          this.props.contactFormOnChange("linkedInProfile", value);
                        }
                        this.setState(prevState => {
                          return { custContact: { ...prevState.custContact, linkedInProfile: value } };
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              {this.props.add || this.props.empty ? null : (
                <div className="first-time-card-profile-picture-container">
                  <p>{Resources.ProfilePicture}</p>
                  <div className="first-time-profile-content">
                    <div className="first-time-profile-picture">
                      {!!custContact.profilePictureURL ? (
                        <Avatar
                          height={"4.3rem"}
                          disabled
                          imgURL={
                            this.props.edit
                              ? custContact.profilePictureURL
                              : !!this.props.networkStore.myProfilePicture
                              ? this.props.networkStore.myProfilePicture
                              : custContact.profilePictureURL
                          }
                          // type="user"
                          objectName={custContact.contactName}
                          className={"avatar"}
                        ></Avatar>
                      ) : (
                        <UserAvatar height={25}></UserAvatar>
                      )}
                    </div>
                    <button disabled={this.props.empty} onClick={this.uploadFile} className="button-primary small">
                      <IconUpload height={18} className="button-primary-icon" /> {Resources.Upload}
                    </button>
                    <input
                      className="hidden-input"
                      type="file"
                      id="uploadFile"
                      style={{ display: "none" }}
                      onChange={e => {
                        let accessId = this.props.match.params.accessId;
                        let lockstepcontactKey = custContact.LockstepContactKey;
                        this.props.edit
                          ? this.props.uploadDocument(custContact.entityGlobalKey, e.target.files[0]).then(response => {
                              this.setState({
                                custContact: { ...this.state.custContact, profilePictureURL: response }
                              });
                            })
                          : this.props.small
                          ? this.props.postMyProfilePicture(e.target.files[0])
                          : this.props
                              .postCustContactPicture(accessId, lockstepcontactKey, e.target.files[0])
                              .then(response => {
                                this.setState({
                                  custContact: { ...this.state.custContact, profilePictureURL: response }
                                });
                              });
                        this.props.displayNotification("uploadingImageNotification");
                        this.setState({ updatedPicture: false });
                      }}
                    />
                  </div>
                </div>
              )}
              {this.props.edit && this.props.add ? <hr></hr> : null}
              {this.props.small ? (
                <div className="profile-update-card-form">
                  <div className="first-time-card-column">
                    <div className="first-time-card-cell">
                      <TextInput
                        width="23.2rem"
                        labelInline={false}
                        label={Resources.MailingAddress}
                        textValue={custContact.addrLn1}
                        inputOnChange={e => {
                          let value = e.target.value;
                          if (this.props.empty) {
                            this.props.contactFormOnChange("addrLn1", value);
                          }
                          this.setState(prevState => {
                            return { custContact: { ...prevState.custContact, addrLn1: value } };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="first-time-card-column">
                    <div className="first-time-card-cell">
                      <TextInput
                        width="23.2rem"
                        labelInline={false}
                        label={Resources.Country}
                        textValue={custContact.country}
                        inputOnChange={e => {
                          let value = e.target.value;
                          if (this.props.empty) {
                            this.props.contactFormOnChange("country", value);
                          }
                          this.setState(prevState => {
                            return { custContact: { ...prevState.custContact, country: value } };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="first-time-card-column">
                    <div className="first-time-card-cell">
                      <TextInput
                        width="23.2rem"
                        labelInline={false}
                        label={Resources.City}
                        textValue={custContact.city}
                        inputOnChange={e => {
                          let value = e.target.value;
                          if (this.props.empty) {
                            this.props.contactFormOnChange("city", value);
                          }
                          this.setState(prevState => {
                            return { custContact: { ...prevState.custContact, city: value } };
                          });
                        }}
                      />
                    </div>
                    <div className="first-time-card-cell">
                      <TextInput
                        width="9.53rem"
                        labelInline={false}
                        label={Resources.State}
                        textValue={custContact.state}
                        inputOnChange={e => {
                          let value = e.target.value;
                          if (this.props.empty) {
                            this.props.contactFormOnChange("state", value);
                          }
                          this.setState(prevState => {
                            return { custContact: { ...prevState.custContact, state: value } };
                          });
                        }}
                      />
                    </div>
                    <div className="first-time-card-cell">
                      <TextInput
                        width="9.53rem"
                        labelInline={false}
                        label={Resources.Zip}
                        textValue={custContact.postalcode}
                        inputOnChange={e => {
                          let value = e.target.value;
                          if (this.props.empty) {
                            this.props.contactFormOnChange("postalcode", value);
                          }
                          this.setState(prevState => {
                            return { custContact: { ...prevState.custContact, postalcode: value } };
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="first-time-card-notes-container">
                <TextArea
                  label={Resources.NotesOptional}
                  width="42rem"
                  labelInline={false}
                  rows={4}
                  textValue={custContact.notes}
                  inputOnChange={e => {
                    let value = e.target.value;
                    if (this.props.empty) {
                      this.props.contactFormOnChange("notes", value);
                    }
                    this.setState(prevState => {
                      return { custContact: { ...prevState.custContact, notes: value } };
                    });
                  }}
                ></TextArea>
              </div>
              {this.props.edit && !this.props.add ? <hr></hr> : null}
              {this.props.add ? (
                <div className="profile-update-form-edit-button-container">
                  <button onClick={() => this.props.addContactFunction(false)} className="button-secondary-square">
                    {Resources.Save}
                  </button>
                  <button onClick={() => this.props.addContactFunction(true)} className="button-primary-square">
                    {Resources.SaveAndSendUpdate}
                  </button>
                </div>
              ) : this.props.small ? (
                <div
                  className={
                    this.props.edit
                      ? "profile-update-form-edit-button-container"
                      : "profile-managment-small-button-container"
                  }
                >
                  <button
                    className={this.props.small ? "button-secondary-square" : "button-primary-square large"}
                    onClick={this.props.toggleEditMode}
                  >
                    {Resources.Cancel}
                  </button>
                  <button
                    className={this.props.small ? "button-primary-square" : "button-primary-square large"}
                    onClick={this.submitForm}
                  >
                    {Resources.Save}
                  </button>
                </div>
              ) : null}
            </Card>
            {this.props.small || this.props.empty ? null : (
              <div className="profile-update-form-button-container">
                <button className="button-primary-square large" onClick={this.submitForm}>
                  {Resources.Save}
                </button>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="profile-update-form-mainloader">
          <MainLoader fullScreen={true} />
        </div>
      );
    }
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network,
    modalStore: store.modal,
    perspectivesStore: store.perspectives
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ContactUpdateForm));
