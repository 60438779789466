import React, { Component } from "react";
import Dropdown from "./dropdown";

export default class Tooltip extends Component {
  render() {
    const { noHideDelay, buttonContent } = this.props;
    return (
      <div className="tooltip-container">
        <Dropdown delayShow toggleOnHover noHideDelay={noHideDelay} buttonContent={buttonContent}>
          <div className="tooltip-content">{this.props.text}</div>
        </Dropdown>
      </div>
    );
  }
}
