import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLDClient, useFlags } from "launchdarkly-react-client-sdk";
import { Userpilot } from "userpilot";
import { FullStoryAPI } from "react-fullstory";

import { getConfig, isEmpty, isLocalHost, isProduction } from "../lib/utils";
import { getMyUserIdFromToken, getMyUserNameFromToken, getMyDisplayNameFromToken } from "../store/user-actions";

export function Tracking(props) {
  const accountStore = useSelector(state => state.accounts);
  const userDecodedSub = useSelector(state => (state.user.decoded || {}).sub);

  useEffect(() => {
    if (window.logToConsole) console.log("Identifying user...");
  }, [userDecodedSub]);

  const dispatch = useDispatch();

  let username = dispatch(getMyUserNameFromToken());
  if (Array.isArray(username)) {
    username = username[0];
  }

  const userIdentity = {
    id: dispatch(getMyUserIdFromToken()),
    username,
    displayName: dispatch(getMyDisplayNameFromToken()),
    custom: {
      accountId: accountStore.selectedAccount.accountId,
      accountName: accountStore.selectedAccount.accountName,
      subscriptionTypeId: accountStore.selectedAccount.subscriptionTypeId,
      subscriptionTypeName: accountStore.subscription.subscriptionTypeName
    }
  };

  if (userIdentity.custom.subscriptionTypeName === SubscriptionTypes.CUSTOMER_PORTAL) {
    userIdentity.custom.vendorGroupKey = (userIdentity.username || "").split(":")[1];
  }

  if (isEmpty(userIdentity.id)) {
    return null;
  }

  return (
    <React.Fragment>
      <LaunchDarklyHandler identity={userIdentity} />
      <UserPilotHandler identity={userIdentity} />
      <FullStoryHandler identity={userIdentity} />
    </React.Fragment>
  );
}

const LaunchDarklyHandler = ({ identity }) => {
  const ldClient = useLDClient();
  if (!isEmpty(identity.id) && !isEmpty((identity.custom || {}).subscriptionTypeId)) {
    ldClient.identify({
      key: identity.id,
      name: identity.username,
      custom: identity.custom
    });
  }
  return null;
};

const UserPilotHandler = ({ identity }) => {
  const flags = useFlags();

  if (flags.userpilot) {
    Userpilot.initialize("40fr55f4");
    Userpilot.identify(identity.id, {
      name: identity.username,
      displayName: identity.displayName,
      ...identity.custom
    });
  }
  return null;
};

const FullStoryHandler = ({ identity }) => {
  if (getConfig().forceEnableFullStory || (isLocalHost() !== true && isProduction())) {
    FullStoryAPI("identify", identity.id, {
      username: identity.username,
      displayName: identity.displayName,
      ...identity.custom,
      isDeveloper: isLocalHost() === true
    });
  }
  return null;
};

const SubscriptionTypes = {
  CUSTOMER_PORTAL: "Customer Portal"
};
