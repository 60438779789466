import React, { useEffect, useState } from "react";
import { createKey, find, isEmpty } from "../../lib/utils";
import Dropdown from "./dropdown";
import IconAngleDown from "./icons/iconAngleDown";

export default function SelectInput({
  value,
  options,
  label,
  labelClassName,
  optionValueKey = "value",
  renderOption,
  optionDisplayKey,
  displayValue,
  width,
  labelInline,
  disabled,
  className,
  style = {},
  onSelectOption = () => null,
  callToActionText,
  callToActionOnSelect,
  name
}) {
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    setSelectedOption(find(options, option => option[optionValueKey] === value) || {});
  }, [value]);

  let dropdownItems = options.map((item, index) => {
    let itemValue = optionValueKey ? item[optionValueKey] : item.value;
    let itemDisplayName = optionDisplayKey ? item[optionDisplayKey] : item.displayName;

    return (
      <button
        key={createKey(label, itemValue || itemDisplayName) + `_${index}`}
        className={`dropdown-item ${value && value === itemValue && "active"}`}
        onClick={() => onSelectOption(item)}
        data-test-id={name + "__item--" + index}
      >
        {renderOption ? renderOption(item) : itemDisplayName || itemValue}
      </button>
    );
  });

  if (callToActionText && callToActionOnSelect) {
    dropdownItems.push(
      <button
        key={callToActionText}
        className="dropdown-item dropdown-call-to-action"
        onClick={callToActionOnSelect}
        data-test-id={name + "__item" + "--call-to-action"}
      >
        {callToActionText}
      </button>
    );
  }

  return (
    <div
      className={`select-input ${className ? className : ""} ${labelInline ? "flex-align-center" : ""}`}
      style={style}
    >
      {label ? (
        <div className={labelClassName || "input-label"} id={`dropdown-${label}`}>
          {label}
        </div>
      ) : null}
      <Dropdown
        buttonContent={
          <React.Fragment>
            {renderOption ? renderOption(selectedOption) : <div>{displayValue || value}</div>}
            <IconAngleDown height={8} className="select-input-arrow-down" />
          </React.Fragment>
        }
        buttonClassName="button-select-input"
        buttonWidth={width}
        menuWidth={width}
        aria-labelledby={`dropdown-${label}`}
        matchButtonMenuWidth
        disabled={isEmpty(dropdownItems) || disabled}
      >
        {dropdownItems}
      </Dropdown>
    </div>
  );
}
