import React, { Component } from "react";
import Card from "../../../library/card";
import TextInput from "../../../library/textInput";
import Resources from "../../../../lib/resources";
import SelectInput from "../../../library/selectInput";
import { find } from "../../../../lib/utils";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { dispatchToProps as netDP } from "../../../../store/network-actions";

const dispatchToProps = dispatch => ({
  ...netDP(dispatch)
});

class LoginInformationForm extends Component {
  componentDidMount() {
    if (this.props.contact && !this.props.profileupdate) {
      let accessId = this.props.match.params.accessId;
      this.props.fetchCustContact(accessId).then(response => {
        this.props.formOnChange("username", this.props.networkStore.custContact.emailAddr);
        this.props.formOnChange("accessId", accessId);
      });
    }
  }

  render() {
    let departments = [];
    if (this.props.conversion) {
      departments = [
        { value: 1, department: "Acumatica" },
        { value: 2, department: "Epicor (E10)" },
        { value: 3, department: "Epicor (P21)" },
        { value: 4, department: "Microsoft Dynamic Business Central" },
        { value: 5, department: "Microsoft Dynamic Great Plains" },
        { value: 6, department: "Microsoft Dynamics Navision" },
        { value: 7, department: "NetSuite" },
        { value: 8, department: "QuickBooks" },
        { value: 9, department: "Sage 100" },
        { value: 10, department: "Sage 500" },
        { value: 11, department: "Sage Intacct" },
        { value: 12, department: "SAP BusinessOne" },
        { value: 13, department: "SAP R/3" },
        { value: 14, department: "Xero" },
        { value: 15, department: "Other" }
      ];
    } else {
      departments = [
        { value: 1, department: "Accounts Payable" },
        { value: 2, department: "Accounts Recievable" }
      ];
    }

    const workspaceLabel =
      this.props.match.params.mode === "ar" ? Resources.ARDepartmentEmailAddress : Resources.APDepartmentEmailAddress;

    return (
      <Card className="login-information-card">
        <TextInput
          textValue={this.props.profile.username}
          width="23.2rem"
          labelInline={false}
          label={this.props.conversion ? workspaceLabel : Resources.UsernameEmail}
          inputOnChange={e => {
            let value = e.target.value;
            this.props.formOnChange("username", value);
          }}
        />
        <TextInput
          textValue={this.props.profile.password}
          width="23.2rem"
          labelInline={false}
          label={this.props.conversion ? Resources.YourEmailAddress : Resources.Password}
          isPassword={!this.props.conversion}
          inputOnChange={e => {
            let value = e.target.value;
            this.props.formOnChange("password", value);
          }}
        />
        {this.props.displayModePicker ? (
          <SelectInput
            className="field"
            value={find(departments, i => i.value === this.props.profile.departmentId) || {}.value || Resources.Select}
            displayValue={(find(departments, i => i.value === this.props.profile.departmentId) || {}).department}
            optionValueKey="value"
            optionDisplayKey="department"
            options={departments}
            labelInline={false}
            label={this.props.conversion ? Resources.WhichAccountingSystem : Resources.Department}
            width={"23.2rem"}
            onSelectOption={option => {
              this.props.formOnChange("departmentId", option.value);
            }}
          />
        ) : null}
        {this.props.submitButton ? (
          <React.Fragment>
            <span className="signup-message">
              {`${Resources.BySignuingUpYourAreAgreeingTo} `}
              <span>{Resources.TermsAndConditions}</span>
              {` ${Resources.And} `}
              <span>{Resources.PrivacyPolicy}</span>.
            </span>
            <div className="submit-button">
              <button onClick={this.props.submitButtonOnClick} className="button-primary-square">
                {Resources.SignUp}
              </button>
            </div>
          </React.Fragment>
        ) : null}
      </Card>
    );
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(LoginInformationForm));
