import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";

import { hideModal } from "../store/modal-actions";

import Resources from "../lib/resources";
import { isEmpty } from "../lib/utils";

function ErrorModal({ errorMessage, customContent, validationErrors, title = Resources.ErrorDialogTitle }) {
  const showModal = useSelector(store => store.modal.showModal);
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => dispatch(hideModal())}
      className="custom-modal"
      overlayClassName="custom-modal-overlay"
    >
      <div className="custom-modal-header">{title}</div>
      <div className="custom-modal-divider" />
      <div className="custom-modal-content">
        <div className="mt-4">{errorMessage.replace("TODO:", "").trim()}</div>
        {!isEmpty(customContent) && <React.Fragment>{customContent}</React.Fragment>}
        {!isEmpty(validationErrors) && (
          <div className="mt-4">
            {Object.keys(validationErrors).map(key => (
              <div>
                {key}: {validationErrors[key]}
              </div>
            ))}
          </div>
        )}
        <div className="flex-row modal-buttons mt-4">
          <button className="btn button-green" onClick={() => dispatch(hideModal())}>
            {Resources.Ok}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ErrorModal;
