import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Resources from "../../../lib/resources";
import IconClose from "../../library/icons/iconClose";
import IconAttachment from "../../library/icons/iconAttachment";
import TableData from "../../library/tableData";

import { formatDate, formatCurrency, isEmpty, openUrlForDownload, find, getCurrencySymbol } from "../../../lib/utils";

import Modal from "react-modal";

import { dispatchToProps as moDP } from "../../../store/modal-actions";
import { dispatchToProps as ledgDP } from "../../../store/ledger-actions";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...ledgDP(dispatch)
});

class InvoicesFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFlyout: true
    };

    this.hideFlyout = this.hideFlyout.bind(this);
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  downloadAttachments(attachments, fileName) {
    if (attachments.length === 1) {
      openUrlForDownload(attachments[0].downloadUrl);
    } else if (attachments.length > 1) {
      this.props.displayNotification("downloadNotification");
      this.props.fetchAttachmentsZip(
        attachments.map(attachment => attachment.attachmentId),
        fileName
      );
    }
  }

  render() {
    const invoiceDateCol = {
      header: Resources.InvoiceDate.toLocaleUpperCase(),
      content: row => <span className="fw-500">{formatDate(row.issueDate, true, false)}</span>
    };

    const invoiceNumCol = {
      header: Resources.InvoiceNumber.toLocaleUpperCase(),
      content: row => {
        let hasAttachment = true || !isEmpty(row.attachments);

        return (
          <div className={`statements-invoice-id d-flex align-items-center ${hasAttachment ? "link" : ""}`}>
            <div className="flex-even overflow-ellipsis">
              {hasAttachment ? (
                <React.Fragment>
                  <button
                    data-test-id="invoices-flyout__attachments"
                    className="invoice-num-attachment"
                    onClick={() => {
                      this.downloadAttachments(row.attachments, Resources.InvoiceNAttachments(row.id) + ".zip");
                    }}
                  >
                    <span className="invoice-num-attachment-link overflow-ellipsis" title={row.id}>
                      {row.id}
                    </span>
                    <IconAttachment height={15} style={{ marginLeft: "0.33rem", minWidth: "1rem" }} />
                  </button>
                </React.Fragment>
              ) : (
                <span className="overflow-ellipsis" title={row.id}>
                  {row.id}
                </span>
              )}
            </div>
            {(row.isDisputed || row.hasPromissoryNote) && (
              <div className="d-flex flex-column justify-content-start flex-even">
                {row.isDisputed && (
                  <div>
                    <button
                      data-test-id="invoices-flyout__disputed"
                      className="button-indicator"
                      onClick={() => this.displayRelatedConversation(row.activeDispute)}
                      style={{ marginBottom: row.hasPromissoryNote ? "0.4rem" : "" }}
                    >
                      {Resources.Disputed}
                    </button>
                  </div>
                )}
                {row.hasPromissoryNote && (
                  <div>
                    <button
                      data-test-id="invoices-flyout__promised"
                      className="button-indicator"
                      onClick={() =>
                        this.displayRelatedConversation(
                          find(row.payments, payment => payment.paymentType === "Promise to Pay")
                        )
                      }
                    >
                      {Resources.Promised}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      }
    };

    const amountPaidCol = {
      header: Resources.AmountPaid.toLocaleUpperCase(),
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId)}</span>
          <span>{formatCurrency(row.amountPaid, "")}</span>
        </div>
      )
    };

    const datePaidCol = {
      header: Resources.DatePaid.toLocaleUpperCase(),
      content: row => <span>{!isEmpty(row.paidDate) ? formatDate(row.paidDate, true, false) : ""}</span>
    };

    let columns = [invoiceDateCol, invoiceNumCol, amountPaidCol, datePaidCol];

    const { payment = {} } = this.props;

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={this.props.hideFlyout}
        contentLabel="Example Modal"
        className="flyout dispute-flyout"
        overlayClassName="flyout-overlay"
        closeTimeoutMS={400}
      >
        <div className="flyout-heading">
          <div>{Resources.InvoicesAppliedToPayment(payment.id)}</div>
          <div onClick={this.hideFlyout} className="flyout-heading-close">
            <IconClose />
          </div>
        </div>
        <div className="flyout-content">
          <div className="flyout-table-container">
            <TableData
              noGrow
              noSort
              pagination
              rowHeight="4rem"
              data={payment.invoices}
              columns={columns}
              maxHeight={`${4 * payment.invoices.length + 2}rem`}
              rowClassName="statements-view-row no-hover"
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    ledgerStore: store.ledger
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(InvoicesFlyout));
