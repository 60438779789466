import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Resources from "../../../../lib/resources";
import { formatDate, isEmpty, copyToClipboard } from "../../../../lib/utils";

import Card from "../../../library/card";
import IconArchive from "../../../library/icons/iconArchive";
import IconPhone from "../../../library/icons/iconPhone";
import IconMapPin from "../../../library/icons/iconMapPin";
import IconLinkedIn from "../../../library/icons/iconLinkedIn";
import IconClipboard from "../../../library/icons/iconClipboard";
import IconMail from "../../../library/icons/iconMail";
import IconEdit from "../../../library/icons/iconEdit";
import IconShare from "../../../library/icons/iconShare";
import IconClose from "../../../library/icons/iconClose";

import { dispatchToProps as networkDP } from "../../../../store/network-actions";
import { dispatchToProps as modDP } from "../../../../store/modal-actions";
import { dispatchToProps as accountDP } from "../../../../store/accounts-actions";

import ContactUpdateForm from "../profileUpdate/contactUpdateForm";
import MainLoader from "../../../mainLoader";
import Modal from "react-modal";
import Dropdown from "../../../library/dropdown";

import Avatar from "../../../library/avatar";
import IconCheckCircle from "../../../library/icons/iconCheckCircle";
import IconBriefcase from "../../../library/icons/iconBriefcase";

const dispatchToProps = dispatch => ({
  ...networkDP(dispatch),
  ...modDP(dispatch),
  ...accountDP(dispatch)
});

class MyProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      showFlyout: true
    };
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.hideFlyout = this.hideFlyout.bind(this);
  }

  componentDidMount() {
    this.props.fetchMyProfile();
  }

  toggleEditMode() {
    this.setState({ editMode: !this.state.editMode });
    if (!this.state.editMode) {
      this.props.fetchMyProfile();
    }
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  render() {
    let selectedAccount = { ...this.props.networkStore.custContact, ...this.state.custContact };
    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={!this.state.showFlyout ? this.props.hideFlyout : null}
        closeTimeoutMS={400}
        contentLabel="Example Modal"
        className="flyout profile-managment-flyout"
        overlayClassName="flyout-overlay"
      >
        {this.props.networkStore.gettingCustContact ? (
          <MainLoader fullScreen={true} />
        ) : (
          <React.Fragment>
            {/* <ViewContentHeader myProfile={selectedAccount} /> */}
            <div className="profile-managment-header">
              <span>
                <Avatar
                  height={"3.4rem"}
                  imgURL={selectedAccount.profilePictureURL}
                  type="user"
                  objectName={selectedAccount.contactName}
                  className={"avatar"}
                ></Avatar>
              </span>
              <h2 className="my-profile-header">{selectedAccount.contactName}</h2>
              {selectedAccount.isLockstepVerified ? (
                <div className="lockstep-verified-checkmark-container">
                  <Dropdown
                    buttonContent={
                      <React.Fragment>
                        <IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />
                      </React.Fragment>
                    }
                  >
                    <div className="lockstep-verified-dropdown-content">{Resources.LockstepVerifiedInformation}</div>
                  </Dropdown>
                </div>
              ) : null}
              <div onClick={() => this.hideFlyout()} className="flyout-heading-close">
                <IconClose />
              </div>
            </div>

            {this.state.editMode ? (
              <ContactUpdateForm
                small={true}
                toggleEditMode={this.toggleEditMode}
                perspectiveId={this.props.perspectiveId}
              />
            ) : (
              <div>
                <div className="profile-management-button-container">
                  <Dropdown
                    buttonContent={
                      <React.Fragment>
                        <IconShare className="button-primary-icon" />
                        <span>{Resources.ShareProfile}</span>
                      </React.Fragment>
                    }
                    buttonClassName="button-primary-large-dropdown"
                  >
                    <Dropdown.Item
                      onClick={() => {
                        this.props.displayFlyout("shareFlyout", {
                          myProfile: selectedAccount,
                          companyId: this.props.companyId,
                          perspectiveId: this.props.perspectiveId,
                          entityGlobalKey: selectedAccount.entityGlobalKey
                        });
                      }}
                    >
                      <span className="share-dropdown-item">{Resources.SendInMessage}</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        this.props.fetchShareLink("ar", selectedAccount.entityGlobalKey).then(response => {
                          copyToClipboard(this.props.networkStore.shareLink);
                        });
                        this.props.displayNotification("copyToClipboardNotification");
                      }}
                    >
                      <span className="share-dropdown-item">{Resources.CopyLink}</span>
                    </Dropdown.Item>
                  </Dropdown>
                  <button onClick={() => this.toggleEditMode()} className="button-primary large">
                    <IconEdit className="button-primary-icon" />
                    {Resources.EditProfile}
                  </button>
                </div>
                <div className="profile-managment-card-container">
                  <div className="profile-container">
                    <Card className="account-profile__card">
                      <div className="account-profile__card__last-updated">
                        {Resources.UpdatedDate(formatDate(selectedAccount.updateDate))}
                      </div>
                      <div className="">
                        {!isEmpty(selectedAccount.title) && (
                          <div className="account-profile-item">
                            <div className="account-profile-item__icon">
                              <IconBriefcase height={22} />
                            </div>
                            <div className="account-profile-item__content">
                              {selectedAccount.title}, {this.props.accountsStore.selectedCompany.companyName}
                            </div>
                          </div>
                        )}
                        <div className="account-profile-item">
                          <div className="account-profile-item__icon">
                            <IconMail height={18} />
                          </div>
                          <div className="account-profile-item__content">{selectedAccount.emailAddr}</div>
                        </div>
                        {!isEmpty(selectedAccount.phone) && (
                          <div className="account-profile-item">
                            <div className="account-profile-item__icon">
                              <IconPhone height={22} />
                            </div>
                            <div className="account-profile-item__content">{selectedAccount.phone}</div>
                          </div>
                        )}
                        {!isEmpty(selectedAccount.fax) && (
                          <div className="account-profile-item">
                            <div className="account-profile-item__icon">
                              <IconArchive height={16} />
                            </div>
                            <div className="account-profile-item__content">{selectedAccount.fax}</div>
                          </div>
                        )}
                        {!isEmpty(
                          [
                            selectedAccount.addrLn1,
                            selectedAccount.city,
                            selectedAccount.state,
                            selectedAccount.postalcode,
                            selectedAccount.country
                          ].filter(value => !isEmpty(value))
                        ) && (
                          <div className="account-profile-item">
                            <div className="account-profile-item__icon">
                              <IconMapPin height={22} />
                            </div>
                            <div className="account-profile-item__content">
                              <div>{selectedAccount.addrLn1}</div>
                              <div>
                                {selectedAccount.city}, {selectedAccount.state} {selectedAccount.postalcode}
                              </div>
                              <div>{selectedAccount.country}</div>
                            </div>
                          </div>
                        )}
                        {!isEmpty(
                          [
                            selectedAccount.ShipToAddrLn1,
                            selectedAccount.ShipToCity,
                            selectedAccount.ShipToState,
                            selectedAccount.ShipToPostalcode,
                            selectedAccount.ShipToCountry
                          ].filter(value => !isEmpty(value))
                        ) && (
                          <div className="account-profile-item">
                            <div className="account-profile-item__icon"></div>
                            <div className="account-profile-item__content">
                              <div className="account-profile-item__header">Ship-to</div>
                              <div>{selectedAccount.ShipToAddrLn1}</div>
                              <div>
                                {selectedAccount.ShipToCity}, {selectedAccount.ShipToState}{" "}
                                {selectedAccount.ShipToPostalcode}
                              </div>
                              <div>{selectedAccount.ShipToCountry}</div>
                            </div>
                          </div>
                        )}
                        {!isEmpty(selectedAccount.linkedInProfile) && (
                          <div className="account-profile-item">
                            <div className="account-profile-item__icon">
                              <IconLinkedIn height={19} />
                            </div>
                            <div className="account-profile-item__content">
                              <a rel="norefferer noopener" href={selectedAccount.linkedInProfile}>
                                {selectedAccount.linkedInProfile}
                              </a>
                            </div>
                          </div>
                        )}
                        {!isEmpty(selectedAccount.notes) && (
                          <div className="account-profile-item">
                            <div className="account-profile-item__icon">
                              <IconClipboard height={20} />
                            </div>
                            <div className="account-profile-item__content">{selectedAccount.notes}</div>
                          </div>
                        )}
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network,
    modalStore: store.modal,
    accountsStore: store.accounts
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(MyProfileView));
