import React, { Component } from "react";
import { connect } from "react-redux";
import TextInput from "./library/textInput";
import { dispatchToProps as compDP } from "../store/company-actions";
import { dispatchToProps as mangDP } from "../store/manage-actions";
import { dispatchToProps as regDP } from "../store/registration-actions";
import Resources from "../lib/resources";
import { isEmpty, find } from "../lib/utils";
import SelectInput from "./library/selectInput";

const dispatchToProps = dispatch => ({
  ...compDP(dispatch),
  ...mangDP(dispatch),
  ...regDP(dispatch)
});

class SMTPEmailSettings extends Component {
  state = {
    errorMsgs: "",
    SMTPConfigData: {
      configurationData: {
        Host: "",
        Port: "",
        Username: "",
        Password: "",
        Timeout: "",
        ConfirmPassword: "",
        Domain: "",
        SendFrom: "",
        UseSSL: false,
        UseStartTLS: false,
        AuthenticationMethod: "",
        ConnectionTimeout: "",
        RetrievalTimeout: ""
      },
      emailConfigurationTypeId: ""
    },
    isEnabled: false
  };

  componentDidMount() {
    this.setPage();
  }

  setPage = () => {
    let { configData } = this.props;
    if (isEmpty(configData) === false) {
      let configurationData = configData.configurationData || "{}";
      let SMTP_ConfigData = JSON.parse(configurationData);
      this.setState({
        SMTPConfigData: {
          configurationData: {
            ...SMTP_ConfigData,
            connectionSecurity: SMTP_ConfigData.UseSSL ? "ssl" : SMTP_ConfigData.UseStartTLS ? "startTLS" : "none",
            ConfirmPassword: SMTP_ConfigData.Password
          }
        },
        isEnabled: true
      });
    }
  };

  setSMTPConfigInput = (key, value) => {
    this.setState({
      SMTPConfigData: {
        ...this.state.SMTPConfigData,
        configurationData: {
          ...this.state.SMTPConfigData.configurationData,
          [key]: value
        }
      }
    });
  };

  setSMTPConfigData = () => {
    let { SMTPConfigData } = this.state;
    let { getAllEmailConfigTypes, setCompanyEmailConfig, perspectiveId, companyId } = this.props;
    let allEmailConfigTypes = getAllEmailConfigTypes();
    let SMTP_Type =
      find(allEmailConfigTypes, item => {
        return item.emailConfigurationTypeName === "SMTP";
      }) || {};

    let configData = {
      ...SMTPConfigData,
      configurationData: {
        ...SMTPConfigData.configurationData,
        UseSSL: SMTPConfigData.configurationData.connectionSecurity === "ssl",
        UseStartTLS: SMTPConfigData.configurationData.connectionSecurity === "starttls"
      }
    };
    delete configData.configurationData.connectionSecurity;
    configData.emailConfigurationTypeId = SMTP_Type.emailConfigurationTypeId;
    configData.perspectiveId = perspectiveId;
    configData.configurationData = JSON.stringify(configData.configurationData);
    setCompanyEmailConfig(companyId, configData);
  };

  render() {
    let { errorMsgs } = this.state;
    let { isVerified, isVisible } = this.props;

    if (isVisible !== true) {
      return null;
    }
    const authenticationMethods = [
      { value: "NONE" },
      { value: "CRAM-MDS" },
      { value: "NTLM" },
      { value: "PLAIN" },
      { value: "LOGIN" }
    ];
    const connectionSecurity = [
      { value: "ssl", test: "SSL" },
      { value: "starttls", test: "StartTLS" },
      { value: "none", test: "None (not recommended)" }
    ];
    const sslProtocols = [{ value: "NONE" }, { value: "TLS 1.0" }, { value: "SSL 3.0" }, { value: "PCT 1.0" }];
    return (
      <div>
        <div className="d-inline-block mb-3">
          {Resources.SMTPMethod}
          <div className="d-inline-block">
            <h5 className={`d-inline-block ${isVerified ? "verified-color-font" : "unverified-color-font"}`}>
              {isVerified ? Resources.Verified : Resources.Unverified}
            </h5>
          </div>
        </div>
        <br />
        <div className="mb-3 input-label">{Resources.RequiredFields}</div>

        <div>
          {errorMsgs && <span className="warning-color-font ml-2">{errorMsgs}</span>}
          <TextInput
            fsObscure
            className="mb-4"
            labelInline={false}
            inline
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            textValue={this.state.SMTPConfigData.configurationData.Host}
            label={Resources.ServerName}
            placeholder={Resources.HostAddressExample}
            inputOnChange={e => {
              this.setSMTPConfigInput("Host", e.target.value);
            }}
          />{" "}
          <br />
          <TextInput
            fsObscure
            labelInline={false}
            className="mb-4"
            inline
            /*disabled={puttingConnectorConfig}*/
            textValue={this.state.SMTPConfigData.configurationData.Port}
            label={Resources.Port}
            placeholder={Resources.Port}
            width="19em"
            inputOnChange={e => {
              this.setSMTPConfigInput("Port", e.target.value);
            }}
          />{" "}
          <br />
          <TextInput
            className="mb-4"
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            inline
            labelInline={false}
            textValue={this.state.SMTPConfigData.configurationData.Username}
            label={Resources.Username}
            placeholder={Resources.Username}
            inputOnChange={e => {
              this.setSMTPConfigInput("Username", e.target.value);
            }}
          />{" "}
          <br />
          <TextInput
            fsObscure
            inline
            labelInline={false}
            isPassword={true}
            className="mb-4"
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            textValue={this.state.SMTPConfigData.configurationData.Password}
            label={Resources.Password}
            placeholder={Resources.Password}
            inputOnChange={e => {
              this.setSMTPConfigInput("Password", e.target.value);
            }}
            style={{ marginRight: "2.5rem" }}
          />
          <TextInput
            fsObscure
            inline
            labelInline={false}
            isPassword={true}
            className="mb-4"
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            textValue={this.state.SMTPConfigData.configurationData.ConfirmPassword}
            label={Resources.ConfirmPassword}
            placeholder={Resources.ConfirmPassword}
            inputOnChange={e => {
              this.setSMTPConfigInput("ConfirmPassword", e.target.value);
            }}
          />
          <div className="d-flex">
            <SelectInput
              className="mb-4"
              value={this.state.SMTPConfigData.configurationData.connectionSecurity}
              displayValue={
                (
                  find(
                    connectionSecurity,
                    option => option.value === this.state.SMTPConfigData.configurationData.connectionSecurity
                  ) || {}
                ).test
              }
              optionValueKey="value"
              optionDisplayKey="test"
              options={connectionSecurity}
              labelInline={false}
              label={Resources.ConnectionSecurity}
              width={"19rem"}
              onSelectOption={option => {
                this.setSMTPConfigInput("connectionSecurity", option.value);
              }}
              style={{ marginRight: "2.5rem", display: "inline-block" }}
            />
            {false && this.state.SMTPConfigData.configurationData.connectionSecurity === "ssl" && (
              <SelectInput
                className="mb-4"
                value={this.state.SMTPConfigData.configurationData.SslProtocol}
                displayValue={
                  (
                    find(
                      sslProtocols,
                      option => option.value === this.state.SMTPConfigData.configurationData.SslProtocol
                    ) || {}
                  ).value
                }
                optionValueKey="value"
                optionDisplayKey="test"
                options={sslProtocols}
                labelInline={false}
                label={Resources.SSLProtocol}
                id="perspective-imap-sslprotocol"
                width={"19rem"}
                onSelectOption={option => {
                  this.setSMTPConfigInput("SslProtocol", option.value);
                }}
                style={{ display: "inline-block" }}
              />
            )}
          </div>
          <div className="nav-tabs mb-4"></div>
          <div className="input-label mb-4">{Resources.OptionalFields}</div>
          <TextInput
            className="mb-4"
            labelInline={false}
            inline
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            textValue={this.state.SMTPConfigData.configurationData.Domain}
            label={Resources.UserDomain}
            placeholder={Resources.UserDomain}
            inputOnChange={e => {
              this.setSMTPConfigInput("Domain", e.target.value);
            }}
          />{" "}
          <br />
          <TextInput
            labelInline={false}
            className="mb-4"
            inline
            /*disabled={puttingConnectorConfig}*/
            textValue={this.state.SMTPConfigData.configurationData.SendFrom}
            label={Resources.DefaultSendAsEmail}
            placeholder={Resources.DefaultSendAsEmail}
            width="19em"
            inputOnChange={e => {
              this.setSMTPConfigInput("SendFrom", e.target.value);
            }}
          />{" "}
          <br />
          <SelectInput
            className="mb-4"
            displayValue={
              (
                find(
                  authenticationMethods,
                  option => option.value === this.state.SMTPConfigData.configurationData.AuthenticationMethod
                ) || {}
              ).value
            }
            optionValueKey="value"
            optionDisplayKey="value"
            options={authenticationMethods}
            labelInline={false}
            label={Resources.AuthenticationMethod}
            width={"19.2rem"}
            onSelectOption={option => {
              this.setSMTPConfigInput("AuthenticationMethod", option.value);
            }}
          />
          <br />
          <TextInput
            inline
            labelInline={false}
            className="mb-4"
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            textValue={this.state.SMTPConfigData.configurationData.ConnectionTimeout}
            label={Resources.ConnectionTimeout}
            placeholder={Resources.ConnectionTimeout}
            inputOnChange={e => {
              this.setSMTPConfigInput("ConnectionTimeout", e.target.value);
            }}
            style={{ marginRight: "2.5rem" }}
          />
          <TextInput
            inline
            labelInline={false}
            className="mb-4"
            /*disabled={puttingConnectorConfig}*/
            width="19em"
            textValue={this.state.SMTPConfigData.configurationData.RetrievalTimeout}
            label={Resources.RetrievalTimeout}
            placeholder={Resources.RetrievalTimeout}
            inputOnChange={e => {
              this.setSMTPConfigInput("RetrievalTimeout", e.target.value);
            }}
          />
        </div>

        <div className="mt-2">
          <button
            className="button-secondary-square"
            style={{ minWidth: "14rem", marginRight: "1.3rem" }}
            onClick={this.props.onCancel}
          >
            {Resources.Cancel}
          </button>
          <button
            className="button-primary-square"
            style={{ minWidth: "14rem" }}
            onClick={e => {
              this.setSMTPConfigData();
              this.props.onSave();
            }}
            disabled={
              isEmpty(this.state.SMTPConfigData.configurationData.Host) ||
              isEmpty(this.state.SMTPConfigData.configurationData.Port) ||
              isEmpty(this.state.SMTPConfigData.configurationData.Username) ||
              isEmpty(this.state.SMTPConfigData.configurationData.Password) ||
              isEmpty(this.state.SMTPConfigData.configurationData.connectionSecurity) ||
              this.state.SMTPConfigData.configurationData.Password !==
                this.state.SMTPConfigData.configurationData.ConfirmPassword
            }
          >
            {Resources.Save}
          </button>
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    manage: store.manage,
    registration: store.registration
  };
};

export default connect(storeToProps, dispatchToProps)(SMTPEmailSettings);
