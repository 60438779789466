import React, { Component } from "react";

export default class MainLoader extends Component {
  render() {
    const spinner = <div className="full-screen-spinner" />;
    if (this.props.fullScreen === true) {
      return (
        <div className={`flex-center flex-align-center h-100 ${this.props.className ? this.props.className : ""}`}>
          {spinner}
        </div>
      );
    }
    else if (this.props.PaymentIsInProcess) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h5> Payment is in process, please wait... &nbsp;&nbsp;</h5>
          <div className="full-screen-spinner" style={{ width: "8px", fontSize: "8px" }} />
        </div>
      );
    }
    else if (this.props.customBackground) {
      return (
        <div className={`full-screen-spinner ${this.props.spinnerBackgroundColor}-background`} style={{ width: "3px", fontSize: "3px", top: "2px", color: "white" }} />
      );
    }
    return spinner;
  }

   
}

MainLoader.defaultProps ={
  spinnerBackgroundColor:"white"
}
