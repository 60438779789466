import { actions } from "./connector-actions";
import _ from "lodash";

const defaultConnectorState = {
  allSupportedConnectors: [],
  allSupportedConnectorsIsFetched: false,
  isGettingAllSupportedConnectors: false,

  connectorResources: {},
  connectorResourcesIsFetched: {},
  isFetchingConnectorResources: false,

  connectorRequests: [],
  isFetchingConnectorRequests: false,
  fetchingConnectorRequestsFailed: null,

  isCreatingConnectorRequest: false,
  createdConnectorRequest: false,
  createdConnectorRequests: [],

  companyConnectors: [],
  companyHasERPConnector: false,
  isFetchingCompanyConnectors: false,
  fetchedCompanyConnectors: false,
  fetchingCompanyConnectorsFailed: false,

  isSettingDefaultConnectorPermissions: false,

  disconnectingConnector: false,
  disconnectedConnector: false,
  disconnectConnectorFailed: false,

  isDeletingCompanyPermssions: false,
  deletingCompanyPermissionsFailed: null,

  isFetchingConnectorConfiguration: false,
  fetchingConnectorConfiguration: [],
  fetchingConnectorConfigurationFailed: null,
  connectorConfigs: {},

  isUpdatingConnectorConfiguration: false,

  isDeletingConnectorConfiguration: false,
  deletingConnectorConfigurationFailed: null,

  connectorActions: {},
  fetchedConnectorActions: {},

  connectorVersionData: {},
  connectorVersionDataIsFetched: {},
  isGettingConnectorVersionData: {},

  companyGrantedPermissions: [],
  companyGrantedPermissionsIsFetched: false,
  isGettingCompanyGrantedPermissions: false,

  isConnectorRegModalVisible: false,

  customConnectorInfo: {},
  isCustomConnectorRegInProgress: false,

  currentlyRunningRequests: [],
  specificConnectorResponseData: {},

  noCompanySelected: true,

  puttingConnectorConfig: false,
  putConnectorConfig: false,
  failedPutConnectorConfig: false,

  puttingConnectorPermissions: false,
  putConnectorPermissions: false,
  failedPutConnectorPermissions: false
};

function connectorReducer(state = defaultConnectorState, action) {
  switch (action.type) {
    case actions.GETTING_ALL_SUPPORTED_CONNECTORS:
      return {
        ...state,
        isGettingAllSupportedConnectors: true,
        allSupportedConnectorsIsFetched: false
      };
    case actions.GOT_ALL_SUPPORTED_CONNECTORS:
      return {
        ...state,
        isGettingAllSupportedConnectors: false,
        allSupportedConnectorsIsFetched: true,
        allSupportedConnectors: action.allSupportedConnectors
      };
    case actions.GET_ALL_SUPPORTED_CONNECTORS_FAILED:
      return {
        ...state,
        isGettingAllSupportedConnectors: false,
        allSupportedConnectorsIsFetched: false
      };
    case actions.GETTING_CONNECTOR_VERSION_DATA:
      return {
        ...state,
        isGettingConnectorVersionData: {
          ...state.isGettingConnectorVersionData,
          [action.connectorId]: true
        },
        connectorVersionDataIsFetched: {
          ...state.connectorVersionDataIsFetched,
          [action.connectorId]: false
        }
      };
    case actions.GOT_CONNECTOR_VERSION_DATA:
      return {
        ...state,
        isGettingConnectorVersionData: {
          ...state.isGettingConnectorVersionData,
          [action.connectorId]: false
        },
        connectorVersionDataIsFetched: {
          ...state.connectorVersionDataIsFetched,
          [action.connectorId]: true
        },
        connectorVersionData: {
          ...state.connectorVersionData,
          [action.connectorId]: action.connectorVersionData
        }
      };
    case actions.GET_CONNECTOR_VERSION_DATA_FAILED:
      return {
        ...state,
        isGettingConnectorVersionData: {
          ...state.isGettingConnectorVersionData,
          [action.connectorId]: false
        },
        connectorVersionDataIsFetched: {
          ...state.connectorVersionDataIsFetched,
          [action.connectorId]: true
        }
      };

    case actions.SETTING_DEFAULT_CONNECTOR_PERMISSIONS:
      return { ...state, isSettingDefaultConnectorPermissions: true };
    case actions.SET_DEFAULT_CONNECTOR_PERMISSIONS:
      return {
        ...state,
        isSettingDefaultConnectorPermissions: false,
        fetchingCompanyConnectorsFailed: null
      };
    case action.SET_DEFAULT_CONNECTOR_PERMISSIONS_FAILED:
      return { ...state, isSettingDefaultConnectorPermissions: false };

    case actions.GETTING_COMPANY_GRANTED_PERMISSIONS:
      return {
        ...state,
        isGettingCompanyGrantedPermissions: true,
        companyGrantedPermissionsIsFetched: false
      };
    case actions.GOT_COMPANY_GRANTED_PERMISSIONS:
      return {
        ...state,
        isGettingCompanyGrantedPermissions: false,
        companyGrantedPermissionsIsFetched: true,
        companyGrantedPermissions: action.companyGrantedPermissions
      };
    case actions.GET_COMPANY_GRANTED_PERMISSIONS_FAILED:
      return {
        ...state,
        isGettingCompanyGrantedPermissions: false,
        companyGrantedPermissionsIsFetched: false
      };
    case actions.TOGGLE_CONNECTOR_REGISTRATION_MODAL:
      return {
        ...state,
        isConnectorRegModalVisible: !state.isConnectorRegModalVisible
      };
    case actions.START_CUSTOM_CONNECTOR_REGISTRATION:
      return {
        ...state,
        isCustomRegInProgress: true,
        customConnectorInfo: action.connectorInfo
      };
    case actions.END_CUSTOM_CONNECTOR_REGISTRATION:
      return {
        ...state,
        isCustomConnectorRegInProgress: false,
        customConnectorInfo: {}
      };

    case actions.GETTING_CONNECTOR_SPECIFIC_REQUEST:
      let runningRequests = [...state.currentlyRunningRequests, action.currentParams];
      return { ...state, currentlyRunningRequests: runningRequests };

    case actions.GOT_CONNECTOR_SPECIFIC_REQUEST:
      return {
        ...state,
        currentlyRunningRequests: filterCompletedRequests(state, action.currentParams),
        specificConnectorResponseData: updateSpecificConnectorResponseData(
          state,
          action.currentParams,
          "SUCCESS",
          action.connectorSuccessResponse
        )
      };

    case actions.GET_CONNECTOR_SPECIFIC_REQUEST_FAILED:
      return {
        ...state,
        currentlyRunningRequests: filterCompletedRequests(state, action.currentParams),
        specificConnectorResponseData: updateSpecificConnectorResponseData(
          state,
          action.currentParams,
          "FAIL",
          action.connectorFailResponse
        )
      };

    case actions.CLEAR_SPECIFIC_CONNECTOR_DATA:
      return {
        ...state,
        specificConnectorResponseData: action.specificConnectorResponseData
      };

    case actions.FETCHING_CONNECTOR_REQUESTS:
      return {
        ...state,
        isFetchingConnectorRequests: true,
        fetchingConnectorRequestsFailed: null
      };
    case actions.FETCHED_CONNECTOR_REQUESTS:
      return {
        ...state,
        isFetchingConnectorRequests: false,
        fetchingConnectorRequestsFailed: false,
        connectorRequests: action.connectorRequests
      };
    case actions.FETCH_CONNECTOR_REQUESTS_FAILED:
      return {
        ...state,
        isFetchingConnectorRequests: false,
        fetchingConnectorRequestsFailed: true
      };

    case actions.FETCHING_CONNECTOR_RESOURCES:
      return { ...state, isFetchingConnectorResources: true };
    case actions.FETCHED_CONNECTOR_RESOURCES:
      return {
        ...state,
        isFetchingConnectorResources: false,
        connectorResourcesIsFetched: {
          ...state.connectorResourcesIsFetched,
          [action.connectorVersionId]: true
        },
        connectorResources: {
          ...state.connectorResources,
          [action.connectorVersionId]: action.connectorResources
        }
      };
    case actions.FETCH_CONNECTOR_RESOURCES_FAILED:
      return {
        ...state,
        isFetchingConnectorResources: false,
        connectorResourcesIsFetched: {
          ...state.connectorResourcesIsFetched,
          [action.connectorVersionId]: true
        }
      };

    case actions.FETCHING_CONNECTOR_ACTIONS:
      return { ...state, isFetchingConnectorActions: true };
    case actions.FETCHED_CONNECTOR_ACTIONS:
      return {
        ...state,
        isFetchingConnectorActions: false,
        fetchedConnectorActions: {
          ...state.fetchedConnectorActions,
          [action.connectorId]: true
        },
        connectorActions: {
          ...state.connectorActions,
          [action.connectorId]: action.connectorActions
        }
      };
    case actions.FETCH_CONNECTOR_ACTIONS_FAILED:
      return {
        ...state,
        isFetchingConnectorActions: false,
        fetchedConnectorActions: {
          ...state.fetchedConnectorActions,
          [action.connectorId]: true
        }
      };

    case actions.CREATING_CONNECTOR_REQUEST:
      return {
        ...state,
        isCreatingConnectorRequest: true,
        createdConnectorRequest: false
      };
    case actions.CREATED_CONNECTOR_REQUEST:
      return {
        ...state,
        isCreatingConnectorRequest: false,
        createdConnectorRequest: true,
        createdConnectorRequests: [...state.createdConnectorRequests, action.connectorName]
      };
    case actions.CREATE_CONNECTOR_REQUEST_FAILED:
      return { ...state, isCreatingConnectorRequest: false };

    case actions.FETCHING_COMPANY_CONNECTORS:
      return {
        ...state,
        isFetchingCompanyConnectors: true,
        fetchedCompanyConnectors: false,
        fetchingCompanyConnectorsFailed: false
      };
    case actions.FETCHED_COMPANY_CONNECTORS:
      return {
        ...state,
        isFetchingCompanyConnectors: false,
        fetchedCompanyConnectors: true,
        fetchingCompanyConnectorsFailed: false,
        companyConnectors: action.companyConnectors,
        companyHasERPConnector: action.companyHasERPConnector
      };
    case actions.FETCH_COMPANY_CONNECTORS_FAILED:
      return {
        ...state,
        isFetchingCompanyConnectors: false,
        fetchedCompanyConnectors: false,
        fetchingCompanyConnectorsFailed: true
      };

    case actions.DISCONNECTING_CONNECTOR:
      return {
        ...state,
        disconnectingConnector: true,
        disconnectedConnector: false,
        disconnectConnectorFailed: false
      };
    case actions.DISCONNECTED_CONNECTOR:
      return {
        ...state,
        disconnectingConnector: false,
        disconnectedConnector: true,
        disconnectConnectorFailed: false
      };
    case actions.DISCONNECT_CONNECTOR_FAILED:
      return {
        ...state,
        disconnectingConnector: false,
        disconnectedConnector: false,
        disconnectConnectorFailed: true
      };

    case actions.DELETING_COMPANY_PERMISSIONS:
      return {
        ...state,
        isDeletingCompanyPermssions: true,
        deletingCompanyPermissionsFailed: null
      };
    case actions.DELETED_COMPANY_PERMISSIONS:
      return {
        ...state,
        isDeletingCompanyPermssions: false,
        deletingCompanyPermissionsFailed: false,
        companyConnectors: [],
        fetchingCompanyConnectorsFailed: null
      };
    case actions.DELET_COMPANY_PERMISSIONS_FAILED:
      return {
        ...state,
        isDeletingCompanyPermssions: false,
        deletingCompanyPermissionsFailed: true
      };

    case actions.FETCHING_CONNECTOR_CONFIGURATION:
      return {
        ...state,
        isFetchingConnectorConfiguration: true,
        fetchingConnectorConfiguration: [...state.fetchingConnectorConfiguration, action.connectorVersionId],
        fetchingConnectorConfigurationFailed: null
      };
    case actions.FETCHED_CONNECTOR_CONFIGURATION:
      let newFetchingState = [...state.fetchingConnectorConfiguration];
      newFetchingState.splice(state.fetchingConnectorConfiguration.indexOf(action.connectorVersionId));
      return {
        ...state,
        isFetchingConnectorConfiguration: false,
        fetchingConnectorConfigurationFailed: false,
        fetchingConnectorConfiguration: newFetchingState,
        connectorConfigs: {
          ...state.connectorConfigs,
          [action.connectorVersionId]: action.connectorConfig
        }
      };
    case actions.FETCH_CONNECTOR_CONFIGURATION_FAILED:
      let newFetchingState1 = [...state.fetchingConnectorConfiguration];
      newFetchingState1.splice(state.fetchingConnectorConfiguration.indexOf(action.connectorVersionId));
      return {
        ...state,
        isFetchingConnectorConfiguration: false,
        fetchingConnectorConfigurationFailed: true,
        fetchingConnectorConfiguration: newFetchingState1
      };

    case actions.UPDATING_CONNECTOR_CONFIGURATION:
      return {
        ...state,
        isUpdatingConnectorConfiguration: true,
        updatingConnectorConfigurationFailed: null
      };
    case actions.UPDATED_CONNECTOR_CONFIGURATION:
      return {
        ...state,
        isUpdatingConnectorConfiguration: false,
        updatingConnectorConfigurationFailed: false,
        connectorConfigs: {
          ...state.connectorConfigs,
          [action.connectorVersionId]: action.connectorConfig
        },
        fetchingConnectorConfigurationFailed: null
      };
    case actions.UPDATE_CONNECTOR_CONFIGURATION_FAILED:
      return {
        ...state,
        isUpdatingConnectorConfiguration: false,
        updatingConnectorConfigurationFailed: true
      };

    case actions.DELETING_CONNECTOR_CONFIGURATION:
      return {
        ...state,
        isDeletingConnectorConfiguration: true,
        deletingConnectorConfigurationFailed: null
      };
    case actions.DELETED_CONNECTOR_CONFIGURATION:
      const connectorConfigs = { ...state.connectorConfigs };
      delete connectorConfigs[action.connectorVersionId];
      return {
        ...state,
        isDeletingConnectorConfiguration: false,
        deletingConnectorConfigurationFailed: false,
        connectorConfigs
      };
    case actions.DELETED_CONNECTOR_CONFIGURATION_FAILED:
      return {
        ...state,
        isDeletingConnectorConfiguration: false,
        deletingConnectorConfigurationFailed: true
      };

    case actions.PUTTING_CONNECTOR_CONFIGURATION:
      return {
        ...state,
        puttingConnectorConfig: true,
        putConnectorConfig: false,
        failedPutConnectorConfig: false
      };
    case actions.PUT_CONNECTOR_CONFIGURATION:
      return {
        ...state,
        puttingConnectorConfig: false,
        putConnectorConfig: true,
        failedPutConnectorConfig: false
      };
    case actions.FAILED_PUT_CONNECTOR_CONFIGURATION:
      return {
        ...state,
        puttingConnectorConfig: false,
        putConnectorConfig: false,
        failedPutConnectorConfig: true
      };

    case actions.PUTTING_CONNECTOR_PERMISSIONS:
      return {
        ...state,
        puttingConnectorPermissions: true,
        putConnectorPermissions: false,
        failedPutConnectorPermissions: false
      };
    case actions.PUT_CONNECTOR_PERMISSIONS:
      return {
        ...state,
        puttingConnectorPermissions: false,
        putConnectorPermissions: true,
        failedPutConnectorPermissions: false
      };
    case actions.FAILED_PUT_CONNECTOR_PERMISSIONS:
      return {
        ...state,
        puttingConnectorPermissions: false,
        putConnectorPermissions: false,
        failedPutConnectorPermissions: true
      };

    case actions.SELECT_COMPANY:
      // Prevent state from being reset when app loads
      if (state.noCompanySelected === true) {
        return { ...state, noCompanySelected: false };
      }
      return { ...defaultConnectorState, noCompanySelected: false };
    default:
      return state;
  }
}

function filterCompletedRequests(state, objectToFilter) {
  let data = state.currentlyRunningRequests.filter(requests => {
    return _.isEqual(requests, objectToFilter) !== true;
  });
  return data;
}

function updateSpecificConnectorResponseData(state, currentParams, responseState, response) {
  let cSRD = state.specificConnectorResponseData;
  let oppositeResponseState = responseState === "SUCCESS" ? "FAIL" : "SUCCESS";
  let newCSRD = null;
  let { reqType, endpoint } = currentParams;
  let responseData = {
    [endpoint]: {
      [reqType]: {
        [responseState]: response,
        [oppositeResponseState]: undefined,
        RESPONSE_STATE: responseState
      }
    }
  };

  if (cSRD[endpoint] !== undefined) {
    responseData = { ...cSRD[endpoint], ...responseData };
  }

  newCSRD = { ...cSRD, ...responseData };

  return newCSRD;
}

export default connectorReducer;
