import React, { Component } from "react";

import Resources from "../../../lib/resources";

export default class StatusChangeThreadItem extends Component {
  render() {
    const { posterName, statusName, dateText } = this.props;

    return (
      <div className="conversation-thread-item">
        <div className="conversation-thread-item__body">
          <div className="conversation-thread-item__body__header">
            <span className="conversation-thread-item__body__badge conversation-thread-item__body__badge--status">
              {Resources.StatusChangedByTo(posterName, statusName.toLowerCase())}
            </span>
            <span className="thread-item-age">{dateText}</span>
          </div>
        </div>
      </div>
    );
  }
}
