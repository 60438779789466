import React from "react";

function PaperSVG() {
  return (
    <div className="svg-container">
      <svg viewBox="0 0 150 93">
        <g fill="none" fillRule="evenodd">
          <path
            fill="#F4F3FF"
            d="M143.712575 14C147.185024 14 150 16.8206061 150 20.3s-2.814976 6.3-6.287425 6.3h-35.928144c3.472449 0 6.287425 2.8206061 6.287425 6.3s-2.814976 6.3-6.287425 6.3h19.760479c3.472449 0 6.287425 2.8206061 6.287425 6.3s-2.814976 6.3-6.287425 6.3h-9.138245c-4.378305 0-7.927623 2.8206061-7.927623 6.3 0 2.3195959 1.796407 4.4195959 5.389221 6.3 3.472449 0 6.287426 2.8206061 6.287426 6.3s-2.814977 6.3-6.287426 6.3H41.3173653c-3.4724491 0-6.2874252-2.8206061-6.2874252-6.3s2.8149761-6.3 6.2874252-6.3H6.28742515C2.81497612 64.4 0 61.5793939 0 58.1s2.81497612-6.3 6.28742515-6.3H42.2155689c3.472449 0 6.2874251-2.8206061 6.2874251-6.3s-2.8149761-6.3-6.2874251-6.3H19.760479c-3.472449 0-6.2874251-2.8206061-6.2874251-6.3s2.8149761-6.3 6.2874251-6.3h35.9281438c-3.4724491 0-6.2874252-2.8206061-6.2874252-6.3s2.8149761-6.3 6.2874252-6.3h88.0239522zm0 25.2C147.185024 39.2 150 42.0206061 150 45.5s-2.814976 6.3-6.287425 6.3c-3.472449 0-6.287425-2.8206061-6.287425-6.3s2.814976-6.3 6.287425-6.3z"
          />
          <path
            fill="#FFF"
            stroke="#BEB6FF"
            strokeLinecap="round"
            strokeWidth="2.5"
            d="M95.7997022 12.62491005l8.37823727 61.12595624.75296598 6.13241583c.24257025 1.97557616-1.15735596 3.77313095-3.12682222 4.014951l-52.59900267 6.45834545c-1.96946625.24182005-3.76267614-1.16366693-4.00524639-3.1392431l-8.12311678-66.15747709c-.12128512-.98778808.57867779-1.88656545 1.56341091-2.00747548l.01866086-.00229126 4.36383219-.48926548m3.52863502-.39570485l4.12012099-.46203468"
          />
          <path
            fill="#F4F3FF"
            d="M93.53892984 16.46035638l7.56247975 55.39623731.68026499 5.55750183c.21914941 1.79036582-1.03295711 3.41784148-2.79665825 3.63506855L51.8814319 86.85069975c-1.76370117.21722688-3.37111907-1.0580541-3.59026878-2.8484199l-7.2963011-59.60795604c-.1326437-1.09598772.64648323-2.09322587 1.74200471-2.22966686l5.62520337-.6928309"
          />
          <path
            fill="#FFF"
            stroke="#BEB6FF"
            strokeWidth="2.5"
            d="M98.1941239 1.25c.7339423 0 1.4374326.29338468 1.9538991.81485715h0l11.805876 11.92030445c.509999.5149417.796101 1.2103923.796101 1.9351429h0V72c0 .7593915-.307804 1.4468915-.805456 1.9445436C111.446892 74.4421958 110.759392 74.75 110 74.75h0-52c-.7593915 0-1.4468915-.3078042-1.9445436-.8054564C55.5578042 73.4468915 55.25 72.7593915 55.25 72h0V4c0-.75939153.3078042-1.44689153.8054564-1.94454365C56.5531085 1.55780423 57.2406085 1.25 58 1.25h0z"
          />
          <path
            stroke="#BEB6FF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
            d="M64 15.4519231h23.5813953M64 26.2980769h39m-39 11.75h39m-39 11.75h39m-39 11.75h23.5813953"
          />
        </g>
      </svg>
    </div>
  );
}

export default PaperSVG;
