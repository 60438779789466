import React, { Component } from "react";
import { Switch, Redirect, withRouter, Route } from "react-router-dom";
import { connect } from "react-redux";

import Resources from "../../../../lib/resources";
import { formatDate, find, updateSelectedRows, isEmpty } from "../../../../lib/utils";
import { guidRegex } from "../../../../lib/validation";

import SummaryCard from "../../../library/summaryCard";
import Card from "../../../library/card";
import AutoCompleteInput from "../../../library/autoCompleteInput";
import TableData from "../../../library/tableData";
import Dropdown from "../../../library/dropdown";
import ViewContentHeader from "../viewContentHeader";
import AccountView from "./accountView";
import ContactView from "./contactView";
import { dispatchToProps as netDP } from "../../../../store/network-actions";
import { dispatchToProps as modDP } from "../../../../store/modal-actions";
import IconSend from "../../../library/icons/iconSend";
import IconFilter from "../../../library/icons/iconFilter";
import ContactsTable from "../../contactsTable";
import Avatar from "../../../library/avatar";
import IconCheckCircle from "../../../library/icons/iconCheckCircle";
import FilterBuilder from "../../../library/filterBuilder";
import IconAlertCircle from "../../../library/icons/iconAlertCircle";
import IconLock from "../../../library/icons/iconLock";
import IconUnlock from "../../../library/icons/iconUnlock";
import { dispatchToProps as persDP } from "../../../../store/perspectives-actions";
import IconPlusCircle from "../../../library/icons/iconPlusCircle";
import IconAward from "../../../library/icons/iconAward";
import IconArchive from "../../../library/icons/iconArchive";
import Tooltip from "../../../library/tooltip";

const dispatchToProps = dispatch => ({
  ...netDP(dispatch),
  ...modDP(dispatch),
  ...persDP(dispatch)
});
class NetworkView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAccountKeys: [],
      selectedAccountRows: [],

      selectedArchivedAccountKeys: [],
      selectedArchivedAccountRows: [],

      selectedContactKeys: [],
      selectedContactRows: [],

      selectedArchivedContactKeys: [],
      selectedArchivedContactRows: [],

      accountsSearchTerm: "",
      accountsSearchLoadedPage: 1,
      contactsSearchTerm: "",
      contactsSearchLoadedPage: 1,

      displayAccountSearchResults: false,
      displayContactSearchResults: false,

      filters: { accounts: [], contacts: [] },
      filterStrings: { accounts: "", contacts: "" }
    };
  }

  getAccountsFilterOptions() {
    return [
      {
        filterKey: this.props.selectedPerspectiveType === "vendors" ? "VendorName" : "CustName",
        filterDescription: Resources.ProfileName,
        filterCriteriaOptions: [
          { optionKey: "=", optionDescription: Resources.EqualTo },
          { optionKey: "LIKE", optionDescription: Resources.Contains }
        ],
        filterValueType: "string"
      },
      {
        filterKey: "EmailAddr",
        filterDescription: Resources.EmailAddress,
        filterCriteriaOptions: [
          { optionKey: "=", optionDescription: Resources.EqualTo },
          { optionKey: "LIKE", optionDescription: Resources.Contains }
        ],
        filterValueType: "string"
      },
      {
        filterKey: "Phone",
        filterDescription: Resources.PhoneNumber,
        filterCriteriaOptions: [
          { optionKey: "=", optionDescription: Resources.EqualTo },
          { optionKey: "LIKE", optionDescription: Resources.Contains }
        ],
        filterValueType: "string"
      },
      {
        filterKey: "ProfileUpdateDate",
        filterDescription: Resources.LastUpdated,
        filterCriteriaOptions: [{ optionKey: "=", optionDescription: Resources.EqualTo }],
        filterValueType: "date"
      },
      {
        filterKey: "IsLockstepVerified",
        filterDescription: Resources.LockstepVerified,
        filterCriteriaOptions: [{ optionKey: "=", optionDescription: Resources.EqualTo }],
        filterValueType: "bool"
      },

      {
        filterKey: "BillToAddress",
        filterDescription: Resources.BillTo,
        filterCriteriaOptions: [
          { optionKey: "=", optionDescription: Resources.EqualTo },
          { optionKey: "LIKE", optionDescription: Resources.Contains }
        ],
        filterValueType: "address",
        addressFilterKeys: [
          "BillToAddrLn1",
          "BillToAddrLn2",
          "BillToCity",
          "BillToState",
          "BillToPostalCode",
          "BillToCountry"
        ]
      },

      {
        filterKey: "ShipToAddress",
        filterDescription: Resources.ShipTo,
        filterCriteriaOptions: [
          { optionKey: "=", optionDescription: Resources.EqualTo },
          { optionKey: "LIKE", optionDescription: Resources.Contains }
        ],
        filterValueType: "address",
        addressFilterKeys: [
          "MailToAddrLn1",
          "MailToAddrLn2",
          "MailToCity",
          "MailToState",
          "MailToPostalCode",
          "MailToCountry"
        ]
      },

      {
        filterKey: "EntityClassification",
        filterDescription: Resources.EntityClassification,
        filterCriteriaOptions: [
          { optionKey: "=", optionDescription: Resources.EqualTo },
          { optionKey: "LIKE", optionDescription: Resources.Contains }
        ],
        filterValueType: "string"
      }
    ];
  }

  getContactsFilterOptions() {
    return [
      {
        filterKey: "ContactName",
        filterDescription: Resources.ProfileName,
        filterCriteriaOptions: [
          { optionKey: "=", optionDescription: Resources.EqualTo },
          { optionKey: "LIKE", optionDescription: Resources.Contains }
        ],
        filterValueType: "string"
      },
      {
        filterKey: "EmailAddr",
        filterDescription: Resources.EmailAddress,
        filterCriteriaOptions: [
          { optionKey: "=", optionDescription: Resources.EqualTo },
          { optionKey: "LIKE", optionDescription: Resources.Contains }
        ],
        filterValueType: "string"
      },
      {
        filterKey: this.props.selectedPerspectiveType === "vendors" ? "VendorName" : "CustName",
        filterDescription: Resources.CompanyName,
        filterCriteriaOptions: [
          { optionKey: "=", optionDescription: Resources.EqualTo },
          { optionKey: "LIKE", optionDescription: Resources.Contains }
        ],
        filterValueType: "string"
      },
      {
        filterKey: "ContactRole",
        filterDescription: Resources.Role,
        filterCriteriaOptions: [
          { optionKey: "=", optionDescription: Resources.EqualTo },
          { optionKey: "LIKE", optionDescription: Resources.Contains }
        ],
        filterValueType: "string"
      },
      {
        filterKey: "ProfileUpdateDate",
        filterDescription: Resources.LastUpdated,
        filterCriteriaOptions: [{ optionKey: "=", optionDescription: Resources.EqualTo }],
        filterValueType: "date"
      },
      {
        filterKey: "IsLockstepVerified",
        filterDescription: Resources.LockstepVerified,
        filterCriteriaOptions: [{ optionKey: "=", optionDescription: Resources.EqualTo }],
        filterValueType: "bool"
      },
      {
        filterKey: "ContactTitle",
        filterDescription: Resources.Title,
        filterCriteriaOptions: [
          { optionKey: "=", optionDescription: Resources.EqualTo },
          { optionKey: "LIKE", optionDescription: Resources.Contains }
        ],
        filterValueType: "string"
      }
    ];
  }

  componentDidMount() {
    this.props.fetchAccountList(this.props.match.params.perspectiveId);
    this.props.fetchContactList(this.props.match.params.perspectiveId);
    this.props.fetchArchivedAccountList(this.props.match.params.perspectiveId);
    this.props.fetchArchivedContactList(this.props.match.params.perspectiveId);
  }

  componentDidUpdate(prevProps) {
    const { networkStore, pageRowCount, match } = this.props;

    if (prevProps && !isEmpty(prevProps.match.params.selectedItem) && isEmpty(match.params.selectedItem)) {
      this.props.fetchAccountList(this.props.match.params.perspectiveId, {
        skip: (networkStore.loadedAccountListPage - 1) * pageRowCount
      });
      this.props.fetchContactList(this.props.match.params.perspectiveId, {
        skip: (networkStore.loadedContactListPage - 1) * pageRowCount
      });
      this.props.fetchArchivedAccountList(this.props.match.params.perspectiveId);
      this.props.fetchArchivedContactList(this.props.match.params.perspectiveId);
    }
  }

  toggleSelectRowAccounts(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedAccountKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedAccountRows);
    this.setState({ selectedAccountKeys: newSelectedKeys, selectedAccountRows: newSelectedRows });
  }

  toggleSelectRowContacts(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedContactKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedContactRows);
    this.setState({ selectedContactKeys: newSelectedKeys, selectedContactRows: newSelectedRows });
  }

  toggleSelectRowArchivedAccounts(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedArchivedAccountKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedArchivedAccountRows);
    this.setState({ selectedArchivedAccountKeys: newSelectedKeys, selectedArchivedAccountRows: newSelectedRows });
  }

  toggleSelectRowArchivedContacts(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedArchivedContactKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedArchivedContactRows);
    this.setState({ selectedArchivedContactKeys: newSelectedKeys, selectedArchivedContactRows: newSelectedRows });
  }

  addFilter(table) {
    this.setState({
      filters: {
        ...this.state.filters,
        [table]: [
          ...this.state.filters[table],
          (table === "accounts" ? this.getAccountsFilterOptions() : this.getContactsFilterOptions())[0]
        ]
      }
    });
  }

  updateFilter(table, i, filter) {
    const newFiltersTable = [...this.state.filters[table]];
    if (i < newFiltersTable.length) {
      newFiltersTable[i] = filter;
    } else {
      newFiltersTable.push(filter);
    }
    this.setState({
      filters: {
        ...this.state.filters,
        [table]: newFiltersTable
      }
    });
  }

  deleteFilter(table, i) {
    const newFiltersTable = [...this.state.filters[table]];
    if (i < newFiltersTable.length) {
      newFiltersTable.splice(i, 1);
    }
    this.setState({
      filters: {
        ...this.state.filters,
        [table]: newFiltersTable
      }
    });
  }

  clearFilters(table) {
    this.setState({
      filters: {
        ...this.state.filters,
        [table]: []
      }
    });
  }

  updateFilterString(table, filterString) {
    if (this.state.filterStrings[table] === filterString || filterString === undefined) {
      return;
    }
    this.setState({ filterStrings: { ...this.state.filterStrings, [table]: filterString } });
  }

  render() {
    const { match = { params: {} }, gettingNetworkAccountList, gettingNetworkContactList, networkStore } = this.props;
    const {
      selectedAccountKeys,
      selectedContactKeys,
      selectedArchivedAccountKeys,
      selectedArchivedContactKeys
    } = this.state;
    const accountList = this.props.getAccountList(match.params.perspectiveId);
    const contactList = this.props.getContactList(match.params.perspectiveId);
    const archivedAccountList = this.props.getArchivedAccountList(match.params.perspectiveId);
    const archivedContactList = this.props.getArchivedContactList(match.params.perspectiveId);

    let summaryItemsAccounts = [
      { description: Resources.TotalNumberOfAccounts, value: accountList.count },
      {
        description: (
          <React.Fragment>
            <div className="lockstep-verified-check-container ">
              {Resources.LockstepVerifiedAccounts}
              <div className="lockstep-verified-checkmark-container">
                <Dropdown buttonContent={<IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />}>
                  <div className="lockstep-verified-dropdown-content">{Resources.LockstepVerifiedInformation}</div>
                </Dropdown>
              </div>
            </div>
          </React.Fragment>
        ),

        value: accountList.totalLockstepVerified
      },
      { description: Resources.UnverifiedAccounts, value: accountList.totalLockstepUnVerified }
    ];

    let summaryItemsArchivedAccounts = [
      { description: Resources.TotalNumberOfArchivedAccounts, value: archivedAccountList.count }
    ];

    let summaryItemsContacts = [
      { description: Resources.TotalNumberOfContacts, value: contactList.count },
      {
        description: (
          <React.Fragment>
            <div className="lockstep-verified-check-container ">
              {Resources.LockstepVerifiedContacts}
              <div className="lockstep-verified-checkmark-container">
                <Dropdown buttonContent={<IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />}>
                  <div className="lockstep-verified-dropdown-content">{Resources.LockstepVerifiedInformation}</div>
                </Dropdown>
              </div>
            </div>
          </React.Fragment>
        ),
        value: contactList.totalLockstepVerified
      },
      { description: Resources.UnverifiedContacts, value: contactList.totalLockstepUnVerified }
    ];

    let summaryItemsArchivedContacts = [
      { description: Resources.TotalNumberOfArchivedContacts, value: archivedContactList.count }
    ];

    let baseAccountColumns = [
      {
        type: "rowSelect",
        name: "rowSelect",
        justify: "center",
        header: "",
        content: row => (
          <Avatar
            height={"3rem"}
            imgURL={row.logoURL}
            type="account"
            objectName={row.companyName || row.custName || row.vendorName}
          ></Avatar>
        ),
        width: "4.22rem"
      },
      {
        header: Resources.Name.toLocaleUpperCase(),
        name: "name",
        content: row => {
          return (
            <React.Fragment>
              <div className="lockstep-verified-check-container">
                <span>{row.displayName || row.custName || row.vendorName}</span>
                {row.isLockstepVerified ? (
                  <div className="lockstep-verified-checkmark-container">
                    <Dropdown
                      buttonContent={<IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />}
                    >
                      <div className="lockstep-verified-dropdown-content">{Resources.LockstepVerifiedInformation}</div>
                    </Dropdown>
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          );
        },
        width: "27%"
      },
      {
        header: Resources.EmailAddress.toLocaleUpperCase(),
        name: "email",
        content: row =>
          row.emailAddr === "" || row.emailAddr === null ? (
            <div className="missing-email-address-container">
              <IconAlertCircle height={20} />
              <span>{Resources.MissingEmailAddress}</span>
            </div>
          ) : (
            row.emailAddr
          ),
        width: "25%"
      },
      { header: Resources.PhoneNumber.toLocaleUpperCase(), name: "phone", content: row => row.phone, width: "25%" }
    ];

    let accountColumns = [
      ...baseAccountColumns,
      {
        header: Resources.LastUpdated.toLocaleUpperCase(),
        name: "last-updated",
        content: row => formatDate(row.profileUpdateDate || row.ContactInsertDate || null),
        width: "15%"
      }
    ];

    let accountHoverColumns = [
      ...baseAccountColumns,
      {
        header: "",
        name: "hover-buttons",
        content: row => {
          return (
            <div className="network-table-hover-icon-container">
              <Tooltip
                noHideDelay
                buttonContent={
                  <button
                    disabled={row.emailAddr === undefined || row.emailAddr === ""}
                    className="button-action-icon"
                    onClick={e => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      this.props.sendAccountProfileUpdateRequest([row.entityGlobalKey]).then(response => {
                        this.props.fetchActivityList(match.params.perspectiveId, {
                          top: 100,
                          skip: 0
                        });
                      });
                      this.props.displayNotification("profileUpdateRequestSentNotification");
                    }}
                  >
                    <IconSend height={20} />
                  </button>
                }
                text={Resources.RequestProfileUpdate}
              />
              <Tooltip
                noHideDelay
                buttonContent={
                  <button
                    className="button-action-icon"
                    onClick={e => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      let mode = this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar";
                      this.props
                        .toggleAccountArchived(
                          match.params.perspectiveId,
                          row.entityGlobalKey,
                          match.params.subView === "accounts",
                          mode
                        )
                        .then(response => {
                          this.props.fetchAccountList(match.params.perspectiveId);
                          this.props.fetchArchivedAccountList(match.params.perspectiveId);
                        });
                    }}
                  >
                    <IconArchive height={20} />
                  </button>
                }
                text={Resources.Archive}
              />
            </div>
          );
        },
        width: "10%"
      }
    ];

    let subViews = [
      { subView: "accounts", display: Resources.Accounts.toLocaleUpperCase() },
      { subView: "contacts", display: Resources.Contacts.toLocaleUpperCase() },
      { subView: "accountArchive", display: Resources.ArchivedAccounts.toLocaleUpperCase() },
      { subView: "contactArchive", display: Resources.ArchivedContacts.toLocaleUpperCase() }
    ];

    const accountTableHeaderButtons = (
      <div className="table-data-card-header-buttons">
        <button
          className="button-primary"
          disabled={this.state.selectedAccountRows.some(
            i => i.emailAddr === "" || i.emailAddr === undefined || i.emailAddr === null
          )}
          onClick={() => {
            this.props.sendAccountProfileUpdateRequest([...selectedAccountKeys]).then(response => {
              this.props.fetchOpenProfileUpdateRequests(match.params.perspectiveId);
              this.props.fetchOpenOnboardingRequests(match.params.perspectiveId);
              this.props.fetchActivityList(match.params.perspectiveId, {
                top: 100,
                skip: 0
              });
            });
            this.props.displayNotification("sendProfileUpdateRequestNotifiation");
          }}
        >
          <IconSend height={22} className="button-primary-icon" />
          {Resources.RequestProfileUpdate}
        </button>
        {/* <button className="button-primary">
          <IconMail height={22} className="button-primary-icon" />
          {Resources.SendMessage}
        </button>
        <button className="button-primary">
          <IconTrash height={22} className="button-primary-icon" />
          {Resources.Remove}
        </button> */}
        <button
          className="button-primary"
          onClick={() => {
            let rows =
              match.params.subView === "accounts"
                ? this.state.selectedAccountRows
                : this.state.selectedArchivedAccountRows;
            let mode = this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar";
            rows.forEach(i => {
              this.props
                .toggleAccountArchived(
                  match.params.perspectiveId,
                  i.entityGlobalKey,
                  match.params.subView === "accounts",
                  mode
                )
                .then(response => {
                  this.props.fetchAccountList(match.params.perspectiveId);
                  this.props.fetchArchivedAccountList(match.params.perspectiveId);
                });
              this.setState({ selectedAccountRows: [], selectedAccountKeys: [] });

              match.params.subView === "accounts"
                ? this.props.displayNotification("archiveProfileNotification")
                : this.props.displayNotification("unArchiveProfileNotification");
            });
          }}
        >
          <IconArchive height={20} className="button-primary-icon" />
          {match.params.subView === "accounts" ? Resources.Archive : Resources.Unarchive}
        </button>
      </div>
    );

    const contactTableHeaderButtons = (
      <div className="table-data-card-header-buttons">
        <button
          className="button-primary"
          onClick={() => {
            this.props.sendContactProfileUpdateRequest([...selectedContactKeys]).then(response => {
              this.props.fetchOpenProfileUpdateRequests(match.params.perspectiveId);
              this.props.fetchOpenOnboardingRequests(match.params.perspectiveId);
              this.props.fetchActivityList(match.params.perspectiveId, {
                top: 100,
                skip: 0
              });
            });
            this.props.displayNotification("sendProfileUpdateRequestNotifiation");
          }}
        >
          <IconSend height={22} className="button-primary-icon" />
          {Resources.RequestProfileUpdate}
        </button>
        {/* <button
          disabled
          className="button-primary"
          onClick={() => {
            alert("send message");
          }}
        >
          <IconMessage height={22} className="button-primary-icon" />
          {Resources.SendMessage}
        </button> */}
        <button
          className="button-primary"
          onClick={() => {
            let keys =
              this.props.match.params.subView === "contacts" ? selectedContactKeys : selectedArchivedContactKeys;
            keys.map(i => {
              let currentContact;
              if (this.props.match.params.subView === "contacts") {
                currentContact = this.props.networkStore.contactsMap[i];
              } else {
                currentContact = find(this.props.networkStore.archivedContactList.value, j => j.entityGlobalKey === i);
              }
              if (currentContact.isPrivate) {
                this.props
                  .updateContactPrivacy(
                    this.props.match.params.perspectiveId,
                    i,
                    false,
                    this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                  )
                  .then(response => {
                    this.props.fetchContactList(this.props.match.params.perspectiveId);
                    this.props.fetchArchivedContactList(this.props.match.params.perspectiveId);
                  });
              }
              return null;
            });
            this.props.displayNotification("contactMarkedPrivateNotification");
          }}
        >
          <IconUnlock height={22} className="button-primary-icon" />
          {Resources.MarkAsPublic}
        </button>
        <button
          className="button-primary"
          onClick={() => {
            let keys =
              this.props.match.params.subView === "contacts" ? selectedContactKeys : selectedArchivedContactKeys;
            keys.map(i => {
              let currentContact;
              if (this.props.match.params.subView === "contacts") {
                currentContact = this.props.networkStore.contactsMap[i];
              } else {
                currentContact = find(this.props.networkStore.archivedContactList.value, j => j.entityGlobalKey === i);
              }
              if (!currentContact.isPrivate) {
                this.props
                  .updateContactPrivacy(
                    this.props.match.params.perspectiveId,
                    i,
                    true,
                    this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                  )
                  .then(response => {
                    this.props.fetchContactList(this.props.match.params.perspectiveId);
                    this.props.fetchArchivedContactList(this.props.match.params.perspectiveId);
                  });
              }
              return null;
            });
            this.props.displayNotification("contactMarkedPrivateNotification");
          }}
        >
          <IconLock height={22} className="button-primary-icon" />
          {Resources.MarkAsPrivate}
        </button>
        {this.props.match.params.subView === "contacts" ? (
          <button
            className="button-primary"
            onClick={() => {
              selectedContactKeys.forEach(i => {
                this.props
                  .markContactPrimary(
                    this.props.match.params.perspectiveId,
                    i,
                    this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                  )
                  .then(response => {
                    this.props.fetchContactList(this.props.match.params.perspectiveId);
                  });
              });
              this.props.displayNotification("markingAsPrimaryNotification");
            }}
          >
            <IconAward height={22} className="button-primary-icon" />
            {Resources.MarkAsPrimary}
          </button>
        ) : null}
        <button
          className="button-primary"
          onClick={() => {
            let mode = this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar";
            let rows =
              this.props.match.params.subView === "contacts"
                ? this.state.selectedContactRows
                : this.state.selectedArchivedContactRows;
            rows.forEach(i => {
              if (i.isPrimaryContact) {
                this.props.displayModal("setnewPrimaryContactDialog", {
                  name: i.contactName,
                  companyname: i.vendorName,
                  perspectiveId: this.props.match.params.perspectiveId,
                  account: i
                });
              } else {
                this.props
                  .toggleContactArchived(
                    match.params.perspectiveId,
                    i.entityGlobalKey,
                    match.params.subView === "contacts",
                    mode
                  )
                  .then(response => {
                    this.props.fetchContactList(match.params.perspectiveId);
                    this.props.fetchArchivedContactList(match.params.perspectiveId);
                  });
                this.setState({
                  selectedContactRows: [],
                  selectedContactKeys: [],
                  selectedArchivedContactKeys: [],
                  selectedArchivedContactRows: []
                });
                match.params.subView === "contacts"
                  ? this.props.displayNotification("archiveContactNotification")
                  : this.props.displayNotification("unArchiveContactNotification");
              }
            });
          }}
        >
          <IconArchive height={20} className="button-primary-icon" />
          {match.params.subView === "contacts" ? Resources.Archive : Resources.Unarchive}
        </button>
      </div>
    );

    return (
      <React.Fragment>
        <Switch>
          <Redirect
            exact
            from="/v2/:companyId/:perspectiveId/network"
            to="/v2/:companyId/:perspectiveId/network/accounts"
          />
          <Route
            exact
            path="/v2/:companyId/:perspectiveId/network/:subView(accounts|contacts|settings|accountArchive|contactArchive)"
          >
            <ViewContentHeader links={subViews} />
            <Switch>
              <Route exact path="/v2/:companyId/:perspectiveId/network/:subView(accounts)">
                <div className="view-content">
                  <SummaryCard summaryItems={summaryItemsAccounts}></SummaryCard>
                  <Card className="card-table">
                    <div className="table-data-card-header">
                      {!isEmpty(selectedAccountKeys) ? (
                        accountTableHeaderButtons
                      ) : (
                        <div className="table-data-card-header-search">
                          <AutoCompleteInput
                            loading={networkStore.gettingNetworkAccountSearchResults}
                            className="auto-complete-input__table-data-search"
                            placeholder={Resources.Search}
                            onChange={e => {
                              const accountsSearchTerm = e.target.value;
                              this.setState({ accountsSearchTerm });
                              this.props.debouncedFetchAccountSearchResults(match.params.perspectiveId, {
                                searchTerm: accountsSearchTerm
                              });
                            }}
                            callToActionButton={
                              <div
                                className="dropdown-item-clean dropdown-call-to-action"
                                onClick={() => this.setState({ displayAccountSearchResults: true })}
                              >
                                {Resources.SeeAllResultsFor(networkStore.accountSearchTerm)}
                              </div>
                            }
                            isShowingSearchResult={this.state.displayAccountSearchResults}
                            handleClearResult={() => {
                              this.setState({
                                accountsSearchTerm: "",
                                displayAccountSearchResults: false,
                                accountsSearchLoadedPage: 1
                              });
                            }}
                            text={this.state.accountsSearchTerm}
                            noResultsMessage={Resources.NoResultsFound}
                            showNoResultsMessage={networkStore.gotNetworkAccountSearchResults}
                            maxOptions={7}
                            handleSelectOption={option => {
                              this.setState({
                                accountsSearchTerm:
                                  this.props.selectedPerspectiveType === "vendors"
                                    ? option.vendorName
                                    : option.custName,
                                displayAccountSearchResults: true
                              });
                              this.props.fetchAccountSearchResults(match.params.perspectiveId, {
                                searchTerm:
                                  this.props.selectedPerspectiveType === "vendors" ? option.vendorName : option.custName
                              });
                            }}
                            renderOption={option =>
                              `${
                                this.props.selectedPerspectiveType === "vendors"
                                  ? option.displayName || option.vendorName
                                  : option.displayName || option.custName
                              }`
                            }
                            options={networkStore.accountSearchResults.value}
                            width="300px"
                          />
                          <Dropdown
                            buttonClassName="button-action-icon ml-2"
                            buttonContent={<IconFilter height="18"></IconFilter>}
                            isForm
                            disabled={this.state.displayAccountSearchResults}
                          >
                            <FilterBuilder
                              filters={this.state.filters.accounts}
                              addFilter={() => this.addFilter("accounts")}
                              updateFilter={(i, filter) => this.updateFilter("accounts", i, filter)}
                              updateFilterString={filterString => this.updateFilterString("accounts", filterString)}
                              deleteFilter={i => this.deleteFilter("accounts", i)}
                              clearFilters={() => {
                                this.clearFilters("accounts");
                                this.props.setAccountListFilter(match.params.perspectiveId, "");
                              }}
                              filterOptions={this.getAccountsFilterOptions()}
                              onDoneClick={() => {
                                this.props.setAccountListFilter(
                                  match.params.perspectiveId,
                                  this.state.filterStrings.accounts
                                );
                              }}
                            />
                          </Dropdown>
                        </div>
                      )}
                    </div>
                    <TableData
                      name="my-network__account-table"
                      data={
                        this.state.displayAccountSearchResults
                          ? networkStore.accountSearchResults.value
                          : accountList.value || []
                      }
                      columns={accountColumns}
                      hoverColumns={accountHoverColumns}
                      style={{ maxHeight: "40vh" }}
                      pagination
                      loadedPage={
                        this.state.displayAccountSearchResults
                          ? this.state.accountsSearchLoadedPage
                          : networkStore.loadedAccountListPage
                      }
                      loading={
                        this.state.displayAccountSearchResults
                          ? networkStore.gettingNetworkAccountSearchResults
                          : gettingNetworkAccountList
                      }
                      maxRows={
                        this.state.displayAccountSearchResults
                          ? networkStore.accountSearchResults.count
                          : accountList.count
                      }
                      onRowClick={row => {
                        this.props.history.push(match.url + "/" + row.entityGlobalKey);
                      }}
                      onLoadMore={pageToLoad =>
                        this.state.displayAccountSearchResults
                          ? this.props.fetchAccountSearchResults(match.params.perspectiveId, {
                              searchTerm: this.state.accountsSearchTerm,
                              skip: (pageToLoad - 1) * this.props.pageRowCount
                            }) && this.setState({ accountsSearchLoadedPage: pageToLoad })
                          : this.props.selectAccountListPage(match.params.perspectiveId, pageToLoad)
                      }
                      localSelectedKeys={selectedAccountKeys}
                      // TODO: Change rowKey to whatever we are using to send profile update requests
                      rowKey="entityGlobalKey"
                      onRowSelectToggle={key =>
                        this.toggleSelectRowAccounts(
                          key,
                          (Array.isArray(key) ? [...key] : [key]).map(k =>
                            find(accountList.value, row => row.entityGlobalKey === k)
                          )
                        )
                      }
                      rowHeight={"5rem"}
                    />
                  </Card>
                </div>
              </Route>
              <Route path="/v2/:companyId/:perspectiveId/network/:subView(contacts)">
                <div className="view-content">
                  <div className="add-contact-button-container">
                    <button
                      onClick={() => {
                        this.props.displayFlyout("addContactFlyout", {
                          perspectiveId: this.props.match.params.perspectiveId,
                          companyId: this.props.match.params.companyId
                        });
                      }}
                      className="button-primary"
                    >
                      <IconPlusCircle className="icon" height={18} />
                      {Resources.AddContact}
                    </button>
                  </div>
                  <SummaryCard summaryItems={summaryItemsContacts}></SummaryCard>
                  <Card className="card-table">
                    <div className="table-data-card-header">
                      {!isEmpty(selectedContactKeys) ? (
                        contactTableHeaderButtons
                      ) : (
                        <div className="table-data-card-header-search">
                          <AutoCompleteInput
                            loading={networkStore.gettingNetworkContactSearchResults}
                            className="auto-complete-input__table-data-search"
                            placeholder={Resources.Search}
                            onChange={e => {
                              const contactsSearchTerm = e.target.value;
                              this.setState({ contactsSearchTerm });
                              this.props.debouncedFetchContactSearchResults(match.params.perspectiveId, {
                                searchTerm: contactsSearchTerm
                              });
                            }}
                            callToActionButton={
                              <div
                                className="dropdown-item-clean dropdown-call-to-action"
                                onClick={() => this.setState({ displayContactSearchResults: true })}
                              >
                                {Resources.SeeAllResultsFor(networkStore.contactSearchTerm)}
                              </div>
                            }
                            isShowingSearchResult={this.state.displayContactSearchResults}
                            handleClearResult={() => {
                              this.setState({
                                contactsSearchTerm: "",
                                displayContactSearchResults: false,
                                contactsSearchLoadedPage: 1
                              });
                            }}
                            text={this.state.contactsSearchTerm}
                            noResultsMessage={Resources.NoResultsFound}
                            showNoResultsMessage={networkStore.gotNetworkContactSearchResults}
                            maxOptions={7}
                            handleSelectOption={option => {
                              this.setState({
                                contactsSearchTerm: option.contactName,
                                displayContactSearchResults: true
                              });
                              this.props.fetchContactSearchResults(match.params.perspectiveId, {
                                searchTerm: option.contactName
                              });
                            }}
                            renderOption={option => `${option.contactName}`}
                            options={networkStore.contactSearchResults.value}
                            width="300px"
                          />
                          <Dropdown
                            buttonClassName="button-action-icon ml-2"
                            buttonContent={<IconFilter height="18"></IconFilter>}
                            isForm
                            disabled={this.state.displayContactSearchResults}
                          >
                            <FilterBuilder
                              filters={this.state.filters.contacts}
                              addFilter={() => this.addFilter("contacts")}
                              updateFilter={(i, filter) => this.updateFilter("contacts", i, filter)}
                              updateFilterString={filterString => this.updateFilterString("contacts", filterString)}
                              deleteFilter={i => this.deleteFilter("contacts", i)}
                              clearFilters={() => {
                                this.clearFilters("contacts");
                                this.props.setContactListFilter(match.params.perspectiveId, "");
                              }}
                              filterOptions={this.getContactsFilterOptions()}
                              onDoneClick={() => {
                                this.props.setContactListFilter(
                                  match.params.perspectiveId,
                                  this.state.filterStrings.contacts
                                );
                              }}
                            />
                          </Dropdown>
                        </div>
                      )}
                    </div>
                    <ContactsTable
                      name="my-network__contacts-table"
                      data={
                        this.state.displayContactSearchResults
                          ? networkStore.contactSearchResults.value
                          : contactList.value || []
                      }
                      loading={
                        this.state.displayContactSearchResults
                          ? networkStore.gettingNetworkContactSearchResults
                          : gettingNetworkContactList
                      }
                      maxRows={
                        this.state.displayContactSearchResults
                          ? networkStore.contactSearchResults.count
                          : contactList.count
                      }
                      loadedPage={
                        this.state.displayContactSearchResults
                          ? this.state.contactsSearchLoadedPage
                          : networkStore.loadedContactListPage
                      }
                      onLoadMore={pageToLoad =>
                        this.state.displayContactSearchResults
                          ? this.props.fetchContactSearchResults(match.params.perspectiveId, {
                              searchTerm: this.state.contactSearchTerm,
                              skip: (pageToLoad - 1) * this.props.pageRowCount
                            }) && this.setState({ contactsSearchLoadedPage: pageToLoad })
                          : this.props.selectContactListPage(match.params.perspectiveId, pageToLoad)
                      }
                      selectedKeys={selectedContactKeys}
                      rowKey="entityGlobalKey"
                      onRowSelectToggle={key =>
                        this.toggleSelectRowContacts(
                          key,
                          (Array.isArray(key) ? [...key] : [key]).map(k =>
                            find(contactList.value, row => row.entityGlobalKey === k)
                          )
                        )
                      }
                    />
                  </Card>
                </div>
              </Route>
              <Route path="/v2/:companyId/:perspectiveId/network/:subView(accountArchive)">
                <div className="view-content">
                  <SummaryCard summaryItems={summaryItemsArchivedAccounts}></SummaryCard>
                  <Card className="card-table">
                    <div className="table-data-card-header">
                      {!isEmpty(selectedArchivedAccountKeys) ? (
                        accountTableHeaderButtons
                      ) : (
                        <div className="table-data-card-header-search">
                          <AutoCompleteInput
                            loading={networkStore.gettingNetworkAccountSearchResults}
                            className="auto-complete-input__table-data-search"
                            placeholder={Resources.Search}
                            onChange={e => {
                              const accountsSearchTerm = e.target.value;
                              this.setState({ accountsSearchTerm });
                              this.props.debouncedFetchAccountSearchResults(match.params.perspectiveId, {
                                searchTerm: accountsSearchTerm
                              });
                            }}
                            callToActionButton={
                              <div
                                className="dropdown-item-clean dropdown-call-to-action"
                                onClick={() => this.setState({ displayAccountSearchResults: true })}
                              >
                                {Resources.SeeAllResultsFor(networkStore.accountSearchTerm)}
                              </div>
                            }
                            isShowingSearchResult={this.state.displayAccountSearchResults}
                            handleClearResult={() => {
                              this.setState({
                                accountsSearchTerm: "",
                                displayAccountSearchResults: false,
                                accountsSearchLoadedPage: 1
                              });
                            }}
                            text={this.state.accountsSearchTerm}
                            noResultsMessage={Resources.NoResultsFound}
                            showNoResultsMessage={networkStore.gotNetworkAccountSearchResults}
                            maxOptions={7}
                            handleSelectOption={option => {
                              this.setState({
                                accountsSearchTerm:
                                  this.props.selectedPerspectiveType === "vendors"
                                    ? option.vendorName
                                    : option.custName,
                                displayAccountSearchResults: true
                              });
                              this.props.fetchAccountSearchResults(match.params.perspectiveId, {
                                searchTerm:
                                  this.props.selectedPerspectiveType === "vendors" ? option.vendorName : option.custName
                              });
                            }}
                            renderOption={option =>
                              `${
                                this.props.selectedPerspectiveType === "vendors"
                                  ? option.displayName || option.vendorName
                                  : option.displayName || option.custName
                              }`
                            }
                            options={networkStore.accountSearchResults.value}
                            width="300px"
                          />
                          <Dropdown
                            buttonClassName="button-action-icon ml-2"
                            buttonContent={<IconFilter height="18"></IconFilter>}
                            isForm
                            disabled={this.state.displayAccountSearchResults}
                          >
                            <FilterBuilder
                              filters={this.state.filters.accounts}
                              addFilter={() => this.addFilter("accounts")}
                              updateFilter={(i, filter) => this.updateFilter("accounts", i, filter)}
                              updateFilterString={filterString => this.updateFilterString("accounts", filterString)}
                              deleteFilter={i => this.deleteFilter("accounts", i)}
                              clearFilters={() => {
                                this.clearFilters("accounts");
                                this.props.setAccountListFilter(match.params.perspectiveId, "");
                              }}
                              filterOptions={this.getAccountsFilterOptions()}
                              onDoneClick={() => {
                                this.props.setAccountListFilter(
                                  match.params.perspectiveId,
                                  this.state.filterStrings.accounts
                                );
                              }}
                            />
                          </Dropdown>
                        </div>
                      )}
                    </div>
                    <TableData
                      name="my-network__archived-account-table"
                      data={
                        this.state.displayAccountSearchResults
                          ? networkStore.accountSearchResults.value
                          : archivedAccountList.value || []
                      }
                      columns={accountColumns}
                      hoverColumns={accountHoverColumns}
                      style={{ maxHeight: "40vh" }}
                      pagination
                      loadedPage={
                        this.state.displayAccountSearchResults
                          ? this.state.accountsSearchLoadedPage
                          : networkStore.loadedAccountListPage
                      }
                      loading={
                        this.state.displayAccountSearchResults
                          ? networkStore.gettingNetworkAccountSearchResults
                          : gettingNetworkAccountList
                      }
                      maxRows={
                        this.state.displayAccountSearchResults
                          ? networkStore.accountSearchResults.count
                          : archivedAccountList.count
                      }
                      onRowClick={row => {
                        this.props.history.push(match.url + "/" + row.entityGlobalKey);
                      }}
                      onLoadMore={pageToLoad =>
                        this.state.displayAccountSearchResults
                          ? this.props.fetchAccountSearchResults(match.params.perspectiveId, {
                              searchTerm: this.state.accountsSearchTerm,
                              skip: (pageToLoad - 1) * this.props.pageRowCount
                            }) && this.setState({ accountsSearchLoadedPage: pageToLoad })
                          : this.props.selectAccountListPage(match.params.perspectiveId, pageToLoad)
                      }
                      localSelectedKeys={selectedArchivedAccountKeys}
                      // TODO: Change rowKey to whatever we are using to send profile update requests
                      rowKey="entityGlobalKey"
                      onRowSelectToggle={key =>
                        this.toggleSelectRowArchivedAccounts(
                          key,
                          (Array.isArray(key) ? [...key] : [key]).map(k =>
                            find(archivedAccountList.value, row => row.entityGlobalKey === k)
                          )
                        )
                      }
                      rowHeight={"5rem"}
                    />
                  </Card>
                </div>
              </Route>
              <Route path="/v2/:companyId/:perspectiveId/network/:subView(contactArchive)">
                <div className="view-content">
                  <SummaryCard summaryItems={summaryItemsArchivedContacts}></SummaryCard>
                  <Card className="card-table">
                    <div className="table-data-card-header">
                      {!isEmpty(selectedArchivedContactKeys) ? (
                        contactTableHeaderButtons
                      ) : (
                        <div className="table-data-card-header-search">
                          <AutoCompleteInput
                            loading={networkStore.gettingNetworkContactSearchResults}
                            className="auto-complete-input__table-data-search"
                            placeholder={Resources.Search}
                            onChange={e => {
                              const contactsSearchTerm = e.target.value;
                              this.setState({ contactsSearchTerm });
                              this.props.debouncedFetchContactSearchResults(match.params.perspectiveId, {
                                searchTerm: contactsSearchTerm
                              });
                            }}
                            callToActionButton={
                              <div
                                className="dropdown-item-clean dropdown-call-to-action"
                                onClick={() => this.setState({ displayContactSearchResults: true })}
                              >
                                {Resources.SeeAllResultsFor(networkStore.contactSearchTerm)}
                              </div>
                            }
                            isShowingSearchResult={this.state.displayContactSearchResults}
                            handleClearResult={() => {
                              this.setState({
                                contactsSearchTerm: "",
                                displayContactSearchResults: false,
                                contactsSearchLoadedPage: 1
                              });
                            }}
                            text={this.state.contactsSearchTerm}
                            noResultsMessage={Resources.NoResultsFound}
                            showNoResultsMessage={networkStore.gotNetworkContactSearchResults}
                            maxOptions={7}
                            handleSelectOption={option => {
                              this.setState({
                                contactsSearchTerm: option.contactName,
                                displayContactSearchResults: true
                              });
                              this.props.fetchContactSearchResults(match.params.perspectiveId, {
                                searchTerm: option.contactName
                              });
                            }}
                            renderOption={option => `${option.contactName}`}
                            options={networkStore.contactSearchResults.value}
                            width="300px"
                          />
                          <Dropdown
                            buttonClassName="button-action-icon ml-2"
                            buttonContent={<IconFilter height="18"></IconFilter>}
                            isForm
                            disabled={this.state.displayContactSearchResults}
                          >
                            <FilterBuilder
                              filters={this.state.filters.contacts}
                              addFilter={() => this.addFilter("contacts")}
                              updateFilter={(i, filter) => this.updateFilter("contacts", i, filter)}
                              updateFilterString={filterString => this.updateFilterString("contacts", filterString)}
                              deleteFilter={i => this.deleteFilter("contacts", i)}
                              clearFilters={() => {
                                this.clearFilters("contacts");
                                this.props.setContactListFilter(match.params.perspectiveId, "");
                              }}
                              filterOptions={this.getContactsFilterOptions()}
                              onDoneClick={() => {
                                this.props.setContactListFilter(
                                  match.params.perspectiveId,
                                  this.state.filterStrings.contacts
                                );
                              }}
                            />
                          </Dropdown>
                        </div>
                      )}
                    </div>
                    <ContactsTable
                      name="my-network__archived-contacts-table"
                      data={
                        this.state.displayContactSearchResults
                          ? networkStore.contactSearchResults.value
                          : archivedContactList.value || []
                      }
                      loading={
                        this.state.displayContactSearchResults
                          ? networkStore.gettingNetworkContactSearchResults
                          : gettingNetworkContactList
                      }
                      maxRows={
                        this.state.displayContactSearchResults
                          ? networkStore.contactSearchResults.count
                          : archivedContactList.count
                      }
                      loadedPage={
                        this.state.displayContactSearchResults
                          ? this.state.contactsSearchLoadedPage
                          : networkStore.loadedContactListPage
                      }
                      onLoadMore={pageToLoad =>
                        this.state.displayContactSearchResults
                          ? this.props.fetchContactSearchResults(match.params.perspectiveId, {
                              searchTerm: this.state.contactSearchTerm,
                              skip: (pageToLoad - 1) * this.props.pageRowCount
                            }) && this.setState({ contactsSearchLoadedPage: pageToLoad })
                          : this.props.selectContactListPage(match.params.perspectiveId, pageToLoad)
                      }
                      selectedKeys={selectedArchivedContactKeys}
                      rowKey="entityGlobalKey"
                      onRowSelectToggle={key =>
                        this.toggleSelectRowArchivedContacts(
                          key,
                          (Array.isArray(key) ? [...key] : [key]).map(k =>
                            find(archivedContactList.value, row => row.entityGlobalKey === k)
                          )
                        )
                      }
                    />
                  </Card>
                </div>
              </Route>
            </Switch>
          </Route>
          <Route
            path={`/v2/:companyId/:perspectiveId/network/:archived(accounts|accountArchive)/:accountId(${guidRegex})`}
          >
            <AccountView></AccountView>
          </Route>
          <Route path={`/v2/:companyId/:perspectiveId/network/contacts/:contactId(${guidRegex})`}>
            <ContactView></ContactView>
          </Route>
          <Redirect from="/v2/:companyId/:perspectiveId/accounts/:extra" to="/v2/:companyId/:perspectiveId/accounts" />
        </Switch>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network,
    pageRowCount: store.general.pageRowCount,
    gettingNetworkAccountList: store.network.gettingNetworkAccountList,
    gettingNetworkContactList: store.network.gettingNetworkContactList,
    selectedPerspectiveType: store.perspectives.selectedPerspectiveType,
    perspectiveStore: store.perspectives
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(NetworkView));
