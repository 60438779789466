import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import moment from "moment";

import MainContentHeader from "../main_content_header/mainContentHeader";
import OpenInvoices from "./openInvoices";
import ClosedInvoices from "./closedInvoices";
import Payments from "./payments";
import Documents from "./documents";
import Profile from "./profile";
import ContactInfo from "./contactInfo";
import Messages from "./messages";
import MainLoader from "../mainLoader";
import PortalSessionExpired from "./portalSessionExpired";
import PortalAccountError from "./portalAccountError";
import LanguageSelector from "./languageSelector";
import DropdownSearchSort from "../library/dropdownSearchSort";
import TableDataSortableHeader from "../library/tableDataSortableHeader";
import SearchInput from "../searchInput";
import Resources from "../../lib/resources";
import { capStringAtLength, find, isEmpty } from "../../lib/utils";
import "../../css/index.scss";

import { dispatchToProps as genDP } from "../../store/general-actions";
import { dispatchToProps as aaDP } from "../../store/accounts-actions";
import { dispatchToProps as paDP } from "../../store/perspectives-actions";
import { dispatchToProps as cgDP } from "../../store/contextGroups-actions";
import { dispatchToProps as convDP } from "../../store/conversations-actions";
import { dispatchToProps as saDP } from "../../store/statements-actions";
import { dispatchToProps as caDP } from "../../store/company-actions";
import { dispatchToProps as uaDP } from "../../store/user-actions";
import { dispatchToProps as moDP } from "../../store/modal-actions";

import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { cloneDeep, isEqual } from "lodash";

const dispatchToProps = dispatch => {
  return {
    ...genDP(dispatch),
    ...aaDP(dispatch),
    ...saDP(dispatch),
    ...paDP(dispatch),
    ...cgDP(dispatch),
    ...caDP(dispatch),
    ...uaDP(dispatch),
    ...convDP(dispatch),
    ...moDP(dispatch)
  };
};

class CustomerPortal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayAccountError: false,
      changedWithCompanyId: false,
      allAccountsSelected: false,
      selectedCompany: {},
      filterValue: '',
      dropdownSortBy: 'Account',
      dropdownSortDirection: 'desc',
      dropdownCompanies: [],
      longestCustomerName: 0,
      hoveredRow: '',
      initialLoad: true,
      
    };

    this.searchInputRef = React.createRef();
    this.allAccountsDisplayName = "All Accounts";
  }

  componentDidMount() {
    window.Intercom("shutdown");
    this.props.fetchDropdownContent();
    this.tryUpdate();
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  componentWillUnmount() {
    window.Intercom("boot");
  }

  tryUpdate(prevProps = {}) {
    let {
      userStore,
      contextGroupsStore,
      perspectivesStore,
      statementsStore,
      conversationsStore,
      accountsStore
    } = this.props;

    if (this.props.APILive === false) {
      return null;
    }

    const companyId = accountsStore.selectedCompanyId;
    const perspectiveId = perspectivesStore.selectedPerspectiveId;
    const subscription = this.props.getSubscription();

    // The rule for whether we have a national account, i.e. show-all pay-all features should be enabled, is that the account has multiple companies
    // under it and the national account option is enabled in ATC.
    const nationalAccount = accountsStore.selectedAccount.companies.length > 1 && (accountsStore.selectedAccount.features || {}).portalAllowNAcctAccess === 1;

    if (subscription === null) {
      return;
    } else if ((subscription.subscriptionTypeName || "").toLowerCase() !== "customer portal") {
      this.props.history.push("/");
    }

    if (userStore.isLoggingIn !== true) {
      let duration = moment.duration(moment(new Date(userStore.decoded.exp * 1000)).diff(moment()));
      let asMinutes = duration.asMinutes();

      if (asMinutes < 5 && !userStore.isTokenExpiring) {
        this.props.tokenExpiring();
      }
    }

    // This check needs to be done before the check for whether we can try to auto-select the All Accounts option. The reason is the call to the
    // action method for selecting a company must be made with the proper data in order to be able to switch from All Accounts to the primary
    // company. We don't want the call to select a company below to happen last if we are dealing with a national account.
    if (isEmpty(companyId)) {
      this.props.selectCompany(this.props.getDefaultCompany().companyId);
      return null;
    }

    // If this is the initial page load and the current account is a national account, we want to auto-select All Accounts as a convenience to the
    // user
    if (this.state.initialLoad && accountsStore.hasDropdownContent && nationalAccount) {
      this.handleDropDownClick({
        value: "ALL_ACCOUNTS_ID",
        displayName: this.allAccountsDisplayName,
        fullName: this.allAccountsDisplayName,
        openInvoices: accountsStore?.dropdownContent?.allAccountObj?.openInvoices
      });

      this.setState({ initialLoad: false });
    }

    if (isEmpty(contextGroupsStore.companyId) && !isEmpty(companyId)) {
      this.props.fetchCompanyInfo(companyId);
      this.props.selectCompany(companyId);
    }

    if (isEmpty(perspectiveId)) {
      const companyPerspectives = this.props.getCompanyPerspectives(companyId);

      if (companyPerspectives === null) {
        return;
      }

      const vendorPerspective = find(
        companyPerspectives,
        cPerspective => cPerspective.parentPerspective.perspectiveName === "vendors"
      );

      this.props.setSelectedPerspectiveId(vendorPerspective.perspectiveId);
    } else if (isEmpty(statementsStore.withCompanyId)) {
      // This should only run once
      const contextGroupsMaps = this.props.getContextGroupsMaps(companyId, perspectiveId);

      if (contextGroupsMaps === null) {
        return;
      }

      if (isEmpty(contextGroupsMaps)) {
        // There are no context groups to show or assosciated companies with this account. This does not make sense as it is a portal account.
        if (this.state.displayAccountError !== true) {
          this.setState({ displayAccountError: true });
        }

        return;
      } else if (this.state.displayAccountError === true) {
        this.setState({ displayAccountError: false });
      }

      const withCompanyId = (contextGroupsMaps[Object.keys(contextGroupsMaps)[0]] || {}).companyId;

      this.props.fetchCompanyInfo(withCompanyId);
      this.props.setWithCompanyId(withCompanyId);
    }

    if (!isEmpty(prevProps) && statementsStore.withCompanyId !== prevProps.statementsStore.withCompanyId) {
      const withCompanyId = this.props.statementsStore.withCompanyId;
      const withContextGroupId = (
        find(this.props.contextGroupsStore.value, contextGroup => contextGroup.companyId === withCompanyId) || {}
      ).contextGroupId;

      if (
        conversationsStore.gettingConversations[perspectiveId] !== true &&
        conversationsStore.fetchedConversations[perspectiveId] !== true
      ) {
        // Get unassigned conversations to populate inbox counter
        this.props.getLabeledWithCompanyConversations(companyId, perspectiveId, withContextGroupId, "unassigned");
      }

      if (statementsStore.fetchingPaymentInfoFailed !== true) {
        this.props.fetchPaymentInfo(companyId, withCompanyId);
      }

      if (statementsStore.fetchingCurrenciesFailed !== true) {
        this.props.fetchCurrencies(companyId, perspectiveId, withCompanyId);
      }

      this.setState({ changedWithCompanyId: true });
    }

    if (isEqual(this.state.selectedCompany, {})) {
      this.setState({ selectedCompany: this.setDropdownRowValues(this.props.accountsStore.selectedCompany) });
    }
  }

  getRouteFromATCPage(custPage){
    switch (custPage){
      case "Statements":
        return "open-invoices";
      case "Invoices":
        return "closed-invoices";
      case "Payments":
        return "payments";
      default:
        return "";
    }
  }

  getDefaultRouteFromPage(defaultRoute) {
    if (isEmpty(defaultRoute)) {
      return "";
    }
    switch (defaultRoute) {
      case "Documents":
        return "documents";
      case "CustPayments":
        return "payments";
      case "CustContactInfo":
        return "contacts";
      case "CustInvoices":
        return "closed-invoices";
      case "CustStatement":
      default:
        return "open-invoices";
    }
  }

  getCompanySelector = (accountsStore, nationalAccount) => {
    const allAccountObj = {
      value: 'ALL_ACCOUNTS_ID',
      displayName: this.allAccountsDisplayName,
      fullName: this.allAccountsDisplayName,
      openInvoices: accountsStore?.dropdownContent?.allAccountObj?.openInvoices
    };

    return (
      <DropdownSearchSort
        // TODO: Future dynamic styling fix for dropdown width
        // longestCustomerName={this.props?.dropdownContent?.longestCustomerName}
        longestCustomerName={Resources.DropdownWidth}
        className='dropdown-trigger button-select-input'
        selectedCompany={this.state.selectedCompany}
        onSetVisible={() => {
          this.searchInputRef.current.focus();
        }}
      >
        <SearchInput
          placeholder='Filter Accounts'
          handleChange={(e) => this.onFilterValueChange(e)}
          onKeyPress={this.onFilterValueChange}
          onClick={this.onFilterValueChange}
          className='account-search-container d-flex mt-2'
          inputRef={this.searchInputRef}
        />
        <table style={{ width: "660px" }}>
          {/* TODO: Future dynamic styling for header width */}
          {/* <thead className={'table-data-header'} style={{width: `${this.props?.dropdownContent?.longestCustomerName * 9.6}px`}}> */}
          <thead className={'table-data-header'} style={{ width: `${Resources.DropdownWidth}px` }}>
          <tr style={{ display: 'flex', width: nationalAccount ? '110%' : '100%', background: '#f3f4f5', 'marginRight': '53px' }}
              className={'table-padding'}>
            <th className={'font-weight-normal vertical-header-padding-8'}>
              <TableDataSortableHeader
                text={'ACCOUNT'}
                sortBy={this.state.dropdownSortBy}
                sortKey={"Account"}
                sortDirection={this.state.dropdownSortDirection}
                updateSort={(sortBy, sortDirection) => this.handleDropdownSort(sortBy, sortDirection)}
              />
            </th>
            <th className='ml-auto font-weight-normal vertical-header-padding-8 padding-right'>
              {nationalAccount ?
                <TableDataSortableHeader
                  text={'OPEN INV'}
                  sortBy={this.state.dropdownSortBy}
                  sortKey={'OpenInvoiceCount'}
                  sortDirection={this.state.dropdownSortDirection}
                  updateSort={(sortBy, sortDirection) => this.handleDropdownSort(sortBy, sortDirection)}
                /> : null}
            </th>
          </tr>
          </thead>
          <tbody>
          {nationalAccount ? <tr className='font-weight-medium dropdown-row' onClick={() => this.handleDropDownClick(allAccountObj)}>
            <td className={'table-padding-left padding-top-10'} value={allAccountObj.value}>{allAccountObj.displayName}</td>
            <td className='padding-top-10 text-align-right'>{allAccountObj.openInvoices > 0 ? allAccountObj.openInvoices : ''}</td>
          </tr> : null}
          {this.props?.accountsStore?.dropdownContent?.combinedDropdownData.filter(company => this.state.filterValue === '' || company.fullName.toLowerCase().includes(this.state.filterValue)).length !==0
          ?
          this.props?.accountsStore?.dropdownContent?.combinedDropdownData.filter(company => this.state.filterValue === '' || company.fullName.toLowerCase().includes(this.state.filterValue))
          .map((item, index) => {
              return (
              <tr className='font-weight-medium dropdown-row' key={item.value} onClick={() => this.handleDropDownClick(item)}>
                <td className={'table-padding-left info-container padding-top-10'}
                    title={item.fullName}
                    value={item.value}
                    onMouseEnter={() => {
                      this.setState({ hoveredRow: index })
                    }}
                    onMouseLeave={() => {
                      this.setState({ hoveredRow: null })
                    }}
                >
                  {item.displayName}
                </td>
                {nationalAccount ? <td className='text-align-right padding-top-10'>{item.openInvoices > 0 ? item.openInvoices : ''}</td> : null}
              </tr>
            )
          }):<div className="padding-top-10 padding-bottom-10 text-center" style={{color:"grey"}}>Sorry! No Account Found.</div>}
          
          </tbody>
        </table>
      </DropdownSearchSort>
    );
  }

  setDropdownRowValues = (company) => {
    if (company) {
      return {
        value: company.companyId,
        displayName: company.companyName.length >= Resources.TruncateLimit ? capStringAtLength(company.companyName, Resources.TruncateLimit) : company.companyName,
        fullName: company.companyName,
        openInvoices: company.openInvoiceCount
      }
    }
  }

  handleDropDownClick = (item) => {
    if (item.displayName === this.allAccountsDisplayName && this.state.allAccountsSelected) {
      return;
    }
    else if (item.displayName === this.allAccountsDisplayName && !this.state.allAccountsSelected) {
      this.setState({
        allAccountsSelected: true,
        selectedCompany: item,
        filterValue: ''
      })
    } else {
      this.setState({
        allAccountsSelected: false,
        selectedCompany: item,
        filterValue: ''
      })
    }

    if (item.value !== this.props.accountsStore.selectedCompany.companyId) {
      this.setState({hoveredRow: ''})
    }

    // We need to include the primary company ID value in order to set it in the ledger store, which will also respond to this action
    this.props.selectCompany(item.value, this.props.accountsStore.primaryCompany.companyId);
  }

  onFilterValueChange = (e) => {
    if (e.target.value || e.target.value === '') {
      this.setState({ filterValue: e.target.value.toLowerCase() });
    }
  }

  handleDropdownSort = (sortBy, sortDirection) => {
    // If the sort direction changes, reverse current array in the table
    let sortedDropdownCompanies = cloneDeep(this.props?.accountsStore?.dropdownContent?.combinedDropdownData);

    if (this.state.dropdownSortBy === sortBy) {
      sortedDropdownCompanies = sortedDropdownCompanies.reverse();
    } else if(sortBy === 'OpenInvoiceCount') {
      sortedDropdownCompanies = sortedDropdownCompanies.sort((a, b) => b.openInvoices - a.openInvoices);
    } else {
      sortedDropdownCompanies = sortedDropdownCompanies.sort((a, b) => a.fullName.localeCompare(b.fullName));
    }

    this.setState({
      dropdownSortBy: sortBy,
      dropdownSortDirection: sortDirection,
      dropdownCompanies: sortedDropdownCompanies
    })

    this.props.updateDropdownSort(sortedDropdownCompanies);
  }

  render() {
    const {
      contextGroupsStore,
      perspectivesStore,
      statementsStore,
      userStore,
      companyStore,
      accountsStore
    } = this.props;

    const { allAccountsSelected } = this.state;
    const nationalAccount = accountsStore.selectedAccount.companies.length > 1 &&  (accountsStore.selectedAccount.features || {}).portalAllowNAcctAccess === 1;

    if (this.state.displayAccountError) {
      return <PortalAccountError />;
    }

    const selectedCompany = accountsStore.selectedCompany;
    const { companyName, companyId } = selectedCompany;

    const perspectiveId = perspectivesStore.selectedPerspectiveId;
    const withCompanyId = statementsStore.withCompanyId;
    const companyCustId = this.props.getCompanyInfo(companyId).CustID || "";
    let defaultPage = (this.props.getCompanyInfo(withCompanyId).CompanySettings || {}).DefaultPortalPage;

    if (companyStore.fetchedCompanyInfo[withCompanyId] && isEmpty(defaultPage)) {
      defaultPage = "CustStatement";
    }

    let defaultRoute = this.getDefaultRouteFromPage(defaultPage);
    const atcCustPage = window.localStorage.getItem("cust-page");

    if (atcCustPage) {
      const overrideRoute = this.getRouteFromATCPage(atcCustPage);

      if (overrideRoute) {
        defaultRoute = overrideRoute;
      }
    }

    const withContextGroup = find(contextGroupsStore.value, contextGroup => contextGroup.companyId === withCompanyId) || {};
    const withContextGroupId = withContextGroup.contextGroupId;
    const withCompanyName = withContextGroup.displayName || withContextGroup.companyName;
    const displayCompanyName = withContextGroup.displayName;
    const injectedProps = { companyId, perspectiveId, withCompanyId, withCompanyName, withContextGroupId, allAccountsSelected, nationalAccount };
    const unassignedUnreads = this.props.conversationsStore.unassignedUnreads;

    const headerLinks = (
      <React.Fragment>
        <NavLink
          data-test-id="portal-navigation__open-invoices"
          className="content-header-link"
          to={"/customer-portal/open-invoices"}
        >
          {Resources.OpenInvoices.toLocaleUpperCase()}
        </NavLink>

        {(accountsStore.selectedAccount.features || {}).custInvoices === 1 && (       
          <NavLink
            data-test-id="portal-navigation__closed-invoices"
            className="content-header-link"
            to={"/customer-portal/closed-invoices"}            
          >
            {Resources.ClosedInvoices.toLocaleUpperCase()}
          </NavLink>
        )}

        {(accountsStore.selectedAccount.features || {}).custPayments === 1 && (
          <NavLink
            data-test-id="portal-navigation__payments"
            className="content-header-link"
            to={"/customer-portal/payments"}
          >
            {Resources.Payments.toLocaleUpperCase()}
          </NavLink>
        )}

        { !this.state.allAccountsSelected ? 
          <>
              <NavLink
              data-test-id="portal-navigation__communications"
              className={`content-header-link ${unassignedUnreads ? "with-badge" : ""}`}
              to={"/customer-portal/communications"}
            >
              {Resources.Messages.toLocaleUpperCase()}
              {unassignedUnreads > 0 && (
                <div className="badge-white-bg" style={{ marginLeft: ".4rem" }}>
                  {unassignedUnreads}
                </div>
              )}
            </NavLink>
            {this.props.flags.updatedProfileAndDocumentsContent ? (
              <NavLink
                data-test-id="portal-navigation__profile"
                className="content-header-link"
                to={"/customer-portal/profile"}
              >
                {Resources.Profile.toLocaleUpperCase()}
              </NavLink>
            ) : (
              <NavLink
                data-test-id="portal-navigation__contacts"
                className="content-header-link"
                to={"/customer-portal/contacts"}
              >
                {Resources.Contacts.toLocaleUpperCase()}
              </NavLink>
            )}

            <NavLink
              data-test-id="portal-navigation__documents"
              className="content-header-link"
              to={"/customer-portal/documents"}
            >
              {Resources.Documents.toLocaleUpperCase()}
            </NavLink>
          </> : null
        }
      </React.Fragment>
    );

    const propMerger = Component => {
      return props => {
        const mergedProps = { ...props, ...injectedProps };
        return <Component {...mergedProps} />;
      };
    };

    if (userStore.isTokenExpiring) {
      return <PortalSessionExpired />;
    }

    if (isEmpty(withCompanyName) || (isEmpty(statementsStore.currencies) && this.state.changedWithCompanyId === false)) {
      return <MainLoader fullScreen={true} />;
    }

    let logoUrl = this.props.getCompanyInfo(withCompanyId).iconUrl;
    let headerSubtitle =
      companyName === companyCustId
        ? Resources.HelloCompanyNameWelcomeBack(companyName)
        : Resources.HelloCompanyNameIdWelcomeBack(companyName, companyCustId);
    let portalCompanySelector;

    if (nationalAccount) {
      portalCompanySelector = this.getCompanySelector(accountsStore, nationalAccount);
         }

    let userAgentString = navigator.userAgent;
    let chromeAgent = userAgentString.indexOf("Chrome") > -1;
    let safariAgent = userAgentString.indexOf("Safari") > -1;

    if (chromeAgent && safariAgent) {
      safariAgent = false;
    }

    if (safariAgent === true) {
      let errorMessage = Resources.UnsupportedBrowserText;
      this.props.displayModal("safariModal", { errorMessage });
    }

    return (
      <div className="customer-portal">
        <MainContentHeader
          logoSrc={logoUrl}
          isCustomerPortal
          title={displayCompanyName}
          links={headerLinks}
          subtitle={!isEmpty(companyName) && headerSubtitle}
          companySelect={portalCompanySelector}
        />
        {this.props.flags.giveFeedbackSsPortal.enabled ? (
          <div
            className="give-feedback-button"
            onClick={() => {
              this.props.displayModal("portalGiveFeedbackModal");
            }}
          >
            {Resources.GiveFeedback}
          </div>
        ) : null}
        <div className="customer-portal-content">
          <Switch>
            {!isEmpty(defaultRoute) && (
              <Redirect exact path="/customer-portal" to={`/customer-portal/${defaultRoute}`} />
            )}
            <Route
              exact
              path="/customer-portal"
              render={() => <MainLoader fullScreen className="portal-page-loader" />}
            />
            
            <Route path="/customer-portal/open-invoices" render={propMerger(OpenInvoices)} />
            {
              (accountsStore.selectedAccount.features || {}).custInvoices !== 1 && (
                <Redirect exact from="/customer-portal/closed-invoices" to="/customer-portal/open-invoices" />
              )
            }

            <Route path="/customer-portal/closed-invoices" render={propMerger(ClosedInvoices)} />
            {
              (accountsStore.selectedAccount.features || {}).custPayments !== 1 && (
                <Redirect exact from="/customer-portal/payments" to="/customer-portal/open-invoices" />
              )
            }

            <Route path="/customer-portal/payments" render={propMerger(Payments)} />
            {/* These redirects deal with the case where the user selects All Accounts while viewing a tab that is normally hidden in that mode */}
            {
              allAccountsSelected && (
                <Redirect exact from="/customer-portal/documents" to="/customer-portal/open-invoices" />
              )
            }
            {
              allAccountsSelected && (
                <Redirect from="/customer-portal/communications/:folder" to="/customer-portal/open-invoices" />
              )
            }
            {
              allAccountsSelected && (
                <Redirect exact from="/customer-portal/contacts" to="/customer-portal/open-invoices" />
              )
            }
            {
              allAccountsSelected && (
                <Redirect exact from="/customer-portal/profile" to="/customer-portal/open-invoices" />
              )
            }
            <Route path="/customer-portal/documents" render={propMerger(Documents)} />
            <Redirect exact path="/customer-portal/communications" to="/customer-portal/communications/inbox" />
            <Route path="/customer-portal/communications/:folder" render={propMerger(Messages)} />
            <Route path="/customer-portal/contacts" render={propMerger(ContactInfo)} />
            <Route path="/customer-portal/profile" render={propMerger(Profile)} />
          </Switch>
        </div>
        <div className="customer-portal-language-selector-container">
          <LanguageSelector />
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    accountsStore: store.accounts,
    userStore: store.user,
    conversationsStore: store.conversations,
    companyStore: store.companies,
    perspectivesStore: store.perspectives,
    statementsStore: store.statements,
    contextGroupsStore: store.contextGroups,
    APILive: store.general.isAPILive
  };
};

export default withLDConsumer()(connect(storeToProps, dispatchToProps)(CustomerPortal));
