import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Modal from "react-modal";
import data from "../../lib/data";
import { dispatchToProps as moDP } from "../../store/modal-actions";
import { dispatchToProps as saDP } from "../../store/statements-actions";
import { dispatchToProps as uaDP } from "../../store/user-actions";

import Resources from "../../lib/resources";
import IconClose from "../library/icons/iconClose";
import TableData from "../library/tableData";
import TextInput from "../library/textInput";
import "react-datepicker/dist/react-datepicker.css";

import {
  includes,
  updateSelectedRows,
  formatCurrency,
  onBlurCheckFocusable,
  formatDate,
  isEmpty,
  find,
  replaceAt,
  decodeURLQueryString
} from "../../lib/utils";

import { isValidDollarAmount } from "../../lib/validation";
import MainLoader from "../mainLoader";
import HtmlEditor from "../htmlEditor";
import IconPlusCircle from "../library/icons/iconPlusCircle";
import IconAngleRight from "../library/icons/iconAngleRight";
import IconAngleLeft from "../library/icons/iconAngleLeft";
import IconStar from "../library/icons/iconStar";
import IconTrash from "../library/icons/iconTrash";
import IconEdit from "../library/icons/iconEdit";

import { PaymentConfirmationMessage } from "./paymentConfirmationMessage";
import PaymentErrorCode from "./paymentErrorCode";
import SelectInput from "../library/selectInput";
import axios from "../../lib/axios";
import IconAlertCircle from "../library/icons/iconAlertCircle";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...saDP(dispatch),
  ...uaDP(dispatch)
});

const paymentErrorCodes = {
  CARD_DECLINED: "5",
  OTHER: "-1"
};

class MakePaymentFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      htmlBody: "",
      textBody: "",
      selectedRows: [],
      selectedPaymentMethod: {},
      paymentView: "main",
      paymentErrorCode: "",
      paymentErrorMessage: "",
      totalAmount: "",
      invoices: [],
      paymentDate: new Date(),
      primaryCompanyId: '',
      showFlyout: true,
      paymentMethodPageNumber: 0,
      isEditPaymentBtnClicked: false,
      iframeLoaded: false,
      merchantcurrid: [],
      selectcurrid: "",
      isSurchargeApplicable: false,
      transactionFeeResponsesLoaded: false,
      payloadForInterpayment: [],
      transactionFeeResponses: [],
      totalFee: 0,
      surchargeErrorMessage: null,
      payload: [],
      ReminderForOneOrMoreNegativeTransaction:""
    };
  }

  componentDidMount = () => {
    const { companyId, withCompanyId, statementsStore, nationalAccount } = this.props;
    const paymentInfo = statementsStore.paymentInfo[withCompanyId];
    const primaryCompanyId = this.props?.accountsStore?.primaryCompany?.companyId;
    let endpoint = 'v3/api/Statements/DefaultSelfServiceCustomerView/00000000-0000-0000-0000-000000000000';

    const cmpid = this.props.companyId;
    const wthcompid = this.props.withCompanyId;
    let merchantacctendpoint = 'v2/api/payments/' + cmpid + '/' + wthcompid + '/merchantinfo';
    data
      .get(merchantacctendpoint)
      .then(res => {
        this.setState({ merchantcurrid: res.data });
      });

    let stateUpdate = {};

    stateUpdate.primaryCompanyId = primaryCompanyId;
    if (nationalAccount) {
      this.props.fetchPaymentInfo(primaryCompanyId, withCompanyId);
    } else if (isEmpty(paymentInfo)) {
      this.props.fetchPaymentInfo(companyId, withCompanyId);
    } else {
      let paymentMethods = this.getPaymentMethods();
      stateUpdate.selectedPaymentMethod = find(paymentMethods, pMethod => pMethod.isDefault === true) || paymentMethods[paymentMethods.length - 1] || {};
    }
    if (this.props.selectedInvoices.length >= 0) {
      const selectedRows = [];
      let invoices = [];

      for (let i = 0; i < this.props.selectedInvoices.length; i++) {
        const inv = { ...this.props.selectedInvoices[i] };
        inv.paymentAmount = Number.parseFloat(inv.amount).toFixed(2);
        invoices.push(inv);
        selectedRows.push(i);
      }

      stateUpdate.invoices = invoices;
      stateUpdate.selectedRows = selectedRows;
      setTimeout(() => {
        this.getInvoiceKey();
        this.getTransactionFee();

      }, 0);
    }
    this.setState(stateUpdate);
  }

  componentDidUpdate = (prevProps, prevState) => {
    const paymentMethods = this.getPaymentMethods();

    if (
      !isEmpty(paymentMethods) &&
      includes(
        paymentMethods.map(method => method.paymentMethodId),
        this.state.selectedPaymentMethod.paymentMethodId
      ) !== true
    ) {
      const defaultPaymentMethod = find(paymentMethods, pMethod => pMethod.isDefault === true) || paymentMethods[paymentMethods.length - 1] || {};
      this.setState({ selectedPaymentMethod: defaultPaymentMethod });
    }
  }

  hideFlyout = (runCallback) => {
    this.setState({ showFlyout: false });

    // If there is a callback for when the flyout is being hidden and the caller wants to run it, then execute it. This allows the parent to refresh
    // its data if desired when the flyout closes.
    if (this.props.onHide && runCallback) {
      this.props.onHide();
    }
  }

  getInvoiceKey = () => {
    this.props.selectedInvoices.reduce((accum, curr, index) => {
      let splitledgerHash = curr.ledgerHash.split('-');
      this.setState({ payload: [...this.state.payloadForInterpayment, { invoiceKey: splitledgerHash[splitledgerHash.length - 1], InvoiceAmount: parseFloat(curr.amount).toFixed(2) }], payloadForInterpayment: [...this.state.payloadForInterpayment, { invoiceKey: splitledgerHash[splitledgerHash.length - 1], InvoiceAmount: parseFloat(curr.amount).toFixed(2) }] })
    }, 0);
  }

  getTotalTransactionFee = (transactionFeeResponses) => {
    let totalTransactionFee = 0;

    transactionFeeResponses.map(feeResponse => {
      totalTransactionFee = totalTransactionFee + feeResponse.transactionFee;
    });

    return totalTransactionFee
  }

  getSelectedPaymentMethod = () => {
    const { companyId, withCompanyId, statementsStore, nationalAccount } = this.props;
    const paymentInfo = statementsStore.paymentInfo[withCompanyId];
    if (nationalAccount) {
      const paymentMethods =this.props.fetchPaymentInfo(this.state.primaryCompanyId, withCompanyId)
      const selectedPaymentMethod = find(paymentMethods, pMethod => pMethod.isDefault === true) || paymentMethods[paymentMethods.length - 1] || {};
      this.setState({ selectedPaymentMethod: selectedPaymentMethod });
    } else if (isEmpty(paymentInfo)) {
      const paymentMethods =this.props.fetchPaymentInfo(companyId, withCompanyId)
      const selectedPaymentMethod = find(paymentMethods, pMethod => pMethod.isDefault === true) || paymentMethods[paymentMethods.length - 1] || {};
      this.setState({ selectedPaymentMethod: selectedPaymentMethod });
    } else {
      const paymentMethods = this.getPaymentMethods();
      const selectedPaymentMethod = find(paymentMethods, pMethod => pMethod.isDefault === true) || paymentMethods[paymentMethods.length - 1] || {};
      this.setState({ selectedPaymentMethod: selectedPaymentMethod });
    }
  }

  getTransactionFee = () => {
    const userId = this.props.getMyUserIdFromToken();
    const myNameDisplaybyToken = this.props.getMyDisplayNameFromToken().toString();
    const splittedNamebyToken = myNameDisplaybyToken.split(":");
    const CustomerKey = splittedNamebyToken[2];
    const GroupKey = splittedNamebyToken[1];
    const PaymentMethodId = this.state.selectedPaymentMethod.paymentMethodId;
    const endpoint = "v2/api/interpayment/fee";
    const InvoiceDetails = this.state.payloadForInterpayment.reduce((acc, curr, index) => {
      return ([{ "InvoiceKey": curr.invoiceKey, "InvoiceAmountToPay": curr.InvoiceAmount || 0 }, ...acc])
    }, [])
    const dataToSend = {
      CustomerKey: CustomerKey,
      GroupKey: GroupKey,
      PaymentMethodId: PaymentMethodId,
      InvoiceDetails: InvoiceDetails
    }
    this.setState({ transactionFeeResponsesLoaded: false });
    if (this.state.selectedPaymentMethod.paymentType?.toLowerCase() !== "ACH".toLowerCase()) {
      data.post(endpoint, dataToSend)
        .then(res => {
          const data = res.data;
          const statusCode = data.statusCode;
          const transactionFeeResponses = data.transactionFeeResponses;
          const isSurchargeApplicable = data.isSurchargeApplicable;
          const totalFee = this.getTotalTransactionFee(transactionFeeResponses);
          const errMsg = data.message;
          this.setState({ surchargeStatusCode: statusCode }, () => {
            if (statusCode === 0) {
              this.setState({ isSurchargeApplicable: isSurchargeApplicable, transactionFeeResponses: transactionFeeResponses, transactionFeeResponsesLoaded: !!transactionFeeResponses.length > 0, totalFee: totalFee, surchargeErrorMessage: null });
            }
            else if (statusCode === 1) {
              this.setState({ surchargeErrorMessage: null });
            }
            else if (statusCode === 5){
              this.setState({surchargeErrorMessage: errMsg,isSurchargeApplicable: isSurchargeApplicable, ReminderForOneOrMoreNegativeTransaction: data.message,transactionFeeResponses: transactionFeeResponses });
            }
            else {
              this.setState({ surchargeErrorMessage: errMsg, isSurchargeApplicable: isSurchargeApplicable, transactionFeeResponses: transactionFeeResponses });
            }
          });
        })
        .catch(err => console.warn(err));
    }
    else {
      this.setState({ isSurchargeApplicable: false });
    }
  }


  handleCurrency = (e) => {
    this.setState({ selectcurrid: e.target.value });
  }

  renderCurrency = (merchantcurrid) => {
    return (
      <div className="select" height="100rem">
        <select onChange={this.handleCurrency}>
          {Object.keys(this.state.merchantcurrid).map((key, index) => (
            <option key={index} value={this.state.merchantcurrid[key]}>{this.state.merchantcurrid[key]}</option>
          ))}
        </select>
      </div>
    );
  }

  getPaymentMethods = () => {
    return (this.props.statementsStore.paymentInfo[this.props.withCompanyId].paymentMethods || []).map((method, index) => ({
      ...method,
      index,
      displayValue: isEmpty(method.name)
        ? `${method.paymentType === "ACH" ? Resources.BankAccount : Resources.CreditCard} ...${method.lastFourDigits}`
        : method.name
    }));
  }

  addOrUpdatePaymentMethodHelperFn = (companyId, withCompanyId) => {
    this.props
      .addOrUpdatePaymentMethod(companyId, withCompanyId, { name: "", isDefault: false })
      .then(res => {
        this.setState({ iframeUrl: res.redirectUrl });
      })
      .catch(err => null);
  }

  addNewPaymentMethod = () => {
    const { companyId, withCompanyId } = this.props;
    this.setState({ paymentView: "addPaymentMethod" });

    if (this.props.nationalAccount) {
      this.addOrUpdatePaymentMethodHelperFn(this.state.primaryCompanyId, withCompanyId);
    } else {
      this.addOrUpdatePaymentMethodHelperFn(companyId, withCompanyId);
    }
  }

  makePaymentHelperfn = (companyId, withCompanyId, payload) => {
    this.props.makePayment(companyId, withCompanyId, payload).then(res => {
      this.setState({ iframeUrl: res.redirectUrl },()=>{
        this.setState({iframeLoaded: true });
      });
    });
  }

  makePayment = () => {
    const { companyId, withCompanyId } = this.props;
    let invoices = this.state.invoices
      .filter((invoice, index) => includes(this.state.selectedRows, index))
      .map(inv => {
        return {
          invoiceId: inv.id,
          amount: Number.parseFloat(inv.paymentAmount),
          ledgerHash: inv.ledgerHash
        };
      });

    if (invoices.length === 0) {
      invoices = [{ amount: Number.parseFloat(this.state.totalAmount) }];
    }
    this.setState({ paymentView: "makingPayment", paymentErrorCode: "", paymentErrorMessage: "" });

    const paymentMethodId = this.state.selectedPaymentMethod.paymentMethodId;


    let payload = {};
    if (this.state.isSurchargeApplicable) {
      let invoiceId;
      let invoiceArray = [];
      this.state.transactionFeeResponses.forEach(transactionFeeResponse => {
        this.state.invoices.find(invoice=>{
          invoiceId=invoice.id;
          let ledgerHash = invoice.ledgerHash.split('-');
          let invoiceKey = ledgerHash[ledgerHash.length - 1];
          if(transactionFeeResponse.invoiceKey===invoiceKey){
            invoiceArray.push({
              invoiceId: invoiceId,
              amount: Number.parseFloat(transactionFeeResponse.invoiceAmount).toFixed(2),
              InterPaymentTransactionId: transactionFeeResponse.sTxId,
              InterPaymentTransactionFee: transactionFeeResponse.transactionFee
            });
          }
        })
      });

      payload = { paymentMethodId, invoices: invoiceArray, paymentDate: new Date(this.state.paymentDate).toUTCString() };
    }
    else {
      payload = { paymentMethodId, invoices, paymentDate: new Date(this.state.paymentDate).toUTCString() };
    }

    if (!isEmpty(this.state.textBody)) {
      payload.note = this.state.textBody;
    }
    if (this.props.nationalAccount) {
      this.makePaymentHelperfn(this.state.primaryCompanyId, withCompanyId, payload);
    } else {
      this.makePaymentHelperfn(companyId, withCompanyId, payload);
    }
  }

  toggleSelectRow = (i) => {
    let newSelectedRows = updateSelectedRows(i, this.state.selectedRows, this.props.selectedInvoices.length);
    this.setState({ selectedRows: newSelectedRows }, () => {
      let arr = [];
      if (this.state.selectedRows.length) {
        this.state.selectedRows.map((rowIndex, index) => {
          arr.push(this.state.payload[rowIndex]);
        });
        if (arr.length > 0) {
          this.setState({ payloadForInterpayment: [...arr] }, () => setTimeout(() => {
            this.getTransactionFee();
          }, 0));
        }
      }
    });
  }

  renderMainContent = () => {
    let { withCompanyId, selectedInvoices, columns, statementsStore } = this.props;
    let invCount = 0;
    let cmCount = 0;
    let invSum = 0;
    let cmSum = 0;
    let totalAmount = this.state.invoices.reduce((accum, curr, index) => {
      curr.paymentAmount = isEmpty(curr.paymentAmount) ? 0 : curr.paymentAmount

      if (curr.transactionType === "IN") {
        invCount += 1;
        invSum += Number.parseFloat(curr.paymentAmount);
      }
      else if (curr.transactionType === "CM") {
        cmCount += 1;
        cmSum += Number.parseFloat(curr.paymentAmount);
      }

      if (!includes(this.state.selectedRows, index)) {
        return accum;
      }
      return accum + (Number.parseFloat(curr.paymentAmount) || 0);
    }, 0);

    // Ensures no weird JS decimal stuff
    totalAmount = Math.round(totalAmount * 1e12) / 1e12;


    const paymentInfo = statementsStore.paymentInfo[withCompanyId] || {};

    const { allowPartialPayments } = paymentInfo;
    columns = [...columns];

    if (allowPartialPayments === true) {
      columns.splice(5, 0, {
        header: Resources.PaymentAmount.toLocaleUpperCase(),
        content: (row, rowIndex) => (
          <TextInput
            className="m-0"
            dollarAmountInput
            textValue={this.state.invoices[rowIndex].paymentAmount}
            width="100px"
            currencyId={row.currencyId}
            inputOnChange={e => {

              let splitledgerHash = this.state.invoices[rowIndex].ledgerHash.split('-');
              let rowInvoicekey = splitledgerHash[splitledgerHash.length - 1];
              const trxnType = this.state.invoices[rowIndex].transactionType;
              this.setState({ transactionFeeResponsesLoaded: false });

              if (isValidDollarAmount((e.target.value), trxnType)) {
                let paymentAmount = e.target.value;

                if (this.state.payload.length > 0) {
                  for (let i = 0; i < this.state.payload.length; i++) {
                    if (this.state.payload[i].invoiceKey === rowInvoicekey) {
                      this.setState(
                        {
                          payload: replaceAt(this.state.payload, i, {
                            ...this.state.payload[i],
                            InvoiceAmount: e.target.value
                          })
                        }
                      )
                    }
                  }

                }
                if (this.state.payloadForInterpayment.length > 0) {
                  for (let i = 0; i < this.state.payloadForInterpayment.length; i++) {
                    if (this.state.payloadForInterpayment[i].invoiceKey === rowInvoicekey) {
                      this.setState(
                        {

                          payloadForInterpayment: replaceAt(this.state.payloadForInterpayment, i, {
                            ...this.state.payloadForInterpayment[i],
                            InvoiceAmount: e.target.value
                          })
                        }
                      )
                    }
                  }

                }

                if (!paymentInfo.allowUnappliedPayment && paymentAmount < row.amount && trxnType === "CM") {
                  paymentAmount = row.amount;
                }
                else if (!paymentInfo.allowUnappliedPayment && paymentAmount > row.amount && trxnType !== "CM") {
                  paymentAmount = row.amount;
                }
                this.setState({
                  invoices: replaceAt(this.state.invoices, rowIndex, {
                    ...this.state.invoices[rowIndex],
                    paymentAmount
                  })
                });
              }
            }}
            onBlur={() => {
              let splitledgerHash = this.state.invoices[rowIndex].ledgerHash.split('-');
              let rowInvoicekey = splitledgerHash[splitledgerHash.length - 1];
              const rowPaymentAmount = this.state.invoices[rowIndex].paymentAmount;
              if (Number.parseInt(rowPaymentAmount) === 0) {
                this.setState({
                  invoices: replaceAt(this.state.invoices, rowIndex, {
                    ...this.state.invoices[rowIndex],
                    paymentAmount: ""
                  })
                });
              } else if (rowPaymentAmount !== "") {
                this.setState({
                  invoices: replaceAt(this.state.invoices, rowIndex, {
                    ...this.state.invoices[rowIndex],
                    paymentAmount: Number.parseFloat(rowPaymentAmount).toFixed(2)
                  })
                });

              }
              setTimeout(() => {
                this.getTransactionFee();
              }, 0);

            }}
          />
        )
      });

      columns.splice(6, 0, { content: row => null });
    }

    let partialPaymentsWidths;

    /* if (columns.find(col => col.name === "customer-name")) {
       partialPaymentsWidths = ["3%", "15%", "15%", "9rem", "12%", "8rem", "1.4rem", "1.4rem", "12%"];
     } else {
       partialPaymentsWidths = ["3%", "13%", "15%", "7rem", "4%", "8rem", "3%", "14%", "10%"];
     }
 
     columns = columns.map((col, i) => {
       const _col = { ...col };
 
       if (allowPartialPayments === true) {
         _col.width = partialPaymentsWidths[i];
       }
 
       return _col;
     });
 */
    let paymentMethods = this.getPaymentMethods();


    let errorMessage = null;
    if (!isEmpty(this.state.paymentErrorCode)) {
      errorMessage = (
        <PaymentErrorCode
          errorCode={this.state.paymentErrorCode}
          errorMessage={this.state.paymentErrorCode === paymentErrorCodes.OTHER ? this.state.paymentErrorMessage : null}
        />
      );
    }

    return (
      <React.Fragment>
        <div className="flex">
          <div className="mr-4" style={{ height: "fit-content" }}>
            <div className="portal-input-label">{Resources.PayFrom}</div>
            <SelectInput
              name="select-payment-method"
              value={this.state.selectedPaymentMethod}
              displayValue={
                isEmpty(this.state.selectedPaymentMethod.name)
                  ? (this.state.selectedPaymentMethod.lastFourDigits === undefined ? Resources.SelectPaymentMethod
                    : `${this.state.selectedPaymentMethod.paymentType === "ACH"
                      ? Resources.BankAccount
                      : Resources.CreditCard
                    } ...${this.state.selectedPaymentMethod.lastFourDigits}`)
                  : this.state.selectedPaymentMethod.name
              }
              options={paymentMethods}
              onSelectOption={option => {
                this.setState({ selectedPaymentMethod: option, paymentErrorCode: null, paymentErrorMessage: null });
                setTimeout(() => {
                  this.getTransactionFee();
                }, 0);

              }}
              optionDisplayKey={"displayValue"}
              callToActionText={Resources.AddNewPaymentMethod}
              callToActionOnSelect={this.addNewPaymentMethod}
              width="20rem"
            />
          </div>
          {selectedInvoices.length === 0 && (
            <div className="mx-4" style={{ height: "fit-content" }}>
              <div className={`portal-input-label ${this.state.focusedField === "amount" ? " focused" : ""}`}>
                {Resources.PaymentAmount}
              </div>
              <TextInput
                className="mb-0"
                dollarAmountInput
                textValue={this.state.totalAmount}
                inputOnChange={e => {
                  if (isValidDollarAmount(e.target.value)) {
                    this.setState({
                      totalAmount: e.target.value
                    });
                  }
                }}
                onBlur={() => {
                  onBlurCheckFocusable(() => this.setState({ focusedField: null }));
                  if (Number.parseInt(this.state.totalAmount) === 0) {
                    this.setState({ totalAmount: "" });
                  } else if (this.state.totalAmount !== "") {
                    this.setState({
                      totalAmount: Number.parseFloat(this.state.totalAmount)
                        .toFixed(2)
                        .toString()
                    });
                  }
                }}
                onFocus={() => this.setState({ focusedField: "amount" })}
              />
            </div>
          )}
          {
            this.props.selectedInvoices.length == 0 ?
              (<div className="mr-4" style={{ height: "fit-content" }}>
                <div className="portal-input-label">{"Currency"}</div>
                <div>
                  {this.renderCurrency(this.state.merchantcurrid)}
                </div>
              </div>) : ""
          }
        </div>
        {this.state.paymentErrorCode === paymentErrorCodes.CARD_DECLINED && <div className="">{errorMessage}</div>}
        <button
          data-test-id="make-payment__manage"
          className="button-secondary manage-payment-methods-button"
          onClick={() => this.setState({ paymentView: "managePaymentMethods" })}
          style={{
            marginTop: this.state.paymentErrorCode === paymentErrorCodes.CARD_DECLINED ? 0 : "0.66rem"
          }}
        >
          {Resources.ManagePaymentMethods}
          <IconAngleRight height={9} strokeWidth="3" style={{ marginRight: "0.65rem", marginLeft: "0.65rem" }} />
        </button>
        {selectedInvoices.length > 0 ? (
          <div className="flyout-table-container">
            <div className="fw-500" style={{ marginBottom: "0.9rem" }}>
              {Resources.InvoicesSelectedForPayment(this.state.selectedRows.length)}
            </div>

            {(this.props.filteredOutCreditMemo || this.props.allAccountsSelected) && (
              <p style={{ marginBottom: "1rem", fontSize: "0.933rem" }}>
                {Resources.MakePaymentFlyoutFilteredOutCreditMemo}
              </p>
            )}
            <TableData
              noSort
              noGrow
              pagination
              data={this.state.invoices}
              columns={columns}
              rowHeight="4rem"
              rowClassName="statements-view-row no-hover"
              selectedRows={this.state.selectedRows}
              onRowSelectToggle={i => this.toggleSelectRow(i)}
              useRedux
            />

            {
              this.state.isSurchargeApplicable && !this.state.surchargeErrorMessage && this.state.selectedRows.length ?
                <>
                  <div className="fw-500" style={{ fontSize: "1.33rem", marginTop: "2rem" }}>
                    {Resources.TotalInvoiceAmount(formatCurrency(totalAmount, statementsStore.selectedCurrency))}
                  </div>
                  <div className="fw-500" style={{ fontSize: "1.33rem", marginTop: "2rem" }}>
                    {this.state.transactionFeeResponsesLoaded ? Resources.TotalTransactionFee(formatCurrency(this.state.totalFee, statementsStore.selectedCurrency)) : <>Total transaction fee: &nbsp;&nbsp;<div className="full-screen-spinner" style={{ width: "4px", fontSize: "4px", top: "2px" }} /></>}
                  </div>
                  <div className="fw-500" style={{ fontSize: "1.33rem", marginTop: "2rem" }}>
                    {this.state.transactionFeeResponsesLoaded ? Resources.TotalPaymentAmount(formatCurrency(totalAmount + this.state.totalFee, statementsStore.selectedCurrency)) : <>Total payment amount: &nbsp;&nbsp;<div className="full-screen-spinner" style={{ width: "4px", fontSize: "4px", top: "2px" }} /></>}
                  </div>
                </>
                :
                <div className="fw-500" style={{ fontSize: "1.33rem", marginBottom: "5.3rem", marginTop: "2rem" }}>
                  {Resources.TotalPaymentAmount(formatCurrency(totalAmount, statementsStore.selectedCurrency))}
                </div>
            }

          </div>
        ) : (
          <div className="payment-editor-container">
            <div className={`portal-input-label ${this.state.focusedField === "message" ? " focused" : ""}`}>
              {Resources.Message}
            </div>
            <HtmlEditor
              onFocus={() => this.setState({ focusedField: "message" })}
              onBlur={e => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
              hideToolbar={this.state.focusedField !== "message"}
              htmlContent={this.state.htmlBody}
              updateHtmlContent={htmlBody => this.setState({ htmlBody })}
              updateTextContent={textBody => this.setState({ textBody })}
            />
          </div>
        )}

        {
          this.state.isSurchargeApplicable && this.state.surchargeErrorMessage && this.state.surchargeStatusCode !== 5 && !isEmpty(this.state.selectedPaymentMethod.name)?
            <div className="payment-error" style={{ marginBottom: "1rem", marginTop: "2rem" }}>
              <IconAlertCircle height={18} className="payment-error__icon" />
              <span className="payment-error__message">{this.state.surchargeErrorMessage}</span>
            </div> : <></>

        }
        {this.state.paymentErrorCode !== paymentErrorCodes.CARD_DECLINED && errorMessage}

        {this.state.isSurchargeApplicable && this.state.surchargeStatusCode !== 1 && this.state.surchargeStatusCode !== 5 && !isEmpty(this.state.selectedPaymentMethod.name)?
          <p style={{ marginBottom: "1rem", marginTop: "1rem", fontSize: "0.933rem", color: "red" }}>
            {Resources.ReminderForTransactionFee.toUpperCase()}
          </p> : <></>
        }
        {(this.state.isSurchargeApplicable && this.state.surchargeStatusCode === 5 && !isEmpty(this.state.selectedPaymentMethod.name)) &&
          <p style={{ marginBottom: "1rem", marginTop: "1rem", fontSize: "0.933rem", color: "red" }}>
            REMINDER: {this.state.ReminderForOneOrMoreNegativeTransaction.toUpperCase()}
          </p>
        }

        <button
          data-test-id="make-payment__pay"
          className="portal-button-green"
          onClick={this.makePayment}
          disabled={
            (this.state.isSurchargeApplicable && this.state.surchargeErrorMessage && this.state.surchargeStatusCode !== 5)
            ||
            (this.state.isSurchargeApplicable && !this.state.transactionFeeResponsesLoaded && this.state.surchargeStatusCode !== 5)
            ||
            (
              (this.state.selectedRows.length < 1 || totalAmount < 0) &&
              (isEmpty(this.state.totalAmount) || isEmpty(this.state.textBody))
            )
            ||
            (
              (this.state.selectedRows.length >= 1) && (totalAmount === 0 && (invCount === 0 || cmCount === 0 || invSum === 0 || cmSum === 0))
            )
            || (this.props.selectedInvoices.length == 0 && (this.state.selectcurrid=== "" || this.state.selectcurrid === "Pick Currency")) 
          }
          style={{ margin: "10px 0" }}
        >
          {
            (!this.props.allAccountsSelected && invCount > 0 && cmCount > 0 && totalAmount === 0 && invSum != 0 && cmSum != 0) ?
              (Resources.Apply) :
              [Resources.Pay]
          }
        </button>
      </React.Fragment>
    );
  }

  renderAddOrEditMethodContent = () => {
    if (isEmpty(this.state.iframeUrl)) {
      return (
        <div className="flex-center flex-align-center payment-method-iframe">
          <MainLoader fullscreen />
        </div>
      );
    }

    const hostname = window.location.hostname;

    return (
      <iframe
        id="newPaymentIframe"
        src={this.state.iframeUrl}
        title="New Payment method"
        className="payment-method-iframe"
        onLoad={() => {
          const iframe = document.getElementById("newPaymentIframe");
          this.setState((prev) =>
            ({ paymentMethodPageNumber: prev.paymentMethodPageNumber + 1 })
          );
          try {
            if (includes(iframe.contentWindow.location.href, hostname)) {
              this.props.displayNotification("addingPaymentMethodNotification");
              this.setState({ paymentView: "main", iframeUrl: null });
			  this.props.fetchPaymentInfo(this.props.companyId, this.props.withCompanyId).then(res => {
                const paymentInfo = this.props.statementsStore.paymentInfo[this.props.withCompanyId].paymentMethods;
                if (paymentInfo.length > 0) {
                  this.setState({
                    selectedPaymentMethod: paymentInfo[paymentInfo.length - 1]
                  });
                }
              });
            }
          } catch(e) {
            console.log(e)
            return;
          }
        }}
      ></iframe>
    );
  }


  confirmDeletePaymentMethodCallback = (passedPmtMethodKey) => {
    const { withCompanyId, statementsStore } = this.props;
    const paymentInfo = this.props.nationalAccount ? statementsStore.paymentInfo[this.state.primaryCompanyId] : statementsStore.paymentInfo[withCompanyId];

    const autoPayConfig = paymentInfo.automaticPaymentConfiguration || {};
    autoPayConfig.automaticPaymentConfiguration = null;
    autoPayConfig.automaticPaymentEnabled = false;

    if (this.props.nationalAccount) {
      this.props.deletePaymentMethod(this.state.primaryCompanyId, this.props.withCompanyId, passedPmtMethodKey);
    } else {
      this.props.deletePaymentMethod(this.props.companyId, this.props.withCompanyId, passedPmtMethodKey);
    }

    this.props.hideModal();
  }

  checkForAutoPayRecord = (passedPmtMethodKey) => {
    const { withCompanyId, statementsStore } = this.props;
    const paymentInfo = statementsStore.paymentInfo[withCompanyId];
    const autoPayConfig = paymentInfo.automaticPaymentConfiguration || {};

    let autoPayMethod;
    autoPayMethod = find(
      paymentInfo.paymentMethods,
      pMethod => pMethod.paymentMethodId.toString() === (autoPayConfig.pmtMethodKey || "").toString()
    );
    if (!isEmpty(autoPayMethod) && autoPayMethod.paymentMethodId === passedPmtMethodKey) {
      this.props.displayModal("confirmModal", {
        confirmMessage: Resources.DeleteAutoPayMethodConfirm,
        onConfirm: () => this.confirmDeletePaymentMethodCallback(passedPmtMethodKey)
      });
    } else {
      this.state.nationalAccount ?
        this.props.deletePaymentMethod(this.state.primaryCompanyId, this.props.withCompanyId, passedPmtMethodKey) :
        this.props.deletePaymentMethod(this.props.companyId, this.props.withCompanyId, passedPmtMethodKey);
    }
  }

  renderManagePaymentMethodsContent = () => {
    const paymentMethods = this.getPaymentMethods();
    const columns = [
      {
        header: Resources.PaymentMethod,
        content: row => (
          <span>
            <span className="fw-500">{row.displayValue}</span>
            {moment(row.expirationDate, "MM/YY").isBefore() && (
              <span className="expired-label">{Resources.Expired.toLocaleUpperCase()}</span>
            )}
          </span>
        ),
        width: "35%"
      },
      {
        header: Resources.DateAdded,
        content: row => (new Date(row.updateDate).getTime() > new Date(row.insertDate).getTime()) ? formatDate(row.updateDate, false, false) : formatDate(row.insertDate, false, false),
        width: "18%"
      },
      {
        header: Resources.Type,
        content: row => (row.paymentType === "ACH" ? Resources.ACH : Resources.CreditCard),
        width: "18%"
      },
      {
        width: "30%",
        content: row => (
          <div className="flex-end flex-align-center">
            <button
              data-test-id="make-payment__default"
              className="button-action-icon"
              onClick={() =>
                this.props
                  .addOrUpdatePaymentMethod(this.props.companyId, this.props.withCompanyId, {
                    paymentMethodId: row.paymentMethodId,
                    isDefault: true
                  })
                  .then(res => {
                    this.props.fetchPaymentInfo(this.props.companyId, this.props.withCompanyId);
                  })
                  .catch(err => {
                    console.log("Making default payment method error");
                  })
              }
            >
              <IconStar height="21" isFilled={row.isDefault} />
            </button>
            <button
              data-test-id="make-payment__edit"
              className="button-action-icon"
              onClick={() => {
                this.setState({ paymentView: "editPaymentMethod", isEditPaymentBtnClicked: true });
                this.props
                  .addOrUpdatePaymentMethod(this.props.companyId, this.props.withCompanyId, {
                    paymentMethodId: row.paymentMethodId
                  })
                  .then(res => {
                    this.setState({ iframeUrl: res.redirectUrl });
                  })
                  .catch(err => null);
              }}
            >
              <IconEdit height="21" />
            </button>
            <button
              data-test-id="make-payment__trash"
              className="button-action-icon"
              onClick={() => this.checkForAutoPayRecord(row.paymentMethodId)}
            >
              <IconTrash height="21" />
            </button>
          </div>
        )
      }
    ];

    const tableHeight = paymentMethods.length > 6 ? "28rem" : `${4 * paymentMethods.length + 2}rem`;
    return (
      <div className="flyout-table-container">
        <button
          data-test-id="make-payment__add-new-pmt"
          className="button-primary mb-5"
          onClick={this.addNewPaymentMethod}
        >
          <IconPlusCircle height="20" className="button-primary-icon" />
          {Resources.AddNewPaymentMethod}
        </button>

        {!isEmpty(paymentMethods) && (
          <TableData
            noGrow
            data={paymentMethods}
            columns={columns}
            rowHeight="4rem"
            maxHeight={tableHeight}
            rowClassName="statements-view-row no-hover"
          />
        )}
        <button
          data-test-id="make-payment__done"
          className="portal-button-green"
          style={{ marginTop: "5.3rem" }}
          onClick={() => {
            this.setState({ paymentView: "main" });
          }}
        >
          {Resources.Done}
        </button>
      </div>
    );
  }

  renderMakingPaymentContent = () => {
    const { companyId, withCompanyId, perspectiveId, pageRowCount } = this.props;

    const loadingState = (
      <div className="flex-center flex-align-center payment-method-iframe">
        <MainLoader fullscreen />
      </div>
    );

    return (
      (isEmpty(this.state.iframeUrl) && !this.state.iframeLoaded) ? (<div className="flex-center flex-align-center payment-method-iframe">
        <MainLoader PaymentIsInProcess />
      </div>) :
        <React.Fragment>
          <iframe
            id="makingPaymentIframe"
            src={this.state.iframeUrl}
            title="Making payment"
            className="payment-method-iframe"
            onLoad={() => {
              const iframe = document.getElementById("makingPaymentIframe");
              try {
                const href = iframe.contentWindow.location.href;

                if (includes(href, "/payment/failure")) {
                  const errorMessage1 = href.slice(href.lastIndexOf("/") + 1);
                  const idx = errorMessage1.indexOf("-");
                  let errorMessage = errorMessage1;
                  if (idx >= 0 && idx < 5) {
                    errorMessage = errorMessage1.slice(2);
                  }

                  if (errorMessage[0] === paymentErrorCodes.CARD_DECLINED) {
                    this.setState({
                      paymentView: "main",
                      iframeUrl: null,
                      paymentErrorCode: paymentErrorCodes.CARD_DECLINED,
                      paymentErrorMessage: decodeURLQueryString(errorMessage)
                    });
                  } else {
                    this.setState({
                      paymentView: "main",
                      iframeUrl: null,
                      paymentErrorCode: paymentErrorCodes.OTHER,
                      paymentErrorMessage: decodeURLQueryString(errorMessage)
                    });
                  }
                } else if (includes(href, "/payment/success")) {
                  this.props.fetchUnappliedOrPendingPayments(
                    companyId,
                    perspectiveId,
                    withCompanyId,
                    null,
                    null,
                    pageRowCount
                  );

                  const transactionId = decodeURLQueryString(href.slice(href.lastIndexOf("/") + 1));
                  this.setState({ paymentView: "paymentConfirmation", transactionId });
                }
              } catch (e) {
                window.logToConsole && console.log(e);
                return;
              }
            }}
          ></iframe>
        </React.Fragment>
    );
  }

  renderPaymentConfirmationContent = () => {
    const columns = this.props.columns.slice(1);
    let totalAmount = this.state.invoices.reduce((accum, curr, index) => {
      if (!includes(this.state.selectedRows, index)) {
        return accum;
      }
      return accum + (Number.parseFloat(curr.paymentAmount) || 0);
    }, 0);

    if (isEmpty(this.state.invoices)) {
      totalAmount = Number.parseFloat(this.state.totalAmount);
    }

    // Ensures no weird JS decimal stuff
    totalAmount = Math.round(totalAmount * 1e12) / 1e12;

    const paidInvoices = this.state.invoices.filter((inv, i) => includes(this.state.selectedRows, i));

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={() => this.hideFlyout(true)}
        onAfterClose={this.props.hideFlyout}
        className="flyout make-payment-flyout"
        overlayClassName="flyout-overlay"
        closeTimeoutMS={400}
      >
        <div className="flex-end">
          <div
            onClick={() => {
              this.hideFlyout(true);
              this.props.clearSelectedRows();
            }}
            className="flyout-heading-close"
          >
            <IconClose />
          </div>
        </div>
        <PaymentConfirmationMessage transactionId={this.state.transactionId || "342"} />
        <div className="confirmation-payment-divider" />

        <div className="flyout-content">
          <div style={{ marginBottom: "2.8rem" }}>
            <div className="d-inline-block" style={{ marginRight: "8.06rem" }}>
              <div className="portal-input-label">{Resources.PaidFrom}</div>
              <div className="payment-field">{`${this.state.selectedPaymentMethod.paymentType === "ACH" ? Resources.BankAccount : Resources.CreditCard
                } ...${this.state.selectedPaymentMethod.lastFourDigits}`}</div>
            </div>
            <div className="d-inline-block">
              <div className="portal-input-label">{Resources.PaymentDate}</div>
              <div className="payment-field">{formatDate(new Date(), false, false)}</div>
            </div>
            {paidInvoices.length === 0 && (
              <div className="d-inline-block" style={{ marginLeft: "8.06rem" }}>
                <div className="portal-input-label">{Resources.PaymentAmount}</div>
                <div className="payment-field">{formatCurrency(totalAmount, this.props.statementsStore.selectedCurrency)}</div>
              </div>
            )}
          </div>
          {paidInvoices.length > 0 ? (
            <React.Fragment>
              <div className="fw-500" style={{ marginBottom: "1.46rem" }}>
                {Resources.InvoicesPaid(this.state.selectedRows.length)}
              </div>
              <TableData
                noSort
                noGrow
                pagination
                data={paidInvoices}
                columns={columns}
                rowHeight="4rem"
                rowClassName="statements-view-row no-hover"
              />
              <div className="fw-500" style={{ fontSize: "1.33rem", marginBottom: "5.3rem", marginTop: "2rem" }}>
                {Resources.TotalPaymentAmount(formatCurrency(totalAmount, this.props.statementsStore.selectedCurrency))}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="portal-input-label">{Resources.Message}</div>
              <div className="payment-field">{this.state.textBody}</div>
            </React.Fragment>
          )}
        </div>
      </Modal>
    );
  }

  handleBackButtonForPaymentMethod = () => {
    const iframe = document.getElementById('newPaymentIframe');
    if (this.state.isEditPaymentBtnClicked) {
      this.setState({ paymentView: "managePaymentMethods", paymentMethodPageNumber: 0, isEditPaymentBtnClicked: false });
    }
    else if (this.state.paymentMethodPageNumber < 1) {
      this.setState({
        paymentView: "main",
        iframeUrl: null,
        paymentErrorCode: null,
        paymentErrorMessage: null,
        paymentMethodPageNumber: 0
      });
    }
    else if (this.state.paymentMethodPageNumber > 1) {
      iframe.setAttribute('src', this.state.iframeUrl);
      this.setState({ paymentMethodPageNumber: 0 });
    }
    else {
      this.setState({
        paymentView: "main",
        iframeUrl: null,
        paymentErrorCode: null,
        paymentErrorMessage: null,
        paymentMethodPageNumber: 0
      });
    }
  }

  render() {
    let header = "";
    let content = null;
    const goBackButton = (
      <span style={{ display: "inline-flex", marginRight: "2.15rem" }}>
        <IconAngleLeft className='go-back-btn' changepaymentview={this.handleBackButtonForPaymentMethod} />
      </span>
    );

    switch (this.state.paymentView) {
      case "addPaymentMethod":
        header = (
          <div className="d-flex align-items-center">
            {goBackButton}
            {Resources.AddNewPaymentMethod}
          </div>
        );
        content = this.renderAddOrEditMethodContent();
        break;
      case "editPaymentMethod":
        header = (
          <div className="d-flex align-items-center">
            {goBackButton}
            {Resources.EditPaymentMethod}
          </div>
        );
        content = this.renderAddOrEditMethodContent();
        break;
      case "managePaymentMethods":
        header = (
          <div className="d-flex align-items-center">
            {goBackButton}
            {Resources.ManagePaymentMethods}
          </div>
        );
        content = this.renderManagePaymentMethodsContent();
        break;

      case "makingPayment":
        header = Resources.MakeAPayment;
        content = this.renderMakingPaymentContent();
        break;
      case "paymentConfirmation":
        return this.renderPaymentConfirmationContent();
      case "main":
      default:
        header = Resources.MakeAPayment;
        content = this.renderMainContent();
        break;
    }

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={() => this.hideFlyout(true)}
        onAfterClose={this.props.hideFlyout}
        className="flyout make-payment-flyout"
        overlayClassName="flyout-overlay"
        closeTimeoutMS={400}
      >
        <div className="flyout-heading">
          <div>{header}</div>
          <div onClick={() => this.hideFlyout(true)} className="flyout-heading-close">
            <IconClose />
          </div>
        </div>
        <div className="flyout-content">{content}</div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    accountsStore: store.accounts,
    modalStore: store.modal,
    statementsStore: store.statements,
    pageRowCount: store.general.pageRowCount
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(MakePaymentFlyout));
