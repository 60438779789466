import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { dispatchToProps as paDP } from "../../../../../store/perspectives-actions";
import { dispatchToProps as uaDP } from "../../../../../store/user-actions";
import { dispatchToProps as gaDP } from "../../../../../store/general-actions";
import Resources from "../../../../../lib/resources";
import IconClose from "../../../../library/icons/iconClose";
import IconAngleLeft from "../../../../library/icons/iconAngleLeft";
import { SettingsViews } from "../settingsControllerFlyout";
import PerspectiveEmailSettings from "./perspectiveEmailSettings";
import { getPartnerType } from "../../../../../lib/perspectives";

const dispatchToProps = dispatch => {
  return {
    ...paDP(dispatch),
    ...uaDP(dispatch),
    ...gaDP(dispatch)
  };
};

const getDefaultPerspective = store => {
  const perspectives = store.perspectives.companyPerspectives;

  return (
    perspectives.find(({ perspectiveId }) => perspectiveId === store.user.userConfig.defaultPerspective) ||
    perspectives[0]
  );
};

const EmailSettings = ({ hideFlyout, setSettingsView, getUserConfig }) => {
  const companyId = useSelector(store => store.accounts.selectedCompany.companyId);
  const perspectives = useSelector(store => store.perspectives.companyPerspectives);
  const defaultPerspective = useSelector(getDefaultPerspective);

  useEffect(() => {
    getUserConfig();
  }, [getUserConfig]);

  const [selectedPerspective, setSelectedPerspective] = useState(defaultPerspective);

  return (
    <React.Fragment>
      <div className="settings-flyout__header flyout-heading">
        <IconAngleLeft onClick={() => setSettingsView(SettingsViews.SETTINGS)} className="settings-flyout__back" />
        <h2>{Resources.EmailSettings}</h2>
        <div onClick={hideFlyout} className="flyout-heading-close">
          <IconClose />
        </div>
      </div>

      <div className="view-content-header-links">
        {perspectives.map((perspective, i) => (
          <div
            className={`content-header-link ${selectedPerspective === perspective ? "active" : "clickable"}`}
            onClick={() => setSelectedPerspective(perspective)}
            key={i}
          >
            {getPartnerType(perspective.perspectiveName, true)}
          </div>
        ))}
      </div>
      <div className="nav-tabs mb-4" />
      <PerspectiveEmailSettings perspective={selectedPerspective} companyId={companyId} />
    </React.Fragment>
  );
};

export default withRouter(connect(null, dispatchToProps)(EmailSettings));
