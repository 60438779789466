import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { setSelectedCurrency } from "../../store/statements-actions";

export default function StatementCurrencySelector(props) {
  const dispatch = useDispatch();

  const { displayCounts = {} } = props;
  const selectedCurrency = useSelector(store => store.statements.selectedCurrency);
  const currencies = useSelector(store => store.statements.currencies);

  return (
    <div className="customer-portal-currency-selector">
      {currencies.map((currency, index)=> (
        <span
          key={index}
          className={`folder-link ${currency === selectedCurrency ? "active" : ""}`}
          onClick={() => dispatch(setSelectedCurrency(currency))}
        >
          {currency + (!!displayCounts && !!displayCounts[currency] ? ` (${displayCounts[currency]})` : "")}
        </span>
      ))}
    </div>
  );
}
