import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../../../../lib/resources";

import RadioInput from "./../../../library/radioInput";

import { dispatchToProps as uaDP } from "../../../../store/user-actions";
import { dispatchToProps as gaDP } from "../../../../store/general-actions";
import { isEmpty } from "../../../../lib/utils";
import MainLoader from "./../../../mainLoader";
import IconAngleLeft from "../../../library/icons/iconAngleLeft";
import { SettingsViews } from "./settingsControllerFlyout";
import IconClose from "../../../library/icons/iconClose";

const dispatchToProps = dispatch => {
  return {
    ...uaDP(dispatch),
    ...gaDP(dispatch)
  };
};

class NotificationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationFrequency: "daily",
      readOnly: true
    };

    this.save = this.save.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.userStore.userConfig)) {
      this.setState({ notificationFrequency: this.props.userStore.userConfig.notificationFrequency || "daily" });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userStore.userConfig !== prevProps.userStore.userConfig) {
      this.setState({
        notificationFrequency: this.props.userStore.userConfig.notificationFrequency || "daily"
      });
    }
  }

  save() {
    this.props.updateUserConfig({ notificationFrequency: this.state.notificationFrequency });
  }

  render() {
    const notificationFrequencyRadios = [
      { label: Resources.DailyWeekdays, value: "daily" },
      { label: Resources.WeeklyMondays, value: "weekly" },
      { label: Resources.Never, value: "never" }
    ];

    return (
      <React.Fragment>
        <div className="settings-flyout__header flyout-heading">
          <IconAngleLeft
            onClick={() => this.props.setSettingsView(SettingsViews.SETTINGS)}
            className="settings-flyout__back"
          />
          <h2>{Resources.ActivitySummaryEmails}</h2>
          <div onClick={this.props.hideFlyout} className="flyout-heading-close">
            <IconClose />
          </div>
        </div>
        {this.props.userStore.gettingUserConfig || this.props.userStore.updatingUserConfig ? (
          <MainLoader fullScreen />
        ) : (
          <div className="manage-content">
            <div>
              <label className="notifications-subtext">{Resources.NotificationSettingsSubtext}</label>
              <RadioInput
                inputClassName="w-45"
                radioName="workspaces"
                inputs={notificationFrequencyRadios}
                value={this.state.notificationFrequency}
                onChange={item => this.setState({ notificationFrequency: item.value })}
              />

              <div className="flex-row mt-4">
                <button className="button-primary-square notifications-button" onClick={this.save}>
                  {Resources.Save}
                </button>
                {this.state.readOnly === false && (
                  <button
                    className="button-cancel ml-1"
                    onClick={() =>
                      this.setState({
                        notificationFrequency: (this.props.userStore.userConfig || {}).notificationFrequency || "daily",
                        readOnly: true
                      })
                    }
                  >
                    {Resources.Cancel}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    userStore: store.user
  };
};

export default connect(storeToProps, dispatchToProps)(NotificationSettings);
