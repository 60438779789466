import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Resources from "../../lib/resources";
import {
  formatDate,
  formatCurrency,
  isEmpty,
  updateSelectedRows,
  openUrlForDownload,
  find,
  getCurrencySymbol,
  isCustomerPortal
} from "../../lib/utils";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";

import { dispatchToProps as saDP } from "../../store/statements-actions";
import { dispatchToProps as aaDP } from "../../store/accounts-actions";
import { dispatchToProps as cgDP } from "../../store/contextGroups-actions";
import { dispatchToProps as paDP } from "../../store/perspectives-actions";
import { dispatchToProps as modDP } from "../../store/modal-actions";
import { dispatchToProps as ledgDP } from "../../store/ledger-actions";

import TableData from "../library/tableData";
import TableDataSortableHeader from "../library/tableDataSortableHeader";
import Card from "../library/card";
import AutoCompleteInput from "../library/autoCompleteInput";
import MainLoader from "../mainLoader";
import IconMessage from "../library/icons/iconMessage";
import IconDownload from "../library/icons/iconDownload";
import IconExport from "../library/icons/iconExport";
import IconFilter from "../library/icons/iconFilter";
import IconArrowRight from "../library/icons/iconArrowRight";
import IconAttachment from "../library/icons/iconAttachment";
import StatementCurrencySelector from "./statementCurrencySelector";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import data from "../../lib/data";
const dispatchToProps = dispatch => {
  return {
    ...dispatch(saDP),
    ...dispatch(aaDP),
    ...dispatch(cgDP),
    ...dispatch(paDP),
    ...dispatch(modDP),
    ...dispatch(ledgDP)
  };
};

class ClosedInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.defaultState,
      closedInvcColViewSett: []
    };

    this.toggleSelectRow = this.toggleSelectRow.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.exportRows = this.exportRows.bind(this);
  }

  defaultState = {
    selectedRows: [],
    selectedKeys: [],
    searchTerm: "",
    fromDate: "2017-01-01",
    toDate: moment()
      .startOf("day")
      .format("YYYY-MM-DD"),
    fromDueDate: "2017-01-01",
    toDueDate: moment()
      .startOf("day")
      .format("YYYY-MM-DD")
  };

  componentDidMount() {
    const { perspectiveId, withCompanyId, pageRowCount, companyId, allAccountsSelected } = this.props;
    let endpoint = 'v3/api/Invoices/DefaultSelfServiceCustomerView/00000000-0000-0000-0000-000000000000';
   
   data
    .get(endpoint)
    .then(res => {
      const closedInvcColViewSett = res.data.colSettings.columnSettings;      
      this.setState({closedInvcColViewSett})
      if(res.data.defaultSortOrder.length >0 && res.data.defaultSortOrder[0].columnName!='' && res.data.defaultSortOrder[0].sortOrder!='' )
      {      
        this.props.updateClosedInvoicesSort(res.data.defaultSortOrder[0].columnName,res.data.defaultSortOrder[0].sortOrder);
      }
      this.props.clearStatementsSearchResults();
      this.props.fetchClosedInvoices(companyId, perspectiveId, withCompanyId, null, null, pageRowCount, null, null, allAccountsSelected);
      this.tryUpdate();
    })   
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps) {
    const {
      perspectiveId,
      withCompanyId,
      pageRowCount,
      companyId,
      allAccountsSelected,
      statementsStore: { closedFromDate, closedToDate, closedFromDueDate, closedToDueDate, closedInvoicesSkip }
    } = this.props;

    if (isEmpty(perspectiveId) || isEmpty(withCompanyId)) {
      return;
    }

    if (this.sortHasChanged(prevProps) || this.dateFilterHasChanged(prevProps) || this.selectedCurrencyHasChanged()) {
      let filter = null;
      if (!isEmpty(closedFromDate)) {
        filter = `IssueDate%20>=%20${closedFromDate}%20and%20IssueDate%20<=%20${closedToDate}`;
      }

      this.props.fetchClosedInvoices(
        companyId,
        perspectiveId,
        withCompanyId,
        closedFromDueDate,
        closedToDueDate,
        pageRowCount,
        closedInvoicesSkip,
        filter,
        allAccountsSelected
      );
    }
  }

  sortHasChanged(prevProps) {
    if (isEmpty(prevProps)) {
      return false;
    }
    if (
      this.props.statementsStore.closedInvoicesSortDirection !==
        prevProps.statementsStore.closedInvoicesSortDirection ||
      this.props.statementsStore.closedInvoicesSortBy !== prevProps.statementsStore.closedInvoicesSortBy
    ) {
      return true;
    }
    return false;
  }

  dateFilterHasChanged(prevProps) {
    if (isEmpty(prevProps)) {
      return false;
    }

    const {
      statementsStore: { closedFromDate, closedToDate, closedFromDueDate, closedToDueDate }
    } = this.props;

    if (
      closedFromDate !== prevProps.statementsStore.closedFromDate ||
      closedToDate !== prevProps.statementsStore.closedToDate ||
      closedFromDueDate !== prevProps.statementsStore.closedFromDueDate ||
      closedToDueDate !== prevProps.statementsStore.closedToDueDate
    ) {
      return true;
    }
    return false;
  }

  selectedCurrencyHasChanged() {
    if (
      this.props.statementsStore.selectedCurrency &&
      this.props.statementsStore.closedInvoicesCurrency &&
      this.props.statementsStore.selectedCurrency !== this.props.statementsStore.closedInvoicesCurrency
    ) {
      return true;
    }
    return false;
  }

  onSortChange() {}

  toggleSelectRow(key, row) {
    let newSelectedRows = updateSelectedRows(row, this.state.selectedRows);
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedKeys);
    this.setState({ selectedRows: newSelectedRows, selectedKeys: newSelectedKeys });
  }

  handleLoadMore(pageToLoad) {
    let { companyId, perspectiveId, withCompanyId, pageRowCount } = this.props;
    let top = pageRowCount;
    let skip = pageToLoad * pageRowCount - pageRowCount;
    this.props.fetchClosedInvoices(companyId, perspectiveId, withCompanyId, null, null, top, skip, null, this.props.allAccountsSelected);
  }

  exportRows(rows) {
    if (isEmpty(rows)) {
      return null;
    }
    let { companyId, perspectiveId, withCompanyId, pageRowCount, statementsStore, allAccountsSelected } = this.props;
    let { closedFromDate, closedToDate, closedFromDueDate, closedToDueDate } = statementsStore;

    let options = {
      fromDate: closedFromDate,
      toDate: closedToDate,
      fromDueDate: closedFromDueDate,
      toDueDate: closedToDueDate
    };

    this.props.displayNotification("exportNotification");
    this.props.fetchTableExport(
      companyId,
      perspectiveId,
      withCompanyId,
      "closed",
      pageRowCount,
      statementsStore.closedInvoicesSkip,
      rows.map(i => i.id),
      options,
      allAccountsSelected
    );
  }

  downloadAttachments(attachments, fileName) {
    if (attachments.length === 1) {
      openUrlForDownload(attachments[0].downloadUrl);
    } else if (attachments.length > 1) {
      this.props.displayNotification("downloadNotification");
      this.props.fetchAttachmentsZip(
        attachments.map(attachment => attachment.attachmentId),
        fileName
      );
    }
  }

  getClosedInvoicesColumns = hover => {
    let { statementsStore, allAccountsSelected } = this.props;
    let columnHeaderText = {}
    let closeinvoiceColumns = {}
    let cnt2 = 0;
    for(cnt2 = 0;cnt2<this.state.closedInvcColViewSett.length;cnt2++)
    {    
      let key =  this.state.closedInvcColViewSett[cnt2].dataField.toLocaleUpperCase();
      let headtext = this.state.closedInvcColViewSett[cnt2].headerText;
      columnHeaderText[key] = headtext;
    }  
    const selectColName = "select";
    const selectCol = {
      type: "rowSelect",
      name: selectColName,
      width: "10px"
    };
    closeinvoiceColumns[selectColName] = selectCol

    const customerColName = "CUSTOMERNAME";

    const customerNameCol = {
      header: noSort =>
        noSort ? (
          Resources.Account.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.Account.toLocaleUpperCase()}
            sortKey={"custName"}
          />
        ),
      sortable: noSort => noSort === false,
      className: "customer-name",
      content: row => <span className="fw-500 customer-name" title={row.customerName}>{row.customerName}</span>,
    //  width: "20%",
      name: "customer-name"
    };
    
    closeinvoiceColumns[customerColName] = customerNameCol;
    
    const invoiceDateColName = "TRANDATE";
    const invoiceDateCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.closedInvoicesSortBy}
          sortDirection={statementsStore.closedInvoicesSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updateClosedInvoicesSort(sortBy, sortDirection);
          }}
          text={columnHeaderText[invoiceDateColName]}
          sortKey={"issueDate"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => <span className="fw-500">{formatDate(row.issueDate, true, false)}</span>,
    //  width: "10%",
      name: invoiceDateColName
    };

    closeinvoiceColumns[invoiceDateColName] = invoiceDateCol;

    const invoiceNumColName = "TRANID";

    const invoiceNumCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.closedInvoicesSortBy}
          sortDirection={statementsStore.closedInvoicesSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updateClosedInvoicesSort(sortBy, sortDirection);
          }}
          text={columnHeaderText[invoiceNumColName]}
          sortKey={"id"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => {
        let hasAttachment = !isEmpty(row.attachments);

        return (
          <div className={`statements-invoice-id d-flex align-items-center ${hasAttachment ? "link" : ""}`}>
            <div className="flex-even overflow-ellipsis">
              {hasAttachment ? (
                <React.Fragment>
                  <button
                    className="invoice-num-attachment"
                    onClick={() => {
                      this.downloadAttachments(row.attachments, Resources.InvoiceNAttachments(row.id) + ".zip");
                    }}
                  >
                    <span className="invoice-num-attachment-link overflow-ellipsis" title={row.id}>
                      {row.id}
                    </span>
                    <IconAttachment height={15} style={{ marginLeft: "0.33rem", minWidth: "1rem" }} />
                  </button>
                </React.Fragment>
              ) : (
                <span className="overflow-ellipsis" title={row.id}>
                  {row.id}
                </span>
              )}
            </div>
            {(row.isDisputed || row.hasPromissoryNote) && (
              <div className="d-flex flex-column justify-content-start flex-even">
                {row.isDisputed && (
                  <div>
                    <button
                      className="button-indicator"
                      onClick={() => this.displayRelatedConversation(row.activeDispute)}
                      style={{ marginBottom: row.hasPromissoryNote ? "0.4rem" : "" }}
                    >
                      {Resources.Disputed}
                    </button>
                  </div>
                )}
                {row.hasPromissoryNote && (
                  <div>
                    <button
                      className="button-indicator"
                      onClick={() =>
                        this.displayRelatedConversation(
                          find(row.payments, payment => payment.paymentType === "Promise to Pay")
                        )
                      }
                    >
                      {Resources.Promised}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      },
    //  width: "30%",
      name: invoiceNumColName
    };
    closeinvoiceColumns[invoiceNumColName] = invoiceNumCol

    const amountPaidColName = "AMOUNTPAID";
    const amountPaidCol = {     
        header: noSort =>
        noSort ? (
          columnHeaderText[invoiceNumColName]
        ) :
        (<TableDataSortableHeader
          sortBy={statementsStore.closedInvoicesSortBy}
          sortDirection={statementsStore.closedInvoicesSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updateClosedInvoicesSort(sortBy, sortDirection);
          }}
          text={columnHeaderText[amountPaidColName]}
          sortKey={"AmountPaid"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId.toLocaleUpperCase())}</span>
          <span>{formatCurrency(row.amountPaid, "")}</span>
        </div>
      ),
      width: "130px",
      name: amountPaidColName
    };
    closeinvoiceColumns[amountPaidColName] = amountPaidCol;

    const spacerCol = { width: "0.1%", content: row => null,name: "empty" };

    closeinvoiceColumns["empty"] = spacerCol
    const paidDateColName = "PAIDDATE";
    const paidDateCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.closedInvoicesSortBy}
          sortDirection={statementsStore.closedInvoicesSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.updateClosedInvoicesSort(sortBy, sortDirection);
          }}
          text={columnHeaderText[paidDateColName]}
          sortKey={"paidDate"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => <span>{!isEmpty(row.paidDate) ? formatDate(row.paidDate, true, false) : ""}</span>,
    //  width: "10%",
      name: paidDateColName
    };
    closeinvoiceColumns[paidDateColName] = paidDateCol;

    const transactionsColName = "TRANSACTIONS";
    const transactionsCol = {
      header: columnHeaderText[transactionsColName],
      content: row => {
        let transactionIdContent;
        if (row.payments.length === 1) {
          transactionIdContent = ((row.payments || [])[0] || {}).id;
        } else if (row.payments.length > 1) {
          transactionIdContent = Resources.NTransactions(row.payments.length);
        } else {
          transactionIdContent = Resources.NTransactions(0);
        }
        return (
          <div className={"statements-transaction-id" + (row.payments.length > 0 ? " link" : "")}>
            {row.payments.length > 0 ? (
              <button
                className="statements-transaction-id-link"
                onClick={() => {
                  this.props.displayFlyout("paymentsFlyout", {
                    invoice: row
                  });
                }}
              >
                {transactionIdContent}
              </button>
            ) : (
              <span>{transactionIdContent}</span>
            )}
          </div>
        );
      },
    //  width: "10%",
      name: transactionsColName
    };
    closeinvoiceColumns[transactionsColName] = transactionsCol;

    const custPoNoColName = "CUSTPONO";
    const custPoNoCol =  {
      header: noSort =>
        noSort ? (
          columnHeaderText[custPoNoColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[custPoNoColName]}
            sortKey={"orderId"}
          />
        ),
      sortable: noSort => noSort === false,
     // width:"10%",
      content: row => <span>{row.orderId}</span>,      
      name: custPoNoColName
    };

    closeinvoiceColumns[custPoNoColName] = custPoNoCol;

    const indisputeYNColName = "INDISPUTEYN";
    const indisputeYNCol =  {
      header: noSort =>
        noSort ? (
          columnHeaderText[indisputeYNColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[indisputeYNColName]}
            sortKey={"isDisputed"}
          />
        ),
      sortable: noSort => noSort === false,
   //   width:"10%",
      content: row => <span>{row.isDisputed ? 'true':'false'}</span>,     
      name: indisputeYNColName
    };
    closeinvoiceColumns[indisputeYNColName] = indisputeYNCol

    const origTranAmtColName = "ORIGTRANAMT";

    const origTranAmtCol = {
      header: noSort =>
        noSort ? (
          columnHeaderText[origTranAmtColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[origTranAmtColName]}
            sortKey={"origTranAmt"}
          />
        ),
      sortable: noSort => noSort === false,
    //  width:"10%",
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId.toLocaleUpperCase())}</span>
          <span>{formatCurrency(row.origTranAmt, "")}</span>
        </div>
      ),      
      name: origTranAmtColName
    };    
    closeinvoiceColumns[origTranAmtColName] = origTranAmtCol;

    const udf1ColName = "INVUDF1";
    const udf1Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf1ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf1ColName]}
            sortKey={"udf1"}
          />
        ),
      sortable: noSort => noSort === false,
   //   width:"10%",
      content: row => <span>{row.udf1}</span>,    
      name: udf1ColName
    };
    closeinvoiceColumns[udf1ColName] = udf1Col 

    const udf2ColName = "INVUDF2";
    const udf2Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf2ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf2ColName]}
            sortKey={"udf2"}
          />
        ),
      sortable: noSort => noSort === false,
    //  width:"10%",
      content: row => <span>{row.udf2}</span>,    
      name: udf2ColName
    };
    closeinvoiceColumns[udf2ColName] = udf2Col 

    const udf3ColName = "INVUDF3";
    const udf3Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf3ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf3ColName]}
            sortKey={"udf3"}
          />
        ),
      sortable: noSort => noSort === false,
     // width:"10%",
      content: row => <span>{row.udf3}</span>,    
      name: udf3ColName
    };
    closeinvoiceColumns[udf3ColName] = udf3Col 

    const udf4ColName = "INVUDF4"
    const udf4Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf4ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf4ColName]}
            sortKey={"udf4"}
          />
        ),
      sortable: noSort => noSort === false,
    //  width:"10%",
      content: row => <span>{row.udf4}</span>,    
      name: udf4ColName
    };
    closeinvoiceColumns[udf4ColName] = udf4Col 

    const udf5ColName = "INVUDF5"
    const udf5Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf5ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf5ColName]}
            sortKey={"udf5"}
          />
        ),
      sortable: noSort => noSort === false,
    //  width:"10%",
      content: row => <span>{row.udf5}</span>,    
      name: udf5ColName
    };
    closeinvoiceColumns[udf5ColName] = udf5Col 

    const udf6ColName = "INVUDF6"

    const udf6Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf6ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf6ColName]}
            sortKey={"udf6"}
          />
        ),
      sortable: noSort => noSort === false,
    //  width:"10%",
      content: row => <span>{row.udf6}</span>,    
      name: udf6ColName
    };
    closeinvoiceColumns[udf6ColName] = udf6Col 

    const udf7ColName = "INVUDF7";
    const udf7Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf7ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf7ColName]}
            sortKey={"udf7"}
          />
        ),
      sortable: noSort => noSort === false,
     // width:"10%",
      content: row => <span>{row.udf7}</span>,    
      name: udf7ColName
    };
    closeinvoiceColumns[udf7ColName] = udf7Col 

    const udf8ColName = "INVUDF8";
    const udf8Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf8ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf8ColName]}
            sortKey={"udf8"}
          />
        ),
      sortable: noSort => noSort === false,
    //  width:"10%",
      content: row => <span>{row.udf8}</span>,    
      name: udf8ColName
    };
    closeinvoiceColumns[udf8ColName] = udf8Col 

    const udf9ColName = "INVUDF9"
    const udf9Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf9ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf9ColName]}
            sortKey={"udf9"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => <span>{row.udf9}</span>,   
    //  width:"10%",
      name: udf9ColName
    };
    closeinvoiceColumns[udf9ColName] = udf9Col 
    
    const udf10ColName = "INVUDF10";
    const udf10Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf10ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.closedInvoicesSortBy}
            sortDirection={statementsStore.closedInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateClosedInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf10ColName]}
            sortKey={"udf10"}
          />
        ),
      sortable: noSort => noSort === false,
     // width:"10%",
      content: row => <span>{row.udf10}</span>,    
      name: udf10ColName
    };
    closeinvoiceColumns[udf10ColName] = udf10Col     
    
   // let columns = [selectCol, invoiceDateCol, invoiceNumCol, amountPaidCol, spacerCol, transactionIdCol, datePaidCol];
   const columns = new Array()
   let cnt1 = 0;
   let cnt = 0;
   // alert(key);
   columns[cnt] = closeinvoiceColumns[selectColName]      
   ++cnt      
    for(cnt1 = 0;cnt1<this.state.closedInvcColViewSett.length;cnt1++)
    {    
      let key =  this.state.closedInvcColViewSett[cnt1].dataField.toLocaleUpperCase();
      let headtext = this.state.closedInvcColViewSett[cnt1].HeaderText;
      if(closeinvoiceColumns[key]!=null  && this.state.closedInvcColViewSett[cnt1].display == true )
      {      
        columns[cnt] = closeinvoiceColumns[key]              
        ++cnt      
      }
    } 
    
    let hoverColumns = new Array(); 
    let cnt3 = 0;  
    if(cnt>11)
    {
      cnt = 9;
    }
    for(cnt3 =0;cnt3<cnt;++cnt3)
    {
      hoverColumns[cnt3] = columns[cnt3];  
    }
    hoverColumns[++cnt3] = {
      content: (row, i) => (
        <div className="flex-end flex-align-center">
          {!allAccountsSelected &&(
            <button
              data-test-id="closed-invoices__message"
              className="button-action-icon data-Tooltip"
              data-tooltip={Resources.SendAMessage}
              onClick={() => {
                this.props.displayFlyout("messageFlyout", {
                  columns,
                  selectedInvoices: [row],
                  companyId: this.props.companyId,
                  perspectiveId: this.props.perspectiveId,
                  withContextGroupId: this.props.withContextGroupId,
                  withCompanyId: this.props.withCompanyId,
                  isPortal,
                  clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] }),
                  fetchTemplate: () => {
                    return this.props.fetchClosedInvoicesTemplate(
                      this.props.companyId,
                      this.props.perspectiveId,
                      this.props.withCompanyId,
                      { invoiceIds: [row.id] }
                    );
                  }
                });
              }}
            >
              <IconMessage height="20" className="icon-button" />
            </button>
          )}
          <button
            data-test-id="closed-invoices__export"
            className="button-action-icon data-Tooltip"
            data-tooltip={Resources.ExportInvoices}
            onClick={() => this.exportRows([row])}
          >
            <IconExport height="20" className="icon-button" />
          </button>
        </div>
      )
    };
   const isPortal = isCustomerPortal(this.props.history ? this.props.history.location.pathname : "");
  /*  const hoverColumns = [
      selectCol,
      invoiceDateCol,
      invoiceNumCol,
      amountPaidCol,
      spacerCol,
      transactionsCol,
      {
        content: (row, i) => (
          <div className="flex-end flex-align-center">
            {!allAccountsSelected &&(
              <button
                data-test-id="closed-invoices__message"
                className="button-action-icon"
                onClick={() => {
                  this.props.displayFlyout("messageFlyout", {
                    columns,
                    selectedInvoices: [row],
                    companyId: this.props.companyId,
                    perspectiveId: this.props.perspectiveId,
                    withContextGroupId: this.props.withContextGroupId,
                    withCompanyId: this.props.withCompanyId,
                    isPortal,
                    clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] }),
                    fetchTemplate: () => {
                      return this.props.fetchClosedInvoicesTemplate(
                        this.props.companyId,
                        this.props.perspectiveId,
                        this.props.withCompanyId,
                        { invoiceIds: [row.id] }
                      );
                    }
                  });
                }}
              >
                <IconMessage height="20" className="icon-button" />
              </button>
            )}
            <button
              data-test-id="closed-invoices__export"
              className="button-action-icon"
              onClick={() => this.exportRows([row])}
            >
              <IconExport height="20" className="icon-button" />
            </button>
          </div>
        )
      }
    ];
*/
    if (this.props.allAccountsSelected) {
      columns.splice(1, 0, customerNameCol);
      hoverColumns.splice(1, 0, customerNameCol);
    }

    if (hover) {
      return hoverColumns;
    } else {
      return columns;
    }
  };

  renderEmptyState(useCard) {
    const svgInternals = (
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F4F3FF"
          d="M256.633663 24.0594059c6.2009 0 11.227723 5.0268228 11.227723 11.2277228 0 6.2009001-5.026823 11.2277228-11.227723 11.2277228h-64.158415c6.2009 0 11.227722 5.0268227 11.227722 11.2277228 0 6.2009-5.026822 11.2277227-11.227722 11.2277227h35.287128c6.2009 0 11.227723 5.0268227 11.227723 11.2277228s-5.026823 11.2277228-11.227723 11.2277228h-16.318553c-7.818527 0-14.156694 5.0268227-14.156694 11.2277224 0 4.133934 3.207921 7.876508 9.623762 11.227723 6.2009 0 11.227723 5.026823 11.227723 11.227723 0 6.2009-5.026823 11.227723-11.227723 11.227723H73.7821782c-6.2009 0-11.2277228-5.026823-11.2277228-11.227723 0-6.2009 5.0268228-11.227723 11.2277228-11.227723H11.2277228C5.02682271 113.881188 0 108.854365 0 102.653465c0-6.2008997 5.02682271-11.2277224 11.2277228-11.2277224h64.1584158c6.2009001 0 11.2277228-5.0268227 11.2277228-11.2277228S81.5870387 68.970297 75.3861386 68.970297H35.2871287c-6.2009 0-11.2277228-5.0268227-11.2277228-11.2277227 0-6.2009001 5.0268228-11.2277228 11.2277228-11.2277228h64.1584159c-6.2009001 0-11.2277228-5.0268227-11.2277228-11.2277228 0-6.2009 5.0268227-11.2277228 11.2277228-11.2277228H256.633663zm0 44.9108911c6.2009 0 11.227723 5.0268227 11.227723 11.2277228s-5.026823 11.2277228-11.227723 11.2277228c-6.2009 0-11.227722-5.0268227-11.227722-11.2277228s5.026822-11.2277228 11.227722-11.2277228z"
        />
        <path
          fill="#FFF"
          stroke="#BEB6FF"
          strokeLinecap="round"
          strokeWidth="3.5"
          d="M171.09746275 22.45544561l14.91985216 108.81705333 1.34044493 10.91704788c.43182842 3.51696024-2.0691646 6.71808531-5.58612484 7.14991373l-93.92827812 11.53294239c-3.51695965.43182835-6.71808492-2.06916464-7.14991334-5.58612488L66.23251038 37.51142872c-.21591419-1.75847992 1.03458241-3.35904247 2.79306224-3.57495665l.03332354-.00409161 7.79264977-.87395535m6.3012086-.70683126l7.35744625-.82531361"
        />
        <path
          fill="#DEDAFF"
          d="M167.03644893 29.30305224l13.5046427 98.61747678 1.21477826 9.89357497c.39134452 3.18724535-1.84459613 6.08450974-4.99411182 6.47122164l-84.11487626 10.32800815c-3.14951618.38671196-6.01995084-1.88356838-6.41129536-5.07081373L73.01608092 41.87828745c-.13461316-1.0963362.64501741-2.09421783 1.74135362-2.22883099l11.60477308-1.42488697"
        />
        <path
          fill="#FFF"
          stroke="#BEB6FF"
          strokeWidth="3.5"
          d="M176.198643 1.75H99.7113243c-.6213204 0-1.1838204.25183983-1.5909903.65900974-.4071699.40716992-.6590097.96966992-.6590097 1.59099026v126.732673c0 .621321.2518398 1.183821.6590097 1.590991.4071699.407169.9696699.659009 1.5909903.659009h99.4653467c.62132 0 1.18382-.25184 1.59099-.659009.40717-.40717.65901-.96967.65901-1.590991V26.9638138c0-.5969784-.237244-1.1694856-.659502-1.5914824L177.789141 2.40851759C177.367224 1.98686182 176.795139 1.75 176.198643 1.75z"
        />
        <path
          stroke="#BEB6FF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.5"
          d="M175.909344 3.85404742V22.4554455c0 2.6575286 2.154353 4.8118812 4.811881 4.8118812h12.72517"
        />
        <path
          stroke="#DEDAFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.5"
          d="M114.958849 27.2673267h41.70297m-41.70297 19.2475248h68.970297m-68.970297 20.8514851h68.970297m-68.970297 20.8514852h68.970297m-68.970297 20.8514852h41.70297"
        />
      </g>
    );
    if (this.props.flags.portalUpdatedZeroState) {
      if (useCard) {
        return (
          <Card className="open-invoices-empty-state-ld">
            <svg viewBox="0 0 268 163" className="mt-4">
              {svgInternals}
            </svg>
            <h2>{Resources.NothingToSeeHere}</h2>
            <p>{Resources.PortalClosedInvoicesEmptyNew}</p>
          </Card>
        );
      } else {
        return (
          <div className="closed-invoices-empty-state-ld">
            <svg viewBox="0 0 268 163">{svgInternals}</svg>
            <h2>{Resources.NothingToSeeHere}</h2>
            <p>{Resources.PortalClosedInvoicesEmptyNew}</p>
          </div>
        );
      }
    } else {
      if (useCard) {
        return (
          <Card className="closed-invoices-empty-state">
            <svg viewBox="0 0 268 163" className="mt-4">
              {svgInternals}
            </svg>
            <h2>{Resources.NothingToSeeHere}</h2>
            <p>{Resources.PortalClosedInvoicesEmpty}</p>
          </Card>
        );
      } else {
        return (
          <div className="open-invoices-empty-state">
            <svg viewBox="0 0 268 163">{svgInternals}</svg>
            <h2>{Resources.NothingToSeeHere}</h2>
            <p>{Resources.PortalClosedInvoicesEmpty}</p>
          </div>
        );
      }
    }
  }

  render() {
    let { statementsStore, withCompanyName, allAccountsSelected } = this.props;
    let { selectedRows, selectedKeys } = this.state;

    const helmet = (
      <Helmet>
        <title>
          {withCompanyName} | {Resources.ClosedInvoices}
        </title>
      </Helmet>
    );

    const data = statementsStore.closedInvoices;
    const loading = statementsStore.isFetchingClosedInvoices;
    const maxRows = statementsStore.isShowingSearchResult ? data.length : statementsStore.closedInvoicesCount;

    const prevYearTotal =
      (statementsStore.closedInvoicesCurrencyStats[statementsStore.selectedCurrency] || {}).totalAmountPastYear ||
      statementsStore.closedTotalAmountPastYear;
    const currentYearTotal =
      (statementsStore.closedInvoicesCurrencyStats[statementsStore.selectedCurrency] || {}).totalAmountCurrentYear ||
      statementsStore.closedTotalAmountCurrentYear;

    const hasMultiCurrency = (statementsStore.currencies || []).length > 1;
    const isPortal = isCustomerPortal(this.props.history ? this.props.history.location.pathname : "")

    if (
      loading &&
      (isEmpty(prevYearTotal) || prevYearTotal === 0) &&
      (isEmpty(currentYearTotal) || currentYearTotal === 0)
    ) {
      return (
        <React.Fragment>
          {helmet}
          <MainLoader fullScreen className="portal-page-loader"></MainLoader>
        </React.Fragment>
      );
    }

    if (
      isEmpty(data) &&
      isEmpty(statementsStore.closedFromDate) &&
      isEmpty(statementsStore.closedToDate) &&
      isEmpty(statementsStore.closedFromDueDate) &&
      isEmpty(statementsStore.closedToDueDate) &&
      isEmpty(this.state.searchTerm) &&
      (isEmpty(prevYearTotal) || prevYearTotal === 0) &&
      (isEmpty(currentYearTotal) || currentYearTotal === 0) &&
      this.props.flags.portalUpdatedZeroState
    ) {
      return (
        <React.Fragment>
          {helmet}
          {hasMultiCurrency && <StatementCurrencySelector />}
          <Card className="statements-summary">
            <div className="statements-summary-row">
              <div className="statements-summary-item">
                <div className="statements-summary-item-header">
                  {Resources.TotalInvoicedIn(
                    moment()
                      .subtract(1, "year")
                      .year()
                  )}
                </div>
                <div className="statements-summary-item-data">
                  {formatCurrency(prevYearTotal, this.props.statementsStore.selectedCurrency)}
                </div>
              </div>
              <div className="statements-summary-item">
                <div className="statements-summary-item-header">{Resources.TotalInvoicedIn(moment().year())}</div>
                <div className="statements-summary-item-data">
                  {formatCurrency(currentYearTotal, this.props.statementsStore.selectedCurrency)}
                </div>
              </div>
            </div>
          </Card>
          {this.renderEmptyState(hasMultiCurrency)}
        </React.Fragment>
      );
    } else if (
      isEmpty(data) &&
      isEmpty(statementsStore.closedFromDate) &&
      isEmpty(statementsStore.closedToDate) &&
      isEmpty(statementsStore.closedFromDueDate) &&
      isEmpty(statementsStore.closedToDueDate) &&
      isEmpty(this.state.searchTerm) &&
      (isEmpty(prevYearTotal) || prevYearTotal === 0) &&
      (isEmpty(currentYearTotal) || currentYearTotal === 0) &&
      !this.props.flags.portalUpdatedZeroState
    ) {
      return (
        <React.Fragment>
          {helmet}
          {hasMultiCurrency && <StatementCurrencySelector />}
          {this.renderEmptyState(hasMultiCurrency)}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {helmet}
        {hasMultiCurrency && <StatementCurrencySelector />}
        <Card className="statements-summary">
          <div className="statements-summary-row">
            <div className="statements-summary-item">
              <div className="statements-summary-item-header">
                {Resources.TotalInvoicedIn(
                  moment()
                    .subtract(1, "year")
                    .year()
                )}
              </div>
              <div className="statements-summary-item-data">
                {formatCurrency(prevYearTotal, this.props.statementsStore.selectedCurrency)}
              </div>
            </div>
            <div className="statements-summary-item">
              <div className="statements-summary-item-header">{Resources.TotalInvoicedIn(moment().year())}</div>
              <div className="statements-summary-item-data">
                {formatCurrency(currentYearTotal, this.props.statementsStore.selectedCurrency)}
              </div>
            </div>
          </div>
        </Card>
        <Card type="table" className="portal-table">
          <div className="table-data-card-header inline">
            <div className="table-data-card-header-search">
              <AutoCompleteInput
                className="auto-complete-input__table-data-search"
                placeholder={Resources.Search}
                onChange={e => {
                  const searchTerm = e.target.value;
                  this.setState({ searchTerm });
                  this.props.fetchStatementSearchResults({
                    companyId: this.props.companyId,
                    perspectiveId: this.props.perspectiveId,
                    withCompanyId: this.props.withCompanyId,
                    searchTerm,
                    type: "closed"
                  });
                }}
                isShowingSearchResult={statementsStore.isShowingSearchResult}
                handleClearResult={() => {
                  this.setState({ searchTerm: "" });
                  this.props.clearStatementsSearchResults();
                  this.props.fetchClosedInvoices(
                    this.props.companyId,
                    this.props.perspectiveId,
                    this.props.withCompanyId,
                    null,
                    null,
                    this.props.pageRowCount,
                    null,
                    null,
                    allAccountsSelected
                  );
                }}
                text={this.state.searchTerm}
                noResultsMessage={Resources.NoStatementSearchResultsMessage}
                showNoResultsMessage={statementsStore.fetchedStatementsSearchResults}
                maxOptions={7}
                loading={statementsStore.fetchingStatementsSearchResults}
                handleSelectOption={option => {
                  this.setState({ searchTerm: option.id });
                  this.props.fetchStatementSearchResults({
                    companyId: this.props.companyId,
                    perspectiveId: this.props.perspectiveId,
                    withCompanyId: this.props.withCompanyId,
                    searchTerm: option.id,
                    type: "closed"
                  });
                  this.props.setClosedInvoicesToSearchResult([option]);
                }}
                callToActionButton={
                  <div
                    className="dropdown-item-clean dropdown-call-to-action"
                    onClick={() => this.props.setClosedInvoicesToSearchResult(statementsStore.searchResults)}
                  >
                    {Resources.SeeAllInvoiceResults(this.state.searchTerm)}
                  </div>
                }
                renderOption={option => `${Resources.InvoiceNumber} ${option.id}`}
                options={statementsStore.searchResults}
                width="300px"
              />
            </div>
            <div className="table-data-card-header-buttons">
              <div className="dropdown">
                <button
                  data-test-id="closed-invoices__button-filter"
                  className="button-action-icon dropdown-toggle customer-portal-button-filter data-Tooltip"
                  data-tooltip="Filter"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ marginTop: "1.9rem" }}
                >
                  <IconFilter height="18"></IconFilter>
                </button>
                <div className="dropdown-menu">
                  <form>
                    <div className="mb-2">{Resources.InvoiceDate}</div>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          // startDate={new Date(this.state.fromDate)}
                          // endDate={new Date(this.state.toDate)}
                          maxDate={new Date(this.state.toDate)}
                          selected={new Date(this.state.fromDate)}
                          // selectsStart
                          onChange={d =>
                            this.setState({
                              fromDate: d
                            })
                          }
                        />
                      </span>
                      <IconArrowRight line className="mr-3 ml-3" height={14}></IconArrowRight>
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          // startDate={new Date(this.state.fromDate)}
                          // endDate={new Date(this.state.toDate)}
                          minDate={new Date(this.state.fromDate)}
                          selected={new Date(this.state.toDate)}
                          // selectsStart
                          onChange={d =>
                            this.setState({
                              toDate: d
                            })
                          }
                        />
                      </span>
                    </div>

                    {/* <div className="mb-2 mt-4">{Resources.Due_Date}</div>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          // startDate={new Date(this.state.fromDate)}
                          // endDate={new Date(this.state.toDate)}
                          maxDate={new Date(this.state.toDueDate)}
                          selected={new Date(this.state.fromDueDate)}
                          // selectsStart
                          onChange={d =>
                            this.setState({
                              fromDueDate: d
                            })
                          }
                        />
                      </span>
                      <IconArrowRight line className="mr-3 ml-3" height={14}></IconArrowRight>
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          // startDate={new Date(this.state.fromDate)}
                          // endDate={new Date(this.state.toDate)}
                          minDate={new Date(this.state.fromDueDate)}
                          selected={new Date(this.state.toDueDate)}
                          // selectsStart
                          onChange={d =>
                            this.setState({
                              toDueDate: d
                            })
                          }
                        />
                      </span>
                    </div> */}
                  </form>
                  <div className="filter-dropdown-buttons">
                    <button
                      className="filter-dropdown-reset"
                      onClick={() => {
                        this.setState({
                          fromDate: this.defaultState.fromDate,
                          toDate: this.defaultState.toDate,
                          fromDueDate: this.defaultState.fromDueDate,
                          toDueDate: this.defaultState.toDueDate
                        });
                        this.props.updateClosedInvoicesDate(null, null, null, null);
                      }}
                    >
                      {Resources.Reset}
                    </button>
                    <button
                      className="filter-dropdown-save"
                      onClick={() => {
                        this.props.updateClosedInvoicesDate(
                          moment(this.state.fromDate)
                            .utc()
                            .startOf("day")
                            .format("YYYY-MM-DD"),
                          moment(this.state.toDate)
                            .utc()
                            .startOf("day")
                            .format("YYYY-MM-DD")
                          // moment(this.state.fromDueDate)
                          //   .utc()
                          //   .startOf("day")
                          //   .format("YYYY-MM-DD"),
                          // moment(this.state.toDueDate)
                          //   .utc()
                          //   .startOf("day")
                          //   .format("YYYY-MM-DD")
                        );
                      }}
                    >
                      {Resources.Save}
                    </button>
                  </div>
                </div>
              </div>
              {!allAccountsSelected && (
                <button
                  data-test-id="closed-invoices__button-send"
                  className="button-primary"
                  disabled={isEmpty(selectedRows)}
                  onClick={() => {
                    const selectedInvoices = [...selectedRows];

                    this.props.displayFlyout("messageFlyout", {
                      columns: this.getClosedInvoicesColumns(false),
                      selectedInvoices,
                      companyId: this.props.companyId,
                      perspectiveId: this.props.perspectiveId,
                      withContextGroupId: this.props.withContextGroupId,
                      withCompanyId: this.props.withCompanyId,
                      isPortal,
                      fetchingTemplate: () => this.props.statementsStore.isFetchingClosedInvoicesTemplate,
                      fetchTemplate: () => {
                        return this.props.fetchClosedInvoicesTemplate(
                          this.props.companyId,
                          this.props.perspectiveId,
                          this.props.withCompanyId,
                          { invoiceIds: selectedInvoices.map(i => i.id) }
                        );
                      },
                      clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                    });
                  }}
                >
                  <IconMessage height="20" className="button-primary-icon" />
                  {Resources.SendMessage}
                </button>
              )}
              <button
                data-test-id="closed-invoices__button-download"
                className="button-primary"
                disabled={isEmpty(selectedRows) || !selectedRows.some(row => !isEmpty((row || {}).attachments))}
                onClick={() => {
                  let allAttachments = [];
                  selectedRows.forEach(row => {
                    allAttachments = [...allAttachments, ...row.attachments];
                  });
                  this.downloadAttachments(allAttachments, Resources.InvoiceAttachments + ".zip");
                }}
              >
                <IconDownload height="20" className="button-primary-icon" />
                {Resources.DownloadAttachments}
              </button>
              <button
                data-test-id="closed-invoices__button-export"
                className="button-primary"
                disabled={isEmpty(selectedRows)}
                onClick={() => this.exportRows(this.state.selectedRows)}
              >
                <IconExport height="20" className="button-primary-icon" />
                {Resources.Export}
              </button>
            </div>
          </div>
          <TableData
            name="closed-invoices__table"
            pagination
            data={data}
            hoverColumns={this.getClosedInvoicesColumns(true)}
            columns={this.getClosedInvoicesColumns(false)}
            rowHeight="4em"
            onLoadMore={pageSelected => this.handleLoadMore(pageSelected)}
            maxRows={maxRows}
            loading={loading}
            localSelectedKeys={selectedKeys}
            showSelectAll
            pageName="closed invoices"
            selectedType="invoices"
            className={"self-service-table"}
            rowKey="ledgerHash"
            onRowSelectToggle={key =>
              this.toggleSelectRow(
                key,
                (Array.isArray(key) ? [...key] : [key]).map(k => find(data, row => row.ledgerHash === k))
              )
            }
            rowClassName="statements-view-row"
            offsetHeight="40"
            emptyRender={
              <div className="table-data-empty-render">
                <h4 className="mt-5">{Resources.EmptyStatements}</h4>
              </div>
            }
          />
        </Card>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    contextGroupsStore: store.contextGroups,
    statementsStore: store.statements,
    perspectivesStore: store.perspectives,
    ledgerStore: store.ledger,
    pageRowCount: store.general.pageRowCount
  };
};

export default withLDConsumer()(connect(storeToProps, dispatchToProps)(ClosedInvoices));
