import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";

import { hideModal } from "../store/modal-actions";
import { testEmailConfig } from "../store/manage-actions";
import { isEmpty } from "../lib/utils";
import Resources from "../lib/resources";
import data from "../lib/data";
import Mainloader from "./mainLoader";
import TextInput from "./library/textInput";

function TestEmailConfigModal({ companyId, accountingGroupId, accountingGroupName }) {
  useEffect(() => {
    dispatch(testEmailConfig(companyId, accountingGroupId));
  }, [testEmailConfig]);

  const configTestStatus = useSelector(
    store => store.manage.accountingGroupEmailConfigTestStatus[accountingGroupId] || "idle"
  );
  const configTest = useSelector(store => store.manage.accountingGroupEmailConfigTestValue[accountingGroupId] || {});
  const showModal = useSelector(store => store.modal.showModal);

  const [testEmailAddress, setTestEmailAddress] = useState("");
  const [testEmailAddressStatus, setTestEmailAddressStatus] = useState("idle");

  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => dispatch(hideModal())}
      contentLabel="Example Modal"
      className="custom-modal-wide"
      overlayClassName="custom-modal-overlay"
    >
      <div className="custom-modal-header">{Resources.TestEmailConfiguration}</div>
      <div className="custom-modal-divider" />
      <div className="custom-modal-content">
        <div className="test-email-config-modal__status">
          {configTestStatus === "loading"
            ? Resources.TestingEmailConfigurationForName(accountingGroupName)
            : Resources.CompletedTestingEmailConfigurationForName(accountingGroupName)}
        </div>
        {configTestStatus === "loading" ? (
          <Mainloader fullScreen className="test-email-config-modal__loader" />
        ) : (
          <div className="test-email-config-modal__content">
            {!isEmpty(configTest.imapConfig) && (
              <React.Fragment>
                <div className="test-email-config-modal__content__config">
                <div>
                  <h2 className="test-email-config-modal__content__config__header">
                    {Resources.ImapConfigTestResults}
                  </h2>
                  <HideableJSONAttribute label={Resources.ImapConfigUsed} code={configTest.imapConfig} />
                  <div className="test-email-config-modal__content__config__item">
                    <span className="test-email-config-modal__content__config__label">
                      {Resources.ImapConnectionStatus}:{" "}
                    </span>
                    {configTest.imapConfigStatus.canConnect ? (
                      <span className="badge badge-active">{Resources.SuccessfullyConnected}</span>
                    ) : (
                      <span className="badge badge-rejected">{Resources.FailedToConnect}</span>
                    )}
                  </div>
                  <div className="test-email-config-modal__content__config__item">
                    <span className="test-email-config-modal__content__config__label">
                      {Resources.ImapAuthentication}:{" "}
                    </span>
                    {configTest.imapConfigStatus.canAuthorize ? (
                      <span className="badge badge-active">{Resources.SuccessfullyAuthorized}</span>
                    ) : (
                      <span className="badge badge-rejected">{Resources.FailedToAuthenticate}</span>
                    )}
                  </div>
                  <div className="test-email-config-modal__content__config__item">
                    <span className="test-email-config-modal__content__config__label">{Resources.InboxFolder}: </span>
                    <code>{configTest.imapInboxFolderPath}</code>
                  </div>
                  {!isEmpty(configTest.imapSpecialFolders) && (
                    <HideableJSONAttribute
                      label={Resources.ImapSpecialFolders}
                      buttonShowText={Resources.ShowFolders}
                      buttonHideText={Resources.HideFolders}
                      code={configTest.imapSpecialFolders}
                    />
                  )}
                  {!isEmpty(configTest.imapFolders) && (
                    <HideableJSONAttribute
                      label={Resources.ImapFolders}
                      buttonShowText={Resources.ShowFolders}
                      buttonHideText={Resources.HideFolders}
                      code={configTest.imapFolders}
                    />
                  )}
                  {!isEmpty(configTest.imapConfigStatus.errorString) && (
                    <div className="test-email-config-modal__content__config__item">
                      <span className="test-email-config-modal__content__config__label">
                        {Resources.ErrorMessage}:{" "}
                      </span>
                      <div>{configTest.imapConfigStatus.errorString}</div>
                    </div>
                  )}
                </div>
              </div>
              </React.Fragment>
            )}
            {!isEmpty(configTest.smtpConfig) && (
              <React.Fragment>
                <div className="test-email-config-modal__content__config">
                  <div>
                    <h2 className="test-email-config-modal__content__config__header">
                      {Resources.SmtpConfigTestResults}
                    </h2>
                    <HideableJSONAttribute label={Resources.SmtpConfigUsed} code={configTest.smtpConfig} />
                    <div className="test-email-config-modal__content__config__item">
                      <span className="test-email-config-modal__content__config__label">
                        {Resources.SmtpConnectionStatus}:{" "}
                      </span>
                      {configTest.smtpConfigStatus.canConnect ? (
                        <span className="badge badge-active">{Resources.SuccessfullyConnected}</span>
                      ) : (
                        <span className="badge badge-rejected">{Resources.FailedToConnect}</span>
                      )}
                    </div>
                    <div className="test-email-config-modal__content__config__item">
                      <span className="test-email-config-modal__content__config__label">
                        {Resources.SmtpAuthentication}:{" "}
                      </span>
                      {configTest.smtpConfigStatus.canAuthorize ? (
                        <span className="badge badge-active">{Resources.SuccessfullyAuthorized}</span>
                      ) : (
                        <span className="badge badge-rejected">{Resources.FailedToAuthenticate}</span>
                      )}
                    </div>
                    {!isEmpty(configTest.smtpConfigStatus.errorString) && (
                      <div className="test-email-config-modal__content__config__item">
                        <span className="test-email-config-modal__content__config__label">
                          {Resources.ErrorMessage}:{" "}
                        </span>
                        <div>{configTest.smtpConfigStatus.errorString}</div>
                      </div>
                    )}
                  </div>
                </div>
                {configTest.smtpConfigStatus.canAuthorize && (
                  <div>
                    <div className="smtp-testing-send">
                      <TextInput
                        textValue={testEmailAddress}
                        inputOnChange={e => setTestEmailAddress(e.target.value)}
                        label={Resources.SendTestEmailViaSMTP}
                        labelInline={false}
                        placeholder={Resources.EmailAddress}
                        className="mb-0"
                      />
                      <button
                        className="button-primary-square"
                        disabled={testEmailAddressStatus === "fetching"}
                        onClick={() => {
                          if (testEmailAddressStatus === "fetching") {
                            return null;
                          }
                          setTestEmailAddressStatus("fetching");
                          data
                            .post(
                              `v3/api/emailconfig/${companyId}/${accountingGroupId}/test`,
                              JSON.stringify(testEmailAddress),
                              {
                                headers: { "Content-Type": "application/json" }
                              }
                            )
                            .then(response => {
                              setTestEmailAddressStatus("success");
                            })
                            .catch(e => {
                              setTestEmailAddressStatus("Error - " + e.response.data.Message);
                            });
                        }}
                      >
                        {testEmailAddressStatus === "fetching" ? Resources.Sending : Resources.Send}
                      </button>
                    </div>
                    {testEmailAddressStatus !== "fetching" && testEmailAddressStatus !== "idle" && (
                      <div>
                        {Resources.EmailStatus}: {testEmailAddressStatus}
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            )}
            {!isEmpty(configTest.dkimConfig) && (
              <React.Fragment>
                <div className="test-email-config-modal__content__config">
                <div>
                  <h2 className="test-email-config-modal__content__config__header">
                    {Resources.DkimConfigTestResults}
                  </h2>
                  <div className="test-email-config-modal__content__config__item">
                    <span className="test-email-config-modal__content__config__label">{Resources.DkimDomain}: </span>
                    <span>{configTest.dkimConfig.dkimDomain}</span>
                  </div>
                  <div className="test-email-config-modal__content__config__item">
                    <span className="test-email-config-modal__content__config__label">
                      {Resources.DkimRecordsStatus}:{" "}
                    </span>
                    {configTest.dkimConfigStatus ? (
                      <span className="badge badge-active">{Resources.SuccessfullyFoundRecords}</span>
                    ) : (
                      <span className="badge badge-rejected">{Resources.FailedToFindRecords}</span>
                    )}
                  </div>
                </div>
              </div>
              </React.Fragment>
            )}
          </div>
        )}

        <div className="flex-row modal-buttons test-email-config-modal-buttons">
          <button className="button-primary-square float-left mr-1" onClick={() => dispatch(hideModal())}>
            {Resources.Close}
          </button>
        </div>
      </div>
    </Modal>
  );
}

function HideableJSONAttribute({
  label,
  code,
  buttonShowText = Resources.ShowConfig,
  buttonHideText = Resources.HideConfig
}) {
  const [showCode, setShowCode] = useState(false);

  return (
    <div className="test-email-config-modal__content__config__item">
      <span className="test-email-config-modal__content__config__label">{label}: </span>
      <button className="button-secondary d-inline p-0" onClick={() => setShowCode(!showCode)}>
        {showCode ? buttonHideText : buttonShowText}
      </button>
      {showCode && (
        <pre>
          <code>{JSON.stringify(code, null, "\t")}</code>
        </pre>
      )}
    </div>
  );
}

export default TestEmailConfigModal;
