import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Resources from "../../lib/resources";
import { isCustomerPortal, isEmpty } from "../../lib/utils";

import { dispatchToProps as contDP } from "../../store/contextGroups-actions";
import { dispatchToProps as comDP } from "../../store/company-actions";
import { dispatchToProps as modDP } from "../../store/modal-actions";
import { dispatchToProps as manDP } from "../../store/manage-actions";

import Card from "../library/card";
import IconPlusCircle from "../library/icons/iconPlusCircle";
import IconCompany from "../library/icons/iconCompany";
import IconLinkedIn from "../library/icons/iconLinkedIn";
import IconClipboard from "../library/icons/iconClipboard";
import IconArchive from "../library/icons/iconArchive";
import IconPhone from "../library/icons/iconPhone";
import IconMapPin from "../library/icons/iconMapPin";
import IconMail from "../library/icons/iconMail";
import IconUser from "../library/icons/iconUser";
import IconBriefcase from "../library/icons/iconBriefcase";
// import IconTrash from "../library/icons/iconTrash";
// import IconEdit from "../library/icons/iconEdit";
import MainLoader from "../mainLoader";
import { Link, generatePath } from "react-router-dom";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(comDP),
    ...dispatch(contDP),
    ...dispatch(manDP),
    ...dispatch(modDP)
  };
};

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addressBook: {}
    };

    this.fetchAddressBook = this.fetchAddressBook.bind(this);
  }

  componentDidMount() {
    this.tryUpdate();
    this.fetchAddressBook();
  }

  fetchAddressBook() {
    let { companyId } = this.props;
    this.props.fetchCompanyAddressBook(companyId, "contextGroupId%20=%20NULL").then(response => {
      if (!isEmpty((response || {}).perspectives)) {
        this.setState({
          addressBook: response.perspectives["00000000-0000-0000-0000-000000000000"][0].items.sort((a, b) =>
            a.isPrimary ? -1 : 1
          )
        });
      } else {
        this.setState({ addressBook: [] });
      }
    });
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps) {
    const { perspectiveId, withCompanyId, companyStore, manageStore } = this.props;

    if (isEmpty(perspectiveId) || isEmpty(withCompanyId)) {
      return;
    }

    if (isEmpty(companyStore.fetchedCompanyInfo[withCompanyId])) {
      this.props.fetchCompanyInfo(withCompanyId);
    }

    if (isEmpty(prevProps)) {
      return;
    }

    let addressBookChanged =
      (manageStore.putAddressBookContact === true && prevProps.manageStore.putAddressBookContact === false) ||
      (manageStore.deletedCompanyAddressBookContact === true &&
        prevProps.manageStore.deletedCompanyAddressBookContact === false) ||
      (manageStore.postedAddressBookContact === true && prevProps.manageStore.postedAddressBookContact === false);

    if (addressBookChanged) {
      this.fetchAddressBook();
    }
  }

  renderAddContactButton() {
    return (
      <button
        data-test-id="contacts__button-add"
        className="button-primary"
        onClick={() => this.props.displayFlyout("contactFlyout", { companyId: this.props.companyId })}
      >
        <IconPlusCircle height="16" className="mr-2" />
        <span>{Resources.AddNewContact}</span>
      </button>
    );
  }

  emptyYourInformationState() {
    return (
      <div className="company-info-your-info-empty">
        <img alt="No information to show." src={"/img/contact-empty.svg"} />
        <span className="company-info-your-info-empty-header">{Resources.NoContactInfoYet}</span>
        <span className="company-info-your-info-empty-description mb-4">
          {Resources.AddYourInformationSoWeKnowBestWayToReachYou}
        </span>
        {/* {this.renderAddContactButton()} */}
      </div>
    );
  }

  render() {
    let { withCompanyId, companyStore, perspectiveId, companyId, withContextGroupId, statementsStore } = this.props;

    let withCompanyInfo = this.props.getCompanyInfo(withCompanyId);
    isEmpty(withCompanyInfo.Address) && (withCompanyInfo.Address = {});

    const isPortal = isCustomerPortal(this.props.history ? this.props.history.location.pathname : "");

    const helmet = (
      <Helmet>
        <title>
          {this.props.withCompanyName} | {Resources.Contacts}
        </title>
      </Helmet>
    );

    if (companyStore.gettingCompanyInfo[withCompanyId]) {
      return (
        <React.Fragment>
          {helmet}
          <MainLoader fullScreen className="portal-page-loader" />
        </React.Fragment>
      );
    }

    return (
      <div className="profile-managment-card-container">
        <div className="profile-container">
          <Card className="profile__card">
            {!isEmpty((withCompanyInfo || {}).arEmail) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconMail height={18} />
                </div>
                <div className="account-profile-item__content">
                  <a
                    className="email-click"
                    onClick={() =>
                      this.props.displayFlyout("messageFlyout", {
                        companyId,
                        perspectiveId,
                        withContextGroupId,
                        withCompanyId: statementsStore.withCompanyId,
                        refetchConversations: this.fetchFolderConversations,
                        isPortal
                      })
                    }
                  >
                    {(withCompanyInfo || {}).arEmail}
                  </a>
                </div>
              </div>
            )}
            {!isEmpty((withCompanyInfo["Contact Numbers"] || {})["AR Phone"]) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconPhone height={22} />
                </div>
                <div className="account-profile-item__content">
                  {(withCompanyInfo["Contact Numbers"] || {})["AR Phone"]}
                </div>
              </div>
            )}
            {!isEmpty((withCompanyInfo["Contact Numbers"] || {})["AR Fax"]) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconArchive height={16} />
                </div>
                <div className="account-profile-item__content">
                  {(withCompanyInfo["Contact Numbers"] || {})["AR Fax"] || null}
                </div>
              </div>
            )}
            <div className="">
              {!isEmpty(withCompanyInfo.Address.Line1) && (
                <div className="account-profile-item">
                  <div className="account-profile-item__icon">
                    <IconMapPin height={22} />
                  </div>
                  <div className="account-profile-item__content">
                    {`${withCompanyInfo.Address.Line1}${
                      !isEmpty(withCompanyInfo.Address.City) ? `, ${withCompanyInfo.Address.City}` : ""
                    }${!isEmpty(withCompanyInfo.Address.Region) ? `, ${withCompanyInfo.Address.Region}` : ""} ${
                      withCompanyInfo.Address.PostalCode
                    }`}
                  </div>
                </div>
              )}
              {!isEmpty((withCompanyInfo || {}).Notes) && (
                <div className="account-profile-item">
                  <div className="account-profile-item__icon">
                    <IconClipboard height={20} />
                  </div>
                  <div className="account-profile-item__content">{(withCompanyInfo || {}).Notes}</div>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    companyStore: store.companies,
    manageStore: store.manage,
    statementsStore: store.statements
  };
};

export default connect(storeToProps, dispatchToProps)(Profile);
