import util from "util";

import data from "../lib/data";
import { handlePromiseError } from "./error-actions";

import { actions as GeneralActions } from "./general-actions";
import { actions as UserActions } from "./user-actions";
import { isEmpty, isGuid, removeLeading, find, debounce } from "../lib/utils";
import { isNumeric } from "jquery";
import _ from "lodash";

export const actions = {
  ...GeneralActions,
  ...{
    GETTING_CONVERSATION_STATUSES: "GETTING_CONVERSATION_STATUSES",
    GOT_CONVERSATION_STATUSES: "GOT_CONVERSATION_STATUSES",
    GET_CONVERSATION_STATUSES_FAILED: "GET_CONVERSATION_STATUSES_FAILED",

    GETTING_CONVERSATIONS: "GETTING_CONVERSATIONS",
    GOT_CONVERSATIONS: "GOT_CONVERSATIONS",
    GET_CONVERSATIONS_FAILED: "GET_CONVERSATIONS_FAILED",

    GETTING_CONVERSATIONS_SEARCH_RESULTS: "GETTING_CONVERSATIONS_SEARCH_RESULTS",
    GOT_CONVERSATIONS_SEARCH_RESULTS: "GOT_CONVERSATIONS_SEARCH_RESULTS",
    FAILED_GET_CONVERSATIONS_SEARCH_RESULTS: "FAILED_GET_CONVERSATIONS_SEARCH_RESULTS",

    CLEAR_CONVERSATIONS_SEARCH_RESULTS: "CLEAR_CONVERSATIONS_SEARCH_RESULTS",

    GETTING_CONVERSATION: "GETTING_CONVERSATION",
    GOT_CONVERSATION: "GOT_CONVERSATION",
    GET_CONVERSATION_FAILED: "GET_CONVERSATION_FAILED",

    UPDATING_CONVERSATION_READ_STATUSES: "UPDATING_CONVERSATION_READ_STATUSES",
    UPDATED_CONVERSATION_READ_STATUSES: "UPDATED_CONVERSATION_READ_STATUSES",
    UPDATE_CONVERSATION_READ_STATUSES_FAILED: "UPDATE_CONVERSATION_READ_STATUSES_FAILED",

    DELETING_CONVERSATION: "DELETING_CONVERSATION",
    DELETED_CONVERSATION: "DELETED_CONVERSATION",
    DELETE_CONVERSATION_FAILED: "DELETE_CONVERSATION_FAILED",

    CREATING_CONVERSATION: "CREATING_CONVERSATION",
    CREATED_CONVERSATION: "CREATED_CONVERSATION",
    CREATE_CONVERSATION_FAILED: "CREATE_CONVERSATION_FAILED",

    ASSOCIATION_ADDED: "ASSOCIATION_ADDED",

    START_CONVERSATION: "START_CONVERSATION",
    CLEAR_CONVERSATION: "CLEAR_CONVERSATION",
    GOT_RELATED: "GOT_RELATED",
    SET_MAX_RELATED_LENGTH: "SET_MAX_RELATED_LENGTH",

    ASSIGNING_CONVERSATIONS: "ASSIGNING_CONVERSATIONS",
    ASSIGNED_CONVERSATIONS: "ASSIGNED_CONVERSATIONS",
    ASSIGN_CONVERSATIONS_FAILED: "ASSIGN_CONVERSATIONS_FAILED",

    SETTING_CONVERSATION_STATUSES: "SETTING_CONVERSATION_STATUSES",
    SET_CONVERSATION_STATUSES: "SET_CONVERSATION_STATUSES",
    SET_CONVERSATION_STATUSES_FAILED: "SET_CONVERSATION_STATUSES_FAILED",

    SET_CONVERSATION_MOVE_TO_NEXT: "SET_CONVERSATION_MOVE_TO_NEXT",

    SET_CONVERSATION_SORT: "SET_CONVERSATION_SORT",
    SET_DOCUMENT_SORT: "SET_DOCUMENT_SORT",

    GETTING_DOCUMENTS: "GETTING_DOCUMENTS",
    GOT_DOCUMENTS: "GOT_DOCUMENTS",
    GET_DOCUMENTS_FAILED: "GET_DOCUMENTS_FAILED",

    GETTING_REMINDERS: "GETTING_REMINDERS",
    GOT_REMINDERS: "GOT_REMINDERS",
    GET_REMINDERS_FAILED: "GET_REMINDERS_FAILED",

    GETTING_APPROVAL_REQUEST: "GETTING_APPROVAL_REQUEST",
    GOT_APPROVAL_REQUEST: "GOT_APPROVAL_REQUEST",
    GET_APPROVAL_REQUEST_FAILED: "GET_APPROVAL_REQUEST_FAILED",

    PUTTING_APPROVAL_REQUEST_ACTION: "PUTTING_APPROVAL_REQUEST_ACTION",
    PUT_APPROVAL_REQUEST_ACTION: "PUT_APPROVAL_REQUEST_ACTION",
    PUT_APPROVAL_REQUEST_ACTION_FAILED: "PUT_APPROVAL_REQUEST_ACTION_FAILED",

    SET_CONVERSATIONS_TO_SEARCH_RESULT: "SET_CONVERSATIONS_TO_SEARCH_RESULT",

    CLEAR_CONVERSATIONS: "CLEAR_CONVERSATIONS",

    // These actions are for the updated implementation of the conversation table component that uses SWR for data fetching. Eventually we can
    // remove any similar existing actions once we determine they aren't being used by any self-service portal components.
    SET_CONVERSATIONS_VIEW_SEARCH_TERM: "SET_CONVERSATIONS_VIEW_SEARCH_TERM",
    SET_CONVERSATIONS_VIEW_DATA: "SET_CONVERSATIONS_VIEW_DATA",
    SET_CONVERSATIONS_READ_STATUS: "SET_CONVERSATIONS_READ_STATUS",
    SET_CONVERSATIONS_CURRENT_PAGE: "SET_CONVERSATIONS_CURRENT_PAGE",
    SET_CONVERSATIONS_SEARCH_RESULTS_CURRENT_PAGE: "SET_CONVERSATIONS_SEARCH_RESULTS_CURRENT_PAGE",
    SET_CONVERSATIONS_LABEL: "SET_CONVERSATIONS_LABEL",
    SET_DISPLAYING_SEARCH_RESULTS: "SET_DISPLAYING_SEARCH_RESULTS",
    SET_UPDATING_SEARCH_RESULTS: "SET_UPDATING_SEARCH_RESULTS"
  }
};

const getConversationStatuses = companyId => (dispatch, getState) => {
  let state = getState().conversations;
  if (state.companyId && state.companyId !== companyId) return;

  if (state.gettingStatuses) return;
  dispatch({ type: actions.GETTING_CONVERSATION_STATUSES, companyId });
  data
    .get(`v1/api/conversation/status/for/${companyId}`)
    .then(response => {
      dispatch({
        type: actions.GOT_CONVERSATION_STATUSES,
        statuses: response.data,
        companyId
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_CONVERSATION_STATUSES_FAILED, companyId });
      handlePromiseError(
        response,
        "TODO: Getting the list of conversation statuses for the company failed.  Please refresh the page.",
        "activity statuses"
      );
    });
};

const ensureConversationStatuses = () => (dispatch, getState) => {
  let state = getState().conversations;
  if (state.fetchedStatuses !== true && state.gettingStatuses !== true && isEmpty(state.companyId) === false)
    dispatch(getConversationStatuses(state.companyId));
};

const getConversationStatus = statusId => (dispatch, getState) => {
  let statusesLookup = getState().conversations.statusesLookup;
  return statusesLookup[statusId];
};

const getConversationStatusByName = name => (dispatch, getState) => {
  const statuses = getState().conversations.statuses;
  return find(statuses, status => {
    return status.conversationStatusName.toLowerCase() === name.toLowerCase();
  });
};

// TODO: companyId needs to be changed to contextGroupId, action and reducer are being worked on in another branch.
// Needs a general overhaul
const refreshConversations = (companyId, perspective, withCompanyId) => (dispatch, getState) => {
  let state = getState().conversations;

  if (state.companyId && state.companyId !== companyId) return;
  if (isEmpty(state.conversations[perspective])) return;
  if (state.gettingConversations[perspective]) return;

  let withCompany = withCompanyId || state.withCompanyId;

  let label = isNumeric(withCompany) || isGuid(withCompany) ? "" : withCompany;
  let queryCompanyId = isEmpty(label) ? withCompany : undefined;
  dispatch({
    type: actions.GETTING_CONVERSATIONS,
    companyId,
    perspective,
    withCompany,
    clearConversations: true
  });
  data
    .get(
      `v2/api/conversation/for/${companyId}/${perspective}${
        isEmpty(queryCompanyId) === true ? "" : `/with/${withCompany}`
      }?$orderby=${state.sortBy}%20${state.sortDirection}&$top=${state.conversations[perspective].value.length}${
        isEmpty(label) ? "" : `&$label=${label}`
      }`
    )
    .then(response => {
      dispatch({
        type: actions.GOT_CONVERSATIONS,
        conversations: response.data,
        companyId,
        perspective,
        withCompany
      });
    })
    .catch(response => {
      dispatch({
        type: actions.GET_CONVERSATIONS_FAILED,
        companyId,
        perspective,
        withCompany
      });
      handlePromiseError(
        response,
        "TODO: Refreshing the list of conversations failed.  Please refresh the page.",
        "conversations"
      );
    });
};

const getConversations = (companyId, perspective, withCompanyId, next, moveToNext, top, skip) => (
  dispatch,
  getState
) => {
  let state = getState().conversations;
  if (state.companyId && state.companyId !== companyId) return;

  if ((state.withCompanyId === withCompanyId && state.gettingConversations[perspective]) || isEmpty(withCompanyId)) {
    return;
  }

  let label = isNumeric(withCompanyId) || isGuid(withCompanyId) ? "" : withCompanyId;
  let queryCompanyId = isEmpty(label) ? withCompanyId : undefined;

  let nextLink = next !== true ? null : removeLeading("/", state.conversations[perspective].nextLink);
  if (next === true && isEmpty(nextLink)) return;

  dispatch({
    type: actions.GETTING_CONVERSATIONS,
    companyId,
    perspective,
    withCompanyId
  });
  data
    .get(
      nextLink ||
        `v2/api/conversation/for/${companyId}/${perspective}${
          isEmpty(queryCompanyId) === true ? "?" : `/with/${withCompanyId}?`
        }$orderby=${state.sortBy}%20${state.sortDirection}&$top=${top || getState().general.pageRowCount}${
          isEmpty(label) ? "" : `&$label=${label}`
        }${isEmpty(skip) ? "" : `&$skip=${skip}`}`
    )
    .then(response => {
      dispatch({
        type: actions.GOT_CONVERSATIONS,
        conversations: response.data,
        companyId,
        perspective,
        withCompanyId,
        nextLink
      });
      dispatch({ type: actions.SET_CONVERSATION_MOVE_TO_NEXT, moveToNext });
    })
    .catch(response => {
      dispatch({
        type: actions.GET_CONVERSATIONS_FAILED,
        companyId,
        perspective,
        withCompanyId
      });
      handlePromiseError(
        response,
        "TODO: Getting the list of conversations failed.  Please refresh the page.",
        "conversations"
      );
    });
};

const getLabeledWithCompanyConversations = (companyId, perspective, withCompanyId, label, top, skip) => (
  dispatch,
  getState
) => {
  let state = getState().conversations;
  if (state.companyId && state.companyId !== companyId) return;

  if (state.withCompanyId === withCompanyId && state.gettingConversations[perspective] && state.label === label) {
    return;
  }

  const gettingAction = {
    type: actions.GETTING_CONVERSATIONS,
    companyId,
    perspective,
    withCompanyId,
    label
  };

  if (label !== state.label) {
    gettingAction.clearConversations = true;
  }

  dispatch(gettingAction);
  data
    .get(
      `v2/api/conversation/for/${companyId}/${perspective}/with/${withCompanyId}?$orderby=${state.sortBy}%20${
        state.sortDirection
      }&$top=${top || getState().general.pageRowCount}&$label=${label}${isEmpty(skip) ? "" : `&$skip=${skip}`}`
    )
    .then(response => {
      dispatch({
        type: actions.GOT_CONVERSATIONS,
        conversations: response.data,
        label,
        companyId,
        perspective,
        withCompanyId
      });
    })
    .catch(response => {
      dispatch({
        label,
        type: actions.GET_CONVERSATIONS_FAILED,
        companyId,
        perspective,
        withCompanyId
      });
      handlePromiseError(
        response,
        "TODO: Getting the list of conversations failed.  Please refresh the page.",
        "conversations"
      );
    });
};

const fetchConversationsSearchResults = (companyId, perspective, withCompanyId, params = {}) => (
  dispatch,
  getState
) => {
  let state = getState().conversations;
  const { top = getState().general.pageRowCount, skip = 0, searchTerm, searchAttribute = "subject" } = params;

  if (state.companyId && state.companyId !== companyId) return;

  if ((state.withCompanyId === withCompanyId && state.gettingConversations[perspective]) || isEmpty(withCompanyId)) {
    return;
  }

  let label = isNumeric(withCompanyId) || isGuid(withCompanyId) ? "" : withCompanyId;
  let queryCompanyId = isEmpty(label) ? withCompanyId : undefined;

  let endpoint = `v2/api/conversation/for/${companyId}/${perspective}${
    isEmpty(queryCompanyId) === true ? "?" : `/with/${withCompanyId}?`
  }$orderby=${state.sortBy}%20${state.sortDirection}`;

  if (!isEmpty(top)) {
    endpoint = `${endpoint}?$top=${top}`;
  }
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }
  if (!isEmpty(label)) {
    endpoint = `${endpoint}&$label=${label}`;
  }
  if (!isEmpty(searchTerm)) {
    endpoint = `${endpoint}&$filter=${searchAttribute}%20LIKE%20"${searchTerm}"`;
  }

  dispatch({
    type: actions.GETTING_CONVERSATIONS_SEARCH_RESULTS,
    companyId,
    perspective,
    withCompanyId,
    searchTerm
  });
  data
    .get(endpoint)
    .then(response => {
      dispatch({
        type: actions.GOT_CONVERSATIONS_SEARCH_RESULTS,
        conversations: response.data,
        companyId,
        perspective,
        withCompanyId,
        searchTerm
      });
    })
    .catch(response => {
      dispatch({
        type: actions.FAILED_GET_CONVERSATIONS_SEARCH_RESULTS,
        companyId,
        perspective,
        withCompanyId
      });
      handlePromiseError(response, "TODO: Searching conversations failed.  Please refresh the page.", "conversations");
    });
};

const debouncedFetchConversationsSearchResultsGet = debounce(
  (companyId, perspective, withCompanyId, params = {}, dispatch, getState) => {
    let state = getState().conversations;
    const { top = getState().general.pageRowCount, skip = 0, searchTerm, searchAttribute = "subject" } = params;

    if (state.companyId && state.companyId !== companyId) return;

    if ((state.withCompanyId === withCompanyId && state.gettingConversations[perspective]) || isEmpty(withCompanyId)) {
      return;
    }

    let label = isNumeric(withCompanyId) || isGuid(withCompanyId) ? "" : withCompanyId;
    let queryCompanyId = isEmpty(label) ? withCompanyId : undefined;

    let endpoint = `v2/api/conversation/for/${companyId}/${perspective}${
      isEmpty(queryCompanyId) === true ? "?" : `/with/${withCompanyId}?`
    }$orderby=${state.sortBy}%20${state.sortDirection}`;

    if (!isEmpty(top)) {
      endpoint = `${endpoint}?$top=${top}`;
    }
    if (!isEmpty(skip)) {
      endpoint = `${endpoint}&$skip=${skip}`;
    }
    if (!isEmpty(label)) {
      endpoint = `${endpoint}&$label=${label}`;
    }
    if (!isEmpty(searchTerm)) {
      endpoint = `${endpoint}&$filter=${searchAttribute}%20LIKE%20"${searchTerm}"`;
    }

    dispatch({
      type: actions.GETTING_CONVERSATIONS_SEARCH_RESULTS,
      companyId,
      perspective,
      withCompanyId,
      searchTerm
    });
    data
      .get(endpoint)
      .then(response => {
        dispatch({
          type: actions.GOT_CONVERSATIONS_SEARCH_RESULTS,
          conversations: response.data,
          companyId,
          perspective,
          withCompanyId,
          searchTerm
        });
      })
      .catch(response => {
        dispatch({
          type: actions.FAILED_GET_CONVERSATIONS_SEARCH_RESULTS,
          companyId,
          perspective,
          withCompanyId
        });
        handlePromiseError(
          response,
          "TODO: Searching conversations failed.  Please refresh the page.",
          "conversations"
        );
      });
  },
  400
);

const debouncedFetchConversationsSearchResults = (companyId, perspective, withCompanyId, params) => (
  dispatch,
  getState
) => {
  const store = getState().conversations;
  if (store.gettingNetworkAccountSearchResults && _.isEqual(params.searchTerm, store.conversationsSearchTerm)) {
    return;
  }
  debouncedFetchConversationsSearchResultsGet(companyId, perspective, withCompanyId, params, dispatch, getState);
};

export const fetchConversation = conversationId => (dispatch, getState) => {
  return data.get(`v1/api/conversation/${conversationId}`);
};

const getConversation = conversationId => (dispatch, getState) => {
  dispatch({ type: UserActions.USER_ACTIVITY });
  let state = getState().conversations;
  if (state.gettingConversation) return;

  dispatch({ type: actions.GETTING_CONVERSATION, conversationId });
  return data
    .get(`v1/api/conversation/${conversationId}`)
    .then(response => {
      dispatch({
        type: actions.GOT_CONVERSATION,
        conversation: response.data,
        conversationId
      });
      return response.data;
    })
    .catch(response => {
      dispatch({ type: actions.GET_CONVERSATION_FAILED, conversationId });
      handlePromiseError(response, "TODO: Getting the conversation failed.  Please try again.", "conversation");
    });
};

export const refreshConversation = () => (dispatch, getState) => {
  let state = getState().conversations;
  if (isEmpty(state.conversationId) === true || state.conversationId === "new") return;

  return dispatch(getConversation(state.conversationId));
};

let RELATED_PROMISES = {};
const fetchRelated = addressId => (dispatch, getState) => {
  let state = getState().conversations;
  let related = state.related[addressId];
  if (related) {
    return Promise.resolve({ data: related });
  }

  if (RELATED_PROMISES[addressId]) return RELATED_PROMISES[addressId];

  RELATED_PROMISES[addressId] = data
    .get(`v2/api/conversation/about/${addressId}?$top=${getState().general.pageRowCount}&$orderby=modifiedDate%20desc`)
    .then(response => {
      delete RELATED_PROMISES[addressId];
      dispatch({
        type: actions.GOT_RELATED,
        related: response.data,
        addressId
      });
    })
    .catch(response => {
      handlePromiseError(
        response,
        "TODO: Getting related conversations failed.  Please refresh the page.",
        "related conversations"
      );
    });
  return RELATED_PROMISES[addressId];
};

const deleteConversation = conversationId => (dispatch, getState) => {
  if (getState().conversations.isDeletingConversation) {
    return;
  }
  dispatch({ type: actions.DELETING_CONVERSATION });
  return data
    .delete(`v2/api/conversation/${conversationId}`)
    .then(response => {
      dispatch({ type: actions.DELETED_CONVERSATION });
      return true;
    })
    .catch(error => {
      dispatch({ type: actions.DELETE_CONVERSATION_FAILED });
      handlePromiseError(error, "TODO: Deleting the conversation failed. Please try again.", "conversation");
      throw error;
    });
};

const getRelated = addressId => (dispatch, getState) => {
  let state = getState().conversations;
  return state.related[addressId];
};

const assignConversationsTo = (conversationIds, perspective, userId) => (dispatch, getState) => {
  let ids = util.isString(conversationIds) ? [conversationIds] : util.isArray(conversationIds) ? conversationIds : null;
  if (isEmpty(ids)) return Promise.resolve();
  if (getState().conversations.assigningConversations === true) throw new Error("already assigning...you must wait");

  dispatch({ type: UserActions.USER_ACTIVITY });
  dispatch({ type: actions.ASSIGNING_CONVERSATIONS });
  return data
    .post(`v1/api/conversation/bulk/assign`, {
      conversationIds: ids,
      perspective,
      userId
    })
    .then(response => {
      dispatch({
        type: actions.ASSIGNED_CONVERSATIONS
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.ASSIGN_CONVERSATIONS_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Assigning the conversation failed.  Please refresh the page and try again.",
        "conversation assignment"
      );
    });
};

let SET_CONVERSATION_STATUSES_PROMISE = null;
const setConversationStatusesTo = (conversations, statusId, suppressError) => (dispatch, getState) => {
  let items = util.isArray(conversations) ? conversations : [conversations];
  if (isEmpty(items)) throw new Error("invalid parameter type for conversations");
  if (isEmpty(SET_CONVERSATION_STATUSES_PROMISE) !== true) throw new Error("already setting status...you must wait");

  let statusToConversations = {};
  items.forEach(conversation =>
    statusToConversations[conversation.conversationStatusId]
      ? statusToConversations[conversation.conversationStatusId].push(conversation)
      : (statusToConversations[conversation.conversationStatusId] = [conversation])
  );

  dispatch({ type: UserActions.USER_ACTIVITY });
  dispatch({ type: actions.SETTING_CONVERSATION_STATUSES });
  SET_CONVERSATION_STATUSES_PROMISE = Promise.all(
    Object.keys(statusToConversations).map(key => {
      let fromConversationStatusId = statusToConversations[key][0].conversationStatusId;
      return data.post(`v1/api/conversation/status/workflow/advance/bulk`, {
        conversationIds: statusToConversations[key].map(conversation => conversation.conversationId),
        fromConversationStatusId,
        toConversationStatusId: statusId
      });
    })
  )
    .then(responses => {
      SET_CONVERSATION_STATUSES_PROMISE = null;
      dispatch({
        type: actions.SET_CONVERSATION_STATUSES,
        conversations: responses.map(response => {
          return response.data;
        })
      });
      return true;
    })
    .catch(rejection => {
      dispatch({ type: actions.SET_CONVERSATION_STATUSES_FAILED });
      if (suppressError !== true) {
        handlePromiseError(
          rejection,
          "TODO: Setting the conversation status failed.  Please refresh the page and try again.",
          "conversation status"
        );
      }

      throw rejection;
    });
  return SET_CONVERSATION_STATUSES_PROMISE;
};

const isSelectedCompanyMatch = companyId => (dispatch, getState) => {
  return companyId === getState().conversations.companyId;
};

export const getConversationDocuments = (companyId, selector, next, perspective, top, skip) => (dispatch, getState) => {
  let state = getState().conversations.documents;

  let selectorIsGuid = isGuid(selector);

  // label acts as the withCompanyId or the label. ie. unassigned or mine
  let queryCompanyId = selectorIsGuid ? selector : undefined;

  let nextLink = next !== true ? null : removeLeading("/", state.nextLink);
  if (next === true && isEmpty(nextLink)) return;

  dispatch({ type: actions.GETTING_DOCUMENTS, companyId, selector, perspectiveId: perspective, next });
  return data
    .get(
      nextLink ||
        `v2/api/ledger/attachments/list/${companyId}${selectorIsGuid ? `/with/${queryCompanyId}?` : "?"}$orderby=${
          state.sortBy
        }%20${state.sortDirection}&$top=${top || getState().general.pageRowCount}${
          selectorIsGuid ? "" : `&$label=${selector}`
        }&$filter=perspectiveid%20eq%20${perspective}${isEmpty(skip) ? "" : `&$skip=${skip}`}`
    )
    .then(response => {
      dispatch({
        type: actions.GOT_DOCUMENTS,
        documents: response.data.value,
        companyId,
        selector,
        nextLink: response.data.nextLink,
        count: response.data.count,
        perspectiveId: perspective
      });
      return response.data.value;
    })
    .catch(error => {
      handlePromiseError(error, "TODO: Getting the list of documents failed.  Please refresh the page.", "documents");
      dispatch({ type: actions.GET_DOCUMENTS_FAILED, companyId, selector, perspectiveId: perspective });
    });
};

export const getReminders = (companyId, perspectiveId) => (dispatch, getState) => {
  dispatch({ type: actions.GETTING_REMINDERS, companyId, perspectiveId });
  return data
    .get(`v1/api/conversation/for/${companyId}/${perspectiveId}?$top=0&$label=reminder`)
    .then(response => {
      dispatch({
        type: actions.GOT_REMINDERS,
        reminders: response.data.value,
        companyId,
        perspectiveId,
        count: response.data.count
      });
      return response.data.value;
    })
    .catch(response => {
      dispatch({ type: actions.GET_REMINDERS_FAILED, companyId, perspectiveId });
      handlePromiseError(
        response,
        "TODO: Getting the list of reminders failed.  Please refresh the page.",
        "reminders"
      );
    });
};

export const getApprovalRequest = (conversationId, signature) => (dispatch, getState) => {
  let state = getState().conversations;
  if (state.gettingApprovalRequest === true) return;

  dispatch({ type: actions.GETTING_APPROVAL_REQUEST, conversationId, signature });
  return data
    .get(`v2/api/approvalrequest/${conversationId}/${signature}`)
    .then(response => {
      dispatch({
        type: actions.GOT_APPROVAL_REQUEST,
        approvalRequest: response.data,
        conversationId,
        signature
      });
      return response.data;
    })
    .catch(response => {
      dispatch({ type: actions.GET_APPROVAL_REQUEST_FAILED, conversationId, signature });
      handlePromiseError(response, "TODO: We were unable to locate the approval information.  Please try again.");
      throw response;
    });
};

export const putApprovalRequestAction = (conversationId, signature, action, comment) => (dispatch, getState) => {
  let state = getState().conversations;
  if (state.gettingConversation) return;

  dispatch({ type: actions.PUTTING_APPROVAL_REQUEST_ACTION, conversationId, signature, action });
  return data
    .put(
      `v2/api/approvalrequest/${conversationId}/${action}/${signature}`,
      { comment: comment },
      { headers: { Authorization: null } }
    )
    .then(response => {
      dispatch({
        type: actions.PUT_APPROVAL_REQUEST_ACTION,
        approvalRequest: response.data,
        conversationId,
        signature,
        action
      });
      return true;
    })
    .catch(error => {
      dispatch({ type: actions.PUT_APPROVAL_REQUEST_ACTION_FAILED, conversationId, signature, action });
      handlePromiseError(
        error,
        "TODO: Sending response to approval request failed, please try again or notify us if the issue persists."
      );
      throw error;
    });
};

export const updateConversationReadStatuses = (conversationIds, isRead) => (dispatch, getState) => {
  let state = getState().conversations;

  if (state.isUpdatingConversationReadStatuses) {
    return;
  }

  dispatch({ type: actions.UPDATING_CONVERSATION_READ_STATUSES });

  return data
    .post("v2/api/conversation/read", { conversationIds, isRead })
    .then(response => {
      // This action will update the new store property for conversation data used by the updated conversation table component. Eventually once we
      // are using a single store property for conversation data, one of these dispatch calls can be removed.
      dispatch({
        type: actions.SET_CONVERSATIONS_READ_STATUS,
        conversationIds,
        isRead
      });

      dispatch({
        type: actions.UPDATED_CONVERSATION_READ_STATUSES,
        conversationIds,
        isRead
      });

      return true;
    })
    .catch(error => {
      dispatch({ type: actions.UPDATE_CONVERSATION_READ_STATUSES_FAILED });
      handlePromiseError(
        error,
        "TODO: Updating conversation read status failed, please try again or notify us if the issue persists."
      );
      throw error;
    });
};

const setConversationsViewSearchTerm = (searchTerm) => (dispatch) => {
  dispatch({
    type: actions.SET_CONVERSATIONS_VIEW_SEARCH_TERM,
    conversationsViewSearchTerm: searchTerm
  });
}

const setConversationsViewData = (data) => (dispatch) => {
  dispatch({
    type: actions.SET_CONVERSATIONS_VIEW_DATA,
    conversationsViewData: data
  });
}

const setConversationsCurrentPage = (page) => (dispatch) => {
  dispatch({
    type: actions.SET_CONVERSATIONS_CURRENT_PAGE,
    conversationsCurrentPage: page
  });
}

const setConversationsSearchResultsCurrentPage = (page) => (dispatch) => {
  dispatch({
    type: actions.SET_CONVERSATIONS_SEARCH_RESULTS_CURRENT_PAGE,
    conversationsSearchResultsCurrentPage: page
  });
}

const setConversationsLabel = (value) => (dispatch) => {
  dispatch({
    type: actions.SET_CONVERSATIONS_LABEL,
    conversationsLabel: value
  });
}

const setDisplayingSearchResults = (value) => (dispatch) => {
  dispatch({
    type: actions.SET_DISPLAYING_SEARCH_RESULTS,
    displayingSearchResults: value
  });
}

const setUpdatingSearchResults = (value) => (dispatch) => {
  dispatch({
    type: actions.SET_UPDATING_SEARCH_RESULTS,
    updatingSearchResults: value
  });
}

export const dispatchToProps = dispatch => ({
  isSelectedCompanyMatch: companyId => {
    return dispatch(isSelectedCompanyMatch(companyId));
  },
  setConversationsToSearchResult: (searchResult, perspectiveId) => {
    dispatch({ type: actions.SET_CONVERSATIONS_TO_SEARCH_RESULT, perspectiveId, conversations: searchResult });
  },
  getConversationStatuses: companyId => {
    dispatch(getConversationStatuses(companyId));
  },
  ensureConversationStatuses: () => {
    dispatch(ensureConversationStatuses());
  },
  getConversationStatus: statusId => {
    return dispatch(getConversationStatus(statusId));
  },
  getConversationStatusByName: name => {
    return dispatch(getConversationStatusByName(name));
  },
  startConversation: activityType => {
    dispatch({ type: actions.START_CONVERSATION, activityType });
  },
  getConversations: (companyId, perspective, withCompanyId, next, moveToNext, top, skip) => {
    dispatch(getConversations(companyId, perspective, withCompanyId, next, moveToNext, top, skip));
  },
  fetchConversationsSearchResults: (companyId, perspective, withCompanyId, params) => {
    dispatch(fetchConversationsSearchResults(companyId, perspective, withCompanyId, params));
  },
  debouncedFetchConversationsSearchResultsGet: (companyId, perspective, withCompanyId, params) => {
    dispatch(debouncedFetchConversationsSearchResultsGet(companyId, perspective, withCompanyId, params));
  },
  debouncedFetchConversationsSearchResults: (companyId, perspective, withCompanyId, params) => {
    dispatch(debouncedFetchConversationsSearchResults(companyId, perspective, withCompanyId, params));
  },
  clearConversationsSearchResults: () => {
    dispatch({ type: actions.CLEAR_CONVERSATIONS_SEARCH_RESULTS });
  },
  getLabeledWithCompanyConversations: (companyId, perspective, withCompanyId, label, top, skip) => {
    dispatch(getLabeledWithCompanyConversations(companyId, perspective, withCompanyId, label, top, skip));
  },
  refreshConversations: (companyId, perspective, withCompanyId) => {
    dispatch(refreshConversations(companyId, perspective, withCompanyId));
  },
  fetchConversation: conversationId => {
    dispatch(fetchConversation(conversationId));
  },
  getConversation: conversationId => {
    return dispatch(getConversation(conversationId));
  },
  deleteConversation: conversationId => {
    return dispatch(deleteConversation(conversationId));
  },
  refreshConversation: () => {
    return dispatch(refreshConversation());
  },
  clearConversations: () => dispatch({ type: actions.CLEAR_CONVERSATIONS }),
  clearConversation: () => {
    dispatch({ type: actions.CLEAR_CONVERSATION });
    dispatch({ type: UserActions.USER_ACTIVITY });
  },
  assignConversationsTo: (conversationIds, perspective, userId) => {
    return dispatch(assignConversationsTo(conversationIds, perspective, userId));
  },
  setConversationStatusesTo: (conversations, statusId, suppressError) => {
    return dispatch(setConversationStatusesTo(conversations, statusId, suppressError));
  },
  getRelated: addressId => {
    return dispatch(getRelated(addressId));
  },
  fetchRelated: addressId => {
    return dispatch(fetchRelated(addressId));
  },
  setMaxRelatedLength: maxStorageSize => {
    dispatch({
      type: actions.SET_MAX_RELATED_LENGTH,
      maxLength: maxStorageSize
    });
  },
  setMoveToNext: bool => {
    return dispatch({
      type: actions.SET_CONVERSATION_MOVE_TO_NEXT,
      moveToNext: bool
    });
  },
  setConversationSort: (sortBy, sortDirection) => {
    return dispatch({
      type: actions.SET_CONVERSATION_SORT,
      sortBy,
      sortDirection
    });
  },
  setDocumentSort: (sortBy, sortDirection) => {
    return dispatch({
      type: actions.SET_DOCUMENT_SORT,
      sortBy,
      sortDirection
    });
  },
  getConversationDocuments: (companyId, perspective, withCompanyId, next, top, skip) => {
    return dispatch(getConversationDocuments(companyId, perspective, withCompanyId, next, top, skip));
  },
  getReminders: (companyId, perspectiveId) => {
    return dispatch(getReminders(companyId, perspectiveId));
  },
  getApprovalRequest: (conversationId, signature) => {
    return dispatch(getApprovalRequest(conversationId, signature));
  },
  putApprovalRequestAction: (conversationId, signature, action, comment) => {
    return dispatch(putApprovalRequestAction(conversationId, signature, action, comment));
  },
  updateConversationReadStatuses: (conversationIds, isRead) => {
    return dispatch(updateConversationReadStatuses(conversationIds, isRead));
  },
  setConversationsViewSearchTerm: (searchTerm) => {
    dispatch(setConversationsViewSearchTerm(searchTerm));
  },
  setConversationsViewData: (data) => {
    dispatch(setConversationsViewData(data));
  },
  setConversationsCurrentPage: (page) => {
    dispatch(setConversationsCurrentPage(page));
  },
  setConversationsSearchResultsCurrentPage: (page) => {
    dispatch(setConversationsSearchResultsCurrentPage(page));
  },
  setConversationsLabel: (value) => {
    dispatch(setConversationsLabel(value));
  },
  setDisplayingSearchResults: (value) => {
    dispatch(setDisplayingSearchResults(value));
  },
  setUpdatingSearchResults: (value) => {
    dispatch(setUpdatingSearchResults(value));
  }
});
