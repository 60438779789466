import React, { useEffect } from "react";
import { connect } from "react-redux";

import Modal from "react-modal";
import { ReactTypeformEmbed } from "react-typeform-embed";

import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { dispatchToProps } from "../../store/modal-actions";

import { event as FullStoryEvent } from "react-fullstory";

import IconClose from "../library/icons/iconClose";

const PortalAddVendorsModal = ({ modal: modalStore, flags, hideModal, selectedAccountId }) => {
  useEffect(() => {
    if (flags.portalAddVendorsConversion.enabled === false) {
      hideModal();
    }
  }, [flags, hideModal]);

  return (
    <Modal
      isOpen={modalStore.showModal}
      onRequestClose={() => hideModal()}
      contentLabel="Example Modal"
      className="custom-modal-wide add-vendors-modal"
      overlayClassName="custom-modal-overlay"
    >
      <div onClick={hideModal} className="heading-close button button-action-icon">
        <IconClose height="1.66rem" />
      </div>
      <ReactTypeformEmbed
        url={flags.portalAddVendorsConversion.typeformLink + "#accountid=" + selectedAccountId}
        onSubmit={() => storeAccountIdOnSubmit(selectedAccountId)}
      />
    </Modal>
  );
};

function storeAccountIdOnSubmit(accountId) {
  FullStoryEvent("Completed add vendors form");
  const localStorageVarName = "submitted-add-vendors-for-accountids";
  let submittedAccountIds = JSON.parse(window.localStorage.getItem(localStorageVarName) || "[]") || [];
  submittedAccountIds = [...submittedAccountIds, accountId];
  window.localStorage.setItem(localStorageVarName, JSON.stringify(submittedAccountIds));
}

const storeToProps = store => {
  return {
    modal: store.modal,
    selectedAccountId: store.accounts.selectedAccountId
  };
};

export default withLDConsumer()(connect(storeToProps, dispatchToProps)(PortalAddVendorsModal));
