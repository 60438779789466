import React, { Component } from "react";
import { isEmpty } from "../../lib/utils";
import IconSearch from "./icons/iconSearch";
import IconXCircle from "./icons/iconXCircle";
import Dropdown from "./dropdown";

// This component is used for fetching results from an api in the onKeyUp event

class AutoCompleteInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false
    };
    this.input = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.disabled || this.state.isActive) {
      return;
    }
    this.input.current.focus();
  }

  render() {
    let {
      width,
      disabled,
      options,
      loading,
      placeholder,
      className,
      dropdownHeader,
      hideDropdownMenu = false
    } = this.props;

    let dropDownMenuClassName = "";

    let dropdownMenu = null;

    if (this.state.isActive) {
      if (loading) {
        dropdownMenu = (
          <React.Fragment>
            <div className="background-masker dropdown-menu-masker-top"></div>
            <div className="background-masker dropdown-menu-masker-left1"></div>
            <div className="background-masker dropdown-menu-masker-right1"></div>
            <div className="background-masker dropdown-menu-masker-spacer1"></div>
            <div className="background-masker dropdown-menu-masker-left2"></div>
            <div className="background-masker dropdown-menu-masker-right2"></div>
            <div className="background-masker dropdown-menu-masker-spacer2"></div>
            <div className="background-masker dropdown-menu-masker-left3"></div>
            <div className="background-masker dropdown-menu-masker-right3"></div>
            <div className="background-masker dropdown-menu-masker-bottom"></div>
          </React.Fragment>
        );
        dropDownMenuClassName += " animated-background loading-dropdown-menu";
      } else if (options && options.length > 0) {
        dropdownMenu = (
          <React.Fragment>
            {!!dropdownHeader && dropdownHeader}
            {this.props.options.slice(0, this.props.maxOptions).map((option, i) => {
              return (
                <div
                  key={i}
                  className="dropdown-item-clean"
                  onClick={() => {
                    this.props.handleSelectOption(option);
                  }}
                  style={{ width }}
                >
                  {this.props.renderOption(option)}
                </div>
              );
            })}
            {this.props.callToActionButton || null}
          </React.Fragment>
        );
      } else if (this.props.showNoResultsMessage && !isEmpty(this.props.noResultsMessage)) {
        dropdownMenu = (
          <div className="dropdown-item no-hover" style={{ width, whiteSpace: "initial" }}>
            {this.props.noResultsMessage}
          </div>
        );
      }
    }

    return (
      <Dropdown
        buttonContent={
          <React.Fragment>
            <input
              type="text"
              autoComplete="off"
              disabled={disabled}
              onChange={this.props.onChange}
              onFocus={() => {
                this.setState({ isActive: true });
              }}
              value={this.props.text || ""}
              onBlur={() => setTimeout(() => this.setState({ isActive: false }), 200)}
              style={{
                border: "none",
                padding: "0",
                paddingRight: "10px"
              }}
              ref={this.input}
              placeholder={placeholder}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  typeof this.props.onSearch === "function" && this.props.onSearch();
                }
              }}
            />
            {this.props.isShowingSearchResult ? (
              <div
                onClick={e => {
                  e.stopPropagation();
                  this.props.handleClearResult();
                }}
              >
                <IconXCircle height="12" />
              </div>
            ) : (
              <IconSearch small className={this.state.isActive ? "active-search-icon" : ""} height="12" />
            )}
          </React.Fragment>
        }
        buttonClassName={"auto-complete-input " + className}
        buttonWidth={width}
        menuClassName={dropDownMenuClassName}
        menuWidth={width}
      >
        {!hideDropdownMenu && dropdownMenu}
      </Dropdown>
    );
  }
}

AutoCompleteInput.defaultProps = {
  maxOptions: 7
};

export default AutoCompleteInput;
