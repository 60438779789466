import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { isEmpty, find, isGuid, getRRule } from "../../lib/utils";
import Resources from "../../lib/resources";

import IconRead from "../library/icons/iconRead";
import IconUnread from "../library/icons/iconUnread";
import IconArrowUp from "../library/icons/iconArrowUp";
import IconXCircle from "../library/icons/iconXCircle";
import IconSlash from "../library/icons/iconSlash";
import IconUsers from "../library/icons/iconUsers";
import IconMove from "../library/icons/iconMove";
import IconBell from "../library/icons/iconBell";

import UserPicker from "../userPicker";
import FilterInput from "../library/filterInput";

import { dispatchToProps as uaDP } from "../../store/user-actions";
import { dispatchToProps as convDP } from "../../store/conversations-actions";
import { dispatchToProps as connDP } from "../../store/connector-actions";
import { dispatchToProps as congDP } from "../../store/contextGroups-actions";
import { dispatchToProps as netDP } from "../../store/network-actions";
import { dispatchToProps as ledgDP } from "../../store/ledger-actions";
import { dispatchToProps as modDP } from "../../store/modal-actions";
import Dropdown from "../library/dropdown";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import IconRightArrowCircle from "../library/icons/iconRightArrowCircle";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...convDP(dispatch),
  ...connDP(dispatch),
  ...congDP(dispatch),
  ...netDP(dispatch),
  ...ledgDP(dispatch),
  ...modDP(dispatch)
});

class ConversationActionButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remapIdentifiersOnMove: false,
      selectedNewChannel: { displayName: "", value: "" }
    };
  }

  render() {
    const { selectedRows, selectedKeys, perspectiveId, conversationsStore, ledgerStore } = this.props;
    const containsUnreadConversations = selectedRows.map(row => row.hasUnreads).reduce((a, b) => a || b, false);
    const containsReadConversations = selectedRows.map(row => row.hasUnreads).reduce((a, b) => a || !b, false);
    const { selectedContextGroupId } = this.props.match.params;
    const isSpamView = selectedContextGroupId === "spam" || (!this.props.lastEventStatusChange && selectedContextGroupId === "closed")
    return (
      <div className="conversation-action-buttons">
        {containsUnreadConversations && (
          <button
            className="button-primary bg-white"
            disabled={isEmpty(selectedRows) || conversationsStore.isUpdatingConversationReadStatuses}
            onClick={() => {
              this.props.updateConversationReadStatuses(selectedKeys, true);
              this.props.displayNotification("markingConversationRead");
            }}
          >
            <IconRead height="21" className="button-primary-icon" />
            {Resources.MarkAsRead}
          </button>
        )}
        {containsReadConversations && (
          <button
            className="button-primary bg-white"
            disabled={isEmpty(selectedRows) || conversationsStore.isUpdatingConversationReadStatuses}
            onClick={() => {
              this.props.updateConversationReadStatuses(selectedKeys, false);
              this.props.displayNotification("markingConversationUnread");
            }}
          >
            <IconUnread height="16" className="button-primary-icon" />
            {Resources.MarkAsUnread}
          </button>
        )}
        {this.props.match.params.selectedContextGroupId === "closed" ? (
          <button
            className="button-primary bg-white"
            disabled={
              conversationsStore.assigningConversations ||
              conversationsStore.settingConversationStatuses ||
              Object.keys(selectedRows).length === 0
            }
            onClick={() => {
              let status = find(conversationsStore.statuses, status => status.conversationStatusName === "Active");
              let impactedConversations = [];
              selectedRows.forEach(row => {
                if (row.conversationStatusId !== status.conversationStatusId) impactedConversations.push(row);
              });
              this.props.setConversationStatusesTo(impactedConversations, status.conversationStatusId).then(() => {
                this.props.actionComplete();
              });
              this.props.displayNotification("activatingConversations");
            }}
          >
            <IconArrowUp height="22" className="button-primary-icon" />
            {Resources.Activate}
          </button>
        ) : (
          <button
            className="button-primary bg-white"
            disabled={
              conversationsStore.assigningConversations ||
              conversationsStore.settingConversationStatuses ||
              Object.keys(selectedRows).length === 0
            }
            onClick={() => {
              let status = find(conversationsStore.statuses, status => status.conversationStatusName === "Closed");
              let impactedConversations = [];
              selectedRows.forEach(row => {
                if (row.conversationStatusId !== status.conversationStatusId) impactedConversations.push(row);
              });
              this.props.setConversationStatusesTo(impactedConversations, status.conversationStatusId).then(() => {
                this.props.actionComplete();
              });
              this.props.displayNotification("closingConversations");
            }}
          >
            <IconXCircle height="20" className="button-primary-icon" />
            {Resources.Close}
          </button>
        )}
        <UserPicker
          perspective={perspectiveId}
          buttonContent={
            <React.Fragment>
              <IconUsers height="20" className="button-primary-icon" />
              {Resources.Assign}
            </React.Fragment>
          }
          buttonClassName="button-primary bg-white"
          //disableUserId={selectedUserId}
          disabled={conversationsStore.assigningConversations || Object.keys(selectedRows).length === 0}
          userSelected={user => {
            let impactedConversations = [];
            selectedRows.forEach(row => {
              if (row.assignedUserId !== user.userId) {
                impactedConversations.push(row.conversationId);
              }
            });
            this.props.assignConversationsTo(impactedConversations, perspectiveId, user.userId).then(() => {
              this.props.actionComplete();
            });
            this.props.displayNotification("assigningConversation");
          }}
        />
        <Dropdown
          buttonContent={
            <React.Fragment>
              <IconBell height="20" className="button-primary-icon" />
              {Resources.Snooze}
            </React.Fragment>
          }
          buttonClassName="button-primary bg-white"
          disabled={
            selectedRows.length !== 1 ||
            ((this.props.manageStore || {}).workflows[perspectiveId] || []).length === 0 ||
            ledgerStore.creatingEntry
          }
        >
          {[
            {
              displayName: Resources.UntilTomorrow,
              value: moment(
                moment()
                  .add(1, "day")
                  .startOf("day")
                  .set("hour", 8)
                  .format()
              )
            },
            {
              displayName: Resources.UntilFriday,
              value: moment(
                moment()
                  .startOf("day")
                  .set("hour", 8)
                  .add("day", 5 - moment().day() < 0 ? 5 - moment().day() + 7 : 5 - moment().day())
              )
            },
            {
              displayName: Resources.UntilNextWeek,
              value: moment(
                moment()
                  .add(1, "week")
                  .startOf("week")
                  .set("hour", 8)
              )
            },
            {
              displayName: Resources.UntilNextMonth,
              value: moment(
                moment()
                  .add(1, "month")
                  .startOf("month")
                  .set("hour", 8)
              )
            }
          ].map((date, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => {
                this.props.displayNotification("snoozingActivity");
                this.props
                  .putReminder(
                    this.props.match.params.companyId,
                    (((this.props.manageStore || {}).workflows[perspectiveId] || [])[0] || {}).ledgerHash,
                    selectedRows[0].conversationId,
                    getRRule(date.value),
                    date.value
                  )
                  .then(() => {
                    this.props.getReminders(this.props.match.params.companyId, perspectiveId);
                    this.props.actionComplete();
                  });
              }}
            >
              {date.displayName}
            </Dropdown.Item>
          ))}
          <Dropdown.Item
            onClick={e => {
              this.props.displayModal("setReminder", {
                companyId: this.props.match.params.companyId,
                conversationId: selectedRows[0].conversationId,
                perspectiveId,
                selector: this.props.match.params.withCompanyId,
                actionComplete: this.props.actionComplete
              });
            }}
          >
            {Resources.CustomDate}
          </Dropdown.Item>
        </Dropdown>
        <button
          className="button-primary bg-white"
          disabled={
            conversationsStore.assigningConversations ||
            conversationsStore.settingConversationStatuses ||
            isEmpty(selectedRows)
          }
          onClick={() => {
            const statusName = isSpamView ? "Active" : "Spam";
            const notification = isSpamView ? "activatingConversations" : "markingConversationAsSpam";
            let status = find(conversationsStore.statuses, status => status.conversationStatusName === statusName);
            let impactedConversations = [];
            selectedRows.forEach(row => {
              if (row.conversationStatusId !== status.conversationStatusId) {
                impactedConversations.push(row);
              }
            });
            this.props.setConversationStatusesTo(impactedConversations, status.conversationStatusId).then(() => {
              this.props.actionComplete();
            });
            this.props.displayNotification(notification);
          }}
        >
          <IconSlash height="20" className="button-primary-icon" />
          {isSpamView ? Resources.NotSpam : Resources.Spam}
        </button>
        <Dropdown
          buttonContent={
            <React.Fragment>
              <IconMove height="20" className="button-primary-icon" />
              {Resources.MoveChannel}
            </React.Fragment>
          }
          buttonClassName="button-primary bg-white"
          disabled={selectedRows.length !== 1}
          menuClassName="conversation-action-buttons__move__menu"
          isForm
        >
          <FilterInput
            disabled={false}
            width="300px"
            selected={this.state.selectedNewChannel}
            options={this.props.contextGroupsStore.value || []}
            displayKey="displayName"
            placeholder={Resources.LoadingOptions}
            closeDropdownOnClick
            handleSelectOption={option => {
              this.props
                .moveConversationToContextGroup(
                  this.props.match.params.companyId,
                  perspectiveId,
                  option.contextGroupId,
                  selectedRows[0].conversationId,
                  this.state.remapIdentifiersOnMove
                )
                .then(response => {
                  if (isGuid(this.props.match.params.selectedContextGroupId)) {
                    this.props.history.push(
                      this.props.location.pathname.replace(
                        this.props.match.params.selectedContextGroupId,
                        option.companyLocalKey
                      )
                    );
                  }
                  this.props.getConversation(selectedRows[0].conversationId);
                });
              this.setState({ selectedNewChannel: option });
            }}
          />
        </Dropdown>
        {this.props.flags.forwarding && !this.props.noForward ? (
          <button
            className="button-primary bg-white"
            disabled={
              conversationsStore.assigningConversations ||
              conversationsStore.settingConversationStatuses ||
              isEmpty(selectedRows)
            }
            onClick={() => {
              this.props.updateEditorMode("forward");
            }}
          >
            <IconRightArrowCircle className="button-primary-icon" />
            {Resources.Forward}
          </button>
        ) : null}
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    conversationsStore: store.conversations,
    connectorStore: store.connector,
    ledgerStore: store.ledger,
    workflows: store.manage.workflows,
    contextGroupsStore: store.contextGroups,
    manageStore: store.manage
  };
};

export default withLDConsumer()(withRouter(connect(storeToProps, dispatchToProps)(ConversationActionButtons)));
