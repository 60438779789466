import moment from "moment";

import { actions as GeneralActions } from "./general-actions";
import { refreshConversation } from "./conversations-actions";
import { handlePromiseError } from "./error-actions";

import data from "../lib/data";
import Resources from "../lib/resources";
import {
  isEmpty,
  compareDates,
  getQueryParameters,
  find,
  createKey,
  getAttatchedDocumentLedgerContent,
  saveBlobAsDownload
} from "../lib/utils";

export const actions = {
  ...GeneralActions,
  ...{
    SELECT_RELATIONSHIP: "SELECT_RELATIONSHIP",

    GETTING_GRANTS_FROM: "GETTING_GRANTS_FROM",
    GOT_GRANTS_FROM: "GOT_GRANTS_FROM",
    GET_GRANTS_FROM_FAILED: "GET_GRANTS_FROM_FAILED",

    GETTING_GRANTS_TO: "GETTING_GRANTS_TO",
    GOT_GRANTS_TO: "GOT_GRANTS_TO",
    GET_GRANTS_TO_FAILED: "GET_GRANTS_TO_FAILED",

    FETCHING_RESOURCES: "FETCHING_RESOURCES",
    FETCHED_RESOURCES: "FETCHED_RESOURCES",
    FETCH_RESOURCES_FAILED: "FETCH_RESOURCES_FAILED",

    FETCHING_RESOURCE_CONFIG_SCHEMA: "FETCHING_RESOURCE_CONFIG_SCHEMA",
    FETCHED_RESOURCE_CONFIG_SCHEMA: "FETCHED_RESOURCE_CONFIG_SCHEMA",
    FETCH_RESOURCE_CONFIG_SCHEMA_FAILED: "FETCH_RESOURCE_CONFIG_SCHEMA_FAILED",

    GETTING_RESOURCES_FROM: "GETTING_RESOURCES_FROM",
    GOT_RESOURCES_FROM: "GOT_RESOURCES_FROM",
    GET_RESOURCES_FROM_FAILED: "GET_RESOURCES_FROM_FAILED",

    GETTING_RESOURCES_TO: "GETTING_RESOURCES_TO",
    GOT_RESOURCES_TO: "GOT_RESOURCES_TO",
    GET_RESOURCES_TO_FAILED: "GET_RESOURCES_TO_FAILED",

    FETCHING_COMPANY_RESOURCES: "FETCHING_COMPANY_RESOURCES",
    FETCHED_COMPANY_RESOURCES: "FETCHED_COMPANY_RESOURCES",
    FETCH_COMPANY_RESOURCES_FAILED: "FETCH_COMPANY_RESOURCES_FAILED",

    CREATING_DRAFT: "CREATING_DRAFT",
    CREATED_DRAFT: "CREATED_DRAFT",
    CREATE_DRAFT_FAILED: "CREATE_DRAFT_FAILED",

    FORWARDING_DRAFT: "FORWARDING_DRAFT",
    FORWARDED_DRAFT: "FORWARDED_DRAFT",
    FORWARDED_DRAFT_FAILED: "FORWARDED_DRAFT_FAILED",

    COMMITTING_DRAFT: "COMMITTING_DRAFT",
    COMMITTED_DRAFT: "COMMITTED_DRAFT",
    COMMIT_DRAFT_FAILED: "COMMIT_DRAFT_FAILED",

    CREATING_ENTRY: "CREATING_ENTRY",
    CREATED_ENTRY: "CREATED_ENTRY",
    CREATE_ENTRY_FAILED: "CREATE_ENTRY_FAILED",

    FETCHING_ENTRY: "FETCHING_ENTRY",
    GOT_ENTRY: "GOT_ENTRY",
    SET_MAX_ENTRIES_LENGTH: "SET_MAX_ENTRIES_LENGTH",

    GETTING_ATTACHMENTS: "GETTING_ATTACHMENTS",
    GOT_ATTACHMENTS: "GOT_ATTACHMENTS",
    GET_ATTACHMENTS_FAILED: "GET_ATTACHMENTS_FAILED",

    UPLOADING_DOCUMENT: "UPLOADING_DOCUMENT",
    UPLOADED_DOCUMENT: "UPLOADED_DOCUMENT",
    UPLOAD_DOCUMENT_FAILED: "UPLOAD_DOCUMENT_FAILED",

    DELETING_ATTACHMENTS: "DELETING_ATTACHMENTS",
    DELETED_ATTACHMENTS: "DELETED_ATTACHMENTS",
    DELETE_ATTACHMENTS_FAILED: "DELETE_ATTACHMENTS_FAILED",

    SET_MAX_ATTACHMENTS_LENGTH: "SET_MAX_ATTACHMENTS_LENGTH",
    ADDING_ATTACHMENT: "ADDING_ATTACHMENT",
    ADDED_ATTACHMENT: "ADDED_ATTACHMENT",
    ADD_ATTACHMENT_FAILED: "ADD_ATTACHMENT_FAILED",

    ADDED_DOCUMENT_ATTACHMENT: "ADDED_DOCUMENT_ATTACHMENT",

    GETTING_ATTACHMENT_URL: "GETTING_ATTACHMENT_URL",
    GOT_ATTACHMENT_URL: "GOT_ATTACHMENT_URL",
    GET_ATTACHMENT_URL_FAILED: "GET_ATTACHMENT_URL_FAILED",
    CLEAR_ATTACHMENT_URL: "CLEAR_ATTACHMENT_URL",

    GETTING_ATTACHMENT_URLS: "GETTING_ATTACHMENT_URLS",
    GOT_ATTACHMENT_URLS: "GOT_ATTACHMENT_URLS",
    GET_ATTACHMENT_URLS_FAILED: "GET_ATTACHMENT_URLS_FAILED",

    GETTING_ATTACHMENTS_ZIP: "GETTING_ATTACHMENTS_ZIP",
    GOT_ATTACHMENTS_ZIP: "GOT_ATTACHMENTS_ZIP",
    GET_ATTACHMENTS_ZIP_FAILED: "GET_ATTACHMENTS_ZIP_FAILED",

    FETCHING_ENTRY_CONFIG: "FETCHING_ENTRY_CONFIG",
    FETCHED_ENTRY_CONFIG: "FETCHED_ENTRY_CONFIG",
    FETCH_ENTRY_CONFIG_FAILED: "FETCH_ENTRY_CONFIG_FAILED",

    UPDATING_ENTRY_CONFIG: "UPDATING_ENTRY_CONFIG",
    UPDATED_ENTRY_CONFIG: "UPDATED_ENTRY_CONFIG",
    UPDATE_ENTRY_CONFIG_FAILED: "UPDATE_ENTRY_CONFIG_FAILED",

    PUTTING_CONVERSATION_REMINDER: "PUTTING_CONVERSATION_REMINDER",
    PUT_CONVERSATION_REMINDER: "PUT_CONVERSATION_REMINDER",
    FAILED_PUT_CONVERSATION_REMINDER: "FAILED_PUT_CONVERSATION_REMINDER",

    SET_MAX_ATTACHMENT_URLS_LENGTH: "SET_MAX_ATTACHMENT_URLS_LENGTH"
  }
};

const getGrantsFrom = companyId => (dispatch, getState) => {
  let state = getState().ledger;
  if (state.gettingGrantsFrom) return;
  dispatch({ type: actions.GETTING_GRANTS_FROM });
  data
    .get(`v1/api/permission/grants/from/${companyId}`)
    .then(response => {
      dispatch({
        type: actions.GOT_GRANTS_FROM,
        grantsFrom: response.data,
        companyId: companyId
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_GRANTS_FROM_FAILED });
      handlePromiseError(response, Resources.GrantsFetchFailure, "granted permissions");
    });
};

const getResourcesFrom = (companyId, resourceName, continuation) => (dispatch, getState) => {
  let state = getState().ledger;
  if (state.gettingResourcesFrom) return;
  dispatch({ type: actions.GETTING_RESOURCES_FROM });
  data
    .get(`v1/api/ledger/from/${companyId}/${resourceName}${continuation ? `?continuation=${continuation}` : ""}`)
    .then(response => {
      dispatch({
        type: actions.GOT_RESOURCES_FROM,
        companyId: companyId,
        resourceName: resourceName,
        continuation: continuation,
        resourcesFrom: response.data
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_RESOURCES_FROM_FAILED });
      handlePromiseError(response, Resources.ResourcesFetchFailure, "company resources");
    });
};

const getGrantsTo = companyId => (dispatch, getState) => {
  let state = getState().ledger;
  if (state.gettingGrantsTo) return;
  dispatch({ type: actions.GETTING_GRANTS_TO });
  data
    .get(`v1/api/permission/grants/to/${companyId}`)
    .then(response => {
      dispatch({
        type: actions.GOT_GRANTS_TO,
        grantsTo: response.data,
        companyId: companyId
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_GRANTS_TO_FAILED });
      handlePromiseError(response, Resources.GrantsFetchFailure, "granted permissions");
    });
};

const fetchResources = () => (dispatch, getState) => {
  if (getState().ledger.isFetchingResources) {
    return;
  }
  dispatch({ type: actions.FETCHING_RESOURCES });
  data
    .get("v1/api/resource")
    .then(response => {
      dispatch({ type: actions.FETCHED_RESOURCES, resources: response.data });
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_RESOURCES_FAILED });
      handlePromiseError(rejection, Resources.FetchResourcesFailure, "lockstep resources");
    });
};

const getResourcesForPerspective = perspectiveId => (dispatch, getState) => {
  return getState().ledger.resources.filter(resource => {
    return resource.perspectiveId === perspectiveId || resource.perspective === null;
  });
};

const getResource = (key, value) => (dispatch, getState) => {
  return find(getState().ledger.resources, r => {
    return r[key].toLowerCase() === value.toLowerCase();
  });
};

const fetchResourceConfigSchema = resourceId => (dispatch, getState) => {
  if (getState().ledger.isFetchingResourceConfigSchema[resourceId]) {
    return;
  }
  dispatch({ type: actions.FETCHING_RESOURCE_CONFIG_SCHEMA, resourceId });
  data
    .get(`v1/api/resource/${resourceId}/config/schema`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_RESOURCE_CONFIG_SCHEMA,
        resourceId,
        configSchema: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: actions.FETCH_RESOURCE_CONFIG_SCHEMA_FAILED,
        resourceId
      });
      handlePromiseError(
        error,
        "TODO: Fetching the configuration schema for the resource failed.",
        "configuration schema"
      );
    });
};

const getResourceConfigSchema = resourceId => (dispatch, getState) => {
  const store = getState().ledger;
  if (store.fetchingResourceConfigSchemaFailed[resourceId] === undefined) {
    dispatch(fetchResourceConfigSchema(resourceId));
    return null;
  }
  return store.resourceConfigSchemas[resourceId];
};

const getResourcesTo = (companyId, resourceName, nextLink) => (dispatch, getState) => {
  let state = getState().ledger;
  if (state.gettingResourcesTo) return;
  dispatch({ type: actions.GETTING_RESOURCES_TO });
  data
    .get(nextLink || `v1/api/ledger/to/${companyId}/${resourceName}`)
    .then(response => {
      dispatch({
        type: actions.GOT_RESOURCES_TO,
        companyId: companyId,
        resourceName: resourceName,
        nextLink: nextLink,
        resourcesTo: response.data
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_RESOURCES_TO_FAILED });
      handlePromiseError(response, Resources.ResourcesFetchFailure, "company resources");
    });
};

// This fetches resources that exist between two companies
const fetchCompanyResources = (fromCompanyId, toCompanyId, resourceName, nextLink) => (dispatch, getState) => {
  let state = getState().ledger;

  if (state.isFetchingCompanyResources[createKey(toCompanyId, resourceName)]) {
    return;
  }
  dispatch({
    type: actions.FETCHING_COMPANY_RESOURCES,
    toCompanyId,
    resourceName
  });
  data
    .get(nextLink || `v1/api/ledger/from/${fromCompanyId}/to/${toCompanyId}/${resourceName}`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_COMPANY_RESOURCES,
        toCompanyId,
        resourceName,
        nextLink,
        resources: response.data
      });
    })
    .catch(response => {
      dispatch({
        type: actions.FETCH_COMPANY_RESOURCES_FAILED,
        toCompanyId,
        resourceName
      });
      handlePromiseError(response, Resources.ResourcesFetchFailure, "company resources");
    });
};

const getCompanyResources = (fromCompanyId, toCompanyId, resourceName, nextLink) => (dispatch, getState) => {
  const state = getState().ledger;
  const resourceKey = createKey(toCompanyId, resourceName);
  if (state.fetchingCompanyResourcesFailed[resourceKey] === undefined) {
    dispatch(fetchCompanyResources(fromCompanyId, toCompanyId, resourceName, nextLink));
    return null;
  }
  return state.companyResources[resourceKey];
};

const createEntry = (companyId, resourceName, content, headers) => (dispatch, getState) => {
  if (getState().ledger.creatingEntry === true) {
    return;
  }
  dispatch({ type: actions.CREATING_ENTRY });
  return data
    .put(`v1/api/ledger/from/${companyId}/${resourceName}/create`, content, {
      headers: headers
    })
    .then(response => {
      dispatch({ type: actions.CREATED_ENTRY });
      return response.data;
    })
    .catch(rejection => {
      dispatch({ type: actions.CREATE_ENTRY_FAILED });
      if (resourceName === "approval") {
        handlePromiseError(
          rejection,
          "TODO: Approving the transaction failed. Please refresh and try again.",
          "approval"
        );
      } else if (resourceName === "approvalRequest") {
        handlePromiseError(
          rejection,
          "TODO: Creating the approval request failed. Please refresh and try again.",
          "approval request"
        );
      }
      throw rejection;
    });
};

const createEntryToContextGroup = (companyId, withContextGroupId, resourceName, content, headers) => (
  dispatch,
  getState
) => {
  if (getState().ledger.creatingEntry === true) {
    return;
  }
  dispatch({ type: actions.CREATING_ENTRY });
  return data
    .put(`v2/api/ledger/from/${companyId}/to/${withContextGroupId}/${resourceName}/create`, content, {
      headers: headers
    })
    .then(response => {
      dispatch({ type: actions.CREATED_ENTRY });
      return response.data;
    })
    .catch(rejection => {
      dispatch({ type: actions.CREATE_ENTRY_FAILED });
      switch (resourceName) {
        case "approval":
          handlePromiseError(
            rejection,
            "TODO: Approving the transaction failed. Please refresh and try again.",
            "approval"
          );
          break;
        case "approvalRequest":
          handlePromiseError(
            rejection,
            "TODO: Creating the approval request failed. Please refresh and try again.",
            "approval request"
          );
          break;
        case "ApplicationResponse":
          handlePromiseError(rejection, "TODO: Creating the dispute failed. Please refresh and try again.", "dispute");
          break;
        case "DebitNote":
          handlePromiseError(
            rejection,
            "TODO: Creating the promise to pay failed. Please refresh and try again.",
            "promise to pay"
          );
          break;
        default:
          break;
      }
      throw rejection;
    });
};

export const createDraft = (companyId, resourceName, content) => (dispatch, getState) => {
  dispatch({ type: actions.CREATING_DRAFT });
  return data
    .put(`v1/api/ledger/from/${companyId}/draft/${resourceName}/create`, content)
    .then(res => {
      dispatch({ type: actions.CREATED_DRAFT });
      return res;
    })
    .catch(err => {
      dispatch({ type: actions.CREATE_DRAFT_FAILED });
      throw err;
    });
};

export const forwardDraft = (companyId, resourceName, content) => (dispatch, getState) => {
  dispatch({ type: actions.FORWARDING_DRAFT });
  return data
    .put(`v1/api/ledger/from/${companyId}/draft/${resourceName}/create`, content)
    .then(res => {
      dispatch({ type: actions.FORWARDED_DRAFT });
      return res;
    })
    .catch(err => {
      dispatch({ type: actions.FORWARDED_DRAFT_FAILED });
      throw err;
    });
};

export const saveDraft = (ledgerHash, content) => (dispatch, getState) => {
  return data.post(`v1/api/ledger/draft/${ledgerHash}`, content);
};

export const deleteDraft = ledgerHash => (dispatch, getState) => {
  data
    .delete(`v1/api/ledger/draft/${ledgerHash}`)
    .then(() => {
      dispatch(refreshConversation());
    })
    .catch(rejection => {
      handlePromiseError(
        rejection,
        "TODO: Delete of the draft failed.  Please refresh the page and try again.",
        "draft"
      );
    });
};

export const commitDraft = ledgerHash => (dispatch, getState) => {
  dispatch({ type: actions.COMMITTING_DRAFT });
  return data
    .post(`v1/api/ledger/draft/${ledgerHash}/commit`)
    .then(res => {
      dispatch({ type: actions.COMMITTED_DRAFT });
      return res;
    })
    .catch(err => {
      dispatch({ type: actions.COMMIT_DRAFT_FAILED });
      throw err;
    });
};

export const deleteAttachments = attachmentIds => (dispatch, getState) => {
  dispatch({ type: actions.DELETING_ATTACHMENTS });
  let deletionPromises = attachmentIds.map(attachmentId => {
    return dispatch(deleteAttachment(attachmentId));
  });
  return Promise.all(deletionPromises)
    .then(() => {
      dispatch({ type: actions.DELETED_ATTACHMENTS });
    })
    .catch(error => {
      dispatch({ type: actions.DELETE_ATTACHMENTS_FAILED });
    });
};

export const deleteAttachment = attachmentId => (dispatch, getState) => {
  return data.delete(`v1/api/ledger/attachments/${attachmentId}`);
};

const addAttachment = (ledgerHash, file) => (dispatch, getState) => {
  let body = new FormData();
  body.append("file", file);
  dispatch({ type: actions.ADDING_ATTACHMENT });
  return data
    .post(`v1/api/ledger/attachments/${ledgerHash}`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({
        type: actions.ADDED_ATTACHMENT,
        attachment: response.data,
        ledgerHash
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.ADD_ATTACHMENT_FAILED });
      handlePromiseError(rejection, "TODO: Adding the attachment failed.  Please try again.", "attachment");
    });
};

const uploadDocument = (companyId, withContextGroupId, file) => (dispatch, getState) => {
  dispatch({ type: actions.UPLOADING_DOCUMENT });
  return dispatch(
    createEntryToContextGroup(companyId, withContextGroupId, "AttachedDocument", getAttatchedDocumentLedgerContent(), {
      "Content-Type": "application/xml"
    })
  )
    .then(result => {
      dispatch(addAttachment(result.ledgerHash, file)).then(result => {
        dispatch({ type: actions.UPLOADED_DOCUMENT });
      });
    })
    .catch(error => {
      dispatch({ type: actions.UPLOAD_DOCUMENT_FAILED });
    });
};

const addDocumentAttachment = (ledgerHash, companyDocumentId) => (dispatch, getState) => {
  return data
    .post(`v1/api/ledger/attachments/${ledgerHash}/document`, JSON.stringify(companyDocumentId), {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      dispatch({
        type: actions.ADDED_DOCUMENT_ATTACHMENT,
        attachment: response.data,
        ledgerHash
      });
    })
    .catch(rejection => {
      handlePromiseError(rejection, "TODO: Adding the attachment failed.  Please try again.", "attachment");
    });
};

let ENTRY_PROMISES = {};
export const fetchEntry = (ledgerHash, isDraft) => (dispatch, getState) => {
  let state = getState().ledger;
  let entry = state.entries[ledgerHash];
  if (entry) {
    return Promise.resolve({ data: entry });
  }

  if (ENTRY_PROMISES[ledgerHash]) return ENTRY_PROMISES[ledgerHash];

  dispatch({ type: actions.FETCHING_ENTRY, ledgerHash });

  ENTRY_PROMISES[ledgerHash] = data
    .get(`v1/api/ledger/${isDraft === true ? "draft" : "entry"}/${ledgerHash}`)
    .then(response => {
      delete ENTRY_PROMISES[ledgerHash];
      dispatch({ type: actions.GOT_ENTRY, entry: response.data, ledgerHash });
    })
    .catch(rejection => {
      handlePromiseError(
        rejection,
        "TODO: Fetching a conversation element failed.  Please retry your request.",
        "entry"
      );
    });
  return ENTRY_PROMISES[ledgerHash];
};

export const getEntry = ledgerHash => (dispatch, getState) => {
  let state = getState().ledger;
  return state.entries[ledgerHash];
};

const fetchAttachments = ledgerHash => (dispatch, getState) => {
  let state = getState().ledger;
  let attachments = state.attachments[ledgerHash];
  if (attachments || state.gettingAttachments[ledgerHash] === true) return;

  dispatch({ type: actions.GETTING_ATTACHMENTS, ledgerHash });
  data
    .get(`v1/api/ledger/attachments/for/${ledgerHash}`)
    .then(response => {
      dispatch({
        type: actions.GOT_ATTACHMENTS,
        attachments: response.data,
        ledgerHash
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_ATTACHMENTS_FAILED, ledgerHash });
      handlePromiseError(
        response,
        "TODO: Getting the related attachments failed.  Please refresh your page.",
        "attachments"
      );
    });
};

const getAttachments = ledgerHash => (dispatch, getState) => {
  let state = getState().ledger;
  return state.attachments[ledgerHash];
};

const fetchAttachmentUrl = attachmentId => (dispatch, getState) => {
  let state = getState().ledger;
  let attachmentUrl = state.attachmentUrls[attachmentId];
  if (attachmentUrl || state.gettingAttachmentUrl[attachmentId] === true) return;

  dispatch({ type: actions.GETTING_ATTACHMENT_URL, attachmentId });
  data
    .get(`v1/api/ledger/attachments/${attachmentId}/url`)
    .then(response => {
      let url = response.data;
      let res = getQueryParameters(url);
      let expiresIn = parseInt(res["X-Amz-Expires"], 10) || 600;
      let strDate = res["X-Amz-Date"];
      let date = isEmpty(strDate) ? moment() : moment(strDate);
      let expirationDate = date.add(expiresIn, "seconds");
      dispatch({
        type: actions.GOT_ATTACHMENT_URL,
        attachmentUrl: { url, expirationDate },
        attachmentId
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_ATTACHMENT_URL_FAILED, attachmentId });
      handlePromiseError(
        response,
        "TODO: Getting the attachment url failed.  Please refresh your page.",
        "attachment url"
      );
    });
};

const fetchAttachmentUrls = attachmentIds => (dispatch, getState) => {
  let state = getState().ledger;
  if (state.gettingAttachmentUrl[attachmentIds] === true) {
    return;
  }

  dispatch({ type: actions.GETTING_ATTACHMENT_URLS, attachmentIds });
  data
    .post("v1/api/ledger/attachments/urls", attachmentIds)
    .then(response => {
      let attachmentUrls = response.data.map((attachmentUrl, i) => {
        let urlWithExpiration = {};
        urlWithExpiration.url = attachmentUrl.signedUrl;
        let res = getQueryParameters(attachmentUrl.signedUrl);
        let expiresIn = parseInt(res["X-Amz-Expires"], 10) || 600;
        let strDate = res["X-Amz-Date"];
        let date = isEmpty(strDate) ? moment() : moment(strDate);
        urlWithExpiration.expirationDate = date.add(expiresIn, "seconds");
        urlWithExpiration.attachmentId = attachmentUrl.attachmentId;
        return urlWithExpiration;
      });
      dispatch({
        type: actions.GOT_ATTACHMENT_URLS,
        attachmentUrls,
        attachmentIds
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_ATTACHMENT_URLS_FAILED, attachmentIds });
      handlePromiseError(
        response,
        "TODO: Getting the attachment url failed.  Please refresh your page.",
        "attachment urls"
      );
    });
};

const fetchAttachmentsZip = (attachmentIds, fileName) => (dispatch, getState) => {
  dispatch({ type: actions.GETTING_ATTACHMENTS_ZIP });
  return data
    .post("v1/api/ledger/attachments/download", attachmentIds, {
      responseType: "arraybuffer",
      headers: { Accept: "application/zip" }
    })
    .then(response => {
      dispatch({ type: actions.GOT_ATTACHMENTS_ZIP });
      saveBlobAsDownload(response.data, fileName);

      return true;
    })
    .catch(error => {
      dispatch({ type: actions.GET_ATTACHMENTS_ZIP_FAILED });
      return error;
    });
};

const fetchEntryConfig = (ledgerHash, perspectiveId) => (dispatch, getState) => {
  const entryPerspectiveKey = createKey(ledgerHash, perspectiveId);
  if (getState().ledger.isFetchingEntryConfig[entryPerspectiveKey]) {
    return;
  }
  dispatch({ type: actions.FETCHING_ENTRY_CONFIG, ledgerHash, perspectiveId });
  data
    .get(`v1/api/ledger/entry/${ledgerHash}/config/${perspectiveId}`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_ENTRY_CONFIG,
        ledgerHash,
        perspectiveId,
        config: response.data
      });
    })
    .catch(rejected => {
      dispatch({ type: actions.FETCH_ENTRY_CONFIG_FAILED, ledgerHash, perspectiveId });
      handlePromiseError(rejected, Resources.FetchSettingsFailure, "configuration data");
    });
};

const getEntryConfig = (ledgerHash, perspectiveId) => (dispatch, getState) => {
  const state = getState().ledger;
  const entryPerspectiveKey = createKey(ledgerHash, perspectiveId);
  if (state.fetchingEntryConfigFailed[entryPerspectiveKey] === undefined) {
    dispatch(fetchEntryConfig(ledgerHash, perspectiveId));
    return null;
  }
  return state.entryConfigs[entryPerspectiveKey];
};

const updateEntryConfig = (ledgerHash, perspectiveId, config) => (dispatch, getState) => {
  dispatch({ type: actions.UPDATING_ENTRY_CONFIG });
  return data
    .put(`v1/api/ledger/entry/${ledgerHash}/config/${perspectiveId}`, config)
    .then(response => {
      dispatch({
        type: actions.UPDATED_ENTRY_CONFIG,
        ledgerHash,
        perspectiveId,
        config: response.data
      });
      return response;
    })
    .catch(rejected => {
      handlePromiseError(rejected, Resources.SavingSettingsFailure, "configuration data");
      dispatch({ type: actions.UPDATE_ENTRY_CONFIG_FAILED });
    });
};

const getAttachmentUrl = attachmentId => (dispatch, getState) => {
  let state = getState().ledger;
  let urlData = state.attachmentUrls[attachmentId];
  if (isEmpty(urlData)) {
    return;
  }
  if (compareDates(moment(), urlData.expirationDate) >= 0) {
    dispatch({ type: actions.CLEAR_ATTACHMENT_URL, attachmentId });
    return;
  }

  return urlData.url;
};

const getEntries = () => (dispatch, getState) => {
  let ledger = getState().ledger;
  if (!ledger.fromCompanyId || !ledger.toCompanyId || !ledger.resourceName) return [];
  let isAR = ledger.companies[ledger.fromCompanyId] !== undefined;
  let companyId = isAR ? ledger.toCompanyId : ledger.fromCompanyId;
  let company = ledger.companies[isAR ? ledger.fromCompanyId : ledger.toCompanyId];
  let grants = isAR ? company.grantsTo : company.grantsFrom;
  let resources = ((grants[companyId].resources || {})[ledger.resourceName] || {}).items || [];
  return resources;
};

const putReminder = (companyId, reminderLedgerHash, conversationId, recurrenceRule, dueDate) => (
  dispatch,
  getState
) => {
  dispatch({ type: actions.CREATING_ENTRY });
  return data
    .put(`v1/api/ledger/from/${companyId}/on/${reminderLedgerHash}/reminder/set`, {
      conversationId,
      recurrenceRule,
      dueDate
    })
    .then(response => {
      dispatch({ type: actions.CREATED_ENTRY });
      return true;
    })
    .catch(error => {
      dispatch({ type: actions.CREATE_ENTRY_FAILED });
      handlePromiseError(error, "TODO: Setting the reminder failed.", "reminder");
      throw error;
    });
};

const unsetReminder = (companyId, reminderLedgerHash) => (dispatch, getState) => {
  dispatch({ type: actions.CREATING_ENTRY });
  return data
    .put(`v1/api/ledger/from/${companyId}/on/${reminderLedgerHash}/reminder/unset`, {})
    .then(response => {
      dispatch({ type: actions.CREATED_ENTRY });
    })
    .catch(error => {
      dispatch({ type: actions.CREATE_ENTRY_FAILED });
      handlePromiseError(error, "TODO: Setting the reminder failed.", "reminder");
    });
};

const putLedgerActionEntry = (companyId, ledgerHash, resourceName, actionName, content) => (dispatch, getState) => {
  dispatch({ type: actions.CREATING_ENTRY });
  return data
    .put(`v1/api/ledger/from/${companyId}/on/${ledgerHash}/${resourceName}/${actionName}`, content)
    .then(response => {
      dispatch({ type: actions.CREATED_ENTRY });
      return response;
    })
    .catch(error => {
      dispatch({ type: actions.CREATE_ENTRY_FAILED });
      handlePromiseError(
        error,
        "TODO: Adding the ledger entry failed. If this continues, please contact us.",
        "reminder"
      );
      throw error;
    });
};

export const dispatchToProps = dispatch => ({
  getGrantsFrom: companyId => {
    dispatch(getGrantsFrom(companyId));
  },
  getGrantsTo: companyId => {
    dispatch(getGrantsTo(companyId));
  },
  getResourcesFrom: (companyId, resourceName, continuation) => {
    dispatch(getResourcesFrom(companyId, resourceName, continuation));
  },
  getResourcesTo: (companyId, resourceName, continuation) => {
    dispatch(getResourcesTo(companyId, resourceName, continuation));
  },
  selectRelationship: (fromCompanyId, toCompanyId, resourceName) => {
    dispatch({
      type: actions.SELECT_RELATIONSHIP,
      fromCompanyId: fromCompanyId,
      toCompanyId: toCompanyId,
      resourceName: resourceName
    });
  },
  getEntries: () => {
    return dispatch(getEntries());
  },

  fetchCompanyResources: (fromCompanyId, toCompanyId, resourceName, nextLink) => {
    dispatch(fetchCompanyResources(fromCompanyId, toCompanyId, resourceName, nextLink));
  },
  getCompanyResources: (fromCompanyId, toCompanyId, resourceName, nextLink) => {
    return dispatch(getCompanyResources(fromCompanyId, toCompanyId, resourceName, nextLink));
  },

  fetchResources: () => {
    dispatch(fetchResources());
  },
  getResourcesForPerspective: perspectiveId => {
    return dispatch(getResourcesForPerspective(perspectiveId));
  },
  getResource: (key, value) => {
    return dispatch(getResource(key, value));
  },
  fetchResourceConfigSchema: resourceId => {
    dispatch(fetchResourceConfigSchema(resourceId));
  },
  getResourceConfigSchema: resourceId => {
    return dispatch(getResourceConfigSchema(resourceId));
  },

  fetchEntry: (ledgerHash, isDraft) => {
    return dispatch(fetchEntry(ledgerHash, isDraft));
  },
  getEntry: ledgerHash => {
    return dispatch(getEntry(ledgerHash));
  },
  setMaxEntriesLength: maxStorageSize => {
    dispatch({
      type: actions.SET_MAX_ENTRIES_LENGTH,
      maxLength: maxStorageSize
    });
  },

  createDraft: (companyId, resourceName, content) => {
    return dispatch(createDraft(companyId, resourceName, content));
  },
  forwardDraft: (companyId, resourceName, content) => {
    return dispatch(forwardDraft(companyId, resourceName, content));
  },
  saveDraft: (ledgerHash, content) => {
    return dispatch(saveDraft(ledgerHash, content));
  },
  deleteDraft: ledgerHash => {
    dispatch(deleteDraft(ledgerHash));
  },
  commitDraft: ledgerHash => {
    return dispatch(commitDraft(ledgerHash));
  },

  createEntry: (companyId, resourceName, content, headers) => {
    return dispatch(createEntry(companyId, resourceName, content, headers));
  },
  createEntryToContextGroup: (companyId, withContextGroupId, resourceName, content, headers) => {
    return dispatch(createEntryToContextGroup(companyId, withContextGroupId, resourceName, content, headers));
  },

  fetchEntryConfig: (ledgerHash, perspectiveId) => {
    return dispatch(fetchEntryConfig(ledgerHash, perspectiveId));
  },
  getEntryConfig: (ledgerHash, perspectiveId) => {
    return dispatch(getEntryConfig(ledgerHash, perspectiveId));
  },
  updateEntryConfig: (ledgerHash, perspectiveId, config) => {
    return dispatch(updateEntryConfig(ledgerHash, perspectiveId, config));
  },

  addAttachment: (ledgerHash, file) => {
    return dispatch(addAttachment(ledgerHash, file));
  },
  uploadDocument: (companyId, withContextGroupId, file) => {
    return dispatch(uploadDocument(companyId, withContextGroupId, file));
  },
  addDocumentAttachment: (ledgerHash, companyDocumentId) => {
    return dispatch(addDocumentAttachment(ledgerHash, companyDocumentId));
  },
  deleteAttachments: attachmentIds => {
    return dispatch(deleteAttachments(attachmentIds));
  },
  deleteAttachment: attachmentId => {
    return dispatch(deleteAttachment(attachmentId));
  },
  fetchAttachments: ledgerHash => {
    dispatch(fetchAttachments(ledgerHash));
  },
  getAttachments: ledgerHash => {
    return dispatch(getAttachments(ledgerHash));
  },
  setMaxAttachmentsLength: maxStorageSize => {
    dispatch({
      type: actions.SET_MAX_ATTACHMENTS_LENGTH,
      maxLength: maxStorageSize
    });
  },

  fetchAttachmentUrl: attachmentId => {
    dispatch(fetchAttachmentUrl(attachmentId));
  },
  fetchAttachmentUrls: attachmentIds => {
    dispatch(fetchAttachmentUrls(attachmentIds));
  },
  fetchAttachmentsZip: (attachmentIds, fileName) => {
    return dispatch(fetchAttachmentsZip(attachmentIds, fileName));
  },
  getAttachmentUrl: attachmentId => {
    return dispatch(getAttachmentUrl(attachmentId));
  },
  setMaxAttachmentUrlsLength: maxStorageSize => {
    dispatch({
      type: actions.SET_MAX_ATTACHMENT_URLS_LENGTH,
      maxLength: maxStorageSize
    });
  },
  putReminder: (companyId, reminderLedgerHash, conversationId, recurrenceRule) => {
    return dispatch(putReminder(companyId, reminderLedgerHash, conversationId, recurrenceRule));
  },
  unsetReminder: (companyId, reminderLedgerHash) => {
    return dispatch(unsetReminder(companyId, reminderLedgerHash));
  },
  putLedgerActionEntry: (companyId, ledgerHash, resourceName, actionName, content) => {
    return dispatch(putLedgerActionEntry(companyId, ledgerHash, resourceName, actionName, content));
  }
});
