import React, { Component } from "react";
import { connect } from "react-redux";
import Resources from "../lib/resources";
import { Link } from "react-router-dom";
import { dispatchToProps } from "../store/user-actions";
import { isEmpty, getQueryParameters } from "../lib/utils";

import LockstepLogo from "./library/lockstepLogo";

class ResetPassword extends Component {
  state = {
    currentLoginView: "SENDING_RESET_PASSWORD_EMAIL",
    errorMessages: "",
    username: "",
    resetPassword: "",
    confirmResetPassword: ""
  };

  componentDidMount() {
    let resetCode = this.props.match.params.resetCode;
    if (resetCode) {
      const username = getQueryParameters(this.props.location.search, {
        ignoreQueryPrefix: true
      }).email;
      this.setState({
        currentLoginView: "CONFIRMING_RESET_PASSWORD",
        username
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      currentLoginView: "SENDING_RESET_PASSWORD_EMAIL",
      errorMessages: [],
      username: "",
      resetPassword: "",
      confirmResetPassword: ""
    });
  }

  confirmResetPassword = () => {
    const { username, resetPassword, confirmResetPassword } = this.state;
    const resetCode = decodeURIComponent(this.props.match.params.resetCode);

    if (resetPassword.trim() !== confirmResetPassword.trim()) {
      this.setState({ errorMessages: [Resources.PasswordsNeedToMatchError] });
      return;
    } else if (isEmpty(resetPassword.trim())) {
      this.setState({ errorMessages: [Resources.BlankPasswordError] });
    }

    this.props.isPasswordValid(resetPassword).then(() => {
      const {
        userStore: { isPasswordValid, validatePasswordErrors }
      } = this.props;

      if (isPasswordValid) {
        this.props.confirmResetPassword(username, resetPassword, resetCode).then(response => {
          if (response === "PASSWORD_RESET_SUCCESSFUL")
            this.setState({ currentLoginView: "PASSWORD_RESET_SUCCESSFUL" });
          if (response === "PASSWORD_RESET_FAILED") this.setState({ currentLoginView: "PASSWORD_RESET_FAILED" });
        });
      } else if (validatePasswordErrors.length > 0) {
        this.setState({ errorMessages: validatePasswordErrors });
      }
    });
  };

  resetPasswordEmailSent = () => {
    let username = this.state.username;
    if (username !== "") {
      this.props.resetPassword(username).then(response => {
        if (response === "EMAIL_SENT") {
          this.setState({ currentLoginView: "SENT_RESET_PASSWORD_EMAIL" });
        }
      });
    }
  };

  render() {
    const { username, errorMessages, resetPassword, confirmResetPassword, currentLoginView } = this.state;

    const confirmationView = () => {
      return (
        <div>
          <h4 className="fw-500 text-center">{Resources.EnterNewPassword(username)}</h4>
          <div className="flex-column">
            {isEmpty(errorMessages) !== true && (
              <ul className="warning-color-font">
                {errorMessages.map((message, i) => (
                  <li key={i}>{message}</li>
                ))}
              </ul>
            )}
            <label className="mt-3">
              {Resources.NewPassword}
              <input
                type="password"
                value={resetPassword}
                onChange={e => this.setState({ resetPassword: e.target.value })}
                className="w-100 form-control"
              />
            </label>
            <label className="mt-3">
              {Resources.ConfirmNewPassword}
              <input
                type="password"
                value={confirmResetPassword}
                onChange={e => this.setState({ confirmResetPassword: e.target.value })}
                className="w-100 form-control"
              />
            </label>
            <button onClick={this.confirmResetPassword} className="mx-auto mt-3 d-block button-green">
              {Resources.ChangePassword}
            </button>
          </div>
        </div>
      );
    };

    const sendingResetPassword = () => {
      return (
        <div className="flex-column">
          <h3 className="fw-500">{Resources.FindYourAccount}</h3>
          <label>
            {Resources.EmailAddress}
            <input
              name="username"
              type="username"
              className="w-100"
              value={username}
              onChange={e => this.setState({ username: e.target.value })}
            />
          </label>

          <div style={{ alignSelf: "center", marginTop: "20px", marginBottom: "10px" }}>
            <button className="button-green" onClick={this.resetPasswordEmailSent}>
              {Resources.FindAccount}
            </button>
          </div>
        </div>
      );
    };

    const sentResetPassword = () => {
      return (
        <div>
          <h4 className="ml-5 mr-5">{Resources.CheckEmailResetPassword}</h4>

          <div className="flex-center">
            <button onClick={this.resetPasswordEmailSent} className="mr-3 button-green">
              {Resources.ResendLink}
            </button>
            <button
              onClick={() => {
                this.setState({
                  currentLoginView: "SENDING_RESET_PASSWORD_EMAIL"
                });
              }}
              className="warning-color-bg"
            >
              {Resources.TryAnotherEmail}
            </button>
          </div>
        </div>
      );
    };

    const resetSuccessful = () => {
      return (
        <div>
          <h3 className="text-center">{Resources.ResetPasswordSuccess}</h3>
          <Link className="btn w-35 mx-auto mt-3 d-block" style={{ color: "#FFFFFF", fontSize: "20px" }} to="/login">
            {Resources.Login}
          </Link>
        </div>
      );
    };

    const resetFailed = () => {
      return (
        <div>
          <h2 className="text-center">{Resources.LinkExpired}</h2>
          <button
            onClick={() => {
              this.setState({
                currentLoginView: "SENDING_RESET_PASSWORD_EMAIL"
              });
            }}
            className="btn w-35 mx-auto mt-3 d-block"
          >
            {Resources.TryAgain}
          </button>
        </div>
      );
    };

    const getView = () => {
      if (window.testing) return resetFailed();
      if (currentLoginView === "SENDING_RESET_PASSWORD_EMAIL") return sendingResetPassword();
      if (currentLoginView === "SENT_RESET_PASSWORD_EMAIL") return sentResetPassword();
      if (currentLoginView === "CONFIRMING_RESET_PASSWORD") return confirmationView();
      if (currentLoginView === "PASSWORD_RESET_SUCCESSFUL") return resetSuccessful();
      if (currentLoginView === "PASSWORD_RESET_FAILED") return resetFailed();
    };

    return (
      <div className="login-container">
        <LockstepLogo height={57} inverse />
        <div id="login-subcontainer" className="reset-login">
          {getView()}
          <Link to="/login" className="mt-1 clickable blue-font-white-bg">
            {Resources.BackToLogin}
          </Link>
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    registration: store.registration,
    userStore: store.user
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(ResetPassword);
