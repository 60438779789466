import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { connect, useSelector } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { Perspectives } from "../lib/perspectives";
import Resources from "../lib/resources";
import { dispatchToProps as perspectivesDP } from "../store/perspectives-actions";
import { dispatchToProps as modalDP } from "../store/modal-actions";
import { dispatchToProps as generalDP } from "../store/general-actions";
import { dispatchToProps as contextGroupsDP } from "../store/contextGroups-actions";
import TextInput from "./library/textInput";

const getParentPerspectiveLabel = parentPerspectiveKey => {
  switch (parentPerspectiveKey) {
    case Perspectives.AccountsPayable:
      return Resources.AccountsPayable;
    case Perspectives.AccountsReceivable:
      return Resources.AccountsReceivable;
    default:
      throw new Error("unexpected parentPerspectiveKey", parentPerspectiveKey);
  }
};

const getParentPerspectiveId = parentPerspectiveKey => {
  switch (parentPerspectiveKey) {
    case Perspectives.AccountsPayable:
      return "1";
    case Perspectives.AccountsReceivable:
      return "2";
    default:
      throw new Error("unexpected parentPerspectiveKey", parentPerspectiveKey);
  }
};

const AddPerspectiveModal = ({
  parentPerspectiveKey,
  hideModal,
  createCompanyPerspective,
  setSelectedPerspectiveId,
  selectCompany,
  fetchContextGroups
}) => {
  const companyId = useSelector(store => store.accounts.selectedCompany.companyId);
  const showModal = useSelector(store => store.modal.showModal);
  const createdPerspective = useSelector(store => store.perspectives.createdPerspective);
  const isCreatingCompanyPerspective = useSelector(store => store.perspectives.isCreatingCompanyPerspective);

  const history = useHistory();

  const [perspectiveName, setPerspectiveName] = useState("");

  const addPerspective = () =>
    createCompanyPerspective(companyId, {
      perspectiveName: perspectiveName,
      parentPerspectiveKey: getParentPerspectiveId(parentPerspectiveKey)
    });

  useEffect(() => {
    if (createdPerspective !== null) {
      const createdPerspectivePath = generatePath("/v2/:companyId/:perspectiveId/:view", {
        perspectiveId: createdPerspective.perspectiveId,
        companyId: createdPerspective.companyId,
        view: "home"
      });

      hideModal();

      history.push(createdPerspectivePath);

      // This logic was copied from perspectiveSelector component.
      // TODO: selected perspective and company should be recognized based on URL.
      setSelectedPerspectiveId(createdPerspective.perspectiveId);
      selectCompany(createdPerspective.companyId);
      fetchContextGroups(createdPerspective.companyId, createdPerspective.perspectiveId);
    }
  }, [createdPerspective, hideModal, history, setSelectedPerspectiveId, selectCompany, fetchContextGroups]);

  const perspectiveType = getParentPerspectiveLabel(parentPerspectiveKey).toLocaleLowerCase();

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={hideModal}
      className="custom-modal add-perspective-modal"
      overlayClassName="custom-modal-overlay"
    >
      <div className="custom-modal-content">
        <p className="add-perspective-modal__title">{Resources.AddNewWorkspaceOfType(perspectiveType)}</p>
        <div className="mt-4">
          <p className="add-perspective-modal__label">{Resources.WorkspaceName}</p>
          <TextInput
            textValue={perspectiveName}
            inputOnChange={event => setPerspectiveName(event.target.value)}
            onPressEnter={addPerspective}
            className="w-45"
          />
        </div>
        <div className="custom-modal-content add-perspective-modal__buttons">
          <button className="button-secondary-square mr-3" onClick={hideModal}>
            {Resources.Cancel}
          </button>
          <button className="button-primary-square" onClick={addPerspective} disabled={isCreatingCompanyPerspective}>
            {Resources.AddWorkspace}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default connect(null, dispatch => ({
  ...modalDP(dispatch),
  ...perspectivesDP(dispatch),
  ...generalDP(dispatch),
  ...contextGroupsDP(dispatch),
  ...perspectivesDP(dispatch)
}))(AddPerspectiveModal);
