import React, { Component } from "react";

class IconAngleLeft extends Component {
  render() {
    let { className, height,changepaymentview, ...props } = this.props;
    return (
      <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        height={height}
        className={`icon ${className ? className : ""}`}
        onClick={()=>{changepaymentview()}}
        {...props}
      >
        <defs />
        <path d="M15 18l-6-6 6-6" />
      </svg>
    );
  }
}

IconAngleLeft.defaultProps = {
  height: 24
};

export default IconAngleLeft;
