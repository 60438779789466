import React, { Component } from "react";
import { updateSort } from "../../lib/utils";
import IconTableSort from "./icons/iconTableSort";

export default class TableDataSortableHeader extends Component {
  render() {
    return (
      <span
        className={this.props.className ? "table-data-heading-sort " + this.props.className: "table-data-heading-sort"}
        onClick={() =>
          updateSort(this.props.sortKey, this.props.sortBy, this.props.sortDirection, (sortBy, sortDirection) =>
            this.props.updateSort(sortBy, sortDirection)
          )
        }
      >
        <span>{this.props.text}</span>
        <span style={{ marginLeft: "0.3rem" }}>
          <IconTableSort
            height={14}
            topClass={
             this.props.sortKey.toLowerCase() === this.props.sortBy.toLowerCase() && this.props.sortDirection.toLowerCase() === "desc" ? "font-turquoise" : null
            }
            bottomClass={
              this.props.sortKey.toLowerCase() === this.props.sortBy.toLowerCase() && this.props.sortDirection.toLowerCase() === "asc" ? "font-turquoise" : null
            }
          />
        </span>
      </span>
    );
  }
}
