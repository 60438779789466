import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import Resources from "../../../lib/resources";
import { dispatchToProps as netDP } from "../../../store/network-actions";
import { dispatchToProps as modDP } from "../../../store/modal-actions";
import { dispatchToProps as persDP } from "../../../store/perspectives-actions";
import RadioInput from "../../library/radioInput";

const dispatchToProps = dispatch => ({
  ...netDP(dispatch),
  ...modDP(dispatch),
  ...persDP(dispatch)
});

class SetNewDocumentTypeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: 1
    };
  }

  render() {
    const { modalStore } = this.props;
    return (
      <Modal
        isOpen={modalStore.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="primary-contact-archive-modal"
        overlayClassName="custom-modal-overlay"
      >
        <p className="header">{Resources.WhatDocumentTypeShouldWeSetTheseTo}</p>
        <div className="">
          <RadioInput
            inputs={this.props.documentTypes}
            radioName="documentType"
            value={this.state.selectedType}
            onChange={item => {
              this.setState({
                selectedType: item.value
              });
            }}
          />
        </div>
        <div className="button-container">
          <button onClick={() => this.props.hideModal()} className="button-secondary-square">
            {Resources.Cancel}
          </button>
          <button
            onClick={() => {
              let body = {
                fileKeys: this.props.selectedDocuments,
                newType: this.state.selectedType
              };
              this.props.attachments
                ? this.props
                    .updateEmailAttachmentType(this.props.perspectiveId, this.props.companyId, body)
                    .then(response => {
                      this.props.fetchEmailAttachments(
                        this.props.perspectiveId,
                        this.props.companyId,
                        this.props.accountId
                      );
                    })
                : this.props.updateDocumentType(this.props.perspectiveId, this.props.accountId, body).then(response => {
                    this.props.fetchAccountDocuments(this.props.perspectiveId, this.props.accountId);
                  });
              this.props.hideModal();
            }}
            className="button-primary-square"
          >
            {Resources.Save}
          </button>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    networkStore: store.network,
    perspectiveStore: store.perspectives
  };
};

export default connect(storeToProps, dispatchToProps)(SetNewDocumentTypeDialog);
