import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Resources from "../lib/resources";
import { isEmpty, isGuid, includes } from "../lib/utils";

import { withLDConsumer } from "launchdarkly-react-client-sdk";

import Conversation from "./activities/conversation";
import ConversationTable from "./conversationTable";
import CompanyProfile from "./companyProfile/companyProfile";
import CompanySettings from "./companySettings";
import SpamSettings from "./spamSettings";
import DocumentsTable from "./documentsTable";
import StatementsView from "./statementsView";
import ViewContentHeader from "./lockstep/views/viewContentHeader";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as lgrDP } from "../store/ledger-actions";
import { dispatchToProps as convDP } from "../store/conversations-actions";
import { dispatchToProps as genDP } from "../store/general-actions";
import { dispatchToProps as modDP } from "../store/modal-actions";
import { dispatchToProps as congDP } from "../store/contextGroups-actions";
import { dispatchToProps as paDP } from "../store/perspectives-actions";
import { dispatchToProps as connDP } from "../store/connector-actions";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...aaDP(dispatch),
  ...lgrDP(dispatch),
  ...convDP(dispatch),
  ...genDP(dispatch),
  ...modDP(dispatch),
  ...congDP(dispatch),
  ...paDP(dispatch),
  ...connDP(dispatch)
});

class DepartmentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedConversations: null,
      displayedToast: false,
      calledDocs: false
    };
    this.refreshData = this.refreshData.bind(this);
  }

  getRoute(view, selectedContextGroupId) {
    let {
      match,
      match: { params }
    } = this.props;

    let parts = match.url.split("/");
    if (isEmpty(params.selectedItemId) === false) {
      parts.pop();
    }
    if (isEmpty(params.selectedContextGroupId) === false) {
      parts.pop();
    }
    if (isEmpty(params.view) === false) {
      parts.pop();
    }
    parts.push(view);
    if (isEmpty(selectedContextGroupId) === false) {
      parts.push(selectedContextGroupId);
    }
    return parts.join("/");
  }

  componentDidMount() {
    window.addEventListener("refreshData", this.refreshData);
    this.props.ensureConversationStatuses();
    this.tryUpdate();
    this.refreshTimer = setInterval(() => this.refreshData(), 45000);
  }

  componentWillUnmount() {
    window.removeEventListener("refreshData", this.refreshData);
    clearInterval(this.refreshTimer);
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(
    prevProps = {
      match: { params: {} },
      conversations: { gettingDocuments: false }
    }
  ) {
    this.props.ensureConversationStatuses();

    const { params } = this.props.match;
    const prevParams = prevProps.match.params;
    const { conversations } = this.props;

    const perspective = params.perspectiveId;

    // const changedDepartment = params.perspectiveId !== prevParams.perspectiveId;
    // const changedSelectedContextGroup = params.selectedContextGroupId !== prevParams.selectedContextGroupId;
    // const shouldGetConversations =
    //   conversations.gettingConversations[perspective] !== true &&
    //   conversations.fetchedConversations[perspective] !== true;

    if (
      params.selectedContextGroupId !== prevParams.selectedContextGroupId ||
      params.perspectiveId !== prevParams.perspectiveId
    ) {
      this.props.hideToast();
      this.setState({ displayedToast: false });
    }

    const companyPerspectives = this.props.getCompanyPerspectives(params.companyId);
    if (companyPerspectives !== null) {
      if (
        includes(
          companyPerspectives.map(p => p.perspectiveId),
          params.perspectiveId
        ) !== true
      ) {
        window.location = "/";
      }
    }

    // if (
    //   prevProps.conversations.gettingDocuments === true &&
    //   conversations.gettingDocuments === false &&
    //   !isEmpty(conversations.documents.value)
    // ) {
    //   let urls = conversations.documents.value.map(doc => {
    //     return doc.attachmentId;
    //   });
    //   this.props.fetchAttachmentUrls(urls);
    // }

    if (
      this.props.isContextGroupNew(params.selectedContextGroupId, perspective, params.companyId) &&
      !this.state.displayedToast
    ) {
      const selectedContextGroupId = params.selectedContextGroupId;
      const companyName = (this.props.contextGroupsStore.contextGroupsMaps[selectedContextGroupId] || {}).displayName;
      this.props.showToast({
        type: "orange",
        text: "Unknown/New company detected.",
        linkText: "Rename?",
        linkAction: () => {
          this.props.displayModal("rename", {
            contextGroupId: selectedContextGroupId,
            companyName,
            perspective,
            currentCompanyId: params.companyId
          });
        }
      });
      this.setState({ displayedToast: true });
    }

    // if (accounts.hasFetchedAccounts === true && params.view === "activities") {
    //   let update = changedDepartment || changedSelectedContextGroup;
    //   if (update || shouldGetConversations) {
    //     this.props.getConversations(params.companyId, perspective, params.selectedContextGroupId);
    //   }
    // }

    if (
      isEmpty(params.selectedItemId) === true &&
      isEmpty(conversations.conversationId) === false &&
      conversations.conversationId !== "new"
    ) {
      this.props.clearConversation();
    }
  }

  refreshData() {
    this.props.ensureConversationStatuses();
  }

  render() {
    let { user, contextGroupsStore, flags } = this.props;
    let params = this.props.match.params;
    let { companyId } = params;
    if (user.isLoggedIn !== true || this.props.isSelectedCompanyMatch(companyId) === false) {
      return <div />;
    }

    let partners = {};
    let selectedCompany = {};
    const perspectiveId = params.perspectiveId;
    // TODO: we can probably remove all of this now since it was here to populate the ARRAY for companyBrowser
    // just have to make sure the conversations.companies[perspective] is keyed off of companyId...then it IS the partners dictionary
    let companies = [];
    let perspectiveContextGroups = contextGroupsStore.value || [];
    let selectedContextGroup = this.props.contextGroupsStore.contextGroupsMaps[params.selectedContextGroupId] || {};

    const companyPerspective = this.props.getCompanyPerspectiveById(perspectiveId, params.companyId);
    const parentPerspective = (companyPerspective || {}).parentPerspective;

    perspectiveContextGroups.forEach(contextGroup => {
      partners[contextGroup.companyId] = contextGroup;
      companies.push(contextGroup);
      if (contextGroup.companyId === selectedContextGroup.companyId) {
        selectedCompany = contextGroup;
      }
    });

    if (isEmpty(selectedContextGroup)) {
      let setCompanyName = selectedContextGroupId => {
        switch (selectedContextGroupId) {
          case "unassigned":
            return Resources.Unassigned;
          case "mine":
            return Resources.Mine;
          case "assigned":
            return Resources.Assigned;
          case "closed":
            return Resources.Closed;
          case "spam":
            return Resources.Spam;
          case "pending-approval":
            return Resources.OutForApproval;
          case "reminder":
          case "snoozed":
            return Resources.Snoozed;
          case "waiting-for-response":
            return Resources.WaitingResponse;
          default:
            return selectedContextGroupId;
        }
      };
      selectedContextGroup.displayName = setCompanyName(params.selectedContextGroupId);
    }

    let subViews = [
      {
        subView: "activities",
        path: "/v2/:companyId/:perspectiveId/:view(activities)/:subView?",
        display: Resources.Activities.toLocaleUpperCase()
      }
      // {
      //   subView: "documents",
      //   path: "/v2/:companyId/:perspectiveId/:view(documents)/:subView?",
      //   display: Resources.Documents.toLocaleUpperCase()
      // },
    ];

    if (flags.sequencesActivityTab) {
      subViews.push({
        subView: "sequences",
        path: "/v2/:companyId/:perspectiveId/:view(sequences)/:subView?",
        display: Resources.Sequences.toLocaleUpperCase()
      });
    }

    let view = null;
    let header = <ViewContentHeader links={subViews.length <= 1 ? null : subViews} viewParam="view" />;
    const isNewConversation = params.selectedItemId === "new";

    // const registeredConnector = this.props.getRegisteredERPConnectors(params.companyId)[0];

    switch (params.view) {
      case "activities":
        if (isEmpty(params.selectedItemId) === false && params.selectedItemId !== "search") {
          view = (
            <Conversation
              isNew={false}
              conversationId={params.selectedItemId}
              companyId={companyId}
              perspectiveId={perspectiveId}
              perspective={perspectiveId}
              partners={isNewConversation ? {} : partners}
            />
          );
          header = <ViewContentHeader viewParam="view" viewingConversation />;
          break;
        }

        view = <ConversationTable {...this.props} />;
        break;
      case "documents":
        view = <DocumentsTable {...this.props} />;
        break;
      case "statements":
        const props = { ...this.props, parentPerspective };
        if (isGuid(params.selectedContextGroupId)) {
          view = <StatementsView {...props} />;
        } else {
          window.location = `/#${this.getRoute("activities", params.selectedContextGroupId)}`;
        }
        break;
      case "profile":
        if (isGuid(params.selectedContextGroupId)) {
          view = (
            <div className="manage-content max-100">
              <CompanyProfile
                companyId={selectedCompany.companyId}
                companyName={selectedCompany.companyName || Resources.Unknown}
              />
            </div>
          );
        } else {
          window.location = `/#${this.getRoute("activities", params.selectedContextGroupId)}`;
        }
        break;
      case "settings":
        if (isGuid(params.selectedContextGroupId)) {
          view = (
            <div className="manage-content max-100">
              <CompanySettings companyId={selectedCompany.companyId} />
            </div>
          );
        } else if (params.selectedContextGroupId === "spam") {
          view = <SpamSettings companyId={params.companyId} />;
        } else {
          window.location = `/#${this.getRoute("activities", params.selectedContextGroupId)}`;
        }
        break;
      default:
        return <div></div>;
      //<Redirect to="/" />;
    }

    // let iconObject = (
    //   <div id="content-header-user-picker">
    //     <UserPicker
    //       includeCompanyActions
    //       company={selectedContextGroup}
    //       displayUsers={false}
    //       perspective={perspective}
    //     />
    //   </div>
    // );

    // const showStatements = includes(["customers", "vendors"], (parentPerspective || {}).perspectiveName);
    // const showSettings = isGuid(params.selectedContextGroupId) || params.selectedContextGroupId === "spam";

    // const headerLinks = isNewConversation ? null : (
    //   <React.Fragment>
    //     <NavLink className="content-header-link" to={this.getRoute("activities", params.selectedContextGroupId)}>
    //       {Resources.Activities.toLocaleUpperCase()}
    //     </NavLink>
    //     <NavLink className="content-header-link" to={this.getRoute("documents", params.selectedContextGroupId)}>
    //       {Resources.Documents.toLocaleUpperCase()}
    //     </NavLink>

    //     {showSettings && (
    //       <NavLink className="content-header-link" to={this.getRoute("settings", params.selectedContextGroupId)}>
    //         {Resources.Settings.toLocaleUpperCase()}
    //       </NavLink>
    //     )}

    //     {isGuid(params.selectedContextGroupId) && (
    //       <React.Fragment>
    //         {showStatements && (
    //           <NavLink className="content-header-link" to={this.getRoute("statements", params.selectedContextGroupId)}>
    //             {Resources.Statements.toLocaleUpperCase()}
    //           </NavLink>
    //         )}
    //         <NavLink className="content-header-link" to={this.getRoute("profile", params.selectedContextGroupId)}>
    //           {Resources.Profile.toLocaleUpperCase()}
    //         </NavLink>
    //       </React.Fragment>
    //     )}
    //   </React.Fragment>
    // );

    // const headerTitle = isNewConversation
    //   ? params.activityType === "task"
    //     ? Resources.CreateNewTask
    //     : Resources.NewConversation
    //   : selectedContextGroup.displayName;

    if (this.props.noHeader) {
      return view;
    }
    return (
      <React.Fragment>
        {header}
        <div className="view-content">
          {/* {
          <MainContentHeader
          links={headerLinks}
          title={headerTitle || Resources.Unknown}
          iconObject={isGuid(params.selectedContextGroupId) && selectedCompany.isUnownedCompany ? iconObject : null}
          isNewCompany={(selectedContextGroup || {}).isNew}
          />
        } */}

          {view}
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    accounts: store.accounts,
    conversations: store.conversations,
    ledger: store.ledger,
    contextGroupsStore: store.contextGroups,
    perspectivesStore: store.perspectives,
    connectorStore: store.connector
  };
};

export default withLDConsumer()(withRouter(connect(storeToProps, dispatchToProps)(DepartmentView)));
