import React, { Component } from "react";
import Resources from "../lib/resources";
import { connect } from "react-redux";

import { dispatchToProps as compDP } from "../store/company-actions";
import { dispatchToProps as mangDP } from "../store/manage-actions";
import { dispatchToProps as regDP } from "../store/registration-actions";
import { dispatchToProps as genDP } from "../store/general-actions";

import ModalDialog from "./modalDialog";

import { isEmpty, isEmailValid } from "../lib/utils";
import { raiseError } from "../store/error-actions";

const dispatchToProps = dispatch => ({
  ...mangDP(dispatch),
  ...compDP(dispatch),
  ...regDP(dispatch),
  ...genDP(dispatch)
});

class ForwardingEmailSettings extends Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();
    this.setDefaultState = this.setDefaultState.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.emailInput = React.createRef();
  }

  defaultState() {
    return {
      remoteForwardingEmailValue: ((this.props.companyInfo.connectorEmail || {}).remote || {})[
        this.props.perspectiveId
      ],
      isEditingForwardingEmail: false,
      isModalOpen: false,
      emailClient: "gmail",
      copiedToClipboard: false
    };
  }

  setDefaultState() {
    this.setState(this.defaultState());
  }

  componentDidUpdate(prevProps) {
    if (this.props.perspectiveId !== prevProps.perspectiveId) {
      this.setDefaultState();
    }
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      copiedToClipboard: false
    });
  }

  toClipboard(text) {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ copiedToClipboard: true });
    this.props.showToast({
      timeout: 5000,
      text: Resources.CopiedToClipboard,
      type: "blue",
      linkHidden: true
    });
  }

  editForwardingEmail = () => {
    let { fetchCompanyInfo, getCompanyInfo, setEmailForwardingAddress, perspectiveId, companyId } = this.props;
    let { isEditingForwardingEmail, remoteForwardingEmailValue } = this.state;

    if (isEditingForwardingEmail) {
      if (isEmailValid(remoteForwardingEmailValue) !== true) {
        raiseError(Resources.ValidEmailError);
        return;
      }
      setEmailForwardingAddress(companyId, perspectiveId, remoteForwardingEmailValue).then(() => {
        let companyInfo = getCompanyInfo(companyId);
        let {
          connectorEmail: { remote }
        } = companyInfo;
        if (remote !== remoteForwardingEmailValue) fetchCompanyInfo(companyId);
        this.setState({
          isEditingForwardingEmail: false,
          remoteForwardingEmailValue: remote[perspectiveId]
        });
      });
    } else {
      this.setState({ isEditingForwardingEmail: true }, () => {
        this.emailInput.current.focus();
      });
    }
  };

  render() {
    let {
      /* resendEmailVerification,*/ /* companyId ,*/ companyInfo,
      perspectiveId,
      registration: { errorMsgs }
    } = this.props;

    this.props.fetchCompanyInfo(this.props.companyId);

    if (isEmpty(companyInfo)) {
      return null;
    }

    let {
      connectorEmail: { remote, synthetic }
    } = companyInfo;
    let {
      remoteForwardingEmailValue,
      isEditingForwardingEmail,
      isModalOpen,
      emailClient,
      copiedToClipboard
    } = this.state;
    //let perspectiveConnectorEmailData = (find((connectorEmails || []), (item) => { return (item || {}).perspective === perspective }) || {})
    // let emailId = perspectiveConnectorEmailData.companyIntegrationEmailId
    //let isVerified = perspectiveConnectorEmailData.isVerified

    const modalContent = (
      <div className="p-2">
        <ul className="nav nav-tabs my-2">
          <li className="nav-item">
            <div
              onClick={() => this.setState({ emailClient: "gmail" })}
              className={`nav-link clickable${emailClient === "gmail" ? " active " : ""}`}
            >
              {Resources.Gmail}
            </div>
          </li>
          <li className="nav-item">
            <div
              onClick={() => this.setState({ emailClient: "outlook" })}
              className={`nav-link clickable${emailClient === "outlook" ? " active " : ""}`}
            >
              {Resources.Outlook}
            </div>
          </li>
        </ul>

        <div className="p-2">
          {Resources.EmailAddress}: <span>{synthetic[perspectiveId]}</span>
          <button
            className={`ml-2 ${copiedToClipboard ? "dark-gray-bg" : "button-green"}`}
            onClick={e => this.toClipboard(synthetic[perspectiveId])}
          >
            {copiedToClipboard ? Resources.Copied : Resources.CopyToClipboard}
          </button>
          {emailClient === "gmail" && (
            <div
              dangerouslySetInnerHTML={{
                __html: Resources.GmailForwardingSetup(synthetic[perspectiveId])
              }}
            />
          )}
          {emailClient === "outlook" && (
            <div
              dangerouslySetInnerHTML={{
                __html: Resources.OutlookForwardingSetup(synthetic[perspectiveId])
              }}
            />
          )}
        </div>
        <div className="right-aligned">
          <button className="button-cancel" onClick={this.toggleModal}>
            {Resources.Close}
          </button>
        </div>
      </div>
    );

    if (this.props.showOnlySynthetic) {
      return (
        <div className="d-flex flex-column w-100">
          <div className="mt-1">{Resources.ForwardAllEmailsFromInboxToSharedLedger}</div>
          <span
            onClick={() => this.setState({ isModalOpen: true })}
            className="d-inline-block turquoise-link clickable mb-4"
          >
            {Resources.StepByStepInstructions}
          </span>
          <div className="card-attribute">
            <div className="card-attribute-label-no-max fw-500">
              {Resources.WorkspaceAddress} {companyInfo.connectorEmail.synthetic[perspectiveId]}
            </div>
            <div
              className="turquoise-link clickable float-right"
              onClick={() => {
                this.toClipboard(companyInfo.connectorEmail.synthetic[perspectiveId]);
              }}
            >
              {Resources.CopyAddress}
            </div>
          </div>

          <ModalDialog
            footer={null}
            title={null}
            size="lg"
            content={modalContent}
            isOpen={isModalOpen}
            toggle={this.toggleModal}
            bodyClassName="m-0 p-0"
          />
        </div>
      );
    }
    return (
      <React.Fragment>
        <h5>{Resources.EmailSettings}</h5>
        <div className="w-100 d-inline-block">
          <div className="mb-1">{Resources.EmailAddressForWorkspace}</div>
          {isEmpty(errorMsgs) !== true && <div className="warning-color-font">{errorMsgs}</div>}
          <div className="email-address-box">
            {isEditingForwardingEmail ? (
              <input
                ref={this.emailInput}
                autoComplete="off"
                type="text"
                className="edit-email-input"
                value={remoteForwardingEmailValue}
                onChange={e =>
                  this.setState({
                    remoteForwardingEmailValue: e.target.value
                  })
                }
              />
            ) : (
              <span className="readonly-email overflow-ellipsis">{remote[perspectiveId]}</span>
            )}
            <span className="float-right green-link-white-bg fw-700" onClick={this.editForwardingEmail}>
              {isEditingForwardingEmail ? Resources.Set : Resources.Edit}
            </span>
          </div>
        </div>
        <ModalDialog
          footer={null}
          title={null}
          size="lg"
          content={modalContent}
          isOpen={isModalOpen}
          toggle={this.toggleModal}
          bodyClassName="m-0 p-0"
        />
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    companies: store.companies,
    manage: store.manage,
    registration: store.registration
  };
};

export default connect(storeToProps, dispatchToProps)(ForwardingEmailSettings);
