import React, { Component } from "react";
import { connect } from "react-redux";
import { dispatchToProps } from "../store/registration-actions";
import Resources from "../lib/resources";
import Icons from "../lib/icons";
import RegCompanyInfo from "./regCompanyInfo";
import RegUserAccountExists from "./regUserAccountExists";
import RegUserCompanyExists from "./regUserCompanyExists";
import RegGettingVerified from "./regGettingVerified";

class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentComponent: <RegCompanyInfo onClick={this.setRegistrationStage} />,
      step: 1,
      header: "Enter Company Information and Setup Lockstep Password"
    };
  }

  componentDidMount() {
    this.setRegistrationStage();
  }

  redirectToLoginPage = () => {
    window.localStorage.removeItem("is_registering");
    this.props.history.replace("/login");
  };

  setRegistrationStage = () => {
    let currentComponent, header;
    let stage = window.localStorage.getItem("registration-stage");
    switch (stage) {
      case "COMPANY_INFO":
        currentComponent = <RegCompanyInfo setRegStage={this.setRegistrationStage} />;
        header = Resources.RegCompanyInfoHeader;
        break;
      case "GETTING_VERIFIED":
        currentComponent = <RegGettingVerified />;
        header = Resources.RegVerifyEmailHeader;
        break;
      case "USER_ACCOUNT_EXISTS":
        currentComponent = <RegUserAccountExists />;
        header = Resources.RegUserExistsHeader;
        break;
      case "USER_COMPANY_EXISTS":
        currentComponent = <RegUserCompanyExists />;
        header = Resources.RegCompanyExistsHeader;
        break;
      case "LOADING":
        currentComponent = <div className={`w-100 mt-5 text-center fa-10x ${Icons.spinner}`} />;
        break;
      default:
        break;
    }

    this.setState({ currentComponent, header });
  };

  render() {
    return (
      <div className="">
        <div className="main-flood-color-bg mb-3">
          <h3 className="teal-color-font pt-2 pl-2">{Resources.Lockstep}</h3>
          <h2 className="text-center pb-3">{this.state.header}</h2>
        </div>

        {this.state.currentComponent}

        <a
          onClick={this.redirectToLoginPage}
          href={window.location.hash}
          className="mt-5 pt-5 text-center teal-color-font"
        >
          {Resources.RegBackToLogin}
        </a>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    registration: store.registration
  };
};

export default connect(storeToProps, dispatchToProps)(Registration);
