import React, { useEffect, useState } from "react";

import Resources from "../../../lib/resources";
import { isEmpty, htmlToText } from "../../../lib/utils";
import { generatePath, withRouter } from "react-router-dom";
import Attachment from "../../attachment";
import Avatar from "../../library/avatar";
import Dropdown from "../../library/dropdown";
import Iframe from "../../library/Iframe";

function EmailThreadItem({
  emailBody,
  attachments,
  dateText,
  postingUser = {},
  conversationId = {},
  loggedInUser,
  itemType = "email",
  item = {},
  history,
  match
}) {
  const [isExpanded, setIsExpanded] = useState(true);
  const [emailPreviewText, setEmailPreviewText] = useState("");

  useEffect(() => {
    setEmailPreviewText(htmlToText(emailBody).slice(0, 300));
  }, [emailBody]);

  const posterName =
    postingUser.fullName === loggedInUser.contactName
      ? Resources.You
      : (((item.data || {}).from || [])[0] || {}).name || Resources.Unknown;
  let forwardedTask = (item.data || {}).forwardedTask;

  let titleNote = Resources.ConvoStartedByName(posterName === "You" ? posterName.toLocaleLowerCase() : posterName);

  return (
    <div className="conversation-thread-item">
      <Avatar height={34} imgURL={postingUser.profilePictureURL} />
      <div className="conversation-thread-item__body">
        <div className="conversation-thread-item__body__header__container" onClick={() => setIsExpanded(!isExpanded)}>
          {/* Messasge Header */}
          <div className="conversation-thread-item__body__header">
            <div className="flex-align-center">
              <Dropdown
                buttonContent={posterName}
                buttonClassName="conversation-thread-item__body__poster"
                toggleOnHover
              >
                {/* From */}
                <div className="conversation-thread-item__body__poster__menu">
                  <div className="conversation-thread-item__body__poster__menu__name">{posterName}</div>
                  <div className="conversation-thread-item__body__poster__menu__email">
                    {postingUser.emailAddress || (((item.data || {}).from || [])[0] || {}).address}
                  </div>
                </div>
              </Dropdown>
              {itemType === "note" && (
                <span className="badge badge-note" style={{ marginLeft: "0.66rem" }}>
                  {Resources.Note.toLocaleUpperCase()}
                </span>
              )}
            </div>

            <span className="float-right" style={{ textAlign: "right" }}>
              <div className="thread-item-age">{dateText}</div>
            </span>
          </div>

          {/* To */}
          {isExpanded && itemType !== "note" && (
            <div className="conversation-thread-item__body__header__to">
              {Resources.To.toLocaleLowerCase()}{" "}
              {((item.data || {}).to || []).map(recipient => (recipient || {}).address).join(", ")}
            </div>
          )}

          {/* CC */}
          {isExpanded && itemType !== "note" && !isEmpty(item.data.cc) && (
            <div className="conversation-thread-item__body__header__cc">
              {Resources.CC.toLocaleLowerCase()}{" "}
              {((item.data || {}).cc || []).map(recipient => (recipient || {}).address).join(", ")}
            </div>
          )}

          {/* Render Body */}
          <div
            className={`conversation-thread-item__body__content ${
              isExpanded
                ? "conversation-thread-item__body__content--expanded"
                : "conversation-thread-item__body__content--minimized"
            }`}
          >
            <div style={!isExpanded ? { display: "none" } : {}}>
              <Iframe content={emailBody} />
            </div>
            <div className="wrap" style={isExpanded ? { display: "none" } : {}}>
              {emailPreviewText}
            </div>
          </div>
        </div>
        {isEmpty(attachments) === false && (
          <div>
            <br />
            {attachments.map((att, index) => {
              return (
                <Attachment
                  attachment={att}
                  key={`${att.attachmentId}_${index}`}
                  showIcon={true}
                  name={att.fileName}
                  size={att.sizeBytes}
                />
              );
            })}
          </div>
        )}
        {forwardedTask !== conversationId && forwardedTask !== null && itemType !== "note" ? (
          <div className="conversation-thread-forward-badge-container">
            <span className="conversation-thread-item__body__badge conversation-thread-item__body__badge--forward">
              {titleNote}
            </span>
            <span
              className="view-link"
              onClick={() => {
                history.push(
                  generatePath("/v2/:companyId/:perspectiveId/activities/assigned/:conversationId", {
                    companyId: match.params.companyId,
                    perspectiveId: match.params.perspectiveId,
                    selectedContextGroupId: match.params.selectedContextGroupId,
                    conversationId: forwardedTask
                  })
                );
              }}
            >
              {Resources.ShowOriginal}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default withRouter(EmailThreadItem);
