import React, { useState } from "react";
import Resources from "../../../../lib/resources";
import { generatePath, withRouter } from "react-router-dom";
import { dispatchToProps as networkDP } from "../../../../store/network-actions";
import { connect } from "react-redux";
import LoginInformationForm from "../signup/loginInformationForm";
import DoneSVG from "../../../library/svgComponents/doneSVG";
import PaperSVG from "../../../library/svgComponents/paperSVG";
import UserIconSVG from "../../../library/svgComponents/userIconSVG";

import { withLDConsumer } from "launchdarkly-react-client-sdk";

const dispatchToProps = dispatch => ({
  ...networkDP(dispatch)
});

function ConversionPage(props) {
  const myProfileLink = "";
  const [profile, setProfile] = useState({});

  const formOnChange = (field, value) => {
    let newProfile = { ...profile, [field]: value };
    setProfile(newProfile);
  };

  const submitFunction = () => {
    let erps = {
      1: "Acumatica",
      2: "Epicor (E10)",
      3: "Epicor (P21)",
      4: "Microsoft Dynamic Business Central",
      5: "Microsoft Dynamic Great Plains",
      6: "Microsoft Dynamics Navision",
      7: "NetSuite",
      8: "QuickBooks",
      9: "Sage 100",
      10: "Sage 500",
      11: "Sage Intacct",
      12: "SAP BusinessOne",
      13: "SAP R/3",
      14: "Xero",
      15: "Other"
    };

    let postRequest = {
      companyEmail: profile.username,
      userEmail: profile.password,
      erp: erps[profile.departmentId]
    };

    props.submitConversionInfo(postRequest);
    props.history.push(
      generatePath("/v2/profileupdate/success/:mode(ar|ap)/conversion/:accessId", {
        accessId: props.match.params.accessId,
        mode: props.match.params.mode
      })
    );
  };

  return (
    <div className="conversion-page-container">
      <div className="header-container">
        <h2>{Resources.MVPConversionHeader}</h2>
        {props.flags.mvpconversionviewbuttons ? (
          <div className="view-buttons-container">
            <button className="button-secondary-square">{Resources.ViewCompanyNamesProfile()}</button>
            <button className="button-secondary-square">
              <a href={myProfileLink}>{Resources.ViewYourProfile}</a>
            </button>
          </div>
        ) : null}
      </div>
      <div className="body-container">
        <div>
          <LoginInformationForm
            profile={profile}
            displayModePicker
            submitButton
            submitButtonOnClick={submitFunction}
            conversion
            formOnChange={formOnChange}
          />
        </div>
        <div className="bullet-container">
          <div className="bullet-element">
            <PaperSVG />
            <span>{Resources.NeverFillOutAFormAgain}</span>
          </div>
          <div className="bullet-element">
            <UserIconSVG />
            <span>{Resources.SecurelyCollectAndStoreVendorProfiles}</span>
          </div>
          <div className="bullet-element">
            <DoneSVG />
            <span>{Resources.AutomaticallyKeepVendorDataUpToDate}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

const storeToProps = store => {
  return {
    networkStore: store.network
  };
};

export default withLDConsumer()(withRouter(connect(storeToProps, dispatchToProps)(ConversionPage)));
