import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { dispatchToProps as moDP } from "../../../store/modal-actions";
import { dispatchToProps as networkDP } from "../../../store/network-actions";

import Resources from "../../../lib/resources";
import IconClose from "../../library/icons/iconClose";
import Conversation from "../../activities/conversation";
import "react-datepicker/dist/react-datepicker.css";

import Modal from "react-modal";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...networkDP(dispatch)
});

class NewActivityFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFlyout: true
    };

    this.hideFlyout = this.hideFlyout.bind(this);
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  render() {
    const { sendToAccountId, sendToContactId } = this.props;

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={!this.state.showFlyout ? this.props.hideFlyout : null}
        closeTimeoutMS={400}
        contentLabel="Example Modal"
        className="flyout message-flyout"
        overlayClassName="flyout-overlay"
      >
        <React.Fragment>
          <div className="flyout-heading">
            <p>{Resources.SendAMessage}</p>
            <div onClick={this.hideFlyout} className="flyout-heading-close">
              <IconClose />
            </div>
          </div>
          <div className="flyout-content">
            <Conversation
              isNew={true}
              companyId={this.props.companyId}
              perspectiveId={this.props.perspectiveId}
              partners={{}}
              sendToAccountId={sendToAccountId}
              sendToContactId={sendToContactId}
              selectedItemId="new"
              conversationId="new"
              activityType="conversation"
              onSubmitComplete={() => this.hideFlyout()}
              onClosed={() => this.hideFlyout()}
            />
          </div>
        </React.Fragment>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {};
};

export default withRouter(connect(storeToProps, dispatchToProps)(NewActivityFlyout));
