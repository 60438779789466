import React, { Component } from "react";
import Resources from "../lib/resources";
import Icons from "../lib/icons";
import { connect } from "react-redux";
import { dispatchToProps as compDP } from "../store/company-actions";
import { dispatchToProps as mangDP } from "../store/manage-actions";
import { dispatchToProps as regDP } from "../store/registration-actions";
import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as paDP } from "../store/perspectives-actions";
import { dispatchToProps as conDP } from "../store/connector-actions";
import { dispatchToProps as modDP } from "../store/modal-actions";
import { raiseError } from "../store/error-actions";

import { withLDConsumer } from "launchdarkly-react-client-sdk";

import { getPartnerType } from "../lib/perspectives";
import { isEmpty, find, isEmailValid } from "../lib/utils";

import MainLoader from "./mainLoader";
import FlexibleScrollContainer from "./flexibleScrollContainer";
import SMTPEmailSettings from "./SMTPEmailSettings";
import SPFDKIMEmailSettings from "./SPFDKIMEmailSettings";
import MainContentHeader from "./main_content_header/mainContentHeader";
import PerspectiveTabs from "./perspectiveTabs";
import TextInput from "./library/textInput";
import CustomerIcon from "./library/customerIcon";
import VendorIcon from "./library/vendorIcon";
import IncomingSettings from "./incomingSettings";
import Card from "./library/card";
import AutoCompleteDropdown from "./library/autoCompleteDropdown";
import SelectInput from "./library/selectInput";

const dispatchToProps = dispatch => ({
  ...compDP(dispatch),
  ...mangDP(dispatch),
  ...regDP(dispatch),
  ...uaDP(dispatch),
  ...paDP(dispatch),
  ...conDP(dispatch),
  ...modDP(dispatch)
});

class CompanyEmailSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditingWorkspace: false,
      isEditingWorkspaceEmailSettings: false,
      perspective: null,
      perspectiveName: "",
      perspectiveIcon: "",
      parentPerspectiveName: "",
      parentPerspectiveKey: 1,
      radioSelectedEmailType: null,
      selectedEmailTab: "incoming",
      selectedIncomingMailOption: null,
      confirmedChangeFromGmail: false,

      remoteEmail: ""
    };

    this.inputRef = React.createRef();

    this.toggleEditing = this.toggleEditing.bind(this);
    this.createWorkspace = this.createWorkspace.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.saveEmailSettings = this.saveEmailSettings.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isCompanyAdmin() !== true) {
      return;
    }
    let {
      fetchCompanyEmailConfigData,
      getCompanyEmailConfigData,
      fetchCompanyInfo,
      getCompanyInfo,
      companies: { gettingCompanyInfo, fetchingCompanyInfoFailed },
      fetchCompanyEmailConfigType,
      getCompanyEmailConfigType,
      fetchAllEmailConfigTypes,
      getAllEmailConfigTypes,
      company,
      clearManageIsSetData,
      manage: { newCompanyEmailConfigDataIsSet }
    } = this.props;

    let currentEConfigType = (getCompanyEmailConfigType(company.companyId) || {})[
      (this.state.perspective || {}).perspectiveId || "0"
    ];
    let currentEConfigData = find(
      getCompanyEmailConfigData(company.companyId) || [],
      f => f.perspectiveId === (this.state.perspective || {}).perspectiveId
    );

    if (
      this.state.perspective !== undefined &&
      this.state.perspective !== "new" &&
      (currentEConfigType === undefined || currentEConfigData === undefined || newCompanyEmailConfigDataIsSet)
    ) {
      fetchCompanyEmailConfigType(company.companyId);
      fetchCompanyEmailConfigData(company.companyId);

      if (newCompanyEmailConfigDataIsSet) {
        clearManageIsSetData("newCompanyEmailConfigDataIsSet");
      }
    }

    let allEmailConfigTypes = getAllEmailConfigTypes();
    if (isEmpty(allEmailConfigTypes)) {
      fetchAllEmailConfigTypes();
    }

    let companyInfo = getCompanyInfo(company.companyId);
    if (
      isEmpty(companyInfo.connectorEmail.synthetic) &&
      gettingCompanyInfo[company.companyId] !== true &&
      fetchingCompanyInfoFailed[company.companyId] !== true
    ) {
      fetchCompanyInfo(company.companyId);
    }

    if (this.state.perspective === null) {
      const defaultPerspective =
        this.props.getCompanyPerspectiveById(
          this.props.userStore.userConfig.defaultPerspective,
          this.props.company.companyId
        ) || (this.props.getCompanyPerspectives(company.companyId) || [])[0];
      if (!isEmpty(defaultPerspective)) {
        this.setState({
          perspective: defaultPerspective,
          perspectiveName: getPartnerType(defaultPerspective.perspectiveName, true),
          parentPerspectiveName: defaultPerspective.parentPerspective.perspectiveName,
          perspectiveIcon: defaultPerspective.iconName,
          remoteEmail: ((companyInfo.connectorEmail || {}).remote || {})[defaultPerspective.perspectiveId]
        });
      }
    }
    if (
      prevState.perspective !== null &&
      prevState.perspective !== this.state.perspective &&
      this.state.perspective !== null
    ) {
      if (this.state.perspective === "new") {
        this.setState({
          perspectiveName: "",
          parentPerspectiveName: "vendors"
        });
      } else {
        this.setState({
          perspectiveName: getPartnerType(this.state.perspective.perspectiveName, true),
          parentPerspectiveName: this.state.perspective.parentPerspective.perspectiveName,
          perspectiveIcon: this.state.perspective.iconName,
          remoteEmail: ((companyInfo.connectorEmail || {}).remote || {})[this.state.perspective.perspectiveId]
        });
      }
    }

    const { createdPerspective } = this.props.perspectivesStore;
    if (prevProps && createdPerspective !== prevProps.perspectivesStore.createdPerspective) {
      fetchCompanyInfo(company.companyId);
      this.setState({
        perspective: createdPerspective,
        perspectiveName: getPartnerType(createdPerspective.perspectiveName, true),
        parentPerspectiveName: createdPerspective.parentPerspective.perspectiveName,
        perspectiveIcon: createdPerspective.iconName
      });
    }

    if (
      (prevState.isEditingWorkspace === false && this.state.isEditingWorkspace === true) ||
      (prevState.perspective !== "new" && this.state.perspective === "new")
    ) {
      this.inputRef.current && this.inputRef.current.focus();
    }
  }

  clearErrors = () => {
    let {
      clearRegErrorMessages,
      registration: { errorMsgs }
    } = this.props;
    if ((errorMsgs || []).length > 0) {
      clearRegErrorMessages();
    }
  };

  createWorkspace() {
    const payload = {
      iconName: this.state.perspectiveIcon,
      perspectiveName: this.state.perspectiveName,
      parentPerspectiveKey: this.state.parentPerspectiveKey
    };

    this.props.createCompanyPerspective(this.props.company.companyId, payload);
  }

  toggleEditing() {
    if (this.state.isEditingWorkspace === true) {
      const payload = {
        iconName: this.state.perspectiveIcon,
        perspectiveName: this.state.perspectiveName,
        parentPerspectiveKey: this.state.perspective.parentPerspectiveKey,
        perspectiveId: this.state.perspective.perspectiveId
      };
      this.props.createCompanyPerspective(this.props.company.companyId, payload);
    }
    this.setState({ isEditingWorkspace: !this.state.isEditingWorkspace });
  }

  setEmailConfigType = (configType, currentConfigType) => {
    if (configType === currentConfigType) {
      this.setState({ radioSelectedEmailType: configType, confirmedChangeFromGmail: false });
      return;
    }

    let {
      setCompanyEmailConfig,
      getAllEmailConfigTypes,
      company: { companyId }
    } = this.props;
    let allEmailConfigTypes = getAllEmailConfigTypes();
    let selectedConfigTypeId = (
      find(allEmailConfigTypes, eConfigType => {
        return eConfigType.emailConfigurationTypeName === configType;
      }) || {}
    ).emailConfigurationTypeId;

    switch (configType) {
      case "Lockstep":
        this.setState({ radioSelectedEmailType: "Lockstep" }, () => {
          setCompanyEmailConfig(companyId, {
            emailConfigurationTypeId: selectedConfigTypeId,
            perspectiveId: this.state.perspective.perspectiveId,
            configurationData: null
          });
        });

        break;
      case "Gmail":
        this.setState({ radioSelectedEmailType: "Gmail", confirmedChangeFromGmail: false }, () => {
          setCompanyEmailConfig(companyId, {
            emailConfigurationTypeId: selectedConfigTypeId,
            perspectiveId: this.state.perspective.perspectiveId,
            configurationData: null
          });
        });
        break;
      case "DKIM/SPF":
        this.setState({ radioSelectedEmailType: "DKIM/SPF" });
        break;
      case "SMTP":
        this.setState({ radioSelectedEmailType: "SMTP" });
        break;
      default:
    }
  };

  weightedBoolean = (radioBtnBool, configTypeBool) => {
    if (this.state.radioSelectedEmailType === null) {
      return configTypeBool;
    } else {
      return radioBtnBool;
    }
  };

  renderIcon(icon) {
    const isActive = this.state.perspectiveIcon === icon;

    switch (icon) {
      case "vendor-text-icon":
        return <VendorIcon key={icon} className={isActive ? " active-icon" : ""} />;
      case "customer-text-icon":
        return <CustomerIcon key={icon} className={isActive ? " active-icon" : ""} />;
      default:
        return <span key={icon} className={icon + (isActive ? " active-icon" : "")} />;
    }
  }

  renderIconPicker() {
    const icons = [
      Icons.warningTriangle,
      "vendor-text-icon",
      "customer-text-icon",
      Icons.userFriendsSolid,
      Icons.genericCompany,
      Icons.eye
    ];
    const disabled = this.state.isEditingWorkspace !== true && this.state.perspective !== "new";
    return (
      <div className="icon-picker">
        <div className={"icon-picker-container" + (disabled ? " disabled" : "")}>
          {icons.map((icon, i) => {
            return (
              <div
                key={i}
                className="perspective-icon"
                onClick={e => {
                  if (disabled !== true) {
                    this.setState({ perspectiveIcon: icon });
                  }
                }}
              >
                {this.renderIcon(icon)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  collaborationOptions() {
    return [
      { value: 1, displayName: this.getParentPerspectiveDisplayName("vendors") },
      { value: 3, displayName: this.getParentPerspectiveDisplayName("all") },
      { value: 2, displayName: this.getParentPerspectiveDisplayName("customers") },
      { value: 4, displayName: this.getParentPerspectiveDisplayName("none") }
    ];
  }

  getParentPerspectiveDisplayName(parentPerspectiveName) {
    switch (parentPerspectiveName) {
      case "vendors":
        return Resources.Vendors;
      case "customers":
        return Resources.Customers;
      case "all":
        return Resources.VendorsAndCustomers;
      case "none":
        return Resources.OtherCompanyBusiness;
      default:
        return "";
    }
  }

  renderCollaborationDropdown() {
    let collaborationOptions = this.collaborationOptions();
    return (
      <React.Fragment>
        <SelectInput
          value={this.state.parentPerspectiveKey}
          displayValue={
            (find(collaborationOptions, option => option.value === this.state.parentPerspectiveKey) || {}).displayName
          }
          options={collaborationOptions}
          onSelectOption={option => {
            this.setState({
              parentPerspectiveKey: option.value
            });
          }}
          optionDisplayKey={"displayName"}
          width="100%"
        />
      </React.Fragment>
    );
  }

  saveEmailSettings() {
    let {
      fetchCompanyInfo,
      getCompanyInfo,
      setEmailForwardingAddress,
      company: { companyId }
    } = this.props;
    let { remoteEmail, perspective } = this.state;
    if (isEmailValid(remoteEmail) !== true) {
      raiseError(Resources.ValidEmailError);
      return;
    }
    setEmailForwardingAddress(companyId, perspective.perspectiveId, remoteEmail).then(() => {
      let companyInfo = getCompanyInfo(companyId);
      let {
        connectorEmail: { remote }
      } = companyInfo;
      if (remote[perspective.perspectiveId] !== remoteEmail) fetchCompanyInfo(companyId);
      this.setState({
        isEditingWorkspaceEmailSettings: false,
        remoteEmail: remote[perspective.perspectiveId]
      });
    });
  }

  getIncomingEmailOptions() {
    return [
      { type: "imap", displayName: Resources.ImapServer },
      { type: "forwarding", displayName: Resources.EmailForwarding }
    ];
  }

  getIncomingEmailOption() {
    if (isEmpty(this.state.perspective)) {
      return null;
    }
    let companyId = this.props.company.companyId;
    let imapConnector =
      find(this.props.connector.allSupportedConnectors, connector => connector.connectorName === "Email") || {};
    let imapConnectorVersion = this.props.connector.connectorVersionData[imapConnector.connectorId] || {};

    if (isEmpty(imapConnectorVersion)) {
      return null;
    }

    if (isEmpty(this.props.connector.connectorConfigs)) {
      this.props.fetchConnectorConfiguration(companyId, imapConnectorVersion[0].connectorVersionId);
    }

    let imapConnectorVersionId = imapConnectorVersion[0].connectorVersionId;
    let imapConnectorConfigs =
      (this.props.connector.connectorConfigs[imapConnectorVersionId] || {}).connectorConfiguration || [];
    let imapConnectorConfig = find(
      imapConnectorConfigs,
      config => config.perspectiveId === this.state.perspective.perspectiveId
    );

    let incomingOptions = this.getIncomingEmailOptions();
    let activeOption;
    let statusBadge;

    if (imapConnectorConfig) {
      activeOption = find(incomingOptions, option => option.type === "imap");
      return { ...activeOption, config: imapConnectorConfig, statusBadge };
    } else {
      activeOption = find(incomingOptions, option => option.type === "forwarding");
      return { ...activeOption, config: {} };
    }
  }

  getOutgoingEmailOptions() {
    return [
      { value: "Lockstep", displayName: this.getOutgoingEmailOptionDisplayName("Lockstep") },
      { value: "DKIM/SPF", displayName: this.getOutgoingEmailOptionDisplayName("DKIM/SPF") },
      { value: "SMTP", displayName: this.getOutgoingEmailOptionDisplayName("SMTP") }
    ];
  }

  getOutgoingEmailOptionDisplayName(type) {
    switch (type) {
      case "Lockstep":
        return Resources.StandardLockstepDelivery;
      case "Gmail":
        return Resources.ConnectedGmailAccount;
      case "DKIM/SPF":
        return Resources.ImproveDeliveryRatesSPFDKIM;
      case "SMTP":
        return Resources.CustomSMTP;
      default:
        return null;
    }
  }

  render() {
    let perspective = this.state.perspective || {};
    const creatingNewWorkspace = perspective === "new";

    let {
      getCompanyEmailConfigData,
      getCompanyEmailConfigType,
      getCompanyInfo,
      company,
      company: { companyId },
      registration: { isSettingEmailForwardingAddress },
      manage: {
        isGettingCompanyEmailConfigType,
        isGettingCompanyConfigData,
        isSettingCompanyEmailConfigData,
        isCurrentEmailConfigVerified
      }
    } = this.props;
    let companyInfo = getCompanyInfo(companyId);
    let currentEConfigType = ((getCompanyEmailConfigType(companyId) || {})[perspective.perspectiveId || "0"] || {})
      .emailConfigurationTypeName;
    let currentEConfigData = find(
      getCompanyEmailConfigData(companyId) || [],
      f => f.perspectiveId === perspective.perspectiveId
    );
    let isDKIMSPF = currentEConfigType === "DKIM/SPF";
    let isSMTP = currentEConfigType === "SMTP";

    let radioSelectedEmailType = this.state.radioSelectedEmailType;
    let isRadioDKIMSPF = radioSelectedEmailType === "DKIM/SPF";
    let isRadioSMTP = radioSelectedEmailType === "SMTP";

    let workspaceName = getPartnerType((this.state.perspective || {}).perspectiveName, true);

    let isLoading =
      isGettingCompanyConfigData ||
      isGettingCompanyEmailConfigType ||
      isSettingCompanyEmailConfigData ||
      isSettingEmailForwardingAddress;

    const isAdmin = this.props.isCompanyAdmin() === true;

    let mailSettingsContent;
    let remoteEmail = ((companyInfo.connectorEmail || {}).remote || {})[perspective.perspectiveId];
    let incomingEmailOption = this.getIncomingEmailOption() || {};
    let incomingEmailOptions = this.getIncomingEmailOptions();

    let outgoingMailOptions = this.getOutgoingEmailOptions();
    let selectedOutgoingmailOption = find(
      outgoingMailOptions,
      option => option.value === this.state.radioSelectedEmailType
    );
    let currentEConfig = find(outgoingMailOptions, option => option.value === currentEConfigType);

    if (this.state.selectedEmailTab === "incoming") {
      mailSettingsContent = (
        <React.Fragment>
          <IncomingSettings
            companyId={companyId}
            perspectiveId={perspective.perspectiveId}
            perspectiveName={perspective.perspectiveName}
            incomingEmail={incomingEmailOption}
            incomingOptions={incomingEmailOptions}
            onSave={() => {
              this.saveEmailSettings();
            }}
            onCancel={() => {
              this.setState({ isEditingWorkspaceEmailSettings: false });
            }}
          />
        </React.Fragment>
      );
    } else {
      mailSettingsContent = (
        <React.Fragment>
          <div
            className={
              this.state.isEditingWorkspace ? "card-attribute-editing border-none" : "card-attribute border-none"
            }
          >
            <div className="card-attribute-column border-none pt-0">
              <div className="input-label">{Resources.DeliveryPreference}</div>
              <div className="card-attribute-content mb-4">
                <AutoCompleteDropdown
                  width="350px"
                  //id={this.props.conversationsCompanies[perspective].companyId}
                  selected={selectedOutgoingmailOption || currentEConfig}
                  options={outgoingMailOptions}
                  displayKey="displayName"
                  placeholder={Resources.LoadingOptions}
                  handleSelectOption={option => {
                    this.setEmailConfigType(option.value, currentEConfigType);
                  }}
                  icon={Icons.angleDown + " solid"}
                  readOnly
                />
                <button
                  className="button-primary-square display-outgoing-email-test-modal"
                  onClick={() =>
                    this.props.displayModal("testEmailConfigModal", {
                      companyId: companyId,
                      accountingGroupId: this.state.perspective.perspectiveId,
                      accountingGroupName: workspaceName
                    })
                  }
                >
                  {Resources.TestOutgoingEmail}
                </button>
              </div>
              <div className="nav-tabs mb-4 w-100" />
            </div>
          </div>

          <SPFDKIMEmailSettings
            isVerified={isDKIMSPF ? isCurrentEmailConfigVerified : false}
            isVisible={this.weightedBoolean(isRadioDKIMSPF, isDKIMSPF)}
            configData={isDKIMSPF ? currentEConfigData : {}}
            companyId={companyId}
            perspectiveId={this.state.perspective.perspectiveId}
            companyName={company.companyName}
            onSave={() => {
              this.saveEmailSettings();
            }}
            onCancel={() => {
              this.setState({ isEditingWorkspaceEmailSettings: false });
            }}
          />
          <SMTPEmailSettings
            isVerified={isSMTP ? isCurrentEmailConfigVerified : false}
            isVisible={this.weightedBoolean(isRadioSMTP, isSMTP)}
            perspectiveId={this.state.perspective.perspectiveId}
            configData={isSMTP ? currentEConfigData : {}}
            companyId={companyId}
            onSave={() => {
              this.saveEmailSettings();
            }}
            onCancel={() => {
              this.setState({ isEditingWorkspaceEmailSettings: false });
            }}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <MainContentHeader
          hideSearch={true}
          title={Resources.CompanyWorkspaceSettings}
          customContent={
            <PerspectiveTabs
              handleAddWorkspace={() => {
                this.setState(
                  { perspective: "new", isEditingWorkspace: false, isEditingWorkspaceEmailSettings: false },
                  this.clearErrors
                );
              }}
              handleSelect={perspective =>
                this.setState(
                  { perspective, isEditingWorkspace: false, isEditingWorkspaceEmailSettings: false },
                  this.clearErrors
                )
              }
              selectedPerspective={perspective}
            />
          }
        />
        <FlexibleScrollContainer noFooter>
          <div className="manage-content">
            {isLoading || isAdmin !== true ? (
              <MainLoader fullScreen={true} className="mt-5" />
            ) : (
              <div className="mt-3">
                <Card
                  maxWidth={"50em"}
                  title={isEmpty(workspaceName) ? Resources.AddWorkspace : Resources.WorkspaceSettings(workspaceName)}
                  onClickEdit={this.toggleEditing}
                  editing={this.state.isEditingWorkspace || creatingNewWorkspace}
                  onSave={creatingNewWorkspace ? null : this.toggleEditing}
                  onCancel={
                    creatingNewWorkspace
                      ? null
                      : () =>
                          this.setState({
                            isEditingWorkspace: false,
                            perspectiveName: this.state.perspective.perspectiveName,
                            perspectiveIcon: this.state.perspective.iconName
                          })
                  }
                >
                  <div className={this.state.isEditingWorkspace ? "card-attribute-editing" : "card-attribute"}>
                    <div className="card-attribute-label">{Resources.Name.toLocaleUpperCase()}</div>
                    <div className="card-attribute-content">
                      {this.state.isEditingWorkspace || creatingNewWorkspace ? (
                        <TextInput
                          inputRef={this.inputRef}
                          disabled={this.state.isEditingWorkspace !== true && creatingNewWorkspace === false}
                          width="19em"
                          textValue={this.state.perspectiveName}
                          id="perspective-name"
                          className="m-0"
                          inputOnChange={e => {
                            this.setState({ perspectiveName: e.target.value });
                          }}
                        />
                      ) : (
                        this.state.perspectiveName
                      )}
                    </div>
                  </div>
                  <div className={this.state.isEditingWorkspace ? "card-attribute-editing" : "card-attribute"}>
                    <div className="card-attribute-label">{Resources.Icon.toLocaleUpperCase()}</div>
                    <div className="card-attribute-content">
                      {this.state.isEditingWorkspace || creatingNewWorkspace
                        ? this.renderIconPicker()
                        : this.renderIcon(this.state.perspectiveIcon)}
                    </div>
                  </div>
                  <div className={this.state.isEditingWorkspace ? "card-attribute-editing" : "card-attribute"}>
                    <div className="card-attribute-label">{Resources.ForCollaborationWith.toLocaleUpperCase()}</div>
                    <div className="card-attribute-content">
                      {creatingNewWorkspace
                        ? this.renderCollaborationDropdown()
                        : this.getParentPerspectiveDisplayName(this.state.parentPerspectiveName)}
                    </div>
                  </div>{" "}
                  {creatingNewWorkspace ? (
                    <div className="mt-2 d-flex justify-content-end">
                      <button className="button-cancel mr-2" onClick={() => this.setState({ perspective: null })}>
                        {Resources.Cancel}
                      </button>
                      <button className="button-green" onClick={this.createWorkspace}>
                        {Resources.Save}
                      </button>
                    </div>
                  ) : null}
                  {this.props.flags.workspaceArchive ? (
                    this.state.isEditingWorkspace ? (
                      <div className="delete-button-container">
                        <button
                          onClick={() => {
                            this.props.deleteCompanyPerspective(
                              this.props.match.params.companyId,
                              this.state.perspective.perspectiveId
                            );
                            this.toggleEditing();
                          }}
                          className="button-primary-square"
                        >
                          {Resources.Delete}
                        </button>
                      </div>
                    ) : null
                  ) : null}
                </Card>
                {creatingNewWorkspace ? null : (
                  <Card
                    className="mt-4"
                    maxWidth={"50em"}
                    title={Resources.EmailSettings}
                    description={Resources.ConnectEmailAccountToSendAndReceiveMailFromWorkspace}
                    onClickEdit={() => {
                      this.setState({
                        isEditingWorkspaceEmailSettings: true,
                        remoteEmail: remoteEmail,
                        radioSelectedEmailType: null,
                        selectedEmailTab: "incoming"
                      });
                    }}
                    editing={this.state.isEditingWorkspaceEmailSettings}
                    noSave
                  >
                    <div
                      className={
                        this.state.isEditingWorkspaceEmailSettings ? "card-attribute-editing mb-4" : "card-attribute"
                      }
                    >
                      {this.state.isEditingWorkspaceEmailSettings ? (
                        <div>
                          <TextInput
                            label={Resources.EmailAddress.toLocaleUpperCase()}
                            inline
                            labelInline={false}
                            textValue={this.state.remoteEmail}
                            placeholder={Resources.RemoteEmailPlaceholder}
                            className="m-0"
                            inputOnChange={e => {
                              this.setState({ remoteEmail: e.target.value });
                            }}
                          />
                          {((this.state.selectedEmailTab === "incoming" && incomingEmailOption.type !== "imap") ||
                            (this.state.selectedEmailTab === "outgoing" && !isSMTP)) && (
                            <button className="button-primary-square d-inline ml-5" onClick={this.saveEmailSettings}>
                              {Resources.Save}
                            </button>
                          )}
                        </div>
                      ) : (
                        <React.Fragment>
                          <div className="card-attribute-label-long">{Resources.EmailAddress.toLocaleUpperCase()}</div>
                          <div className="card-attribute-content">
                            {remoteEmail || <span className="placeholder">{Resources.RemoteEmailPlaceholder}</span>}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    {this.state.isEditingWorkspaceEmailSettings ? (
                      <React.Fragment>
                        <ul className="email-tabs nav nav-tabs">
                          <li className="email-item first  mx-0">
                            <div
                              onClick={() => this.setState({ selectedEmailTab: "incoming" })}
                              className={
                                (this.state.selectedEmailTab === "incoming" ? "active" : "") +
                                " content-header-link clickable"
                              }
                            >
                              {Resources.IncomingEmail.toLocaleUpperCase()}
                            </div>
                          </li>
                          <li className="email-item first">
                            <div
                              onClick={() => this.setState({ selectedEmailTab: "outgoing" })}
                              className={
                                (this.state.selectedEmailTab === "outgoing" ? "active" : "") +
                                " content-header-link clickable"
                              }
                            >
                              {Resources.OutgoingMail.toLocaleUpperCase()}
                            </div>
                          </li>
                        </ul>
                        <div className="">{mailSettingsContent}</div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className={"card-attribute"}>
                          <div className="card-attribute-label-long">
                            {Resources.IncomingDeliveryPreference.toLocaleUpperCase()}
                          </div>
                          <div className="card-attribute-content">
                            {incomingEmailOption.displayName}
                            <span className="ml-5">{incomingEmailOption.statusBadge}</span>
                          </div>
                        </div>
                        <div className={"card-attribute"}>
                          <div className="card-attribute-label-long">
                            {Resources.OutgoingDeliveryPreference.toLocaleUpperCase()}
                          </div>
                          <div className="card-attribute-content">
                            {this.getOutgoingEmailOptionDisplayName(currentEConfigType)}
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </Card>
                )}
              </div>
            )}
          </div>
        </FlexibleScrollContainer>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    company: store.accounts.selectedCompany,
    manage: store.manage,
    companies: store.companies,
    registration: store.registration,
    fetchedCompanyRoles: store.accounts.fetchedCompanyRoles,
    userStore: store.user,
    perspectivesStore: store.perspectives,
    ledgerResources: store.ledger.resources,
    connector: store.connector,
    allSupportedConnectors: store.connector.allSupportedConnectors
  };
};

export default withLDConsumer()(connect(storeToProps, dispatchToProps)(CompanyEmailSettings));
