import React from "react";

import { dispatchToProps as modDP } from "../../store/modal-actions";
import { dispatchToProps as persDP } from "../../store/perspectives-actions";
import { dispatchToProps as netDP } from "../../store/network-actions";

import Resources from "../../lib/resources";

import { withRouter } from "react-router-dom";
import IconSend from "../library/icons/iconSend";

import { connect } from "react-redux";
import IconAward from "../library/icons/iconAward";
import IconLock from "../library/icons/iconLock";
import IconUnlock from "../library/icons/iconUnlock";
import IconArchive from "../library/icons/iconArchive";
import Tooltip from "../library/tooltip";

const dispatchToProps = dispatch => ({
  ...modDP(dispatch),
  ...persDP(dispatch),
  ...netDP(dispatch)
});

function ContactsHoverButtons(props) {
  const {
    row,
    match,
    perspectiveStore,
    updateContactPrivacy,
    fetchContactList,
    fetchArchivedContactList,
    fetchAccountArchivedContacts,
    fetchAccountContacts
  } = props;
  return (
    <div className="network-table-hover-icon-container">
      <Tooltip
        noHideDelay
        buttonContent={
          <button
            disabled={row.isPrivate}
            className={row.isPrivate ? "hover-icon-disabled" : "button-action-icon"}
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              props.sendContactProfileUpdateRequest([row.entityGlobalKey]).then(response => {
                props.fetchActivityList(match.params.perspectiveId, {
                  top: 100,
                  skip: 0
                });
              });
              props.displayNotification("profileUpdateRequestSentNotification");
            }}
          >
            <IconSend height={20} />
          </button>
        }
        text={Resources.RequestProfileUpdate}
      />
      <Tooltip
        noHideDelay
        buttonContent={
          <button
            className="button-action-icon"
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              if (!row.isPrivate) {
                updateContactPrivacy(
                  match.params.perspectiveId,
                  row.entityGlobalKey,
                  true,
                  perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                ).then(response => {
                  fetchContactList(match.params.perspectiveId);
                  fetchArchivedContactList(match.params.perspectiveId);
                  fetchAccountArchivedContacts(match.params.perspectiveId, match.params.accountId);
                  fetchAccountContacts(match.params.perspectiveId, match.params.accountId);
                });
                props.displayNotification("contactMarkedPrivateNotification");
              }
            }}
          >
            <IconLock height={20} />
          </button>
        }
        text={Resources.MarkAsPrivate}
      />
      <Tooltip
        noHideDelay
        buttonContent={
          <button
            className="button-action-icon"
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              if (row.isPrivate) {
                updateContactPrivacy(
                  match.params.perspectiveId,
                  row.entityGlobalKey,
                  false,
                  perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                ).then(response => {
                  fetchContactList(match.params.perspectiveId);
                  fetchArchivedContactList(match.params.perspectiveId);
                  fetchAccountArchivedContacts(match.params.perspectiveId, match.params.accountId);
                  fetchAccountContacts(match.params.perspectiveId, match.params.accountId);
                });
                props.displayNotification("contactMarkedPrivateNotification");
              }
            }}
          >
            <IconUnlock height={20} />
          </button>
        }
        text={Resources.MarkAsPublic}
      />
      <Tooltip
        noHideDelay
        buttonContent={
          <button
            disabled={row.isArchived}
            className={row.isArchived ? "hover-icon-disabled" : "button-action-icon"}
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();

              props
                .markContactPrimary(
                  match.params.perspectiveId,
                  row.entityGlobalKey,
                  perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                )
                .then(response => {
                  props.fetchContactList(match.params.perspectiveId);
                  props.fetchArchivedContactList(match.params.perspectiveId);
                  props.fetchAccountArchivedContacts(match.params.perspectiveId, match.params.accountId);
                  props.fetchAccountContacts(match.params.perspectiveId, match.params.accountId);
                });
              props.displayNotification("markingAsPrimaryNotification");
            }}
          >
            <IconAward height={20} />
          </button>
        }
        text={Resources.MarkAsPrimary}
      />
      <Tooltip
        noHideDelay
        buttonContent={
          <button
            className="button-action-icon"
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              let mode = perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar";
              props
                .toggleContactArchived(match.params.perspectiveId, row.entityGlobalKey, !row.isArchived, mode)
                .then(response => {
                  props.fetchContactList(match.params.perspectiveId);
                  props.fetchArchivedContactList(match.params.perspectiveId);
                  props.fetchAccountArchivedContacts(match.params.perspectiveId, match.params.accountId);
                  props.fetchAccountContacts(match.params.perspectiveId, match.params.accountId);
                });
              props.displayNotification("updatedContactStatusNotification");
            }}
          >
            <IconArchive height={20} />
          </button>
        }
        text={Resources.Archive}
      />
    </div>
  );
}

const storeToProps = store => {
  return {
    networkStore: store.network,
    perspectiveStore: store.perspectives
  };
};

export default withRouter(
  connect(
    storeToProps,
    dispatchToProps
  )(function(props) {
    return <ContactsHoverButtons {...props} {...this} />;
  })
);
