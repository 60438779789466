import React, { Component } from "react";
import { connect } from "react-redux";

import { dispatchToProps } from "../../store/modal-actions";

import IconCheck from "../library/icons/iconCheck";
import IconAlertCircle from "../library/icons/iconAlertCircle";
import IconSpinner from "../library/icons/iconSpinner";

class ActionNotification extends Component {
  componentDidUpdate(prevProps) {
    const { actionComplete, actionPending, actionFailed } = this.props;
    if (
      (actionComplete === true && prevProps.actionComplete === false) ||
      (actionFailed === true && prevProps.actionFailed === false)
    ) {
      setTimeout(() => this.props.hideNotification(), 5000);
    }

    if (actionComplete === false && actionFailed === false && actionPending === false) {
      this.props.hideNotification();
    }
  }

  render() {
    let {
      actionPending,
      actionComplete,
      actionFailed,
      actionPendingText,
      actionCompleteText,
      actionFailedText
    } = this.props;

    let content;
    if (actionPending) {
      content = (
        <React.Fragment>
          <IconSpinner height={20} className="notification-icon-left" />
          <div>{actionPendingText}</div>
        </React.Fragment>
      );
    }
    if (actionComplete) {
      content = (
        <React.Fragment>
          <IconCheck height={18} className="notification-icon-left"></IconCheck>
          <div>{actionCompleteText}</div>
        </React.Fragment>
      );
    }
    if (actionFailed) {
      content = (
        <React.Fragment>
          <IconAlertCircle height={18} className="notification-icon-left"></IconAlertCircle>
          <div>{actionFailedText}</div>
        </React.Fragment>
      );
    }
    return (
      <div className="notification" onClick={this.props.hideNotification}>
        {content}
      </div>
    );
  }
}

const storeToProps = (store, ownProps) => {
  return {};
};

export default connect(storeToProps, dispatchToProps)(ActionNotification);
