import React, { useState, useEffect, useRef } from "react";
import { usePopper } from "react-popper";
import OutsideClickHandler from "react-outside-click-handler";

import DropdownItem from "./dropdownItem";
import { isEmpty } from "../../lib/utils";

function Dropdown(props) {
  const {
    buttonContent = "",
    children,
    buttonClassName = "",
    buttonWidth = "",
    menuWidth = "",
    matchButtonMenuWidth = false,
    disabled = false,
    menuAlign = "bottom",
    isForm = false,
    menuClassName = "",
    toggleOnHover = false,
    noHideDelay = false,
    delayShow = false,
    buttonName = "",
    onSetVisible
  } = props;

  const referenceRef = useRef(null);
  const popperRef = useRef(null);

  const [visible, setVisible] = useState(false);

  const setVisibleClickHandler = value => {
    if (!toggleOnHover) {
      setVisible(value);
    }

    // If the parent provided a callback for when the drop-down becomes visisble, execute it. We use setTimeout to let any remaining DOM rendering
    // activities complete before trying to manipulate it, otherwise the callback might not have any effect.
    if (onSetVisible && value) {
      setTimeout(() => {
        onSetVisible();
      })
    }
  }

  // When toggleHover is true we want the dropdown to open on mouse enter
  // and the dropdown to close after a delay on mouse exit.
  const [hovered, setHovered] = useState(false);
  const displayTimeout = noHideDelay ? 0 : 110;

  const setVisibleHoverHandler = value => {
    if (!toggleOnHover) return null;
    // We want the visibility to be updated immediately if we are opening the dropdown
    if (value && !delayShow) {
      setVisible(value);
    }
    setHovered(value);
  };
  // This will close the dropdown after dropdown is not hovered anymore after a delay
  useInterval(
    () => {
      setVisible(false);
    },
    toggleOnHover && !hovered ? displayTimeout : null
  );
  // This will open the dropdown on hover after a delay
  useInterval(
    () => {
      setVisible(true);
    },
    toggleOnHover && hovered ? 400 : null
  );

  const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
    placement: menuAlign + "-start",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 6]
        }
      }
    ]
  });

  let containerStyle = {
    ...styles.popper
  };
  if (!isEmpty(menuWidth)) {
    containerStyle.width = menuWidth;
  }
  if (matchButtonMenuWidth) {
    containerStyle.width = buttonWidth || "100%";
  }

  return (
    <React.Fragment>
      <div
        className="dropdown"
        onMouseEnter={() => setVisibleHoverHandler(true)}
        onMouseLeave={() => setVisibleHoverHandler(false)}
      >
        <OutsideClickHandler onOutsideClick={() => setVisibleClickHandler(false)}>
          <button
            className={"dropdown-trigger " + (buttonClassName ? buttonClassName : "")}
            aria-haspopup="true"
            aria-expanded={visible}
            disabled={disabled}
            ref={referenceRef}
            onClick={e => {
              setVisibleClickHandler(!visible);
            }}
            style={{ width: buttonWidth }}
            data-test-id={buttonName}
          >
            {buttonContent}
          </button>
          <span
            ref={popperRef}
            style={containerStyle}
            {...attributes.popper}
            className={`lockstep-dropdown__menu ${
              visible && !isEmpty(children) ? "show" : ""
            } ${menuClassName} breakout`}
            onClick={e =>
              isForm
                ? (e.target.attributes.closedropdownonclick || {}).value
                  ? setVisibleClickHandler(false)
                  : null
                : setVisibleClickHandler(false)
            }
          >
            <OutsideClickHandler
              onOutsideClick={e => {
                e.current && !e.current.contains(referenceRef) && setVisibleClickHandler(false);
              }}
              disabled={isForm || visible === false}
            >
              {children || <span />}
            </OutsideClickHandler>
          </span>
        </OutsideClickHandler>
      </div>
    </React.Fragment>
  );
}

Dropdown.Item = DropdownItem;

export default Dropdown;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
