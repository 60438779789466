import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AutoCompleteInput from "../../library/autoCompleteInput";
import { updateSelectedRows, find, formatDate, isEmpty } from "../../../lib/utils";

import { dispatchToProps as moDP } from "../../../store/modal-actions";
import { dispatchToProps as ledgDP } from "../../../store/ledger-actions";
import { dispatchToProps as statDP } from "../../../store/statements-actions";
import { dispatchToProps as manDP } from "../../../store/manage-actions";
import { dispatchToProps as networkDP } from "../../../store/network-actions";

import Resources from "../../../lib/resources";
import IconClose from "../../library/icons/iconClose";
import TableData from "../../library/tableData";
import "react-datepicker/dist/react-datepicker.css";
// import IconFilter from "../../library/icons/iconFilter";

import Modal from "react-modal";
import RadioInput from "../../library/radioInput";
import { Card } from "reactstrap";
import MainLoader from "../../mainLoader";
import IconLeftArrow from "../../library/icons/iconLeftArrow";
import ContactsTable from "../contactsTable";
import Avatar from "../../library/avatar";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...ledgDP(dispatch),
  ...statDP(dispatch),
  ...manDP(dispatch),
  ...networkDP(dispatch)
});

class ProfileUpdateRequestFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAction: "all",
      tableView: "ACCOUNTS",
      nextPage: false,
      searchTerm: "",
      displayAccountSearchResults: false,

      contactSearchTerm: "",
      displayContactSearchResults: false,

      selectedAccountKeys: [],
      selectedAccountRows: [],

      selectedContactKeys: [],
      selectedContactRows: []
    };

    this.goToNextPage = this.goToNextPage.bind(this);
    this.goToPrevPage = this.goToPrevPage.bind(this);
    this.sendRequests = this.sendRequests.bind(this);
    this.switchTableView = this.switchTableView.bind(this);
  }

  handleSelect(input) {
    input.defaultChecked = true;
  }

  switchTableView(e) {
    if (this.state.tableView === "ACCOUNTS") {
      this.setState({ tableView: "CONTACTS" });
    } else {
      this.setState({ tableView: "ACCOUNTS" });
    }
  }

  componentDidMount() {
    let accountingGroupId = this.props.accountingGroupId;
    this.props.getCommunicationTemplates(this.props.companyId);
    this.props.fetchAccountList(accountingGroupId, this.props.pageRowCount, 0);
    this.props.fetchContactList(accountingGroupId, this.props.pageRowCount, 0);
  }

  goToNextPage() {
    this.setState({ nextPage: true });
  }

  goToPrevPage() {
    this.setState({ nextPage: false });
  }

  renderEmailTemplate() {
    if (this.props.manageStore.gettingCommunicationTemplates) {
      return (
        <div className="flyout-loading-container">
          <MainLoader fullScreen={true} />
        </div>
      );
    } else {
      let template = this.props.manageStore.communicationTemplates.filter(
        i => i.emailTemplateID === "ProfileUpdateRequest"
      );
      return (
        <div className="email-template-container" dangerouslySetInnerHTML={{ __html: template[0].emailBody }}></div>
      );
    }
  }

  sendRequests() {
    let { accountingGroupId } = this.props;

    let accountKeys = [];
    if (this.state.selectedAction === "all") {
      this.props.accountList.value.map(row => accountKeys.push(row.entityGlobalKey));
    } else {
      accountKeys = this.state.selectedAccountKeys;
    }
    let contactKeys = [];
    if (this.state.selectedAction === "all") {
      this.props.contactList.value.map(row => contactKeys.push(row.entityGlobalKey));
    } else {
      contactKeys = this.state.selectedContactKeys;
    }

    const sendRequestsFlyout = Promise.all([
      this.props.sendAccountProfileUpdateRequest(accountKeys),
      this.props.sendContactProfileUpdateRequest(contactKeys)
    ]);
    sendRequestsFlyout.then(responses => {
      this.props.fetchOpenProfileUpdateRequests(accountingGroupId);
      this.props.fetchOpenOnboardingRequests(accountingGroupId);
      this.props.fetchActivityList(accountingGroupId, {
        top: 100,
        skip: 0
      });
    });
    this.props.displayNotification("profileUpdateRequestSentNotification");
    this.props.hideFlyout();
  }

  toggleSelectRowContacts(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedContactKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedContactRows);
    this.setState({ selectedContactKeys: newSelectedKeys, selectedContactRows: newSelectedRows });
  }

  toggleSelectRowAccounts(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedAccountKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedAccountRows);
    this.setState({ selectedAccountKeys: newSelectedKeys, selectedAccountRows: newSelectedRows });
  }

  render() {
    const {
      accountList,
      gettingNetworkAccountList,
      gotNetworkAccountSearchResults,
      contactList,
      gettingNetworkContactList,
      pageRowCount,
      modalStore,
      accountSearchResults,
      gettingNetworkAccountSearchResults,
      accountSearchTerm,
      gettingNetworkContactSearchResults,
      gotNetworkContactSearchResults,
      contactSearchTerm,
      contactSearchResults,
      networkStore,
      perspectiveId,
      selectedPerspectiveType
    } = this.props;
    let profileamount = contactList.totalCount + accountList.totalCount;

    let columns = [
      {
        type: "rowSelect",
        justify: "center",
        header: "",
        content: row => (
          <Avatar
            height={"3.6rem"}
            imgURL={row.profilePictureURL}
            type="user"
            objectName={row.custName || row.vendorName}
          ></Avatar>
        ),
        width: "5.2rem"
      },
      {
        header: Resources.Name.toLocaleUpperCase(),
        content: row => row.displayName || row.custName || row.vendorName,
        width: "27%"
      },
      { header: Resources.EmailAddress.toLocaleUpperCase(), content: row => row.Email || row.emailAddr, width: "25%" },
      { header: Resources.PhoneNumber.toLocaleUpperCase(), content: row => row.phone, width: "25%" },
      {
        header: Resources.LastUpdated.toLocaleUpperCase(),
        content: row => formatDate(row.profileUpdateDate),
        width: "15%"
      }
    ];

    let links = [
      { subView: "accounts", display: Resources.Accounts.toLocaleUpperCase() },
      { subView: "contacts", display: Resources.Contacts.toLocaleUpperCase() }
    ];

    return (
      <Modal
        isOpen={modalStore.showFlyout}
        onRequestClose={this.props.hideFlyout}
        contentLabel="Example Modal"
        className="flyout profile-udpate-request-flyout"
        overlayClassName="flyout-overlay"
      >
        {(!isEmpty(accountList) || !isEmpty(contactList)) &&
        (this.props.networkStore.gotNetworkContactList || this.props.networkStore.gotNetworkAccountList) ? (
          <React.Fragment>
            <div className="flyout-heading">
              <div className="template-flyout-back-button-container">
                {this.state.nextPage ? (
                  <button className="template-flyout-back-button" onClick={this.goToPrevPage}>
                    <IconLeftArrow height={24} />
                  </button>
                ) : null}
                <p>{Resources.RequestProfileUpdate}</p>
              </div>
              <div onClick={this.props.hideFlyout} className="flyout-heading-close">
                <IconClose />
              </div>
            </div>
            <div className="flyout-content">
              {this.state.nextPage === true ? (
                <div>
                  <p className="flyout-p">
                    {Resources.ProfileUpdateRequestFlyoutPreview(
                      this.state.selectedAction === "all"
                        ? profileamount
                        : this.state.selectedAccountRows.length + this.state.selectedContactRows.length
                    )}
                  </p>
                  {this.renderEmailTemplate()}
                  <div className="profile-update-flyout-button-container">
                    <button className="button-primary-square large" onClick={this.sendRequests}>
                      {Resources.SendRequests}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <span
                    className={`portal-input-label ${this.state.focusedField === "expectedDate" ? " focused" : ""}`}
                  >
                    <p className="flyout-p">{Resources.AboutSendRequests}</p>
                    <p className="flyout-p">{Resources.JustStartingOutMessage}</p>
                  </span>
                  <div className={`portal-input-label mt-5 ${this.state.focusedField === "message" ? " focused" : ""}`}>
                    <RadioInput
                      inputs={[
                        { label: "Send to all profiles", value: "all" },
                        { label: "Send to a few profiles", value: "some" }
                      ]}
                      radioName="select-all"
                      className="profile-update-flyout-radios"
                      value={this.state.selectedAction}
                      onChange={item => {
                        this.setState({
                          selectedAction: item.value
                        });
                      }}
                    />
                  </div>
                  {this.state.selectedAction === "all" ? (
                    <p className="profile-update-flyout-all-message">{Resources.ProfileUpdateToAll(profileamount)}</p>
                  ) : (
                    <div>
                      <p className="profile-update-flyout-all-message">{Resources.ProfileUpdateToSome}</p>
                      {links && (
                        <div className="view-content-header-links">
                          {links.map(link => (
                            <button
                              id={link.display}
                              className={
                                this.state.tableView === link.display
                                  ? "content-header-link-active"
                                  : "content-header-link"
                              }
                              onClick={this.switchTableView}
                            >
                              {link.display}
                            </button>
                          ))}
                        </div>
                      )}
                      <div className="divider-div-profile-update-flyout"></div>

                      <Card className="profile-update-flyout-card">
                        <div className="search-div-container">
                          {this.state.tableView === "ACCOUNTS" ? (
                            <AutoCompleteInput
                              loading={gettingNetworkAccountSearchResults}
                              className="auto-complete-input__table-data-search"
                              placeholder={Resources.Search}
                              onChange={e => {
                                const searchTerm = e.target.value;
                                this.setState({ searchTerm });
                                this.props.debouncedFetchAccountSearchResults(this.props.accountingGroupId, {
                                  searchTerm
                                });
                              }}
                              callToActionButton={
                                <div
                                  className="dropdown-item-clean dropdown-call-to-action"
                                  onClick={() => this.setState({ displayAccountSearchResults: true })}
                                >
                                  {Resources.SeeAllResultsFor(accountSearchTerm)}
                                </div>
                              }
                              isShowingSearchResult={this.state.displayAccountSearchResults}
                              handleClearResult={() => {
                                this.setState({ searchTerm: "", displayAccountSearchResults: false });
                              }}
                              text={this.state.searchTerm}
                              noResultsMessage={Resources.NoResultsFound}
                              showNoResultsMessage={gotNetworkAccountSearchResults}
                              maxOptions={7}
                              handleSelectOption={option => {
                                this.setState({
                                  searchTerm:
                                    selectedPerspectiveType === "vendors" ? option.custName : option.vendorName,
                                  displayAccountSearchResults: true
                                });
                                this.props.fetchAccountSearchResults(this.props.accountingGroupId, {
                                  searchTerm:
                                    selectedPerspectiveType === "customers" ? option.custName : option.vendorName
                                });
                              }}
                              renderOption={option =>
                                `${
                                  selectedPerspectiveType === "vendors"
                                    ? option.displayName || option.custName
                                    : option.displayName || option.vendorName
                                }`
                              }
                              options={accountSearchResults.value}
                              width="300px"
                            />
                          ) : (
                            <AutoCompleteInput
                              loading={gettingNetworkContactSearchResults}
                              className="auto-complete-input__table-data-search"
                              placeholder={Resources.Search}
                              onChange={e => {
                                const contactsSearchTerm = e.target.value;
                                this.setState({ contactsSearchTerm });
                                this.props.debouncedFetchContactSearchResults(this.props.accountingGroupId, {
                                  searchTerm: contactsSearchTerm
                                });
                              }}
                              callToActionButton={
                                <div
                                  className="dropdown-item-clean dropdown-call-to-action"
                                  onClick={() => this.setState({ displayContactSearchResults: true })}
                                >
                                  {Resources.SeeAllResultsFor(contactSearchTerm)}
                                </div>
                              }
                              isShowingSearchResult={this.state.displayContactSearchResults}
                              handleClearResult={() => {
                                this.setState({ contactsSearchTerm: "", displayContactSearchResults: false });
                              }}
                              text={this.state.contactsSearchTerm}
                              noResultsMessage={Resources.NoResultsFound}
                              showNoResultsMessage={gotNetworkContactSearchResults}
                              maxOptions={7}
                              handleSelectOption={option => {
                                this.setState({
                                  contactsSearchTerm: option.contactName,
                                  displayContactSearchResults: true
                                });
                                this.props.fetchContactSearchResults(this.props.accountingGroupId, {
                                  searchTerm: option.contactName
                                });
                              }}
                              renderOption={option => `${option.contactName}`}
                              options={contactSearchResults.value}
                              width="300px"
                            />
                          )}
                          <div className="profile-updat-flyout-icon">{/* <IconFilter height={19} /> */}</div>
                        </div>
                        <div className="flyout-table-container">
                          {this.state.tableView === "ACCOUNTS" ? (
                            <TableData
                              name="update-request-flyout__accounts-table"
                              data={
                                this.state.displayAccountSearchResults ? accountSearchResults.value : accountList.value
                              }
                              columns={columns}
                              style={{ maxHeight: "40vh" }}
                              pagination
                              loading={
                                this.state.displayAccountSearchResults
                                  ? gettingNetworkAccountSearchResults
                                  : gettingNetworkAccountList
                              }
                              maxRows={
                                this.state.displayAccountSearchResults ? accountSearchResults.count : accountList.count
                              }
                              // onRowClick={row => {
                              //   this.props.history.push(match.url + "/" + row.entityGlobalKey);
                              // }}
                              onLoadMore={pageToLoad =>
                                this.state.displayAccountSearchResults
                                  ? this.props.fetchAccountSearchResults(this.props.accountingGroupId, {
                                      searchTerm: this.state.searchTerm,
                                      skip: (pageToLoad - 1) * pageRowCount
                                    })
                                  : this.props.fetchAccountList(
                                      this.props.accountingGroupId,
                                      pageRowCount,
                                      (pageToLoad - 1) * pageRowCount
                                    )
                              }
                              localSelectedKeys={this.state.selectedAccountKeys}
                              rowKey="entityGlobalKey"
                              onRowSelectToggle={key =>
                                this.toggleSelectRowAccounts(
                                  key,
                                  (Array.isArray(key) ? [...key] : [key]).map(k =>
                                    find(accountList.value, row => row.entityGlobalKey === k)
                                  )
                                )
                              }
                              rowHeight={"5rem"}
                            />
                          ) : (
                            <ContactsTable
                              name="update-request-flyout__contacts-table"
                              data={
                                this.state.displayContactSearchResults
                                  ? networkStore.contactSearchResults.value
                                  : contactList.value || []
                              }
                              loading={
                                this.state.displayContactSearchResults
                                  ? networkStore.gettingNetworkContactSearchResults
                                  : gettingNetworkContactList
                              }
                              maxRows={
                                this.state.displayContactSearchResults
                                  ? networkStore.contactSearchResults.count
                                  : contactList.count
                              }
                              onLoadMore={pageToLoad =>
                                this.state.displayAccountSearchResults
                                  ? this.props.fetchContactSearchResults(this.props.accountingGroupId, {
                                      searchTerm: this.state.contactsSearchTerm,
                                      skip: (pageToLoad - 1) * pageRowCount
                                    })
                                  : this.props.selectContactListPage(perspectiveId, pageToLoad)
                              }
                              selectedKeys={this.state.selectedContactKeys}
                              rowKey="entityGlobalKey"
                              onRowSelectToggle={key =>
                                this.toggleSelectRowContacts(
                                  key,
                                  (Array.isArray(key) ? [...key] : [key]).map(k =>
                                    find(contactList.value, row => row.entityGlobalKey === k)
                                  )
                                )
                              }
                            />
                          )}
                        </div>
                      </Card>
                    </div>
                  )}
                  <div className="profile-update-flyout-button-container">
                    <button className="button-primary-square large" onClick={this.goToNextPage}>
                      {Resources.Next}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        ) : (
          <MainLoader fullScreen={true} />
        )}
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    manageStore: store.manage,
    contactList: store.network.contactList,
    accountList: store.network.accountList,
    pageRowCount: store.general.pageRowCount,
    networkStore: store.network,
    accountSearchResults: store.network.accountSearchResults,
    gotNetworkAccountSearchResults: store.network.gotNetworkAccountSearchResults,
    gettingNetworkAccountList: store.network.gettingNetworkAccountList,
    gettingNetworkContactList: store.network.gettingNetworkContactList,
    gettingNetworkAccountSearchResults: store.network.gettingNetworkAccountSearchResults,
    accountSearchTerm: store.network.accountSearchTerm,
    gettingNetworkContactSearchResults: store.network.gettingNetworkContactSearchResults,
    gotNetworkContactSearchResults: store.network.gotNetworkContactSearchResults,
    contactSearchTerm: store.network.contactSearchTerm,
    contactSearchResults: store.network.contactSearchResults,
    selectedPerspectiveType: store.perspectives.selectedPerspectiveType
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ProfileUpdateRequestFlyout));
