import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { dispatchToProps as paDP } from "../../../../store/perspectives-actions";
import { dispatchToProps as uaDP } from "../../../../store/user-actions";
import { dispatchToProps as gaDP } from "../../../../store/general-actions";
import { dispatchToProps as accDP } from "../../../../store/accounts-actions";
import MainLoader from "../../../mainLoader";
import Resources from "../../../../lib/resources";
import IconClose from "../../../library/icons/iconClose";
import IconAngleLeft from "../../../library/icons/iconAngleLeft";
import { SettingsViews } from "./settingsControllerFlyout";
import ManageCompanyUsers from "../../../manageCompanyUsers";

const dispatchToProps = dispatch => {
  return {
    ...paDP(dispatch),
    ...uaDP(dispatch),
    ...gaDP(dispatch),
    ...accDP(dispatch)
  };
};

const UserPermissionSettings = ({ hideFlyout, setSettingsView, getUserConfig, fetchCompanyUserRoles, isCompanyAdmin }) => {
  const companyId = useSelector(store => store.accounts.selectedCompany.companyId);
  const gettingCompanyRoles = useSelector(store => store.accounts.gettingCompanyRoles);
  const fetchedUsers = useSelector(store => store.user.fetchedUsers);

  useEffect(() => {
    getUserConfig();
    fetchCompanyUserRoles(companyId);
  }, [getUserConfig, fetchCompanyUserRoles, companyId]);

  const isLoading = gettingCompanyRoles || !fetchedUsers;

  return (
    <React.Fragment>
      {
        isLoading ? (
          <MainLoader fullScreen={true} />
        ) : (
          <div>
            <div className="settings-flyout__header flyout-heading">
              <IconAngleLeft onClick={() => setSettingsView(SettingsViews.SETTINGS)} className="settings-flyout__back" />
              <h2>{Resources.CompanyUsersAndPermissions}</h2>
              <div onClick={hideFlyout} className="flyout-heading-close">
                <IconClose />
              </div>
            </div>

            <ManageCompanyUsers companyId={companyId}/>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default withRouter(connect(null, dispatchToProps)(UserPermissionSettings));
