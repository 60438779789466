import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import { dispatchToProps as moDP } from "../store/modal-actions";
import { dispatchToProps as maDP } from "../store/manage-actions";
import { raiseError } from "../store/error-actions";

import Icons from "../lib/icons";
import Resources from "../lib/resources";

import FlexibleScrollContainer from "./flexibleScrollContainer";
import Checkbox from "./library/checkbox";
import { removeAt, getFileNameIconClasses } from "../lib/utils";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...maDP(dispatch)
});

class AttachFileModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updatingAttachments: false,
      uploads: Array.from(this.props.uploads) || [],
      addToLibrary: false
    };

    this.updateAttachments = this.updateAttachments.bind(this);
  }

  componentDidMount() {
    document.getElementById("uploadFiles").value = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uploads !== this.props.uploads) {
      this.setState({ uploads: Array.from(this.props.uploads) });
    }
  }

  updateAttachments() {
    const { companyId, perspectiveId } = this.props;
    this.setState({ updatingAttachments: true });
    if (this.state.addToLibrary) {
      const companyDocumentPromises = this.state.uploads.map(upload => {
        return this.props.createCompanyDocument({
          companyId,
          perspectiveId,
          upload
        });
      });

      Promise.all(companyDocumentPromises)
        .then(companyDocuments => {
          this.props.updateAttachments(companyDocuments);
          this.props.hideModal();
        })
        .catch(e => {
          this.setState({ updatingAttachments: false });
          raiseError("Updating attachments failed. Please try again.");
        });
    } else {
      this.props.updateAttachments(this.state.uploads);
      this.props.hideModal();
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modal.showModal}
        onRequestClose={() => this.props.hideModal()}
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="custom-modal-header">{Resources.AttachFile}</div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
          <div className="attach-file-content">
            {this.state.uploads.length > 0 && (
              <FlexibleScrollContainer maxHeight={`${Math.min(60 * this.state.uploads.length, 160) + 20}px`}>
                {this.state.uploads.map((file, i) => {
                  return (
                    <div key={"fileupload_" + i} className="file-upload-item">
                      <span className={getFileNameIconClasses(file.name) + " filetype-icon"} />
                      <span className="attachment-name">{file.name}</span>
                      <span
                        className={Icons.close + " attachment-close"}
                        onClick={() => {
                          this.setState({
                            uploads: removeAt(this.state.uploads, i)
                          });
                        }}
                      />
                    </div>
                  );
                })}
              </FlexibleScrollContainer>
            )}

            {this.state.uploads.length === 0 && (
              <React.Fragment>
                <div className="no-file-selected">{Resources.NoFilesSelected}</div>
              </React.Fragment>
            )}
          </div>
          {this.state.updatingAttachments ? (
            <div className="mt-3">
              <span className={Icons.spinner} />
              <span className="pl-2">{Resources.UploadingAttachments}</span>
            </div>
          ) : (
            <React.Fragment>
              {/* {this.state.uploads.length > 0 && (
                <Checkbox
                  className="add-to-shared-checkbox"
                  label={Resources.AddFilesToSharedDocuments}
                  checked={this.state.addToLibrary}
                  onChange={() => this.setState({ addToLibrary: !this.state.addToLibrary })}
                />
              )} */}
            </React.Fragment>
          )}

          <div className="flex-row modal-buttons">
            <button className="button-cancel float-left mr-1" onClick={this.props.hideModal}>
              {Resources.Cancel}
            </button>
            {this.state.uploads.length > 0 ? (
              <button
                disabled={this.state.updatingAttachments === true}
                className="btn button-green"
                onClick={this.updateAttachments}
              >
                {Resources.Attach}
              </button>
            ) : (
              <button
                onClick={() => {
                  window.document.getElementById("uploadFiles").click();
                }}
                className="button-green"
              >
                {Resources.SelectUploadFile}
              </button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal
  };
};

export default connect(
  storeToProps,
  dispatchToProps
)(AttachFileModal);
