import React, { useEffect } from "react";
import { connect } from "react-redux";

import Modal from "react-modal";
import { ReactTypeformEmbed } from "react-typeform-embed";

import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { dispatchToProps } from "../../store/modal-actions";
import { event as FullStoryEvent } from "react-fullstory";

import IconClose from "../library/icons/iconClose";

const PortalGiveFeedbackModal = ({ modal: modalStore, flags, hideModal, selectedAccountId }) => {
  useEffect(() => {
    if (flags.giveFeedbackSsPortal.enabled === false) {
      hideModal();
    }
  }, [flags, hideModal]);

  return (
    <Modal
      isOpen={modalStore.showModal}
      onRequestClose={() => hideModal()}
      contentLabel="Example Modal"
      className="custom-modal-wide add-vendors-modal"
      overlayClassName="custom-modal-overlay"
    >
      <div onClick={hideModal} className="heading-close button button-action-icon">
        <IconClose height="1.66rem" />
      </div>
      <ReactTypeformEmbed url={flags.giveFeedbackSsPortal.typeformLink + "#accountid=" + selectedAccountId} />
    </Modal>
  );
};

const storeToProps = store => {
  return {
    modal: store.modal,
    selectedAccountId: store.accounts.selectedAccountId
  };
};

export default withLDConsumer()(connect(storeToProps, dispatchToProps)(PortalGiveFeedbackModal));
