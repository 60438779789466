import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Resources from "../../lib/resources";
import { decodeURLQueryString } from "../../lib/utils";

export class PaymentConfirmationMessage extends Component {
  constructor(props) {
    super(props);

    if (!!props.match) {
      window.Intercom("shutdown");
    }
  }

  componentWillUnmount() {
    if (!!this.props.match) {
      window.Intercom("boot");
    }
  }

  render() {
    const transactionId = this.props.transactionId || this.props.match.params.transactionId;
    const isFailure = !!this.props.match && !!this.props.match.params.message;

    return (
      <div className="flex-column flex-align-center customer-portal-payment-confirmation">
        <img
          alt={isFailure ? "An error has occurred" : "Successful payment"}
          src={isFailure ? "/img/error.svg" : "/img/done.svg"}
          className="site-down-image"
        />

        <div className="flyout-heading" style={{ marginTop: "2.4rem", marginBottom: "1rem" }}>
          {isFailure
            ? Resources.PaymentFailureMessage
            : !!transactionId
            ? Resources.PaymentSuccessfulMessage
            : Resources.AddedPaymentMethod}
        </div>
        <div style={{ marginBottom: "3.34rem" }}>
          {isFailure
            ? Resources.PaymentErrorIs(decodeURLQueryString(this.props.match.params.message.replace("0","")))
            : !!transactionId
            ? Resources.YourTransactionIdIs(transactionId)
            : Resources.YourPaymentMethodWasAddedSuccessfully}
        </div>
      </div>
    );
  }
}

export default withRouter(PaymentConfirmationMessage);
