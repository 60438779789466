import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { dispatchToProps as networkDP } from "../../../../store/network-actions";
import { dispatchToProps as modDP } from "../../../../store/modal-actions";
import Modal from "react-modal";
import SettingsNavigator from "./settingsNavigator";
import WorkspacePreferences from "./workspacePreferences";
import SummaryEmailPreferences from "./summaryEmailPreferences";
import EmailSettings from "./emailSettings/emailSettings";
import Templates from "./templates";
import SignInSecurity from "./signInSecurity";
import UserPermissionSettings from "./userPermissionSettings";

export const SettingsViews = {
  SETTINGS: "settings",
  SIGN_IN_SECURITY: "sign_in_security",
  WORKSPACE_PREFERENCES: "workspace_preferences",
  SUMMARY_EMAIL_PREFERENCES: "summary_email_preferences",
  EMAIL_SETTINGS: "email_settings",
  TEMPLATES: "templates",
  ACCOUNTING_SOFTWARE_SETTINGS: "accounting_software_settings",
  USER_PERMISSION_SETTINGS: "user_permission_settings"
};

const dispatchToProps = dispatch => ({
  ...networkDP(dispatch),
  ...modDP(dispatch)
});

const Settings = ({ hideFlyout, flags }) => {
  const [showFlyout, setShowFlyout] = useState(true);
  const [settingsView, setSettingsView] = useState("settings");

  const requestClose = () => setShowFlyout(false);

  const renderSettingsView = view => {
    switch (view) {
      case SettingsViews.SETTINGS:
        return <SettingsNavigator setSettingsView={setSettingsView} hideFlyout={hideFlyout} />;
      case SettingsViews.SIGN_IN_SECURITY:
        return <SignInSecurity setSettingsView={setSettingsView} hideFlyout={hideFlyout} />;
      case SettingsViews.WORKSPACE_PREFERENCES:
        return <WorkspacePreferences setSettingsView={setSettingsView} hideFlyout={hideFlyout} />;
      case SettingsViews.SUMMARY_EMAIL_PREFERENCES:
        return <SummaryEmailPreferences setSettingsView={setSettingsView} hideFlyout={hideFlyout} />;
      case SettingsViews.EMAIL_SETTINGS:
        return <EmailSettings setSettingsView={setSettingsView} hideFlyout={hideFlyout} />;
      case SettingsViews.TEMPLATES:
        return <Templates setSettingsView={setSettingsView} hideFlyout={hideFlyout} />;
      case SettingsViews.USER_PERMISSION_SETTINGS:
        return <UserPermissionSettings setSettingsView={setSettingsView} hideFlyout={hideFlyout} />;
      default:
        throw new Error(`Unexpected view: ${view}`);
    }
  };

  return (
    <Modal
      isOpen={showFlyout}
      onRequestClose={requestClose}
      onAfterClose={!showFlyout ? hideFlyout : null}
      closeTimeoutMS={400}
      contentLabel="Settings flyout"
      className="flyout settings-flyout"
      overlayClassName="flyout-overlay"
    >
      <React.Fragment>{renderSettingsView(settingsView)}</React.Fragment>
    </Modal>
  );
};

const storeToProps = store => {
  return {
    networkStore: store.network,
    modalStore: store.modal
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(Settings));
