import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { withRouter } from "react-router-dom";

import "react-quill/dist/quill.snow.css";
import "../../../css/quill.scss";

import Resources from "../../../lib/resources";
import Icons from "../../../lib/icons";
import ModalDialog from "../../modalDialog";
import EmailTemplatePicker from "../../emailTemplatePicker";
import ReplyTemplateForm from "../../replyTemplateForm";
import Attachment from "../../attachment";
import HtmlEditor from "../../htmlEditor";
import IconUpload from "../../library/icons/iconUpload";
import TextInput from "../../library/textInput";
import ContactEmailSelect from "../contactEmailSelect";

import { removeAt, isEmpty, getConversationSubject, deepCompare, find, onBlurCheckFocusable } from "../../../lib/utils";

import { dispatchToProps as uaDP } from "../../../store/user-actions";
import { dispatchToProps as compDP } from "../../../store/company-actions";
import { dispatchToProps as lgrDP } from "../../../store/ledger-actions";
import { dispatchToProps as convDP } from "../../../store/conversations-actions";
import { dispatchToProps as aaDP } from "../../../store/accounts-actions";
import { handlePromiseError } from "../../../store/error-actions";
import { dispatchToProps as maDP } from "../../../store/manage-actions";
import { dispatchToProps as persDP } from "../../../store/perspectives-actions";
import { dispatchToProps as gaDP } from "../../../store/general-actions";
import { dispatchToProps as modDP } from "../../../store/modal-actions";
import { dispatchToProps as congDP } from "../../../store/contextGroups-actions";
import { searchContacts } from "../../../store/search-actions";
import MainLoader from "../../mainLoader";
import ToggleSwitch from "../../library/toggleSwitch";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

const dispatchToProps = dispatch => ({
  ...aaDP(dispatch),
  ...uaDP(dispatch),
  ...compDP(dispatch),
  ...lgrDP(dispatch),
  ...convDP(dispatch),
  ...persDP(dispatch),
  ...maDP(dispatch),
  ...gaDP(dispatch),
  ...modDP(dispatch),
  ...congDP(dispatch)
});

class ConversationEditorError extends Error {
  constructor(message, title) {
    super(message);
    this.title = title;
    this.name = "ConversationEditorError";
  }
}

class ConversationEditor extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.defaultState };

  }

  defaultState = {
    contacts: [],
    mode: null,
    value: "",
    includeSignature: false,
    showCc: false,
    showBcc: false,
    subject: "",
    to: [],
    cc: [],
    bcc: [],
    // this is a mixed list of File objects and companyDocuments
    attachments: [],
    dropEnabled: false,
    draftId: null,
    existingAttachments: [],
    detachments: [],
    selectedNewChannel: {},
    includeInternal: false,
    partnerEntry: {},
    remapIdentifiersOnMove: false,

    selectedTemplateId: "",
    fetchingTemplateId: null,

    saving: false,
    committing: false,
    commitFailed: false,

    quillConfig: {
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, false] }],
          ["bold", "italic", "underline"],
          ["strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link"]
        ],
        keyboard: {
          bindings: {
            tab: {
              key: 9,
              handler: () => {
                return true;
              }
            }
          }
        }
      },
      readOnly: false
    },
    savingAsTemplate: false
  };

  setMode = (mode) => {
    this.props.updateEditorMode(mode);
    let state = mode === "new" ? { ...this.defaultState, mode } : { mode };
    let newTo = [...(state.to || []), ...this.props.participants.to];
    let newCc = [...(state.cc || [])];
    let newBcc = [...(state.cc || [])];

    switch (mode) {
      case "reply":
        const companyEmail = this.state.companyEmail || { address: "" };
        this.props.conversation.latestEntry.asJson.from.forEach(address => {
          if (find(newTo, i => i.address === address.address)) {
            return null;
          }
          if (companyEmail.address.toLowerCase() !== address.address.toLowerCase()) {
            newTo.push(address);
          }
          return newTo;
        });
        if (isEmpty(newTo)) {
          this.props.conversation.latestEntry.asJson.to.forEach(address => {
            if (find(newTo, i => i.address === address.address)) {
              return null;
            }
            if (companyEmail.address.toLowerCase() !== address.address.toLowerCase()) {
              newTo.push(address);
            }
            return newTo;
          });
        }

        state.to = newTo;
        state.cc = newCc;
        state.bcc = newBcc;
        state.showTo = true;
        state.showCc = !isEmpty(state.cc);
        state.showBcc = !isEmpty(this.state.bcc);
        break;
      case "forward":
        state.to = [];
        state.cc = [];
        state.bcc = [];
        break;
      default:
        state.to = newTo;
        break;
    }

    const isEmail = ["new", "reply", "forward", "forwardCopy", "waitingResponse", "approvalRequest"].includes(mode);

    if (isEmail) {
      state.includeSignature = true;
    } else {
      state.includeSignature = false;
    }

    this.setState(state);
  }

  componentDidMount = () => {
    const perspectiveId = this.props.match.params.perspectiveId || this.props.perspectiveId;
    this.props.clearAllReplyTemplates();
    this.props.fetchCompanyDocuments(this.props.companyId, perspectiveId);
    this.tryUpdate();
  }

  componentDidUpdate = (prevProps, prevState) => {
    this.tryUpdate(prevProps, prevState);
  }

  tryUpdate = (prevProps, prevState) => {
    const { mode } = this.state;
    const prevMode = (prevState || {}).mode;
    let {
      match: { params },
      conversation,
      withCompanies,
      draft,
      companyId
    } = this.props;
    const perspectiveId = this.props.match.params.perspectiveId || this.props.perspectiveId;

    if (prevProps && prevProps.conversation.conversationId !== conversation.conversationId) {
      this.setState({ ...this.defaultState });
    }

    if (!isEmpty(conversation.withCompanyId)) {
      const perspective = this.props.getCompanyPerspectiveById(perspectiveId, params.companyId);

      if (!isEmpty(perspective)) {
        const parentPerspective = perspective.parentPerspective;
        const partnerType = parentPerspective.perspectiveName === "customers" ? "customer" : "vendor";
        const partnerEntry =
          (this.props.getCompanyResources(params.companyId, conversation.withCompanyId, partnerType) || [])[0] || {};

        if (!isEmpty(partnerEntry) && isEmpty(this.state.partnerEntry)) {
          this.setState({ partnerEntry });
        }
        if (!isEmpty(this.state.partnerEntry) && isEmpty(prevState.partnerEntry)) {
          this.props.getEntryConfig(partnerEntry.ledgerHash, perspectiveId);
        }
      }
    }

    if (prevState && mode !== prevMode) {
      this.props.userActivity();
    }

    if (!isEmpty(withCompanies) && (isEmpty(prevProps) || isEmpty(prevProps.withCompanies))) {
      let { companyEmail } = this.props;
      this.setState({
        companyEmail
      });
    }

    if (conversation.new && mode !== "new") {
      this.setMode("new");
    } else if (!conversation.new && mode === "new") {
      this.setMode(null);
    }

    if (!isEmpty(draft) && (isEmpty(this.state.draftId) || this.state.draftId !== draft.addr.address)) {
      let {
        data,
        data: { subject, to, from, cc, bcc, htmlBody }
      } = draft;

      if (!isEmpty(data)) {
        let draftId = draft.addr.address;

        let modeUpdate = "reply";
        let toChanged = false;
        if (deepCompare(to, from) === 0) {
          modeUpdate = "note";
          to = [];
          toChanged = true;
        } else if (isEmpty(data.inReplyTo)) {
          if (isEmpty(data.references)) {
            modeUpdate = "new";
          } else {
            modeUpdate = "forward";
          }
        }

        this.setState({
          draftId,
          mode: modeUpdate,
          subject,
          to,
          toChanged,
          cc,
          ccChanged: !isEmpty(cc),
          bcc,
          showCc: !isEmpty(cc),
          showBcc: !isEmpty(bcc),
          value: htmlBody,
          existingAttachments: [...(draft.attachments || [])]
        });
      }
    } else if (
      isEmpty(draft) &&
      !isEmpty(this.state.draftId) &&
      !this.state.committing &&
      !this.state.commitFailed
    ) {
      this.setState({ ...this.defaultState });
    }

    let contacts = this.props.getCompanyAddressBook(companyId) || [];
    this.updateSubject();
    if (mode !== prevMode) {
      if (mode === "approvalRequest") {
        const to = [];
        const approvers = this.props.getCompanyApprovers(companyId)[perspectiveId] || [];

        if (!isEmpty(this.state.partnerEntry)) {
          const defaultApproverId = (this.props.getEntryConfig(this.state.partnerEntry.ledgerHash, perspectiveId) || {})
            .defaultApprover;
          const defaultApprover = find(approvers, a => {
            return a.companyContactId === defaultApproverId;
          });
          if (!isEmpty(defaultApprover)) {
            to.push({
              name: defaultApprover.fullName,
              address: defaultApprover.email
            });
          }
        }
        contacts = approvers;
        this.setState({
          showTo: true,
          to,
          contacts,
          cc: [],
          showCc: false,
          bcc: [],
          showBcc: false
        });
      } else {
        let { cc } = this.props.participants;
        let newState = { cc, contacts };
        if (this.state.ccChanged) {
          newState.cc = this.state.cc;
        }

        if (mode === "forward" || mode === "forwardCopy" || mode === "waitingResponse") {
          if (this.state.to.length > 0) {
            if (isEmpty(this.state.draftId) || this.state.toChanged) {
              newState.to = [];
            } else if (!this.state.toChanged) {
              newState.to = draft.data.to;
            }
          }
          newState.showTo = true;
          newState.cc = [];
          newState.bcc = [];
          newState.showCc = false;
          newState.showBcc = false;
        }
        this.setState(newState);
      }
    }

    this.props.ensureConversationStatuses();

    if (this.quillRef) {
      let toolbar = $(".quill .ql-toolbar");
      this.setTabIndex(toolbar[0], -1);
    }
  }

  getContacts = request => {
    const companyId = this.props.match.params.companyId || this.props.companyId;
    const perspectiveId = this.props.match.params.perspectiveId || this.props.perspectiveId;

    return searchContacts(this.props.selectedAccountId, companyId, perspectiveId, { request }).then(response =>
      Object.entries(response.data)
        .reduce((acc, [, accountingGroupSearchResults]) => [...acc, ...accountingGroupSearchResults.value], [])
        // TODO: Move filtering out contacts without e-mail addresses to the API
        .filter(c => c.emailAddr)
        .map(c => ({
          address: c.emailAddr,
          label: c.emailAddr,
          profilePictureURL: c.profilePictureURL,
          name: c.displayName || c.contactName
        }))
    );
  };

  setTabIndex = (item, tabIndex) => {
    item.tabIndex = tabIndex;
    if (item.children) {
      for (let i = 0; i < item.children.length; i++) {
        this.setTabIndex(item.children[i], tabIndex);
      }
    }
  }

  toChanged = (to) => {
    this.setState({ to, toChanged: true });
  }

  onClosed = () => {
    let onClosed = this.props.onClosed;
    if (onClosed) onClosed();
  }

  onDraftSaved = (draftId) => {
    this.setState({ draftId });
    let onDraftSaved = this.props.onDraftSaved;
    if (onDraftSaved) onDraftSaved(draftId);
  }

  updateSubject = () => {
    if (!isEmpty(this.state.subject)) return;
    let { conversation } = this.props;
    let subject = getConversationSubject((conversation || {}).subject || "");
    if (!conversation.new) {
      switch (this.state.mode) {
        case "forward":
        case "forwardCopy":
          subject = `${Resources.EmailFw} ${subject}`;
          break;
        case "note":
          subject = `${Resources.EmailAbout} ${subject}`;
          break;
        case "approvalRequest":
          subject = `${Resources.ApprovalRequest}: ${subject}`;
          break;
        case "reply":
        default:
          subject = `${Resources.EmailRe} ${subject}`;
          break;
      }
    }
    if (subject !== this.state.subject) this.setState({ subject });
  }

  toggleCc = () => {
    this.setState({ showCc: !this.state.showCc });
  }

  toggleBcc = () => {
    this.setState({ showBcc: !this.state.showBcc });
  }

  splitEmails = (emails) => {
    if (isEmpty(emails)) return [];
    return emails.split(";").map(email => {
      return { address: email.trim() };
    });
  }

  delete = () => {
    this.setMode(null);
    this.setState({ ...this.defaultState });
    this.onClosed();
  }

  save = (transitionConversationToStatusId) => {
    let result = {};
    let {
      conversation: { conversationId },
      match: { params }
    } = this.props;
    const companyId = params.companyId || this.props.companyId;
    const perspectiveId = this.props.match.params.perspectiveId || this.props.perspectiveId;

    result.inReplyTo = conversationId;
    if (!isEmpty(transitionConversationToStatusId)) {
      result.conversationStatusId = transitionConversationToStatusId;
    }

    result.from = [];
    if (this.state.companyEmail) {
      result.from.push(this.state.companyEmail);
    }

    result.to = this.state.to;
    result.cc = this.state.cc;
    result.bcc = this.state.bcc;

    let error = null;
    let resourceName = "";
    switch (this.props.mode) {
      case "waitingResponse":
        resourceName = "requestResponse";
        break;
      case "forward":
      case "forwardCopy":
      case "new":
        if (this.state.mode === "forward" || this.state.mode === "forwardCopy") {
          result.includeInternal = this.state.includeInternal;
        }
        resourceName = "email";
        delete result["inReplyTo"];
        if (isEmpty(result.to)) {
          error = new ConversationEditorError(
            "TODO: New or Forwarded messages require at least one entry in the TO field.  Please add an email address to the TO field."
          );
        }
        if (this.state.mode === "new") {
          break;
        }
        result.references = [conversationId];
        break;
      case "note":
        resourceName = "note";
        result.to = result.from;
        result.cc = [];
        result.bcc = [];
        break;
      case "reply":
        resourceName = "email";
        // to needs to default to the list emails on the existing conversation
        if (isEmpty(result.to)) {
          result.to = this.props.participants.to || [];
        }
        result.to = [...result.to];
        break;
      case "approvalRequest":
        if (isEmpty(result.to)) {
          error = new ConversationEditorError(
            "TODO: Approval requests require at least one entry in the TO field.  Please add an email address to the TO field."
          );
        }
        resourceName = "approvalrequest";
        break;
      case "approval":
        resourceName = "approval";
        result.to = result.from;
        delete result.cc;
        delete result.bcc;
        break;
      case "rejection":
        resourceName = "rejection";
        result.to = result.from;
        delete result.cc;
        delete result.bcc;
        break;
      default:
        break;
    }

    if (!error) {
      const recipients = [...result.to, ...result.cc, ...result.bcc];
      if (recipients.some(contact => contact.isInvalid)) {
        error = new ConversationEditorError(Resources.SomeAddressesAreInvalid, Resources.InvalidEmail);
      }
    }

    let textBody = this.state.textBody;
    let htmlBody = this.state.value;

    const userSignature = this.props.getCompanyUserSignature(this.props.getMyUserIdFromToken(), companyId);

    if (this.state.includeSignature && !isEmpty(userSignature)) {
      textBody = textBody + "\n\n\n" + userSignature.signatureText;
      htmlBody = htmlBody + "<br/><br/>" + userSignature.signatureHtml;
    }

    result = {
      ...result,
      subject: this.state.subject,
      textBody,
      htmlBody
    };

    this.setState({
      saving: true,
      commitFailed: false,
      quillConfig: { ...this.state.quillConfig, readOnly: true }
    });

    let ok = !error ? Promise.resolve() : Promise.reject(error);

    if (isEmpty(this.state.draftId)) {
      ok = ok.then(() => {
        if (this.props.mode === "forward") {
          this.props.displayNotification("forwardingMessageNotification");
          return this.props.forwardDraft(companyId, "forward", result);
        } else if (this.props.mode === "note") {
          this.props.displayNotification("creatingNoteNotification");
          return this.props.createDraft(companyId, resourceName, result);
        } else {
          this.props.displayNotification("sendingMessageNotification");
          return this.props.createDraft(companyId, resourceName, result);
        }
      });
      ok = ok.then(response => {
        return this.addAttachments(response.data);
      });
    } else {
      ok = ok.then(() => {
        return this.props.saveDraft(this.state.draftId, result);
      });
      ok = ok.then(response => {
        return this.addAttachments(response.data);
      });
      ok = ok.then(draftId => {
        return this.removeAttachments().then(response => {
          return Promise.resolve(draftId);
        });
      });
    }

    ok = ok
      .then(draftId => {
        this.onDraftSaved(draftId);
        if (!this.state.committing) {
          if (!isEmpty(this.props.conversation.conversationId)) {
            this.props.refreshConversation();
            this.setState({ ...this.defaultState });
            this.onClosed();
          } else {
            this.props.fetchContextGroups(companyId, perspectiveId);
            this.props.history.goBack();
          }
        } else {
          this.setState({
            saving: false,
            quillConfig: {
              ...this.state.quillConfig,
              readOnly: this.state.committing
            }
          });
        }

        return Promise.resolve(draftId);
      }, this)
      .catch(rejection => {
        this.setState({
          saving: false,
          quillConfig: {
            ...this.state.quillConfig,
            readOnly: this.state.committing
          }
        });
        if (rejection.name === "ConversationEditorError") {
          throw rejection;
        } else if (this.state.committing) {
          throw new Error("save of draft failed");
        }
      }, this);

    return ok;
  }

  addAttachments = (draftId) => {
    let resolveDraftId = Promise.resolve(draftId);
    if (this.state.attachments.length > 0) {
      return Promise.all([
        ...this.state.attachments.map(a => {
          if (!isEmpty(a.companyDocumentId)) {
            return this.props.addDocumentAttachment(draftId, a.companyDocumentId);
          }
          return this.props.addAttachment(draftId, a);
        })
      ]).then(response => {
        return resolveDraftId;
      });
    } else return resolveDraftId;
  }

  removeAttachments = () => {
    if (this.state.detachments.length > 0)
      return Promise.all(
        this.state.detachments.map(a => {
          return this.props.deleteAttachment(a.attachmentId);
        })
      );
    else return Promise.resolve();
  }

  submit = (e, submitAndClose = false, submitAndAwaitResponse = false) => {
    this.setState(
      {
        saving: true,
        committing: true,
        commitFailed: false,
        quillConfig: { ...this.state.quillConfig, readOnly: true }
      },
      () => {
        let closedStatusId = this.props.getConversationStatusByName("closed").conversationStatusId;
        let waitingResponseStatusId = this.props.getConversationStatusByName("Waiting For Response")
          .conversationStatusId;
        let ok;

        if (submitAndClose) {
          ok = this.save(closedStatusId);
        } else if (submitAndAwaitResponse) {
          ok = this.save(waitingResponseStatusId);
        } else {
          ok = this.save();
        }

        ok = ok.then(draftId => {
          return this.props.commitDraft(draftId);
        }, this);

        ok.then(response => {
          if (!isEmpty(this.props.conversation.conversationId)) {
            if (submitAndClose || submitAndAwaitResponse) {
              this.props
                .refreshConversation()
                .then(response => {
                  this.props.refreshContextGroups(this.props.companyId, this.props.perspectiveId);
                  this.props.refreshConversations(this.props.companyId, this.props.perspectiveId);
                  this.returnToListView();
                })
                .catch(error => {
                  this.returnToListView();
                });
            } else {
              if (this.state.mode === "note") {
                this.setMode(null);
                this.props.refreshConversation();
              } else {
                this.props.refreshConversations(this.props.companyId, this.props.perspectiveId);
                this.returnToListView();
              }
            }
          } else {
            this.props.refreshConversations(this.props.companyId, this.props.perspectiveId);
            this.props.refreshContextGroups(this.props.companyId, this.props.perspectiveId);
            this.props.onSubmitComplete && this.props.onSubmitComplete();
          }
        }, this).catch(rejection => {
          this.setState({
            saving: false,
            committing: false,
            commitFailed: true,
            quillConfig: { ...this.state.quillConfig, readOnly: false }
          });
          this.props.refreshConversation();
          const errorMessage =
            rejection.name === "ConversationEditorError"
              ? rejection.message
              : "TODO: Sending of the response to the conversation has failed.  Please try again.";
          handlePromiseError(rejection, errorMessage);
        }, this);
      }
    );
  }

  updateAttachments = (attachments) => {
    this.setState({ attachments: [...this.state.attachments, ...attachments], dropEnabled: false });
  }

  removeAttachment = (index) => {
    this.setState({ attachments: removeAt(this.state.attachments, index) });
  }

  removeExistingAttachment = (index) => {
    let detachments = [...this.state.detachments, this.state.existingAttachments[index]];
    this.setState({
      existingAttachments: removeAt(this.state.existingAttachments, index),
      detachments
    });
  }

  returnToListView = () => {
    const {
      match: { params },
      location: { pathname }
    } = this.props;

    let previousPath = pathname.slice(0, pathname.indexOf(`/${params.selectedItemId}`));
    this.props.history.push(previousPath);
  }

  showAttachFilesModal = (e) => {
    const perspectiveId = this.props.match.params.perspectiveId || this.props.perspectiveId;

    this.props.displayModal("attachFileModal", {
      companyId: this.props.companyId,
      perspectiveId: perspectiveId,
      updateAttachments: this.updateAttachments,
      uploads: e.target.files
    });
  }

  showAttachFromLibraryModal = () => {
    const perspectiveId = this.props.match.params.perspectiveId || this.props.perspectiveId;

    this.props.displayModal("attachFromLibraryModal", {
      companyId: this.props.companyId,
      perspectiveId: perspectiveId,
      updateAttachments: this.updateAttachments
    });
  }

  getSendButton = () => {
    let { saving, committing } = this.state;
    let { mode } = this.props;
    //let andCloseText;
    let buttonText;

    let disabled = saving || committing;
    let canSend = !isEmpty(this.state.companyEmail);
    let sendDisabled = disabled || !canSend;

    // Handling all the text for edge cases
    switch (mode) {
      case "approval":
        buttonText = Resources.Approve;
        break;
      case "rejection":
        buttonText = Resources.Reject;
        break;
      case "note":
        if (saving || committing) {
          buttonText = Resources.Saving;
        } else {
          buttonText = Resources.Save;
          //andCloseText = Resources.SaveAndClose;
        }
        break;
      default:
        buttonText = Resources.Send;
        //andCloseText = Resources.SendAndClose;
        break;
    }
    switch (mode) {
      case "new":
      case "note":
      case "reply":
        return (
          <button
            className="button-primary-square"
            style={{ minWidth: "14rem" }}
            onClick={e => {
              this.submit(e, false);
            }}
            disabled={sendDisabled}
          >
            {buttonText}
          </button>
        );
      case "forwardCopy":
      case "forward":
        return (
          <span>
            <button
              className="button-primary-square"
              style={{ minWidth: "14rem" }}
              tabIndex="0"
              disabled={sendDisabled}
              onKeyPress={e => {
                if (e.key === "Enter" || e.key === " ") {
                  e.stopPropagation();
                  this.submit();
                }
              }}
              onClick={this.submit}
            >
              {disabled ? <span className={Icons.spinner} /> : buttonText}
            </button>
          </span>
        );
      case "waitingResponse":
      case "approvalRequest":
      case "approval":
      case "rejection":
      default:
        return (
          <button
            className="send-button"
            tabIndex="0"
            disabled={sendDisabled}
            onKeyPress={e => {
              if (e.key === "Enter" || e.key === " ") {
                e.stopPropagation();
                this.submit();
              }
            }}
            onClick={this.submit}
          >
            {disabled ? <span className={Icons.spinner} /> : buttonText}
          </button>
        );
    }
  }

  render() {
    let disabled = this.state.saving || this.state.committing || this.state.fetchingTemplateId;
    let needsEmails = true;

    let { showTo, showCc, showBcc } = this.state;
    const { conversation, perspectiveId, mode } = this.props;
    const companyId = this.props.match.params.companyId || this.props.companyId;

    let editorClass = "";
    let modeLabel = null;

    switch (mode) {
      case "reply":
        showTo = true;
        //editorClass = "gray-left";
        break;
      case "waitingResponse":
        //editorClass = "purple-left";
        modeLabel = Resources.RequestResponse;
        break;
      case "forwardCopy":
        //editorClass = "purple-left";
        modeLabel = Resources.ForwardAsNewConversation;
        break;
      case "forward":
        //editorClass = "purple-left";
        modeLabel = Resources.ForwardActivity;
        showTo = true;
        break;
      case "new":
        showTo = true;
        //showSaveDraft = false;
        break;
      case "approvalRequest":
        //editorClass = "orange-left";
        //showSaveDraft = false;
        //showAddAttachment = true;
        modeLabel = Resources.SendForApproval;
        break;
      case "approval":
        //editorClass = "teal-left";
        //showSaveDraft = false;
        //showAddAttachment = true;
        needsEmails = false;
        modeLabel = Resources.MarkAsApproved;
        break;
      case "rejection":
        //editorClass = "red-left";
        //showSaveDraft = false;
        //showAddAttachment = true;
        needsEmails = false;
        modeLabel = Resources.MarkAsRejected;
        break;
      case "note":
        //editorClass = "yellow-left";
        needsEmails = false;
        break;
      default:
        break;
    }

    //TODO: Remove all this code related to status?
    //let badgeClass = "badge-success";
    let statusId = conversation.conversationStatusId || "65d7d5d6-3615-4710-bce7-7920fd12d0d0";
    let status = this.props.getConversationStatus(statusId) || {};

    switch ((status.conversationStatusName || "").toLowerCase()) {
      case "in progress":
        //badgeClass = "badge-in-progress";
        break;
      case "closed":
        //badgeClass = "badge-closed";
        break;
      case "spam":
        // badgeClass = "badge-spam";
        break;
      case "out for approval":
        // badgeClass = "badge-out-for-approval";
        break;
      case "active":
      default:
        // badgeClass = "badge-active";
        break;
    }

    // let currentUser = this.props.getUser(conversation.assignedUserId);
    const perspective = this.props.getCompanyPerspectiveById(perspectiveId, this.props.companyId);

    let modalDialog =
      !this.state.savingAsTemplate ? null : (
        <ModalDialog
          isOpen={true}
          size="lg"
          className="template-modal"
          title={Resources.SaveAsTemplate}
          content={
            <ReplyTemplateForm
              isUpdating={false}
              templateData={{
                perspectiveEmailTemplateName: this.state.subject,
                subject: this.state.subject,
                htmlBody: this.state.value
              }}
              perspectiveId={perspective.perspectiveId}
              companyId={this.props.companyId}
              closeForm={v => {
                this.setState({ savingAsTemplate: false });
              }}
              toggle={() => {
                this.setState({ savingAsTemplate: false });
              }}
            />
          }
        />
      );

    const templates = this.props.getAllCompanyReplyTemplates(this.props.companyId, perspectiveId);
    return (
      <div className="conversation-editor">
        {modalDialog}
        {isEmpty(mode) && (
          <div className="conversation-editor__mode-buttons">
            <button
              className="button-primary-square"
              style={{ minWidth: "14rem" }}
              onClick={e => this.setMode("reply")}
            >
              {Resources.Reply}
            </button>
            <button
              className="button-secondary-square"
              style={{ minWidth: "14rem" }}
              onClick={e => this.setMode("note")}
            >
              {Resources.AddNote}
            </button>
          </div>
        )}
        {!isEmpty(mode) && (
          <div className={`${editorClass}`}>
            {modeLabel ? (
              <div className="mode-label-header-container">
                <span className="mode-label-header">{modeLabel}</span>
              </div>
            ) : null}
            {needsEmails && (
              <React.Fragment>
                {showTo && (
                  <ContactEmailSelect
                    label={
                      <div className="to-label">
                        <div>{Resources.EmailTo}</div>
                        <div className="to-label__buttons">
                          {!showCc && (
                            <div className="input-label-inline mr-2" onClick={this.toggleCc}>
                              {Resources.EmailCC}
                            </div>
                          )}
                          {!showBcc && (
                            <div className="input-label-inline" onClick={this.toggleBcc}>
                              {Resources.EmailBCC}
                            </div>
                          )}
                        </div>
                      </div>
                    }
                    loadOptions={this.getContacts}
                    onChange={selected => this.setState({ to: selected })}
                    value={this.state.to}
                    isMulti={false}
                  />
                )}
                {showCc && (
                  <ContactEmailSelect
                    label={Resources.EmailCC}
                    loadOptions={this.getContacts}
                    onChange={cc => this.setState({ ccChanged: true, cc })}
                    value={this.state.cc}
                  />
                )}
                {showBcc && (
                  <ContactEmailSelect
                    label={Resources.EmailBCC}
                    loadOptions={this.getContacts}
                    onChange={bcc => this.setState({ bcc })}
                    value={this.state.bcc}
                  />
                )}
              </React.Fragment>
            )}
            {mode === "new" && (
              <TextInput
                className="mt-4"
                textValue={this.state.subject}
                disabled={disabled}
                inputOnChange={e => {
                  this.setState({ subject: e.target.value });
                }}
                label={Resources.Subject}
                labelInline={false}
              ></TextInput>
            )}

            {/* we are letting quill do it's thing and own all the html editing things.  Our custom actions will live in the foot section below*/}
            {/* <Dropzone
                noClick={true}
                onDragEnter={e => {
                  this.setState({ dropEnabled: true });
                }}
                onDragLeave={e => {
                  this.setState({ dropEnabled: false });
                }}
                onDrop={acceptedFiles => this.updateAttachments(Array.from(acceptedFiles))}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="quill-dropzone-container">
                    <ReactQuill
                      ref={el => {
                        this.quillRef = el;
                      }}
                      {...this.state.quillConfig}
                      value={this.state.value}
                      onChange={value => {
                        this.setState({ value });
                      }}
                    />
                    {this.state.dropEnabled && (
                      <div className="quill-dropzone-overlay">
                        <h4>{Resources.DropFilesHere}</h4>
                      </div>
                    )}
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone> */}

            {
              //TODO: Add signature support to html editor
            }
            {this.state.fetchingTemplateId ? (
              <div className="conversation-editor__template-loader">
                <MainLoader />
              </div>
            ) : (
              <React.Fragment>
                <div>
                  <HtmlEditor
                    label={this.props.mode === "note" ? Resources.Note : Resources.Message}
                    focused={this.state.focusedField === "message"}
                    onFocus={() => this.setState({ focusedField: "message" })}
                    onBlur={e => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
                    hideToolbar={this.state.focusedField !== "message"}
                    htmlContent={this.state.value}
                    updateHtmlContent={htmlBody => this.setState({ value: htmlBody })}
                    updateTextContent={textBody => this.setState({ textBody })}
                    signature={
                      (this.props.getCompanyUserSignature(this.props.getMyUserIdFromToken(), companyId) || {})
                        .signatureHtml || ""
                    }
                  />

                  {/* this is the footer toolbar where all of our custom actions will live */}
                  <div className="footer-toolbar">
                    {this.props.flags.teamInboxTemplates && templates && templates.length > 0 && (
                      <EmailTemplatePicker
                        noLabel
                        disabled={disabled}
                        companyId={this.props.companyId}
                        perspective={perspectiveId}
                        templateSelected={(template, templateObject) => {
                          if (mode === "new") {
                            this.setState({ value: templateObject.emailBody, subject: templateObject.subject });
                          } else {
                            this.props
                              .fetchPopulatedTemplate(
                                this.props.companyId,
                                perspectiveId,
                                template,
                                this.props.conversation.conversationId
                              )
                              .then(populatedTemplate => {
                                if (!isEmpty(populatedTemplate)) {
                                  if (populatedTemplate.htmlBodyCompilerOutput.unmappedProperties.length > 0) {
                                    this.props.displayModal("unmappedPropertyModal", {
                                      unmappedProperties: populatedTemplate.htmlBodyCompilerOutput.unmappedProperties
                                    });
                                  }
                                  this.setState({
                                    value: populatedTemplate.emailBody,
                                    selectedTemplateId: template,
                                    fetchingTemplateToApply: false,
                                    fetchingTemplateId: null
                                  });
                                }
                              });
                            this.setState({ fetchingTemplateId: template.emailTemplateID });
                          }
                        }}
                        appliedTemplateId={this.state.selectedTemplateId}
                        fetchingTemplateId={this.state.fetchingTemplateId}
                      />
                    )}

                    {/* <Dropdown
                      disabled={disabled}
                      buttonClassName={"button-secondary"}
                      buttonContent={
                        <React.Fragment>
                          <IconUpload height="16" style={{ marginRight: "0.65rem" }} />
                        </React.Fragment>
                      }
                    >
                      <span className="dropdown-item" onClick={this.showAttachFromLibraryModal}>
                        {Resources.SharedDocuments}
                      </span>
                      <span
                        className="dropdown-item"
                        onClick={() => {
                          window.document.getElementById("uploadFiles").click();
                        }}
                      >
                        {Resources.UploadFromComputer}
                      </span>
                    </Dropdown> */}

                    <button
                      className="button-secondary"
                      onClick={() => {
                        window.document.getElementById("uploadFiles").click();
                      }}
                    >
                      <IconUpload height="20" />
                    </button>

                    {/*
                    // Add templates saving back in later
                    <div className="editor-action">
                      <span
                        className="dropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className={Icons.save} disabled={disabled} />
                        <span className="editor-action-label">{Resources.SaveAs}</span>
                        <span className={Icons.dropdown} style={{ fontSize: ".8em" }} />
                      </span>
                      <span className="dropdown-menu">
                        <span className="dropdown-item" onClick={() => this.setState({ savingAsTemplate: true })}>
                          {Resources.SaveNewTemplate}
                        </span>
                        {showSaveDraft && (
                          <span className="dropdown-item" onClick={this.save}>
                            {Resources.SaveAsDraft}
                          </span>
                        )}
                      </span>
                    </div> */}
                  </div>

                  {(mode === "forward" || mode === "forwardCopy") && (
                    <div className="forward-internal-switch-container">
                      <ToggleSwitch
                        className="forward-internal-switch"
                        checked={this.state.includeInternal}
                        text={this.state.includeInternal ? Resources.Yes : Resources.No}
                        onChange={() => {
                          this.setState({ includeInternal: !this.state.includeInternal });
                        }}
                        label={Resources.StripOutInternalActivityInForwardedMessage}
                      />
                    </div>
                  )}
                  <div className="conversation-editor__mode-buttons">
                    {this.getSendButton(this.props.mode)}
                    {!conversation.new && (
                      <button
                        className="button-secondary-square"
                        onClick={this.delete}
                        style={{ minWidth: "14rem" }}
                        disabled={this.state.saving || this.state.committing}
                      >
                        {Resources.Cancel}
                      </button>
                    )}
                  </div>
                </div>
                <div className="p-1">
                  {this.state.attachments.map((a, index) => {
                    return (
                      <Attachment
                        attachment={a}
                        key={index}
                        name={a.name || a.companyDocumentName}
                        size={a.size || a.fileSize}
                        showDelete={true}
                        showIcon
                        onDelete={() => {
                          this.removeAttachment(index);
                        }}
                      />
                    );
                  })}
                  {this.state.existingAttachments.map((a, index) => {
                    return (
                      <Attachment
                        attachment={a}
                        key={a.attachmentId}
                        name={a.fileName}
                        size={a.sizeBytes}
                        showDelete={true}
                        showIcon
                        onDelete={() => {
                          this.removeExistingAttachment(index);
                        }}
                      />
                    );
                  })}
                </div>
              </React.Fragment>
            )}
            <input
              className="hidden-input"
              type="file"
              id="uploadFiles"
              multiple
              style={{ visibility: "hidden" }}
              onChange={this.showAttachFilesModal}
            />
          </div>
        )}
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    ledgerStore: store.ledger,
    conversations: store.conversations,
    accountsStore: store.accounts,
    manageStore: store.manage,
    contextGroupsStore: store.contextGroups,
    selectedAccountId: store.accounts.selectedAccountId
  };
};

export default withLDConsumer()(withRouter(connect(storeToProps, dispatchToProps)(ConversationEditor)));
