import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { dispatchToProps as maDP } from "../../../../../store/manage-actions";
import { dispatchToProps as caDP } from "../../../../../store/connector-actions";
import { dispatchToProps as coaDP } from "../../../../../store/company-actions";
import { dispatchToProps as eaDP } from "../../../../../store/error-actions";
import { dispatchToProps as raDP } from "../../../../../store/registration-actions";
import Resources from "../../../../../lib/resources";
import Card from "../../../../library/card";
import TextInput from "../../../../library/textInput";
import IconEdit from "../../../../library/icons/iconEdit";
import { isEmpty, find, isEmailValid } from "../../../../../lib/utils";
import IncomingSettings from "../../../../incomingSettings";
import OutgoingEmailSettings, { getOutgoingEmailOptionDisplayName } from "./outgoingEmailSettings";
import FlexibleScrollContainer from "../../../../flexibleScrollContainer";

const getCompanyForwardingAddress = (companyId, perspectiveId) => store => {
  const companyInfo = store.companies.companyInfo[companyId];
  const connectorEmail = companyInfo && find(companyInfo.connectorEmails, p => p.perspectiveId === perspectiveId);

  return connectorEmail && connectorEmail.companyForwardingAddress;
};

const getPerspectiveEmailConfigurationType = (companyId, perspectiveId) => store => {
  const config = store.manage.companiesEmailConfigType[companyId];
  return config && config[perspectiveId] && config[perspectiveId].emailConfigurationTypeName;
};

const getImapConnectorId = store => {
  let imapConnector = store.connector.allSupportedConnectors.find(({ connectorName }) => connectorName === "Email");
  return imapConnector && imapConnector.connectorId;
};

const getImapConnectorVersionId = connectorId => store => {
  let imapConnectorVersion = store.connector.connectorVersionData[connectorId];
  return imapConnectorVersion && imapConnectorVersion[0].connectorVersionId;
};

const getImapConfig = (imapConnectorVersionId, perspectiveId) => store => {
  if (imapConnectorVersionId && perspectiveId && !isEmpty(store.connector.connectorConfigs)) {
    const configs = store.connector.connectorConfigs[imapConnectorVersionId].connectorConfiguration;
    return find(configs, config => config.perspectiveId === perspectiveId);
  }
};

const IncomingEmailOptions = [
  { type: "imap", displayName: Resources.ImapServer },
  { type: "forwarding", displayName: Resources.EmailForwarding }
];

export const PerspectiveEmailSettingsViews = {
  PREFERENCES_OVERVIEW: "preferences_overview",
  EDIT_INCOMING_MAIL: "edit_incoming_mail",
  EDIT_OUTGOING_MAIL: "edit_outgoing_mail"
};

const EmailFormComponent = ({
  perspective,
  companyId,
  fetchCompanyEmailConfigType,
  getCompanyEmailConfigVerification,
  fetchCompanyEmailConfigData,
  fetchAllConnectors,
  fetchConnectorVersionData,
  fetchConnectorConfiguration,
  getCompanyInfo,
  raiseError,
  fetchCompanyInfo,
  setEmailForwardingAddress,
  setCompanyEmailConfig
}) => {
  const imapConnectorId = useSelector(getImapConnectorId);
  const imapConnectorVersionId = useSelector(getImapConnectorVersionId(imapConnectorId));
  const imapConfiguration = useSelector(getImapConfig(imapConnectorVersionId, perspective.perspectiveId));
  const configType = useSelector(getPerspectiveEmailConfigurationType(companyId, perspective.perspectiveId));
  const companyForwardingAddress = useSelector(getCompanyForwardingAddress(companyId, perspective.perspectiveId));
  const configData = useSelector(store => store.manage.companiesEmailConfigData[companyId]);
  const isCurrentEmailConfigVerified = useSelector(store => store.manage.isCurrentEmailConfigVerified);
  const allEmailConfigTypes = useSelector(store => store.manage.allEmailConfigTypes);
  const companyName = useSelector(store => store.accounts.selectedCompany.companyName);

  const [emailSettingsView, setEmailSettingsView] = useState(PerspectiveEmailSettingsViews.PREFERENCES_OVERVIEW);
  const [remoteEmail, setRemoteEmail] = useState(companyForwardingAddress);

  const incomingEmailOption = isEmpty(imapConfiguration)
    ? { type: "forwarding", displayName: Resources.EmailForwarding }
    : { type: "imap", displayName: Resources.ImapServer, config: imapConfiguration };

  useEffect(() => {
    fetchAllConnectors();
  }, [fetchAllConnectors]);

  useEffect(() => {
    if (imapConnectorId) {
      fetchConnectorVersionData(imapConnectorId);
    }
  }, [fetchConnectorVersionData, imapConnectorId]);

  useEffect(() => {
    if (companyId) {
      fetchCompanyEmailConfigType(companyId);
      fetchCompanyEmailConfigData(companyId);
      getCompanyEmailConfigVerification(companyId);
      getCompanyInfo(companyId);
    }
  }, [
    fetchCompanyEmailConfigType,
    fetchCompanyEmailConfigData,
    getCompanyEmailConfigVerification,
    getCompanyInfo,
    companyId
  ]);

  useEffect(() => {
    if (imapConnectorVersionId) {
      fetchConnectorConfiguration(companyId, imapConnectorVersionId);
    }
  }, [fetchConnectorConfiguration, companyId, imapConnectorVersionId]);

  useEffect(() => {
    setRemoteEmail(companyForwardingAddress);
  }, [companyForwardingAddress]);

  useEffect(() => {
    setEmailSettingsView(PerspectiveEmailSettingsViews.PREFERENCES_OVERVIEW);
  }, [perspective.perspectiveId]);

  const saveEmailSettings = () => {
    if (isEmailValid(remoteEmail) !== true) {
      raiseError(Resources.ValidEmailError);
      return;
    }

    setEmailForwardingAddress(companyId, perspective.perspectiveId, remoteEmail).then(() => {
      if (companyForwardingAddress !== remoteEmail) {
        fetchCompanyInfo(companyId);
      }
    });
  };

  return (
    <FlexibleScrollContainer>
      <h5 className="my-4">{Resources.ConnectEmailAccountToSendAndReceiveMailFromWorkspace}</h5>

      <TextInput
        inline
        label={Resources.EmailAddress}
        labelInline={false}
        textValue={remoteEmail}
        placeholder={Resources.RemoteEmailPlaceholder}
        width="19rem"
        inputOnChange={e => setRemoteEmail(e.target.value)}
      />

      <button className="button-primary-square ml-4" style={{ minWidth: "14rem" }} onClick={saveEmailSettings}>
        {Resources.Save}
      </button>

      <div className="nav-tabs my-4" />

      {emailSettingsView === PerspectiveEmailSettingsViews.PREFERENCES_OVERVIEW && (
        <React.Fragment>
          <h4>{Resources.DeliveryPreferences}</h4>

          <table className="email-types">
            <tbody>
              <tr>
                <td>{Resources.IncomingMail.toLocaleUpperCase()}</td>
                <td>
                  {incomingEmailOption.displayName}
                  <IconEdit
                    onClick={() => setEmailSettingsView(PerspectiveEmailSettingsViews.EDIT_INCOMING_MAIL)}
                    height={18}
                    className="email-types__edit"
                  />
                </td>
              </tr>
              <tr>
                <td>{Resources.OutgoingMail.toLocaleUpperCase()}</td>
                <td>
                  {getOutgoingEmailOptionDisplayName(configType)}
                  <IconEdit
                    onClick={() => setEmailSettingsView(PerspectiveEmailSettingsViews.EDIT_OUTGOING_MAIL)}
                    height={18}
                    className="email-types__edit"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      )}

      {emailSettingsView === PerspectiveEmailSettingsViews.EDIT_INCOMING_MAIL && (
        <Card className="mt-4" noSave>
          <h4>{Resources.EditIncomingEmailDelivery}</h4>
          <IncomingSettings
            companyId={companyId}
            perspectiveId={perspective.perspectiveId}
            perspectiveName={perspective.perspectiveName}
            incomingEmail={incomingEmailOption}
            incomingOptions={IncomingEmailOptions}
            // TODO: remove onSave prop from IncomingSettings after settings are migrated to v2
            // See also OutgoingEmailSettings and dummy callback passed into SPFDKIMEmailSettings and SMTPEmailSettings
            onSave={() => {}}
            onCancel={() => setEmailSettingsView(PerspectiveEmailSettingsViews.PREFERENCES_OVERVIEW)}
          />
        </Card>
      )}

      {emailSettingsView === PerspectiveEmailSettingsViews.EDIT_OUTGOING_MAIL && (
        <Card
          className="mt-4"
          maxWidth="50em"
          title={Resources.EmailSettings}
          description={Resources.ConnectEmailAccountToSendAndReceiveMailFromWorkspace}
          noSave
        >
          <OutgoingEmailSettings
            perspectiveId={perspective.perspectiveId}
            perspectiveName={perspective.perspectiveName}
            companyId={companyId}
            companyName={companyName}
            selectedConfig={configType}
            configData={configData}
            isCurrentEmailConfigVerified={isCurrentEmailConfigVerified}
            onCancel={() => setEmailSettingsView(PerspectiveEmailSettingsViews.PREFERENCES_OVERVIEW)}
            allEmailConfigTypes={allEmailConfigTypes}
            setCompanyEmailConfig={setCompanyEmailConfig}
          />
        </Card>
      )}
    </FlexibleScrollContainer>
  );
};

const EmailForm = connect(null, dispatch => ({
  ...maDP(dispatch),
  ...caDP(dispatch),
  ...coaDP(dispatch),
  ...eaDP(dispatch),
  ...raDP(dispatch)
}))(EmailFormComponent);

export default EmailForm;
