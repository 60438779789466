import React from "react";

function CelebrateSVG() {
  return (
    <div className="svg-container">
      <svg viewBox="0 0 242 244">
        <defs>
          <path id="a" d="M.42777614.93209475H142.166514V142.671781H.42777614z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            fill="#EF3C6B"
            d="M46 67.8049185S46.0382617 56.2463203 53.6578005 53c0 0 16.9581201 3.474973 16.7586128 23.0008162-.1995073 19.5258433 4.8086723 19.346481 15.3593286 15.6811923C96.3277647 88.0167198 106.14735 84.897733 117 90.8180573c0 0-8.474961 4.5004872-3.567901 14.1819427 0 0-15.3784595-7.6701335-24.4355441-3.091603-8.6334732 4.3622-24.8044959 5.230259-19.8318449-16.8669004 1.5741945-7.0033439-3.3314986-11.4230496-10.1051811-13.7616051-.1352824-.0479212-.2719312-.0917349-.4085801-.1369178C49.8425651 68.3320519 46 67.8049185 46 67.8049185"
          />
          <path
            fill="#576E7C"
            d="M45.8086908 67.0118454c1.5467803.2894015 5.3849347 1.1205736 12.0437691 3.242394.1453535.0466334.2879644.0946384.4305754.1426434 5.2820902 1.8200748 8.7500046 4.5741895 10.2241082 8.0195761-.0109701-.7859103-.0109701-1.6047382-.0027425-2.4605986.0781618-7.7918952-2.5903085-13.8830423-7.9354767-18.1033665-3.4336329-2.7102245-6.9372-3.7471322-7.9094228-3.9967581-5.704438 2.6512468-6.6848883 10.6653366-6.8508107 13.1561097zm68.6068382 23.9092269c-10.379061-4.978803-20.0039289-1.6417706-29.3230061 1.5896509-5.0174372 1.7405237-9.4178078 3.0023691-12.4565181.8160848-1.8717687-1.3468828-3.0551654-3.8760599-3.6585194-8.0223192-1.7702956 7.9235661-.8638933 13.5456359 2.6177337 16.2640902 4.0904661 3.191645 11.1826184 2.00798 16.0409896-.443018 7.4349671-3.7512468 18.5887893.189277 23.2113033 2.143766-1.343834-3.8870327-.600611-6.8962597.670546-9.0564841.884462-1.505985 2.02535-2.5991272 2.897471-3.2917706zm-2.25435 14.8870327c-.038396-.019202-3.861466-1.906484-8.718466-3.214963-4.3976282-1.182294-10.5724082-2.123192-15.0084315.115212-2.8234226 1.423691-6.0225702 2.323442-9.0078014 2.53192-3.6091541.250998-6.6931159-.536284-8.921412-2.275436-4.1329751-3.2245637-5.2217548-9.328055-3.2361716-18.1390275.9036598-4.003616-.0233114-9.465212-9.5631613-12.7528679-.1288984-.0438902-.259168-.0877805-.3894376-.1289276-8.6073937-2.7417706-12.4853146-3.3027432-12.5483925-3.3109726L44 68.5274314l.0027425-.7763092c.0013713-.4965087.1330122-12.2028678 8.2248124-15.6427681L52.4812378 52l.2687668.0548628c.1837488.038404 4.5100714.9518704 8.8542204 4.3561098 4.0150468 3.1477556 8.778801 9.0976309 8.6718428 19.5627182-.0932456 9.1236907.9859354 14.1806733 3.3952377 15.9143391 2.1240804 1.5279302 5.5837672.770823 10.8425461-1.051995 5.5865097-1.9366583 10.6286296-3.5811721 15.7571384-4.0790524 5.873103-.569202 11.201816.5019951 16.290559 3.2711971L118 90.8140898l-1.445307.7749376c-.316761.1687033-7.66671 4.2436409-3.208747 13.0244386L114.55814 107l-2.396961-1.191895z"
          />
          <path fill="#4E4F51" d="M92.8478645 99.705c-.264.448-.96.039-.696-.41.263-.448.961-.039.696.41" />
          <path
            fill="#F38923"
            d="M7.95521529 122.540941S22.273861 111.638598 33 135c0 0-10.0248573-8.285195-23.66305137-1.423664 0 0-4.15769811-1.454267-1.38173334-11.035395"
          />
          <path
            fill="#3E5969"
            d="M10.0387752 132.850947c6.8962064-3.546213 12.8312784-2.932156 16.6233169-1.772423 1.818617.55525 3.318202 1.277348 4.4422176 1.924228-3.4110847-6.895491-7.445426-10.97097-12.0114872-12.126601-4.8460556-1.226746-8.8521283 1.330685-9.7553792 1.972094-1.91284584 7.215512-.00134613 9.451555.7013319 10.002702zm22.421084 2.923951c-.0928827-.082057-9.4740387-8.096253-22.091283-1.363507l-.2974939.157275-.31499364-.116247c-.19384222-.071116-4.65490564-1.857215-1.81054022-12.272935l.06730633-.2407.1924961-.155907c.20191898-.162746 5.00893693-3.970173 11.22938773-2.406994 5.583733 1.4018 10.3665206 6.619234 14.2137503 15.505965L35 138l-2.5401408-2.225102z"
          />
          <path
            fill="#F38923"
            d="M33 134.734143S44.1534306 145.67578 62.1437061 134c0 0-5.6928687 9.318503.8562939 14.934491 0 0-23.512966 1.856788-30-14.200348"
          />
          <path
            fill="#3E5969"
            d="M59.4555675 139.577162c.2404163-1.190872.6023914-2.271377.9616652-3.155675-5.1716521 2.873627-12.2355698 5.309873-19.6898264 3.448624-2.3244746-.579085-4.1829738-1.45112-5.5390299-2.245489 2.9876455 4.793465 8.1728042 8.058144 15.4595797 9.721823 4.3855719 1.001477 8.4564415 1.133644 10.6634093 1.111844-1.2588091-1.526061-1.9679022-3.350521-2.1097208-5.413427-.0769872-1.091406.0067533-2.250939.2539229-3.4677zm3.4171533-4.722612c-.0121559.021801-1.372264 2.245489-1.9354865 5.070063-.7266515 3.64892.1593771 6.560697 2.635125 8.652218L65 149.785416l-1.8584992.144431c-.2431176.019075-6.0684861.449643-12.7920391-1.07778-9.0048067-2.046556-15.2178127-6.675152-17.9690938-13.387092L31 132.094016l2.6094625 2.524813c.0270131.024526 2.697255 2.567051 7.4826203 3.762011 6.384539 1.594188 13.5511063.009538 20.7284789-4.580906L64.6353236 132l-1.7626028 2.85455z"
          />
          <path
            fill="#1FB6C1"
            d="M96.4843756 2.52679547S84.5487721 19.5353252 113 30c0 0-10.520962-10.7599379-3.332051-26.70552038 0 0-2.01521-4.60473156-13.1835734-.76768415"
          />
          <path
            fill="#3E5969"
            d="M97.9118925 3.1380926c-.6667977 1.09220551-3.2986078 5.91051738-1.5622119 11.489399 1.6329742 5.2554681 6.5659174 9.6818468 14.6790774 13.1818378-.816487-1.2648754-1.747283-2.9641662-2.507976-5.047168-1.588068-4.3400437-2.709377-11.2166901.842342-19.50621475-.692653-.7975704-3.382978-2.84905302-11.4512315-.11785405zm15.4397715 27.5065859c-10.403407-3.8645164-16.6876359-9.0761319-18.6812252-15.4868437-2.2222057-7.14524409 1.7772202-13.02013118 1.9486825-13.26680244l.1632974-.23570809.2708016-.09455732c11.6676007-4.04814939 14.0231657 1.03190809 14.1170617 1.24980104l.155133.35904372-.159215.35767332c-2.835932 6.35315525-3.180218 12.59393807-1.02197 18.54967847 1.618005 4.4647498 4.116456 7.093169 4.13959 7.1178362L117 32l-3.648336-1.3553215z"
          />
          <path
            fill="#1FB6C1"
            d="M113 30s13.032776 12.2306605 1.2176 34c0 0 10.0354-7.2793375 16.781523-.0398303 0 0 .433796-27.5227631-17.999123-33.9601697"
          />
          <path
            fill="#3E5969"
            d="M118.720142 38.7269128c2.692984 8.509389.372683 16.8643848-2.593602 23.0592419 1.000724-.4768428 2.2292-.9728139 3.594326-1.3362528 4.023603-1.069822 7.591704-.5396931 10.437903 1.5275364-.13251-2.5577354-.57835-7.2674117-2.052521-12.2790428-2.45419-8.3263033-6.611684-14.1058016-12.38828-17.2305564 1.018669 1.5138733 2.162945 3.6084291 3.002174 6.2590737zm11.739535 25.8629134c-2.606025-2.7216928-6.048518-3.5455787-10.229476-2.4456978-3.239587.8512121-5.722765 2.5809627-5.74623 2.5987247L111.288554 67l1.896545-3.402116c4.791056-8.6009319 6.127196-16.9859866 3.860727-24.2506657-1.707443-5.4761771-4.883536-8.4547365-4.930467-8.4998248L109 28l4.01118 1.3649453c7.982332 2.7162276 13.800338 9.5915079 16.820456 19.8825673 2.254046 7.6841368 2.171227 14.4651415 2.167086 14.7493344l-.034508 2.165604-1.504537-1.5726248z"
          />
          <path
            fill="#7D4ED8"
            d="M235.583011 240c-.490421 0-.986307-.106551-1.450773-.32375L95.1230613 174.437268c-1.1283789-.377025-2.1010251-.969883-2.8933493-1.764914-3.8892188-3.890461-2.6870717-11.256108 3.5791195-21.893404 5.3645815-9.110071 13.9162175-19.72551 24.0798245-29.88606C134.767957 106.014064 154.68808 91 165.649475 91c3.12968 0 5.003937 1.2144029 6.024395 2.2361953.793691.7950309 1.385202 1.764914 1.760873 2.8946229l65.241064 139.0020078c.612002 1.303195.341519 2.848178-.676207 3.867238-.655717.652964-1.530006.999936-2.416589.999936"
          />
          <path
            fill="#576E7C"
            d="M165.641326 88h-.002718c-5.516945 0-12.895519 3.0563835-21.933254 9.0802623-8.294441 5.5302077-17.27918 13.0843297-25.986713 21.8459627-6.998096 7.04404-41.2181027 42.879624-27.509989 56.675058 1.1183364 1.124093 2.4622425 1.970581 4.0018235 2.517585l138.1084315 65.232933c.922661.434868 1.909189.648199 2.884846.648199 1.763791 0 3.503124-.69196 4.806264-2.003401 2.024692-2.036222 2.561439-5.132263 1.346624-7.740103L176.537972 95.2669448c-.546259-1.5493881-1.386031-2.9018549-2.501649-4.0245801C172.566043 89.7627196 169.916279 88 165.641326 88m-.001359 6.8375469c1.54094 0 2.758472.4006802 3.59145 1.2389635.457933.4608507.78134 1.0406746.981092 1.7244293l64.992602 139.3601463-138.4780389-65.405239c-.6794267-.202391-1.2569394-.529226-1.714873-.990077-5.2995286-5.334654 7.0171189-26.379256 27.5113469-47.004032 17.272387-17.385147 34.846439-28.9241911 43.116421-28.9241911"
          />
          <g transform="translate(93 94)">
            <mask id="b" fill="#fff"></mask>
            <path
              fill="#000"
              d="M41.8400295 77.7443333L142.167468 142.671781 3.37847714 77.5346c-.68095238-.2015619-1.2597619-.5270571-1.7173619-.9846571-5.31279048-5.3141524 7.03015238-26.2725048 27.57039996-46.8127524C49.7731248 9.19558095 70.7314771-3.1473619 76.0442676 2.16542857c.4589619.45896191.7830953 1.03640953.9846572 1.71872381l6.2089238 13.22818092c-33.3693905 10.9211143-46.1522286 33.6336-50.6969048 45.5148572-1.4313619 3.7397905 3.0901619 11.0995238 9.2990857 15.1171428"
              mask="url(#b)"
              opacity=".22999999"
            />
          </g>
          <path
            fill="#F5F5F5"
            d="M205.959405 223L185 213h39.38295L229 223zM147.061371 195L126 185h85.361848L216 195zM94.0181836 168c-.1657291-2.486591.805552-5.919406 2.744039-10H198.363661L203 168H94.0181836zM108 140c2.544536-3.251272 5.380577-6.60982 8.461809-10h68.890462L190 140h-82zM148.178308 102h24.18898L177 112h-42c4.608265-3.917469 9.06849-7.287483 13.178308-10"
          />
          <path
            fill="#F38923"
            d="M77 122.500699C77 125.538168 79.4624571 128 82.4993012 128 85.5361453 128 88 125.538168 88 122.500699 88 119.464626 85.5361453 117 82.4993012 117S77 119.464626 77 122.500699"
          />
          <path
            fill="#F38923"
            d="M78.2796983 122.500681c0 2.328094 1.8943013 4.221629 4.2203017 4.221629 2.3273622 0 4.2203017-1.893535 4.2203017-4.221629 0-2.326732-1.8929395-4.222991-4.2203017-4.222991-2.3260004 0-4.2203017 1.896259-4.2203017 4.222991m-2.2796983 0C76 118.916588 78.9170333 116 82.5 116c3.5816049 0 6.5 2.916588 6.5 6.500681S86.0816049 129 82.5 129c-3.5829667 0-6.5-2.915226-6.5-6.499319"
          />
          <path
            fill="#EF3C6B"
            d="M75 14.5c0 3.0364583 2.4638547 5.5 5.5006988 5.5C83.5361453 20 86 17.5364583 86 14.5S83.5361453 9 80.5006988 9C77.4638547 9 75 11.4635417 75 14.5"
          />
          <path
            fill="#EF3C6B"
            d="M76.2799371 14.5c0 2.3264878 1.8944998 4.2211861 4.2207439 4.2211861 2.3276061 0 4.2193819-1.8946983 4.2193819-4.2211861 0-2.3264878-1.8917758-4.2211861-4.2193819-4.2211861-2.3262441 0-4.2207439 1.8946983-4.2207439 4.2211861M74 14.5c0-3.5837175 2.9173389-6.5 6.500681-6.5C84.084023 8 87 10.9162825 87 14.5S84.084023 21 80.500681 21C76.9173389 21 74 18.0837175 74 14.5"
          />
          <path
            fill="#F38923"
            d="M121 80.5c0 3.0378557 2.462457 5.5 5.500699 5.5C129.537543 86 132 83.5378557 132 80.5c0-3.0364583-2.462457-5.5-5.499301-5.5C123.462457 75 121 77.4635417 121 80.5"
          />
          <path
            fill="#F38923"
            d="M122.279937 80.5c0 2.3264878 1.893138 4.2211861 4.220744 4.2211861s4.219382-1.8946983 4.219382-4.2211861c0-2.3264878-1.891776-4.2211861-4.219382-4.2211861-2.327606 0-4.220744 1.8946983-4.220744 4.2211861M120 80.5c0-3.5837175 2.915977-6.5 6.500681-6.5C130.084023 74 133 76.9162825 133 80.5s-2.915977 6.5-6.499319 6.5C122.915977 87 120 84.0837175 120 80.5M27 50.5c0 3.0378557 2.4624571 5.5 5.5006988 5.5C35.5375429 56 38 53.5378557 38 50.5c0-3.0364583-2.4624571-5.5-5.4993012-5.5C29.4624571 45 27 47.4635417 27 50.5"
          />
          <path
            fill="#F38923"
            d="M28.2799371 50.5c0 2.3264878 1.8931378 4.2211861 4.2207439 4.2211861 2.3276061 0 4.2193819-1.8946983 4.2193819-4.2211861 0-2.3264878-1.8917758-4.2211861-4.2193819-4.2211861S28.2799371 48.1735122 28.2799371 50.5M26 50.5c0-3.5837175 2.915977-6.5 6.500681-6.5C36.084023 44 39 46.9162825 39 50.5S36.084023 57 32.500681 57C28.915977 57 26 54.0837175 26 50.5"
          />
          <path
            fill="#1FB6C1"
            d="M1 102.5c0 3.036458 2.46245712 5.5 5.50069877 5.5C9.53754288 108 12 105.536458 12 102.5c0-3.0378557-2.46245712-5.5-5.49930123-5.5C3.46245712 97 1 99.4621443 1 102.5"
          />
          <path
            fill="#1FB6C1"
            d="M2.27993714 102.5c0 2.326488 1.89313777 4.221186 4.22074384 4.221186 2.32760608 0 4.21938192-1.894698 4.21938192-4.221186 0-2.326488-1.89177584-4.2211861-4.21938192-4.2211861-2.32760607 0-4.22074384 1.8946981-4.22074384 4.2211861M0 102.5C0 98.9162825 2.91597695 96 6.50068098 96 10.0826611 96 13 98.9162825 13 102.5c0 3.583718-2.9173389 6.5-6.49931902 6.5C2.91597695 109 0 106.083718 0 102.5"
          />
        </g>
      </svg>
    </div>
  );
}

export default CelebrateSVG;
