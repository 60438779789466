import React from "react";
import { cleanHtml } from "../../lib/utils";

const Iframe = ({ content, sanitize = true }) => {
  let iframe_ref = null;
  const writeHTML = frame => {
    if (!frame) {
      return;
    }
    iframe_ref = frame;
    let doc = frame.contentDocument;
    doc.open();
    if (sanitize) {
      doc.write(cleanHtml(content));
    } else {
      doc.write(content);
    }
    doc.close();
    frame.style.width = "100%";
    frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
  };

  return <iframe sandbox="allow-same-origin" src="about:blank" scrolling="no" frameBorder="0" ref={writeHTML} />;
};

export default Iframe;
