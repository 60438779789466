import React, { Component } from "react";
import { connect } from "react-redux";

import { isEmpty, find } from "../lib/utils";

import { getAllCompanyReplyTemplates } from "../store/manage-actions";
import Resources from "../lib/resources";
import SelectInput from "./library/selectInput";

class EmailTemplatePicker extends Component {
  onTemplateSelected(e, template, templateObject) {
    if (isEmpty(this.props.templateSelected) !== true) this.props.templateSelected(template, templateObject || {});
  }

  render() {
    let templates = this.props.getAllCompanyReplyTemplates(this.props.companyId, this.props.perspective) || [];

    const selectInputOptions = templates.map(template => {
      return { displayName: template.emailTemplateID, value: template.emailTemplateKey };
    });

    return (
      <div className="email-template-picker">
        {this.props.noLabel ? null : <div className="portal-input-label">{Resources.Template}</div>}
        <div>
          <SelectInput
            value={this.props.appliedTemplateId}
            displayValue={
              this.props.fetchingTemplateId === null
                ? (find(templates, template => template.emailTemplateKey === this.props.appliedTemplateId) || {})
                    .emailTemplateID || Resources.SelectMessageTemplate
                : Resources.FetchingPopulatedTemplate
            }
            options={selectInputOptions}
            onSelectOption={option =>
              this.onTemplateSelected(
                null,
                option.value,
                find(templates, t => t.emailTemplateKey === option.value)
              )
            }
            labelClassName="reply-form-label"
            width="20rem"
            disabled={this.props.disabled}
          />
        </div>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    user: store.user,
    manage: store.manage
  };
};

export default connect(storeToProps, {
  getAllCompanyReplyTemplates
})(EmailTemplatePicker);
