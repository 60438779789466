import React from "react";

import Resources from "../../lib/resources";
import { decodeURLQueryString } from "../../lib/utils";

import IconAlertCircle from "../library/icons/iconAlertCircle";

export default function PaymentErrorCode({ errorCode, errorMessage }) {
  let message = errorMessage;

  if (errorCode === "5") {
    message = Resources.YourPaymentMethodWasDeclined;
  }

  return (
    <div className="payment-error">
      <IconAlertCircle height={18} className="payment-error__icon" />
      <span className="payment-error__message">{decodeURLQueryString(message)}</span>
    </div>
  );
}
