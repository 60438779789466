import React from "react";

function UserIconSVG() {
  return (
    <div className="svg-container">
      <svg viewBox="0 0 150 101">
        <g fill="none" fillRule="evenodd">
          <path
            fill="#F4F3FF"
            d="M82.6365418 23.9077887c2.6846413 0 4.860973 2.1732164 4.860973 4.8540146 0 2.6807982-2.1763317 4.8540146-4.860973 4.8540146h44.4431822c2.684641 0 4.860973 2.1732164 4.860973 4.8540146 0 2.6807982-2.176332 4.8540146-4.860973 4.8540146h15.277344c2.684641 0 4.860973 2.1732164 4.860973 4.8540146 0 2.6807982-2.176332 4.8540146-4.860973 4.8540146h-13.19407c-2.684641 0-4.860973 2.1732164-4.860973 4.8540146 0 2.6807982 2.176332 4.8540146 4.860973 4.8540146h4.166548c2.684642 0 4.860973 2.1732164 4.860973 4.8540146 0 2.6807982-2.176331 4.8540146-4.860973 4.8540146H31.9435372c-2.6846413 0-4.8609731-2.1732164-4.8609731-4.8540146 0-2.6807982 2.1763318-4.8540146 4.8609731-4.8540146H4.86097305C2.17633176 62.7399055 0 60.5666891 0 57.8858909c0-2.6807982 2.17633176-4.8540146 4.86097305-4.8540146H32.6379619c2.6846413 0 4.860973-2.1732164 4.860973-4.8540146 0-2.6807982-2.1763317-4.8540146-4.860973-4.8540146h-17.360618c-2.6846413 0-4.8609731-2.1732164-4.8609731-4.8540146 0-2.6807982 2.1763318-4.8540146 4.8609731-4.8540146h27.7769888c-2.6846413 0-4.860973-2.1732164-4.860973-4.8540146 0-2.6807982 2.1763317-4.8540146 4.860973-4.8540146h39.5822091zm62.4982252 38.8321168c2.684641 0 4.860973 2.1732164 4.860973 4.8540146 0 2.6807982-2.176332 4.8540146-4.860973 4.8540146-2.684642 0-4.860973-2.1732164-4.860973-4.8540146 0-2.6807982 2.176331-4.8540146 4.860973-4.8540146z"
          />
          <path
            fill="#FFF"
            stroke="#BEB6FF"
            strokeLinecap="round"
            strokeWidth="3.5"
            d="M96.9552 78.20292111c2.10923471-1.35814792 3.98491112-3.05208202 5.20537571-4.41942975m1.96952608-2.27219692h0c1.00956547-1.23615724 1.91721215-2.52880303 2.72275395-3.86658713 1.77169673-2.94230274 3.04948117-6.1029529 3.83137014-9.361195.79076937-3.29524831 1.07431455-6.69031784.84858433-10.06029196-.19697057-2.94062645-.7817168-5.86214343-1.75559978-8.68155636-.96619313-2.79715074-2.31540328-5.49380227-4.04896087-8.00890905-1.03300072-1.498713-2.20247475-2.93296364-3.50870382-4.28560344-1.91777122-1.98591023-4.0216379-3.68335906-6.2573334-5.0913429-2.40218162-1.51283263-4.95655707-2.69148382-7.59581026-3.53470963-3.30530764-1.05602606-6.74373972-1.58596431-10.18307273-1.58737093-2.6760082-.00109435-5.35256207.3177727-7.9673816.95775253-2.94376364.72048934-5.80928376 1.84796517-8.50769522 3.38407023-2.37206855 1.35033022-4.61500418 3.0164281-6.66844164 4.9994096-2.58052996 2.49198882-4.67136617 5.3005285-6.27054078 8.30581088-1.5656629 2.94230494-2.6600545 6.07319325-3.28132815 9.28023218-.64007549 3.30409453-.77796355 6.68901845-.41164497 10.03181935.47479264 4.33266923 1.79661512 8.59457486 3.96986412 12.51800186 1.39059904 2.51048717 3.12980175 4.8823894 5.21876027 7.04556927 3.31624905 3.43407641 7.18896181 6.00559416 11.33753909 7.70936583 5.04504335 2.07193972 10.49805588 2.86059162 15.85439532 2.35662656 4.49548027-.42296895 8.92286445-1.75645026 12.98381546-4.0059592"
          />
          <g
            stroke="#BEB6FF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3.5"
            transform="translate(62 32)"
          >
            <path d="M34 36v-4.1818182c0-4.6191088-3.8055796-8.3636363-8.5-8.3636363h-17c-4.69442037 0-8.5 3.7445275-8.5 8.3636363V36" />
            <ellipse cx="16.7301587" cy="8.18181818" rx="8.0952381" ry="8.18181818" />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default UserIconSVG;
