import data from "../lib/data";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError, raiseValidationError } from "./error-actions";
import Resources from "../lib/resources";
import { isEmpty, find, debounce, getConfig, openUrlForDownload } from "../lib/utils";
import _ from "lodash";

export const actions = {
  ...GeneralActions,
  ...{
    GETTING_NETWORK_ACCOUNT_LIST: "GETTING_NETWORK_ACCOUNT_LIST",
    GOT_NETWORK_ACCOUNT_LIST: "GOT_NETWORK_ACCOUNT_LIST",
    FAILED_GET_NETWORK_ACCOUNT_LIST: "FAILED_GET_NETWORK_ACCOUNT_LIST",

    GETTING_NETWORK_ACCOUNT_SEARCH_RESULTS: "GETTING_NETWORK_ACCOUNT_SEARCH_RESULTS",
    GOT_NETWORK_ACCOUNT_SEARCH_RESULTS: "GOT_NETWORK_ACCOUNT_SEARCH_RESULTS",
    FAILED_GET_NETWORK_ACCOUNT_SEARCH_RESULTS: "FAILED_GET_NETWORK_ACCOUNT_SEARCH_RESULTS",

    SET_NETWORK_ACCOUNT_LIST_LOADED_PAGE: "SET_NETWORK_ACCOUNT_LIST_LOADED_PAGE",

    GETTING_NETWORK_CONTACT_LIST: "GETTING_NETWORK_CONTACT_LIST",
    GOT_NETWORK_CONTACT_LIST: "GOT_NETWORK_CONTACT_LIST",
    FAILED_GET_NETWORK_CONTACT_LIST: "FAILED_GET_NETWORK_CONTACT_LIST",

    GETTING_NETWORK_CONTACT_SEARCH_RESULTS: "GETTING_NETWORK_CONTACT_SEARCH_RESULTS",
    GOT_NETWORK_CONTACT_SEARCH_RESULTS: "GOT_NETWORK_CONTACT_SEARCH_RESULTS",
    FAILED_GET_NETWORK_CONTACT_SEARCH_RESULTS: "FAILED_GET_NETWORK_CONTACT_SEARCH_RESULTS",

    SET_NETWORK_CONTACT_LIST_LOADED_PAGE: "SET_NETWORK_CONTACT_LIST_LOADED_PAGE",

    GETTING_CUST_CONTACT: "GETTING_CUST_CONTACT",
    GOT_CUST_CONTACT: "GOT_CUST_CONTACT",
    FAILED_GET_CUST_CONTACT: "FAILED_GET_CUST_CONTACT",

    POSTING_CUST_CONTACT: "POSTING_CUST_CONTACT",
    POSTED_CUST_CONTACT: "POSTED_CUST_CONTACT",
    FAILED_POST_CUST_CONTACT: "FAILED_POST_CUST_CONTACT",

    POSTING_CUST_CONTACT_PICTURE: "POSTING_CUST_CONTACT_PICTURE",
    POSTED_CUST_CONTACT_PICTURE: "POSTED_CUST_CONTACT_PICTURE",
    FAILED_POST_CUST_CONTACT_PICTURE: "FAILED_POST_CUST_CONTACT_PICTURE",

    GETTING_CUST_ACCOUNT: "GETTING_CUST_ACCOUNT",
    GOT_CUST_ACCOUNT: "GOT_CUST_ACCOUNT",
    FAILED_GET_CUST_ACCOUNT: "FAILED_GET_CUST_ACCOUNT",

    GETTING_CUST_ACCOUNT_DOCUMENTS: "GETTING_CUST_ACCOUNT_DOCUMENTS",
    GOT_CUST_ACCOUNT_DOCUMENTS: "GOT_CUST_ACCOUNT_DOCUMENTS",
    FAILED_GET_CUST_ACCOUNT_DOCUMENTS: "FAILED_GET_CUST_ACCOUNT_DOCUMENTS",

    GETTING_CUST_ACCOUNT_CONTACTS: "GETTING_CUST_ACCOUNT_CONTACTS)",
    GOT_CUST_ACCOUNT_CONTACTS: "GOT_CUST_ACCOUNT_CONTACTS",
    FAILED_GET_CUST_ACCOUNT_CONTACTS: "FAILED_GET_CUST_ACCOUNT_CONTACTS",

    POSTING_CUST_ACCOUNT: "POSTING_CUST_ACCOUNT",
    POSTED_CUST_ACCOUNT: "POSTED_CUST_ACCOUNT",
    FAILED_POST_CUST_ACCOUNT: "FAILED_POST_CUST_ACCOUNT",

    POSTING_CUST_ACCOUNT_PICTURE: "POSTING_CUST_ACCOUNT_PICTURE",
    POSTED_CUST_ACCOUNT_PICTURE: "POSTED_CUST_ACCOUNT_PICTURE",
    FAILED_POST_CUST_ACCOUNT_PICTURE: "FAILED_POST_CUST_ACCOUNT_PICTURE",

    GETTING_OPEN_ONBOARDING_REQUESTS: "GETTING_OPEN_ONBOARDING_REQUESTS",
    GOT_OPEN_ONBOARDING_REQUESTS: "GOT_OPEN_ONBOARDING_REQUESTS",
    FAILED_GET_OPEN_ONBOARDING_REQUESTS: "FAILED_GET_OPEN_ONBOARDING_REQUESTS",

    GETTING_OPEN_PROFILE_UPDATE_REQUESTS: "GETTING_OPEN_PROFILE_UPDATE_REQUESTS",
    GOT_OPEN_PROFILE_UPDATE_REQUESTS: "GOT_OPEN_PROFILE_UPDATE_REQUESTS",
    FAILED_GET_OPEN_PROFILE_UPDATE_REQUESTS: "FAILED_GET_OPEN_PROFILE_UPDATE_REQUESTS",

    GETTING_ACCOUNT_PROFILE: "GETTING_ACCOUNT_PROFILE",
    GOT_ACCOUNT_PROFILE: "GOT_ACCOUNT_PROFILE",
    GET_ACCOUNT_PROFILE_FAILED: "GET_ACCOUNT_PROFILE_FAILED",

    GETTING_ACCOUNT_DOCUMENTS: "GETTING_ACCOUNT_DOCUMENTS",
    GOT_ACCOUNT_DOCUMENTS: "GOT_ACCOUNT_DOCUMENTS",
    GET_ACCOUNT_DOCUMENTS_FAILED: "GET_ACCOUNT_DOCUMENTS_FAILED",

    GETTING_ACCOUNT_CONTACTS: "GETTING_ACCOUNT_CONTACTS",
    GOT_ACCOUNT_CONTACTS: "GOT_ACCOUNT_CONTACTS",
    GET_ACCOUNT_CONTACTS_FAILED: "GET_ACCOUNT_CONTACTS_FAILED",

    GETTING_CONTACT_PROFILE: "GETTING_CONTACT_PROFILE",
    GOT_CONTACT_PROFILE: "GOT_CONTACT_PROFILE",
    GOT_CONTACT_PROFILE_FAILED: "GOT_CONTACT_PROFILE_FAILED",

    POSTING_ACCOUNT_PROFILE_UPDATE_REQUEST: "POSTING_ACCOUNT_PROFILE_UPDATE_REQUEST",
    POSTED_ACCOUNT_PROFILE_UPDATE_REQUEST: "POSTED_ACCOUNT_PROFILE_UPDATE_REQUEST",
    FAILED_POST_ACCOUNT_PROFILE_UPDATE_REQUEST: "FAILED_POST_ACCOUNT_PROFILE_UPDATE_REQUEST",

    POSTING_CONTACT_PROFILE_UPDATE_REQUEST: "POSTING_CONTACT_PROFILE_UPDATE_REQUEST",
    POSTED_CONTACT_PROFILE_UPDATE_REQUEST: "POSTED_CONTACT_PROFILE_UPDATE_REQUEST",
    FAILED_POST_CONTACT_PROFILE_UPDATE_REQUEST: "FAILED_POST_CONTACT_PROFILE_UPDATE_REQUEST",

    GETTING_HEADER_INFO: "GETTING_HEADER_INFO",
    GOT_HEADER_INFO: "GOT_HEADER_INFO",
    FAILED_GET_HEADER_INFO: "FAILED_GET_HEADER_INFO",

    GETTING_CUST_ACCOUNT_SETTINGS: "GETTING_CUST_ACCOUNT_SETTINGS",
    GOT_CUST_ACCOUNT_SETTINGS: "GOT_CUST_ACCOUNT_SETTINGS",
    FAILED_GET_CUST_ACCOUNT_SETTINGS: "FAILED_GET_CUST_ACCOUNT_SETTINGS",

    POSTING_CUST_ACCOUNT_SETTINGS: "POSTING_CUST_ACCOUNT_SETTINGS",
    POSTED_CUST_ACCOUNT_SETTINGS: "POSTED_CUST_ACCOUNT_SETTINGS",
    FAILED_POST_CUST_ACCOUNT_SETTINGS: "FAILED_POST_CUST_ACCOUNT_SETTINGS",

    GETTING_NETWORK_ACTIVITIES: "GETTING_NETWORK_ACTIVITIES",
    GOT_NETWORK_ACTIVITIES: "GOT_NETWORK_ACTIVITIES",
    FAILED_GET_NETWORK_ACTIVITIES: "FAILED_GET_NETWORK_ACTIVITIES",

    GETTING_NOTES: "GETTING_NOTES",
    GOT_NOTES: "GOT_NOTES",
    FAILED_GET_NOTES: "FAILED_GET_NOTES",

    POSTING_NOTES: "POSTING_NOTES",
    POSTED_NOTES: "POSTED_NOTES",
    FAILED_POST_NOTES: "FAILED_POST_NOTES",

    DELETING_NOTE: "DELETING_NOTE",
    DELETED_NOTE: "DELETED_NOTE",
    FAILED_DELETE_NOTE: "FAILED_DELETE_NOTE",

    GETTING_SHARE_FLYOUT_MESSAGE: "GETTING_SHARE_FLYOUT_MESSAGE",
    GOT_SHARE_FLYOUT_MESSAGE: "GOT_SHARE_FLYOUT_MESSAGE",
    FAILED_GET_SHARE_FLYOUT_MESSAGE: "FAILED_SHARE_FLYOUT_MESSAGE",

    GETTING_SHARE_LINK: "GETTING_SHARE_LINK",
    GOT_SHARE_LINK: "GOT_SHARE_LINK",
    FAILED_GET_SHARE_LINK: "FAILED_GET_SHARE_LINK",

    POSTING_SHARE_FLYOUT: "POSTING_SHARE_FLYOUT",
    POSTED_SHARE_FLYOUT: "POSTED_SHARE_FLYOUT",
    FAILED_POST_SHARE_FLYOUT: "FAILED_POST_SHARE_FLYOUT",

    UPLOADING_ACCOUNT_DOCUMENT: "UPLOADING_ACCOUNT_DOCUMENT",
    UPLOADED_ACCOUNT_DOCUMENT: "UPLOADED_ACCOUNT_DOCUMENT",
    FAILED_UPLOAD_ACCOUNT_DOCUMENT: "FAILED_UPLOAD_ACCOUNT_DOCUMENT",

    POSTING_MY_PROFILE_PICTURE: "POSTING_MY_PROFILE_PICTURE",
    POSTED_MY_PROFILE_PICTURE: "POSTED_MY_PROFILE_PICTURE",
    FAILED_POST_MY_PROFILE_PICTURE: "FAILED_POST_MY_PROFILE_PICTURE",

    GETTING_SUBSIDIARY_LIST: "GETTING_SUBSIDIARY_LIST",
    GOT_SUBSIDIARY_LIST: "GOT_SUBSIDIARY_LIST",
    FAILED_GET_SUBSIDIARY_LIST: "FAILED_GET_SUBSIDIARY_LIST",

    UPDATING_CONTACT_PRIVACY: "UPDATING_CONTACT_PRIVACY",
    UPDATED_CONTACT_PRIVACY: "UPDATED_CONTACT_PRIVACY",
    FAILED_UPDATE_CONTACT_PRIVACY: "FAILED_UPDATE_CONTACT_PRIVACY",

    ADDING_CONTACT: "ADDING_CONTACT",
    ADDED_CONTACT: "ADDED_CONTACT",
    FAILED_ADD_CONTACT: "FAILED_ADD_CONTACT",

    MARKING_CONTACT_AS_PRIMARY: "MARKING_CONTACT_AS_PRIMARY",
    MARKED_CONTACT_AS_PRIMARY: "MARKED_CONTACT_AS_PRIMARY",
    FAILED_MARK_CONTACT_AS_PRIMARY: "FAILED_MARK_CONTACT_AS_PRIMARY",

    ARCHIVING_ACCOUNT: "ARCHIVING_ACCOUNT",
    ARCHIVED_ACCOUNT: "ARCHIVED_ACCOUNT",
    FAILED_ARCHIVE_ACCOUNT: "FAILED_ARCHIVE_ACCOUNT",

    GETTING_ARCHIVED_LIST: "GETTING_ARCHIVED_LIST",
    GOT_ARCHIVED_LIST: "GOT_ARCHIVED_LIST",
    FAILED_GET_ARCHIVED_LIST: "FAILED_GET_ARCHIVED_LIST",

    GETTING_ARCHIVED_CONTACT_LIST: "GETTING_ARCHIVED_CONTACT_LIST",
    GOT_ARCHIVED_CONTACT_LIST: "GOT_ARCHIVED_CONTACT_LIST",
    FAILED_GET_ARCHIVED_CONTACT_LIST: "FAILED_GET_ARCHIVED_CONTACT_LIST",

    ARCHIVING_CONTACT: "ARCHIVING_CONTACT",
    ARCHIVED_CONTACT: "ARCHIVED_CONTACT",
    FAILED_ARCHIVE_CONTACT: "FAILED_ARCHIVE_CONTACT",

    GETTING_ACCOUNT_ARCHIVED_CONTACTS: "GETTING_ACCOUNT_ARCHIVED_CONTACTS",
    GOT_ACCOUNT_ARCHIVED_CONTACTS: "GOT_ACCOUNT_ARCHIVED_CONTACTS",
    GET_ACCOUNT_ARCHIVED_CONTACTS_FAILED: "GET_ACCOUNT_ARCHIVED_CONTACTS_FAILED",

    CLAIMING_PROFILE: "CLAIMING_PROFILE",
    CLAIMED_PROFILE: "CLAIMED_PROFILE",
    FAILED_CLAIM_PROFILE: "FAILED_CLAIM_PROFILE",

    GETTING_SELF_SERVICE_STATUS: "GETTING_SELF_SERVICE_STATUS",
    GOT_SELF_SERVICE_STATUS: "GOT_SELF_SERVICE_STATUS",
    FAILED_GET_SELF_SERVICE_STATUS: "FAILED_GET_SELF_SERVICE_STATUS",

    GETTING_EMAIL_ATACHMENTS: "GETTING_EMAIL_ATACHMENTS",
    GOT_EMAIL_ATACHMENTS: "GOT_EMAIL_ATACHMENTS",
    FAILED_GET_EMAIL_ATACHMENTS: "FAILED_GET_EMAIL_ATACHMENTS",

    UPDATING_DOCUMENT_TYPE: "UPDATING_DOCUMENT_TYPE",
    UPDATED_DOCUMENT_TYPE: "UPDATED_DOCUMENT_TYPE",
    FAILED_UPDATE_DOCUMENT_TYPE: "FAILED_UPDATE_DOCUMENT_TYPE",

    DELETING_DOCUMENT: "DELETING_DOCUMENT",
    DELETED_DOCUMENT: "DELETED_DOCUMENT",
    FAILED_DELETE_DOCUMENT: "FAILED_DELETE_DOCUMENT",

    DOWNLOADING_ATTACHMENT: "DOWNLOADING_ATTATCHMENT",
    DOWNLOADED_ATTACHMENT: "DOWNLOADED_ATTACHMENT",
    FAILED_DOWNLOAD_ATTACHMENT: "FAILED_DOWNLOAD_ATTACHMENT",

    SUBMITING_CONVERSION_INFO: "SUBMITTING_CONVERSION_INFO",
    SUBMITTED_CONVERSION_INFO: "SUBMITTED_CONVERSION_INFO",
    FAILED_SUBMIT_CONVERSION_INFO: "FAILED_SUBMIT_CONVERSION_INFO"
  }
};

const submitConversionInfo = request => dispatch => {
  dispatch({ type: actions.SUBMITING_CONVERSION_INFO });
  let endpoint = `v3/api/conversion/submit`;
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.SUBMITTED_CONVERSION_INFO, conversionInfo: response.data });
    })
    .catch(error => {
      handlePromiseError(error, "Failed to submit your information", "customer contact data");
      dispatch({ type: actions.FAILED_SUBMIT_CONVERSION_INFO });
      throw error;
    });
};

const markContactPrimary = (accountingGroupId, contactKey, mode) => dispatch => {
  let endpoint = "";
  if (mode === "ap") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendorcontact/${contactKey}/update/setasprimarycontact`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customercontact/${contactKey}/update/setasprimarycontact`;
  }
  dispatch({ type: actions.MARKING_CONTACT_AS_PRIMARY });
  return data
    .put(endpoint)
    .then(response => {
      dispatch({ type: actions.MARKED_CONTACT_AS_PRIMARY, setAsPrimary: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, "failed to mark contact primary", "mark contact primary");
      dispatch({ type: actions.FAILED_MARK_CONTACT_AS_PRIMARY });
      throw error;
    });
};

const addContact = (accountingGroupId, companyKey, mode, request) => dispatch => {
  let endpoint = "";
  if (mode === "ap") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendor/${companyKey}/vendorcontact/add`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customer/${companyKey}/customercontact/add`;
  }
  dispatch({ type: actions.ADDING_CONTACT });
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.ADDED_CONTACT, addedContact: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, "failed to add contact", "add contact");
      dispatch({ type: actions.FAILED_ADD_CONTACT });
      throw error;
    });
};

const fetchAccountList = (accountingGroupId, params = {}) => (dispatch, getState) => {
  // 1 = vendors, 2 = customers
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendor/list`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customer/list`;
  }

  const { top, skip, filter } = params;

  endpoint = `${endpoint}?$top=${top || getState().general.pageRowCount}`;
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }
  if (!isEmpty(filter)) {
    endpoint = `${endpoint}&$filter=${filter}`;
  }

  dispatch({ type: actions.GETTING_NETWORK_ACCOUNT_LIST });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_NETWORK_ACCOUNT_LIST, accountList: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetAccountListFailure, "account list");
      dispatch({ type: actions.FAILED_GET_NETWORK_ACCOUNT_LIST });
      throw error;
    });
};

const getAccountList = accountingGroupId => (dispatch, getState) => {
  let state = getState().network;
  let fetchedAccountList =
    state.gettingNetworkAccountList || state.gotNetworkAccountList || state.failedGetNetworkAccountList;

  if (!fetchedAccountList) {
    return dispatch(fetchAccountList(accountingGroupId));
  }

  return state.accountList;
};

const selectAccountListPage = (accountingGroupId, selectedPage) => (dispatch, getState) => {
  dispatch(
    fetchAccountList(accountingGroupId, {
      top: getState().general.pageRowCount,
      skip: (selectedPage - 1) * getState().general.pageRowCount
    })
  );
  dispatch({ type: actions.SET_NETWORK_ACCOUNT_LIST_LOADED_PAGE, selectedPage });
};

const setAccountListFilter = (accountingGroupId, filter) => (dispatch, getState) => {
  dispatch(
    fetchAccountList(accountingGroupId, {
      top: getState().general.pageRowCount,
      skip: 0,
      filter
    })
  );
  dispatch({ type: actions.SET_NETWORK_ACCOUNT_LIST_LOADED_PAGE, selectedPage: 1 });
};

const fetchContactList = (accountingGroupId, params = {}) => (dispatch, getState) => {
  // 1 = vendors, 2 = customers
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendorcontact/list`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customercontact/list`;
  }

  const { top, skip, filter } = params;

  endpoint = `${endpoint}?$top=${top || getState().general.pageRowCount}`;
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }
  if (!isEmpty(filter)) {
    endpoint = `${endpoint}&$filter=${filter}`;
  }

  dispatch({ type: actions.GETTING_NETWORK_CONTACT_LIST });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_NETWORK_CONTACT_LIST, contactList: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetContactListFailure, "contact list");
      dispatch({ type: actions.FAILED_GET_NETWORK_CONTACT_LIST });
      throw error;
    });
};

const getContactList = accountingGroupId => (dispatch, getState) => {
  let state = getState().network;
  let fetchedContactList =
    state.gettingNetworkContactList || state.gotNetworkContactList || state.failedGetNetworkContactList;

  if (!fetchedContactList) {
    return dispatch(fetchContactList(accountingGroupId));
  }

  return state.contactList;
};

const setContactListFilter = (accountingGroupId, filter) => (dispatch, getState) => {
  dispatch(
    fetchContactList(accountingGroupId, {
      top: getState().general.pageRowCount,
      skip: 0,
      filter
    })
  );
  dispatch({ type: actions.SET_NETWORK_CONTACT_LIST_LOADED_PAGE, selectedPage: 1 });
};

const fetchActivityList = (accountingGroupId, params = {}) => (dispatch, getState) => {
  let endpoint = `v3/api/${accountingGroupId}/networkactivity/list`;

  const { top, skip } = params;

  endpoint = `${endpoint}?$top=${top || getState().general.pageRowCount}`;
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }
  dispatch({ type: actions.GETTING_NETWORK_ACTIVITIES, skip });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_NETWORK_ACTIVITIES, activityList: response.data });
    })
    .catch(error => {
      handlePromiseError(error, "activity list failed", "activities list");
      dispatch({ type: actions.FAILED_GET_NETWORK_ACTIVITIES });
      throw error;
    });
};

const getActivityList = (accountingGroupId, params) => (dispatch, getState) => {
  let state = getState().network;
  let fetchedActivityList =
    state.gettingNetworkActivityList || state.gotNetworkActivityList || state.failedGetNetworkActivityList;

  if (!fetchedActivityList) {
    return dispatch(fetchActivityList(accountingGroupId, params));
  }

  return state.activityList;
};

const selectContactListPage = (accountingGroupId, selectedPage) => (dispatch, getState) => {
  dispatch(
    fetchContactList(accountingGroupId, {
      top: getState().general.pageRowCount,
      skip: (selectedPage - 1) * getState().general.pageRowCount
    })
  );
  dispatch({ type: actions.SET_NETWORK_CONTACT_LIST_LOADED_PAGE, selectedPage });
};

const fetchCustContact = accessId => dispatch => {
  dispatch({ type: actions.GETTING_CUST_CONTACT });
  let endpoint = `v3/api/global/contact/guest/${encodeURIComponent(accessId)}`;
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_CUST_CONTACT, custContact: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetCustContact, "customer contact data");
      dispatch({ type: actions.FAILED_GET_CUST_CONTACT });
      throw error;
    });
};

const fetchMyProfile = () => dispatch => {
  dispatch({ type: actions.GETTING_CUST_CONTACT });
  let endpoint = `v3/api/global/user/contact`;
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_CUST_CONTACT, custContact: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetCustContact, "customer contact data");
      dispatch({ type: actions.FAILED_GET_CUST_CONTACT });
      throw error;
    });
};

const postMyProfile = request => dispatch => {
  dispatch({ type: actions.POSTING_CUST_CONTACT });
  let endpoint = `v3/api/global/user/contact/update`;
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_CONTACT, custContact: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedPostCustContact, "customer contact data");
      dispatch({ type: actions.FAILED_POST_CUST_CONTACT });
      throw error;
    });
};

const postMyProfilePicture = file => dispatch => {
  let body = new FormData();
  body.append("file", file);
  dispatch({ type: actions.POSTING_MY_PROFILE_PICTURE });
  let endpoint = `v3/api/global/user/contact/uploaddocument`;
  return data
    .post(endpoint, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({ type: actions.POSTED_MY_PROFILE_PICTURE, myProfilePicture: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.UploadingImageFailed, "customer profile picture");
      dispatch({ type: actions.FAILED_POST_MY_PROFILE_PICTURE });
      throw error;
    });
};

const postCustContact = (accessId, request) => dispatch => {
  dispatch({ type: actions.POSTING_CUST_CONTACT });
  let endpoint = `v3/api/global/contact/update/guest/${encodeURIComponent(accessId)}`;
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_CONTACT, custContact: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedPostCustContact, "customer contact data");
      dispatch({ type: actions.FAILED_POST_CUST_CONTACT });
      throw error;
    });
};

const postCustContactPicture = (accessId, mode, file) => dispatch => {
  let body = new FormData();
  body.append("file", file);
  dispatch({ type: actions.POSTING_CUST_CONTACT_PICTURE });
  let endpoint = `v3/api/global/contact/uploaddocument/${encodeURIComponent(accessId)}`;
  return data
    .post(endpoint, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_CONTACT_PICTURE, custContactPicture: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.UploadingImageFailed, "customer profile picture");
      dispatch({ type: actions.FAILED_POST_CUST_CONTACT_PICTURE });
      throw error;
    });
};

const fetchCustAccount = (accessId, mode) => dispatch => {
  dispatch({ type: actions.GETTING_CUST_ACCOUNT });
  let endpoint = "";
  if (mode === "ap") {
    endpoint = `v3/api/global/company/approfile/guest/${encodeURIComponent(accessId)}`;
  } else {
    endpoint = `v3/api/global/company/arprofile/guest/${encodeURIComponent(accessId)}`;
  }
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_CUST_ACCOUNT, custAccount: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetCustAccount, "account");
      dispatch({ type: actions.FAILED_GET_CUST_ACCOUNT });
      throw error;
    });
};

const fetchCustAccountContacts = (accessId, mode) => dispatch => {
  dispatch({ type: actions.GETTING_CUST_ACCOUNT_CONTACTS });
  let endpoint = "";
  if (mode === "ap") {
    endpoint = `v3/api/global/company/approfile/contacts/guest/${encodeURIComponent(accessId)}`;
  } else {
    endpoint = `v3/api/global/company/arprofile/contacts/guest/${encodeURIComponent(accessId)}`;
  }
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_CUST_ACCOUNT_CONTACTS, custAccountContacts: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetCustAccount, "account");
      dispatch({ type: actions.FAILED_GET_CUST_ACCOUNT_CONTACTS });
      throw error;
    });
};

const fetchCustAccountDocuments = (accessId, mode) => dispatch => {
  dispatch({ type: actions.GETTING_CUST_ACCOUNT_DOCUMENTS });
  let endpoint = "";
  if (mode === "ap") {
    endpoint = `v3/api/global/company/approfile/documents/guest/${encodeURIComponent(accessId)}`;
  } else {
    endpoint = `v3/api/global/company/arprofile/documents/guest/${encodeURIComponent(accessId)}`;
  }
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_CUST_ACCOUNT_DOCUMENTS, custAccountDocuments: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetCustAccount, "account");
      dispatch({ type: actions.FAILED_GET_CUST_ACCOUNT_DOCUMENTS });
      throw error;
    });
};

const fetchOpenOnboardingRequests = (accountingGroupId, params = {}) => (dispatch, getState) => {
  if (getState().network.gettingOpenOnboardingRequests) {
    return false;
  }

  let endpoint = `v3/api/activity/${accountingGroupId}/OnboardingRequest/open`;

  const { top, skip } = params;

  endpoint = `${endpoint}?$top=${top || getState().general.pageRowCount}`;
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }

  dispatch({ type: actions.GETTING_OPEN_ONBOARDING_REQUESTS });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_OPEN_ONBOARDING_REQUESTS, data: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetContactListFailure, "onboarding requests");
      dispatch({ type: actions.FAILED_GET_OPEN_ONBOARDING_REQUESTS });
      throw error;
    });
};

const getOpenOnboardingRequests = accountingGroupId => (dispatch, getState) => {
  const state = getState().network;
  let fetchedOpenOnboardingRequests =
    state.gettingOpenOnboardingRequests || state.gotOpenOnboardingRequests || state.failedGetOpenOnboardingRequests;
  if (!fetchedOpenOnboardingRequests) {
    return dispatch(fetchOpenOnboardingRequests(accountingGroupId));
  }
  return state.openOnboardingRequests;
};

const selectOpenOnboardingRequestsPage = (accountingGroupId, selectedPage) => (dispatch, getState) => {
  return dispatch(
    fetchOpenOnboardingRequests(accountingGroupId, {
      top: getState().general.pageRowCount,
      skip: (selectedPage - 1) * getState().general.pageRowCount
    })
  );
};

const fetchOpenProfileUpdateRequests = (accountingGroupId, params = {}) => (dispatch, getState) => {
  if (getState().network.gettingOpenProfileUpdateRequests) {
    return false;
  }

  let endpoint = `v3/api/activity/${accountingGroupId}/ProfileUpdateRequest/open`;

  const { top, skip } = params;

  endpoint = `${endpoint}?$top=${top || getState().general.pageRowCount}`;
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }

  dispatch({ type: actions.GETTING_OPEN_PROFILE_UPDATE_REQUESTS });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_OPEN_PROFILE_UPDATE_REQUESTS, data: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetContactListFailure, "profile update requests");
      dispatch({ type: actions.FAILED_GET_OPEN_PROFILE_UPDATE_REQUESTS });
      throw error;
    });
};
const getOpenProfileUpdateRequests = accountingGroupId => (dispatch, getState) => {
  const state = getState().network;
  let fetchedOpenProfileUpdateRequests =
    state.gettingOpenProfileUpdateRequests ||
    state.gotOpenProfileUpdateRequests ||
    state.failedGetOpenProfileUpdateRequests;

  if (!fetchedOpenProfileUpdateRequests) {
    return dispatch(fetchOpenProfileUpdateRequests(accountingGroupId));
  }
  return state.openProfileUpdateRequests;
};

const selectOpenProfileUpdateRequestsPage = (accountingGroupId, selectedPage) => (dispatch, getState) => {
  return dispatch(
    fetchOpenProfileUpdateRequests(accountingGroupId, {
      top: getState().general.pageRowCount,
      skip: (selectedPage - 1) * getState().general.pageRowCount
    })
  );
};
const postCustAccount = (accessId, mode, request) => dispatch => {
  dispatch({ type: actions.POSTING_CUST_ACCOUNT });
  let endpoint = "";
  if (mode === "ar") {
    endpoint = `v3/api/global/company/arprofile/update/guest/${encodeURIComponent(accessId)}`;
  } else {
    endpoint = `v3/api/global/company/approfile/update/guest/${encodeURIComponent(accessId)}`;
  }
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_ACCOUNT, custAccount: request });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedPostCustAccount, "post cust account");
      dispatch({ type: actions.FAILED_POST_CUST_ACCOUNT });
      throw error;
    });
};

const updateCustContact = (accountingGroupKey, lockstepContactKey, mode, request) => dispatch => {
  dispatch({ type: actions.POSTING_CUST_CONTACT });
  let endpoint = "";
  if (mode === "ap") {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/vendorcontact/${lockstepContactKey}/update`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/customercontact/${lockstepContactKey}/update`;
  }
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_CONTACT, custContact: request });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedPostCustAccount, "post cust contact", () =>
        raiseValidationError(error, Resources.FailedPostCustAccount)
      );
      dispatch({ type: actions.FAILED_POST_CUST_CONTACT });
      throw error;
    });
};

const postCustAccountKeyContact = (accessId, request, mode) => dispatch => {
  dispatch({ type: actions.POSTING_CUST_ACCOUNT });
  let endpoint = "";
  if (mode === "ar") {
    endpoint = `v3/api/global/company/arprofile/contacts/update/guest/${encodeURIComponent(accessId)}`;
  } else {
    endpoint = `v3/api/global/company/approfile/contacts/update/guest/${encodeURIComponent(accessId)}`;
  }
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_ACCOUNT, custAccount: request });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedPostCustAccount, "post cust account");
      dispatch({ type: actions.FAILED_POST_CUST_ACCOUNT });
      throw error;
    });
};

const postCustAccountKeyDocuments = (accessId, mode, request) => dispatch => {
  dispatch({ type: actions.POSTING_CUST_ACCOUNT });
  let endpoint = ``;
  if (mode === "ar") {
    endpoint = `v3/api/global/company/arprofile/documents/update/guest/${encodeURIComponent(accessId)}`;
  } else {
    endpoint = `v3/api/global/company/approfile/documents/update/guest/${encodeURIComponent(accessId)}`;
  }
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_ACCOUNT, custAccount: request });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedPostCustAccount, "post cust account");
      dispatch({ type: actions.FAILED_POST_CUST_ACCOUNT });
      throw error;
    });
};

const postCustAccountPicture = (accessId, mode, file) => dispatch => {
  let body = new FormData();
  body.append("file", file);
  dispatch({ type: actions.POSTING_CUST_ACCOUNT_PICTURE });
  let endpoint = `v3/api/global/company/uploaddocument/${encodeURIComponent(accessId)}`;
  return data
    .post(endpoint, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_ACCOUNT_PICTURE, custAccountPicture: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.UploadingImageFailed, "account profile picture");
      dispatch({ type: actions.FAILED_POST_CUST_ACCOUNT_PICTURE });
      throw error;
    });
};

const fetchAccountProfile = (accountingGroupId, accountKey) => (dispatch, getState) => {
  if (getState().network.gettingAccountProfiles[accountKey] || isEmpty(accountingGroupId) || isEmpty(accountKey)) {
    return false;
  }

  // 1 = vendors, 2 = customers
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendor/${accountKey}/arprofile`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customer/${accountKey}/arprofile`;
  }

  dispatch({ type: actions.GETTING_ACCOUNT_PROFILE, accountKey });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_ACCOUNT_PROFILE, accountProfile: response.data, accountKey });
      return response;
    })
    .catch(rejection => {
      handlePromiseError(rejection, Resources.FailedGetCustAccount, "customer account");
      dispatch({ type: actions.GET_ACCOUNT_PROFILE_FAILED, accountKey });
      throw rejection;
    });
};

const getAccountProfile = (accountingGroupId, custKey) => (dispatch, getState) => {
  if (isEmpty(custKey)) {
    return false;
  }
  let state = getState();

  if (!isEmpty(state.network.accountsMap[custKey])) {
    if (!state.network.gotCustomerAccounts[custKey] && !state.network.failedGetCustomerAccounts[custKey]) {
      dispatch(fetchAccountProfile(accountingGroupId, custKey));
    }
    return state.network.accountsMap[custKey];
  }

  // Look through search results to see if the account is there
  let searchAccount = find(state.search.values.customers, customer => custKey === customer.entityGlobalKey.toString());
  if (!isEmpty(searchAccount)) {
    dispatch(fetchAccountProfile(accountingGroupId, custKey));
    return searchAccount;
  }

  if (!state.network.gotCustomerAccounts[custKey] && !state.network.failedGetCustomerAccounts[custKey]) {
    return dispatch(fetchAccountProfile(accountingGroupId, custKey));
  } else {
    return null;
  }
};

const fetchAccountDocuments = (accountingGroupId, accountKey) => (dispatch, getState) => {
  if (getState().network.gettingAccountDocuments[accountKey] || isEmpty(accountingGroupId) || isEmpty(accountKey)) {
    return false;
  }

  // 1 = vendors, 2 = customers
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendor/${accountKey}/documents`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customer/${accountKey}/documents`;
  }

  dispatch({ type: actions.GETTING_ACCOUNT_DOCUMENTS, accountKey });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_ACCOUNT_DOCUMENTS, accountDocuments: response.data.documents, accountKey });
      return response;
    })
    .catch(rejection => {
      handlePromiseError(rejection, Resources.FailedGetCustAccountDocuments, "account documents");
      dispatch({ type: actions.GET_ACCOUNT_DOCUMENTS_FAILED, accountKey });
      throw rejection;
    });
};

const getAccountDocuments = (accountingGroupId, accountKey) => (dispatch, getState) => {
  if (isEmpty(accountKey)) {
    return false;
  }
  let state = getState();

  if (state.network.accountDocumentsMap[accountKey] !== undefined) {
    return state.network.accountDocumentsMap[accountKey];
  }

  if (
    state.network.gettingAccountDocuments[accountKey] ||
    state.network.gotAccountDocuments[accountKey] ||
    state.network.failedGetAccountDocuments[accountKey]
  ) {
    return null;
  }

  dispatch(fetchAccountDocuments(accountingGroupId, accountKey));
  return null;
};

const fetchAccountContacts = (accountingGroupId, accountKey) => (dispatch, getState) => {
  if (getState().network.gettingAccountContacts[accountKey] || isEmpty(accountingGroupId) || isEmpty(accountKey)) {
    return false;
  }

  // 1 = vendors, 2 = customers
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendor/${accountKey}/vendorcontacts`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customer/${accountKey}/customercontacts`;
  }

  dispatch({ type: actions.GETTING_ACCOUNT_CONTACTS, accountKey });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_ACCOUNT_CONTACTS, accountContacts: response.data, accountKey });
      return response;
    })
    .catch(rejection => {
      handlePromiseError(rejection, Resources.FailedGetCustAccountContacts, "account contacts");
      dispatch({ type: actions.GET_ACCOUNT_CONTACTS_FAILED, accountKey });
      throw rejection;
    });
};

const fetchAccountArchivedContacts = (accountingGroupId, accountKey) => (dispatch, getState) => {
  if (getState().network.gettingAccountContacts[accountKey] || isEmpty(accountingGroupId) || isEmpty(accountKey)) {
    return false;
  }

  // 1 = vendors, 2 = customers
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendor/${accountKey}/archivedvendorcontacts`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customer/${accountKey}/archivedcustomercontacts`;
  }

  dispatch({ type: actions.GETTING_ACCOUNT_ARCHIVED_CONTACTS, accountKey });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_ACCOUNT_ARCHIVED_CONTACTS, accountArchivedContacts: response.data, accountKey });
      return response;
    })
    .catch(rejection => {
      handlePromiseError(rejection, Resources.FailedGetCustAccountContacts, "account archived contacts");
      dispatch({ type: actions.GET_ACCOUNT_ARCHIVED_CONTACTS_FAILED, accountKey });
      throw rejection;
    });
};

const getAccountContacts = (accountingGroupId, accountKey) => (dispatch, getState) => {
  if (isEmpty(accountKey)) {
    return false;
  }
  let state = getState();

  if (state.network.accountContactsMap[accountKey] !== undefined) {
    return state.network.accountContactsMap[accountKey];
  }

  if (
    state.network.gettingAccountContacts[accountKey] ||
    state.network.gotAccountContacts[accountKey] ||
    state.network.failedGetAccountContacts[accountKey]
  ) {
    return null;
  }

  dispatch(fetchAccountContacts(accountingGroupId, accountKey));
  return null;
};

const getAccountArchivedContacts = (accountingGroupId, accountKey) => (dispatch, getState) => {
  if (isEmpty(accountKey)) {
    return false;
  }
  let state = getState();

  if (state.network.accountArchivedContactsMap[accountKey] !== undefined) {
    return state.network.accountArchivedContactsMap[accountKey];
  }

  if (
    state.network.gettingAccountArchivedContacts[accountKey] ||
    state.network.gotAccountArchivedContacts[accountKey] ||
    state.network.failedGetAccountArchivedContacts[accountKey]
  ) {
    return null;
  }

  dispatch(fetchAccountArchivedContacts(accountingGroupId, accountKey));
  return null;
};

const fetchContactProfile = (accountingGroupId, contactKey) => (dispatch, getState) => {
  if (getState().network.gettingContactProfiles[contactKey] || isEmpty(accountingGroupId) || isEmpty(contactKey)) {
    return false;
  }

  // 1 = vendors, 2 = customers
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendorcontact/${contactKey}`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customercontact/${contactKey}`;
  }

  dispatch({ type: actions.GETTING_CONTACT_PROFILE, contactKey });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_CONTACT_PROFILE, customerContact: response.data, contactKey });
      return response;
    })
    .catch(rejection => {
      handlePromiseError(rejection, Resources.FailedGetCustContact, "customer contact");
      dispatch({ type: actions.GOT_CONTACT_PROFILE_FAILED, contactKey });
      throw rejection;
    });
};

const getContactProfile = (accountingGroupId, contactKey) => (dispatch, getState) => {
  if (isEmpty(contactKey)) {
    return false;
  }
  let state = getState();

  if (state.network.contactsMap[contactKey] !== undefined) {
    if (!state.network.gotCustomerContacts[contactKey] && !state.network.failedGetCustomerContacts[contactKey]) {
      dispatch(fetchContactProfile(accountingGroupId, contactKey));
    }
    return state.network.contactsMap[contactKey];
  }

  // Look through search results to check if the contact is there
  let searchContact = find(
    state.search.values.customerContacts,
    contact => contactKey === contact.entityGlobalKey.toString()
  );
  if (!isEmpty(searchContact)) {
    dispatch(fetchContactProfile(accountingGroupId, contactKey));
    return searchContact;
  }

  return dispatch(fetchContactProfile(accountingGroupId, contactKey));
};

const sendAccountProfileUpdateRequest = accountKeys => (dispatch, getState) => {
  if (isEmpty(accountKeys) || getState().network.postingAccountProfileUpdateRequest) {
    return false;
  }
  let body = accountKeys;
  dispatch({ type: actions.POSTING_ACCOUNT_PROFILE_UPDATE_REQUEST, accountKeys });
  return data
    .post(`v3/api/updaterequest/profile`, body)
    .then(response => {
      dispatch({ type: actions.POSTED_ACCOUNT_PROFILE_UPDATE_REQUEST, accountKeys });
      return response;
    })
    .catch(rejection => {
      handlePromiseError(rejection, "TODO: Sending account profile update request failed.", "account profile request");
      dispatch({ type: actions.FAILED_POST_ACCOUNT_PROFILE_UPDATE_REQUEST, accountKeys });
      throw rejection;
    });
};

const sendContactProfileUpdateRequest = contactKeys => (dispatch, getState) => {
  if (isEmpty(contactKeys) || getState().network.postingContactProfileUpdateRequest) {
    return false;
  }
  let endpoint = `v3/api/updaterequest/contact`;
  let body = contactKeys;
  dispatch({ type: actions.POSTING_CONTACT_PROFILE_UPDATE_REQUEST, contactKeys });
  return data
    .post(endpoint, body)
    .then(response => {
      dispatch({ type: actions.POSTED_CONTACT_PROFILE_UPDATE_REQUEST, contactKeys });
      return response;
    })
    .catch(rejection => {
      handlePromiseError(rejection, "TODO: Sending contact profile update request failed.", "contact profile request");
      dispatch({ type: actions.FAILED_POST_CONTACT_PROFILE_UPDATE_REQUEST, contactKeys });
      throw rejection;
    });
};

const fetchHeaderInfo = accessId => dispatch => {
  dispatch({ type: actions.GETTING_HEADER_INFO, accessId });
  return data
    .get(`v3/api/global/account/headerinfo/guest/${encodeURIComponent(accessId)}`)
    .then(response => {
      dispatch({ type: actions.GOT_HEADER_INFO, headerInfo: response.data });
      return response;
    })
    .catch(rejection => {
      handlePromiseError(rejection, "TODO: Geting header info failed", "header info");
      dispatch({ type: actions.FAILED_GET_HEADER_INFO });
      throw rejection;
    });
};

const fetchAccountSearchResults = (accountingGroupId, params = {}) => (dispatch, getState) => {
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  let searchAttribute;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendor/list`;
    searchAttribute = "VendorName";
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customer/list`;
    searchAttribute = "CustName";
  }

  const { top = getState().general.pageRowCount, skip = 0, searchTerm } = params;

  if (!isEmpty(top)) {
    endpoint = `${endpoint}?$top=${top}`;
  }
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }
  if (!isEmpty(searchTerm)) {
    endpoint = `${endpoint}&$filter=${searchAttribute}%20LIKE%20"${searchTerm}"`;
  }

  dispatch({ type: actions.GETTING_NETWORK_ACCOUNT_SEARCH_RESULTS, searchTerm });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_NETWORK_ACCOUNT_SEARCH_RESULTS, searchResults: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetAccountListFailure, "account list");
      dispatch({ type: actions.FAILED_GET_NETWORK_ACCOUNT_SEARCH_RESULTS });
      throw error;
    });
};

const debouncedFetchAccountSearchResultsGet = debounce((accountingGroupId, params = {}, dispatch, getState) => {
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  let searchAttribute;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendor/list`;
    searchAttribute = "VendorName";
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customer/list`;
    searchAttribute = "CustName";
  }

  const { top = getState().general.pageRowCount, skip = 0, searchTerm } = params;

  if (!isEmpty(top)) {
    endpoint = `${endpoint}?$top=${top}`;
  }
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }
  if (!isEmpty(searchTerm)) {
    endpoint = `${endpoint}&$filter=${searchAttribute}%20LIKE%20"${searchTerm}"`;
  }

  dispatch({ type: actions.GETTING_NETWORK_ACCOUNT_SEARCH_RESULTS, searchTerm: searchTerm });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_NETWORK_ACCOUNT_SEARCH_RESULTS, searchResults: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetAccountListFailure, "account list");
      dispatch({ type: actions.FAILED_GET_NETWORK_ACCOUNT_SEARCH_RESULTS });
      throw error;
    });
}, 400);

const debouncedFetchAccountSearchResults = (accountingGroupId, params) => (dispatch, getState) => {
  const store = getState().network;
  if (store.gettingNetworkAccountSearchResults && _.isEqual(params.searchTerm, store.accountSearchTerm)) {
    return;
  }
  debouncedFetchAccountSearchResultsGet(accountingGroupId, params, dispatch, getState);
};

const fetchContactSearchResults = (accountingGroupId, params = {}) => (dispatch, getState) => {
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  let searchAttribute;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendorcontact/list`;
    searchAttribute = "ContactName";
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customercontact/list`;
    searchAttribute = "ContactName";
  }

  const { top = getState().general.pageRowCount, skip = 0, searchTerm } = params;

  if (!isEmpty(top)) {
    endpoint = `${endpoint}?$top=${top}`;
  }
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }
  if (!isEmpty(searchTerm)) {
    endpoint = `${endpoint}&$filter=${searchAttribute}%20LIKE%20"${searchTerm}"`;
  }

  dispatch({ type: actions.GETTING_NETWORK_CONTACT_SEARCH_RESULTS, searchTerm });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_NETWORK_CONTACT_SEARCH_RESULTS, searchResults: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetAccountListFailure, "account list");
      dispatch({ type: actions.FAILED_GET_NETWORK_CONTACT_SEARCH_RESULTS });
      throw error;
    });
};

const debouncedFetchContactSearchResultsGet = debounce((accountingGroupId, params = {}, dispatch, getState) => {
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  let searchAttribute;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendorcontact/list`;
    searchAttribute = "ContactName";
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customercontact/list`;
    searchAttribute = "ContactName";
  }

  const { top = getState().general.pageRowCount, skip = 0, searchTerm } = params;

  if (!isEmpty(top)) {
    endpoint = `${endpoint}?$top=${top}`;
  }
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }
  if (!isEmpty(searchTerm)) {
    endpoint = `${endpoint}&$filter=${searchAttribute}%20LIKE%20"${searchTerm}"`;
  }

  dispatch({ type: actions.GETTING_NETWORK_CONTACT_SEARCH_RESULTS, searchTerm: searchTerm });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_NETWORK_CONTACT_SEARCH_RESULTS, searchResults: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetAccountListFailure, "account list");
      dispatch({ type: actions.FAILED_GET_NETWORK_CONTACT_SEARCH_RESULTS });
      throw error;
    });
}, 400);

const debouncedFetchContactSearchResults = (accountingGroupId, params) => (dispatch, getState) => {
  const store = getState().network;
  if (store.gettingNetworkContactSearchResults && _.isEqual(params.searchTerm, store.contactSearchTerm)) {
    return;
  }
  debouncedFetchContactSearchResultsGet(accountingGroupId, params, dispatch, getState);
};

const fetchAccountingGroupProfile = (accountingGroupKey, companyGlobalKey, mode) => dispatch => {
  dispatch({ type: actions.GETTING_CUST_ACCOUNT });
  let endpoint = "";
  if (mode === "ap") {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/company/${companyGlobalKey}/approfile`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/company/${companyGlobalKey}/arprofile`;
  }
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_CUST_ACCOUNT, custAccount: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetCustAccount, "account");
      dispatch({ type: actions.FAILED_GET_CUST_ACCOUNT });
      throw error;
    });
};

const fetchAccountingGroupProfileContacts = (accountingGroupKey, companyGlobalKey, mode) => dispatch => {
  dispatch({ type: actions.GETTING_CUST_ACCOUNT_CONTACTS });
  let endpoint = "";
  if (mode === "ap") {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/company/${companyGlobalKey}/approfile/contacts`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/company/${companyGlobalKey}/arprofile/contacts`;
  }
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_CUST_ACCOUNT_CONTACTS, custAccountContacts: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetCustAccountContacts, "account");
      dispatch({ type: actions.FAILED_GET_CUST_ACCOUNT_CONTACTS });
      throw error;
    });
};

const fetchAccountingGroupProfileDocuments = (accountingGroupKey, companyGlobalKey, mode) => dispatch => {
  dispatch({ type: actions.GETTING_CUST_ACCOUNT_DOCUMENTS });
  let endpoint = "";
  if (mode === "ap") {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/company/${companyGlobalKey}/approfile/documents`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/company/${companyGlobalKey}/arprofile/documents`;
  }
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_CUST_ACCOUNT_DOCUMENTS, custAccountDocuments: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetCustAccountDocuments, "account");
      dispatch({ type: actions.FAILED_GET_CUST_ACCOUNT_DOCUMENTS });
      throw error;
    });
};

const postAccountingGroupProfile = (accountingGroupKey, companyGlobalKey, mode, request) => dispatch => {
  dispatch({ type: actions.POSTING_CUST_ACCOUNT });
  let endpoint = "";
  if (mode === "ar") {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/company/${companyGlobalKey}/arprofile/update`;
  } else {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/company/${companyGlobalKey}/approfile/update`;
  }
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_ACCOUNT, custAccount: request });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedPostCustAccount, "post cust account");
      dispatch({ type: actions.FAILED_POST_CUST_ACCOUNT });
      throw error;
    });
};

const updateAccountingGroupProfile = (accountingGroupKey, accountId, mode, request) => dispatch => {
  dispatch({ type: actions.POSTING_CUST_ACCOUNT });
  let endpoint = "";
  if (mode === "ar") {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/vendor/${accountId}/arprofile/update`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/customer/${accountId}/arprofile/update`;
  }
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_ACCOUNT, custAccount: request });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedPostCustAccount, "post cust account", () =>
        raiseValidationError(error, Resources.FailedPostCustAccount)
      );
      dispatch({ type: actions.FAILED_POST_CUST_ACCOUNT });
      throw error;
    });
};

const postAccountingGroupProfileContacts = (accountingGroupKey, companyGlobalKey, mode, request) => dispatch => {
  dispatch({ type: actions.POSTING_CUST_ACCOUNT });
  let endpoint = "";
  if (mode === "ar") {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/company/${companyGlobalKey}/arprofile/contact/update`;
  } else {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/company/${companyGlobalKey}/approfile/contact/update`;
  }
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_ACCOUNT, custAccountContacts: request });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedPostCustAccount, "post cust account");
      dispatch({ type: actions.FAILED_POST_CUST_ACCOUNT });
      throw error;
    });
};

const postAccountingGroupProfileDocuments = (accountingGroupKey, companyGlobalKey, mode, request) => dispatch => {
  dispatch({ type: actions.POSTING_CUST_ACCOUNT });
  let endpoint = "";
  if (mode === "ar") {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/company/${companyGlobalKey}/arprofile/documents/update`;
  } else {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/company/${companyGlobalKey}/approfile/documents/update`;
  }
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_ACCOUNT, custAccountDocuments: request });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedPostCustAccount, "post cust account");
      dispatch({ type: actions.FAILED_POST_CUST_ACCOUNT });
      throw error;
    });
};

const fetchAccountSettings = accountingGroupKey => dispatch => {
  dispatch({ type: actions.GETTING_CUST_ACCOUNT_SETTINGS });
  let endpoint = `v3/api/${accountingGroupKey}/settings`;
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_CUST_ACCOUNT_SETTINGS, custAccountSettings: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetCustAccount, "account");
      dispatch({ type: actions.FAILED_GET_CUST_ACCOUNT_SETTINGS });
      throw error;
    });
};

const postAccountSettings = (accountingGroupKey, request) => dispatch => {
  dispatch({ type: actions.POSTING_CUST_ACCOUNT_SETTINGS });
  let endpoint = `v3/api/${accountingGroupKey}/settings`;
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_ACCOUNT_SETTINGS, custAccountSettings: request });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedPostCustAccount, "post cust account");
      dispatch({ type: actions.FAILED_POST_CUST_ACCOUNT_SETTINGS });
      throw error;
    });
};

const uploadAccountingGroupDocument = (accountingGroupKey, companyGlobalKey, mode, file) => dispatch => {
  let body = new FormData();
  body.append("file", file);
  dispatch({ type: actions.POSTING_CUST_ACCOUNT_PICTURE });
  let endpoint = ``;
  if (mode === "ar") {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/company/${companyGlobalKey}/arprofile/uploaddocument`;
  } else {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/company/${companyGlobalKey}/approfile/uploaddocument`;
  }
  return data
    .post(endpoint, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_ACCOUNT_PICTURE, custAccountPicture: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.UploadingImageFailed, "account profile picture");
      dispatch({ type: actions.FAILED_POST_CUST_ACCOUNT_PICTURE });
      throw error;
    });
};

const addAccountingGroupDocument = (accountingGroupKey, vendorGlobalKey, mode, file) => dispatch => {
  let body = new FormData();
  body.append("file", file);
  dispatch({ type: actions.UPLOADING_ACCOUNT_DOCUMENT });
  let endpoint = ``;
  if (mode === "ar") {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/customer/${vendorGlobalKey}/arprofile/uploaddocument`;
  } else {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/vendor/${vendorGlobalKey}/approfile/uploaddocument`;
  }
  return data
    .post(endpoint, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({ type: actions.UPLOADED_ACCOUNT_DOCUMENT, uploadedAccountDocument: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.UploadingDocument, "account document");
      dispatch({ type: actions.FAILED_UPLOAD_ACCOUNT_DOCUMENT });
      throw error;
    });
};

const fetchEmailAttachments = (accountingGroupKey, companyGlobalKey, entityKey) => (dispatch, getState) => {
  // 1 = vendors, 2 = customers
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;

  let endpoint;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/activity/${accountingGroupKey}/company/${companyGlobalKey}/vendor/${entityKey}`;
  } else {
    endpoint = `v3/api/activity/${accountingGroupKey}/company/${companyGlobalKey}/customer/${entityKey}`;
  }

  dispatch({ type: actions.GETTING_EMAIL_ATACHMENTS, entityKey });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_EMAIL_ATACHMENTS, emailAtachments: response.data, entityKey });
      return response;
    })
    .catch(rejection => {
      handlePromiseError(rejection, Resources.FailedGetCustAccountDocuments, "account documents");
      dispatch({ type: actions.FAILED_GET_EMAIL_ATACHMENTS, entityKey });
      throw rejection;
    });
};

const getEmailAttachments = (accountingGroupKey, companyGlobalKey, entityKey) => (dispatch, getState) => {
  if (isEmpty(entityKey)) {
    return false;
  }
  let state = getState();

  if (state.network.emailAttachments !== undefined) {
    return state.network.emailAttachments.value;
  }

  if (
    state.network.gettingEmailAttachments ||
    state.network.gotEmailAttachments ||
    state.network.failedGetEmailAttachments
  ) {
    return null;
  }

  dispatch(fetchEmailAttachments(accountingGroupKey, companyGlobalKey, entityKey));
  return null;
};

const updateDocumentType = (accountingGroupKey, entityKey, request) => (dispatch, getState) => {
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;

  dispatch({ type: actions.UPDATING_DOCUMENT_TYPE });
  let endpoint = "";
  if (accountingGroupType === "customers") {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/customer/${entityKey}/arprofile/documenttype`;
  } else {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/vendor/${entityKey}/approfile/documenttype`;
  }
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.UPDATED_DOCUMENT_TYPE, updatedDocumentType: request });
      return response;
    })
    .catch(error => {
      handlePromiseError(error, "Failed to update document type", "document type");
      dispatch({ type: actions.FAILED_UPDATE_DOCUMENT_TYPE });
      throw error;
    });
};

const deleteDocuments = (accountingGroupKey, entityKey, request) => (dispatch, getState) => {
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;

  dispatch({ type: actions.DELETING_DOCUMENT });
  let endpoint = "";
  if (accountingGroupType === "customers") {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/customer/${entityKey}/arprofile/deletedocuments`;
  } else {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/vendor/${entityKey}/approfile/deletedocuments`;
  }
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.DELETED_DOCUMENT, deletedDocuments: request });
      return response;
    })
    .catch(error => {
      handlePromiseError(error, "Failed to update document type", "document type");
      dispatch({ type: actions.FAILED_DELETE_DOCUMENT });
      throw error;
    });
};

const updateEmailAttachmentType = (accountingGroupKey, companyKey, request) => (dispatch, getState) => {
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;

  dispatch({ type: actions.UPDATING_DOCUMENT_TYPE });
  let endpoint = "";
  if (accountingGroupType === "customers") {
    endpoint = `v3/api/activity/${accountingGroupKey}/company/${companyKey}/customer/attachmenttype`;
  } else {
    endpoint = `v3/api/activity/${accountingGroupKey}/company/${companyKey}/vendor/attachmenttype`;
  }
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.UPDATED_DOCUMENT_TYPE, updatedDocumentType: request });
      return response;
    })
    .catch(error => {
      handlePromiseError(error, "Failed to update document type", "document type");
      dispatch({ type: actions.FAILED_UPDATE_DOCUMENT_TYPE });
      throw error;
    });
};

const deleteAttachments = (accountingGroupKey, companyKey, request) => (dispatch, getState) => {
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;

  dispatch({ type: actions.DELETING_DOCUMENT });
  let endpoint = "";
  if (accountingGroupType === "customers") {
    endpoint = `v3/api/activity/${accountingGroupKey}/company/${companyKey}/customer/deleteattachments`;
  } else {
    endpoint = `v3/api/activity/${accountingGroupKey}/company/${companyKey}/vendor/deleteattachments`;
  }
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.DELETED_DOCUMENT, updatedDocumentType: request });
      return response;
    })
    .catch(error => {
      handlePromiseError(error, "Failed to delete documents", "document type");
      dispatch({ type: actions.FAILED_DELETE_DOCUMENT });
      throw error;
    });
};

const downloadAttachment = (accountingGroupKey, companyKey, entityKey, attachmentKey, signature) => (
  dispatch,
  getState
) => {
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;

  dispatch({ type: actions.DOWNLOADING_ATTACHMENT });
  let endpoint = "";
  if (accountingGroupType === "customers") {
    endpoint = `v3/api/activity/${accountingGroupKey}/company/${companyKey}/customer/${entityKey}/${attachmentKey}/${signature}`;
  } else {
    endpoint = `v3/api/activity/${accountingGroupKey}/company/${companyKey}/vendor/${entityKey}/${attachmentKey}/${signature}`;
  }
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.DOWNLOADED_ATTACHMENT, attachmentDownload: response.data });
      let config = getConfig();
      let downloadURL = config.apiEndpoint.concat("/").concat(endpoint);
      openUrlForDownload(downloadURL);
      return response;
    })
    .catch(error => {
      handlePromiseError(error, "Failed to download document", "document type");
      dispatch({ type: actions.FAILED_DOWNLOAD_ATTACHMENT });
      throw error;
    });
};

const fetchAttachmentSearchResults = (accountingGroupKey, companyGlobalKey, entityKey, params = {}) => (
  dispatch,
  getState
) => {
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  let searchAttribute = "FileName";
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/activity/${accountingGroupKey}/company/${companyGlobalKey}/vendor/${entityKey}`;
  } else {
    endpoint = `v3/api/activity/${accountingGroupKey}/company/${companyGlobalKey}/customer/${entityKey}`;
  }

  const { top = getState().general.pageRowCount, skip = 0, searchTerm } = params;

  if (!isEmpty(top)) {
    endpoint = `${endpoint}?$top=${top}`;
  }
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }
  if (!isEmpty(searchTerm)) {
    endpoint = `${endpoint}&$filter=${searchAttribute}%20LIKE%20"${searchTerm}"`;
  }

  dispatch({ type: actions.GETTING_NETWORK_ACCOUNT_SEARCH_RESULTS, searchTerm });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_NETWORK_ACCOUNT_SEARCH_RESULTS, searchResults: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetAccountListFailure, "account list");
      dispatch({ type: actions.FAILED_GET_NETWORK_ACCOUNT_SEARCH_RESULTS });
      throw error;
    });
};

const debouncedFetchAttachmentSearchResultsGet = debounce(
  (accountingGroupKey, companyGlobalKey, entityKey, params = {}, dispatch, getState) => {
    let accountingGroupType = getState().perspectives.selectedPerspectiveType;
    if (accountingGroupType === null) {
      return false;
    }

    let endpoint;
    let searchAttribute = "FileName";
    if (accountingGroupType === "vendors") {
      endpoint = `v3/api/activity/${accountingGroupKey}/company/${companyGlobalKey}/vendor/${entityKey}`;
    } else {
      endpoint = `v3/api/activity/${accountingGroupKey}/company/${companyGlobalKey}/customer/${entityKey}`;
    }

    const { top = getState().general.pageRowCount, skip = 0, searchTerm } = params;

    if (!isEmpty(top)) {
      endpoint = `${endpoint}?$top=${top}`;
    }
    if (!isEmpty(skip)) {
      endpoint = `${endpoint}&$skip=${skip}`;
    }
    if (!isEmpty(searchTerm)) {
      endpoint = `${endpoint}&$filter=${searchAttribute}%20LIKE%20"${searchTerm}"`;
    }

    dispatch({ type: actions.GETTING_NETWORK_ACCOUNT_SEARCH_RESULTS, searchTerm: searchTerm });
    return data
      .get(endpoint)
      .then(response => {
        dispatch({ type: actions.GOT_NETWORK_ACCOUNT_SEARCH_RESULTS, searchResults: response.data });
      })
      .catch(error => {
        handlePromiseError(error, Resources.GetAccountListFailure, "account list");
        dispatch({ type: actions.FAILED_GET_NETWORK_ACCOUNT_SEARCH_RESULTS });
        throw error;
      });
  },
  400
);

const debouncedFetchAttachmentSearchResults = (accountingGroupKey, companyGlobalKey, entityKey, params) => (
  dispatch,
  getState
) => {
  const store = getState().network;
  if (store.gettingNetworkAccountSearchResults && _.isEqual(params.searchTerm, store.accountSearchTerm)) {
    return;
  }
  debouncedFetchAttachmentSearchResultsGet(accountingGroupKey, companyGlobalKey, entityKey, params, dispatch, getState);
};

const uploadAccountingGroupDocuments = (accountingGroupKey, companyGlobalKey, mode, file) => dispatch => {
  let body = new FormData();
  body.append("file", file);
  dispatch({ type: actions.UPLOADING_ACCOUNT_DOCUMENT });
  let endpoint = ``;
  if (mode === "ar") {
    endpoint = `v3/api/wrkspcar/${accountingGroupKey}/company/${companyGlobalKey}/arprofile/uploaddocument`;
  } else {
    endpoint = `v3/api/wrkspcap/${accountingGroupKey}/company/${companyGlobalKey}/approfile/uploaddocument`;
  }
  return data
    .post(endpoint, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({ type: actions.UPLOADED_ACCOUNT_DOCUMENT, uploadedAccountDocument: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.UploadDocumentFailed, "account document");
      dispatch({ type: actions.FAILED_UPLOAD_ACCOUNT_DOCUMENT });
      throw error;
    });
};

const fetchNotes = entityGlobalKey => dispatch => {
  dispatch({ type: actions.GETTING_NOTES });
  let endpoint = `v3/api/global/${entityGlobalKey}/notes`;
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_NOTES, notes: response.data, entityGlobalKey: entityGlobalKey });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetNotes, "notes");
      dispatch({ type: actions.FAILED_GET_NOTES });
      throw error;
    });
};

const postNotes = (entityGlobalKey, request) => dispatch => {
  dispatch({ type: actions.POSTING_NOTES });
  let endpoint = `v3/api/global/${entityGlobalKey}/notes`;
  return data
    .post(endpoint, request)
    .then(response => {
      dispatch({ type: actions.POSTED_NOTES, notes: request });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedPostNotes, "post notes");
      dispatch({ type: actions.FAILED_POST_NOTES });
      throw error;
    });
};

const deleteNote = (entityGlobalKey, entityGlobalNoteKey) => dispatch => {
  dispatch({ type: actions.DELETING_NOTE });
  let endpoint = `v3/api/global/${entityGlobalKey}/notes/${entityGlobalNoteKey}`;
  return data
    .delete(endpoint)
    .then(response => {
      dispatch({ type: actions.DELETED_NOTE, notes: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedDeleteNote, "notes");
      dispatch({ type: actions.FAILED_DELETE_NOTE });
      throw error;
    });
};

const getShareFlyoutMessage = (accountingGroupType, entityGlobalKey) => dispatch => {
  dispatch({ type: actions.GETTING_SHARE_FLYOUT_MESSAGE });
  let endpoint = `v3/api/global/${accountingGroupType}/${entityGlobalKey}/share`;
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_SHARE_FLYOUT_MESSAGE, shareFlyout: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetShareFlyout, "share flyout");
      dispatch({ type: actions.FAILED_GET_SHARE_FLYOUT_MESSAGE });
      throw error;
    });
};

const fetchShareLink = (mode, entityGlobalKey) => dispatch => {
  dispatch({ type: actions.GETTING_SHARE_LINK });
  let endpoint = `v3/api/global/${mode}/${entityGlobalKey}/link`;
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_SHARE_LINK, shareLink: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetShareLink, "share link");
      dispatch({ type: actions.FAILED_GET_SHARE_LINK });
      throw error;
    });
};

const sendShareFlyout = (accountingGroupGlobalKey, companyGlobalKey, request) => dispatch => {
  dispatch({ type: actions.POSTING_SHARE_FLYOUT });
  let endpoint = `v3/api/activity/${accountingGroupGlobalKey}/company/${companyGlobalKey}/draft/EmailSend`;
  return data
    .post(endpoint, request)
    .then(response => {
      endpoint = `v3/api/activity/${accountingGroupGlobalKey}/company/${companyGlobalKey}/draft/EmailSend/${response.data}/commit`;

      return data
        .post(endpoint, request)
        .then(response => {
          dispatch({ type: actions.POSTED_SHARE_FLYOUT, session: response.data });
          return response;
        })
        .catch(error => {
          handlePromiseError(error, Resources.FailedSendMessage, "send message");
          dispatch({ type: actions.FAILED_POST_SHARE_FLYOUT });
          throw error;
        });
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedSendMessage, "send message");
      dispatch({ type: actions.FAILED_POST_SHARE_FLYOUT });
      throw error;
    });
};

const fetchSubsidiaryList = accountingGroupGlobalKey => dispatch => {
  dispatch({ type: actions.GETTING_SUBSIDIARY_LIST });
  let endpoint = `v3/api/global/accountinggroup/${accountingGroupGlobalKey}/company/selectlist`;
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_SUBSIDIARY_LIST, subsidiaryList: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetSubsidiaryList, "subsidiary list");
      dispatch({ type: actions.FAILED_GET_SUBSIDIARY_LIST });
      throw error;
    });
};

const updateContactPrivacy = (accountingGroupGlobalKey, contactKey, isPrivate, mode) => dispatch => {
  dispatch({ type: actions.UPDATING_CONTACT_PRIVACY });
  let endpoint;
  if (mode === "ap") {
    endpoint = `v3/api/wrkspcap/${accountingGroupGlobalKey}/vendorcontact/${contactKey}/update/publicprivatestatus/${isPrivate}`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupGlobalKey}/customercontact/${contactKey}/update/publicprivatestatus/${isPrivate}`;
  }
  return data
    .put(endpoint)
    .then(response => {
      dispatch({ type: actions.UPDATED_CONTACT_PRIVACY, madePrivate: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.FailedGetSubsidiaryList, "subsidiary list");
      dispatch({ type: actions.FAILED_UPDATE_CONTACT_PRIVACY });
      throw error;
    });
};

const uploadDocument = (entityGlobalKey, file) => dispatch => {
  let body = new FormData();
  body.append("file", file);
  dispatch({ type: actions.POSTING_CUST_ACCOUNT_PICTURE });
  let endpoint = `v3/api/global/${entityGlobalKey}/uploaddocument`;
  return data
    .post(endpoint, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      dispatch({ type: actions.POSTED_CUST_ACCOUNT_PICTURE, custAccountPicture: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, Resources.UploadingImageFailed, "account profile picture");
      dispatch({ type: actions.FAILED_POST_CUST_ACCOUNT_PICTURE });
      throw error;
    });
};

const toggleAccountArchived = (accountingGroupId, custKey, request, mode) => dispatch => {
  let endpoint = "";
  if (mode === "ap") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendor/${custKey}/arprofile/archive`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customer/${custKey}/arprofile/archive`;
  }
  dispatch({ type: actions.ARCHIVING_ACCOUNT });
  return data
    .post(endpoint, request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      dispatch({ type: actions.ARCHIVED_ACCOUNT, archivedAccount: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, "failed to archive account", "account archive");
      dispatch({ type: actions.FAILED_ARCHIVE_ACCOUNT });
      throw error;
    });
};

const toggleContactArchived = (accountingGroupId, custKey, request, mode) => dispatch => {
  let endpoint = "";
  if (mode === "ar") {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customercontact/${custKey}/archive`;
  } else {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendorcontact/${custKey}/archive`;
  }
  dispatch({ type: actions.ARCHIVING_CONTACT });
  return data
    .post(endpoint, request, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      dispatch({ type: actions.ARCHIVED_CONTACT, archivedContact: response.data });
      return response.data;
    })
    .catch(error => {
      handlePromiseError(error, "failed to archive contact", "contact archive");
      dispatch({ type: actions.FAILED_ARCHIVE_CONTACT });
      throw error;
    });
};

const fetchArchivedAccountList = (accountingGroupId, params = {}) => (dispatch, getState) => {
  // 1 = vendors, 2 = customers
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendor/archived`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customer/archived`;
  }

  const { top, skip, filter } = params;

  endpoint = `${endpoint}?$top=${top || getState().general.pageRowCount}`;
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }
  if (!isEmpty(filter)) {
    endpoint = `${endpoint}&$filter=${filter}`;
  }

  dispatch({ type: actions.GETTING_ARCHIVED_LIST });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_ARCHIVED_LIST, archivedList: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetAccountListFailure, "account list");
      dispatch({ type: actions.FAILED_GET_ARCHIVED_LIST });
      throw error;
    });
};

const getArchivedAccountList = accountingGroupId => (dispatch, getState) => {
  let state = getState().network;
  let fetchedArchiveAccountList = state.gettingArchivedList || state.gotArchivedList || state.failedGetArchivedList;

  if (!fetchedArchiveAccountList) {
    return dispatch(fetchArchivedAccountList(accountingGroupId));
  }

  return state.archivedList;
};

const fetchArchivedContactList = (accountingGroupId, params = {}) => (dispatch, getState) => {
  // 1 = vendors, 2 = customers
  let accountingGroupType = getState().perspectives.selectedPerspectiveType;
  if (accountingGroupType === null) {
    return false;
  }

  let endpoint;
  if (accountingGroupType === "vendors") {
    endpoint = `v3/api/wrkspcap/${accountingGroupId}/vendorcontact/archived`;
  } else {
    endpoint = `v3/api/wrkspcar/${accountingGroupId}/customercontact/archived`;
  }

  const { top, skip, filter } = params;

  endpoint = `${endpoint}?$top=${top || getState().general.pageRowCount}`;
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&$skip=${skip}`;
  }
  if (!isEmpty(filter)) {
    endpoint = `${endpoint}&$filter=${filter}`;
  }

  dispatch({ type: actions.GETTING_ARCHIVED_CONTACT_LIST });
  return data
    .get(endpoint)
    .then(response => {
      dispatch({ type: actions.GOT_ARCHIVED_CONTACT_LIST, archivedContactList: response.data });
    })
    .catch(error => {
      handlePromiseError(error, Resources.GetContactListFailure, "contact list");
      dispatch({ type: actions.FAILED_GET_ARCHIVED_CONTACT_LIST });
      throw error;
    });
};

const getArchivedContactList = accountingGroupId => (dispatch, getState) => {
  let state = getState().network;
  let fetchedContactList =
    state.gettingArchivedNetworkContactList ||
    state.gotArchivedNetworkContactList ||
    state.failedGetArchivedNetworkContactList;

  if (!fetchedContactList) {
    return dispatch(fetchArchivedContactList(accountingGroupId));
  }

  return state.archivedContactList;
};

const getSelfServiceStatus = () => (dispatch, getState) => {
  let state = getState().network;

  if (state.gettingSelfServiceStatus) {
    return false;
  }

  dispatch({ type: actions.GETTING_SELF_SERVICE_STATUS });
  return data
    .get(`v3/api/global/selfservice`)
    .then(response => {
      dispatch({ type: actions.GOT_SELF_SERVICE_STATUS, selfServiceStatus: response.data });
      return response.data;
    })
    .catch(rejection => {
      dispatch({ type: actions.FAILED_GET_SELF_SERVICE_STATUS, selfServiceStatus: {} });
      handlePromiseError(rejection);
    });
};

const claimProfile = accessId => dispatch => {
  dispatch({ type: actions.CLAIMING_PROFILE });
  return data
    .post(`v3/api/global/account/${accessId}/claim`)
    .then(response => {
      dispatch({ type: actions.CLAIMED_PROFILE });
      return response.data;
    })
    .catch(rejection => {
      dispatch({ type: actions.FAILED_CLAIM_PROFILE });
      handlePromiseError(rejection, "Failed to claim the profile", "company contact");
    });
};

export const dispatchToProps = dispatch => ({
  submitConversionInfo: request => {
    return dispatch(submitConversionInfo(request));
  },
  fetchAccountList: (accountingGroupId, params) => {
    return dispatch(fetchAccountList(accountingGroupId, params));
  },
  getSelfServiceStatus: () => {
    return dispatch(getSelfServiceStatus());
  },
  claimProfile: accessId => {
    return dispatch(claimProfile(accessId));
  },
  fetchArchivedAccountList: (accountingGroupId, params) => {
    return dispatch(fetchArchivedAccountList(accountingGroupId, params));
  },
  fetchArchivedContactList: (accountingGroupId, params) => {
    return dispatch(fetchArchivedContactList(accountingGroupId, params));
  },
  getArchivedContactList: accountingGroupId => {
    return dispatch(getArchivedContactList(accountingGroupId));
  },
  getArchivedAccountList: accountingGroupId => {
    return dispatch(getArchivedAccountList(accountingGroupId));
  },
  toggleAccountArchived: (accountingGroupId, companyKey, archived, mode) => {
    return dispatch(toggleAccountArchived(accountingGroupId, companyKey, archived, mode));
  },
  toggleContactArchived: (accountingGroupId, companyKey, archived, mode) => {
    return dispatch(toggleContactArchived(accountingGroupId, companyKey, archived, mode));
  },
  updateContactPrivacy: (accountingGroupGlobalKey, contactKey, isPrivate, mode) => {
    return dispatch(updateContactPrivacy(accountingGroupGlobalKey, contactKey, isPrivate, mode));
  },
  uploadDocument: (entityGlobalKey, file) => {
    return dispatch(uploadDocument(entityGlobalKey, file));
  },
  getAccountList: accountingGroupId => {
    return dispatch(getAccountList(accountingGroupId));
  },
  selectAccountListPage: (accountingGroupId, selectedPage) => {
    return dispatch(selectAccountListPage(accountingGroupId, selectedPage));
  },
  setAccountListPage: selectedPage => {
    return dispatch({ type: actions.SET_NETWORK_ACCOUNT_LIST_LOADED_PAGE, selectedPage });
  },
  setAccountListFilter: (accountingGroupId, filter) => {
    return dispatch(setAccountListFilter(accountingGroupId, filter));
  },
  fetchContactList: (accountingGroupId, params) => {
    return dispatch(fetchContactList(accountingGroupId, params));
  },
  getContactList: accountingGroupId => {
    return dispatch(getContactList(accountingGroupId));
  },
  selectContactListPage: (accountingGroupId, selectedPage) => {
    return dispatch(selectContactListPage(accountingGroupId, selectedPage));
  },
  setContactListFilter: (accountingGroupId, filter) => {
    return dispatch(setContactListFilter(accountingGroupId, filter));
  },
  fetchCustContact: accessId => {
    return dispatch(fetchCustContact(accessId));
  },
  postCustContact: (accessId, request) => {
    return dispatch(postCustContact(accessId, request));
  },
  postCustContactPicture: (vendorAccountKey, custKey, custContactKey, signature, file) => {
    return dispatch(postCustContactPicture(vendorAccountKey, custKey, custContactKey, signature, file));
  },
  fetchCustAccount: (accessId, mode) => {
    return dispatch(fetchCustAccount(accessId, mode));
  },
  fetchCustAccountContacts: (accessId, mode) => {
    return dispatch(fetchCustAccountContacts(accessId, mode));
  },
  fetchCustAccountDocuments: (accessId, mode) => {
    return dispatch(fetchCustAccountDocuments(accessId, mode));
  },
  fetchOpenOnboardingRequests: (perspectiveId, params) => {
    return dispatch(fetchOpenOnboardingRequests(perspectiveId, params));
  },
  getOpenOnboardingRequests: perspectiveId => {
    return dispatch(getOpenOnboardingRequests(perspectiveId));
  },
  selectOpenOnboardingRequestsPage: (accountingGroupId, selectedPage) => {
    return dispatch(selectOpenOnboardingRequestsPage(accountingGroupId, selectedPage));
  },
  fetchOpenProfileUpdateRequests: (perspectiveId, params) => {
    return dispatch(fetchOpenProfileUpdateRequests(perspectiveId, params));
  },
  getOpenProfileUpdateRequests: perspectiveId => {
    return dispatch(getOpenProfileUpdateRequests(perspectiveId));
  },
  selectOpenProfileUpdateRequestsPage: (accountingGroupId, selectedPage) => {
    return dispatch(selectOpenProfileUpdateRequestsPage(accountingGroupId, selectedPage));
  },
  postCustAccount: (accessId, accountKey, request) => {
    return dispatch(postCustAccount(accessId, accountKey, request));
  },
  postCustAccountKeyContact: (accessId, request, mode) => {
    return dispatch(postCustAccountKeyContact(accessId, request, mode));
  },
  postCustAccountKeyDocuments: (accessId, accountKey, request) => {
    return dispatch(postCustAccountKeyDocuments(accessId, accountKey, request));
  },
  postCustAccountPicture: (accessId, mode, file) => {
    return dispatch(postCustAccountPicture(accessId, mode, file));
  },
  fetchAccountProfile: (accountingGroupId, custKey) => {
    return dispatch(fetchAccountProfile(accountingGroupId, custKey));
  },
  getAccountProfile: (accountingGroupId, custKey) => {
    return dispatch(getAccountProfile(accountingGroupId, custKey));
  },
  fetchAccountDocuments: (accountingGroupId, accountKey) => {
    return dispatch(fetchAccountDocuments(accountingGroupId, accountKey));
  },
  getAccountDocuments: (accountingGroupId, accountKey) => {
    return dispatch(getAccountDocuments(accountingGroupId, accountKey));
  },
  fetchAccountContacts: (accountingGroupId, accountKey) => {
    return dispatch(fetchAccountContacts(accountingGroupId, accountKey));
  },
  getAccountContacts: (accountingGroupId, accountKey) => {
    return dispatch(getAccountContacts(accountingGroupId, accountKey));
  },
  fetchContactProfile: (accountingGroupId, contactKey) => {
    return dispatch(fetchContactProfile(accountingGroupId, contactKey));
  },
  getContactProfile: (accountingGroupId, contactKey) => {
    return dispatch(getContactProfile(accountingGroupId, contactKey));
  },
  fetchActivityList: (accountingGroupId, params) => {
    return dispatch(fetchActivityList(accountingGroupId, params));
  },
  getActivityList: (accountingGroupId, params) => {
    return dispatch(getActivityList(accountingGroupId, params));
  },
  sendAccountProfileUpdateRequest: accountKeys => {
    return dispatch(sendAccountProfileUpdateRequest(accountKeys));
  },
  sendContactProfileUpdateRequest: contactKeys => {
    return dispatch(sendContactProfileUpdateRequest(contactKeys));
  },
  fetchHeaderInfo: accessId => {
    return dispatch(fetchHeaderInfo(accessId));
  },
  fetchAccountSearchResults: (accountingGroupId, params) => {
    return dispatch(fetchAccountSearchResults(accountingGroupId, params));
  },
  debouncedFetchAccountSearchResults: (accountingGroupId, params) => {
    return dispatch(debouncedFetchAccountSearchResults(accountingGroupId, params));
  },
  fetchContactSearchResults: (accountingGroupId, params) => {
    return dispatch(fetchContactSearchResults(accountingGroupId, params));
  },
  debouncedFetchContactSearchResults: (accountingGroupId, params) => {
    return dispatch(debouncedFetchContactSearchResults(accountingGroupId, params));
  },
  fetchAccountingGroupProfile: (accountingGroupKey, companyGlobalKey, mode) => {
    return dispatch(fetchAccountingGroupProfile(accountingGroupKey, companyGlobalKey, mode));
  },
  fetchAccountingGroupProfileContacts: (accountingGroupKey, companyGlobalKey, mode) => {
    return dispatch(fetchAccountingGroupProfileContacts(accountingGroupKey, companyGlobalKey, mode));
  },
  fetchAccountingGroupProfileDocuments: (accountingGroupKey, companyGlobalKey, mode) => {
    return dispatch(fetchAccountingGroupProfileDocuments(accountingGroupKey, companyGlobalKey, mode));
  },
  postAccountingGroupProfile: (accountingGroupKey, companyGlobalKey, mode, request) => {
    return dispatch(postAccountingGroupProfile(accountingGroupKey, companyGlobalKey, mode, request));
  },
  postAccountingGroupProfileDocuments: (accountingGroupKey, companyGlobalKey, mode, request) => {
    return dispatch(postAccountingGroupProfileDocuments(accountingGroupKey, companyGlobalKey, mode, request));
  },
  postAccountingGroupProfileContacts: (accountingGroupKey, companyGlobalKey, mode, request) => {
    return dispatch(postAccountingGroupProfileContacts(accountingGroupKey, companyGlobalKey, mode, request));
  },
  uploadAccountingGroupDocument: (accountingGroupKey, companyGlobalKey, mode, file) => {
    return dispatch(uploadAccountingGroupDocument(accountingGroupKey, companyGlobalKey, mode, file));
  },
  uploadAccountingGroupDocuments: (accountingGroupKey, companyGlobalKey, mode, file) => {
    return dispatch(uploadAccountingGroupDocuments(accountingGroupKey, companyGlobalKey, mode, file));
  },
  addAccountingGroupDocument: (accountingGroupKey, vendorGlobalKey, mode, file) => {
    return dispatch(addAccountingGroupDocument(accountingGroupKey, vendorGlobalKey, mode, file));
  },
  fetchEmailAttachments: (accountingGroupKey, companyGlobalKey, entityType, entityKey) => {
    return dispatch(fetchEmailAttachments(accountingGroupKey, companyGlobalKey, entityType, entityKey));
  },
  getEmailAttachments: (accountingGroupKey, companyGlobalKey, entityKey) => {
    return dispatch(getEmailAttachments(accountingGroupKey, companyGlobalKey, entityKey));
  },
  updateDocumentType: (accountingGroupKey, entityKey, request) => {
    return dispatch(updateDocumentType(accountingGroupKey, entityKey, request));
  },
  deleteDocuments: (accountingGroupKey, entityKey, request) => {
    return dispatch(deleteDocuments(accountingGroupKey, entityKey, request));
  },
  updateEmailAttachmentType: (accountingGroupKey, companyKey, request) => {
    return dispatch(updateEmailAttachmentType(accountingGroupKey, companyKey, request));
  },
  deleteAttachments: (accountingGroupKey, companyKey, request) => {
    return dispatch(deleteAttachments(accountingGroupKey, companyKey, request));
  },
  postAccountSettings: (accountingGroupKey, request) => {
    return dispatch(postAccountSettings(accountingGroupKey, request));
  },
  fetchAccountSettings: accountingGroupKey => {
    return dispatch(fetchAccountSettings(accountingGroupKey));
  },
  fetchMyProfile: () => {
    return dispatch(fetchMyProfile());
  },
  postMyProfile: request => {
    return dispatch(postMyProfile(request));
  },
  postMyProfilePicture: file => {
    return dispatch(postMyProfilePicture(file));
  },
  fetchNotes: entityGlobalKey => {
    return dispatch(fetchNotes(entityGlobalKey));
  },
  postNotes: (entityGlobalKey, request) => {
    return dispatch(postNotes(entityGlobalKey, request));
  },
  deleteNote: (entityGlobalKey, entityGlobalNoteKey) => {
    return dispatch(deleteNote(entityGlobalKey, entityGlobalNoteKey));
  },
  getShareFlyoutMessage: (accountingGroupType, entityGlobalKey) => {
    return dispatch(getShareFlyoutMessage(accountingGroupType, entityGlobalKey));
  },
  sendShareFlyout: (accountingGroupGlobalKey, companyGlobalKey, request) => {
    return dispatch(sendShareFlyout(accountingGroupGlobalKey, companyGlobalKey, request));
  },
  fetchShareLink: (mode, entityGlobalKey) => {
    return dispatch(fetchShareLink(mode, entityGlobalKey));
  },
  fetchSubsidiaryList: accountingGroupGlobalKey => {
    return dispatch(fetchSubsidiaryList(accountingGroupGlobalKey));
  },
  updateCustContact: (accountingGroupKey, lockstepContactKey, mode, request) => {
    return dispatch(updateCustContact(accountingGroupKey, lockstepContactKey, mode, request));
  },
  updateAccountingGroupProfile: (accountingGroupKey, accountId, mode, request) => {
    return dispatch(updateAccountingGroupProfile(accountingGroupKey, accountId, mode, request));
  },
  addContact: (accountingGroupId, companyKey, mode, request) => {
    return dispatch(addContact(accountingGroupId, companyKey, mode, request));
  },
  markContactPrimary: (accountingGroupId, contactKey, mode) => {
    return dispatch(markContactPrimary(accountingGroupId, contactKey, mode));
  },
  getAccountArchivedContacts: (accountingGroupId, accountKey) => {
    return dispatch(getAccountArchivedContacts(accountingGroupId, accountKey));
  },
  fetchAccountArchivedContacts: (accountingGroupId, accountKey) => {
    return dispatch(fetchAccountArchivedContacts(accountingGroupId, accountKey));
  },
  downloadAttachment: (accountingGroupKey, companyKey, entityKey, attachmentKey, signature) => {
    return dispatch(downloadAttachment(accountingGroupKey, companyKey, entityKey, attachmentKey, signature));
  },
  debouncedFetchAttachmentSearchResultsGet: (accountingGroupKey, companyKey, entityKey, params) => {
    return dispatch(debouncedFetchAttachmentSearchResultsGet(accountingGroupKey, companyKey, entityKey, params));
  },
  debouncedFetchAttachmentSearchResults: (accountingGroupKey, companyKey, entityKey, params) => {
    return dispatch(debouncedFetchAttachmentSearchResults(accountingGroupKey, companyKey, entityKey, params));
  },
  fetchAttachmentSearchResults: (accountingGroupKey, companyKey, entityKey, params) => {
    return dispatch(fetchAttachmentSearchResults(accountingGroupKey, companyKey, entityKey, params));
  }
});
