import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import InvitedAcceptProfile from "./invitedAcceptProfile";
import TextInput from "./library/textInput";
import LockstepLogo from "./library/lockstepLogo";
import Card from "./library/card";
import Checkbox from "./library/checkbox";
import Resources from "../lib/resources";
import auth from "../lib/auth";
import { isEmpty, includes } from "../lib/utils";
import LockstepHeader from "./lockstep/views/lockstepHeader";
import MainLoader from "./mainLoader";
import { handlePromiseError } from "../store/error-actions";
import { dispatchToProps as usrDP } from "../store/user-actions";
import { dispatchToProps as regDP } from "../store/registration-actions";
import { dispatchToProps as invDP } from "../store/invite-actions";

const dispatchToProps = dispatch => ({
  ...usrDP(dispatch),
  ...regDP(dispatch),
  ...invDP(dispatch)
});

class InvitedSetPassword extends Component {
  state = {
    fullName: "",
    password: "",
    confirmPassword: "",
    isPasswordValid: true,
    isPasswordSubmitted: false,
    isPoliciesAccepted: false,
    errorMessages: []
  };

  componentDidMount = () => {
    if (this.props.user.isLoggedIn) {
      this.props.history.push("/");
      return;
    }

    let inviteCode = decodeURIComponent(this.props.match.params.inviteCode);

    if (inviteCode)
      this.props
        .getInviteData(inviteCode)
        .then(() => {
          this.setState({
            fullName: this.props.registration.inviteData.fullName,
            email: this.props.registration.inviteData.email
          });
        })
        .catch(err => {
          if (err.message === "Invalid invite") {
            this.setState({ invalidInvite: true });
          }
        });
  }

  componentDidUpdate = () => {
    let {
      registration: { inviteData }
    } = this.props;

    if (
      this.state.isPasswordSubmitted &&
      inviteData.companyInvite.existingCompanyId &&
      !this.state.creatingUser &&
      !this.state.userCreated &&
      !this.state.userCreateFailed
    ) {
      if (isEmpty(inviteData.invitedUserId)) {
        this.setState({ creatingUser: true }, () => {
          auth
            .createNewUser({
              userName: inviteData.email,
              password: this.state.password,
              fullName: inviteData.fullName,
              givenName: isEmpty(inviteData.fullName) ? inviteData.email : inviteData.fullName,
              email: inviteData.email,
              inviteId: inviteData.userInviteId
            })
            .then(response => {
              this.setState({
                creatingUser: false,
                userCreated: true,
                userCreateFailed: false
              });
            })
            .catch(e => {
              let response = e.request.response || "{}";
              let errorResponse = JSON.parse(response);
              let errorMsgKeys = Object.keys(errorResponse);
              let errorMsgs = errorMsgKeys.map(key => {
                return errorResponse[key];
              });
              if (
                errorMsgs.some(
                  item =>
                    item[0] === "A user with this email address already exists." ||
                    item[0] === "A user with this username already exists."
                )
              ) {
                this.setState({
                  creatingUser: false,
                  userCreated: true,
                  userCreateFailed: false
                });
              } else {
                this.setState({
                  creatingUser: false,
                  userCreated: false,
                  userCreateFailed: true
                });
                handlePromiseError(e, "TODO: User creation failed.  Please try again or contact customer support.");
              }
            });
        });
      } else {
        this.setState({
          creatingUser: false,
          userCreated: true,
          userCreateFailed: false
        });
      }
    }

    if (this.state.userCreated && this.props.user.isLoggedIn === false && this.props.user.isLoggingIn === false) {
      if (this.props.user.didLoginFail === false) {
        this.props.login(inviteData.email, this.state.password);
      } else {
        if (this.state.errorMessages.length === 0) {
          this.setState({ errorMessages: [Resources.UserLoginFailure] });
          this.props.loginFailedReset();
        }
      }
    }

    if (
      this.props.user.isLoggedIn &&
      !this.state.acceptingCompanyInvite &&
      !this.state.acceptedCompanyInvite &&
      !this.state.acceptCompanyInviteFailed
    ) {
      this.setState({ acceptingCompanyInvite: true }, () => {
        this.props
          .acceptCompanyInvite(inviteData.companyInvite.existingCompanyId, inviteData.userInviteId)
          .then(response => {
            this.setState({
              acceptingCompanyInvite: false,
              acceptedCompanyInvite: true,
              acceptCompanyInviteFailed: false
            });
            window.location = `/#/company/${inviteData.companyInvite.existingCompanyId}/${inviteData.defaultPerspective}`;
          })
          .catch(rejection => {
            if (
              includes(
                ((((rejection || {}).response || {}).data || {})["inviteId"] || [""])[0].toLocaleLowerCase(),
                "already a user of this company"
              )
            ) {
              this.setState({
                acceptingCompanyInvite: false,
                acceptedCompanyInvite: true,
                acceptCompanyInviteFailed: false
              });
              window.location = `/#/company/${inviteData.companyInvite.existingCompanyId}/${inviteData.defaultPerspective}`;
            } else {
              this.setState({
                acceptingCompanyInvite: false,
                acceptedCompanyInvite: false,
                acceptCompanyInviteFailed: true
              });
            }
          });
      });
    }
  }

  submitOnEnter = e => {
    if (e.key === "Enter") {
      this.signUpUser(e);
    }
  };

  signUpUser = e => {
    e.preventDefault();
    this.setState({ errorMessages: [] });

    if (this.state.isPoliciesAccepted !== true) {
      return this.setState({ errorMessages: [Resources.PleaseAcceptPolicies] });
    }

    this.props.isPasswordValid(this.state.password).then(() => {
      let {
        user: { isPasswordValid, validatePasswordErrors }
      } = this.props;

      let isPasswordConfirmed = this.state.password === this.state.confirmPassword;

      if (isPasswordValid && isPasswordConfirmed) {
        this.setState({ isPasswordSubmitted: true, errorMessages: [] });
      } else {
        this.setState({ errorMessages: [] }, () => {
          let isEmpty = this.state.password === "";
          let doesPasswordMissMatch = this.state.password !== this.state.confirmPassword;
          let didTheReduxStoreFindErrors = validatePasswordErrors.length > 0;

          if (isEmpty)
            this.setState({
              errorMessages: [...this.state.errorMessages, Resources.AllEntriesEmptyError]
            });

          if (doesPasswordMissMatch)
            this.setState({
              errorMessages: [...this.state.errorMessages, Resources.PasswordsNeedToMatchError]
            });

          if (isEmpty === false && doesPasswordMissMatch === false && didTheReduxStoreFindErrors)
            this.setState({
              errorMessages: [...this.state.errorMessages, ...validatePasswordErrors]
            });
        });
      }
    });
  };

  render() {
    const { email, password, confirmPassword, isPoliciesAccepted } = this.state;
    let passwordData = { password, confirmPassword };
    let errorMessages = this.state.errorMessages;
    let { inviteData } = this.props.registration;

    inviteData = isEmpty(inviteData) ? { companyInvite: {} } : inviteData;
    let userExists = isEmpty(inviteData.invitedUserId) !== true;

    if (
      isEmpty(inviteData.invitedUserId) === false &&
      isEmpty(inviteData.companyInvite.existingCompanyId) === false &&
      isEmpty(inviteData.companyInvite.companyName) === false
    ) {
      return <Redirect to="/login" />;
    }

    if (this.state.invalidInvite === true) {
      return (
        <div className="login-container" style={{ height: "100vh" }}>
          <LockstepLogo height={57} inverse />
          <div className="login-form-error">
            <h2 className="warning-color-font">{Resources.UhOh}</h2>
            <h6>{Resources.InvalidInviteMessage}</h6>
            <button onClick={() => this.props.history.push("/")} className="button-green my-5">
              {Resources.BackToLogin}
            </button>
          </div>
        </div>
      );
    }

    let page = null;

    if (this.state.isPasswordSubmitted && isEmpty(inviteData.companyInvite.existingCompanyId)) {
      page = <InvitedAcceptProfile passwordData={passwordData} replace={this.props.history.replace} />;
    } else {
      page = (
        <div className="confirm-password-page-container">
          <LockstepHeader />
          <h2 className="first-time-title-contact">{Resources.InvitedWeNeedMoreDetails(email)}</h2>
          <Card className="confirm-password-container">
            {errorMessages.map(error => {
              return <span className="error-text">{error}</span>;
            })}
            {this.props.user.isValidatingPassword ? (
              <div className="confirm-password-loader-container">
                <MainLoader />
              </div>
            ) : null}
            <TextInput
              textValue={password}
              width="23.2rem"
              labelInline={false}
              isPassword
              label={Resources.Password}
              inputOnChange={e => {
                let password = e.target.value;
                userExists ? this.setState({ password, confirmPassword: password }) : this.setState({ password });
              }}
            />
            <TextInput
              textValue={confirmPassword}
              width="23.2rem"
              labelInline={false}
              label={Resources.ConfirmPassword}
              isPassword
              inputOnChange={e => {
                this.setState({ confirmPassword: e.target.value });
              }}
            />
            <div className="checkbox-container">
              <Checkbox
                checked={this.state.isPoliciesAccepted}
                onChange={() => this.setState({ isPoliciesAccepted: !isPoliciesAccepted })}
              />
              <label htmlFor="checkbox_1">{Resources.IAcceptPolicies}</label>
            </div>
            <div className="checkbox">
              <div
                className="sub-label"
                dangerouslySetInnerHTML={{
                  __html: Resources.InvitePolicyLinks(
                    `<a href="${Resources.TermsOfServiceUrl}" target="_blank" rel="noopener noreferrer" class="blue-font-white-bg">${Resources.TermsOfService}</a>`,
                    `<a href="${Resources.AcceptableUseUrl}" target="_blank" rel="noopener noreferrer" class="blue-font-white-bg">${Resources.AcceptableUse}</a>`,
                    `<a href="${Resources.PrivacyUrl}" target="_blank" rel="noopener noreferrer" class="blue-font-white-bg">${Resources.Privacy}</a>`
                  )
                }}
              />
            </div>
          </Card>
          <button onClick={this.signUpUser} disabled={!isPoliciesAccepted} className="button-primary-square large">
            {userExists ? Resources.SignIn : Resources.SignUp}
          </button>
        </div>
      );
    }

    return <React.Fragment>{page}</React.Fragment>;
  }
}

const storeToProps = store => {
  return {
    registration: store.registration,
    user: store.user,
    errors: store.errors
  };
};

export default connect(storeToProps, dispatchToProps)(InvitedSetPassword);
