import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { find, isEmpty, updateSelectedRows } from "../../../../lib/utils";
import Resources from "../../../../lib/resources";
import LockstepHeader from "../lockstepHeader";
import { generatePath, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LoginInformationForm from "./loginInformationForm";
import ProfileUpdateForm from "../profileUpdate/profileUpdateForm";
import ContactUpdateForm from "../profileUpdate/contactUpdateForm";
import ContactDisplayCard from "./contactDisplayCard";
import { dispatchToProps as accDP } from "../../../../store/accounts-actions";
import { dispatchToProps as regDP } from "../../../../store/registration-actions";
import { dispatchToProps as perDP } from "../../../../store/perspectives-actions";
import { dispatchToProps as netDP } from "../../../../store/network-actions";
import { handlePromiseError } from "../../../../store/error-actions";
import SignupRoutes from "./signupRoutes.json";
import CompanyInfoCard from "../../../library/companyInfoCard";
import MainLoader from "../../../mainLoader";
import ContactsTable from "../../contactsTable";

const dispatchToProps = dispatch => ({
  ...accDP(dispatch),
  ...regDP(dispatch),
  ...perDP(dispatch),
  ...netDP(dispatch)
});

class SignupView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {
        username: "",
        password: "",
        departmentId: 1,
        taxId: "",
        companyName: "",
        emailAddr: "",
        companyWebsite: "",
        phone: ""
      },
      currentUser: {},
      currentUserEdit: {},
      sameAsBilling: false,
      contactEditMode: true,
      profileEditMode: false,
      accountId: "",
      selectedKeys: [],
      selectedRows: []
    };
    this.formOnChange = this.formOnChange.bind(this);
    this.toggleSameAsBilling = this.toggleSameAsBilling.bind(this);
    this.contactFormOnChange = this.contactFormOnChange.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.getCompanyInfo = this.getCompanyInfo.bind(this);
    this.markForInvite = this.markForInvite.bind(this);
  }

  getCompanyInfo() {
    this.props.fetchCustAccountContacts(this.props.match.params.accessId, this.props.match.params.mode);
    this.props.fetchCustAccount(this.props.match.params.accessId, this.props.match.params.mode).then(() => {
      this.setState({
        profile:
          this.props.networkStore.custAccount.companyAPDetails || this.props.networkStore.custAccount.companyARDetails
      });
    });
  }

  formOnChange(field, value) {
    this.setState(prevState => {
      return { profile: { ...prevState.profile, [field]: value } };
    });
  }

  contactFormOnChange(field, value) {
    this.setState(prevState => {
      return { currentUserEdit: { ...prevState.currentUserEdit, [field]: value } };
    });
  }

  createAccount() {
    let modeMap = {
      1: "ap",
      2: "ar"
    };
    let companyInfoObj = {
      personalEmail: this.state.profile.username,
      password: this.state.profile.password,
      companyWebsite: this.state.profile.linkedInProfile,
      streetAddress:
        this.state.profile.departmentId === 1 ? this.state.profile.billToAddrLn1 : this.state.profile.mailToAddrLn1,
      city: this.state.profile.departmentId === 1 ? this.state.profile.billToCity : this.state.profile.mailToCity,
      state: this.state.profile.departmentId === 1 ? this.state.profile.billToState : this.state.profile.mailToState,
      zip:
        this.state.profile.departmentId === 1
          ? this.state.profile.billToPostalcode
          : this.state.profile.mailToPostalcode,
      country:
        this.state.profile.departmentId === 1 ? this.state.profile.billToCountry : this.state.profile.mailToCountry
    };
    this.props
      .createUserAccount(companyInfoObj)
      .then(response => {
        this.props.createAccount(this.state.profile.companyName).then(response => {
          this.setState({ accountId: response.accountId });
          let companyBody = {
            companyName: this.state.profile.companyName,
            companyEmail: this.state.profile.emailAddr,
            accountId: this.state.accountId,
            inviteId: this.state.profile.inviteId
          };
          this.props.createAccountCompany(companyBody).then(response => {
            let companyId = response.companyId;
            this.props.fetchPerspectives().then(response => {
              let parentPerspectiveId = find(this.props.perspectives.perspectives, i =>
                modeMap[this.state.profile.departmentId] === "ap"
                  ? i.perspectiveName === "vendors"
                  : i.perspectiveName === "customers"
              ).perspectiveId;
              let payload = {
                parentPerspectiveId: parentPerspectiveId,
                perspectiveName:
                  modeMap[this.state.profile.departmentId] === "ap" ? "Accounts Payable" : "Accounts Recievable"
              };
              this.props.createCompanyPerspective(companyId, payload).then(response => {
                let companyDetailsToggle =
                  modeMap[this.state.profile.departmentId] === "ap" ? "companyAPDetails" : "companyARDetails";
                let accountingGroupDetailsToggle =
                  modeMap[this.state.profile.departmentId] === "ap"
                    ? "accountingGroupAPDetails"
                    : "accountingGroupARDetails";
                let postRequest = {
                  [companyDetailsToggle]: {
                    ...this.state.profile
                  },
                  [accountingGroupDetailsToggle]: {
                    ...this.state.profile
                  },
                  isLockstepVerified: true
                };
                this.props
                  .postAccountingGroupProfile(
                    response[0].perspectiveId,
                    companyId,
                    modeMap[this.state.profile.departmentId],
                    postRequest
                  )
                  .then(response => {
                    this.props.history.push("/v2");
                  });
                let profilePostRequest = {
                  isLockstepVerified: true,
                  ...this.state.currentUserEdit
                };
                this.props.postMyProfile(profilePostRequest);
              });
            });
          });
        });
      })
      .catch(error => {
        handlePromiseError(error, Resources.AUserWithThisEmailAlreadyExists, "user accounts");
      });
  }

  toggleSameAsBilling() {
    this.setState({ sameAsBilling: !this.state.sameAsBilling });
  }

  markForInvite(key, row) {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedRows);
    this.setState({ selectedKeys: newSelectedKeys, selectedRows: newSelectedRows });
  }

  componentDidMount() {
    this.props.getSelfServiceStatus();
    if (this.props.match.params.accessId !== undefined) {
      this.getCompanyInfo();
      this.setState({ profileEditMode: true, contactEditMode: false });
    }
  }

  render() {
    let accountData = {
      ...(this.props.networkStore.custAccount.companyAPDetails || this.props.networkStore.custAccount.companyARDetails),
      ...this.state.accountData
    };
    let accountingGroupDetails = {
      ...(this.props.networkStore.custAccount.accountingGroupAPDetails ||
        this.props.networkStore.custAccount.accountingGroupARDetails),
      ...this.state.accountingGroupDetails
    };
    let modeMap = {
      1: "ap",
      2: "ar"
    };

    let remainingContacts = [];
    if (this.props.match.params.accessId !== undefined) {
      remainingContacts = (this.props.networkStore.custAccountContacts.contacts || []).filter(
        i => i.entityGlobalKey !== this.state.currentUser.entityGlobalKey
      );
    }
    return (
      <React.Fragment>
        {!this.props.networkStore.selfServiceStatus.claim &&
          this.props.networkStore.selfServiceStatus.claim !== undefined &&
          this.props.match.params.accessId !== undefined && <Redirect to="/login" />}
        {!this.props.networkStore.selfServiceStatus.create &&
          this.props.networkStore.selfServiceStatus.claim !== undefined &&
          this.props.match.params.accessId === undefined && <Redirect to="/login" />}
        <Switch>
          <Route path="/signup/:type(account|contact|profileupdate)/:view(login|userinfo|contacts|profile|invite)/:mode?/:accessId?">
            <LockstepHeader />
            <Switch>
              <Route path="/signup/account/:view(login|userinfo|invite|profile)/:mode?/:accessId?">
                <Switch>
                  <Route path={SignupRoutes.account.login}>
                    <div className="signup-container">
                      <h2 className="first-time-title-contact">{Resources.CreateUsernamePasswordHeading}</h2>
                      <h2 className="first-time-title-contact">{Resources.RecomendUsingCompanyEmail}</h2>
                      <LoginInformationForm
                        displayModePicker={this.props.match.params.accessId === undefined}
                        profile={this.state.profile}
                        formOnChange={this.formOnChange}
                      />
                      <button
                        onClick={() => {
                          if (this.props.match.params.accessId !== undefined) {
                            this.props.networkStore.custAccountContacts.contacts.forEach(i => {
                              if (i.emailAddr === this.state.profile.username) {
                                this.setState({ currentUser: i, currentUserEdit: i });
                              }
                              if (isEmpty(this.state.currentUser)) {
                                this.setState(prevState => {
                                  return {
                                    currentUser: { ...prevState.currentUser, emailAddr: this.state.profile.username },
                                    currentUserEdit: {
                                      ...prevState.currentUserEdit,
                                      emailAddr: this.state.profile.username
                                    }
                                  };
                                });
                              }
                            });
                          }
                          this.props.history.push(
                            generatePath(SignupRoutes.account.userinfo, {
                              accessId: this.props.match.params.accessId,
                              mode: this.props.match.params.mode
                            })
                          );
                        }}
                        className="button-primary-square large"
                      >
                        {Resources.Next}
                      </button>
                    </div>
                  </Route>
                  <Route path={SignupRoutes.account.userinfo}>
                    <div className="signup-container">
                      {this.props.networkStore.gettingCustAccount ? (
                        <div className="mt-5">
                          <MainLoader fullScreen={true} />
                        </div>
                      ) : this.state.profileEditMode ? (
                        <div className="signup-container">
                          <div className="signup-heading-container">
                            <h2 className="first-time-title-contact">{Resources.YourOneStepCloserToOptimizing}</h2>
                            <h2 className="first-time-title-contact">{Resources.ConfirmCompanyInfoProfile}</h2>
                          </div>
                          <CompanyInfoCard accountData={accountData} accountingGroupDetails={accountingGroupDetails} />
                        </div>
                      ) : (
                        <div>
                          <h2 className="first-time-title-contact">{Resources.CreateUserProfileInfo}</h2>
                          <ProfileUpdateForm
                            small
                            empty
                            editedAccount={this.state.profile}
                            mode={modeMap[this.state.profile.departmentId]}
                            formOnChange={this.formOnChange}
                            sameAsBilling={this.state.sameAsBilling}
                            toggleSameAsBilling={this.toggleSameAsBilling}
                          />
                        </div>
                      )}
                      {this.props.networkStore.gettingCustAccount ? null : (
                        <div className="button-container">
                          {this.props.match.params.accessId !== undefined ? (
                            <button
                              onClick={() => this.setState({ profileEditMode: !this.state.profileEditMode })}
                              className="button-secondary-square large"
                            >
                              {!this.state.profileEditMode ? Resources.Cancel : Resources.INeedToUpdateThisProfile}
                            </button>
                          ) : null}
                          <button
                            onClick={() => {
                              let accountingGroupToggle =
                                this.props.match.params.mode === "ap"
                                  ? "accountingGroupAPDetails"
                                  : "accountingGroupARDetails";
                              let companyDetailToggle =
                                this.props.match.params.mode === "ap" ? "companyAPDetails" : "companyARDetails";
                              if (this.props.match.params.accessId !== undefined) {
                                let accountingGroupDetails = {
                                  ...(this.props.networkStore.custAccount.accountingGroupAPDetails ||
                                    this.props.networkStore.custAccount.accountingGroupARDetails),
                                  ...this.state.accountingGroupDetails
                                };
                                let body = {
                                  [companyDetailToggle]: this.state.profile,
                                  [accountingGroupToggle]: {
                                    emailAddr: accountingGroupDetails.emailAddr
                                  },
                                  isLockstepVerified: true
                                };
                                this.props.postCustAccount(
                                  this.props.match.params.accessId,
                                  this.props.match.params.mode,
                                  body
                                );
                              }
                              this.props.history.push(
                                generatePath(
                                  this.props.match.params.accessId !== undefined
                                    ? SignupRoutes.account.invite
                                    : SignupRoutes.account.profile,
                                  {
                                    mode: this.props.match.params.mode,
                                    accessId: this.props.match.params.accessId
                                  }
                                )
                              );
                            }}
                            className="button-primary-square large"
                          >
                            {this.props.match.params.accessId !== undefined ? Resources.LooksGood : Resources.Next}
                          </button>
                        </div>
                      )}
                    </div>
                  </Route>
                  <Route path={SignupRoutes.account.invite}>
                    <div className="signup-container">
                      <h2 className="first-time-title-contact">{Resources.GreatWhoGetsInvite}</h2>
                      <div className="document-container">
                        <ContactsTable
                          data={remainingContacts}
                          noCompany
                          loading={this.props.networkStore.gettingCustAccountContacts}
                          noHover
                          signupView
                          markedContacts={this.state.selectedKeys}
                          markForInvite={this.markForInvite}
                          currentPrimary={this.state.currentUser}
                        />
                      </div>
                      <div className="button-container">
                        <button
                          onClick={() => {
                            // TODO
                            // In the future the endpoint call to send out a claim request to each of these contacts
                            // will also go here.
                            this.props.history.push(
                              generatePath(SignupRoutes.account.profile, {
                                mode: this.props.match.params.mode,
                                accessId: this.props.match.params.accessId
                              })
                            );
                          }}
                          className="button-primary-square large"
                        >
                          {Resources.Next}
                        </button>
                      </div>
                    </div>
                  </Route>
                  <Route path={SignupRoutes.account.profile}>
                    {this.props.networkStore.gettingCustAccountContacts ? (
                      <MainLoader fullScreen={true} />
                    ) : (
                      <div className="signup-container">
                        <h2 className="first-time-title-contact">{Resources.CreateUserMyProfileInfo}</h2>
                        {this.state.contactEditMode ? (
                          <ContactUpdateForm
                            currentContact={this.state.currentUserEdit}
                            empty
                            signup={this.props.match.params.accessId !== undefined}
                            contactFormOnChange={this.contactFormOnChange}
                          />
                        ) : (
                          <ContactDisplayCard noavatar signup selectedContact={this.state.currentUser} />
                        )}
                        <div className="button-container">
                          {this.props.match.params.accessId !== undefined ? (
                            <button
                              onClick={() => this.setState({ contactEditMode: !this.state.contactEditMode })}
                              className="button-secondary-square large"
                            >
                              {this.state.contactEditMode ? Resources.Cancel : Resources.INeedToUpdateThisProfile}
                            </button>
                          ) : null}
                          <button
                            disabled={
                              this.props.match.params.accessId === undefined
                                ? !this.props.networkStore.selfServiceStatus.create
                                : false
                            }
                            onClick={() => {
                              if (this.props.match.params.accessId !== undefined && this.state.contactEditMode) {
                                this.setState({ currentUser: this.state.currentUserEdit, contactEditMode: false });
                              } else {
                                if (this.props.match.params.accessId !== undefined) {
                                  let companyInfoObj = {
                                    personalEmail: this.state.profile.username,
                                    password: this.state.profile.password
                                  };
                                  this.props.createUserAccount(companyInfoObj).then(response => {
                                    this.props.claimProfile(this.props.match.params.accessId).then(response => {
                                      let profileRequest = {
                                        ...this.state.currentUser,
                                        isLockstepVerified: true
                                      };
                                      this.props.postMyProfile(profileRequest);
                                      this.props.history.push("/v2");
                                    });
                                  });
                                } else {
                                  if (this.props.networkStore.selfServiceStatus.create) {
                                    this.createAccount();
                                  }
                                }
                              }
                            }}
                            className="button-primary-square large"
                          >
                            {this.state.contactEditMode ? Resources.Save : Resources.LooksGood}
                          </button>
                        </div>
                      </div>
                    )}
                  </Route>
                </Switch>
              </Route>
              <Route path="/signup/contact/:view(login|userinfo)">
                <Switch>
                  <Route path="/signup/contact/login/:accessId">
                    <div className="signup-container">
                      <h2 className="first-time-title-contact">{Resources.CreateUsernamePasswordHeading}</h2>
                      <h2 className="first-time-title-contact">{Resources.RecomendUsingCompanyEmail}</h2>
                      <LoginInformationForm contact profile={this.state.profile} formOnChange={this.formOnChange} />
                      <button
                        onClick={() => {
                          this.props.history.push(
                            generatePath("/signup/contact/userinfo/:accessId", {
                              accessId: this.state.profile.accessId
                            })
                          );
                        }}
                        className="button-primary-square large"
                      >
                        {Resources.Next}
                      </button>
                    </div>
                  </Route>
                  <Route path="/signup/contact/userinfo/:accessId">
                    <div className="signup-container">
                      <h2 className="first-time-title-contact">{Resources.CreateUserMyContactProfileInfo}</h2>
                      {this.state.editMode ? (
                        <ContactUpdateForm empty contact contactFormOnChange={this.contactFormOnChange} />
                      ) : (
                        <ContactDisplayCard />
                      )}
                      <div className="button-container">
                        {this.state.editMode ? null : (
                          <button
                            onClick={() => {
                              this.setState({ editMode: true });
                            }}
                            className="button-secondary-square large"
                          >
                            {Resources.INeedToUpdateThisProfile}
                          </button>
                        )}

                        <button
                          onClick={() => {
                            this.props.history.push(
                              generatePath("/signup/contact/userinfo/:accessId", {
                                accessId: this.state.profile.accessId
                              })
                            );
                          }}
                          className="button-primary-square large"
                        >
                          {Resources.Next}
                        </button>
                      </div>
                    </div>
                  </Route>
                </Switch>
              </Route>
            </Switch>
          </Route>
          <Redirect to="/signup/account/login" />
        </Switch>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    accountsStore: store.accounts,
    registration: store.registration,
    perspectives: store.perspectives,
    networkStore: store.network
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(SignupView));
