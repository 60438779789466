import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import moment from "moment";

import Resources from "../lib/resources";
import ModalDialog from "./modalDialog";
import InviteCompanyUser from "./inviteCompanyUser";
import Card from "./library/card";
import CRUDList from "./library/crudList";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as aaDP } from "../store/accounts-actions";
import { dispatchToProps as regDP } from "../store/registration-actions";
import { dispatchToProps as invDP } from "../store/invite-actions";
import { dispatchToProps as errDP } from "../store/error-actions";
import { dispatchToProps as paDP } from "../store/perspectives-actions";
import { dispatchToProps as moDP } from "../store/modal-actions";

import { compareStrings, isEmailValid, isEmpty, compareDates } from "../lib/utils";
import { handlePromiseError } from "../store/error-actions";
import MainContentHeader from "./main_content_header/mainContentHeader";
import { getPartnerType } from "../lib/perspectives";

class ManageCompanyUsers extends Component {
  constructor(props) {
    super(props);

    // This component is currently used in two places: one expects the company ID to be in the URL, the other in a prop. Once the URL-based usage 
    // is officially deprecated, this logic can be reworked.
    let companyId;

    if (this.props.match) {
      companyId = this.props.match.params.companyId;
    } else {
      companyId = this.props.companyId;
    }

    this.state = {
      invitingUser: false,
      revokeInvite: null,
      companyId
    };
  }

  componentDidMount() {
    window.addEventListener("refreshData", this.refreshData);
    this.componentDidUpdate();
  }

  componentWillUnmount() {
    window.removeEventListener("refreshData", this.refreshData);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isCompanyAdmin() === true &&
      this.props.invite.fetchingCompanyUserInvites === false &&
      this.props.invite.fetchedCompanyUserInvites === false &&
      this.props.invite.fetchCompanyUserInvitesFailed === false
    ) {
      this.refreshData();
    }
  }

  refreshData = () => {
    if (isEmpty(this.state.companyId)) {
      return;
    }

    if (this.props.isCompanyAdmin() === true && this.props.invite.fetchingCompanyUserInvites === false) {
      this.props.fetchCompanyUserInvites(this.state.companyId);
    }
  }

  getUserColumns(isAdmin) {
    const columns = [
      {
        header: Resources.Name.toLocaleUpperCase(),
        content: row => {
          return <div className="overflow-ellipsis">{row.givenName}</div>;
        },
        className: "highlight",
        width: "20%"
      },
      {
        header: Resources.Email.toLocaleUpperCase(),
        content: row => {
          return <div className="overflow-ellipsis">{row.email}</div>;
        },
        width: "25%"
      },
      {
        header: Resources.Role.toLocaleUpperCase(),
        content: row => {
          return <div className="overflow-ellipsis">{row.roleName}</div>;
        },
        width: "15%"
      }
    ];

    if (isAdmin) {
      columns.push({
        header: Resources.WorkspaceAccess.toLocaleUpperCase(),
        content: row => {
          let workspaces;
          if (row.role === 1 || row.perspectiveIds.length === this.props.perspectivesStore.companyPerspectives.length) {
            workspaces = Resources.All;
          } else {
            workspaces = row.perspectiveIds
              .map(perspective =>
                getPartnerType(
                  (this.props.perspectivesStore.companyPerspectivesMap[perspective] || {}).perspectiveName,
                  true
                )
              )
              .join(", ");
          }
          return <div className="overflow-ellipsis">{workspaces}</div>;
        },
        width: "25%"
      });
      columns.push({
        header: Resources.Actions.toLocaleUpperCase(),
        type: "actions",
        actions: [
          {
            type: "update",
            condition: row => !row.readOnly,
            onClick: row =>
              this.props.displayModal("userManagementModal", {
                user: row,
                companyId: this.state.companyId
              })
          },
          {
            type: "delete",
            condition: row => !row.readOnly,
            onClick: row =>
              this.props.displayModal("warnBeforeDeleteModal", {
                warningMessage: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: Resources.ConfirmDeleteUser(ReactDOMServer.renderToStaticMarkup(row.email))
                    }}
                  />
                ),
                delete: () => {
                  this.props.deleteCompanyUser(this.state.companyId, row.userId);
                  this.props.hideModal();
                }
              })
          },
          {
            type: "other",
            condition: row => isEmailValid(row.email),
            text: Resources.ResetPassword,
            // TODO: Commenting this out for now since making a POST to the reset endpoint causes a weird issue where the back end thinks the user 
            //       isn't logged in any more. We also want to show an indication that something happened.
            onClick: row => {
              // this.props.resetPassword(row.email, null).catch(rejection => {
              //   handlePromiseError(rejection, "Sending of the password reset email failed. Please try again.");
              // })
            }
          }
        ],
        width: "25%"
      });
    }

    return columns;
  }

  getInviteColumns(isAdmin) {
    let inviteColumns = [
      {
        header: Resources.Name.toLocaleUpperCase(),
        content: row => row.fullName,
        width: "20%"
      },
      {
        header: Resources.EmailAddress.toLocaleUpperCase(),
        content: row => row.email,
        style: {
          width: "30%",
          fontSize: "15px"
        }
      },
      {
        header: Resources.Status.toLocaleUpperCase(),
        content: row => row.status,
        width: "20%"
      }
    ];

    if (isAdmin) {
      inviteColumns.push({
        header: Resources.Actions.toLocaleUpperCase(),
        type: "actions",
        actions: [
          { type: "other", text: Resources.Revoke, onClick: row => this.setState({ revokeInvite: row }) },
          {
            type: "other",
            condition: row => {
              let duration = moment.duration(moment().diff(moment(row.modifiedDate)));
              if (duration.asHours() >= 24) {
                return {
                  type: "other",
                  text: Resources.Resend,
                  onClick: row => {
                    if (
                      this.props.invite.resendingInvite[row.userInviteId] === "pending" ||
                      this.props.invite.resendingInvite[row.userInviteId] === "failed"
                    ) {
                      this.props.resendCompanyUserInvite(this.state.companyId, row.userInviteId);
                    }
                  }
                };
              } else {
                return null;
              }
            }
          }
        ],
        width: "30%"
      });
    }

    return inviteColumns;
  }

  render() {
    let {
      userStore,
      accounts: { companyRoles },
    } = this.props;

    const { invitingUser, revokeInvite, companyId } = this.state;

    let myUserId = userStore.decoded.sub || "";

    const perspectiveId =
      ((this.props.perspectivesStore.companyPerspectives || []).filter(
        i => i.perspectiveId === userStore.userConfig.defaultPerspective
      )[0] || (this.props.perspectivesStore.companyPerspectives[0] || {})).perspectiveId;

    const perspective = this.props.getCompanyPerspectiveById(perspectiveId);

    let users = companyRoles.map(cr => {
      let u = {
        ...cr,
        ...this.props.getUser(cr.userId),
        roleName: this.props.getUserRole(cr.role)
      };

      u.email = u.userName;

      if ((u.userId || "").toLowerCase() === myUserId.toLowerCase() || (u.isInherited && u.role === 1)) {
        u.readOnly = true;
      }

      return u;
    });

    users.sort((a, b) => {
      return compareStrings(a.givenName || "", b.givenName || "");
    });

    const isAdmin = this.props.isCompanyAdmin() === true;

    let userColumns = this.getUserColumns(isAdmin);
    let modalDialog = null;
    let invites = [];
    let inviteColumns = [];

    if (isAdmin) {
      let existingEmails = {};

      invites = this.props.invite.invites
        .filter(invite => {
          return isEmpty(invite.invitedUserId) && isEmpty(invite.companyInvite.companyName);
        })
        .map(invite => {
          let res = { ...invite };
          existingEmails[invite.email.toLocaleLowerCase()] = true;
          res.pending = isEmpty(res.invitedUserId);
          res.statusid = "pending";
          res.status = res.pending ? Resources.Pending : Resources.Accepted;

          let { userInviteId } = invite;
          if (this.props.invite.resendingInvite[userInviteId]) {
            res.statusid = "resending";
            res.status = Resources.Resending;
          } else if (this.props.invite.resentInvite[userInviteId]) {
            res.statusid = "resent";
            res.status = Resources.Resent;
          } else if (this.props.invite.resendInviteFailed[userInviteId]) {
            res.statusid = "failed";
            res.status = Resources.Retry;
          }

          if (isAdmin && res.pending) {
            let duration = moment.duration(moment().diff(moment(res.modifiedDate)));
            if (duration.asHours() >= 24) {
              res.resend = (
                <span
                  className={`blue-font-white-bg clickable ${
                    res.statusid === "pending" || res.statusid === "failed" ? "" : "disabled"
                  }`}
                  onClick={() => {
                    if (res.statusid === "pending" || res.statusid === "failed") {
                      this.props.resendCompanyUserInvite(companyId, res.userInviteId);
                    }
                  }}
                >
                  {Resources.Resend}
                </span>
              );
            }
            res.revoke = (
              <span className="blue-font-white-bg clickable" onClick={() => this.setState({ revokeInvite: res })}>
                {Resources.Revoke}
              </span>
            );
          }

          res.email = <span>{res.email}</span>;
          return res;
        });
      invites.sort((a, b) => {
        if (a.pending !== b.pending) {
          return a.pending ? -1 : 1;
        }
        return 0 - compareDates(a.modifiedDate, b.modifiedDate);
      });

      inviteColumns = this.getInviteColumns(isAdmin);

      users
        .filter(user => {
          return isEmpty(user) === false && isEmpty(user.userName) === false;
        })
        .forEach(user => {
          existingEmails[user.userName.toLocaleLowerCase()] = true;
        });

      if (isEmpty(modalDialog) && invitingUser === true) {
        modalDialog = (
          <InviteCompanyUser
            existingEmails={existingEmails}
            companyId={companyId}
            perspective={perspective}
            onClosed={() => {
              this.setState({ invitingUser: false });
            }}
            toggle={() => {
              this.setState({ invitingUser: false });
            }}
            onCancel={() => {
              this.setState({ invitingUser: false });
            }}
          />
        );
      }

      if (isEmpty(modalDialog) && !isEmpty(revokeInvite)) {
        modalDialog = (
          <ModalDialog
            isOpen={true}
            toggle={() => this.setState({ revokeInvite: null })}
            title={Resources.Confirm}
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: Resources.ConfirmRevokeInvite(ReactDOMServer.renderToStaticMarkup(revokeInvite.email))
                }}
              />
            }
            footer={
              <div className="float-right">
                <button
                  className="cancel-button"
                  onClick={() => {
                    this.setState({ revokeInvite: null });
                  }}
                >
                  {Resources.Cancel}
                </button>
                <button
                  className="delete-button"
                  onClick={() => {
                    this.props.revokeCompanyUserInvite(companyId, revokeInvite.userInviteId);
                    this.setState({ revokeInvite: null });
                  }}
                >
                  {Resources.Revoke}
                </button>
              </div>
            }
          />
        );
      }
    }

    return (
      <React.Fragment>
        {modalDialog}

        <div className="manage-content">
          <Card maxWidth={"80em"} className="card-crud-list">
            <CRUDList
              columns={userColumns}
              data={users}
              title={Resources.UserList}
              description={Resources.UserListDescription}
              noHover={true}
              onCreate={
                isAdmin
                  ? () => {
                      this.setState({ invitingUser: true });
                    }
                  : null
              }
              createText={Resources.AddUser}
            />
          </Card>
          {isAdmin && (
            <Card maxWidth={"80em"} className="mt-4 card-crud-list">
              <CRUDList
                columns={inviteColumns}
                data={invites}
                title={Resources.InviteList}
                description={Resources.InviteListDescription}
                noHover={true}
              />
            </Card>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    accounts: store.accounts,
    userStore: store.user,
    invite: store.invite,
    perspectivesStore: store.perspectives
  };
};

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...aaDP(dispatch),
  ...regDP(dispatch),
  ...invDP(dispatch),
  ...errDP(dispatch),
  ...paDP(dispatch),
  ...moDP(dispatch)
});

export default connect(storeToProps, dispatchToProps)(ManageCompanyUsers);
