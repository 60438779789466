import React, { Component } from "react";
import { Switch, Redirect, withRouter, Route, Link, generatePath } from "react-router-dom";
import { connect } from "react-redux";

import Resources from "../../../../lib/resources";
import { isEmpty, formatDate } from "../../../../lib/utils";

import Card from "../../../library/card";
import ViewContentHeader from "../viewContentHeader";
import IconArchive from "../../../library/icons/iconArchive";
import IconPhone from "../../../library/icons/iconPhone";
import IconMapPin from "../../../library/icons/iconMapPin";
import IconLinkedIn from "../../../library/icons/iconLinkedIn";
import IconClipboard from "../../../library/icons/iconClipboard";
import IconMail from "../../../library/icons/iconMail";
import IconSend from "../../../library/icons/iconSend";
import IconEdit from "../../../library/icons/iconEdit";
import TextArea from "../../../library/textAreaInput";
import MainLoader from "../../../mainLoader";
import IconAlertCircle from "../../../library/icons/iconAlertCircle";
import ContactUpdateForm from "../profileUpdate/contactUpdateForm";

import { dispatchToProps as netDP } from "../../../../store/network-actions";
import { dispatchToProps as modDP } from "../../../../store/modal-actions";
import { dispatchToProps as persDP } from "../../../../store/perspectives-actions";

import IconTrash from "../../../library/icons/iconTrash";
import DepartmentView from "../../../departmentView";
import IconBriefcase from "../../../library/icons/iconBriefcase";
import IconLock from "../../../library/icons/iconLock";
import IconUnlock from "../../../library/icons/iconUnlock";
import IconAward from "../../../library/icons/iconAward";

const dispatchToProps = dispatch => ({
  ...netDP(dispatch),
  ...modDP(dispatch),
  ...persDP(dispatch)
});

class ContactView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notesEditMode: false,
      notes: [],
      newNote: "",
      editMode: false
    };
    this.toggleNotesEditMode = this.toggleNotesEditMode.bind(this);
    this.postNoteChanges = this.postNoteChanges.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount() {
    this.refreshData();
    this.refreshTimer = setInterval(() => this.refreshData(), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimer);
  }

  refreshData() {
    this.props.fetchContactProfile(this.props.match.params.perspectiveId, this.props.match.params.contactId);
    this.props.fetchMyProfile();
    this.props.fetchNotes(this.props.match.params.contactId);
  }

  toggleNotesEditMode() {
    this.setState({ notesEditMode: !this.state.notesEditMode });
  }

  postNoteChanges() {
    let newNote = this.state.newNote;
    if (newNote !== "") {
      let request = {
        note: newNote
      };
      this.props.postNotes(this.props.match.params.contactId, request).then(response => {
        this.props.fetchNotes(this.props.match.params.contactId);
      });
    }
    this.toggleNotesEditMode();
  }

  deleteNote(note) {
    this.props.deleteNote(this.props.match.params.contactId, note.entityGlobalIdentifierNoteKey).then(response => {
      this.props.fetchNotes(this.props.match.params.contactId);
    });
  }

  renderNotesCard() {
    let contactId = this.props.match.params.contactId;
    return (
      <Card className="card-notes">
        <div className="header">
          <h2>{Resources.Notes.toLocaleUpperCase()}</h2>
          {this.state.notesEditMode ? null : (
            <React.Fragment>
              <div className="header-notes" onClick={this.toggleNotesEditMode}>
                <IconEdit className="icon-button" height={16} />
              </div>
            </React.Fragment>
          )}
        </div>
        {(this.props.gettingNotes && this.props.notes[contactId] === undefined) || this.props.postingNotes ? (
          <div className="notes-loader-container">
            <MainLoader fullScreen={true} />
          </div>
        ) : isEmpty(this.props.notes[contactId] || []) && !this.state.notesEditMode ? (
          <React.Fragment>
            <div className="notes-empty-container">
              <svg viewBox="0 0 268 163" height="75px">
                <g fill="none" fillRule="evenodd">
                  <path fill="#FFF" d="M-586-192H854V832H-586z" />
                  <path fill="#FFF" d="M-586-40H854v871H-586z" />
                  <path
                    fill="#F4F3FF"
                    d="M256.633663 24.0594059c6.2009 0 11.227723 5.0268228 11.227723 11.2277228 0 6.2009001-5.026823 11.2277228-11.227723 11.2277228h-64.158415c6.2009 0 11.227722 5.0268227 11.227722 11.2277228 0 6.2009-5.026822 11.2277227-11.227722 11.2277227h35.287128c6.2009 0 11.227723 5.0268227 11.227723 11.2277228s-5.026823 11.2277228-11.227723 11.2277228h-16.318553c-7.818527 0-14.156694 5.0268227-14.156694 11.2277224 0 4.133934 3.207921 7.876508 9.623762 11.227723 6.2009 0 11.227723 5.026823 11.227723 11.227723 0 6.2009-5.026823 11.227723-11.227723 11.227723H73.7821782c-6.2009 0-11.2277228-5.026823-11.2277228-11.227723 0-6.2009 5.0268228-11.227723 11.2277228-11.227723H11.2277228C5.02682271 113.881188 0 108.854365 0 102.653465c0-6.2008997 5.02682271-11.2277224 11.2277228-11.2277224h64.1584158c6.2009001 0 11.2277228-5.0268227 11.2277228-11.2277228S81.5870387 68.970297 75.3861386 68.970297H35.2871287c-6.2009 0-11.2277228-5.0268227-11.2277228-11.2277227 0-6.2009001 5.0268228-11.2277228 11.2277228-11.2277228h64.1584159c-6.2009001 0-11.2277228-5.0268227-11.2277228-11.2277228 0-6.2009 5.0268227-11.2277228 11.2277228-11.2277228H256.633663zm0 44.9108911c6.2009 0 11.227723 5.0268227 11.227723 11.2277228s-5.026823 11.2277228-11.227723 11.2277228c-6.2009 0-11.227722-5.0268227-11.227722-11.2277228s5.026822-11.2277228 11.227722-11.2277228z"
                  />
                  <path
                    fill="#FFF"
                    stroke="#BEB6FF"
                    strokeLinecap="round"
                    strokeWidth="3.5"
                    d="M171.09746275 22.45544561l14.91985216 108.81705333 1.34044493 10.91704788c.43182842 3.51696024-2.0691646 6.71808531-5.58612484 7.14991373l-93.92827812 11.53294239c-3.51695965.43182835-6.71808492-2.06916464-7.14991334-5.58612488L66.23251038 37.51142872c-.21591419-1.75847992 1.03458241-3.35904247 2.79306224-3.57495665l.03332354-.00409161 7.79264977-.87395535m6.3012086-.70683126l7.35744625-.82531361"
                  />
                  <path
                    fill="#DEDAFF"
                    d="M167.03644893 29.30305224l13.5046427 98.61747678 1.21477826 9.89357497c.39134452 3.18724535-1.84459613 6.08450974-4.99411182 6.47122164l-84.11487626 10.32800815c-3.14951618.38671196-6.01995084-1.88356838-6.41129536-5.07081373L73.01608092 41.87828745c-.13461316-1.0963362.64501741-2.09421783 1.74135362-2.22883099l11.60477308-1.42488697"
                  />
                  <path
                    fill="#FFF"
                    stroke="#BEB6FF"
                    strokeWidth="3.5"
                    d="M176.198643 1.75H99.7113243c-.6213204 0-1.1838204.25183983-1.5909903.65900974-.4071699.40716992-.6590097.96966992-.6590097 1.59099026v126.732673c0 .621321.2518398 1.183821.6590097 1.590991.4071699.407169.9696699.659009 1.5909903.659009h99.4653467c.62132 0 1.18382-.25184 1.59099-.659009.40717-.40717.65901-.96967.65901-1.590991V26.9638138c0-.5969784-.237244-1.1694856-.659502-1.5914824L177.789141 2.40851759C177.367224 1.98686182 176.795139 1.75 176.198643 1.75z"
                  />
                  <path
                    stroke="#BEB6FF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.5"
                    d="M175.909344 3.85404742V22.4554455c0 2.6575286 2.154353 4.8118812 4.811881 4.8118812h12.72517"
                  />
                  <path
                    stroke="#DEDAFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.5"
                    d="M114.958849 27.2673267h41.70297m-41.70297 19.2475248h68.970297m-68.970297 20.8514851h68.970297m-68.970297 20.8514852h68.970297m-68.970297 20.8514852h41.70297"
                  />
                </g>
              </svg>
              <p>{Resources.NothingToSeeHereNotes}</p>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {(this.props.notes[contactId] || []).map((note, i) => {
              return (
                <div className="note-container">
                  <div className="note-button-container">
                    <div className="note-body">{note.note}</div>
                    {!this.state.notesEditMode ? null : (
                      <React.Fragment>
                        <div
                          key={i}
                          onClick={() => {
                            this.deleteNote(note);
                            let newNotes = [...this.state.notes];
                            newNotes.splice(i, 1);
                            this.setState({ notes: newNotes });
                          }}
                        >
                          <IconTrash className="icon-button" height={16} />
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  <p className="note-subtext">
                    {note.userFullName === null ? null : note.userFullName + " "}
                    {formatDate(note.createdDate)}
                  </p>
                </div>
              );
            })}
          </React.Fragment>
        )}
        {this.state.notesEditMode ? (
          <div>
            <div className="note-container">
              <TextArea
                rows={4}
                inputOnChange={e => {
                  let value = e.target.value;
                  this.setState({ newNote: value });
                }}
              />
            </div>
            <div className="notes-button-container">
              <button onClick={this.postNoteChanges} className="button-primary-square">
                {Resources.Save}
              </button>
            </div>
          </div>
        ) : null}
      </Card>
    );
  }

  toggleEditMode() {
    this.setState({ editMode: false });
    this.props.fetchContactProfile(this.props.match.params.perspectiveId, this.props.match.params.contactId);
  }

  render() {
    const { match = { params: {} }, gettingContactProfiles } = this.props;

    let roles = {
      1: "CFO",
      2: "Manager/Controller",
      3: "Disputes",
      4: "Payments",
      5: "Remittance",
      6: "Primary",
      7: "Secondary"
    };

    let selectedContact =
      this.props.getContactProfile(match.params.perspectiveId, this.props.match.params.contactId) || {};

    const selectedContactCompanyName = selectedContact.vendorName || selectedContact.custName;
    const selectedContactCompanyGlobalKey = selectedContact.vendorGlobalKey || selectedContact.custGlobalKey;

    const contactCompanyLink = selectedContactCompanyGlobalKey && (
      <Link
        to={generatePath(`/v2/:companyId/:perspectiveId/network/:subView/:selectedContextGroupId`, {
          ...match.params,
          view: "activities",
          selectedContextGroupId: selectedContactCompanyGlobalKey,
          subView: "accounts"
        })}
        data-test-id="contact-profile__company-link"
      >
        {selectedContactCompanyName}
      </Link>
    );

    const gettingSelectedContact = gettingContactProfiles[match.params.contactId];
    const displayProfileLoadingState =
      isEmpty(selectedContact.email || selectedContact.emailAddr) && gettingSelectedContact;

    // let subViews = [
    //   //{ subView: "messages", display: Resources.Messages.toLocaleUpperCase() },
    //   { subView: "profile", display: Resources.Profile.toLocaleUpperCase() }
    // ];

    if (this.props.networkStore.failedGetCustomerContacts[this.props.match.params.contactId]) {
      return (
        <Redirect
          to={generatePath(`/v2/:companyId/:perspectiveId/network/contacts`, {
            companyId: this.props.match.params.companyId,
            perspectiveId: this.props.match.params.perspectiveId
          })}
        />
      );
    }

    return (
      <React.Fragment>
        <Redirect
          exact
          from={`/v2/:companyId/:perspectiveId/network/contacts/:contactId`}
          to={match.url + "/profile"}
        />
        <Route path={`/v2/:companyId/:perspectiveId/network/contacts/:contactId/:subView`}>
          {displayProfileLoadingState && isEmpty(selectedContact.contactName) ? null : (
            <ViewContentHeader
              //links={subViews}
              contactViewing={selectedContact}
              onClickBackRoute={generatePath(
                `/v2/:companyId/:perspectiveId/network/${selectedContact.isArchived ? "contactArchive" : "contacts"}`,
                this.props.match.params
              )}
            />
          )}
          <div className="view-content">
            {this.state.editMode ? (
              <ContactUpdateForm
                mode={this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"}
                small
                edit
                toggleEditMode={this.toggleEditMode}
                perspectiveId={this.props.match.params.perspectiveId}
              />
            ) : (
              <Switch>
                <Route exact path={`/v2/:companyId/:perspectiveId/network/contacts/:contactId/profile`}>
                  {displayProfileLoadingState ? (
                    <MainLoader fullScreen className="pt-5" />
                  ) : (
                    <React.Fragment>
                      <div className="action-buttons">
                        <button
                          className="button-primary"
                          onClick={() => {
                            if (selectedContact.isPrivate) {
                              this.props.displayModal("sendPrivateContactRequestDialog", {
                                perspectiveId: this.props.match.params.perspectiveId,
                                contactId: this.props.match.params.contactId,
                                selectedContact: selectedContact,
                                selectedPerspectiveType: this.props.perspectiveStore.selectedPerspectiveType
                              });
                            } else {
                              this.props.displayNotification("sendProfileUpdateRequestNotifiation");
                              this.props
                                .sendContactProfileUpdateRequest([selectedContact.entityGlobalKey])
                                .then(response => {
                                  this.props.fetchOpenProfileUpdateRequests(match.params.perspectiveId);
                                  this.props.fetchOpenOnboardingRequests(match.params.perspectiveId);
                                  this.props.fetchActivityList(match.params.perspectiveId, {
                                    top: 100,
                                    skip: 0
                                  });
                                });
                            }
                          }}
                        >
                          <IconSend height="20" className="button-primary-icon" />
                          {Resources.RequestProfileUpdate}
                        </button>
                        {selectedContact.isPrivate ? (
                          <button
                            className="button-primary"
                            onClick={() => {
                              this.props
                                .updateContactPrivacy(
                                  this.props.match.params.perspectiveId,
                                  this.props.match.params.contactId,
                                  !selectedContact.isPrivate,
                                  this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                                )
                                .then(response => {
                                  this.props.fetchContactProfile(
                                    this.props.match.params.perspectiveId,
                                    this.props.match.params.contactId
                                  );
                                  this.props.fetchContactList(this.props.match.params.perspectiveId);
                                });
                              this.props.displayNotification("contactMarkedPrivateNotification");
                            }}
                          >
                            <IconUnlock height="20" className="button-primary-icon" />
                            {Resources.MarkAsPublic}
                          </button>
                        ) : (
                          <button
                            className="button-primary"
                            onClick={() => {
                              this.props
                                .updateContactPrivacy(
                                  this.props.match.params.perspectiveId,
                                  this.props.match.params.contactId,
                                  !selectedContact.isPrivate,
                                  this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                                )
                                .then(response => {
                                  this.props.fetchContactProfile(
                                    this.props.match.params.perspectiveId,
                                    this.props.match.params.contactId
                                  );
                                  this.props.fetchContactList(this.props.match.params.perspectiveId);
                                });
                              this.props.displayNotification("contactMarkedPrivateNotification");
                              this.props.fetchContactList(this.props.match.params.perspectiveId);
                            }}
                          >
                            <IconLock height="20" className="button-primary-icon" />
                            {Resources.MarkAsPrivate}
                          </button>
                        )}
                        <button
                          className="button-primary"
                          onClick={() => {
                            this.props
                              .markContactPrimary(
                                this.props.match.params.perspectiveId,
                                this.props.match.params.contactId,
                                this.props.perspectiveStore.selectedPerspectiveType === "vendors" ? "ap" : "ar"
                              )
                              .then(response => {
                                this.props.fetchContactProfile(
                                  this.props.match.params.perspectiveId,
                                  this.props.match.params.contactId
                                );
                              });
                            this.props.fetchContactList(this.props.match.params.perspectiveId);
                            this.props.displayNotification("markingAsPrimaryNotification");
                          }}
                        >
                          <IconAward height={22} className="button-primary-icon" />
                          {Resources.MarkAsPrimary}
                        </button>
                        <button
                          className="button-primary"
                          onClick={() => {
                            this.setState({ editMode: true });
                          }}
                        >
                          <IconEdit height="20" className="button-primary-icon" />
                          {Resources.EditProfile}
                        </button>
                      </div>
                      <div className="profile-container">
                        <Card className="account-profile__card">
                          <div
                            className="account-profile__card__last-updated"
                            data-test-id="contact-profile__last-updated"
                          >
                            {Resources.UpdatedDate(formatDate(selectedContact.updateDate))}
                          </div>
                          <div className="">
                            {isEmpty(selectedContact.title) &&
                            isEmpty(selectedContactCompanyName) &&
                            (isEmpty(selectedContact.contactRoleId) || selectedContact.contactRoleId === 0) ? null : (
                              <div className="account-profile-item" data-test-id="contact-profile__title">
                                <div className="account-profile-item__icon" data-test-id="contact-profile__title--icon">
                                  <IconBriefcase height={18} />
                                </div>
                                <div
                                  className="accont-profile-item__content"
                                  data-test-id="contact-profile__title--content"
                                >
                                  {!isEmpty(selectedContact.title) && selectedContact.title + ", "}
                                  {!isEmpty(selectedContact.contactRoleId) && selectedContact.contactRoleId !== 0 ? (
                                    <span>
                                      {roles[selectedContact.contactRoleId]}, {contactCompanyLink}
                                    </span>
                                  ) : (
                                    contactCompanyLink
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="account-profile-item" data-test-id="contact-profile__email">
                              <div className="account-profile-item__icon" data-test-id="contact-profile__email--icon">
                                <IconMail height={18} />
                              </div>
                              <div className="account-profile-item__content">
                                {selectedContact.emailAddr === "" || selectedContact.emailAddr === null ? (
                                  <div
                                    onClick={() => this.setState({ editMode: true })}
                                    className="missing-email-address-container"
                                    data-test-id="contact-profile__email--content"
                                  >
                                    <IconAlertCircle height={20} />
                                    <span>{Resources.MissingEmailAddress}</span>
                                  </div>
                                ) : (
                                  <Link
                                    to={generatePath(
                                      `/v2/:companyId/:perspectiveId/network/:subView(contacts)/:selectedContextGroupId/:view(activities|documents|statements|profile|settings)/:selectedItemId?/:activityType?`,
                                      {
                                        ...match.params,
                                        view: "activities",
                                        selectedContextGroupId: selectedContact.entityGlobalKey || "mine",
                                        selectedItemId: "new",
                                        activityType: "conversation",
                                        subView: "contacts"
                                      }
                                    )}
                                    data-test-id="contact-profile__email--content"
                                  >
                                    {selectedContact.emailAddr}
                                  </Link>
                                )}
                              </div>
                            </div>
                            {!isEmpty(selectedContact.phone) && (
                              <div className="account-profile-item" data-test-id="contact-profile__phone">
                                <div
                                  className="account-profile-item__icon"
                                  data-test-id="contact-profile__phone--content"
                                >
                                  <IconPhone height={22} />
                                </div>
                                <div
                                  className="account-profile-item__content"
                                  data-test-id="contact-profile__phone--icon"
                                >
                                  {selectedContact.phone}
                                </div>
                              </div>
                            )}
                            {!isEmpty(selectedContact.fax) && (
                              <div className="account-profile-item" data-test-id="contact-profile__fax">
                                <div className="account-profile-item__icon" data-test-id="contact-profile__fax--icon">
                                  <IconArchive height={16} />
                                </div>
                                <div
                                  className="account-profile-item__content"
                                  data-test-id="contact-profile__fax--content"
                                >
                                  {selectedContact.fax}
                                </div>
                              </div>
                            )}
                            {!isEmpty(
                              [
                                selectedContact.addrLn1,
                                selectedContact.city,
                                selectedContact.state,
                                selectedContact.postalcode,
                                selectedContact.country
                              ].filter(value => !isEmpty(value))
                            ) && (
                              <div className="account-profile-item" data-test-id="contact-profile__address">
                                <div
                                  className="account-profile-item__icon"
                                  data-test-id="contact-profile__address--icon"
                                >
                                  <IconMapPin height={22} />
                                </div>
                                <div
                                  className="account-profile-item__content"
                                  data-test-id="contact-profile__address--content"
                                >
                                  <div data-test-id="contact-profile__address__addrLine1">
                                    {selectedContact.addrLn1}
                                  </div>
                                  <div data-test-id="contact-profile__address__city-state-postal">
                                    {selectedContact.city}, {selectedContact.state} {selectedContact.postalcode}
                                  </div>
                                  <div data-test-id="contact-profile__address__country">{selectedContact.country}</div>
                                </div>
                              </div>
                            )}
                            {!isEmpty(selectedContact.linkedInProfile) && (
                              <div className="account-profile-item" data-test-id="contact-profile__linkedIn">
                                <div
                                  className="account-profile-item__icon"
                                  data-test-id="contact-profile__linkedIn--icon"
                                >
                                  <IconLinkedIn height={19} />
                                </div>
                                <div
                                  className="account-profile-item__content"
                                  data-test-id="contact-profile__linkedIn--content"
                                >
                                  <a rel="norefferer noopener" href={selectedContact.linkedInProfile}>
                                    {selectedContact.linkedInProfile}
                                  </a>
                                </div>
                              </div>
                            )}
                            {!isEmpty(selectedContact.notes) && (
                              <div className="account-profile-item" data-test-id="contact-profile__notes">
                                <div className="account-profile-item__icon" data-test-id="contact-profile__notes--icon">
                                  <IconClipboard height={20} />
                                </div>
                                <div
                                  className="account-profile-item__content"
                                  data-test-id="contact-profile__notes--content"
                                >
                                  {selectedContact.notes}
                                </div>
                              </div>
                            )}
                          </div>
                        </Card>
                        {this.renderNotesCard()}
                      </div>
                    </React.Fragment>
                  )}
                </Route>
                <Route
                  path={
                    "/v2/:companyId/:perspectiveId/network/:subView(contacts)/:selectedContextGroupId/:view(activities|documents|statements|profile|settings)/:selectedItemId?/:activityType?"
                  }
                >
                  <DepartmentView />
                </Route>
              </Switch>
            )}
          </div>
        </Route>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    gettingContactProfiles: store.network.gettingContactProfiles,
    notes: store.network.notes,
    networkStore: store.network,
    gettingNotes: store.network.gettingNotes,
    postingNotes: store.network.postingNotes,
    contactName: store.network.custContact.contactName,
    perspectiveStore: store.perspectives
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ContactView));
