import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "react-modal";

import { dispatchToProps as moDP } from "../../../store/modal-actions";
import { dispatchToProps as manDP } from "../../../store/manage-actions";

import Resources from "../../../lib/resources";
import IconClose from "../../library/icons/iconClose";
import { isEmpty } from "../../../lib/utils";
import TextInput from "../../library/textInput";
import TextAreaInput from "../../library/textAreaInput";
import Checkbox from "../../library/checkbox";
import RadioInput from "../../library/radioInput";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...manDP(dispatch)
});

class ContactFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactAddressBookId: null,

      fullName: "",
      title: "",
      email: "",
      phone: "",
      fax: "",
      address: {
        line1: "",
        line2: "",
        line3: "",
        city: "",
        region: "",
        postalCode: "",
        country: ""
      },
      type: "person",
      notes: "",
      isPrimary: false,
      showFlyout: true
    };

    this.submitContact = this.submitContact.bind(this);
    this.hideFlyout = this.hideFlyout.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.contact)) {
      this.setState(this.props.contact);
    }
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  submitContact() {
    let { companyId } = this.props;

    if (isEmpty(this.state.addressBookContactId)) {
      this.props.displayNotification("contactSubmittingNotification", { isNew: true });
      this.props
        .putAddressBookContact(companyId, this.state)
        .then(response => {
          this.hideFlyout();
        })
        .catch(error => {});
    } else {
      this.props.displayNotification("contactSubmittingNotification", { isNew: false });
      this.props
        .postAddressBookContact(companyId, this.state.addressBookContactId, this.state)
        .then(response => {
          this.hideFlyout();
        })
        .catch(error => {});
    }
  }

  render() {
    const disableSend =
      isEmpty(this.state.fullName) ||
      isEmpty(this.state.email) ||
      this.props.manageStore.postingAddressBookContact ||
      this.props.manageStore.puttingAddressBookContact;

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={this.props.hideFlyout}
        contentLabel={Resources.AddNewContact}
        className="flyout"
        overlayClassName="flyout-overlay"
        closeTimeoutMS={400}
      >
        <div className="flyout-heading">
          <div>{Resources.AddNewContact}</div>
          <div onClick={this.hideFlyout} className="flyout-heading-close">
            <IconClose />
          </div>
        </div>
        <div className="flyout-content">
          <div className="d-flex align-items-center justify-content-between text-input-row">
            <TextInput
              label={Resources.Name}
              labelInline={false}
              width="20rem"
              textValue={this.state.fullName}
              inputOnChange={e => {
                this.setState({ fullName: e.target.value });
              }}
            />
            <TextInput
              label={Resources.JobTitle}
              labelInline={false}
              width="20rem"
              textValue={this.state.title}
              className="pr-5"
              inputOnChange={e => {
                this.setState({ title: e.target.value });
              }}
            />
          </div>

          <TextInput
            label={Resources.Email}
            labelInline={false}
            width="20rem"
            textValue={this.state.email}
            inputOnChange={e => {
              this.setState({ email: e.target.value });
            }}
          />
          <TextInput
            label={Resources.PhoneNumber}
            labelInline={false}
            width="20rem"
            textValue={this.state.phone}
            inputOnChange={e => {
              this.setState({ phone: e.target.value });
            }}
          />
          <TextInput
            label={Resources.Fax}
            labelInline={false}
            width="20rem"
            textValue={this.state.fax}
            inputOnChange={e => {
              this.setState({ fax: e.target.value });
            }}
          />
          <hr style={{ paddingBottom: "1rem" }} />
          <TextInput
            label={Resources.CompanyStreetAddress}
            labelInline={false}
            width="20rem"
            textValue={this.state.address.line1}
            inputOnChange={e => {
              this.setState({ address: { ...this.state.address, line1: e.target.value } });
            }}
          />
          <TextInput
            label={Resources.Country}
            labelInline={false}
            width="20rem"
            textValue={this.state.address.country}
            inputOnChange={e => {
              this.setState({ address: { ...this.state.address, country: e.target.value } });
            }}
          />
          <div className="d-flex align-items-center text-input-row">
            <TextInput
              label={Resources.City}
              labelInline={false}
              width="20rem"
              textValue={this.state.address.city}
              inputOnChange={e => {
                this.setState({ address: { ...this.state.address, city: e.target.value } });
              }}
            />
            <TextInput
              label={Resources.StateRegion}
              labelInline={false}
              width="9rem"
              textValue={this.state.address.region}
              inputOnChange={e => {
                this.setState({ address: { ...this.state.address, region: e.target.value } });
              }}
            />
            <TextInput
              label={Resources.ZipCode}
              labelInline={false}
              width="9rem"
              className="pr-5"
              textValue={this.state.address.postalCode}
              inputOnChange={e => {
                this.setState({ address: { ...this.state.address, postalCode: e.target.value } });
              }}
            />
          </div>
          <hr style={{ paddingBottom: "1rem" }} />
          <RadioInput
            inline
            label={Resources.Type}
            inputs={[
              { label: Resources.Person, value: "person" },
              { label: Resources.Business, value: "business" }
            ]}
            radioName="contactType"
            value={this.state.type}
            onChange={item => {
              this.setState({
                type: item.value
              });
            }}
          />
          <div style={{ marginBottom: "2rem" }}></div>
          <TextAreaInput
            label={Resources.Notes}
            labelInline={false}
            optional
            width="33rem"
            textValue={this.state.notes}
            inputOnChange={e => {
              this.setState({ notes: e.target.value });
            }}
            rows={8}
          />
          <div>
            <Checkbox
              className="pt-2"
              label={Resources.MakePrimaryContact}
              checked={this.state.isPrimary}
              onChange={() => {
                this.setState({ isPrimary: !this.state.isPrimary });
              }}
              style={{ paddingBottom: "4rem" }}
            />
          </div>
          <button
            data-test-id="contacts__save"
            className="portal-button-green"
            onClick={this.submitContact}
            disabled={disableSend}
          >
            {Resources.Save}
          </button>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    conversationsStore: store.conversations,
    ledgerStore: store.ledger,
    accountsStore: store.accounts,
    manageStore: store.manage
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ContactFlyout));
