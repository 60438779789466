import React, { Component} from "react";
import { connect } from "react-redux";
import moment from "moment";
import Tippy from "@tippyjs/react";
import data from "../../lib/data";
import Icons from "../../lib/icons";
import Resources from "../../lib/resources";
import {
  formatDate,
  formatCurrency,
  isEmpty,
  stringToSentenceCase,
  find,
  openUrlForDownload,
  getCurrencySymbol,
  isCustomerPortal
} from "../../lib/utils";

import { dispatchToProps as saDP } from "../../store/statements-actions";
import { dispatchToProps as cmpDP } from "../../store/company-actions";
import { dispatchToProps as aaDP } from "../../store/accounts-actions";
import { dispatchToProps as cgDP } from "../../store/contextGroups-actions";
import { dispatchToProps as paDP } from "../../store/perspectives-actions";
import { dispatchToProps as moDP } from "../../store/modal-actions";
import { dispatchToProps as conDP } from "../../store/conversations-actions";
import { dispatchToProps as ledgDP } from "../../store/ledger-actions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Helmet } from "react-helmet";

import TableData from "../library/tableData";
import TableDataSortableHeader from "../library/tableDataSortableHeader";
import Card from "../library/card";
import AutoCompleteInput from "../library/autoCompleteInput";
import AutoPayIndicator from "./autoPayIndicator";
import MainLoader from "../mainLoader";
import StatementCurrencySelector from "./statementCurrencySelector";
import IconDollarSign from "../library/icons/iconDollarSign";
import IconMessage from "../library/icons/iconMessage";
import IconHelp from "../library/icons/iconHelp";
import IconStar from "../library/icons/iconStar";
import IconDownload from "../library/icons/iconDownload";
import IconExport from "../library/icons/iconExport";
import IconFilter from "../library/icons/iconFilter";
import IconAlert from "../library/icons/iconAlert";
import IconAlertCircle from "../library/icons/iconAlertCircle";
import IconAttachment from "../library/icons/iconAttachment";
import IconArrowRight from "../library/icons/iconArrowRight";
import IconInfo from "../library/icons/iconInfo";
import IconClose from "../library/icons/iconClose";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import TextInput from "../library/textInput";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(saDP),
    ...dispatch(aaDP),
    ...dispatch(cmpDP),
    ...dispatch(cgDP),
    ...dispatch(paDP),
    ...dispatch(moDP),
    ...dispatch(conDP),
    ...dispatch(ledgDP)
  };
};

  class OpenInvoices extends Component {
  constructor(props) {
    super(props);    
    this.state = {
      ...this.defaultState,
      openInvcColViewSett: [],
      openInvcDivWidth: 0      
    };
    this.wrapperRef = React.createRef();
  }

  defaultState = {
    currentSelection: [],
    searchTerm: "",
    fromDate: "2017-01-01",
    toDate: moment()
      .startOf("day")
      .format("YYYY-MM-DD"),
    fromDueDate: "2017-01-01",
    toDueDate: moment()
      .startOf("day")
      .format("YYYY-MM-DD") ,
      openInvcDivWidth: 0     
  };

  componentDidMount = () => {
                     
    const { companyId, perspectiveId, withCompanyId, pageRowCount, allAccountsSelected,viewName,settingId,userKey} = this.props;

   this.props.clearStatementsSearchResults();             
   let endpoint = 'v3/api/Statements/DefaultSelfServiceCustomerView/00000000-0000-0000-0000-000000000000';
   const paymentInfo = this.props.statementsStore.paymentInfo[withCompanyId];
     
  
   data
    .get(endpoint)
    .then(res => {
      const openInvcColViewSett = res.data.colSettings.columnSettings;                  
      this.setState({ openInvcColViewSett})
      if(res.data.defaultSortOrder.length >0 && res.data.defaultSortOrder[0].columnName!='' && res.data.defaultSortOrder[0].sortOrder!='' )
      {      
        this.props.updateOpenInvoicesSort(res.data.defaultSortOrder[0].columnName,res.data.defaultSortOrder[0].sortOrder);
      }
      this.props.fetchOpenInvoices(companyId, perspectiveId, withCompanyId, null, null, pageRowCount, null, null, null, allAccountsSelected);    
      this.props.fetchUnappliedOrPendingPayments(companyId, perspectiveId, withCompanyId, null, null, pageRowCount, null, allAccountsSelected);         
      document.addEventListener('mousedown', this.handleExitTooltip);         
      document.addEventListener('mouseover',this.handleonload);  
      this.tryUpdate();
    })    
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleExitTooltip)
    document.removeEventListener('mouseover', this.handleonload);
    this.toggleSelectRow(this.props.statementsStore.selectedKeys, this.props.statementsStore.selectedRows)
  }

  handleExitTooltip = (event) => {
    if(this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ showAccountBalanceHelperText: false});
    }
  }
  
 handleonload =(event) =>
  {           
    let documentOpenInvoice = document.getElementById("open-invoices__table__tablediv");  
    
    if(documentOpenInvoice!=null)
    {
     // this.state.openInvcDivWidth = documentOpenInvoice.clientWidth-10;  
      this.state.openInvcDivWidth = documentOpenInvoice.clientWidth + documentOpenInvoice. scrollLeft;     
    }     
  }
  
  componentDidUpdate = (prevProps) => {
    this.tryUpdate(prevProps);    
  }

  tryUpdate = (prevProps, paymentMade) => {
    const {
      companyId,
      perspectiveId,
      withCompanyId,
      pageRowCount,
      allAccountsSelected,
      statementsStore: {
        openFromDate,
        openToDate,
        openFromDueDate,
        openToDueDate,
        fetchedCurrencyStats,
        openInvoicesCurrencyStats,
        selectedCurrency,
        currencies    ,
        unappliedOrPendingPaymentsSkip,
        openInvoicesSkip,
        paymentsFromDueDate,
        paymentsToDueDate,   
      }
    } = this.props;

    if (isEmpty(perspectiveId) || isEmpty(withCompanyId)) {
      return;
    }

    if (this.sortHasChanged(prevProps) || this.dateFilterHasChanged(prevProps) || this.selectedCurrencyHasChanged() || paymentMade) {
      let filter = null;

      if (!isEmpty(openFromDate)) {
        filter = `IssueDate%20>=%20${openFromDate}%20and%20IssueDate%20<=%20${openToDate}`;
      }

      this.props.fetchOpenInvoices(
        companyId,
        perspectiveId,
        withCompanyId,
        openFromDueDate,
        openToDueDate,
        pageRowCount,
        openInvoicesSkip,
        null,
        filter,
        allAccountsSelected
      );
    }
    if (this.unAppliedPaymentSortHasChanged(prevProps) 
    || this.dateFilterHasChanged(prevProps) || this.selectedCurrencyHasChanged()) {
      this.props.fetchUnappliedOrPendingPayments(
        companyId,
        perspectiveId,
        withCompanyId,
        paymentsFromDueDate,
        paymentsToDueDate,
        pageRowCount,
        unappliedOrPendingPaymentsSkip,
        allAccountsSelected
      );
    }

    if (prevProps && prevProps.statementsStore.fetchedCurrencyStats === false && fetchedCurrencyStats === true) {
      if ((openInvoicesCurrencyStats[selectedCurrency] || {}).count === 0) {
        const currencyWithCount = find(currencies, currency => (openInvoicesCurrencyStats[currency] || {}).count > 0);
        currencyWithCount && this.props.setSelectedCurrency(currencyWithCount);
      }
    }
  }

  sortHasChanged = (prevProps) => {
    if (isEmpty(prevProps)) {
      return false;
    }
    if (
      this.props.statementsStore.openInvoicesSortDirection !== prevProps.statementsStore.openInvoicesSortDirection ||
      this.props.statementsStore.openInvoicesSortBy !== prevProps.statementsStore.openInvoicesSortBy
    ) {
      return true;
    }
    return false;
  }

  unAppliedPaymentSortHasChanged = (prevProps) => {
    if (isEmpty(prevProps)) {
      return false;
    }
    if (
      this.props.statementsStore.unappliedPaymentsSortDirection !== prevProps.statementsStore.unappliedPaymentsSortDirection ||
      this.props.statementsStore.unappliedPaymentsSortBy !== prevProps.statementsStore.unappliedPaymentsSortBy

    ) {
      return true;
    }
    return false;
  }

  dateFilterHasChanged = (prevProps) => {
    if (isEmpty(prevProps)) {
      return false;
    }

    const {
      statementsStore: { openFromDate, openToDate, openFromDueDate, openToDueDate }
    } = this.props;

    if (
      openFromDate !== prevProps.statementsStore.openFromDate ||
      openToDate !== prevProps.statementsStore.openToDate ||
      openFromDueDate !== prevProps.statementsStore.openFromDueDate ||
      openToDueDate !== prevProps.statementsStore.openToDueDate
    ) {
      return true;
    }
    return false;
  }

  selectedCurrencyHasChanged = () => {
    if (
      this.props.statementsStore.selectedCurrency &&
      this.props.statementsStore.openInvoicesCurrency &&
      this.props.statementsStore.selectedCurrency !== this.props.statementsStore.openInvoicesCurrency
    ) {
      return true;
    }
    return false;
  }

  toggleSelectRow = (key, row) => {
    this.props.setSelectedKeys(key, row);
  }
 
  handleLoadMore = (pageToLoad) => {
    let { companyId, perspectiveId, withCompanyId, pageRowCount, allAccountsSelected } = this.props;
    let top = pageRowCount;
    let skip = pageToLoad * pageRowCount - pageRowCount;
    this.props.fetchOpenInvoices(companyId, perspectiveId, withCompanyId, null, null, top, skip, null, null, allAccountsSelected);
  }

  handleSelectAll = (top) => {
    let { companyId, perspectiveId, withCompanyId, allAccountsSelected } = this.props;
    this.props.fetchOpenInvoices(companyId, perspectiveId, withCompanyId, null, null, top, 0, true, null, allAccountsSelected);
  }

  handleLoadMoreUnapplied = (pageToLoad) => {
    let { companyId, perspectiveId, withCompanyId, pageRowCount, allAccountsSelected } = this.props;
    let top = pageRowCount;
    let skip = pageToLoad * pageRowCount - pageRowCount;
    this.props.fetchUnappliedOrPendingPayments(companyId, perspectiveId, withCompanyId, null, null, top, skip, allAccountsSelected);
  }

  exportRows = (rows) => {
    if (isEmpty(rows)) {
      return null;
    }
    let { companyId, perspectiveId, withCompanyId, pageRowCount, statementsStore, allAccountsSelected } = this.props;
    let { openFromDate, openToDate, openFromDueDate, openToDueDate } = statementsStore;

    let options = {
      fromDate: openFromDate,
      toDate: openToDate,
      fromDueDate: openFromDueDate,
      toDueDate: openToDueDate
    };

    this.props.displayNotification("exportNotification");
    this.props.fetchTableExport(
      companyId,
      perspectiveId,
      withCompanyId,
      "open",
      pageRowCount,
      statementsStore.openInvoicesSkip,
      rows.map(i => i.id),
      options,
      allAccountsSelected
    );
  }

  displayRelatedConversation = (entry = {}, customerId) => {
    let { companyId, perspectiveId, withCompanyId, withContextGroupId } = this.props;
    let { ledgerHash } = entry;

    // When opening the message flyout, we need to pass in IDs for the company whose data is being accessed. When viewing all accounts, the
    // regular company ID prop value is going to be for the primary account, and using that will cause a cascade of requests that results in
    // an endless loader. This mimics what happens when you open the flyout while a single company is selected.
    if (this.props.allAccountsSelected) {
      companyId = customerId;
      perspectiveId = customerId;
    }

    this.props.displayFlyout("messageFlyout", {
      loading: true
    });

    this.props.fetchRelated(ledgerHash).then(() => {
      let relatedConversations = this.props.getRelated(ledgerHash);

      if (isEmpty(relatedConversations)) {
        return;
      }

      const conversation = relatedConversations.value[0];
      const conversationId = conversation.conversationId;
      const invoiceColumns = this.getOpenInvoiceColumns(false).splice(1);
      const isPortal = isCustomerPortal(this.props.history ? this.props.history.location.pathname : "");
      const useRedux = true;

      this.props.displayFlyout("messageFlyout", {
        conversationId,
        companyId,
        perspectiveId,
        withCompanyId,
        withContextGroupId,
        invoiceColumns,
        useRedux,
        isPortal
      });
    });
  }

  downloadAttachments = (attachments, fileName) => {
    if (attachments.length === 1) {
      openUrlForDownload(attachments[0].downloadUrl);
    } else if (attachments.length > 1) {
      this.props.displayNotification("downloadNotification");
      this.props.fetchAttachmentsZip(
        attachments.map(attachment => attachment.attachmentId),
        fileName
      );
    }
  }

  handleHideMakePaymentFlyout = () => {
    this.tryUpdate(null, true);
  }
   
  getOpenInvoiceColumns = (hover) => {
    let openinvoiceColumns = {}
    let { statementsStore, withCompanyId, allAccountsSelected, nationalAccount} = this.props;

    const allowPayments = (statementsStore.paymentInfo[withCompanyId] || {}).allowPayments;
    const allowDisputes = !isEmpty(
      (this.props.getCompanyInfo(this.props.withCompanyId).CompanySettings || {}).PortalDisputeActivityKey
    );
    let columnHeaderText = {}
    let cnt2 = 0;
    for(cnt2 = 0;cnt2<this.state.openInvcColViewSett.length;cnt2++)
    {    
      let key =  this.state.openInvcColViewSett[cnt2].dataField.toLocaleUpperCase();
      let headtext = this.state.openInvcColViewSett[cnt2].headerText;
      columnHeaderText[key] = headtext;
    }  
    const selectColName = "select";
    const selectCol = {
      type: "rowSelect",     
      name: selectColName,
      width: "10px",     
    };    
    openinvoiceColumns[selectColName] = selectCol

    const customerColName = "CUSTOMERNAME";
    const customerNameCol = {
      header: noSort =>
        noSort ? (
          Resources.Account.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.Account.toLocaleUpperCase()}
            sortKey={"CustName"}
          />
        ),
      sortable: noSort => noSort === false,
     // width:"20%",
      className: "customer-name",
      content: row => <span className="fw-500 customer-name" title={row.customerName}>{row.customerName}</span>,
      name: "customer-name"
    };
    openinvoiceColumns[customerColName] = customerNameCol

    const invoiceDateColName = "TRANDATE";
    const invoiceDateCol = {    
      header: noSort =>
        noSort ? (
          columnHeaderText[invoiceDateColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[invoiceDateColName]}
            sortKey={"IssueDate"}
          />
        ),
      sortable: noSort => noSort === false,
     // width:"10%",
      content: row => <span className="fw-500">{formatDate(row.issueDate, true, false)}</span>,    
      name: invoiceDateColName

    };
    openinvoiceColumns[invoiceDateColName] = invoiceDateCol 
    
    const invoiceNumColName = "TRANID";
    const invoiceNumCol = {
      header: noSort =>
        noSort ? (
          columnHeaderText[invoiceNumColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[invoiceNumColName]}
            sortKey={"id"}
          />
        ),
      sortable: noSort => noSort === false,
     // width:"30%",
      content: row => {
        let hasAttachment = !isEmpty(row.attachments);        
        return (
          <div className={`statements-invoice-id d-flex align-items-center ${hasAttachment ? "link" : ""}`}>
            <div className="flex-even overflow-ellipsis">
              {hasAttachment ? (
                <React.Fragment>
                  <button
                    className="invoice-num-attachment"
                    onClick={() => {
                      this.downloadAttachments(row.attachments, Resources.InvoiceNAttachments(row.id) + ".zip");
                    }}
                  >
                    <span className="invoice-num-attachment-link overflow-ellipsis" title={row.id}>
                      {row.id}
                    </span>
                    <IconAttachment height={15} style={{ marginLeft: "0.33rem", minWidth: "1rem" }} />
                  </button>
                </React.Fragment>
              ) : (
                <span className="overflow-ellipsis" title={row.id}>
                  {row.id}
                </span>
              )}
            </div>
            {(row.isDisputed || row.hasPromissoryNote) && (
              <div className="d-flex flex-column justify-content-start flex-even">
                {row.isDisputed && (
                  <div>
                    <button
                      data-test-id="open-invoices__button-dispute"
                      className="button-indicator"
                      onClick={() => this.displayRelatedConversation(row.activeDispute, row.customerId)}
                      style={{ marginBottom: row.hasPromissoryNote ? "0.4rem" : "" }}
                    >
                      {Resources.Disputed}
                    </button>
                  </div>
                )}
                {row.hasPromissoryNote && (
                  <div>
                    <button
                      data-test-id="open-invoices__button-promised"
                      className="button-indicator"
                      onClick={() =>
                        this.displayRelatedConversation(find(row.payments, payment => payment.paymentType === "Promise to Pay"), row.customerId)
                      }
                    >
                      {Resources.Promised}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      },  
        
      name: invoiceNumColName
    };
    openinvoiceColumns[invoiceNumColName] = invoiceNumCol

    const amountDueColName = "BALANCE";
    const amountDueCol = {
      header: noSort =>
        noSort ? (
          columnHeaderText[amountDueColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[amountDueColName]}
            sortKey={"amount"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId.toLocaleUpperCase())}</span>
          <span>{formatCurrency(row.amount, "")}</span>
        </div>
      ),    
      width:"130px",
      name: amountDueColName
    };
    openinvoiceColumns[amountDueColName] = amountDueCol 

    const fillerCol = { width: "0.1%", content: row => null, name: "empty" };
    openinvoiceColumns["empty"] = fillerCol 

    const dueDateColName = "DUEDATE";
    const dueDateCol = {
      header: noSort =>
        noSort ? (
          columnHeaderText[dueDateColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[dueDateColName]}
            sortKey={"DueDate"}
          />
        ),
      sortable: noSort => noSort === false,
    //  width:"10%",
      content: row => <span>{row.transactionType === "CM" ? Resources.NA : formatDate(row.dueDate, true, false)}</span>,     
      name: dueDateColName
    };
    openinvoiceColumns[dueDateColName] = dueDateCol

    const statusColName = "STATUS";

    const statusCol = {
      header: noSort =>
        noSort ? (
          columnHeaderText[statusColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[statusColName]}
            sortKey={"Status"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => {
        let status = '';
        let pendingPayments = false;
        let isPastDue = moment(row.dueDate).isBefore();
        let priorPayment = false;

        const invoice = statementsStore.openInvoices.find(inv => inv.id === row.id);

        if (invoice) {
          for (const payment of invoice.payments) {
            if (payment.paymentName === 'Payment') {
              priorPayment = true;
              isPastDue = false;
            } else if (payment.paymentName === 'Pending Payment') {
              pendingPayments = true;
              isPastDue = false;
              break;
            }
          }
        }

        // The rules are, if a payment is pending show that status regardless. Otherwise if a partial payment has been made show that status, 
        // and after that go by the past due status or the actual status value from the invoice record.
        if (pendingPayments) {
          status = Resources.PaymentPending;
        } else {
          if (priorPayment) {
            status = Resources.PartialPayment;
          } else {
            if (isPastDue) {
              status = Resources.PastDue;
            } else {
              status = row.status;
            }
          }
        }

        return (
          <React.Fragment>
            <span>{status}</span>
            {isPastDue ? <IconAlert className="pl-2" height={15} /> : ""}
          </React.Fragment>
        );
      },     
    //  width:"10%",
      name: statusColName
    };
    openinvoiceColumns[statusColName] = statusCol

    const pendingPaymentTooltipRef = React.createRef();

    const getPendingPaymentTooltipContent = (row) => {
      const invoice = statementsStore.openInvoices.find(inv => inv.id === row.id);
      let pendingPaymentsToShow = [];

      if (invoice) {
        for (const payment of invoice.payments) {
          if (payment.paymentName === 'Pending Payment') {
            pendingPaymentsToShow.push({
              id: payment.id,
              date: moment(payment.processedDate).format("MMM D, YYYY"),
              amount: formatCurrency(payment.amount, payment.currencyId.toUpperCase())
            })
          }
        }

        if (pendingPaymentsToShow.length > 0) {
          return (
            <div className="pending-payment-tooltip">
              <div>{Resources.PaymentInformation}</div>
              {
                pendingPaymentsToShow.map(payment => {
                  return (
                    <div key={payment.id} className="pending-payment-tooltip-entry">
                      <div className="pending-payment-tooltip-row">
                        <span>Date</span>
                        <span>{payment.date}</span>
                      </div>
                  
                      <div className="pending-payment-tooltip-row">
                        <span>Amount</span>
                        <span>{payment.amount}</span>
                      </div>
  
                      <div className="pending-payment-tooltip-row">
                        <span>Transaction ID</span>
                        <span>{payment.id}</span>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          );
        }
      }

      return '';
    }

    const tranTypeColName = "TRANTYPE";
    const tranTypeCol =  {
      header: noSort =>
        noSort ? (
          columnHeaderText[tranTypeColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[tranTypeColName]}
            sortKey={"TransactionType"}
          />
        ),
      sortable: noSort => noSort === false,
  //    width:"10%",
      content: row => <span>{row.transactionType}</span>,     
      name: tranTypeColName
    };
    openinvoiceColumns[tranTypeColName] = tranTypeCol

    const lastPmtDateColName = "LASTPMTDATE";
    const lastPmtDateCol =  {
      header: noSort =>
        noSort ? (
          columnHeaderText[lastPmtDateColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[lastPmtDateColName]}
            sortKey={"LastPmtDate"}
          />
        ),
      sortable: noSort => noSort === false,
    //  width:"10%",
      content: row => <span>{row.paidDate}</span>,      
      name: lastPmtDateColName
    };
    openinvoiceColumns[lastPmtDateColName] = lastPmtDateCol

    const custPoNoColName = "CUSTPONO";
    const custPoNoCol =  {
      header: noSort =>
        noSort ? (
          columnHeaderText[custPoNoColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[custPoNoColName]}
            sortKey={"CustPONo"}
          />
        ),
      sortable: noSort => noSort === false,
    //  width:"10%",
      content: row => <span>{row.orderId}</span>,      
      name: custPoNoColName
    };
    openinvoiceColumns[custPoNoColName] = custPoNoCol

    const indisputeYNColName = "INDISPUTEYN";
    const indisputeYNCol =  {
      header: noSort =>
        noSort ? (
          columnHeaderText[indisputeYNColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[indisputeYNColName]}
            sortKey={"isDisputed"}
          />
        ),
      sortable: noSort => noSort === false,
   //   width:"10%",
      content: row => <span>{row.isDisputed ? 'true':'false'}</span>,     
      name: indisputeYNColName
    };
    openinvoiceColumns[indisputeYNColName] = indisputeYNCol

    const origTranAmtColName = "ORIGTRANAMT";

    const origTranAmtCol = {
      header: noSort =>
        noSort ? (
          columnHeaderText[origTranAmtColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[origTranAmtColName]}
            sortKey={"origTranAmt"}
          />
        ),
      sortable: noSort => noSort === false,
  //    width:"10%",
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId.toLocaleUpperCase())}</span>
          <span>{formatCurrency(row.origTranAmt, "")}</span>
        </div>
      ),
   //   width: "10%",
      name: origTranAmtColName
    };
    openinvoiceColumns[origTranAmtColName] = origTranAmtCol

    
    const udf1ColName = "INVUDF1";
    const udf1Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf1ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf1ColName]}
            sortKey={"udf1"}
          />
        ),
      sortable: noSort => noSort === false,
  //    width:"10%",
      content: row => <span>{row.udf1}</span>,    
      name: udf1ColName
    };
    openinvoiceColumns[udf1ColName] = udf1Col 

    const udf2ColName = "INVUDF2";
    const udf2Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf2ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf2ColName]}
            sortKey={"Udf2"}
          />
        ),
      sortable: noSort => noSort === false,
 //     width:"10%",
      content: row => <span>{row.udf2}</span>,    
      name: udf2ColName
    };
    openinvoiceColumns[udf2ColName] = udf2Col 

    const udf3ColName = "INVUDF3";
    const udf3Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf3ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf3ColName]}
            sortKey={"Udf3"}
          />
        ),
      sortable: noSort => noSort === false,
 //     width:"10%",
      content: row => <span>{row.udf3}</span>,    
      name: udf3ColName
    };
    openinvoiceColumns[udf3ColName] = udf3Col 

    const udf4ColName = "INVUDF4"
    const udf4Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf4ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf4ColName]}
            sortKey={"Udf4"}
          />
        ),
      sortable: noSort => noSort === false,
  //    width:"10%",
      content: row => <span>{row.udf4}</span>,    
      name: udf4ColName
    };
    openinvoiceColumns[udf4ColName] = udf4Col 

    const udf5ColName = "INVUDF5"
    const udf5Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf5ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf5ColName]}
            sortKey={"Udf5"}
          />
        ),
      sortable: noSort => noSort === false,
  //    width:"10%",
      content: row => <span>{row.udf5}</span>,    
      name: udf5ColName
    };
    openinvoiceColumns[udf5ColName] = udf5Col 

    const udf6ColName = "INVUDF6"

    const udf6Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf6ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf6ColName]}
            sortKey={"Udf6"}
          />
        ),
      sortable: noSort => noSort === false,
  //    width:"10%",
      content: row => <span>{row.udf6}</span>,    
      name: udf6ColName
    };
    openinvoiceColumns[udf6ColName] = udf6Col 

    const udf7ColName = "INVUDF7";
    const udf7Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf7ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf7ColName]}
            sortKey={"Udf7"}
          />
        ),
      sortable: noSort => noSort === false,
    //  width:"10%",
      content: row => <span>{row.udf7}</span>,    
      name: udf7ColName
    };
    openinvoiceColumns[udf7ColName] = udf7Col 

    const udf8ColName = "INVUDF8";
    const udf8Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf8ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf8ColName]}
            sortKey={"Udf8"}
          />
        ),
      sortable: noSort => noSort === false,
   //   width:"10%",
      content: row => <span>{row.udf8}</span>,    
      name: udf8ColName
    };
    openinvoiceColumns[udf8ColName] = udf8Col 

    const udf9ColName = "INVUDF9"
    const udf9Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf9ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf9ColName]}
            sortKey={"Udf9"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => <span>{row.udf9}</span>,   
   //   width:"10%",
      name: udf9ColName
    };
    openinvoiceColumns[udf9ColName] = udf9Col 
    
    const udf10ColName = "INVUDF10";
    const udf10Col = {
      header: noSort =>
        noSort ? (
          columnHeaderText[udf10ColName]
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={columnHeaderText[udf10ColName]}
            sortKey={"Udf10"}
          />
        ),
      sortable: noSort => noSort === false,
   //   width:"10%",
      content: row => <span>{row.udf10}</span>,    
      name: udf10ColName
    };
    openinvoiceColumns[udf10ColName] = udf10Col     
      
   // const columns = [selectCol, invoiceDateCol, invoiceNumCol, amountDueCol, fillerCol, dueDateCol, statusCol];
   const columns = new Array()
   let cnt1 = 0;
   let cnt = 0;
   // alert(key);
   columns[cnt] = fillerCol;
   ++cnt;    
   columns[cnt] = openinvoiceColumns[selectColName];            
   ++cnt;        
    for(cnt1 = 0;cnt1<this.state.openInvcColViewSett.length;cnt1++)
    {    
      let key =  this.state.openInvcColViewSett[cnt1].dataField.toLocaleUpperCase();      
      if(openinvoiceColumns[key]!=null  && this.state.openInvcColViewSett[cnt1].display == true )
      {      
        columns[cnt] = openinvoiceColumns[key];             
        ++cnt;      
      }
    }  


    
 //  hoverColumns[hoverColumns.length] = fillerCol;
   const hoverColumnFirst = {
    colSpan: 1,
    content: (row, i) => {
      const tooltipContent = getPendingPaymentTooltipContent(row);
      return (
        <div className="flex-end flex-align-center">          
          <div>
            <button ref={pendingPaymentTooltipRef} style={{ padding: 0 }} />
            <Tippy
              content={tooltipContent}                  
              reference={pendingPaymentTooltipRef} 
              // If there is tooltip content to show, we need to set the visible attribute explicitly in order for the tooltip to show when the
              // hover columns are shown, versus having to hover over the reference element itself
              visible={tooltipContent !== ""}
              placement= "right"
              animation="shift-away"                  
              popperOptions={{ modifiers: [{ name: 'flip', enabled: false }] }}
              offset={[0, this.state.openInvcDivWidth]}              
              />
          </div>
        </div>
      )
    },
    width:"1px"
  };


  
  const hoverColumnLast = {
    colSpan: 2,
    content: (row, i) => {      
      return (
        <div className="flex-end flex-align-center">
          { allowPayments === true && (
            <button
              data-test-id="invoices__icon-dollar"
              className="button-action-icon data-Tooltip"
              data-tooltip={Resources.MakeAPayment}
              onClick={() => {
                this.props.displayFlyout("makePaymentFlyout", {
                  selectedInvoices: [row],
                  columns,
                  withCompanyId: this.props.withCompanyId,
                  companyId: this.props.companyId,
                  perspectiveId: this.props.perspectiveId,
                  useRedux: true,
                  clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] }),
                    nationalAccount,
                    allAccountsSelected: this.props.allAccountsSelected
                });
              }}
              disabled={row.amount < 0}
            >
              <IconDollarSign height="24" className="icon-button" />
            </button>
            ) }
          { !allAccountsSelected && (
            <button
              data-test-id="invoices__icon-message"
              className="button-action-icon data-Tooltip"
              data-tooltip={Resources.SendAMessage}
              onClick={() =>
                this.props.displayFlyout("messageFlyout", {
                  columns,
                  selectedInvoices: [row],
                  companyId: this.props.companyId,
                  perspectiveId: this.props.perspectiveId,
                  withContextGroupId: this.props.withContextGroupId,
                  withCompanyId: this.props.withCompanyId,
                  useRedux: true,
                  isPortal: isCustomerPortal(this.props.history ? this.props.history.location.pathname : ""),
                  fetchTemplate: () => {
                    return this.props.fetchOpenInvoicesTemplate(
                      this.props.companyId,
                      this.props.perspectiveId,
                      this.props.withCompanyId,
                      { invoiceIds: [row.id] }
                    );
                  },
                  clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                })
              }
            >
              <IconMessage height="20" className="icon-button" />
            </button>
            ) }          
          {allowDisputes && !allAccountsSelected && (
            <button
              data-test-id="invoices__icon-dispute"
              className="button-action-icon data-Tooltip"
              data-tooltip={Resources.DisputeInvoices}
              onClick={() => {
                this.props.displayFlyout("disputeFlyout", {
                  selectedInvoices: [row],
                  columns,
                  withCompanyId: this.props.withCompanyId,
                  companyId: this.props.companyId,
                  withContextGroupId: this.props.withContextGroupId,
                  useRedux: true,
                  clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                });
              } }
            >
              <IconHelp height="20" className="icon-button" />
            </button>
            ) }
          {!allAccountsSelected && (
            <button
              data-test-id="invoices__icon-promise"
              className="button-action-icon data-Tooltip"
              data-tooltip={Resources.PromiseToPay}
              onClick={() => {
                this.props.displayFlyout("promiseToPayFlyout", {
                  selectedInvoices: [row],
                  columns,
                  withCompanyId: this.props.withCompanyId,
                  companyId: this.props.companyId,
                  withContextGroupId: this.props.withContextGroupId,
                  useRedux: true,
                  clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                });
              }}
            >
              <IconStar height="20" className="icon-button" />
            </button>
          )}
          <button
            data-test-id="invoices__icon-download"
          className={isEmpty(row.attachments) ? "button-action-icon data-Tooltip :disabled" : "button-action-icon data-Tooltip"}
          data-tooltip={Resources.DownloadInvoices}
          disabled={isEmpty(row.attachments)}
            onClick={() => {
              this.downloadAttachments(row.attachments, Resources.InvoiceNAttachments(row.id) + ".zip");
            }}
          >
            <IconDownload height="20" className="icon-button" />
          </button>
          <button
            data-test-id="invoices__icon-export"
            className="button-action-icon data-Tooltip"
            data-tooltip={Resources.ExportInvoices}
            onClick={() => this.exportRows([row])}
          >
            <IconExport height="20" className="icon-button" />
          </button>                      
        </div>
      )
    }
  };
  
  let cnt3 = 0;    
  let hoverColumns = new Array();   
  hoverColumns[cnt3++] = hoverColumnFirst;  
  if(cnt>11)
  {
      cnt = 9;
  }
  for(cnt3=1;cnt3<cnt;++cnt3)
  {
      hoverColumns[cnt3] = columns[cnt3];  
  }
  
    hoverColumns[cnt3++] = hoverColumnLast;
//    hoverColumns[cnt3++] = fillerCol;
  /*  const hoverColumns = [
      selectCol,
      invoiceDateCol,
      invoiceNumCol,
      amountDueCol,
      fillerCol,
      {
        colSpan: 2,
        content: (row, i) => {
          const tooltipContent = getPendingPaymentTooltipContent(row);
          return (
            <div className="flex-end flex-align-center">
              {allowPayments === true && (
                <button
                  data-test-id="invoices__icon-dollar"
                  className="button-action-icon"
                  onClick={() => {
                    this.props.displayFlyout("makePaymentFlyout", {
                      selectedInvoices: [row],
                      columns,
                      withCompanyId: this.props.withCompanyId,
                      companyId: this.props.companyId,
                      perspectiveId: this.props.perspectiveId,
                      useRedux: true,
                      clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] }),
                      nationalAccount
                    });
                  }}
                  disabled={row.amount < 0}
                >
                  <IconDollarSign height="24" className="icon-button" />
                </button>
              )}
              {!allAccountsSelected && (
                <button
                  data-test-id="invoices__icon-message"
                  className="button-action-icon"
                  onClick={() =>
                    this.props.displayFlyout("messageFlyout", {
                      columns,
                      selectedInvoices: [row],
                      companyId: this.props.companyId,
                      perspectiveId: this.props.perspectiveId,
                      withContextGroupId: this.props.withContextGroupId,
                      withCompanyId: this.props.withCompanyId,
                      useRedux: true,
                      isPortal: isCustomerPortal(this.props.history ? this.props.history.location.pathname : ""),
                      fetchTemplate: () => {
                        return this.props.fetchOpenInvoicesTemplate(
                          this.props.companyId,
                          this.props.perspectiveId,
                          this.props.withCompanyId,
                          { invoiceIds: [row.id] }
                        );
                      },
                      clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                    })
                  }
                >
                  <IconMessage height="20" className="icon-button" />
                </button>
              )}
              {allowDisputes && !allAccountsSelected && (
                <button
                  data-test-id="invoices__icon-dispute"
                  className="button-action-icon"
                  onClick={() => {
                    this.props.displayFlyout("disputeFlyout", {
                      selectedInvoices: [row],
                      columns,
                      withCompanyId: this.props.withCompanyId,
                      companyId: this.props.companyId,
                      withContextGroupId: this.props.withContextGroupId,
                      useRedux: true,
                      clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                    });
                  }}
                >
                  <IconHelp height="20" className="icon-button" />
                </button>
              )}
              {!allAccountsSelected && (
                <button
                  data-test-id="invoices__icon-promise"
                  className="button-action-icon"
                  onClick={() => {
                    this.props.displayFlyout("promiseToPayFlyout", {
                      selectedInvoices: [row],
                      columns,
                      withCompanyId: this.props.withCompanyId,
                      companyId: this.props.companyId,
                      withContextGroupId: this.props.withContextGroupId,
                      useRedux: true,
                      clearSelectedRows: () => this.setState({ selectedRows: [], selectedKeys: [] })
                    });
                  }}
                >
                  <IconStar height="20" className="icon-button" />
                </button>
              )}
              <button
                data-test-id="invoices__icon-download"
              className={isEmpty(row.attachments) ? "button-action-icon :disabled" : "button-action-icon"}
                disabled={isEmpty(row.attachments)}
                onClick={() => {
                  this.downloadAttachments(row.attachments, Resources.InvoiceNAttachments(row.id) + ".zip");
                }}
              >
                <IconDownload height="20" className="icon-button" />
              </button>
              <button
                data-test-id="invoices__icon-export"
                className="button-action-icon"
                onClick={() => this.exportRows([row])}
              >
                <IconExport height="20" className="icon-button" />
              </button>             
              <div>
                <button ref={pendingPaymentTooltipRef} style={{ padding: 0 }} />
                <Tippy
                  content={tooltipContent}                  
                  reference={pendingPaymentTooltipRef} 
                  // If there is tooltip content to show, we need to set the visible attribute explicitly in order for the tooltip to show when the
                  // hover columns are shown, versus having to hover over the reference element itself
                  visible={tooltipContent !== ""}
                  placement= "right"
                  animation="shift-away"                  
                  popperOptions={{ modifiers: [{ name: 'flip', enabled: false }] }}
                  offset={[0, 40]} />
              </div>
            </div>
          )
        }
      }
    ];
*/
    if (this.props.allAccountsSelected) {
      columns.splice(2, 0, customerNameCol);
      hoverColumns.splice(2, 0, customerNameCol);
    }

    if (hover) {
       return hoverColumns;
    } else {      
      return columns;
    }
  }

  renderUnappliedPayments = () => {
    let openinvoiceColumns = {}
    const { statementsStore } = this.props;
    if (
      statementsStore.unappliedOrPendingPayments.length === 0 &&
      statementsStore.isFetchingUnappliedOrPendingPayments === false
    ) {
      return null;
    }

    const customerNameCol = {
      header: noSort =>
        noSort ? (
          Resources.Account.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
          sortBy={statementsStore.unappliedPaymentsSortBy}
          sortDirection={statementsStore.unappliedPaymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.props.updateUnappliedPaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.Account.toLocaleUpperCase()}
          sortKey={"CustomerName"}
        />
        ),
      sortable: noSort => noSort === false,
      className: "customer-name",
      content: row => <span className="fw-500 customer-name" title={row.customerName}>{row.customerName}</span>,     
      name: "customer-name"
    };

  //  openinvoiceColumns["customer-name"] = customerNameCol
    const transactionDateCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.unappliedPaymentsSortBy}
          sortDirection={statementsStore.unappliedPaymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.props.updateUnappliedPaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.TransactionDate.toLocaleUpperCase()}
          sortKey={"processedDate"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <span className="fw-500">
          {formatDate(row.processedDate, row.paymentName.toLowerCase() !== "pending payment", false)}
        </span>
      ),
      width: "17%",
      name: "tran-date"
    };
    openinvoiceColumns["TRANDATE"] = transactionDateCol

    const transactionIdCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.unappliedPaymentsSortBy}
          sortDirection={statementsStore.unappliedPaymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.props.updateUnappliedPaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.TransactionId.toLocaleUpperCase()}
          sortKey={"tid"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => {
        let hasAttachment = !isEmpty(row.attachments);
        return (
          <div className={`statements-invoice-id ${hasAttachment ? "link" : ""}`}>
            <div className="mr-2">{row.id}</div>
            {hasAttachment && <span className={Icons.filePdf} />}
          </div>
        );
      },
      width: this.props.allAccountsSelected ? "15%" : '17%',
      name: "tran-id"
    };

    openinvoiceColumns["tran-id"] = transactionIdCol

    const amountCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.unappliedPaymentsSortBy}
          sortDirection={statementsStore.unappliedPaymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.props.updateUnappliedPaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.PaymentAmt.toLocaleUpperCase()}
          sortKey={"amount"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId.toLocaleUpperCase())}</span>
          <span>{formatCurrency(row.amount, "")}</span>
        </div>
      ),
      width: '9rem',
      name: "tran-amount"
    };
    openinvoiceColumns["tran-amount"] = amountCol
    const unappliedAmountCol = {
      header: (
        <TableDataSortableHeader
        sortBy={statementsStore.unappliedPaymentsSortBy}
        sortDirection={statementsStore.unappliedPaymentsSortDirection}
        updateSort={(sortBy, sortDirection) => {
          this.props.updateUnappliedPaymentsSort(sortBy, sortDirection);
        }}
        text={Resources.UnappliedAmt.toLocaleUpperCase()}
        sortKey={"unappliedAmount"}
      />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">{getCurrencySymbol(row.currencyId.toLocaleUpperCase())}</span>
          <span>{formatCurrency(row.unappliedAmount, "")}</span>
        </div>
      ),
      width: '10rem',
      name: "unnapplied-amount"
    };
    openinvoiceColumns["unnapplied-amount"] = unappliedAmountCol
    const fillerCol = { width: this.props.allAccountsSelected ? '0%' : "5%", content: row => null };
    openinvoiceColumns["filler"] = fillerCol
    const transactionTypeCol = {
      header: (
        <TableDataSortableHeader
          sortBy={statementsStore.unappliedPaymentsSortBy}
          sortDirection={statementsStore.unappliedPaymentsSortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.props.updateUnappliedPaymentsSort(sortBy, sortDirection);
          }}
          text={Resources.Type.toLocaleUpperCase()}
          sortKey={"paymentType"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <span>{row.paymentName.toLowerCase() === "pending payment" ? Resources.PendingPayment : row.paymentType}</span>
      ),
      width: "15%",
      name: "tran-type"
    };
    openinvoiceColumns["tran-type"] = transactionTypeCol
    const invoicesCol = {
      header: Resources.Invoices.toLocaleUpperCase(),
      content: row => {
        return (
          <span>{Resources.ZeroInvoices}</span>
        );
      },
      name: "invoices",
      width: '15%'
    };
    openinvoiceColumns["invoices"] = invoicesCol
    const columns = new Array()
    let cnt = 0
    for(let key in openinvoiceColumns)   
    {
     columns[cnt] = openinvoiceColumns[key]
     ++cnt
    } 
   
    const maxRows = statementsStore.isShowingSearchResult
      ? statementsStore.unappliedOrPendingPayments.length
      : statementsStore.unappliedOrPendingPaymentsCount;

      if (this.props.allAccountsSelected) {
        columns.splice(0, 0, customerNameCol);
      }

    return (
      <Card type="table" className="unapplied-card">
        <div className="unapplied-table-header">
          {statementsStore.unappliedOrPendingPayments.length > 0 ? (
            <React.Fragment>
              <IconAlertCircle className="unapplied-alert-icon" />
              <div className="portal-input-label mb-0">
                {Resources.TotalUnappliedOrPendingPayments(
                  formatCurrency(statementsStore.paymentsTotalUnappliedOrPending, statementsStore.selectedCurrency)
                )}
              </div>
            </React.Fragment>
          ) : (
            <div className="portal-input-label mb-0">{Resources.CheckingForUnappliedPayments}</div>
          )}
        </div>
        {statementsStore.unappliedOrPendingPayments.length > 0 && (
          <TableData
            name="unapplied-payments__table"
            pagination
            data={statementsStore.unappliedOrPendingPayments}
            columns={columns}
            rowHeight="4em"
            onLoadMore={pageSelected => this.handleLoadMoreUnapplied(pageSelected)}
            maxRows={maxRows}
            loading={statementsStore.isFetchingUnappliedOrPendingPayments}
            rowClassName="statements-view-row no-hover"
            useRedux
            emptyRender={
              <div className="flex-center">
                <h4 className="mt-5">{Resources.EmptyStatements}</h4>
              </div>
            }
            className='unapplied-payments__table'           
          />
        )}
      </Card>
    );
  }
  handleCurrency = (e) =>
  {
    this.setState({ selectcurrid: e.target.value });
  }
  renderCurrency = (merchantcurrid) =>
  {
    return(
        <div>  
          <select value={this.state.selectcurrid} onChange={this.handleCurrency}>
            {merchantcurrid.map((option) => (
              <option value={option.currID}>{option.currID}</option>
            ))}
          </select>
        </div>
    );
  }

  renderButton = (allowPayments,selectedRows,allowOnlineCMAppl,columns,selectedKeys,nationalAccount,allowUnappliedPayments) =>
  {
    return (           
  <Card type="table" className="portal-table"><div className="table-data-card-header-buttons">
      {allowPayments === true && (
        <button
          data-test-id="open-invoices__button-pay"
          className="button-primary"
          onClick={() => {
            const selectedInvoices = [...selectedRows].filter(row => row.transactionType !== "CM" || (allowOnlineCMAppl === 2 && !this.props.allAccountsSelected));
              let filteredOutCreditMemo = selectedInvoices.length !== selectedRows.length;
              if (filteredOutCreditMemo && this.props.allAccountsSelected) {
                  this.props.displayModal("warnBeforeDeleteModal", {
                      warningMessage: (
                          Resources.SelectOkToConfirmPayment
                      ),
                      deleteButtonText: (Resources.Ok),
                      delete: () => {
                          this.props.displayFlyout("makePaymentFlyout", {
                              selectedInvoices,
                              columns,
                              withCompanyId: this.props.withCompanyId,
                              companyId: this.props.companyId,
                              perspectiveId: this.props.perspectiveId,
                              filteredOutCreditMemo,
                              useRedux: true,
                              clearSelectedRows: () => this.toggleSelectRow(selectedKeys, selectedRows),
                              onHide: () => this.handleHideMakePaymentFlyout(),
                              nationalAccount,
                              allAccountsSelected: this.props.allAccountsSelected
                          });

                          this.props.hideModal();
                      }
                  })
              } else {
                  this.props.displayFlyout("makePaymentFlyout", {
                      selectedInvoices,
                      columns,
                      withCompanyId: this.props.withCompanyId,
                      companyId: this.props.companyId,
                      perspectiveId: this.props.perspectiveId,
                      filteredOutCreditMemo,
                      useRedux: true,
                      clearSelectedRows: () => this.toggleSelectRow(selectedKeys, selectedRows),
                      onHide: () => this.handleHideMakePaymentFlyout(),
                      nationalAccount,
                      allAccountsSelected: this.props.allAccountsSelected
                  });
              }
          }}
          disabled={
            (!allowUnappliedPayments && selectedRows.length === 0) ||
            (selectedRows.length !== 0 && selectedRows.every(inv => (inv || {}).transactionType === "CM"))
          }
        >          
        <IconDollarSign height="24" className="button-primary-icon" />                  
                  <span>{Resources.Pay}</span>
          </button>                          
              ) }                             
                </div></Card>                
    );    
  }
  renderEmptyState = (useCard) => {
    const svgInternals = (
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F4F3FF"
          d="M240.479042 44C246.289607 44 251 48.7010101 251 54.5S246.289607 65 240.479042 65h-60.119761c5.810565 0 10.520959 4.7010101 10.520959 10.5S186.169846 86 180.359281 86h33.065869c5.810564 0 10.520958 4.7010101 10.520958 10.5 0 5.79899-4.710394 10.5-10.520958 10.5h-15.291331c-7.326364 0-13.265556 4.70101-13.265556 10.5 0 3.865993 3.005988 7.365993 9.017965 10.5 5.810564 0 10.520958 4.70101 10.520958 10.5s-4.710394 10.5-10.520958 10.5H69.1377246c-5.8105648 0-10.5209581-4.70101-10.5209581-10.5S63.3271598 128 69.1377246 128H10.5209581C4.71039338 128 0 123.29899 0 117.5S4.71039338 107 10.5209581 107h60.1197605c5.8105647 0 10.520958-4.70101 10.520958-10.5 0-5.7989899-4.7103933-10.5-10.520958-10.5H33.0658683c-5.8105647 0-10.5209581-4.7010101-10.5209581-10.5S27.2553036 65 33.0658683 65h60.1197604c-5.8105647 0-10.520958-4.7010101-10.520958-10.5S87.375064 44 93.1856287 44H240.479042zm0 42C246.289607 86 251 90.7010101 251 96.5c0 5.79899-4.710393 10.5-10.520958 10.5s-10.520958-4.70101-10.520958-10.5c0-5.7989899 4.710393-10.5 10.520958-10.5z"
        />
        <path
          fill="#FFF"
          stroke="#BEB6FF"
          strokeWidth="3.5"
          d="M166.969231 64.75c1.182132 0 2.252364.4790605 3.027055 1.2536335.774594.7744765 1.253714 1.8443996 1.253714 3.0262172h0v91.9552243c0 .349269-.141611.66546-.370528.894343-.229014.228979-.5454.370582-.894857.370582h0-67.846153c-.349457 0-.665843-.141603-.894857-.370582-.228917-.228883-.370528-.545074-.370528-.894343h0v-27.45182l-1.8107799.062929c-.2301273.007997-.4611678.012025-.6930663.012025-5.952978 0-11.3333882-2.660451-15.2314583-6.935103C79.1839635 122.337331 76.75 116.344462 76.75 109.731343c0-6.613119 2.4339635-12.6059876 6.3877725-16.9417629 3.8980701-4.2746516 9.2784803-6.9351025 15.2314583-6.9351025.2318985 0 .462939.0040278.6930663.0120252h0l1.8107799.0629288V69.0298507c0-1.1818176.479119-2.2517407 1.253714-3.0262172.774691-.774573 1.844923-1.2536335 3.027055-1.2536335h0zM98.3692308 95.1679104c-3.5223673 0-6.7295251 1.6492273-9.0441163 4.3687243-2.2205915 2.6090533-3.6115248 6.2089613-3.6115248 10.1947083 0 3.985748 1.3909333 7.585655 3.6115248 10.194709 2.3145912 2.719497 5.521749 4.368724 9.0441163 4.368724.3004289 0 .5984641-.012048.8937761-.035737l1.6100701-.129155V95.3328026l-1.6100701-.1291547c-.295312-.023689-.5933472-.0357375-.8937761-.0357375z"
        />
        <path
          stroke="#DEDAFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.5"
          d="M159.5 113.689386v7.196525m0-43.6103183v27.7933733"
        />
        <path
          stroke="#BEB6FF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.5"
          d="M48.1924412 153h10.3374733m161.1497065 0H224m-45.128205 0h33.737446m-145.0536854 0h32.2728365"
        />
        <path
          stroke="#DEDAFF"
          strokeLinecap="round"
          strokeWidth="3.5"
          d="M140.016577 2C136.005526 8.29032074 134 13.7642216 134 18.4217026c0 8.2946801 7 12.0093905 7 20.9943828 0 4.5602882-2.333333 9.0882597-7 13.5839146M121.20468 18C119.809948 26.4141139 126 27.9702226 126 35.5138335c0 3.8287222-1.59844 7.6574443-4.79532 11.4861665M150.122888 15c-2.010365 5.1513862-.88094 8.2912986 0 10.1394581C152.005895 29.0898964 155 32.5 155 36.5199571c0 4.6689227-1.625704 9.1622703-4.877112 13.4800429"
        />
      </g>
    );

    if (this.props.flags.portalUpdatedZeroState) {
      if (useCard) {
        return (
          <Card className="open-invoices-empty-state-ld">
            <svg viewBox="0 0 251 164" className="mt-4">
              {svgInternals}
            </svg>
            <h2>{Resources.NiceWorkYoureAllCaughtUp}</h2>
            <p>{Resources.PortalOpenInvoicesEmptyNew}</p>
          </Card>
        );
      } else {
        return (
          <div className="open-invoices-empty-state-ld">
            <svg viewBox="0 0 251 164">{svgInternals}</svg>
            <h2>{Resources.NiceWorkYoureAllCaughtUp}</h2>
            <p>{Resources.PortalOpenInvoicesEmptyNew}</p>
          </div>
        );
      }
    } else {
      if (useCard) {
        return (
          <Card className="open-invoices-empty-state">
            <svg viewBox="0 0 251 164" className="mt-4">
              {svgInternals}
            </svg>
            <h2>{Resources.NiceWorkYoureAllCaughtUp}</h2>
            <p>{Resources.PortalOpenInvoicesEmpty}</p>
          </Card>
        );
      } else {
        return (
          <div className="open-invoices-empty-state">
            <svg viewBox="0 0 251 164">{svgInternals}</svg>
            <h2>{Resources.NiceWorkYoureAllCaughtUp}</h2>
            <p>{Resources.PortalOpenInvoicesEmpty}</p>
          </div>
        );
      }
    }
  }

  render() {
    let { statementsStore, withCompanyId, withCompanyName, companyId, allAccountsSelected, nationalAccount } = this.props;
    let { selectedRows, selectedKeys } = this.props.statementsStore;
    const hoverColumns = this.getOpenInvoiceColumns(true);
    const columns = this.getOpenInvoiceColumns(false);

    const data = statementsStore.openInvoices;
    const loading = statementsStore.isFetchingOpenInvoices;
    const maxRows = statementsStore.isShowingSearchResult ? data.length : statementsStore.openInvoicesCount;
    const totalStatementAmount =
      (statementsStore.openInvoicesCurrencyStats[statementsStore.selectedCurrency] || {}).totalBalance ||
      statementsStore.openTotalAmount;
    const pastDueInvoices =
      (statementsStore.openInvoicesCurrencyStats[statementsStore.selectedCurrency] || {}).pastDueCount ||
      statementsStore.openPastDueCount;
    const netStatementBalance = totalStatementAmount - statementsStore.paymentsTotalUnappliedOrPending;

    const allowPayments = (statementsStore.paymentInfo[withCompanyId] || {}).allowPayments;
    const allowUnappliedPayments = (statementsStore.paymentInfo[withCompanyId] || {}).allowUnappliedPayments;
    const allowOnlineCMAppl = (statementsStore.paymentInfo[withCompanyId] || {}).allowOnlineCMAppl;
    const allowDisputes = !isEmpty(
      (this.props.getCompanyInfo(this.props.withCompanyId).CompanySettings || {}).PortalDisputeActivityKey
    );

    const autoPayAllowed = (statementsStore.paymentInfo[withCompanyId] || {}).allowAutomaticPayments;
    const autoPayEnabled =
      ((statementsStore.paymentInfo[withCompanyId] || {}).automaticPaymentConfiguration || {})
        .automaticPaymentEnabled || false;

    const hasMultiCurrency = (statementsStore.currencies || []).length > 1;

    const isPortal = isCustomerPortal(this.props.history ? this.props.history.location.pathname : "");

    const helmet = (
      <Helmet>
        <title>
          {withCompanyName} | {Resources.OpenInvoices}
        </title>
      </Helmet>
    );

    if (
      (loading || statementsStore.isFetchingUnappliedOrPendingPayments || statementsStore.fetchingCurrencyStats) &&
      (isEmpty(totalStatementAmount) || totalStatementAmount === 0) &&
      (isEmpty(pastDueInvoices) || pastDueInvoices === 0)
    ) {
      return (
        <React.Fragment>
          {helmet}
          <MainLoader fullScreen className="portal-page-loader" />
        </React.Fragment>
      );
    }

    let currencySelectorDisplayValues = {};
    Object.keys(statementsStore.openInvoicesCurrencyStats).map(
      key => (currencySelectorDisplayValues[key] = statementsStore.openInvoicesCurrencyStats[key].count)
    );

    if (
      isEmpty(data) &&
      isEmpty(statementsStore.openFromDate) &&
      isEmpty(statementsStore.openToDate) &&
      isEmpty(statementsStore.openFromDueDate) &&
      isEmpty(statementsStore.openToDueDate) &&
      isEmpty(this.state.searchTerm) &&
      isEmpty(statementsStore.unappliedOrPendingPayments) &&
      (isEmpty(totalStatementAmount) || totalStatementAmount === 0) &&
      (isEmpty(pastDueInvoices) || pastDueInvoices === 0) &&
      !this.props.flags.portalUpdatedZeroState
    ) {
      return (
        <React.Fragment>
          {helmet}
          {hasMultiCurrency && <StatementCurrencySelector displayCount />}          
          {(allowUnappliedPayments && statementsStore.openInvoicesCount==0)?(this.renderButton(true,selectedRows,allowOnlineCMAppl,columns,selectedKeys,nationalAccount,allowUnappliedPayments)): ""                                    
          }          
         {this.renderEmptyState(hasMultiCurrency)}
        </React.Fragment>
      );
    } else if (
      isEmpty(data) &&
      isEmpty(statementsStore.openFromDate) &&
      isEmpty(statementsStore.openToDate) &&
      isEmpty(statementsStore.openFromDueDate) &&
      isEmpty(statementsStore.openToDueDate) &&
      isEmpty(this.state.searchTerm) &&
      isEmpty(statementsStore.unappliedOrPendingPayments) &&
      (isEmpty(totalStatementAmount) || totalStatementAmount === 0) &&
      (isEmpty(pastDueInvoices) || pastDueInvoices === 0) &&
      this.props.flags.portalUpdatedZeroState
    ) {
      return (
        <React.Fragment>
          {helmet}
          {hasMultiCurrency && <StatementCurrencySelector displayCount displayCounts={currencySelectorDisplayValues} />}
          <Card className="statements-summary">
            <div className="statements-summary-row">
              <div className="statements-summary-item">
                <div className="statements-summary-item-header">{stringToSentenceCase(Resources.OpenInvoices)}</div>
                <div className="statements-summary-item-data">
                  {(statementsStore.openInvoicesCurrencyStats[statementsStore.selectedCurrency] || {}).count ||
                    statementsStore.openInvoicesCount ||
                    "0"}
                </div>
              </div>
              <div className="statements-summary-item">
                <div className="statements-summary-item-header flex-align-center">
                  {stringToSentenceCase(Resources.TotalAccountBalance)}
                  <div style={{ position: "relative" }} ref={this.wrapperRef}>
                    <button
                      className="button-info"
                      style={{ marginLeft: ".33rem" }}
                      onClick={() => this.setState({ showAccountBalanceHelperText: true })}
                    >
                      <IconInfo height={16} />
                    </button>
                    {this.state.showAccountBalanceHelperText && (
                      <Card className="account-balance-info">
                        <div className="flex-align-start" style={{ width: "235px" }}>
                          <span className="account-balance-helper-text">{Resources.AccountBalanceHelperText}</span>
                          <button
                            onClick={() => this.setState({ showAccountBalanceHelperText: false })}
                            style={{ padding: "0", marginTop: "-.4rem" }}
                          >
                            <IconClose height={14} />
                          </button>
                        </div>
                      </Card>
                    )}
                  </div>
                </div>
                <div className="statements-summary-item-data">
                  {formatCurrency(totalStatementAmount, statementsStore.selectedCurrency)}
                </div>
              </div>
              <div className="statements-summary-item">
                <div className="statements-summary-item-header">{stringToSentenceCase(Resources.PastDueInvoices)}</div>
                <div className="statements-summary-item-data">{pastDueInvoices}</div>
              </div>
            </div>
            
          </Card>
          {autoPayAllowed && !allAccountsSelected && (
            <AutoPayIndicator
              onClickManage={() => this.props.displayFlyout("manageAutoPayFlyout", { companyId, withCompanyId })}
              autoPayEnabled={autoPayEnabled}
            />
          )}           
         <div style={{ textAlign: "center" }}>
          {(allowUnappliedPayments && statementsStore.openInvoicesCount==0)?(this.renderButton(true,selectedRows,allowOnlineCMAppl,columns,selectedKeys,nationalAccount,allowUnappliedPayments)): ""                                    
          }           
          </div>          
         {this.renderEmptyState(hasMultiCurrency)}         
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {helmet}
        {hasMultiCurrency && <StatementCurrencySelector displayCount displayCounts={currencySelectorDisplayValues} />}
        <Card className="statements-summary" data-test-id="open-invoices__statements-summary-card">
          <div className="statements-summary-row">
            <div className="statements-summary-item">
              <div
                className="statements-summary-item-header"
                data-test-id="open-invoices__statements-summary-open-invoices"
              >
                {stringToSentenceCase(Resources.OpenInvoices)}
              </div>
              <div
                className="statements-summary-item-data"
                data-test-id="open-invoices__statements-open-invoices-count"
              >
                {(statementsStore.openInvoicesCurrencyStats[statementsStore.selectedCurrency] || {}).count ||
                  statementsStore.openInvoicesCount ||
                  "0"}
              </div>
            </div>
            <div className="statements-summary-item">
              <div
                className="statements-summary-item-header flex-align-center"
                data-test-id="open-invoices__statements-summary-total-account-balance"
              >
                {stringToSentenceCase(Resources.TotalAccountBalance)}
                <div style={{ position: "relative" }} ref={this.wrapperRef}>
                  <button
                    data-test-id="open-invoices__statements-summary-button-info"
                    className="button-info"
                    style={{ marginLeft: ".33rem" }}
                    onClick={() => this.setState({ showAccountBalanceHelperText: true })}
                  >
                    <IconInfo height={16} />
                  </button>
                  {this.state.showAccountBalanceHelperText && (
                    <Card className="account-balance-info" >
                      <div className="flex-align-start" style={{ width: "235px" }}>
                        <span className="account-balance-helper-text">{Resources.AccountBalanceHelperText}</span>
                        <button
                          data-test-id="invoices__icon-close"
                          onClick={() => this.setState({ showAccountBalanceHelperText: false })}
                          style={{ padding: "0", marginTop: "-.4rem" }}
                        >
                          <IconClose height={14} />
                        </button>
                      </div>
                    </Card>
                  )}
                </div>
              </div>
              <div
                className="statements-summary-item-data"
                data-test-id="open-invoices__statements-total-account-balance-amount"
              >
                {formatCurrency(netStatementBalance, statementsStore.selectedCurrency)}
              </div>
            </div>
            <div className="statements-summary-item">
              <div
                className="statements-summary-item-header"
                data-test-id="open-invoices__statements-past-due-invoices"
              >
                {stringToSentenceCase(Resources.PastDueInvoices)}
              </div>
              <div
                className="statements-summary-item-data"
                data-test-id="open-invoices__statements-summary-past-due-invoices-count"
              >
                {pastDueInvoices}
              </div>
            </div>
          </div>
          {autoPayAllowed && !allAccountsSelected && (
            <AutoPayIndicator
              onClickManage={() => this.props.displayFlyout("manageAutoPayFlyout", { companyId, withCompanyId })}
              autoPayEnabled={autoPayEnabled}
            />
          )}
        </Card>
        {this.renderUnappliedPayments()}
        
        <Card type="table" className="portal-table">        
          <div className="table-data-card-header">
            <div className="table-data-card-header-buttons">
              {allowPayments === true && (
                <button
                  data-test-id="open-invoices__button-pay"
                  className="button-primary"
                  onClick={() => {
                    const selectedInvoices = [...selectedRows].filter(row => row.transactionType !== "CM" || (allowOnlineCMAppl === 2 && !this.props.allAccountsSelected));
                      let filteredOutCreditMemo = selectedInvoices.length !== selectedRows.length;
                      if (filteredOutCreditMemo && this.props.allAccountsSelected) {
                          this.props.displayModal("warnBeforeDeleteModal", {
                              warningMessage: (
                                  Resources.SelectOkToConfirmPayment
                              ),
                              deleteButtonText: (Resources.Ok),
                              delete: () => {
                                  this.props.displayFlyout("makePaymentFlyout", {
                                      selectedInvoices,
                                      columns,
                                      withCompanyId: this.props.withCompanyId,
                                      companyId: this.props.companyId,
                                      perspectiveId: this.props.perspectiveId,
                                      filteredOutCreditMemo,
                                      useRedux: true,
                                      clearSelectedRows: () => this.toggleSelectRow(selectedKeys, selectedRows),
                                      onHide: () => this.handleHideMakePaymentFlyout(),
                                      nationalAccount,
                                      allAccountsSelected: this.props.allAccountsSelected
                                  });

                                  this.props.hideModal();
                              }
                          })
                      } else {
                          this.props.displayFlyout("makePaymentFlyout", {
                              selectedInvoices,
                              columns,
                              withCompanyId: this.props.withCompanyId,
                              companyId: this.props.companyId,
                              perspectiveId: this.props.perspectiveId,
                              filteredOutCreditMemo,
                              useRedux: true,
                              clearSelectedRows: () => this.toggleSelectRow(selectedKeys, selectedRows),
                              onHide: () => this.handleHideMakePaymentFlyout(),
                              nationalAccount,
                              allAccountsSelected: this.props.allAccountsSelected
                          });
                      }
                  }}
                  disabled={
                    (!allowUnappliedPayments && selectedRows.length === 0) ||
                    (selectedRows.length !== 0 && selectedRows.every(inv => (inv || {}).transactionType === "CM"))
                  }
                >                 
                    <IconDollarSign height="24" className="button-primary-icon" />                 
                  <span>{Resources.Pay}</span>
                </button>
              )}                                                         
              {!allAccountsSelected && (
                <button
                  data-test-id="open-invoices__button-send-message"
                  className="button-primary"
                  disabled={isEmpty(selectedRows)}
                  onClick={() => {
                    const selectedInvoices = selectedRows;
                    this.props.displayFlyout("messageFlyout", {
                      columns,
                      selectedInvoices,
                      companyId: this.props.companyId,
                      perspectiveId: this.props.perspectiveId,
                      withContextGroupId: this.props.withContextGroupId,
                      withCompanyId: this.props.withCompanyId,
                      useRedux: true,
                      isPortal,
                      fetchingTemplate: () => this.props.statementsStore.isFetchingOpenInvoicesTemplate,
                      fetchTemplate: () => {
                        return this.props.fetchOpenInvoicesTemplate(
                          this.props.companyId,
                          this.props.perspectiveId,
                          this.props.withCompanyId,
                          { invoiceIds: selectedInvoices.map(i => i.id) }
                        );
                      },
                      clearSelectedRows: () => this.toggleSelectRow(selectedKeys, selectedRows)
                    });
                  }}
                >
                  <IconMessage height="20" className="button-primary-icon" />
                  {Resources.SendMessage}
                </button>
              )}
              {allowDisputes && !allAccountsSelected && (
                <button
                  data-test-id="open-invoices__button-dispute"
                  className="button-primary"
                  disabled={isEmpty(selectedRows)}
                  onClick={() => {
                    const selectedInvoices = [...selectedRows];
                    this.props.displayFlyout("disputeFlyout", {
                      selectedInvoices,
                      columns,
                      withCompanyId: this.props.withCompanyId,
                      companyId: this.props.companyId,
                      withContextGroupId: this.props.withContextGroupId,
                      clearSelectedRows: () => this.toggleSelectRow(selectedKeys, selectedRows)
                    });
                  }}
                >
                  <IconHelp height="20" className="button-primary-icon" />
                  {Resources.Dispute}
                </button>
              )}
              {!allAccountsSelected && (
                <button
                  data-test-id="open-invoices__button-promise-pay"
                  className="button-primary"
                  disabled={isEmpty(selectedRows)}
                  onClick={() => {
                    const selectedInvoices = [...selectedRows];
                    this.props.displayFlyout("promiseToPayFlyout", {
                      selectedInvoices,
                      columns,
                      withCompanyId: this.props.withCompanyId,
                      companyId: this.props.companyId,
                      withContextGroupId: this.props.withContextGroupId,
                      clearSelectedRows: () => this.toggleSelectRow(selectedKeys, selectedRows)
                    });
                  }}
                >
                  <IconStar height="20" className="button-primary-icon" />
                  {Resources.PromiseToPay}
                </button>
              )}
              <button
                data-test-id="open-invoices__button-download"
                className="button-primary"
                disabled={isEmpty(selectedRows) || !selectedRows.some(row => !isEmpty((row || {}).attachments))}
                onClick={() => {
                  let allAttachments = [];
                  selectedRows.forEach(row => {
                    allAttachments = [...allAttachments, ...row.attachments];
                  });
                  this.downloadAttachments(allAttachments, Resources.InvoiceAttachments + ".zip");
                }}
              >
                <IconDownload height="20" className="button-primary-icon" />
                {Resources.DownloadAttachments}
              </button>
              <button
                data-test-id="open-invoices__button-export"
                className="button-primary"
                disabled={isEmpty(selectedRows)}
                onClick={() => this.exportRows(selectedRows)}
              >
                <IconExport height="20" className="button-primary-icon" />
                {Resources.Export}
              </button>
            </div>
            <div className="d-flex align-items-center">
              <div className="table-data-card-header-search">
                <AutoCompleteInput
                  data-test-id="open-invoices__search"
                  className="auto-complete-input__table-data-search"
                  placeholder={Resources.Search}
                  onChange={e => {
                    const searchTerm = e.target.value;
                    this.setState({ searchTerm });
                    this.props.fetchStatementSearchResults({
                      companyId: this.props.companyId,
                      perspectiveId: this.props.perspectiveId,
                      withCompanyId: this.props.withCompanyId,
                      searchTerm,
                      type: "open",
                      allAccountsSelected
                    });
                  }}
                  isShowingSearchResult={statementsStore.isShowingSearchResult}
                  handleClearResult={() => {
                    this.setState({ searchTerm: "" });
                    this.props.clearStatementsSearchResults();
                    this.props.fetchOpenInvoices(
                      this.props.companyId,
                      this.props.perspectiveId,
                      this.props.withCompanyId,
                      null,
                      null,
                      this.props.pageRowCount,
                      null,
                      null,
                      null,
                      allAccountsSelected
                    );
                  }}
                  text={this.state.searchTerm}
                  noResultsMessage={Resources.NoStatementSearchResultsMessage}
                  showNoResultsMessage={statementsStore.fetchedStatementsSearchResults}
                  maxOptions={7}
                  loading={statementsStore.fetchingStatementsSearchResults}
                  handleSelectOption={option => {
                    this.setState({ searchTerm: option.id });
                    this.props.fetchStatementSearchResults({
                      companyId: this.props.companyId,
                      perspectiveId: this.props.perspectiveId,
                      withCompanyId: this.props.withCompanyId,
                      searchTerm: option.id,
                      type: "open",
                      allAccountsSelected
                    });
                    this.props.setOpenInvoicesToSearchResult([option]);
                  }}
                  callToActionButton={
                    <div
                      className="dropdown-item-clean dropdown-call-to-action"
                      onClick={() => this.props.setOpenInvoicesToSearchResult(statementsStore.searchResults)}
                    >
                      {Resources.SeeAllInvoiceResults(this.state.searchTerm)}
                    </div>
                  }
                  renderOption={option => `${Resources.InvoiceNumber} ${option.id}`}
                  options={statementsStore.searchResults}
                  width="300px"
                />
              </div>
              <div className="dropdown">
                <button
                  data-test-id="open-invoices__button-filter"
                  className="button-action-icon dropdown-toggle customer-portal-button-filter data-Tooltip"
                  data-tooltip="Filter"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ marginTop: "1.6rem" }}
                >
                  <IconFilter height="18" />
                </button>
                <div className="dropdown-menu">
                  <form>
                    <div className="mb-2">{Resources.InvoiceDate}</div>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          maxDate={new Date(this.state.toDate)}
                          selected={new Date(this.state.fromDate)}
                          onChange={d =>
                            this.setState({
                              fromDate: d
                            })
                          }
                        />
                      </span>
                      <IconArrowRight line className="mr-3 ml-3" height={14} />
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          minDate={new Date(this.state.fromDate)}
                          selected={new Date(this.state.toDate)}
                          onChange={d =>
                            this.setState({
                              toDate: d
                            })
                          }
                        />
                      </span>
                    </div>

                    <div className="mb-2 mt-4">{Resources.Due_Date}</div>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          maxDate={new Date(this.state.toDueDate)}
                          selected={new Date(this.state.fromDueDate)}
                          onChange={d =>
                            this.setState({
                              fromDueDate: d
                            })
                          }
                        />
                      </span>
                      <IconArrowRight line className="mr-3 ml-3" height={14} />
                      <span>
                        <DatePicker
                          startOpen
                          className="date-picker-input"
                          calendarClassName="date-picker-calendar"
                          dayClassName={d => "date-picker-day"}
                          dateFormat="MMM d, yyyy"
                          minDate={new Date(this.state.fromDueDate)}
                          selected={new Date(this.state.toDueDate)}
                          onChange={d =>
                            this.setState({
                              toDueDate: d
                            })
                          }
                        />
                      </span>
                    </div>
                  </form>
                  <div className="filter-dropdown-buttons">
                    <button
                      data-test-id="invoices__icon-filter-reset"
                      className="filter-dropdown-reset"
                      onClick={() => {
                        this.setState({
                          fromDate: this.defaultState.fromDate,
                          toDate: this.defaultState.toDate,
                          fromDueDate: this.defaultState.fromDueDate,
                          toDueDate: this.defaultState.toDueDate
                        });
                        this.props.updateOpenInvoicesDate(null, null, null, null);
                      }}
                    >
                      {Resources.Reset}
                    </button>
                    <button
                      data-test-id="invoices__icon-filter-save"
                      className="filter-dropdown-save"
                      onClick={() => {
                        this.props.updateOpenInvoicesDate(
                          moment(this.state.fromDate)
                            .utc()
                            .startOf("day")
                            .format("YYYY-MM-DD"),
                          moment(this.state.toDate)
                            .utc()
                            .startOf("day")
                            .format("YYYY-MM-DD"),
                          moment(this.state.fromDueDate)
                            .utc()
                            .startOf("day")
                            .format("YYYY-MM-DD"),
                          moment(this.state.toDueDate)
                            .utc()
                            .startOf("day")
                            .format("YYYY-MM-DD")
                        );
                      }}
                    >
                      {Resources.Save}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TableData
            name="open-invoices__table"
            pagination
            data={data}
            columns={columns}
            hoverColumns={hoverColumns}
            rowHeight="4em"
            onLoadMore={pageSelected => this.handleLoadMore(pageSelected)}
            onSelectAll={this.handleSelectAll}
            addAllSelected={this.handleAddAllSelected}
            maxRows={maxRows}
            loading={loading}
            showSelectAll
            pageName="open invoices"
            selectedType="invoices"
            className={"self-service-table"}
            rowClassName="statements-view-row"
            rowKey="ledgerHash"
            useRedux
            onRowSelectToggle={key =>
              this.toggleSelectRow(
                key,
                (Array.isArray(key) ? [...key] : [key]).map(k => find(data, row => row.ledgerHash === k))
              )
             
            }
            emptyRender={
              <div className="table-data-empty-render">
                <h4 className="mt-5">{Resources.EmptyStatements}</h4>
              </div>
            }  
                                  
          />         
        </Card>        
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    contextGroupsStore: store.contextGroups,
    statementsStore: store.statements,
    perspectivesStore: store.perspectives,
    ledgerStore: store.ledger,
    pageRowCount: store.general.pageRowCount
  };
};

export default withLDConsumer()(connect(storeToProps, dispatchToProps)(OpenInvoices));
