import React, { Component } from "react";
import { Switch, Redirect, withRouter, Route, generatePath } from "react-router-dom";
import { connect } from "react-redux";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import SequencesView from "./sequences/sequencesView";
import DepartmentView from "../../departmentView";

import { dispatchToProps as conDP } from "../../../store/conversations-actions";
import { isGuid } from "../../../lib/utils";

const dispatchToProps = dispatch => ({
  ...conDP(dispatch)
});

class CommunicationsView extends Component {
  componentDidMount() {
    this.fetchFolderConversations(this.props.pageRowCount, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.subView !== this.props.match.params.subView) {
      this.fetchFolderConversations(this.props.pageRowCount, 0);
    }
  }

  getFolderLabel() {
    switch (this.props.match.params.subView) {
      case "closed":
        return "closed";
      case "sent":
        return "waiting-for-response";
      case "snoozed":
        return "reminder";
      case "open":
      default:
        if (isGuid(this.props.match.params.subView)) {
          return null;
        } else {
          return this.props.match.params.subView;
        }
    }
  }

  fetchFolderConversations(top, skip) {
    // TODO: The call below to getConversations was causing additional, unneeded requests to be made to fetch conversations. The <DepartmentView> 
    //       component handles that for itself. If the sequences feature is not going to be enabled or further developed, this function can
    //       likely be removed completely.

    // const { companyId, perspectiveId } = this.props.match.params;
    // const label = this.getFolderLabel();

    // this.props.getConversations(companyId, perspectiveId, label, top, skip);
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Redirect
            exact
            from="/v2/:companyId/:perspectiveId/:view(activities|documents)"
            to="/v2/:companyId/:perspectiveId/:view(activities|documents)/mine"
          />
          <Route path="/v2/:companyId/:perspectiveId/:view(activities|documents|statements|profile|settings)/:selectedContextGroupId(mine|unassigned|assigned|waiting-for-response|pending-approval|reminder|closed|spam|snoozed)/:selectedItemId?/:activityType?">
            <DepartmentView />
          </Route>
          <Route path="/v2/:companyId/:perspectiveId/:subView(sequences)/:sequenceView?">
            {!this.props.flags.sequencesActivityTab && (
              <Redirect to={generatePath("/v2/:companyId/:perspectiveId/activities", this.props.match.params)} />
            )}
            <SequencesView />
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    pageRowCount: store.general.pageRowCount,
    gettingConversations: store.conversations.gettingConversations,
    conversations: store.conversations.conversations
  };
};

export default withLDConsumer()(withRouter(connect(storeToProps, dispatchToProps)(CommunicationsView)));
