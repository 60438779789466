import React from "react";

function DoneSVG() {
  return (
    <div className="svg-container">
      <svg viewBox="0 0 151 100">
        <g fill="none" fillRule="evenodd">
          <path
            fill="#F4F3FF"
            d="M83.1898148 23c2.7026157 0 4.8935185 2.1938047 4.8935185 4.9 0 2.7061953-2.1909028 4.9-4.8935185 4.9h44.7407412c2.702615 0 4.893518 2.1938047 4.893518 4.9 0 2.7061953-2.190903 4.9-4.893518 4.9h15.379629c2.702616 0 4.893519 2.1938047 4.893519 4.9 0 2.7061953-2.190903 4.9-4.893519 4.9h-13.282407c-2.702616 0-4.893519 2.1938047-4.893519 4.9 0 2.7061953 2.190903 4.9 4.893519 4.9h4.194444c2.702616 0 4.893519 2.1938047 4.893519 4.9 0 2.7061953-2.190903 4.9-4.893519 4.9H32.1574074c-2.7026156 0-4.8935185-2.1938047-4.8935185-4.9 0-2.7061953 2.1909029-4.9 4.8935185-4.9H4.89351852C2.19090287 62.2 0 60.0061953 0 57.3c0-2.7061953 2.19090287-4.9 4.89351852-4.9H32.8564815C35.5590971 52.4 37.75 50.2061953 37.75 47.5c0-2.7061953-2.1909029-4.9-4.8935185-4.9H15.3796296c-2.7026156 0-4.8935185-2.1938047-4.8935185-4.9 0-2.7061953 2.1909029-4.9 4.8935185-4.9h27.962963c-2.7026157 0-4.8935185-2.1938047-4.8935185-4.9 0-2.7061953 2.1909028-4.9 4.8935185-4.9h39.8472222zm62.9166662 39.2C148.809097 62.2 151 64.3938047 151 67.1c0 2.7061953-2.190903 4.9-4.893519 4.9-2.702615 0-4.893518-2.1938047-4.893518-4.9 0-2.7061953 2.190903-4.9 4.893518-4.9z"
          />
          <path
            fill="#FFF"
            stroke="#BEB6FF"
            strokeLinecap="round"
            strokeWidth="2.5"
            d="M97.92398615 78.02379477c2.12595283-1.3645525 4.01463643-3.06936305 5.24255026-4.44661315m1.9812963-2.2888765h0c1.01532546-1.24546845 1.92745736-2.54841715 2.73625079-3.89737591 1.7788483-2.96688 3.05781779-6.15632337 3.8353664-9.44629573.7863798-3.3273389 1.05988444-6.75750346.81891789-10.16425263-.2102662-2.97271571-.81226257-5.92760265-1.80704864-8.7807853-.98693084-2.8306542-2.36047352-5.56120349-4.12166185-8.1097438-1.04946594-1.51863515-2.23657567-2.9726438-3.56154961-4.34469448-1.94529199-2.01440882-4.07750935-3.73799391-6.34181783-5.16953386-2.43292595-1.53814237-5.01834764-2.73912511-7.68824594-3.6014326-3.34368719-1.0799226-6.81986887-1.62866117-10.29493935-1.64323885-2.70381415-.01134244-5.40695574.30068441-8.04649322.93748194-2.97159103.7169065-5.86256883 1.84543592-8.58313885 3.38758837-2.39154721 1.3556461-4.6514121 3.03091884-6.7185978 5.02717687-2.59780729 2.50867333-4.69962236 5.33914404-6.30391526 8.3703347-1.57067398 2.96767026-2.6644591 6.12773632-3.27991969 9.36657428-.63408651 3.33685569-.76045897 6.7573233-.37754766 10.13714761.49629884 4.38065607 1.8481567 8.69303448 4.0589921 12.66658423 1.41464949 2.54255928 3.18099429 4.94639027 5.2999301 7.14061253 3.36383842 3.48335666 7.28662234 6.09709152 11.48481743 7.8348885 5.1053832 2.11331756 10.61806507 2.93123267 16.028125 2.44238636 4.5405669-.4102801 9.0088474-1.74103582 13.10338363-3.9989823"
          />
          <g stroke="#BEB6FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5">
            <path d="M111.5 9v4.2M111.5 18.8V23M104.35714286 16h4.28571428M114.35714286 16h4.28571428" />
          </g>
          <g stroke="#BEB6FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5">
            <path d="M53.5 82v3M53.5 91v3M48.32352935 87.99999994h2.5882353M56.08823535 88.00000006h2.5882353" />
          </g>
          <path
            fill="#FFF"
            stroke="#BEB6FF"
            strokeLinecap="round"
            strokeWidth="2.5"
            d="M68.64192555 22.23611037c-1.42785634.53202195-2.82221992 1.17954727-4.1688669 1.94289276-2.09260382 1.18619039-4.0699856 2.65205409-5.87877307 4.39877985-.74145432.71601412-1.43673209 1.46198733-2.08578672 2.23424285M54.50102 33.5015546c-.51558414.78132134-.9898173 1.58244709-1.42266028 2.40027128-.31968287.60401675-.61678828 1.21714209-.89130033 1.83812482"
          />
          <path
            fill="#F4F3FF"
            fillRule="nonzero"
            stroke="#BEB6FF"
            strokeWidth="2.5"
            d="M66.186123 49.3772641c-1.0194098-.947427-2.6007031-.8746857-3.5319175.1624722-.9312144 1.0371578-.8597179 2.6459816.1596919 3.5934085L74.579732 64.0681666c1.4271737 1.3263977 3.6409843 1.2245599 4.9446845-.227461.0545161-.0616733.0545161-.0616733.1071237-.1250392l16.790723-20.5459271c.8827319-1.0801527.7376748-2.6838437-.3239942-3.5819441-1.0616689-.8981003-2.6379173-.7505177-3.5206491.329635L76.7916422 59.2339031l-10.6055192-9.856639z"
          />
        </g>
      </svg>
    </div>
  );
}

export default DoneSVG;
