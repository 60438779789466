import { Component } from "react";
import React from "react";
import { generatePath, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Card from "../../../library/card";
import IconPlusCircle from "../../../library/icons/iconPlusCircle";

import { dispatchToProps as networkDP } from "../../../../store/network-actions";
import { dispatchToProps as moDP } from "../../../../store/modal-actions";

import { find, isEmpty } from "../../../../lib/utils";
import SelectInput from "../../../library/selectInput";
import MainLoader from "../../../mainLoader";
import Resources from "../../../../lib/resources";
import TextInput from "../../../library/textInput";
import moment from "moment";
import IconTrash from "../../../library/icons/iconTrash";
import { isLongerThan } from "../../../../lib/validation";

const dispatchToProps = dispatch => ({
  ...networkDP(dispatch),
  ...moDP(dispatch)
});

class AddContactsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.contactInfo,
      dropdownActive: false,
      contactsData: {},
      primaryContact: {},
      secondaryContact: {},
      additionalContacts: [],
      markedForDelete: []
    };
    this.roles = [
      { value: 1, role: Resources.CFO },
      { value: 2, role: Resources.Controller },
      {
        value: 3,
        role: (this.props.match.params.mode === "ar" ? Resources.AR : Resources.AP) + " " + Resources.Manager
      },
      {
        value: 4,
        role: (this.props.match.params.mode === "ar" ? Resources.AR : Resources.AP) + " " + Resources.Specialist
      },
      { value: 5, role: Resources.SalesRepresentitive },
      { value: 6, role: Resources.Other }
    ];
    this.submitForm = this.submitForm.bind(this);
    this.addContact = this.addContact.bind(this);
  }

  set roles(value) {
    this._roles = value;
  }

  get roles() {
    return this._roles;
  }

  componentDidMount() {
    let accessId = this.props.match.params.accessId;
    let mode = this.props.match.params.mode;
    if (this.props.empty) {
    } else {
      this.props.fetchCustAccountContacts(accessId, mode).then(response => {
        this.setState({
          primaryContact: response.contacts.filter(i => i.isPrimaryContact)[0] || {},
          secondaryContact: response.contacts.filter(i => i.isSecondaryContact)[0] || {},
          additionalContacts: response.contacts.filter(i => !i.isPrimaryContact && !i.isSecondaryContact)
        });
      });
    }
  }

  submitForm() {
    let accountContactData = { ...this.props.networkStore.custAccountContacts, ...this.state.accountContactData };
    let accessId = this.props.match.params.accessId;
    let mode = this.props.match.params.mode;
    let { primaryContact } = this.state;
    this.state.markedForDelete.map(i => {
      if (i.emailAddr === null || i.emailAddr === "") {
        i.emailAddr = "lockstepdev@gmail.com";
      }
      return null;
    });
    let postRequest = {
      AccountProfileKey: accountContactData.AccountProfileKey,
      AccountKey: accountContactData.AccountKey,
      APProfileKey: accountContactData.APProfileKey,
      AccountName: accountContactData.AccountName,
      emailAddr: accountContactData.emailAddr,
      isLockstepVerified: accountContactData.isLockstepVerified,
      ProfileUpdateDate: moment().format(),
      contacts: [
        {
          contactName: primaryContact.contactName,
          emailAddr: primaryContact.emailAddr,
          contactRole: primaryContact.contactRole,
          isPrimaryContact: true,
          phone: primaryContact.phone,
          isLockstepVerified: true,
          localContactKey: primaryContact.localContactKey,
          contactRoleId: primaryContact.contactRoleId
        },
        ...this.state.additionalContacts,
        ...this.state.markedForDelete
      ]
    };
    postRequest.contacts.map(i => (i.isLockstepVerified = true));
    if (
      postRequest.contacts.filter(
        i =>
          (isEmpty(i.contactName) && !isEmpty(i.emailAddr)) ||
          (!isEmpty(i.contactName) && isEmpty(i.emailAddr) && !i.isInactive)
      ).length !== 0
    ) {
      let errorMessage = Resources.PleaseEnsureNameEmailAndRoleFilledOut;
      this.props.displayModal("errorModal", { errorMessage });
    } else {
      postRequest.contacts = postRequest.contacts.filter(i => i.contactName !== undefined && i.contactName !== "");
      postRequest.contacts.forEach(i => (i.isLockstepVerified = true));
      this.props.postCustAccountKeyContact(accessId, postRequest, mode).then(response => {
        this.props.history.push(
          generatePath("/v2/profileupdate/:type(account)/:mode(ar|ap)/documents/:accessId", {
            accessId: this.props.match.params.accessId,
            type: this.props.match.params.type,
            mode: this.props.match.params.mode
          })
        );
      });
    }
  }

  handleDropdown() {
    this.setState({ dropdownActive: !this.state.dropdownActive });
  }

  addContact() {
    this.setState({
      additionalContacts: [...this.state.additionalContacts, { isLockstepVerified: true, emailAddr: "" }]
    });
  }

  renderAdditionalContacts() {
    return this.state.additionalContacts.map((item, i) => (
      <div className="additional-contact-container" key={i} data-key={i}>
        <div className="first-time-card-column">
          <div className="first-time-card-cell">
            <TextInput
              width="23.2rem"
              labelInline={false}
              label={Resources.Name}
              textValue={item.contactName}
              isValid={isLongerThan(item.contactName || "", 100)}
              invalidText={Resources.FieldMustBeLessThanN(100)}
              inputOnChange={e => {
                let value = e.target.value;
                if (this.props.empty) {
                  this.props.updateAdditionalContacts("contactName", value, i);
                }
                let additionalContactsUpdate = [...this.state.additionalContacts];
                additionalContactsUpdate[i] = { ...additionalContactsUpdate[i], contactName: value };
                this.setState({ additionalContacts: additionalContactsUpdate });
              }}
            />
          </div>
          <div className="first-time-card-cell">
            <SelectInput
              className="field"
              value={(find(this._roles, role => role.value === item.contactRoleId) || {}).value}
              displayValue={
                (find(this._roles, role => role.value === item.contactRoleId) || {}).role || Resources.Select
              }
              optionValueKey="value"
              optionDisplayKey="role"
              options={this._roles}
              labelInline={false}
              label={Resources.Role}
              width={"23.2rem"}
              onSelectOption={option => {
                if (this.props.empty) {
                  this.props.updateAdditionalContacts("contactRoleId", option.value, i);
                }
                let additionalContactsUpdate = [...this.state.additionalContacts];
                additionalContactsUpdate[i] = { ...additionalContactsUpdate[i], contactRoleId: option.value };
                this.setState({ additionalContacts: additionalContactsUpdate });
              }}
            />
          </div>
          <div className="profile-udpate-remove-contact-adjustment">
            <button
              key={i}
              onClick={() => {
                if (this.props.empty) {
                  this.props.deleteAdditionalContacts(i);
                }
                let additionalContactsUpdate = [...this.state.additionalContacts];
                additionalContactsUpdate[i].isInactive = true;
                let updatedMarkedForDelete = [...this.state.markedForDelete, additionalContactsUpdate[i]];
                additionalContactsUpdate.splice(i, 1);
                this.setState({
                  additionalContacts: additionalContactsUpdate,
                  markedForDelete: updatedMarkedForDelete
                });
              }}
              className="button-primary small"
            >
              <IconTrash height={20} className="button-primary-icon" />
              {Resources.Remove}
            </button>
          </div>
        </div>
        <div className="first-time-card-column">
          <div className="first-time-card-cell">
            <TextInput
              width="23.2rem"
              labelInline={false}
              label={Resources.Email}
              invalidText={Resources.InvalidEmailFormat}
              textValue={item.emailAddr}
              inputOnChange={e => {
                let value = e.target.value;
                if (this.props.empty) {
                  this.props.updateAdditionalContacts("emailAddr", value, i);
                }
                let additionalContactsUpdate = [...this.state.additionalContacts];
                additionalContactsUpdate[i] = { ...additionalContactsUpdate[i], emailAddr: value };
                this.setState({ additionalContacts: additionalContactsUpdate });
              }}
            />
          </div>
          <div className="first-time-card-cell">
            <TextInput
              width="23.2rem"
              labelInline={false}
              label={Resources.Phone}
              invalidText={Resources.InvalidPhoneNumber}
              textValue={item.phone}
              inputOnChange={e => {
                let value = e.target.value;
                if (this.props.empty) {
                  this.props.updateAdditionalContacts("phone", value, i);
                }
                let additionalContactsUpdate = [...this.state.additionalContacts];
                additionalContactsUpdate[i] = { ...additionalContactsUpdate[i], phone: value };
                this.setState({ additionalContacts: additionalContactsUpdate });
              }}
            />
          </div>
        </div>
      </div>
    ));
  }

  render() {
    let { primaryContact } = this.state;
    if (this.props.networkStore.gotCustAccountContacts || this.props.empty) {
      // return this.props.networkStore.custAccount.isLockstepVerified ? (
      //   <React.Fragment>
      //     <ContactTableForm
      //       profileUpdate
      //       contacts={this.props.networkStore.custAccountContacts}
      //       companyName={accountData.companyName}
      //     />
      //   </React.Fragment>
      // ) : (
      return (
        <React.Fragment>
          {this.props.empty ? null : <h2 className="first-time-title-contact">{Resources.AddContactsMessage}</h2>}
          <Card className="first-time-form-card add-contacts">
            <div className="first-time-card-form">
              {/* <Dropdown
                buttonContent={
                  <React.Fragment>
                    <IconHelp height={16} className="button-secondary-icon" />
                    {Resources.HowIsMyDataUsed}
                  </React.Fragment>
                }
                buttonClassName="button-secondary contact-form-dropdown"
              >
                <div>Don't worry about it</div>
              </Dropdown> */}
              <p className="contact-add-form-header">{Resources.PrimaryContact}</p>
              <div className="first-time-card-column">
                <div className="first-time-card-cell">
                  <TextInput
                    width="23.2rem"
                    labelInline={false}
                    label={Resources.Name}
                    isValid={isLongerThan(primaryContact.contactName || "", 100)}
                    invalidText={Resources.FieldMustBeLessThanN(100)}
                    textValue={primaryContact.contactName}
                    inputOnChange={e => {
                      let value = e.target.value;
                      if (this.props.empty) {
                        this.props.updatePrimaryContact("contactName", value);
                      }
                      this.setState(prevState => {
                        return { primaryContact: { ...prevState.primaryContact, contactName: value } };
                      });
                    }}
                  />
                </div>
                <div className="first-time-card-cell">
                  <SelectInput
                    className="field"
                    value={primaryContact.contactRoleId}
                    displayValue={
                      (find(this._roles, role => role.value === primaryContact.contactRoleId) || {}).role ||
                      Resources.Select
                    }
                    optionValueKey="value"
                    optionDisplayKey="role"
                    options={this._roles}
                    labelInline={false}
                    label={Resources.Role}
                    width={"23.2rem"}
                    onSelectOption={option => {
                      let primaryContactUpdate = { ...primaryContact, contactRoleId: option.value };
                      if (this.props.empty) {
                        this.props.updatePrimaryContact("contactRoldId", option.value);
                      }
                      this.setState({ primaryContact: primaryContactUpdate });
                    }}
                  />
                </div>
              </div>
              <div className="first-time-card-column">
                <div className="first-time-card-cell">
                  <TextInput
                    width="23.2rem"
                    labelInline={false}
                    label={Resources.Email}
                    invalidText={Resources.InvalidEmailFormat}
                    textValue={primaryContact.emailAddr}
                    inputOnChange={e => {
                      let value = e.target.value;
                      if (this.props.empty) {
                        this.props.updatePrimaryContact("emailAddr", value);
                      }
                      this.setState(prevState => {
                        return { primaryContact: { ...prevState.primaryContact, emailAddr: value } };
                      });
                    }}
                  />
                </div>
                <div className="first-time-card-cell">
                  <TextInput
                    width="23.2rem"
                    labelInline={false}
                    label={Resources.Phone}
                    invalidText={Resources.InvalidPhoneNumber}
                    textValue={primaryContact.phone}
                    inputOnChange={e => {
                      let value = e.target.value;
                      if (this.props.empty) {
                        this.props.updatePrimaryContact("phone", value);
                      }
                      this.setState(prevState => {
                        return { primaryContact: { ...prevState.primaryContact, phone: value } };
                      });
                    }}
                  />
                </div>
              </div>
              {this.state.additionalContacts ? (
                <React.Fragment>
                  {this.state.additionalContacts.length > 0 && (
                    <h2 className="contact-add-form-header">{Resources.AdditionalContacts}</h2>
                  )}
                  {this.renderAdditionalContacts()}
                </React.Fragment>
              ) : null}
            </div>
            <div className="add-contacts-button-adjustment">
              <button onClick={this.addContact} className="button-primary small">
                <IconPlusCircle height={20} className="button-primary-icon" />
                {Resources.AddNewContact}
              </button>
            </div>
          </Card>
          {this.props.empty ? null : (
            <div className="profile-update-form-button-container">
              <button onClick={this.submitForm} className="button-primary-square large">
                {Resources.Next}
              </button>
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return <MainLoader className="profile-update-form-mainloader" fullScreen={true} />;
    }
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network,
    modalStore: store.modal
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(AddContactsForm));
