import { Card } from "reactstrap";
import { generatePath, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import UserAvatar from "../../../library/userAvatar";
import Avatar from "../../../library/avatar";
import IconUpload from "../../../library/icons/iconUpload";
import React, { Component } from "react";
import IconInfo from "../../../library/icons/iconInfo";
import IconMail from "../../../library/icons/iconMail";
import IconPhone from "../../../library/icons/iconPhone";
import IconArchive from "../../../library/icons/iconArchive";
import IconMapPin from "../../../library/icons/iconMapPin";
import IconLinkedIn from "../../../library/icons/iconLinkedIn";
import IconClipboard from "../../../library/icons/iconClipboard";
import IconBriefcase from "../../../library/icons/iconBriefcase";
import { dispatchToProps as networkDP } from "../../../../store/network-actions";
import { dispatchToProps as moDP } from "../../../../store/modal-actions";
import Dropdown from "../../../library/dropdown";
import Checkbox from "../../../library/checkbox";
import MainLoader from "../../../mainLoader";
import Resources from "../../../../lib/resources";
import TextInput from "../../../library/textInput";
import TextArea from "../../../library/textAreaInput";
import SelectInput from "../../../library/selectInput";
import moment from "moment";
import { find, isEmpty, formatDate } from "../../../../lib/utils";
import { BlockPicker } from "react-color";
import { isEmail, isLongerThan } from "../../../../lib/validation";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...networkDP(dispatch)
});

class ProfileUpdateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.profileInfo,
      accountData: {},
      settings: {},
      sameAsBilling: false,
      dropdownActive: false,
      updatedPicture: false,
      activeColor: "#1fb6c1",
      editMode: true
    };
    this.submitForm = this.submitForm.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidMount() {
    if (this.props.admin) {
      let accountingGroupKey = this.props.match.params.accountingGroupKey;
      let companyGlobalKey = this.props.match.params.companyGlobalKey;
      let mode = this.props.match.params.mode;
      this.props.fetchAccountingGroupProfile(accountingGroupKey, companyGlobalKey, mode);
      this.props.fetchAccountSettings(accountingGroupKey);
    } else if (this.props.small || this.props.edit) {
      //custContact is already loaded
    } else {
      let accessId = this.props.match.params.accessId;
      let mode = this.props.match.params.mode;
      if (!this.props.signup) {
        this.props.fetchCustAccount(accessId, mode).then(response => {
          this.setState({ editMode: !this.props.networkStore.custAccount.isLockstepVerified });
        });
      }
    }
  }

  handleDropdown() {
    this.setState({ dropdownActive: !this.state.dropdownActive });
  }

  checkForInvalidInputs(postRequest) {
    let value = isEmail(postRequest.emailAddr);
    return value;
  }

  submitForm() {
    let accountData = this.props.edit
      ? {
          ...(this.props.getAccountProfile(this.props.match.params.perspectiveId, this.props.match.params.accountId) ||
            {}),
          ...this.state.accountData
        }
      : {
          ...(this.props.networkStore.custAccount.companyAPDetails ||
            this.props.networkStore.custAccount.companyARDetails),
          ...this.state.accountData
        };
    let accountingGroupDetails = {
      ...(this.props.networkStore.custAccount.accountingGroupAPDetails ||
        this.props.networkStore.custAccount.accountingGroupARDetails),
      ...this.state.accountingGroupDetails
    };
    let accessId = this.props.match.params.accessId;
    let companyGlobalKey = this.props.edit
      ? this.props.match.params.accountId
      : this.props.match.params.companyGlobalKey;
    let accountingGroupKey = this.props.edit
      ? this.props.match.params.perspectiveId
      : this.props.match.params.accountingGroupKey;
    let mode = this.props.edit ? this.props.mode : this.props.match.params.mode;
    let companyDetailToggle =
      this.props.match.params.mode === "ap" || this.props.mode === "ap" ? "companyAPDetails" : "companyARDetails";

    let companyDetails = {
      accountProfileKey: accountData.accountProfileKey,
      accountKey: accountData.accountKey,
      aPProfileKey: accountData.ProfileKey,
      companyName: !this.props.edit
        ? this.props.networkStore.custAccount[companyDetailToggle].companyName
        : accountData.companyName,
      taxId: accountData.taxId,
      entityClassificationId: accountData.entityClassificationId,
      entityClassification: accountData.entityClassification,
      dateOfIncoporation: accountData.dateOfIncoporation,
      stateOfIncoporation: accountData.stateOfIncoporation,
      countryOfIncoporation: accountData.countryOfIncoporation,
      emailAddr: accountData.emailAddr,
      phone: accountData.phone,
      phoneExt: accountData.phoneExt,
      fax: accountData.fax,
      faxExt: accountData.faxExt,
      billToAddrLn1: accountData.billToAddrLn1,
      billToCity: accountData.billToCity,
      billToState: accountData.billToState,
      billToCountry: accountData.billToCountry,
      billToPostalcode: accountData.billToPostalcode,
      shipToAddrLn1: this.state.sameAsBilling ? accountData.billToAddrLn1 : accountData.shipToAddrLn1,
      shipToAddrLn2: accountData.shipToAddrLn2,
      shipToCity: this.state.sameAsBilling ? accountData.billToCity : accountData.shipToCity,
      shipToState: this.state.sameAsBilling ? accountData.billToState : accountData.shipToState,
      shipToCountry: this.state.sameAsBilling ? accountData.billToCountry : accountData.shipToCountry,
      shipToPostalcode: this.state.sameAsBilling ? accountData.billToPostalcode : accountData.shipToPostalcode,
      mailToAddrLn1: accountData.mailToAddrLn1,
      mailToCity: accountData.mailToCity,
      mailToCountry: accountData.mailToCountry,
      mailToPostalcode: accountData.mailToPostalcode,
      mailToState: accountData.mailToState,
      isLockstepVerified: true,
      notes: accountData.notes,
      linkedInProfile: accountData.linkedInProfile,
      logoURL: accountData.logoURL,
      profileUpdateDate: moment().format()
    };

    let accountingGroupToggle =
      this.props.match.params.mode === "ap" || this.props.mode === "ap"
        ? "accountingGroupAPDetails"
        : "accountingGroupARDetails";

    let postRequest = {
      [companyDetailToggle]: companyDetails,
      [accountingGroupToggle]: {
        emailAddr: this.props.edit ? accountData.emailAddr : accountingGroupDetails.emailAddr
      },
      isLockstepVerified: true
    };

    if (this.props.edit) {
      // let settings = { ...this.props.networkStore.custAccountSettings, ...this.state.settings };
      // let settingsRequest = {
      //   accountingGroupKey: accountingGroupKey,
      //   buttonColor: settings.buttonColor
      // };
      // this.props.postAccountSettings(accountingGroupKey, settingsRequest);
      if (!!postRequest[accountingGroupToggle].emailAddr) {
        this.props
          .updateAccountingGroupProfile(accountingGroupKey, companyGlobalKey, mode, postRequest)
          .then(response => {
            this.props.toggleEditMode();
          });
      } else {
        let errorMessage = Resources.EmailFieldIsEmpty;
        this.props.displayModal("errorModal", { errorMessage });
      }
    } else {
      this.props.postCustAccount(accessId, mode, postRequest).then(response => {
        this.props.history.push(
          generatePath("/v2/profileupdate/:type(account)/:mode(ar|ap)/addcontact/:accessId", {
            accessId: this.props.match.params.accessId,
            type: this.props.match.params.type,
            mode: this.props.match.params.mode
          })
        );
      });
    }
  }

  uploadFile() {
    window.document.getElementById("uploadFile").click();
  }

  handleChangeComplete = color => {
    this.setState({ settings: { ...this.state.settings, buttonColor: color.hex } });
  };

  render() {
    let classifications = [
      { value: 1, classification: "Corporation" },
      { value: 2, classification: "Partnership" },
      { value: 3, classification: "Sole-proprietor" }
    ];
    let accountData = this.props.edit
      ? {
          ...(this.props.getAccountProfile(this.props.match.params.perspectiveId, this.props.match.params.accountId) ||
            {}),
          ...this.state.accountData
        }
      : {
          ...(this.props.networkStore.custAccount.companyAPDetails ||
            this.props.networkStore.custAccount.companyARDetails),
          ...this.state.accountData
        };
    let accountingGroupDetails = {
      ...(this.props.networkStore.custAccount.accountingGroupAPDetails ||
        this.props.networkStore.custAccount.accountingGroupARDetails),
      ...this.state.accountingGroupDetails
    };

    let settings = { ...this.props.networkStore.custAccountSettings, ...this.state.settings };

    if (this.props.networkStore.gettingCustAccount && !this.props.networkStore.postedCustAccount) {
      return <MainLoader className="profile-update-form-mainloader" fullScreen={true} />;
    } else {
      return this.state.editMode ? (
        <React.Fragment>
          {this.props.small || this.props.edit ? null : (
            <h2 className="first-time-title-contact">
              {this.props.networkStore.custAccount.isLockstepVerified
                ? Resources.ProfileUpdateThanks
                : Resources.ProfileUpdateWelcome}
            </h2>
          )}
          <Card
            className={
              this.props.small && !this.props.empty
                ? null
                : `first-time-form-card ${this.props.edit ? "edit" : "center"}`
            }
          >
            {/* {this.props.small ? null : (
              <Dropdown
                disabled
                buttonContent={
                  <React.Fragment>
                    <IconHelp height={16} className="button-secondary-icon" />
                    {Resources.HowIsMyDataUsed}
                  </React.Fragment>
                }
                buttonClassName="button-secondary contact-form-dropdown"
              >
                <div>Don't worry about it</div>
              </Dropdown>
            )} */}
            <div className="profile-update-card-form">
              <div className="first-time-card-column">
                <div className="first-time-card-cell">
                  {this.props.edit || this.props.empty ? (
                    <TextInput
                      width="23.2rem"
                      labelInline={false}
                      label={Resources.CompanyName}
                      isValid={isLongerThan(
                        this.props.small ? this.props.editedAccount.companyName : accountData.companyName || "",
                        100
                      )}
                      invalidText={Resources.FieldMustBeLessThanN(100)}
                      textValue={this.props.small ? this.props.editedAccount.companyName : accountData.companyName}
                      inputOnChange={e => {
                        let value = e.target.value;
                        this.props.small
                          ? this.props.formOnChange("companyName", value)
                          : this.setState(prevState => {
                              return { accountData: { ...prevState.accountData, companyName: value } };
                            });
                      }}
                    />
                  ) : this.props.small ? (
                    <TextInput
                      width="23.2rem"
                      labelInline={false}
                      label={
                        this.props.match.params.mode || this.props.mode === "ar" ? Resources.ArEmail : Resources.ApEmail
                      }
                      textValue={this.props.small ? this.props.emailAddr : accountData.emailAddr}
                      inputOnChange={e => {
                        let value = e.target.value;
                        this.props.small
                          ? this.props.formOnChange("emailAddr", value)
                          : this.setState(prevState => {
                              return { accountData: { ...prevState.accountData, emailAddr: value } };
                            });
                      }}
                    />
                  ) : (
                    <React.Fragment>
                      <div className="profile-invariant-container">
                        <label className="input-label">{Resources.CompanyName}</label>
                        <Dropdown
                          toggleOnHover
                          buttonContent={
                            <React.Fragment>
                              <IconInfo height={15} className="profile-update-invariant-field__icon" />
                            </React.Fragment>
                          }
                          buttonClassName="profile-update-invariant-button"
                        >
                          <div className="profile-update-dropdown-content">
                            {Resources.IfYourVARIABLEIsDifferentContactUsAtEMAIL(
                              "company name",
                              "NEED A FETCH FOR THIS EMAIL"
                            )}
                          </div>
                        </Dropdown>
                      </div>
                      <p className={"profile-update-invariant-field"}>
                        {this.props.small ? this.props.editedAccount.companyName : accountData.companyName}
                      </p>
                    </React.Fragment>
                  )}
                </div>
                {this.props.small && !this.props.empty ? null : (
                  <div className="first-time-card-cell">
                    <TextInput
                      width="23.2rem"
                      labelInline={false}
                      label={Resources.ein}
                      isValid={isLongerThan(
                        this.props.small ? this.props.editedAccount.taxId : accountData.taxId || "",
                        25
                      )}
                      invalidText={Resources.FieldMustBeLessThanN(25)}
                      textValue={this.props.small ? this.props.editedAccount.taxId : accountData.taxId}
                      inputOnChange={e => {
                        let value = e.target.value;
                        this.props.small
                          ? this.props.formOnChange("taxId", value)
                          : this.setState(prevState => {
                              return { accountData: { ...prevState.accountData, taxId: value } };
                            });
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="first-time-card-column">
                <div className="first-time-card-cell">
                  {this.props.small && !this.props.empty ? (
                    <TextInput
                      width="23.2rem"
                      labelInline={false}
                      label={Resources.ein}
                      isValid={isLongerThan(
                        this.props.small ? this.props.editedAccount.taxId : accountData.taxId || "",
                        25
                      )}
                      invalidText={Resources.FieldMustBeLessThanN(25)}
                      textValue={this.props.small ? this.props.editedAccount.taxId : accountData.taxId}
                      inputOnChange={e => {
                        let value = e.target.value;
                        this.props.small
                          ? this.props.formOnChange("taxId", value)
                          : this.setState(prevState => {
                              return { accountData: { ...prevState.accountData, taxId: value } };
                            });
                      }}
                    />
                  ) : (
                    <TextInput
                      width="23.2rem"
                      labelInline={false}
                      label={
                        (this.props.match.params.mode || this.props.mode) === "ar"
                          ? Resources.ArEmail
                          : Resources.ApEmail
                      }
                      textValue={
                        this.props.small && !this.props.edit
                          ? this.props.editedAccount.emailAddr
                          : this.props.edit
                          ? accountData.emailAddr
                          : accountingGroupDetails.emailAddr
                      }
                      isValid={isEmail(
                        this.props.small && !this.props.edit
                          ? this.props.editedAccount.emailAddr
                          : this.props.edit
                          ? accountData.emailAddr
                          : accountingGroupDetails.emailAddr
                      )}
                      invalidText={Resources.InvalidEmailFormat}
                      inputOnChange={e => {
                        let value = e.target.value;
                        this.props.small && !this.props.edit
                          ? this.props.formOnChange("emailAddr", value)
                          : this.props.edit
                          ? this.setState(prevState => {
                              return {
                                accountData: { ...prevState.accountData, emailAddr: value }
                              };
                            })
                          : this.setState(prevState => {
                              return {
                                accountingGroupDetails: { ...prevState.accountingGroupDetails, emailAddr: value }
                              };
                            });
                      }}
                    />
                  )}
                </div>
                <div className="first-time-card-cell">
                  <SelectInput
                    className="field"
                    value={
                      find(
                        classifications,
                        classification => classification.value === accountData.entityClassificationId
                      ) === undefined
                        ? null
                        : accountData.entityClassificationId
                    }
                    displayValue={
                      (
                        find(
                          classifications,
                          classification =>
                            classification.value ===
                            (this.props.small
                              ? this.props.editedAccount.entityClassificationId
                              : accountData.entityClassificationId)
                        ) || {}
                      ).classification
                    }
                    optionValueKey="value"
                    optionDisplayKey="classification"
                    options={classifications}
                    labelInline={false}
                    label={Resources.EntityClassification}
                    width={"23.2rem"}
                    onSelectOption={option => {
                      this.props.small
                        ? this.props.formOnChange("entityClassificationId", option.value)
                        : this.setState(prevState => {
                            return { accountData: { ...prevState.accountData, entityClassificationId: option.value } };
                          });
                    }}
                  />
                </div>
              </div>
              <div className="first-time-card-column">
                <div className="first-time-card-cell">
                  <TextInput
                    width="23.2rem"
                    labelInline={false}
                    label={
                      this.props.match.params.mode === "ar" || this.props.mode === "ar"
                        ? Resources.ArPhone
                        : Resources.ApPhone
                    }
                    textValue={this.props.small ? this.props.editedAccount.phone : accountData.phone}
                    invalidText={Resources.InvalidPhoneNumber}
                    inputOnChange={e => {
                      let value = e.target.value;
                      this.props.small
                        ? this.props.formOnChange("phone", value)
                        : this.setState(prevState => {
                            return { accountData: { ...prevState.accountData, phone: value } };
                          });
                    }}
                  />
                </div>
                <div className="first-time-card-cell">
                  <TextInput
                    width="23.2rem"
                    labelInline={false}
                    label={
                      this.props.match.params.mode === "ar" || this.props.mode === "ar"
                        ? Resources.ArFax
                        : Resources.ApFax
                    }
                    textValue={this.props.small ? this.props.editedAccount.fax : accountData.fax}
                    invalidText={Resources.InvalidFaxNumber}
                    inputOnChange={e => {
                      let value = e.target.value;
                      this.props.small
                        ? this.props.formOnChange("fax", value)
                        : this.setState(prevState => {
                            return { accountData: { ...prevState.accountData, fax: value } };
                          });
                    }}
                  />
                </div>
              </div>
              <div className="first-time-card-column">
                <div className="first-time-card-cell">
                  <TextInput
                    width="32.46rem"
                    labelInline={false}
                    label={Resources.LinkedIn}
                    // isValid={isDomain(
                    //   this.props.small ? this.props.editedAccount.linkedInProfile : accountData.linkedInProfile
                    // )}
                    // invalidText={Resources.InvalidDomain}
                    textValue={
                      this.props.small ? this.props.editedAccount.linkedInProfile : accountData.linkedInProfile
                    }
                    inputOnChange={e => {
                      let value = e.target.value;
                      this.props.small
                        ? this.props.formOnChange("linkedInProfile", value)
                        : this.setState(prevState => {
                            return { accountData: { ...prevState.accountData, linkedInProfile: value } };
                          });
                    }}
                  />
                </div>
              </div>
            </div>
            {this.props.empty ? null : (
              <div className="first-time-card-profile-picture-container">
                <p>{Resources.CompanyLogo}</p>
                <div className="first-time-profile-content">
                  <div className="first-time-profile-picture">
                    {!!accountData.logoURL ? (
                      <Avatar
                        height={"4.3rem"}
                        imgURL={accountData.logoURL}
                        // type="user"
                        objectName={accountData.companyName}
                        className={"avatar"}
                      ></Avatar>
                    ) : (
                      <UserAvatar height={25}></UserAvatar>
                    )}
                  </div>
                  <button disabled={this.props.empty} onClick={this.uploadFile} className="button-primary">
                    <IconUpload className="button-primary-icon" height={18} /> {Resources.Upload}
                  </button>
                  <input
                    className="hidden-input"
                    type="file"
                    id="uploadFile"
                    style={{ display: "none" }}
                    onChange={e => {
                      let accessId = this.props.match.params.accessId;
                      let mode = this.props.match.params.mode;
                      let accountingGroupKey = this.props.match.params.accountingGroupKey;
                      let companyGlobalKey = this.props.match.params.companyGlobalKey;
                      if (this.props.admin) {
                        this.props.uploadAccountingGroupDocument(
                          accountingGroupKey,
                          companyGlobalKey,
                          mode,
                          e.target.files[0]
                        );
                        this.props.displayNotification("uploadingImageNotification");
                      } else if (this.props.edit || this.props.empty) {
                        this.props.uploadDocument(accountData.entityGlobalKey, e.target.files[0]).then(response => {
                          this.setState({
                            accountData: { ...this.state.accountData, logoURL: response }
                          });
                        });
                        this.props.displayNotification("uploadingImageNotification");
                      } else if (this.props.small) {
                        this.props
                          .uploadAccountingGroupDocument(
                            this.props.perspectiveId,
                            this.props.companyId,
                            this.props.mode,
                            e.target.files[0]
                          )
                          .then(response => {
                            this.setState({ accountData: { ...this.state.accountData, logoURL: response } });
                            this.props.formOnChange("logoURL", response);
                          });
                        this.props.displayNotification("uploadingImageNotification");
                      } else if (this.props.edit) {
                        this.props
                          .uploadAccountingGroupDocument(
                            this.props.match.params.perspectiveId,
                            this.props.match.params.accountId,
                            this.props.mode,
                            e.target.files[0]
                          )
                          .then(response => {
                            this.setState({ accountData: { ...this.state.accountData, logoURL: response } });
                            this.props.formOnChange("logoURL", response);
                          });
                        this.props.displayNotification("uploadingImageNotification");
                      } else {
                        this.props.postCustAccountPicture(accessId, mode, e.target.files[0]).then(response => {
                          this.setState({ accountData: { ...this.state.accountData, logoURL: response } });
                        });
                        this.props.displayNotification("uploadingImageNotification");
                      }
                    }}
                  />
                  {this.props.admin ? (
                    <div className="profile-update-color-picker-container">
                      <p>{Resources.PrimaryButtonColor}</p>
                      <p className="sub-text">This is used in communication templates</p>
                      <div
                        className="profile-update-form-color-picker"
                        style={{ background: settings.buttonColor === null ? "#1fb6c1" : settings.buttonColor }}
                        onClick={() => this.setState({ showColorPicker: !this.state.showColorPicker })}
                      ></div>
                      {this.state.showColorPicker ? (
                        <div className="color-picker-position">
                          <BlockPicker
                            color={settings.buttonColor || "#1fb6c1"}
                            onChangeComplete={this.handleChangeComplete}
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            <hr></hr>
            <div className="profile-update-card-form">
              <div className="first-time-card-column">
                <div className="first-time-card-cell">
                  <TextInput
                    width="23.2rem"
                    labelInline={false}
                    label={
                      this.props.admin || this.props.mode === "ar" || this.props.match.params.mode === "ar"
                        ? Resources.ARDepartmentStreet
                        : Resources.BillToStreet
                    }
                    textValue={
                      this.props.admin || this.props.match.params.mode === "ar" || this.props.mode === "ar"
                        ? this.props.small
                          ? this.props.editedAccount.mailToAddrLn1
                          : accountData.mailToAddrLn1
                        : this.props.small
                        ? this.props.editedAccount.billToAddrLn1
                        : accountData.billToAddrLn1
                    }
                    inputOnChange={e => {
                      let value = e.target.value;
                      this.props.admin || this.props.mode === "ar" || this.props.match.params.mode === "ar"
                        ? this.props.small
                          ? this.props.formOnChange("mailToAddrLn1", value)
                          : this.setState(prevState => {
                              return { accountData: { ...prevState.accountData, mailToAddrLn1: value } };
                            })
                        : this.props.small
                        ? this.props.formOnChange("billToAddrLn1", value)
                        : this.setState(prevState => {
                            return { accountData: { ...prevState.accountData, billToAddrLn1: value } };
                          });
                    }}
                  />
                </div>
              </div>
              <div className="first-time-card-column">
                <div className="first-time-card-cell">
                  <TextInput
                    width="23.2rem"
                    labelInline={false}
                    label={Resources.Country}
                    textValue={
                      this.props.admin || this.props.mode === "ar" || this.props.match.params.mode === "ar"
                        ? this.props.small
                          ? this.props.editedAccount.mailToCountry
                          : accountData.mailToCountry
                        : this.props.small
                        ? this.props.editedAccount.billToCountry
                        : accountData.billToCountry
                    }
                    inputOnChange={e => {
                      let value = e.target.value;
                      this.props.admin || this.props.mode === "ar" || this.props.match.params.mode === "ar"
                        ? this.props.small
                          ? this.props.formOnChange("mailToCountry", value)
                          : this.setState(prevState => {
                              return { accountData: { ...prevState.accountData, mailToCountry: value } };
                            })
                        : this.props.small
                        ? this.props.formOnChange("billToCountry", value)
                        : this.setState(prevState => {
                            return { accountData: { ...prevState.accountData, billToCountry: value } };
                          });
                    }}
                  />
                </div>
              </div>
              <div className="first-time-card-column">
                <div className="first-time-card-cell">
                  <TextInput
                    width="23.2rem"
                    labelInline={false}
                    label={Resources.City}
                    textValue={
                      this.props.admin || this.props.mode === "ar" || this.props.match.params.mode === "ar"
                        ? this.props.small
                          ? this.props.editedAccount.mailToCity
                          : accountData.mailToCity
                        : this.props.small
                        ? this.props.editedAccount.billToCity
                        : accountData.billToCity
                    }
                    inputOnChange={e => {
                      let value = e.target.value;
                      this.props.admin || this.props.mode === "ar" || this.props.match.params.mode === "ar"
                        ? this.props.small
                          ? this.props.formOnChange("mailToCity", value)
                          : this.setState(prevState => {
                              return { accountData: { ...prevState.accountData, mailToCity: value } };
                            })
                        : this.props.small
                        ? this.props.formOnChange("billToCity", value)
                        : this.setState(prevState => {
                            return { accountData: { ...prevState.accountData, billToCity: value } };
                          });
                    }}
                  />
                </div>
                <div className="first-time-card-cell">
                  <TextInput
                    width="9.53rem"
                    labelInline={false}
                    label={Resources.State}
                    textValue={
                      this.props.admin || this.props.mode === "ar" || this.props.match.params.mode === "ar"
                        ? this.props.small
                          ? this.props.editedAccount.mailToState
                          : accountData.mailToState
                        : this.props.small
                        ? this.props.editedAccount.billToState
                        : accountData.billToState
                    }
                    inputOnChange={e => {
                      let value = e.target.value;
                      this.props.admin || this.props.mode === "ar" || this.props.match.params.mode === "ar"
                        ? this.props.small
                          ? this.props.formOnChange("mailToState", value)
                          : this.setState(prevState => {
                              return { accountData: { ...prevState.accountData, mailToState: value } };
                            })
                        : this.props.small
                        ? this.props.formOnChange("billToState", value)
                        : this.setState(prevState => {
                            return { accountData: { ...prevState.accountData, billToState: value } };
                          });
                    }}
                  />
                </div>
                <div className="first-time-card-cell">
                  <TextInput
                    width="9.53rem"
                    labelInline={false}
                    label={Resources.Zip}
                    textValue={
                      this.props.admin || this.props.mode === "ar" || this.props.match.params.mode === "ar"
                        ? this.props.small
                          ? this.props.editedAccount.mailToPostalcode
                          : accountData.mailToPostalcode
                        : this.props.small
                        ? this.props.editedAccount.billToPostalcode
                        : accountData.billToPostalcode
                    }
                    inputOnChange={e => {
                      let value = e.target.value;
                      this.props.admin || this.props.match.params.mode === "ar" || this.props.mode === "ar"
                        ? this.props.small
                          ? this.props.formOnChange("mailToPostalcode", value)
                          : this.setState(prevState => {
                              return { accountData: { ...prevState.accountData, mailToPostalcode: value } };
                            })
                        : this.props.small
                        ? this.props.formOnChange("billToPostalcode", value)
                        : this.setState(prevState => {
                            return { accountData: { ...prevState.accountData, billToPostalcode: value } };
                          });
                    }}
                  />
                </div>
              </div>
              {this.props.admin || this.props.mode === "ar" || this.props.match.params.mode === "ar" ? null : (
                <div className="profile-update-card-form-checkbox-container">
                  <Checkbox
                    checked={this.state.sameAsBilling}
                    className="profile-update-card-form-checkbox"
                    onChange={
                      this.props.small
                        ? () => {
                            this.props.toggleSameAsBilling();
                            this.setState({ sameAsBilling: !this.state.sameAsBilling });
                          }
                        : () => this.setState({ sameAsBilling: !this.state.sameAsBilling })
                    }
                  />
                  <label className="profile-update-card-form-checkbox-label">{Resources.SameAsBilling}</label>
                </div>
              )}
              {this.props.admin || this.props.mode === "ar" || this.props.match.params.mode === "ar" ? null : this.state
                  .sameAsBilling === true ? null : (
                <div>
                  <div className="first-time-card-column">
                    <div className="first-time-card-cell">
                      <TextInput
                        width="23.2rem"
                        labelInline={false}
                        label={Resources.ShipToStreet}
                        textValue={
                          this.state.sameAsBilling
                            ? this.props.small
                              ? this.props.editedAccount.billToAddrLn1
                              : accountData.billToAddrLn1
                            : this.props.small
                            ? this.props.editedAccount.shipToAddrLn1
                            : accountData.shipToAddrLn1
                        }
                        inputOnChange={e => {
                          let value = e.target.value;
                          this.props.small
                            ? this.props.formOnChange("shipToAddrLn1", value)
                            : this.setState(prevState => {
                                return { accountData: { ...prevState.accountData, shipToAddrLn1: value } };
                              });
                        }}
                      />
                    </div>
                  </div>
                  <div className="first-time-card-column">
                    <div className="first-time-card-cell">
                      <TextInput
                        width="23.2rem"
                        labelInline={false}
                        label={Resources.Country}
                        textValue={
                          this.props.small ? this.props.editedAccount.shipToCountry : accountData.shipToCountry
                        }
                        inputOnChange={e => {
                          let value = e.target.value;
                          this.props.small
                            ? this.props.formOnChange("shipToCountry", value)
                            : this.setState(prevState => {
                                return { accountData: { ...prevState.accountData, shipToCountry: value } };
                              });
                        }}
                      />
                    </div>
                  </div>
                  <div className="first-time-card-column">
                    <div className="first-time-card-cell">
                      <TextInput
                        width="23.2rem"
                        labelInline={false}
                        label={Resources.City}
                        textValue={
                          this.state.sameAsBilling
                            ? this.props.small
                              ? this.props.editedAccount.billToCity
                              : accountData.billToCity
                            : this.props.small
                            ? this.props.editedAccount.shipToCity
                            : accountData.shipToCity
                        }
                        inputOnChange={e => {
                          let value = e.target.value;
                          this.props.small
                            ? this.props.formOnChange("shipToCity", value)
                            : this.setState(prevState => {
                                return { accountData: { ...prevState.accountData, shipToCity: value } };
                              });
                        }}
                      />
                    </div>
                    <div className="first-time-card-cell">
                      <TextInput
                        width="9.53rem"
                        labelInline={false}
                        label={Resources.State}
                        textValue={
                          this.state.sameAsBilling
                            ? this.props.small
                              ? this.props.editedAccount.billToState
                              : accountData.billToState
                            : this.props.small
                            ? this.props.editedAccount.shipToState
                            : accountData.shipToState
                        }
                        inputOnChange={e => {
                          let value = e.target.value;
                          this.props.small
                            ? this.props.formOnChange("shipToState", value)
                            : this.setState(prevState => {
                                return { accountData: { ...prevState.accountData, shipToState: value } };
                              });
                        }}
                      />
                    </div>
                    <div className="first-time-card-cell">
                      <TextInput
                        width="9.53rem"
                        labelInline={false}
                        label={Resources.Zip}
                        textValue={
                          this.props.sameAsBilling ? accountData.billToPostalcode : accountData.shipToPostalcode
                        }
                        inputOnChange={e => {
                          let value = e.target.value;
                          this.props.small
                            ? this.props.formOnChange("shipToPostalcode", value)
                            : this.setState(prevState => {
                                return { accountData: { ...prevState.accountData, shipToPostalcode: value } };
                              });
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <hr></hr>
            <div className="profile-update-card-notes-container">
              <TextArea
                width="42rem"
                rows={4}
                labelInline={false}
                label={Resources.NotesOptional}
                textValue={this.props.small ? this.props.editedAccount.notes : accountData.notes}
                inputOnChange={e => {
                  let value = e.target.value;
                  this.props.small || this.props.empty
                    ? this.props.formOnChange("notes", value)
                    : this.setState(prevState => {
                        return { accountData: { ...prevState.accountData, notes: value } };
                      });
                }}
              ></TextArea>
            </div>
            {this.props.edit ? (
              <React.Fragment>
                <hr></hr>
                <div className="profile-update-form-edit-button-container">
                  <button onClick={() => this.props.toggleEditMode()} className="button-secondary-square small">
                    {Resources.Cancel}
                  </button>
                  <button onClick={() => this.submitForm()} className="button-primary-square small">
                    {Resources.Save}
                  </button>
                </div>
              </React.Fragment>
            ) : null}
          </Card>
          {this.props.small || this.props.edit ? null : (
            <div className="profile-update-form-button-container">
              {this.props.networkStore.custAccount.isLockstepVerified || this.props.showCancelButton ? (
                <button
                  className="button-secondary-square large"
                  onClick={() => {
                    this.setState({ editMode: false });
                    if (this.props.toggleEditMode !== undefined) {
                      this.props.toggleEditMode();
                    }
                  }}
                >
                  {Resources.Cancel}
                </button>
              ) : null}
              <button className="button-primary-square large" onClick={this.submitForm}>
                {Resources.Next}
              </button>
            </div>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {this.props.small ? null : <h2 className="first-time-title-contact">{Resources.ProfileUpdateThanks}</h2>}
          <div className="profile-update-card-container">
            <Card className="account-profile__card">
              <div className="account-profile__card__last-updated">
                {Resources.UpdatedDate(formatDate(accountData.profileUpdateDate))}
              </div>
              <div className="profile-update-card-avatar-container">
                <Avatar
                  height={"3.6rem"}
                  imgURL={accountData.logoURL}
                  // type="user"
                  objectName={accountData.companyName}
                  className={"avatar"}
                ></Avatar>
                <h2>{accountData.companyName}</h2>
              </div>
              <div className="">
                <div className="account-profile-item">
                  <div className="account-profile-item__icon">
                    <IconMail height={18} />
                  </div>
                  <div className="account-profile-item__content">{accountingGroupDetails.emailAddr}</div>
                </div>
                {!isEmpty(accountData.phone) && (
                  <div className="account-profile-item">
                    <div className="account-profile-item__icon">
                      <IconPhone height={22} />
                    </div>
                    <div className="account-profile-item__content">{accountData.phone}</div>
                  </div>
                )}
                {!isEmpty(accountData.fax) && (
                  <div className="account-profile-item">
                    <div className="account-profile-item__icon">
                      <IconArchive height={16} />
                    </div>
                    <div className="account-profile-item__content">{accountData.fax}</div>
                  </div>
                )}
                {!isEmpty(
                  [
                    accountData.mailToAddrLn1,
                    accountData.mailToCity,
                    accountData.mailToState,
                    accountData.mailToPostalcode,
                    accountData.mailToCountry
                  ].filter(value => !isEmpty(value))
                ) && (
                  <div className="account-profile-item">
                    <div className="account-profile-item__icon">
                      <IconMapPin height={22} />
                    </div>
                    <div className="account-profile-item__content">
                      <div>{accountData.mailToAddrLn1}</div>
                      <div>
                        {accountData.mailToCity}, {accountData.mailToState} {accountData.mailToPostalcode}
                      </div>
                      <div>{accountData.mailToCountry}</div>
                    </div>
                  </div>
                )}
                {!isEmpty(
                  [
                    accountData.billToAddrLn1,
                    accountData.billToCity,
                    accountData.billToState,
                    accountData.billToPostalcode,
                    accountData.billToCountry
                  ].filter(value => !isEmpty(value))
                ) && (
                  <div className="account-profile-item">
                    <div className="account-profile-item__icon">
                      <IconMapPin height={22} />
                    </div>
                    <div className="account-profile-item__content">
                      <div className="account-profile-item__header">{Resources.BillTo}</div>
                      <div>{accountData.billToAddrLn1}</div>
                      <div>
                        {accountData.billToCity}, {accountData.billToState} {accountData.billToPostalcode}
                      </div>
                      <div>{accountData.billToCountry}</div>
                    </div>
                  </div>
                )}
                {!isEmpty(
                  [
                    accountData.shipToAddrLn1,
                    accountData.shipToCity,
                    accountData.shipToState,
                    accountData.shipToPostalcode,
                    accountData.shipToCountry
                  ].filter(value => !isEmpty(value))
                ) && (
                  <div className="account-profile-item">
                    <div className="account-profile-item__icon"></div>
                    <div className="account-profile-item__content">
                      <div className="account-profile-item__header">{Resources.ShipTo}</div>
                      <div>{accountData.shipToAddrLn1}</div>
                      <div>
                        {accountData.shipToCity}, {accountData.shipToState} {accountData.shipToPostalcode}
                      </div>
                      <div>{accountData.shipToCountry}</div>
                    </div>
                  </div>
                )}
                {!isEmpty([accountData.taxId, accountData.entityClassification].filter(value => !isEmpty(value))) && (
                  <div className="account-profile-item">
                    <div className="account-profile-item__icon">
                      <IconBriefcase height={19} />
                    </div>
                    <div className="account-profile-item__content">
                      <div>
                        {Resources.ein}: {accountData.taxId}
                      </div>
                      <div>
                        {Resources.EntityClassification}:{" "}
                        {
                          (classifications.filter(i => i.value === accountData.entityClassificationId)[0] || {})
                            .classification
                        }
                      </div>
                    </div>
                  </div>
                )}
                {!isEmpty(accountData.linkedInProfile) && (
                  <div className="account-profile-item">
                    <div className="account-profile-item__icon">
                      <IconLinkedIn height={19} />
                    </div>
                    <div className="account-profile-item__content">
                      <a rel="norefferer noopener" href={accountData.linkedInProfile}>
                        {accountData.linkedInProfile}
                      </a>
                    </div>
                  </div>
                )}
                {!isEmpty(accountData.notes) && (
                  <div className="account-profile-item">
                    <div className="account-profile-item__icon">
                      <IconClipboard height={20} />
                    </div>
                    <div className="account-profile-item__content">{accountData.notes}</div>
                  </div>
                )}
              </div>
            </Card>
          </div>
          <div className="profile-update-form-button-container">
            <button className="button-secondary-square large" onClick={() => this.setState({ editMode: true })}>
              {Resources.INeedToUpdateThisProfile}
            </button>
            <button className="button-primary-square large" onClick={this.submitForm}>
              {Resources.LooksGood}
            </button>
          </div>
        </React.Fragment>
      );
    }
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network,
    modalStore: store.modal
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ProfileUpdateForm));
