import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { dispatchToProps as moDP } from "../../../store/modal-actions";
import { dispatchToProps as ledgDP } from "../../../store/ledger-actions";
import { dispatchToProps as statDP } from "../../../store/statements-actions";
import { dispatchToProps as dcDP } from "../../../store/disputeCodes-actions";

import Resources from "../../../lib/resources";
import IconClose from "../../library/icons/iconClose";
import TableData from "../../library/tableData";
import HtmlEditor from "../../htmlEditor";
import IconAngleDown from "../../library/icons/iconAngleDown";

import Modal from "react-modal";
import { onBlurCheckFocusable, isEmpty, updateSelectedRows, formatCurrency, includes } from "../../../lib/utils";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...ledgDP(dispatch),
  ...statDP(dispatch),
  ...dcDP(dispatch)
});

class DisputeFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedReason: null,
      htmlBody: "",
      textBody: "",
      selectedRows: [],
      showFlyout: true,
      totalAmount: "",
      invoices: []
    };

    this.submitDispute = this.submitDispute.bind(this);
    this.hideFlyout = this.hideFlyout.bind(this);
  }

  componentDidMount() {
    let { selectedInvoices = [] } = this.props;
    let stateUpdate = {};

    if (selectedInvoices.length > 0) {
      this.setState({ selectedRows: selectedInvoices.map((invoice, i) => i) });
    }

    if (this.props.selectedInvoices.length > 0) {
      const selectedRows = [];
      let invoices = [];
      for (let i = 0; i < this.props.selectedInvoices.length; i++) {
        const inv = { ...this.props.selectedInvoices[i] };
        inv.paymentAmount = Number.parseFloat(inv.amount).toFixed(2);
        invoices.push(inv);
        selectedRows.push(i);
      }
      stateUpdate.invoices = invoices;
      stateUpdate.selectedRows = selectedRows;
    }
    this.setState(stateUpdate);
    this.props.fetchDisputeCodes(this.props.companyId);
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  toggleSelectRow(i) {
    let newSelectedRows = updateSelectedRows(i, this.state.selectedRows, this.props.selectedInvoices.length);

    this.setState({ selectedRows: newSelectedRows });
  }

  submitDispute() {
    let resourceName = "ApplicationResponse";
    let { companyId, selectedInvoices, withContextGroupId } = this.props;

    let finalSelectedIndexes = this.state.selectedRows.map(i => selectedInvoices[i]);

    let note = `<Note xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2"><![CDATA[${this.state
      .htmlBody || this.state.textBody}]]></Note>`;

    let docunentReferences = finalSelectedIndexes.map(invoice => {
      return `<DocumentReference>
                <ID xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2">${invoice.ledgerHash}</ID>
                <DocumentStatusCode xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2">Disputed</DocumentStatusCode>
              </DocumentReference>`;
    });

    docunentReferences = docunentReferences.join("");

    let response = `<Response>
                      <ResponseCode xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2">${this
                        .state.selectedReason ||
                        this.props.disputeCodeStore.disputeCodes[0].disputeCodeDescription}</ResponseCode>
                    </Response>`;

    let finalLedgerContent = [
      `<ApplicationResponse xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="urn:oasis:names:specification:ubl:schema:xsd:ApplicationResponse-2">`,
      note,
      `<DocumentResponse xmlns="urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2">`,
      docunentReferences,
      response,
      `</DocumentResponse>`,
      `</ApplicationResponse>`
    ].join("");

    this.props.displayNotification("creatingDisputeNotification");
    this.props
      .createEntryToContextGroup(companyId, withContextGroupId, resourceName, finalLedgerContent, {
        "Content-Type": "application/xml"
      })
      .then(response => {
        this.props.clearSelectedRows();
        this.hideFlyout();
        this.props.refreshOpenInvoices();
      })
      .catch(error => null);
  }

  render() {
    const { selectedInvoices = [], columns = [] } = this.props;

    let disputeReasons = this.props.disputeCodeStore.disputeCodes || [];

    const disableSend =
      isEmpty(this.state.selectedRows) || this.props.ledgerStore.creatingEntry || this.state.selectedReason === null;
    let totalAmount = this.state.invoices.reduce((accum, curr, index) => {
      if (!includes(this.state.selectedRows, index)) {
        return accum;
      }
      return accum + (Number.parseFloat(curr.paymentAmount) || 0);
    }, 0);
    totalAmount = Math.round(totalAmount * 1e12) / 1e12;
    return (
      <Modal
        data-test-id="dispute-flyout__reason"
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={this.props.hideFlyout}
        contentLabel="Example Modal"
        className="flyout dispute-flyout"
        overlayClassName="flyout-overlay"
        closeTimeoutMS={400}
      >
        <div className="flyout-heading">
          <div>{Resources.DisputeInvoices}</div>
          <div
            data-test-id="dispute-flyout__heading-close-icon"
            onClick={this.hideFlyout}
            className="flyout-heading-close"
          >
            <IconClose />
          </div>
        </div>
        <div className="flyout-content">
          <div className={`portal-input-label ${this.state.focusedField === "reason" ? " focused" : ""}`}>
            {Resources.Reason}
          </div>
          <div
            className="dropdown"
            onFocus={() => setTimeout(() => this.setState({ focusedField: "reason" }), 90)}
            onBlur={e => setTimeout(() => onBlurCheckFocusable(() => this.setState({ focusedField: null })), 90)}
          >
            <button
              data-test-id="dispute-flyout-dropdown__reason__button"
              className="dispute-flyout-dropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>
                {this.state.selectedReason || (
                  <span className="placeholder">
                    {this.props.disputeCodeStore.gettingDisputeCode ? Resources.LoadingDisputeCodes : Resources.Select}
                  </span>
                )}
              </span>
              <IconAngleDown height={8}></IconAngleDown>
            </button>
            <span className="dropdown-menu dispute-flyout-dropdown-menu">
              {disputeReasons.map((reason, i) => (
                <span
                  key={i}
                  className="dropdown-item"
                  onClick={() => this.setState({ selectedReason: reason.disputeCodeDescription })}
                >
                  {reason.disputeCodeDescription}
                </span>
              ))}
            </span>
          </div>
          <div className={`portal-input-label mt-5 ${this.state.focusedField === "invoices" ? " focused" : ""}`}>
            {Resources.InvoicesSelectedFor(this.state.selectedRows.length)}
          </div>
          <div className="flyout-table-container">
            <TableData
              name="dispute-flyout__table"
              noGrow
              noSort
              pagination
              rowHeight="4rem"
              data={selectedInvoices}
              columns={columns}
              maxHeight={`${4 * selectedInvoices.length + 2}rem`}
              rowClassName="statements-view-row no-hover"
              selectedRows={this.state.selectedRows}
              onRowSelectToggle={i => this.toggleSelectRow(i)}
              useRedux
            />
          </div>

          <div className="fw-500" style={{ fontSize: "1.33rem", marginTop: "2rem" }}>
            {Resources.TotalDisputedAmount(formatCurrency(totalAmount))}
          </div>
          <div className={`portal-input-label mt-5 ${this.state.focusedField === "message" ? " focused" : ""}`}>
            {Resources.MessageOptional}
          </div>
          <HtmlEditor
            onFocus={() => this.setState({ focusedField: "message" })}
            onBlur={e => onBlurCheckFocusable(() => this.setState({ focusedField: null }))}
            hideToolbar={this.state.focusedField !== "message"}
            htmlContent={this.state.htmlBody}
            updateHtmlContent={htmlBody => this.setState({ htmlBody })}
            updateTextContent={textBody => this.setState({ textBody })}
          />
          <button
            data-test-id="dispute-flyout__submit"
            className="portal-button-green"
            onClick={this.submitDispute}
            disabled={disableSend}
          >
            {Resources.Submit}
          </button>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    conversationsStore: store.conversations,
    ledgerStore: store.ledger,
    accountsStore: store.accounts,
    disputeCodeStore: store.disputeCodes
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(DisputeFlyout));
