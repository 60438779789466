import data from "../lib/data";
import { handlePromiseError } from "./error-actions";

import { actions as GeneralActions } from "./general-actions";

export const actions = {
  ...GeneralActions,
  ...{
    INVITING_COMPANY_USER: "GETTING_CONVERSATION_COMPANIES",
    INVITED_COMPANY_USER: "INVITED_COMPANY_USER",
    INVITE_COMPANY_USER_FAILED: "INVITE_COMPANY_USER_FAILED",
    RESET_INVITE_COMPANY_USER: "RESET_INVITE_COMPANY_USER",

    FETCHING_COMPANY_USER_INVITES: "FETCHING_COMPANY_USER_INVITES",
    FETCHED_COMPANY_USER_INVITES: "FETCHED_COMPANY_USER_INVITES",
    FETCH_COMPANY_USER_INVITES_FAILED: "FETCH_COMPANY_USER_INVITES_FAILED",

    RESEND_COMPANY_USER_INVITE: "RESEND_COMPANY_USER_INVITE",
    RESENT_COMPANY_USER_INVITE: "RESENT_COMPANY_USER_INVITE",
    RESEND_COMPANY_USER_INVITE_FAILED: "RESEND_COMPANY_USER_INVITE_FAILED",

    ACCEPTING_COMPANY_INVITE: "ACCEPTING_COMPANY_INVITE",
    ACCEPTED_COMPANY_INVITE: "ACCEPTED_COMPANY_INVITE",
    ACCEPT_COMPANY_INVITE_FAILED: "ACCEPT_COMPANY_INVITE_FAILED",

    REJECTING_COMPANY_INVITE: "REJECTING_COMPANY_INVITE",
    REJECTED_COMPANY_INVITE: "REJECTED_COMPANY_INVITE",
    REJECT_COMPANY_INVITE_FAILED: "REJECT_COMPANY_INVITE_FAILED"
  }
};

export const inviteCompanyUser = (companyId, fullName, email, defaultPerspective, customMessage) => (
  dispatch,
  getState
) => {
  dispatch({ type: actions.INVITING_COMPANY_USER });
  data
    .put(`v1/api/invite/user/to/${companyId}`, {
      fullName,
      email,
      defaultPerspective,
      customMessage
    })
    .then(response => {
      dispatch({ type: actions.INVITED_COMPANY_USER });
      dispatch(fetchCompanyUserInvites(companyId));
    })
    .catch(rejection => {
      dispatch({ type: actions.INVITE_COMPANY_USER_FAILED });
      handlePromiseError(rejection, "TODO: The request inviting a user to join the company failed.  Please try again.");
    });
};

const fetchCompanyUserInvites = companyId => (dispatch, getState) => {
  dispatch({ type: actions.FETCHING_COMPANY_USER_INVITES, companyId });
  data
    .get(`v1/api/invite/user/to/${companyId}`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_COMPANY_USER_INVITES,
        companyId,
        invites: response.data
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_COMPANY_USER_INVITES_FAILED, companyId });
      handlePromiseError(
        rejection,
        "TODO: The request getting the list of outstanding company invites failed.  Please try again.",
        "pending company invites"
      );
    });
};

const resendCompanyUserInvite = (companyId, inviteId) => dispatch => {
  dispatch({ type: actions.RESEND_COMPANY_USER_INVITE, inviteId });
  data
    .post(`v1/api/invite/user/${inviteId}/resend`)
    .then(response => {
      dispatch({
        type: actions.RESENT_COMPANY_USER_INVITE,
        invite: response.data,
        inviteId
      });
      dispatch(fetchCompanyUserInvites(companyId));
    })
    .catch(response => {
      dispatch({ type: actions.RESEND_COMPANY_USER_INVITE_FAILED, inviteId });
      handlePromiseError(response, "TODO: Resend of the invite failed.  Please try again.", "invitation");
    });
};

const revokeCompanyUserInvite = (companyId, inviteId) => dispatch => {
  dispatch(rejectCompanyInvite(companyId, inviteId))
    .then(response => {
      dispatch(fetchCompanyUserInvites(companyId));
    })
    .catch(rejection => {
      dispatch(fetchCompanyUserInvites(companyId));
      handlePromiseError(rejection, "TODO: Revoking the invite failed.  Please try again.", "invitation");
    });
};

const acceptCompanyInvite = (companyId, inviteId) => (dispatch, getState) => {
  //dispatch({ type: actions.ACCEPTING_COMPANY_INVITE })
  return data.post(`v1/api/invite/user/to/${companyId}/accept`, `"${inviteId}"`, {
    headers: { "Content-Type": "application/json" }
  });
  // .then(response => {
  //   dispatch({ type: actions.ACCEPTED_COMPANY_INVITE })
  // })
  // .catch(rejection => {
  //   dispatch({ type: actions.ACCEPT_COMPANY_INVITE_FAILED })
  //   handlePromiseError(rejection, "TODO: The request to accept the company invite failed.  Please try again.")
  // })
};

const rejectCompanyInvite = (companyId, inviteId) => (dispatch, getState) => {
  // dispatch({ type: actions.REJECTING_COMPANY_INVITE })
  return data.post(`v1/api/invite/user/to/${companyId}/reject`, `"${inviteId}"`, {
    headers: { "Content-Type": "application/json" }
  });
  // .then(response => {
  //   dispatch({ type: actions.REJECTED_COMPANY_INVITE })
  // })
  // .catch(rejection => {
  //   dispatch({ type: actions.REJECT_COMPANY_INVITE_FAILED })
  //   handlePromiseError(rejection, "TODO: The request to reject the company invite failed.  Please try again.")
  // })
};

export const resetInviteCompanyUser = () => (dispatch, getState) => {
  return dispatch({ type: actions.RESET_INVITE_COMPANY_USER });
};

export const dispatchToProps = dispatch => ({
  inviteCompanyUser: (companyId, fullName, email, defaultPerspective, customMessage) => {
    dispatch(inviteCompanyUser(companyId, fullName, email, defaultPerspective, customMessage));
  },
  resetInviteCompanyUser: () => {
    dispatch(resetInviteCompanyUser());
  },

  fetchCompanyUserInvites: companyId => {
    dispatch(fetchCompanyUserInvites(companyId));
  },
  resendCompanyUserInvite: (companyId, inviteId) => {
    dispatch(resendCompanyUserInvite(companyId, inviteId));
  },
  revokeCompanyUserInvite: (companyId, inviteId) => {
    dispatch(revokeCompanyUserInvite(companyId, inviteId));
  },
  acceptCompanyInvite: (companyId, inviteId) => {
    return dispatch(acceptCompanyInvite(companyId, inviteId));
  },
  rejectCompanyInvite: (companyId, inviteId) => {
    return dispatch(rejectCompanyInvite(companyId, inviteId));
  }
});
