import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { isNumber } from "lodash";
import useSWR, { mutate } from "swr";

import Resources from "../lib/resources";
import {
  isEmpty,
  getConversationSubject,
  find,
  updateSelectedRows,
  formatDate,
  htmlToText,
  getRRule,
  isGuid,
  buildRequestUrl
} from "../lib/utils";
import { getPerspective } from "../lib/perspectives";

import { dispatchToProps as uaDP } from "../store/user-actions";
import { dispatchToProps as convDP } from "../store/conversations-actions";
import { dispatchToProps as connDP } from "../store/connector-actions";
import { dispatchToProps as congDP } from "../store/contextGroups-actions";
import { dispatchToProps as netDP } from "../store/network-actions";

import Icons from "../lib/icons";
import Card from "./library/card";
import Dropdown from "./library/dropdown";
import AutoCompleteInput from "./library/autoCompleteInput";
import FilterInput from "./library/filterInput";
import ConversationActionButtons from "./lockstep/conversationActionButtons";

import UserPicker from "./userPicker";
import SummaryCountBadge from "./lockstep/summaryCountBadge";

import IconAttachment from "./library/icons/iconAttachment";
import IconMessage from "./library/icons/iconMessage";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import IconBell from "./library/icons/iconBell";
import IconSlash from "./library/icons/iconSlash";
import IconArrowUp from "./library/icons/iconArrowUp";
import IconUnread from "./library/icons/iconUnread";
import IconRead from "./library/icons/iconRead";
import IconXCircle from "./library/icons/iconXCircle";
import IconUsers from "./library/icons/iconUsers";
import IconMove from "./library/icons/iconMove";
import SelectInput from "./library/selectInput";
import { swrFetcher } from "../lib/data";
import useDebounce from "./hooks/useDebounce";
import TableData from "./library/tableData";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...convDP(dispatch),
  ...connDP(dispatch),
  ...congDP(dispatch),
  ...netDP(dispatch)
});

const ConversationTable = (props) => {
  const perspective = getPerspective(props.match.params.perspectiveId);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const resources = useSelector(store => store.ledger.resources);
  const companyRoles = useSelector(store => store.accounts.companyRoles);
  const navigatingBack = useSelector(store => store.user.navigatingBack);
  const conversationsData = useSelector(store => store.conversations.conversationsViewData);
  const conversationsSearchTerm = useSelector(store => store.conversations.conversationsViewSearchTerm);
  const conversationsCurrentPage = useSelector(store => store.conversations.conversationsCurrentPage);
  const conversationsSearchResultsCurrentPage = useSelector(store => store.conversations.conversationsSearchResultsCurrentPage);
  const conversationsLabel = useSelector(store => store.conversations.conversationsLabel);
  const displayingSearchResults = useSelector(store => store.conversations.displayingSearchResults);
  const updatingSearchResults = useSelector(store => store.conversations.updatingSearchResults);
  const debouncedSearchTerm = useDebounce(conversationsSearchTerm, 1000);
  const { data, error } = useSWR(!displayingSearchResults ? getDataUrl(conversationsCurrentPage) : null, swrFetcher);
  const { data: searchData, error: searchError } = useSWR(debouncedSearchTerm ? getDataUrl(conversationsSearchResultsCurrentPage, true) : null, swrFetcher);

  const {
    fetchResources,
    fetchCompanyUserRoles,
    setConversationsViewData,
    setConversationsViewSearchTerm,
    setConversationsCurrentPage,
    setConversationsSearchResultsCurrentPage,
    setConversationsLabel,
    setDisplayingSearchResults,
    setUpdatingSearchResults,
    setNavigatingBack,
    conversationStore,
    user
  } = props;

  const view = (props.match.params || {}).view;
  const perspectiveId = (props.match.params || {}).perspectiveId
  const selectedContextGroupId = (props.match.params || {}).selectedContextGroupId;

  // The loader component needs to be shown at the proper time, but it's dependent on several factors. When the user is viewing all their search
  // results, we want to show it when they page through the result set. But we don't want to show it if they are viewing all their search results
  // and simply modify the search term, which is what the updatingSearchResults flag guards against.
  const loadingConversations = displayingSearchResults ?
    ((!searchData || !searchData.value) && updatingSearchResults) && !searchError :
    (!data || !data.value) && !error;

  useEffect(() => {
    clearSelectedRows();

    // When navigating from a specific conversation back to the main list we want to show the user what they were previosuly viewing, which means
    // we don't want to reset things
    if (!navigatingBack) {
      setDisplayingSearchResults(false);
      setConversationsViewSearchTerm("");
      setConversationsCurrentPage(1);
      setConversationsSearchResultsCurrentPage(1);
      setConversationsLabel("mine");
    }
  }, [
    view,
    perspectiveId,
    selectedContextGroupId,
    navigatingBack,
    setConversationsViewSearchTerm,
    setDisplayingSearchResults,
    setConversationsCurrentPage,
    setConversationsSearchResultsCurrentPage,
    setConversationsLabel
  ]);

  useEffect(() => {
    if (displayingSearchResults) {
      if (searchData && !searchError) {
        // This flag prevents new search results from showing up automatically if the user is viewing a set of results and modifies the search term
        if (updatingSearchResults) {
          setConversationsViewData(searchData);
          setUpdatingSearchResults(false);
        }
      }

      setConversationsViewSearchTerm(debouncedSearchTerm);
    } else {
      if (data && !error) {
        setConversationsViewData(data);
      }
    }
  }, [
    displayingSearchResults,
    updatingSearchResults,
    debouncedSearchTerm,
    data,
    error,
    searchData,
    searchError,
    setConversationsViewData,
    setConversationsViewSearchTerm,
    setUpdatingSearchResults
  ]);

  useEffect(() => {
    // We only fetch resources and company roles if they haven't been fetched yet to avoid unneeded requests
    if (isEmpty(resources)) {
      fetchResources();
    }

    if (isEmpty(companyRoles)) {
      fetchCompanyUserRoles(props.match.params.companyId);
    }
  });

  function getContextGroupId() {
    const { selectedContextGroupId, perspectiveId, subView } = props.match.params;

    let contextGroupKey = selectedContextGroupId;

    if (subView === "accounts") {
      const accountProfile = props.getAccountProfile(perspectiveId, selectedContextGroupId) || {};
      contextGroupKey = accountProfile.custKey || accountProfile.vendorKey;
    }

    if (subView === "contacts") {
      const contactProfile = props.getContactProfile(perspectiveId, selectedContextGroupId);
      contextGroupKey = contactProfile.contactKey;
    }

    switch (contextGroupKey) {
      case "snoozed":
        contextGroupKey = "reminder";
        break;
      case "sent":
        contextGroupKey = "waiting-for-response";
        break;
      default:
        break;
    }

    return contextGroupKey;
  }

  function getDataUrl(currentPage, includeFilter) {
    const contextGroupId = getContextGroupId();
    const viewingContextGroup = isNumber(contextGroupId);
    const {
      pageRowCount,
      match: { params }
    } = props;

    let urlQueryParams = [
      {
        name: "label",
        value: viewingContextGroup ? conversationsLabel : contextGroupId
      },
      {
        name: "orderby",
        value: `${props.conversationStore.sortBy}%20${props.conversationStore.sortDirection}`
      },
      {
        name: "top",
        value: pageRowCount
      },
      {
        name: "skip",
        value: (currentPage - 1) * pageRowCount
      }
    ];

    if (includeFilter) {
      urlQueryParams.push({ name: "filter", value: debouncedSearchTerm });
    }

    return buildRequestUrl(
      viewingContextGroup
        ? `v2/api/conversation/for/${params.companyId}/${params.perspectiveId}/with/${contextGroupId}`
        : `v2/api/conversation/for/${params.companyId}/${params.perspectiveId}`,
      urlQueryParams
    );
  }

  const refreshConversations = () => {
    mutate(getDataUrl(), { forceLoadingState: true }, true);
  }

  const actionComplete = () => {
    clearSelectedRows();
    refreshConversations();
    props.refreshContextGroups(props.match.params.companyId, props.match.params.perspectiveId);
  }

  const toggleSelectRow = (key, row) => {
    let newSelectedKeys = updateSelectedRows(key, selectedKeys);
    let newSelectedRows = updateSelectedRows(row, selectedRows);

    setSelectedKeys(newSelectedKeys);
    setSelectedRows(newSelectedRows);
  }

  const clearSelectedRows = () => {
    setSelectedRows([]);
    setSelectedKeys([]);
  }

  const EmptyState = () => {
    return (
      <div className="messages-empty-state">
        <svg viewBox="0 0 308 164">
          <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M-566-274H874V750H-566z" />
            <path fill="#F8FBFC" d="M-566-122H874v872H-566z" />
            <rect
              width="1027"
              height="379"
              x=".5"
              y=".5"
              fill="#FFF"
              stroke="#DBE0E3"
              rx="2"
              transform="translate(-360 -38)"
            />
            <path
              fill="#F4F3FF"
              d="M294.545455 20.4090909c7.116942 0 12.886363 5.7694215 12.886363 12.8863636 0 7.1169422-5.769421 12.8863637-12.886363 12.8863637h-73.636364c7.116942 0 12.886364 5.7694215 12.886364 12.8863636 0 7.1169421-5.769422 12.8863637-12.886364 12.8863637h40.5c7.116942 0 12.886364 5.7694215 12.886364 12.8863636 0 7.1169421-5.769422 12.8863636-12.886364 12.8863636h-18.729249c-8.973536 0-16.248024 5.7694213-16.248024 12.8863633 0 4.744628 3.681818 9.040083 11.045455 12.886364 7.116942 0 12.886363 5.769422 12.886363 12.886364 0 7.116942-5.769421 12.886363-12.886363 12.886363H84.6818182c-7.1169421 0-12.8863637-5.769421-12.8863637-12.886363 0-7.116942 5.7694216-12.886364 12.8863637-12.886364H12.8863636C5.76942152 123.5 0 117.730578 0 110.613636c0-7.116942 5.76942152-12.8863633 12.8863636-12.8863633h73.6363637c7.1169421 0 12.8863636-5.7694215 12.8863636-12.8863636 0-7.1169421-5.7694215-12.8863636-12.8863636-12.8863636H40.5c-7.1169421 0-12.8863636-5.7694216-12.8863636-12.8863637S33.3830579 46.1818182 40.5 46.1818182h73.636364c-7.116942 0-12.886364-5.7694215-12.886364-12.8863637 0-7.1169421 5.769422-12.8863636 12.886364-12.8863636h180.409091zm0 51.5454546c7.116942 0 12.886363 5.7694215 12.886363 12.8863636 0 7.1169421-5.769421 12.8863636-12.886363 12.8863636-7.116943 0-12.886364-5.7694215-12.886364-12.8863636 0-7.1169421 5.769421-12.8863636 12.886364-12.8863636z"
            />
            <path
              fill="#FFF"
              d="M130.922787 109.337201c-.143846 1.018442-.218242 2.059205-.218242 3.117344 0 12.200473 9.890437 22.09091 22.09091 22.09091 12.200472 0 22.090909-9.890437 22.090909-22.09091 0-1.058139-.074396-2.098902-.218242-3.117344h53.604605v49.140072c0 3.050118-2.472609 5.522727-5.522727 5.522727H82.8409091c-3.0501181 0-5.5227273-2.472609-5.5227273-5.522727v-49.140072h53.6046052z"
            />
            <path
              fill="#FFF"
              d="M176.727273 108.772727c0 13.217178-10.71464 23.931818-23.931818 23.931818-13.217179 0-23.931819-10.71464-23.931819-23.931818 0-.428198.011246-.853769.033458-1.276435H77.3181818l17.5999005-52.0384031c.7587435-2.2434104 2.8633707-3.7533434 5.2316147-3.7533434h105.291515c2.368244 0 4.472871 1.509933 5.231615 3.7533434l17.5999 52.0384031h-51.578912c.022212.422666.033458.848237.033458 1.276435z"
            />
            <path
              fill="#DEDAFF"
              d="M173.225055 110.529959c0 10.213274-9.146643 20.333677-20.4296 20.333677-11.282957 0-20.429601-10.120403-20.429601-20.333677 0-.330881.0096-2.50064.028561-2.827246H93.8863636l15.0243054-36.5296749c.647708-1.7335444 2.444341-2.9003108 4.466013-2.9003108h78.837545c2.021672 0 3.818305 1.1667664 4.466013 2.9003108l15.024305 36.5296749h-38.508051c.018961.326606.028561 2.496365.028561 2.827246z"
            />
            <path
              stroke="#BEB6FF"
              strokeWidth="3.5"
              d="M207.251104 53.4545455H98.3398048c-.2680112 0-.5211058.0854386-.7286641.2343481-.2075583.1489096-.3695803.3612901-.4554463.615174L79.0681818 107.784215v51.374876c0 .345178.139911.657678.3661165.883883.2262055.226206.5387055.366117.8838835.366117H225.272727c.345178 0 .657678-.139911.883884-.366117.226205-.226205.366116-.538705.366116-.883883v-51.374876l-18.087512-53.4801474c-.085866-.2538839-.247888-.4662644-.455447-.615174-.207558-.1489095-.460652-.2343481-.728664-.2343481z"
            />
            <path
              stroke="#BEB6FF"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3.5"
              d="M89.419822 106.931818h4.4665416-4.4665416zm13.671087 0h23.462223c3.454061 0 3.454061 2.427378 3.454061 3.681818 0 12.200473 10.117987 22.090909 22.599156 22.090909 12.48117 0 22.599157-9.890436 22.599157-22.090909 0-1.25444 0-3.681818 3.454061-3.681818h45.931342M151.137917 2v30.8460138M103.090909 9.92094283l20.433372 22.92507097m75.477273-22.92507097L178.568182 32.8460138"
            />
          </g>
        </svg>
        <h2>{Resources.ThisInboxIsEmpty}</h2>
      </div>
    );
  }

  const getConversationColumns = (contextGroup, getHoveredColumns) => {
    const { params = {} } = props.match;
    const { perspectiveId } = params;

    const isWaitingForResponseView = params.selectedContextGroupId === "waiting-for-response";
    const isSpamView = params.selectedContextGroupId === "spam";
    let columns;
    let selectCol = {
      type: "rowSelect",
      width: "3%"
    };

    let subjectMessageCol = {
      header: Resources.Activity.toLocaleUpperCase(),
      content: row => {
        let content = JSON.parse(row.latestEntry.content.split("\n").join(""));
        let textBody = content.textBody;

        if (textBody === "Text body is not available") {
          textBody = null;
        }

        if (isEmpty(textBody)) {
          textBody = htmlToText(content.htmlBody);
        } else {
          textBody = htmlToText(textBody);
        }

        return (
          <div>
            <div className={`customer-portal-messages-table-subject ${row.hasUnreads ? "highlight" : ""}`}>
              <div className="customer-portal-messages-table-subject-text">{getConversationSubject(row.subject)}</div>
              {row.hasAttachments && (
                <div className="customer-portal-messages-table-attachment-icon">
                  <IconAttachment height="16" />
                </div>
              )}
              {!isEmpty(textBody) && (
                <div className={`overflow-ellipsis customer-portal-messages-table-subject-body`}>
                  {!isEmpty(getConversationSubject(row.subject)) && <span className="mr-2 ml-2">-</span>}
                  <span>{textBody}</span>
                </div>
              )}
            </div>
          </div>
        );
      },
      width: "72%"
    };

    let channelCol = {
      header: isWaitingForResponseView ? Resources.To.toLocaleUpperCase() : Resources.From.toLocaleUpperCase(),
      content: row => {
        const participants = (isWaitingForResponseView ? row.toEmailAddresses : row.fromEmailAddresses) || [];
        const participantNames = participants
          .filter(participant => participant.name)
          .map(participant => participant.name);
        const participantAddresses = participants
          .filter(participant => participant.address)
          .map(participant => participant.address);
        const contextGroupNames = row.contextGroupIds
          .map(id => props.getContextGroupName(id, false, ""))
          .filter(name => name);

        let content;

        if (!isEmpty(participantNames)) {
          content = participantNames.join();
        } else if (!isEmpty(contextGroupNames)) {
          content = contextGroupNames.join();
        } else {
          content = participantAddresses.join();
        }

        return (
          <div className={`conversation-table-row-partner overflow-ellipsis ${row.hasUnreads ? "highlight" : ""}`}>
            {content}
          </div>
        );
      },
      width: "12%"
    };

    let assignedToCol = {
      header: Resources.AssignedTo.toLocaleUpperCase(),
      content: row => (
        <div className="overflow-ellipsis">{props.getUserDisplayName(row.assignedUserId, Resources.You)}</div>
      ),
      width: "10rem"
    };

    let approverCol = {
      content: row => <div className="overflow-ellipsis">{row.approver}</div>,
      width: "11%"
    };

    let dateCol = {
      header: isWaitingForResponseView ? Resources.Sent.toLocaleUpperCase() : Resources.Received.toLocaleUpperCase(),
      content: row => (
        <div>
          <div className="conversation-table-row-original-date">
            <div className="original-date-line">{formatDate(row.modifiedDate)}</div>
          </div>
        </div>
      ),
      width: "18%"
    };

    let hoverActionCol = {
      content: row => (
        <div className="flex-end flex-align-center">
          {row.hasUnreads ? (
            <button
              data-test-id="conversations__read"
              className="button-action-icon"
              onClick={e => {
                e.stopPropagation();

                props.updateConversationReadStatuses([row.conversationId], true);
                props.displayNotification("markingConversationRead");
              }}
            >
              <IconRead height="20" className="icon-button" />
            </button>
          ) : (
            <button
              data-test-id="conversations__unread"
              className="button-action-icon"
              onClick={e => {
                e.stopPropagation();

                props.updateConversationReadStatuses([row.conversationId], false);
                props.displayNotification("markingConversationUnread");
              }}
            >
              <IconUnread height="20" className="icon-button" />
            </button>
          )}
          {conversationsLabel === "closed" ? (
            <button
              data-test-id="conversations__activate"
              className="button-action-icon"
              disabled={conversationStore.assigningConversations || conversationStore.settingConversationStatuses}
              onClick={e => {
                e.stopPropagation();
                let status = find(conversationStore.statuses, status => status.conversationStatusName === "Active");
                props.setConversationStatusesTo([row], status.conversationStatusId).then(actionComplete);
                props.displayNotification("activatingConversations");
              }}
            >
              <IconArrowUp height="22" className="button-primary-icon" />
            </button>
          ) : (
            <button
              data-test-id="conversations__close"
              className="button-action-icon"
              disabled={conversationStore.assigningConversations || conversationStore.settingConversationStatuses}
              onClick={e => {
                e.stopPropagation();
                let status = find(conversationStore.statuses, status => status.conversationStatusName === "Closed");
                props.setConversationStatusesTo([row], status.conversationStatusId).then(actionComplete);
                props.displayNotification("closingConversations");
              }}
            >
              <IconXCircle height="20" className="button-primary-icon" />
            </button>
          )}
          <div onClick={e => e.stopPropagation()}>
            <UserPicker
              perspective={perspectiveId}
              buttonContent={
                <React.Fragment>
                  <IconUsers height="20" className="button-primary-icon" />
                </React.Fragment>
              }
              buttonClassName="button-action-icon"
              disabled={conversationStore.assigningConversations}
              userSelected={user => {
                props.assignConversationsTo([row.conversationId], perspectiveId, user.userId);
                props.displayNotification("assigningConversation");
                refreshConversations();
              }}
            />
          </div>
          <div onClick={e => e.stopPropagation()}>
            <Dropdown
              buttonContent={
                <React.Fragment>
                  <IconBell height="20" className="icon-button" />
                </React.Fragment>
              }
              buttonClassName="button-action-icon"
              disabled={
                ((props.manageStore || {}).workflows[perspectiveId] || []).length === 0 ||
                props.ledgerStore.creatingEntry
              }
            >
              {[
                {
                  displayName: Resources.UntilTomorrow,
                  value: moment(
                    moment()
                      .add(1, "day")
                      .startOf("day")
                      .set("hour", 8)
                      .format()
                  )
                },
                {
                  displayName: Resources.UntilFriday,
                  value: moment(
                    moment()
                      .startOf("day")
                      .set("hour", 8)
                      .add("day", 5 - moment().day() < 0 ? 5 - moment().day() + 7 : 5 - moment().day())
                  )
                },
                {
                  displayName: Resources.UntilNextWeek,
                  value: moment(
                    moment()
                      .add(1, "week")
                      .startOf("week")
                      .set("hour", 8)
                  )
                },
                {
                  displayName: Resources.UntilNextMonth,
                  value: moment(
                    moment()
                      .add(1, "month")
                      .startOf("month")
                      .set("hour", 8)
                  )
                }
              ].map((date, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    props.displayNotification("snoozingActivity");
                    props
                      .putReminder(
                        props.match.params.companyId,
                        (((props.manageStore || {}).workflows[perspectiveId] || [])[0] || {}).ledgerHash,
                        row.conversationId,
                        getRRule(date.value),
                        date.value
                      )
                      .then(() => {
                        props.getReminders(props.match.params.companyId, perspectiveId);
                        actionComplete();
                      });
                  }}
                >
                  {date.displayName}
                </Dropdown.Item>
              ))}
              <Dropdown.Item
                onClick={e => {
                  props.displayModal("setReminder", {
                    companyId: props.match.params.companyId,
                    conversationId: row.conversationId,
                    perspectiveId,
                    selector: props.match.params.withCompanyId,
                    actionComplete: actionComplete
                  });
                }}
              >
                {Resources.CustomDate}
              </Dropdown.Item>
            </Dropdown>
          </div>
          <button
            data-test-id="conversations__spam"
            className="button-action-icon"
            onClick={e => {
              e.stopPropagation();
              const statusName = isSpamView ? "Active" : "Spam";
              const notification = isSpamView ? "activatingConversations" : "markingConversationAsSpam";
              const spamStatus =
                find(conversationStore.statuses, status => status.conversationStatusName === statusName) || {};
              props.setConversationStatusesTo([row], spamStatus.conversationStatusId).then(() => {
                actionComplete();
              });
              props.displayNotification(notification);
            }}
          >
            <IconSlash height="20" className="icon-button" />
          </button>
          <div onClick={e => e.stopPropagation()}>
            <Dropdown
              buttonContent={
                <React.Fragment>
                  <IconMove height="20" className="button-primary-icon" />
                </React.Fragment>
              }
              buttonClassName="button-action-icon"
              menuClassName="conversation-action-buttons__move__menu"
              isForm
            >
              <FilterInput
                disabled={false}
                width="300px"
                selected={{}}
                options={props.contextGroupsStore.value || []}
                displayKey="displayName"
                placeholder={Resources.LoadingOptions}
                closeDropdownOnClick
                handleSelectOption={option => {
                  props.moveConversationToContextGroup(
                      props.match.params.companyId,
                      perspectiveId,
                      option.contextGroupId,
                      row.conversationId,
                      false
                    )
                    .then(response => {
                      if (isGuid(props.match.params.selectedContextGroupId)) {
                        props.history.push(
                          props.location.pathname.replace(
                            props.match.params.selectedContextGroupId,
                            option.companyLocalKey
                          )
                        );
                      }
                      props.getConversation(row.conversationId);
                      actionComplete();
                    });
                }}
              />
            </Dropdown>
          </div>
        </div>
      )
    };

    const iconClassName = row => {
      return row.rootResourceName === "task" ? "" : "conversation-table-row-icon";
    };

    const iconCol = {
      header: "",
      content: row => {
        let icon = null;
        if ((props.contextGroupsStore.contextGroupsMaps[row.contextGroupIds[0]] || {}).isNew) {
          icon = <span className={`${Icons.activeConversation} table-row-main-line-item-icon-new`} />;
        }

        if (!isEmpty(row.activeReminderHash)) {
          icon = <span className={`${Icons.bell} conversation-table-row-icon-bell`} />;
        }
        return <div className={iconClassName(row)}>{icon}</div>;
      },
      width: "2%"
    };

    switch (contextGroup) {
      case "pending-approval":
        columns = [
          selectCol,
          iconCol,
          channelCol,
          {
            header: "",
            content: row => (row.hasAttachments === true ? <span className={Icons.attachment} /> : " "),
            width: "2%"
          },
          { ...subjectMessageCol, width: "40%" },
          {
            header: "",
            content: () => null,
            width: "1%"
          },
          assignedToCol,
          ...[getHoveredColumns ? hoverActionCol : (assignedToCol, approverCol, dateCol)]
        ];
        break;
      case "unassigned":
      case "spam":
      case "mine":
        columns = [
          selectCol,
          iconCol,
          channelCol,
          {
            header: "",
            content: row => (row.hasAttachments === true ? <span className={Icons.attachment} /> : " "),
            width: "2%"
          },
          subjectMessageCol,
          getHoveredColumns ? hoverActionCol : dateCol
        ];
        break;
      case "assigned":
      case "closed":
      default:
        columns = [
          selectCol,
          iconCol,
          channelCol,
          {
            header: "",
            content: row => (row.hasAttachments === true ? <span className={Icons.attachment} /> : " "),
            width: "2%"
          },
          { ...subjectMessageCol, width: "61%" },
          assignedToCol,
          ...[getHoveredColumns ? hoverActionCol : (assignedToCol, dateCol)]
        ];
        break;
    }
    return columns;
  }

  if (user.isLoggedIn !== true) {
    return <div />;
  }

  let params = props.match.params;
  let disableComposeButton;

  if (params.subView === "accounts" || params.subView === "contacts") {
    let sendToEmail;

    if (params.subView === "accounts") {
      sendToEmail = props.getAccountProfile(params.perspectiveId, params.selectedContextGroupId).emailAddr;
    } else {
      sendToEmail = props.getContactProfile(params.perspectiveId, params.selectedContextGroupId).emailAddr;
    }

    disableComposeButton = isEmpty(sendToEmail);
  }

  let conversationLabelOptions = [
    { value: "open", displayValue: Resources.AllOpen, labelValue: "open" },
    { value: "mine", displayValue: Resources.Mine, labelValue: "mine" },
    { value: "unassigned", displayValue: Resources.Unassigned, labelValue: "unassigned" },
    { value: "assigned", displayValue: Resources.Assigned, labelValue: "assignedToOthers" },
    { value: "waiting-for-response", displayValue: Resources.WaitingResponse, labelValue: "waitingForResponse" },
    { value: "closed", displayValue: Resources.Closed, labelValue: "closed" }
  ];

  return (
    <React.Fragment>
      <div className="action-buttons">
        <button
          className="button-primary bg-white"
          onClick={() => {
            const sendToAccountId = params.subView === "accounts" ? params.selectedContextGroupId : null;
            const sendToContactId = params.subView === "contacts" ? params.selectedContextGroupId : null;
            props.displayFlyout("newActivityFlyout", {
              companyId: params.companyId,
              perspectiveId: params.perspectiveId,
              sendToAccountId,
              sendToContactId
            });
          }}
          disabled={disableComposeButton}
        >
          <IconMessage height="20" className="button-primary-icon" />
          <span>{Resources.Compose}</span>
        </button>
      </div>
      <Card type="table">
        {(isEmpty(conversationsData) || isEmpty(conversationsData.value)) &&
        !loadingConversations &&
        !isNumber(getContextGroupId()) ? (
          <EmptyState />
        ) : (
          <React.Fragment>
            <div className="table-data-card-header">
              {props.flags.labeledConversationsWithCompany && isNumber(getContextGroupId()) && (
                <div className="table-data-card-header__select-label">
                  <SelectInput
                    value={conversationsLabel}
                    options={conversationLabelOptions}
                    renderOption={option => {
                      return (
                        <div className="flex justify-content-between flex-grow-1">
                          {option.displayValue}
                          <SummaryCountBadge contextGroupId={getContextGroupId()} label={option.labelValue} />
                        </div>
                      );
                    }}
                    onSelectOption={option => {
                      // We reset various things when the user switches to a different conversation label to given them a clean view
                      setNavigatingBack(false);
                      setDisplayingSearchResults(false);
                      setConversationsViewSearchTerm("");
                      setConversationsCurrentPage(1);
                      setConversationsSearchResultsCurrentPage(1);
                      setConversationsLabel(option.value);
                    }}
                    labelClassName="reply-form-label"
                    width="16.66rem"
                    disabled={props.disabled}
                  />
                </div>
              )}
              {!isEmpty(selectedRows) ? (
                <div className="table-data-card-header-buttons">
                  <ConversationActionButtons
                    actionComplete={() => {
                      actionComplete();
                    }}
                    noForward
                    selectedRows={selectedRows}
                    selectedKeys={selectedKeys}
                    perspectiveId={perspective}
                    lastEventStatusChange={true}
                  />
                </div>
              ) : (
                <div className="table-data-card-header-search">
                  <AutoCompleteInput
                    loading={!!debouncedSearchTerm && !searchData && !searchError}
                    className="auto-complete-input__table-data-search"
                    placeholder={Resources.Search}
                    onChange={e => {
                      setConversationsViewSearchTerm(e.target.value);
                    }}
                    callToActionButton={
                      <div
                        className="dropdown-item-clean dropdown-call-to-action"
                        onClick={() => {
                          setDisplayingSearchResults(true);
                          setUpdatingSearchResults(true);
                        }}
                      >
                        {Resources.SeeAllResultsFor(debouncedSearchTerm)}
                      </div>
                    }
                    isShowingSearchResult={!!debouncedSearchTerm}
                    handleClearResult={() => {
                      setConversationsViewSearchTerm("");

                      // Only do a refresh if the user was actually looking at all search results. Otherwise if they did a search and simply clicked
                      // on the clear icon in the input field, it will make an unneeded API request.
                      if (displayingSearchResults) {
                        setDisplayingSearchResults(false);
                        setUpdatingSearchResults(false);
                        setConversationsCurrentPage(1);
                        setConversationsSearchResultsCurrentPage(1);
                        refreshConversations();
                      }
                    }}
                    text={conversationsSearchTerm}
                    noResultsMessage={Resources.NoResultsFound}
                    showNoResultsMessage={(searchData || {}).count === 0}
                    maxOptions={5}
                    handleSelectOption={option => {
                      props.updateConversationReadStatuses([option.conversationId], true);
                      props.history.push(`${window.location.hash.substr(1)}/${option.conversationId}`);
                    }}
                    renderOption={option => option.subject}
                    options={(searchData || {}).value}
                    width="300px"
                  />
                </div>
              )}
            </div>

            <TableData
              name="conversation-table"
              columns={getConversationColumns(params.selectedContextGroupId, false)}
              hoverColumns={getConversationColumns(params.selectedContextGroupId, true)}
              data={conversationsData.value || []}
              onLoadMore={page => {
                clearSelectedRows();

                if (displayingSearchResults) {
                  setUpdatingSearchResults(true);
                  setConversationsSearchResultsCurrentPage(page);
                } else {
                  setConversationsCurrentPage(page);
                }
              }}
              loadedPage={displayingSearchResults ? conversationsSearchResultsCurrentPage : conversationsCurrentPage}
              pagination
              headerClassName="conversation-table__header"
              loading={loadingConversations}
              maxRows={conversationsData.count || 0}
              onRowClick={(row) => {
                props.updateConversationReadStatuses([row.conversationId], true);
                props.history.push(`${window.location.hash.substr(1)}/${row.conversationId}`);
              }}
              rowKey="conversationId"
              localSelectedKeys={selectedKeys}
              onRowSelectToggle={key =>
                toggleSelectRow(
                  key,
                  (Array.isArray(key) ? [...key] : [key]).map(k => find(conversationsData.value, row => row.conversationId === k))
                )
              }
              rowClassName="conversation-table-row"
              rowHeight="4rem"
              emptyRender={
                <div className="flex-center">
                  <h4 className="mt-5">{Resources.EmptyInboxActivities}</h4>
                </div>
              }
            />
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  );
};

const storeToProps = store => {
  return {
    user: store.user,
    conversationStore: store.conversations,
    connectorStore: store.connector,
    ledgerStore: store.ledger,
    workflows: store.manage.workflows,
    contextGroupsStore: store.contextGroups,
    manageStore: store.manage,
    pageRowCount: store.general.pageRowCount
  };
};

export default withLDConsumer()(withRouter(connect(storeToProps, dispatchToProps)(ConversationTable)));
