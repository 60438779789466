import { actions } from "./ledger-actions";
import { createKey } from "../lib/utils";

const defaultCompanyState = {
  grantsFrom: {},
  grantsTo: {},
  resourcesFrom: {},
  resourcesTo: {},
  attachments: []
};

export const defaultLedgerState = {
  companyResources: {},
  isFetchingCompanyResources: {},
  fetchingCompanyResourcesFailed: {},
  companyResourceNextLinks: {},

  gettingGrantsFrom: false,
  gettingResourcesFrom: false,
  gettingGrantsTo: false,
  gettingResourcesTo: false,
  companies: {},
  entries: {
    maxLength: 500,
    keys: []
  },
  gettingAttachments: {},
  attachments: {
    maxLength: 500,
    keys: []
  },
  gettingAttachmentUrl: {},
  gettingAttachmentUrls: false,
  attachmentUrls: {
    maxLength: 500,
    keys: []
  },

  uploadingDocument: false,
  uploadedDocument: false,
  uploadDocumentFailed: false,

  gettingAttachmentsZip: false,
  gotAttachmentsZip: false,
  getAttachmentsZipFailed: null,

  isAddingAttachment: false,

  deletingAttachments: false,
  deletedAttachments: false,
  deleteAttachmentsFailed: null,

  creatingEntry: false,
  createdEntry: false,
  createEntryFailed: false,

  creatingDraft: false,
  createdDraft: false,
  createDraftFailed: false,

  forwardingDraft: false,
  forwardedDraft: false,
  forwardDraftFailed: false,

  committingDraft: false,
  committedDraft: false,
  commitDraftFailed: false,

  isFetchingResources: false,
  fetchingResourcesFailed: null,
  resourceMaps: {},
  resources: [],

  isFetchingResourceConfigSchema: {},
  fetchingResourceConfigSchemaFailed: {},
  resourceConfigSchemas: {},

  isFetchingEntryConfig: {},
  fetchingEntryConfigFailed: {},
  entryConfigs: {},

  isUpdatingEntryConfig: false,
  updatingEntryConfigFailed: null
};

function ledgerReducer(state = defaultLedgerState, action) {
  if (window.logToConsole) {
    console.log(action);
  }

  switch (action.type) {
    case actions.SELECT_COMPANY:
      if (action.companyId === state.selectedCompanyId) {
        return state;
      }

      let companyId = action.companyId;

      if (action.companyId === "ALL_ACCOUNTS_SELECTED") {
        companyId = action.primaryCompanyId;
      }

      return { ...defaultLedgerState, selectedCompanyId: companyId };
    case actions.SELECT_RELATIONSHIP:
      return {
        ...state,
        fromCompanyId: action.fromCompanyId,
        toCompanyId: action.toCompanyId,
        resourceName: action.resourceName
      };

    case actions.GETTING_GRANTS_FROM:
      return { ...state, ...{ gettingGrantsFrom: true } };
    case actions.GET_GRANTS_FROM_FAILED:
      return { ...state, ...{ gettingGrantsFrom: false } };
    case actions.GOT_GRANTS_FROM:
      if (state.gettingGrantsFrom === false) return state;
      let gfCompany = getCompany(state, action.companyId);
      gfCompany.grantsFrom = mergeGrants(gfCompany.grantsFrom, action.grantsFrom);
      return {
        ...state,
        ...{
          gettingGrantsFrom: false,
          companies: updateCompany(state, action.companyId, gfCompany)
        }
      };

    case actions.GETTING_GRANTS_TO:
      return { ...state, ...{ gettingGrantsTo: true } };
    case actions.GET_GRANTS_TO_FAILED:
      return { ...state, ...{ gettingGrantsTo: false } };
    case actions.GOT_GRANTS_TO:
      if (state.gettingGrantsTo === false) return state;
      let gtCompany = getCompany(state, action.companyId);
      gtCompany.grantsTo = mergeGrants(gtCompany.grantsTo, action.grantsTo);
      return {
        ...state,
        ...{
          gettingGrantsTo: false,
          companies: updateCompany(state, action.companyId, gtCompany)
        }
      };

    case actions.GETTING_RESOURCES_FROM:
      return { ...state, ...{ gettingResourcesFrom: true } };
    case actions.GET_RESOURCES_FROM_FAILED:
      return { ...state, ...{ gettingResourcesFrom: false } };
    case actions.GOT_RESOURCES_FROM:
      if (state.gettingResourcesFrom === false) return state;
      let gfResources = action.resourcesFrom.resources || action.resourcesFrom || [];
      let grfCompany = getCompany(state, action.companyId);
      let grf = (grfCompany.resourcesFrom = {
        ...(grfCompany.resourcesFrom || {})
      });
      let grfResources = (grf[action.resourceName] = {
        ...(grf[action.resourceName] || {})
      });
      let grfExisting = grfResources.items || [];
      if (action.continuation) gfResources = [...grfExisting, ...gfResources];
      grfResources.items = gfResources;
      grfResources.continuation = action.resourcesFrom.continuation;
      return {
        ...state,
        ...{
          gettingResourcesFrom: false,
          companies: updateCompany(state, action.companyId, grfCompany)
        }
      };

    case actions.GETTING_RESOURCES_TO:
      return { ...state, ...{ gettingResourcesTo: true } };
    case actions.GET_RESOURCES_TO_FAILED:
      return { ...state, ...{ gettingResourcesTo: false } };
    case actions.GOT_RESOURCES_TO:
      if (state.gettingResourcesTo === false) return state;
      let gtResources = action.resourcesTo.resources || action.resourcesTo || [];
      let grtCompany = getCompany(state, action.companyId);
      let grt = (grtCompany.resourcesTo = {
        ...(grtCompany.resourcesTo || {})
      });
      let grtResources = (grt[action.resourceName] = {
        ...(grt[action.resourceName] || {})
      });
      let grtExisting = grtResources.items || [];
      if (action.continuation) gtResources = [...grtExisting, ...gtResources];
      grtResources.items = gtResources;
      grtResources.continuation = action.resourcesTo.continuation;
      return {
        ...state,
        ...{
          gettingResourcesTo: false,
          companies: updateCompany(state, action.companyId, grtCompany)
        }
      };

    case actions.FETCHING_COMPANY_RESOURCES:
      let resourceKey = createKey(action.toCompanyId, action.resourceName);
      return {
        ...state,
        isFetchingCompanyResources: {
          ...state.isFetchingCompanyResources,
          [resourceKey]: true
        }
      };
    case actions.FETCHED_COMPANY_RESOURCES:
      resourceKey = createKey(action.toCompanyId, action.resourceName);
      return {
        ...state,
        isFetchingCompanyResources: {
          ...state.isFetchingCompanyResources,
          [resourceKey]: false
        },
        fetchingCompanyResourcesFailed: {
          ...state.fetchingCompanyResourcesFailed,
          [resourceKey]: false
        },
        companyResources: {
          ...state.companyResources,
          [resourceKey]: action.resources
        },
        companyResourceNextLinks: {
          ...state.companyResourceNextLinks,
          [resourceKey]: action.nextLink
        }
      };
    case actions.FETCH_COMPANY_RESOURCES_FAILED:
      resourceKey = createKey(action.toCompanyId, action.resourceName);
      return {
        ...state,
        isFetchingCompanyResources: {
          ...state.isFetchingCompanyResources,
          [resourceKey]: false
        },
        fetchingCompanyResourcesFailed: {
          ...state.fetchingCompanyResourcesFailed,
          [resourceKey]: true
        }
      };

    case actions.FETCHING_RESOURCES:
      return {
        ...state,
        isFetchingResources: true,
        fetchingResourcesFailed: null
      };
    case actions.FETCHED_RESOURCES:
      const resourceMaps = {};
      action.resources.forEach(resource => (resourceMaps[resource.resourceId] = resource));
      return {
        ...state,
        isFetchingResources: false,
        resources: action.resources,
        resourceMaps: resourceMaps,
        fetchingResourcesFailed: false
      };
    case actions.FETCH_RESOURCES_FAILED:
      return {
        ...state,
        isFetchingResources: false,
        fetchingResourcesFailed: true
      };

    case actions.FETCHING_RESOURCE_CONFIG_SCHEMA:
      return {
        ...state,
        isFetchingResourceConfigSchema: {
          ...state.isFetchingResourceConfigSchema,
          [action.resourceId]: true
        }
      };
    case actions.FETCHED_RESOURCE_CONFIG_SCHEMA:
      return {
        ...state,
        isFetchingResourceConfigSchema: {
          ...state.isFetchingResourceConfigSchema,
          [action.resourceId]: false
        },
        resourceConfigSchemas: {
          ...state.resourceConfigSchemas,
          [action.resourceId]: action.configSchema
        },
        fetchingResourceConfigSchemaFailed: {
          ...state.fetchingResourceConfigSchemaFailed,
          [action.resourceId]: false
        }
      };
    case actions.FETCH_RESOURCE_CONFIG_SCHEMA_FAILED:
      return {
        ...state,
        isFetchingResourceConfigSchema: {
          ...state.isFetchingResourceConfigSchema,
          [action.resourceId]: false
        },
        fetchingResourceConfigSchemaFailed: {
          ...state.fetchingResourceConfigSchemaFailed,
          [action.resourceId]: true
        }
      };

    case actions.SET_MAX_ENTRIES_LENGTH:
      if (state.entries.maxLength > action.maxLength) return state;
      let smeLength = { ...state.entries };
      smeLength.maxLength = action.maxLength;
      return { ...state, ...{ entries: smeLength } };

    case actions.CREATING_ENTRY:
      return { ...state, creatingEntry: true, createdEntry: false, createEntryFailed: false };
    case actions.CREATED_ENTRY:
      return { ...state, creatingEntry: false, createdEntry: true, createEntryFailed: false };
    case actions.CREATE_ENTRY_FAILED:
      return { ...state, creatingEntry: false, createdEntry: false, createEntryFailed: true };

    case actions.CREATING_DRAFT:
      return { ...state, creatingDraft: true, createdDraft: false, createDraftFailed: false, committedDraft: false };
    case actions.CREATED_DRAFT:
      return { ...state, creatingDraft: false, createdDraft: true, createDraftFailed: false };
    case actions.CREATE_DRAFT_FAILED:
      return { ...state, creatingDraft: false, createdDraft: false, createDraftFailed: true };

    case actions.FORWARDING_DRAFT:
      return { ...state, forwardingDraft: true, forwardedDraft: false, forwardDraftFailed: false };
    case actions.FORWARDED_DRAFT:
      return { ...state, forwardingDraft: false, forwardedDraft: true, forwardDraftFailed: false };

    case actions.FORWARDED_DRAFT_FAILED:
      return { ...state, forwardingDraft: false, forwardedDraft: false, forwardDraftFailed: true };

    case actions.COMMITTING_DRAFT:
      return { ...state, committingDraft: true, committedDraft: false, commitDraftFailed: false };
    case actions.COMMITTED_DRAFT:
      return { ...state, committingDraft: false, committedDraft: true, commitDraftFailed: false };
    case actions.COMMIT_DRAFT_FAILED:
      return { ...state, committingDraft: false, committedDraft: false, commitDraftFailed: true };

    case actions.FETCHING_ENTRY_CONFIG:
      let entryPerspectiveKey = createKey(action.ledgerHash, action.perspectiveId);
      return {
        ...state,
        isFetchingEntryConfig: {
          ...state.isFetchingEntryConfig,
          [entryPerspectiveKey]: true
        }
      };
    case actions.FETCHED_ENTRY_CONFIG:
      entryPerspectiveKey = createKey(action.ledgerHash, action.perspectiveId);
      return {
        ...state,
        isFetchingEntryConfig: {
          ...state.isFetchingEntryConfig,
          [entryPerspectiveKey]: false
        },
        fetchingEntryConfigFailed: {
          ...state.fetchingEntryConfigFailed,
          [entryPerspectiveKey]: false
        },
        entryConfigs: {
          ...state.entryConfigs,
          [entryPerspectiveKey]: action.config
        }
      };
    case actions.FETCH_ENTRY_CONFIG_FAILED:
      entryPerspectiveKey = createKey(action.ledgerHash, action.perspectiveId);
      return {
        ...state,
        isFetchingEntryConfig: {
          ...state.isFetchingEntryConfig,
          [entryPerspectiveKey]: false
        },
        fetchingEntryConfigFailed: {
          ...state.fetchingEntryConfigFailed,
          [entryPerspectiveKey]: true
        }
      };

    case actions.UPDATING_ENTRY_CONFIG:
      return { ...state, isUpdatingEntryConfig: true };
    case actions.UPDATED_ENTRY_CONFIG:
      entryPerspectiveKey = createKey(action.ledgerHash, action.perspectiveId);
      return {
        ...state,
        isUpdatingEntryConfig: false,
        updatingEntryConfigFailed: false,
        entryConfigs: {
          ...state.entryConfigs,
          [entryPerspectiveKey]: action.config
        }
      };
    case actions.UPDATE_ENTRY_CONFIG_FAILED:
      return {
        ...state,
        isUpdatingEntryConfig: false,
        updatingEntryConfigFailed: true
      };

    case actions.FETCHING_ENTRY:
      return { ...state, fetchingEntries: { ...state.fetchingEntries, [action.ledgerHash]: true } };

    case actions.GOT_ENTRY:
      const nextFetchingEntries = { ...state.fetchingEntries };
      delete nextFetchingEntries[action.ledgerHash];
      return {
        ...state,
        entries: { ...state.entries, [action.ledgerHash]: action.entry },
        fetchingEntries: nextFetchingEntries
      };

    case actions.SET_MAX_ATTACHMENTS_LENGTH:
      if (state.attachments.maxLength > action.maxLength) return state;
      let smaLength = { ...state.attachments };
      smaLength.maxLength = action.maxLength;
      return { ...state, ...{ attachments: smaLength } };

    case actions.UPLOADING_DOCUMENT:
      return { ...state, uploadingDocument: true, uploadedDocument: false, uploadDocumentFailed: false };
    case actions.UPLOADED_DOCUMENT:
      return { ...state, uploadingDocument: false, uploadedDocument: true, uploadDocumentFailed: false };
    case actions.UPLOAD_DOCUMENT_FAILED:
      return { ...state, uploadingDocument: false, uploadedDocument: false, uploadDocumentFailed: true };

    case actions.DELETING_ATTACHMENTS:
      return { ...state, deletingAttachments: true, deletedAttachments: false, deleteAttachmentsFailed: false };
    case actions.DELETED_ATTACHMENTS:
      return { ...state, deletingAttachments: false, deletedAttachments: true, deleteAttachmentsFailed: false };
    case actions.DELETE_ATTACHMENTS_FAILED:
      return { ...state, deletingAttachments: false, deletedAttachments: false, deleteAttachmentsFailed: true };

    case actions.ADDING_ATTACHMENT:
      return { ...state, isAddingAttachment: true };
    case actions.ADDED_ATTACHMENT:
      return { ...state, isAddingAttachment: false };
    case actions.ADD_ATTACHMENT_FAILED:
      return { ...state, isAddingAttachment: false };

    case actions.ADDED_DOCUMENT_ATTACHMENT:
      return { ...state };
    case actions.GETTING_ATTACHMENTS:
      let ga = { ...state.gettingAttachments };
      ga[action.ledgerHash] = true;
      return { ...state, gettingAttachments: ga };
    case actions.GET_ATTACHMENTS_FAILED:
      let gaf = { ...state.gettingAttachments };
      delete gaf[action.ledgerHash];
      return { ...state, gettingAttachments: gaf };
    case actions.GOT_ATTACHMENTS:
      if (state.gettingAttachments[action.ledgerHash] !== true) return state;

      //TODO:  WHY IS THIS IN HERE?
      if (action.type === actions.ADDED_ATTACHMENT) {
        action.attachments = [...(state.attachments[action.ledgerHash] || [])];
        action.attachments.push(action.attachment);
      }

      let gaState = { ...state };
      let gettingAttachments = { ...gaState.gettingAttachments };
      delete gettingAttachments[action.ledgerHash];
      gaState.gettingAttachments = gettingAttachments;

      if (gaState.attachments[action.ledgerHash]) {
        let uAttachments = { ...gaState.attachments };
        uAttachments[action.ledgerHash] = action.attachments;
        return { ...gaState, attachments: uAttachments };
      }

      let gsa = { ...gaState.attachments };
      gaState.attachments = gsa;
      let gaKeys = [...gsa.keys];
      gsa.keys = gaKeys;
      gaKeys.unshift(action.ledgerHash);
      gsa[action.ledgerHash] = action.attachments;
      if (gaKeys.length > gsa.maxLength) {
        let gakPopped = gaKeys.pop();
        delete gsa[gakPopped];
      }

      return gaState;

    case actions.GETTING_ATTACHMENTS_ZIP:
      return { ...state, gettingAttachmentsZip: true, gotAttachmentsZip: false, getAttachmentsZipFailed: false };
    case actions.GOT_ATTACHMENTS_ZIP:
      return { ...state, gettingAttachmentsZip: false, gotAttachmentsZip: true, getAttachmentsZipFailed: false };
    case actions.GET_ATTACHMENTS_ZIP_FAILED:
      return { ...state, gettingAttachmentsZip: false, gotAttachmentsZip: false, getAttachmentsZipFailed: true };

    case actions.SET_MAX_ATTACHMENT_URLS_LENGTH:
      if (state.attachmentUrls.maxLength > action.maxLength) return state;
      let smauLength = { ...state.attachmentUrls };
      smauLength.maxLength = action.maxLength;
      return { ...state, ...{ attachmentUrls: smauLength } };

    case actions.GETTING_ATTACHMENT_URL:
      let gau = { ...state.gettingAttachmentUrl };
      gau[action.attachmentId] = true;
      return { ...state, gettingAttachmentUrl: gau };
    case actions.GET_ATTACHMENT_URL_FAILED:
      let gauf = { ...state.gettingAttachmentUrl };
      delete gauf[action.attachmentId];
      return { ...state, gettingAttachmentUrl: gauf };

    case actions.GOT_ATTACHMENT_URL:
      if (state.attachmentUrls[action.attachmentId]) return state;

      let gettingAttachmentUrl = { ...state.gettingAttachmentUrl };
      delete gettingAttachmentUrl[action.attachmentId];

      let gauState = { ...state, gettingAttachmentUrl };
      let gaua = { ...gauState.attachmentUrls };
      gauState.attachmentUrls = gaua;
      let gauKeys = [...gaua.keys];
      gaua.keys = gauKeys;
      gauKeys.unshift(action.attachmentId);
      gaua[action.attachmentId] = action.attachmentUrl;
      if (gauKeys.length > gaua.maxLength) {
        let gaukPopped = gauKeys.pop();
        delete gaua[gaukPopped];
      }
      return gauState;

    case actions.GETTING_ATTACHMENT_URLS:
      return {
        ...state,
        gettingAttachmentUrl: {
          ...state.gettingAttachmentUrl,
          [action.attachmentIds]: true
        },
        gettingAttachmentUrls: true
      };
    case actions.GET_ATTACHMENT_URLS_FAILED:
      return {
        ...state,
        gettingAttachmentUrl: {
          ...state.gettingAttachmentUrl,
          [action.attachmentIds]: false
        },
        gettingAttachmentUrls: false
      };
    case actions.GOT_ATTACHMENT_URLS:
      if (state.attachmentUrls[action.attachmentIds]) return state;

      let returnState = {
        ...state,
        gettingAttachmentUrl: {
          ...state.gettingAttachmentUrl,
          [action.attachmentIds]: false
        },
        attachmentUrls: {
          ...state.attachmentUrls,
          keys: action.attachmentIds.concat(state.attachmentUrls.keys).slice(0, state.attachmentUrls.maxLength)
        },
        gettingAttachmentUrls: false
      };
      action.attachmentUrls.forEach(attachmentUrl => {
        returnState.attachmentUrls[attachmentUrl.attachmentId] = attachmentUrl;
      });
      return returnState;

    case actions.CLEAR_ATTACHMENT_URL:
      let attachmentUrls = { ...state.attachmentUrls };
      delete attachmentUrls[action.attachmentId];
      return { ...state, attachmentUrls };

    case actions.CLEAR_DATA:
      return defaultLedgerState;

    default:
      return state;
  }
}

function getCompany(state, companyId) {
  let company = state.companies[companyId] || defaultCompanyState;
  return { ...company };
}

function updateCompany(state, companyId, company) {
  let companies = { ...state.companies };
  companies[companyId] = company;
  return companies;
}

function mergeGrants(oldGrants, newGrants) {
  Object.keys(oldGrants).forEach(gkey => {
    let targetGrant = newGrants[gkey];
    if (targetGrant) {
      let targetResources = targetGrant.resources || {};
      let sourceResources = oldGrants[gkey].resources || {};
      Object.keys(sourceResources).forEach(rkey => {
        if (targetResources[rkey]) targetResources[rkey].items = sourceResources[rkey].items;
      });
    }
  });
  return newGrants;
}

export default ledgerReducer;
