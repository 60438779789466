import data from "../lib/data";
import { handlePromiseError } from "./error-actions";
import Resources from "../lib/resources";
import { compareDates, isEmpty, find, includes, capStringAtLength } from "../lib/utils";

import { actions as GeneralActions } from "./general-actions";
import { getUsers } from "./user-actions";

export const actions = {
  ...GeneralActions,
  ...{
    FETCHING_ACCOUNTS: "FETCHING_ACCOUNTS",
    FETCHED_ACCOUNTS: "FETCHED_ACCOUNTS",
    FETCH_ACCOUNTS_FAILED: "FETCH_ACCOUNTS_FAILED",

    ASSUMING_ACCOUNT: "ASSUMING_ACCOUNT",
    ASSUMED_ACCOUNT: "ASSUMED_ACCOUNT",
    ASSUME_ACCOUNT_FAILED: "ASSUME_ACCOUNT_FAILED",

    CREATING_ACCOUNT: "CREATING_ACCOUNT",
    CREATED_ACCOUNT: "CREATED_ACCOUNT",
    CREATE_ACCOUNT_FAILED: "CREATE_ACCOUNT_FAILED",

    GETTING_ACCOUNT: "GETTING_ACCOUNT",
    GOT_ACCOUNT: "GOT_ACCOUNT",
    GET_ACCOUNT_FAILED: "GET_ACCOUNT_FAILED",

    UPDATING_ACCOUNT: "UPDATING_ACCOUNT",
    UPDATED_ACCOUNT: "UPDATED_ACCOUNT",
    UPDATE_ACCOUNT_FAILED: "UPDATE_ACCOUNT_FAILED",

    SELECT_ACCOUNT: "SELECT_ACCOUNT",

    FETCHING_SUBSCRIPTION: "FETCHING_SUBSCRIPTION",
    FETCHED_SUBSCRIPTION: "FETCHED_SUBSCRIPTION",
    FETCH_SUBSCRIPTION_FAILED: "FETCH_SUBSCRIPTION_FAILED",

    CREATING_COMPANY: "CREATING_COMPANY",
    CREATED_COMPANY: "CREATED_COMPANY",
    CREATE_COMPANY_FAILED: "CREATE_COMPANY_FAILED",

    GETTING_COMPANY: "GETTING_COMPANY",
    GOT_COMPANY: "GOT_COMPANY",
    GET_COMPANY_FAILED: "GET_COMPANY_FAILED",

    UPDATING_COMPANY: "UPDATING_COMPANY",
    UPDATED_COMPANY: "UPDATED_COMPANY",
    UPDATE_COMPANY_FAILED: "UPDATE_COMPANY_FAILED",

    GETTING_COMPANY_ROLES: "GETTING_COMPANY_ROLES",
    GOT_COMPANY_ROLES: "GOT_COMPANY_ROLES",
    GET_COMPANY_ROLES_FAILED: "GET_COMPANY_ROLES_FAILED",

    SETTING_COMPANY_USER_ROLE: "SETTING_COMPANY_USER_ROLE",
    SET_COMPANY_USER_ROLE: "SET_COMPANY_USER_ROLE",
    SET_COMPANY_USER_ROLE_FAILED: "SET_COMPANY_USER_ROLE_FAILED",

    DELETING_COMPANY_USER: "DELETING_COMPANY_USER",
    DELETED_COMPANY_USER: "DELETED_COMPANY_USER",
    DELETE_COMPANY_USER_FAILED: "DELETE_COMPANY_USER_FAILED",

    UPDATING_COMPANY_CONTACT: "UPDATING_COMPANY_CONTACT",
    UPDATED_COMPANY_CONTACT: "UPDATED_COMPANY_CONTACT",
    UPDATE_COMPANY_CONTACT_FAILED: "UPDATE_COMPANY_CONTACT_FAILED",

    CREATING_COMPANY_CONTACT: "CREATING_COMPANY_CONTACT",
    CREATED_COMPANY_CONTACT: "CREATED_COMPANY_CONTACT",
    CREATE_COMPANY_CONTACT_FAILED: "CREATE_COMPANY_CONTACT_FAILED",

    DELETING_COMPANY_CONTACT: "DELETING_COMPANY_CONTACT",
    DELETED_COMPANY_CONTACT: "DELETED_COMPANY_CONTACT",
    DELETE_COMPANY_CONTACT_FAILED: "DELETE_COMPANY_CONTACT_FAILED",

    FETCH_DROPDOWN_CONTENT: "FETCH_DROPDOWN_CONTENT",
    UPDATE_DROPDOWN_SORT: "UPDATE_DROPDOWN_SORT"
  }
};

const fetchAccounts = () => (dispatch, getState) => {
  let state = getState().accounts;
  if (state.fetchingAccounts) return;
  dispatch({ type: actions.FETCHING_ACCOUNTS });
  data
    .get("v1/api/account")
    .then(response => {
      dispatch({ type: actions.FETCHED_ACCOUNTS, accounts: response.data });
    })
    .catch(error => {
      dispatch({ type: actions.FETCH_ACCOUNTS_FAILED });
      handlePromiseError(error, Resources.AccountFetchFailure, "accounts");
    });
};

const createAccount = accountName => (dispatch, getState) => {
  // let state = getState().accounts;
  // if (state.creatingAccount) return;
  dispatch({ type: actions.CREATING_ACCOUNT });
  return data
    .put("v1/api/account", { accountName: accountName })
    .then(response => {
      dispatch({ type: actions.CREATED_ACCOUNT, account: response.data });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: actions.CREATE_ACCOUNT_FAILED });
      handlePromiseError(error, Resources.AccountCreateFailure, "account");
    });
};

const getAccount = accountId => (dispatch, getState) => {
  let state = getState().accounts;
  if (state.gettingAccount) return;
  dispatch({ type: actions.GETTING_ACCOUNT });
  data
    .get(`v1/api/account/${accountId}`)
    .then(response => {
      dispatch({ type: actions.GOT_ACCOUNT, account: response.data });
    })
    .catch(error => {
      dispatch({ type: actions.GET_ACCOUNT_FAILED });
      handlePromiseError(error, Resources.AccountFetchFailure, "account");
    });
};

const assumeAccount = () => (dispatch, getState) => {
  let state = getState().accounts;
  if (state.assumingAccount) return;
  dispatch({ type: actions.ASSUMING_ACCOUNT });
  data
    .post(`v1/api/account/assume`)
    .then(response => {
      dispatch({ type: actions.ASSUMED_ACCOUNT, account: response.data });
    })
    .catch(response => {
      dispatch({ type: actions.ASSUME_ACCOUNT_FAILED });
      handlePromiseError(response, Resources.AccountAssumeFailure);
    });
};

const updateAccount = account => (dispatch, getState) => {
  let state = getState().accounts;
  if (state.updatingAccount) return;
  dispatch({ type: actions.UPDATING_ACCOUNT });
  data
    .post(`v1/api/account/${account.accountId}`, {
      accountName: account.accountName
    })
    .then(response => {
      dispatch({ type: actions.UPDATED_ACCOUNT, account: response.data });
    })
    .catch(response => {
      dispatch({ type: actions.UPDATE_ACCOUNT_FAILED });
      handlePromiseError(response, Resources.AccountUpdateFailure, "account");
    });
};

const fetchSubscription = () => (dispatch, getState) => {
  const store = getState();
  const accountsStore = store.accounts;

  const account = accountsStore.selectedAccount;
  const accountId = (account || {}).accountId;
  if (accountsStore.fetchingSubscription || isEmpty(accountId)) {
    return;
  }
  dispatch({ type: actions.FETCHING_SUBSCRIPTION });
  data
    .get(`v1/api/account/${accountId}/subscription`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_SUBSCRIPTION,
        subscription: response.data
      });
    })
    .catch(error => {
      dispatch({ type: actions.FETCH_SUBSCRIPTION_FAILED });
      handlePromiseError(error, Resources.SubscriptionFetchError, "subscription");
    });
};

const getSubscription = () => (dispatch, getState) => {
  const state = getState().accounts;
  if (state.hasFetchedAccounts !== true) {
    dispatch(fetchAccounts());
    return null;
  } else if (state.hasFetchedSubscription === false) {
    dispatch(fetchSubscription());
    return null;
  } else {
    return state.subscription;
  }
};

const createAccountCompany = companyData => (dispatch, getState) => {
  let state = getState().accounts;
  if (state.creatingCompany) return;
  dispatch({ type: actions.CREATING_COMPANY });
  return data
    .put("v1/api/account/company", companyData)
    .then(response => {
      dispatch({
        type: actions.CREATED_COMPANY,
        accountId: companyData.accountId,
        company: response.data
      });
      return response.data;
    })
    .catch(response => {
      dispatch({ type: actions.CREATE_COMPANY_FAILED });
      handlePromiseError(response, Resources.CompanyCreateFailure, "company");
    });
};

const fetchCompany = companyId => (dispatch, getState) => {
  if (isEmpty(companyId)) {
    return null;
  }

  let state = getState().accounts;
  if (state.gettingCompany) return;
  dispatch({ type: actions.GETTING_COMPANY });
  return data
    .get(`v1/api/account/company/${companyId}`)
    .then(response => {
      dispatch({ type: actions.GOT_COMPANY, company: response.data });
      return response.data;
    })
    .catch(response => {
      dispatch({ type: actions.GET_COMPANY_FAILED });
      handlePromiseError(response, Resources.CompanyFetchFailure, "company data");
    });
};

const getCompany = companyId => (dispatch, getState) => {
  let state = getState().accounts;

  let company = find(state.selectedAccount.companies, company => company.companyId === companyId);

  if (isEmpty(company)) {
    return dispatch(fetchCompany(companyId));
  } else {
    return company;
  }
};

const updateCompany = company => (dispatch, getState) => {
  let state = getState().accounts;
  if (state.updatingCompany) return;
  dispatch({ type: actions.UPDATING_COMPANY });
  data
    .post(`v1/api/account/company/${company.companyId}`, {
      companyName: company.companyName
    })
    .then(response => {
      dispatch({ type: actions.UPDATED_COMPANY, company: response.data });
    })
    .catch(response => {
      dispatch({ type: actions.UPDATE_COMPANY_FAILED });
      handlePromiseError(response, Resources.CompanyUpdateFailure, "company");
    });
};

const fetchCompanyUserRoles = companyId => (dispatch, getState) => {
  let state = getState().accounts;

  if (state.gettingCompanyRoles || isEmpty(companyId)) {
     return;
  }

  dispatch({ type: actions.GETTING_COMPANY_ROLES, companyId });

  data
    .get(`v1/api/account/company/${companyId}/userroles`)
    .then(response => {
      dispatch({
        type: actions.GOT_COMPANY_ROLES,
        companyRoles: response.data,
        companyId
      });

      // The /userroles endpoint retrieves a list of users by role, and then the /users endpoint retrieves the specific info for those users
      let userIds = response.data.map(role => {
        return role.userId;
      });

      dispatch(getUsers(companyId, userIds));
    })
    .catch(error => {
      dispatch({ type: actions.GET_COMPANY_ROLES_FAILED });
      handlePromiseError(error, "Getting company roles failed. Please refresh the page.", "userroles");
    });
};

const getCompanyUserRoles = () => (dispatch, getState) => {
  let state = getState().accounts;

  if (!state.fetchedCompanyRoles) {
    dispatch(fetchCompanyUserRoles(state.selectedCompanyId));
  }

  return state.companyRoles;
};

const getPerspectiveUserRoles = perspectiveId => (dispatch, getState) => {
  const companyRoles = dispatch(getCompanyUserRoles());
  return companyRoles.filter(role => {
    return role.role === 1 || includes(role.perspectiveIds, perspectiveId);
  });
};

const putCompanyUserRole = (companyId, userId, role, perspectiveIds) => (dispatch, getState) => {
  dispatch({ type: actions.SETTING_COMPANY_USER_ROLE, companyId, userId });
  let payload = { userId, role };
  if (!isEmpty(perspectiveIds)) {
    payload.perspectiveIds = perspectiveIds;
  }
  data
    .put(`v1/api/account/company/${companyId}/userroles`, payload)
    .then(response => {
      dispatch({
        type: actions.SET_COMPANY_USER_ROLE,
        data: response.data,
        companyId,
        userId,
        role
      });
      dispatch(fetchCompanyUserRoles(companyId));
    })
    .catch(rejection => {
      dispatch({
        type: actions.SET_COMPANY_USER_ROLE_FAILED,
        companyId,
        userId
      });
      handlePromiseError(
        rejection,
        "TODO: Changing company user role failed.  Please refresh the page and try again.",
        "userrole"
      );
    });
};

const deleteCompanyUser = (companyId, userId) => (dispatch, getState) => {
  dispatch({ type: actions.DELETING_COMPANY_USER, companyId, userId });
  data
    .delete(`v1/api/account/company/${companyId}/userroles/${userId}`)
    .then(response => {
      dispatch({
        type: actions.DELETED_COMPANY_USER,
        data: response.data,
        companyId,
        userId
      });
      dispatch(fetchCompanyUserRoles(companyId));
    })
    .catch(rejection => {
      dispatch({ type: actions.DELETE_COMPANY_USER_FAILED, companyId, userId });
      handlePromiseError(
        rejection,
        "TODO: Delete of company user failed.  Please refresh the page and try again." < "userrole"
      );
    });
};

// Fetches given company and falls back on oldest company
const getDefaultCompany = defaultCompanyId => (dispatch, getState) => {
  let accounts = getState().accounts.accounts;
  let defaultCompany;
  let companies = [];
  accounts.forEach(account => {
    account.companies.forEach(company => {
      if (company.companyId === defaultCompanyId) {
        defaultCompany = company;
      }
      companies.push(company);
    });
  });
  if (defaultCompany) return defaultCompany;
  companies.sort((a, b) => {
    return compareDates(a.createdDate, b.createdDate);
  });
  return companies[0] || {};
};

const getCompanyApprovers = companyId => (dispatch, getState) => {
  let approvers = {};
  ((dispatch(getDefaultCompany(companyId)) || {}).companyContacts || []).forEach(contact => {
    if (contact.isApprover) {
      contact.perspectives.forEach(perspectiveId => {
        if (isEmpty(approvers[perspectiveId])) {
          approvers[perspectiveId] = [contact];
        } else {
          approvers[perspectiveId].push(contact);
        }
      });
    }
  });

  return approvers;
};

const getCompanyContact = (companyId, companyContactId) => (dispatch, getState) => {
  return find((dispatch(getDefaultCompany(companyId)) || {}).companyContacts || [], contact => {
    contact.companyContactId = companyContactId;
  });
};

const createCompanyContact = (companyId, contact) => dispatch => {
  dispatch({ type: actions.CREATING_COMPANY_CONTACT });
  data
    .put(`v1/api/account/company/${companyId}/contacts`, contact)
    .then(response => {
      dispatch(fetchAccounts());
      dispatch({ type: actions.CREATED_COMPANY_CONTACT });
    })
    .catch(rejection => {
      dispatch({ type: actions.CREATE_COMPANY_CONTACT_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Creating the company contact failed.  Please refresh the page and try again.",
        "company contact"
      );
    });
};

const updateCompanyContact = (companyId, companyContactId, contact) => dispatch => {
  dispatch({ type: actions.UPDATING_COMPANY_CONTACT });
  data
    .post(`v1/api/account/company/${companyId}/contacts/${companyContactId}`, contact)
    .then(response => {
      dispatch(fetchAccounts());
      dispatch({ type: actions.UPDATED_COMPANY_CONTACT });
    })
    .catch(error => {
      dispatch({ type: actions.UPDATE_COMPANY_CONTACT_FAILED });
      if (error.response && error.response.status === 400) {
        if (error.response.data && error.response.data.companyContactId) {
          const message = error.response.data.companyContactId[0];
          handlePromiseError(error.response, message || "This approver is set as a default and cannot be deleted.");
        }
      } else {
        handlePromiseError(
          error,
          "TODO: Update of company contact failed.  Please refresh the page and try again.",
          "company contact"
        );
      }
    });
};

const deleteCompanyContact = (companyId, companyContactId) => dispatch => {
  dispatch({ type: actions.DELETING_COMPANY_CONTACT });
  data
    .delete(`v1/api/account/company/${companyId}/contacts/${companyContactId}`)
    .then(response => {
      dispatch(fetchAccounts());
      dispatch({ type: actions.DELETED_COMPANY_CONTACT });
    })
    .catch(rejection => {
      dispatch({ type: actions.DELETE_COMPANY_CONTACT_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Delete of company contact failed.  Please refresh the page and try again.",
        "company contact"
      );
    });
};

const selectAccount = accountId => dispatch => {
  dispatch({ type: actions.SELECT_ACCOUNT, accountId: accountId });
};

const fetchDropdownContent = () => dispatch => {
  data.get('v1/api/account/dropdown').then(response => {
    const dropdownContent = createDropdownData(response.data.account.companies, response.data.keyToAggregatedStatements);
    let primaryCompany = {};

    // In order to be able to accurately switch between a child company and viewing all accounts, we need to store the primary company. If the user
    // decides to view all accounts, we will need to set various state values that makes the currently selected company this primary company.
    if (response.data.account.companies.length > 0) {
      primaryCompany = response.data.account.companies[0];
    }

    dispatch({ type: actions.FETCH_DROPDOWN_CONTENT, data: { ...dropdownContent }, primaryCompany })
  })
}

// Iterate through companies and summaries (dictionary) matching values based on companyId,
// "___--" prefix is cross-currency-total
// "USD--" prefix for USD openInvoices
const createDropdownData = (companies, summaries) => {
  const combinedDropdownData = [];
  let longestCustomerName = 0;

  companies.map(comp => {
    let temp = {}
    let tempId = '___--'.concat(comp.companyId);

    if (longestCustomerName < comp.companyName.length && comp.companyName.length <= 69) {
      longestCustomerName = comp.companyName.length;
    }

    if (summaries[tempId]) {
        temp.value = comp.companyId
        temp.companyName = comp.companyName
        temp.openInvoiceCount = summaries[tempId].count;
    } else {
        temp.value = comp.companyId
        temp.companyName = comp.companyName
        temp.openInvoiceCount = 0;
    }

    let constructedRow = setDropdownRowValues(temp);
    combinedDropdownData.push(constructedRow);
  })

  const allAccountObj = {
    openInvoices: summaries['___--'].count,
    companyName: 'All Accounts',
    value: "ALL_ACCOUNTS_ID",
    displayName: 'All Accounts'
  }

  return { combinedDropdownData, longestCustomerName, allAccountObj };
}

const setDropdownRowValues = (company) => {
  return {
    value: company.value,
    displayName: company.companyName.length >= Resources.TruncateLimit ? capStringAtLength(company.companyName, Resources.TruncateLimit) : company.companyName,
    fullName: company.companyName,
    openInvoices: company.openInvoiceCount
  }
}

const updateDropdownSort = (updatedArray) => dispatch => {
  dispatch({type: actions.UPDATE_DROPDOWN_SORT, data: updatedArray})
}

export const dispatchToProps = dispatch => ({
  fetchAccounts: () => {
    dispatch(fetchAccounts());
  },
  
  fetchDropdownContent: () => {
    return dispatch(fetchDropdownContent());
  },

  updateDropdownSort: (updatedArray) => {
    return dispatch(updateDropdownSort(updatedArray));
  },

  assumeAccount: () => {
    dispatch(assumeAccount());
  },

  createAccount: accountName => {
    return dispatch(createAccount(accountName));
  },
  getAccount: accountId => {
    dispatch(getAccount(accountId));
  },
  updateAccount: account => {
    dispatch(updateAccount(account));
  },
  selectAccount: accountId => {
    dispatch(selectAccount(accountId));
  },

  fetchSubscription: () => {
    dispatch(fetchSubscription());
  },

  getSubscription: () => {
    return dispatch(getSubscription());
  },

  // TODO: add managment of account userroles
  // GET - /v1/api/account/{accountId}/userroles
  // PUT - /v1/api/account/{accountId}/userroles
  // POST - no way to change role for existing user but a user  can have multiple roles
  // DELETE - /v1/api/account/{accountId}/userroles/{userId}

  createAccountCompany: companyData => {
    return dispatch(createAccountCompany(companyData));
  },
  getCompany: companyId => {
    dispatch(getCompany(companyId));
  },
  updateCompany: company => {
    dispatch(updateCompany(company));
  },
  getDefaultCompany: defaultCompanyId => {
    return dispatch(getDefaultCompany(defaultCompanyId));
  },

  getCompanyContact: (companyId, companyContactId) => {
    return dispatch(getCompanyContact(companyId, companyContactId));
  },

  getCompanyApprovers: companyId => {
    return dispatch(getCompanyApprovers(companyId));
  },

  // TODO: add managment of company userroles
  fetchCompanyUserRoles: companyId => {
    return dispatch(fetchCompanyUserRoles(companyId));
  },
  getCompanyUserRoles: () => {
    return dispatch(getCompanyUserRoles());
  },
  getPerspectiveUserRoles: perspectiveId => {
    return dispatch(getPerspectiveUserRoles(perspectiveId));
  },
  putCompanyUserRole: (companyId, userId, role, perspectiveIds) => {
    dispatch(putCompanyUserRole(companyId, userId, role, perspectiveIds));
  },
  deleteCompanyUser: (companyId, userId) => {
    dispatch(deleteCompanyUser(companyId, userId));
  },

  createCompanyContact: (companyId, contact) => {
    dispatch(createCompanyContact(companyId, contact));
  },
  updateCompanyContact: (companyId, companyContactId, contact) => {
    dispatch(updateCompanyContact(companyId, companyContactId, contact));
  },
  deleteCompanyContact: (companyId, companyContactId) => {
    dispatch(deleteCompanyContact(companyId, companyContactId));
  }

  // TODO: add managment of company emails
  // GET - comes with getCompany results...
  // PUT  /v1/api/account/company/{companyId}/emails
  // POST - no way to edit state...can't make it "inactive"
  // DELETE - no way to remove an email from a company

  // TODO: add managment of company locations
  // GET - comes with getCompany results...
  // PUT - /v1/api/account/company/{companyId}/location
  // POST - /v1/api/account/company/{companyId}/location/{companyLocationId}
  //      - no way to set the isActive state...
  // DELETE - no way to remove a location
});
