import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Resources from "../../lib/resources";
import { isEmpty } from "../../lib/utils";

import { dispatchToProps as contDP } from "../../store/contextGroups-actions";
import { dispatchToProps as comDP } from "../../store/company-actions";
import { dispatchToProps as modDP } from "../../store/modal-actions";
import { dispatchToProps as manDP } from "../../store/manage-actions";

import Card from "../library/card";
import IconPlusCircle from "../library/icons/iconPlusCircle";
import IconCompany from "../library/icons/iconCompany";
import IconUser from "../library/icons/iconUser";
// import IconTrash from "../library/icons/iconTrash";
// import IconEdit from "../library/icons/iconEdit";
import MainLoader from "../mainLoader";

const dispatchToProps = dispatch => {
  return {
    ...dispatch(comDP),
    ...dispatch(contDP),
    ...dispatch(manDP),
    ...dispatch(modDP)
  };
};

class ContactInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addressBook: {}
    };

    this.fetchAddressBook = this.fetchAddressBook.bind(this);
  }

  componentDidMount() {
    this.tryUpdate();
    this.fetchAddressBook();
  }

  fetchAddressBook() {
    let { companyId } = this.props;
    this.props.fetchCompanyAddressBook(companyId, "contextGroupId%20=%20NULL").then(response => {
      if (!isEmpty((response || {}).perspectives)) {
        this.setState({
          addressBook: response.perspectives["00000000-0000-0000-0000-000000000000"][0].items.sort((a, b) =>
            a.isPrimary ? -1 : 1
          )
        });
      } else {
        this.setState({ addressBook: [] });
      }
    });
  }

  componentDidUpdate(prevProps) {
    this.tryUpdate(prevProps);
  }

  tryUpdate(prevProps) {
    const { perspectiveId, withCompanyId, companyStore, manageStore } = this.props;

    if (isEmpty(perspectiveId) || isEmpty(withCompanyId)) {
      return;
    }

    if (isEmpty(companyStore.fetchedCompanyInfo[withCompanyId])) {
      this.props.fetchCompanyInfo(withCompanyId);
    }

    if (isEmpty(prevProps)) {
      return;
    }

    let addressBookChanged =
      (manageStore.putAddressBookContact === true && prevProps.manageStore.putAddressBookContact === false) ||
      (manageStore.deletedCompanyAddressBookContact === true &&
        prevProps.manageStore.deletedCompanyAddressBookContact === false) ||
      (manageStore.postedAddressBookContact === true && prevProps.manageStore.postedAddressBookContact === false);

    if (addressBookChanged) {
      this.fetchAddressBook();
    }
  }

  renderAddContactButton() {
    return (
      <button
        data-test-id="contacts__button-add"
        className="button-primary"
        onClick={() => this.props.displayFlyout("contactFlyout", { companyId: this.props.companyId })}
      >
        <IconPlusCircle height="16" className="mr-2" />
        <span>{Resources.AddNewContact}</span>
      </button>
    );
  }

  emptyYourInformationState() {
    return (
      <div className="company-info-your-info-empty">
        <img alt="No information to show." src={"/img/contact-empty.svg"} />
        <span className="company-info-your-info-empty-header">{Resources.NoContactInfoYet}</span>
        <span className="company-info-your-info-empty-description mb-4">
          {Resources.AddYourInformationSoWeKnowBestWayToReachYou}
        </span>
        {/* {this.renderAddContactButton()} */}
      </div>
    );
  }

  renderContact(contact) {
    return (
      <React.Fragment>
        <div data-test-id="contacts__primary-icon" className="contact-info-item-primary-icon">
          {contact.type === "person" ? <IconUser height={21} /> : <IconCompany height={21} />}
        </div>
        <div className="company-info-item-content">
          <div className="company-info-item-content-header" data-test-id="contacts__header">
            <span>
              {contact.fullName}
              {!isEmpty(contact.title) && <span className="pl-2">- {contact.title}</span>}
              {contact.isPrimary && <span className="pl-2 contact-primary">{Resources.Primary}</span>}
            </span>
            {/* Below are buttons for editing of contacts */}
            {/* <span>
              <button
                className="button-action-icon"
                onClick={() => {
                  this.props.displayFlyout("contactFlyout", { companyId: this.props.companyId, contact });
                }}
              >
                <IconEdit height={20} />
              </button>
              <button
                className="button-action-icon"
                onClick={() => {
                  this.props.displayNotification("deleteContactNotification");
                  this.props.deleteCompanyAddressBookContact(this.props.companyId, contact.addressBookContactId);
                }}
              >
                <IconTrash height={20} />
              </button>
            </span> */}
          </div>

          <div className="company-info-item-content-attribute" data-test-id="contacts__email">
            {Resources.Email}: {contact.email}
          </div>
          <div className="company-info-item-content-attribute" data-test-id="phone">
            {Resources.Phone}: {contact.phone}
          </div>
          <div className="company-info-item-content-attribute" data-test-id="fax">
            {Resources.Fax}: {contact.fax}
          </div>
          <div className="company-info-item-content-attribute" data-test-id="contacts__address">
            {Resources.MailingAddress}:{" "}
            {`${contact.address.line1}${!isEmpty(contact.address.city) ? `, ${contact.address.city}` : ""}${
              !isEmpty(contact.address.region) ? `, ${contact.address.region}` : ""
            } ${contact.address.postalCode}`}
          </div>
          <div className="company-info-item-content-attribute" data-test-id="contacts__notes">
            {Resources.Notes}: {contact.notes}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    let { withCompanyId, companyStore } = this.props;
    //let { addressBook } = this.state;

    let withCompanyInfo = this.props.getCompanyInfo(withCompanyId);
    isEmpty(withCompanyInfo.Address) && (withCompanyInfo.Address = {});

    // let yourInfoContent;

    // if (isEmpty(addressBook)) {
    //   if (this.props.manageStore.isFetchingCompanyAddressBook) {
    //     yourInfoContent = <MainLoader fullScreen className="mt-5"></MainLoader>;
    //   } else {
    //     yourInfoContent = this.emptyYourInformationState();
    //   }
    // } else {
    //   yourInfoContent = (
    //     <React.Fragment>
    //       {/* Below is add contact button. Removed for read only of contact info on portal */}
    //       {/* <div className="company-info-your-info-header">{this.renderAddContactButton()}</div> */}
    //       {this.state.addressBook.map((contact, i) => {
    //         return (
    //           <div key={i} className="contact-info-item">
    //             {this.renderContact(contact)}
    //           </div>
    //         );
    //       })}
    //     </React.Fragment>
    //   );
    // }

    const helmet = (
      <Helmet>
        <title>
          {this.props.withCompanyName} | {Resources.Contacts}
        </title>
      </Helmet>
    );

    if (companyStore.gettingCompanyInfo[withCompanyId]) {
      return (
        <React.Fragment>
          {helmet}
          <MainLoader fullScreen className="portal-page-loader" />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {helmet}
        <Card
          data-test-id="contacts__contact-us"
          type="company-info-contact-us"
          className="company-info-contact-us"
          title={Resources.ContactUs}
          headAboveCard
        >
          <div className="contact-info-item contact-us-item">
            <div className="contact-info-item-primary-icon contact-us" data-test-id="contacts__contact-us__icon">
              <IconCompany height={21} />
            </div>
            <div className="company-info-item-content">
              <div className="company-info-item-content-header" data-test-id="contacts__contact-us__group">
                {this.props.getContextGroupNameFromCompanyId(withCompanyId)}
              </div>
              <div className="company-info-item-content-attribute" data-test-id="contacts__contact-us__email">
                {Resources.Email}: {(withCompanyInfo || {}).arEmail}
              </div>
              <div className="company-info-item-content-attribute" data-test-id="contacts__contact-us__phone">
                {Resources.Phone}: {(withCompanyInfo["Contact Numbers"] || {})["AR Phone"]}
              </div>
              <div className="company-info-item-content-attribute" data-test-id="contacts__contact-us__fax">
                {Resources.Fax}: {(withCompanyInfo["Contact Numbers"] || {})["AR Fax"] || null}
              </div>
              <div className="company-info-item-content-attribute" data-test-id="contacts__contact-us__address">
                {Resources.MailingAddress}:{" "}
                {`${withCompanyInfo.Address.Line1}${
                  !isEmpty(withCompanyInfo.Address.City) ? `, ${withCompanyInfo.Address.City}` : ""
                }${!isEmpty(withCompanyInfo.Address.Region) ? `, ${withCompanyInfo.Address.Region}` : ""} ${
                  withCompanyInfo.Address.PostalCode
                }`}
              </div>
              {!isEmpty((withCompanyInfo || {}).Notes) && (
                <div className="company-info-item-content-attribute" data-test-id="contacts__contact-us__notes">
                  {Resources.Notes}: {(withCompanyInfo || {}).Notes}
                </div>
              )}
            </div>
          </div>
        </Card>

        {/* Always hide 'your info' */}
        {/* <Card
            type="company-info-your-info"
            className="company-info-your-info"
            title={Resources.YourInformation}
            headAboveCard
          >
            {yourInfoContent}
          </Card> */}
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    companyStore: store.companies,
    manageStore: store.manage
  };
};

export default connect(storeToProps, dispatchToProps)(ContactInfo);
