import data from "../lib/data";
import { handlePromiseError } from "./error-actions";

import { actions as GeneralActions } from "./general-actions";
import Resources from "../lib/resources";
import { isEmpty, find } from "../lib/utils";
import { getCompanyPerspectives } from "./perspectives-actions";

export const actions = {
  ...GeneralActions,
  ...{
    GETTING_CONTEXT_GROUPS: "GETTING_CONTEXT_GROUPS",
    GOT_CONTEXT_GROUPS: "GOT_CONTEXT_GROUPS",
    GET_CONTEXT_GROUPS_FAILED: "GET_CONTEXT_GROUPS_FAILED",

    GETTING_CONTEXT_GROUPS_STATS: "GETTING_CONTEXT_GROUPS_STATS",
    GOT_CONTEXT_GROUPS_STATS: "GOT_CONTEXT_GROUPS_STATS",
    GET_CONTEXT_GROUPS_STATS_FAILED: "GET_CONTEXT_GROUPS_STATS_FAILED",

    UPDATE_PERSPECTIVE_CONTEXT_GROUPS_STATS: "UPDATE_PERSPECTIVE_CONTEXT_GROUPS_STATS",

    MOVING_CONVERSATION_TO_CONTEXT_GROUP: "MOVING_CONVERSATION_TO_CONTEXT_GROUP",
    MOVED_CONVERSATION_TO_CONTEXT_GROUP: "MOVED_CONVERSATION_TO_CONTEXT_GROUP",
    MOVE_CONVERSATION_TO_CONTEXT_GROUP_FAILED: "MOVE_CONVERSATION_TO_CONTEXT_GROUP_FAILED",

    BULK_MOVING_CONVERSATIONS_TO_CONTEXT_GROUP: "BULK_MOVING_CONVERSATIONS_TO_CONTEXT_GROUP",
    BULK_MOVED_CONVERSATIONS_TO_CONTEXT_GROUP: "BULK_MOVED_CONVERSATIONS_TO_CONTEXT_GROUP",
    BULK_MOVE_CONVERSATIONS_TO_CONTEXT_GROUP_FAILED: "BULK_MOVE_CONVERSATIONS_TO_CONTEXT_GROUP_FAILED",

    SET_CONTEXT_GROUPS_SORT_BY: "SET_CONTEXT_GROUPS_SORT_BY",
    SET_CONTEXT_GROUPS_SORT_DIRECTION: "SET_CONTEXT_GROUPS_SORT_DIRECTION",
    SET_CONTEXT_GROUPS_SORT: "SET_CONTEXT_GROUPS_SORT",

    POSTING_CONTEXT_GROUP: "POSTING_CONTEXT_GROUP",
    POSTED_CONTEXT_GROUP: "POSTED_CONTEXT_GROUP",
    POST_CONTEXT_GROUP_FAILED: "POST_CONTEXT_GROUP_FAILED"
  }
};

export const SortBy = {
  Name: "NAME",
  ActiveCount: "ACTIVE_COUNT",
  MostRecent: "MOST_RECENT",
  Hidden: "HIDDEN"
};

export const SortDirection = {
  Ascending: "ASC",
  Descending: "DESC"
};

export const fetchContextGroups = (companyId, perspectiveId) => (dispatch, getState) => {
  const store = getState().contextGroups;
  if (store.companyId === companyId && store.perspectiveId === perspectiveId && store.gettingContextGroups === true) {
    return;
  }

  dispatch({
    type: actions.GETTING_CONTEXT_GROUPS,
    companyId,
    perspectiveId
  });
  return data
    .get(`v2/api/conversation/for/${companyId}/${perspectiveId}/contextgroups`)
    .then(response => {
      dispatch({ type: actions.GOT_CONTEXT_GROUPS, companyId, perspectiveId, data: response.data });
      dispatch({
        type: actions.UPDATE_PERSPECTIVE_CONTEXT_GROUPS_STATS,
        perspectiveId,
        stats: computeStatsForContextGroups(response.data)
      });
    })
    .catch(response => {
      dispatch({ type: actions.GET_CONTEXT_GROUPS_FAILED, companyId, perspectiveId });
      if (
        getState().contextGroups.companyId === companyId &&
        getState().contextGroups.perspectiveId === perspectiveId
      ) {
        handlePromiseError(
          response,
          "TODO: Getting the contextGroups has failed.  Please refresh the page. If this continues please let us know.",
          "context groups"
        );
      }
    });
};

export const computeStatsForContextGroups = contextGroups => {
  let unassignedActive = 0;
  let mineActive = 0;
  let assignedActive = 0;
  let pendingApproval = 0;
  let waitingResponse = 0;
  let reminders = 0; //this.props.reminders.count || 0;
  let summaryCountsMap = {};
  let badgeTypesMap = {};

  for (let i = 0; i < contextGroups.length; i++) {
    let contextGroup = contextGroups[i];
    if (contextGroup.isSpam) {
      continue;
    }
    if (contextGroup.isHidden) {
      continue;
    }
    const ac = contextGroup.activeCounts || {};
    unassignedActive += ac.unassigned || 0;
    mineActive += ac.assignedToMe || 0;
    assignedActive += ac.assignedToOthers || 0;
    pendingApproval += ac.pendingApproval || 0;
    waitingResponse += ac.waitingForResponse || 0;

    let hasTasksForUser = (ac.unassigned || 0) + (ac.assignedToMe || 0) > 0;
    if (hasTasksForUser) {
      summaryCountsMap[contextGroup.companyLocalKey] = (ac.unassigned || 0) + (ac.assignedToMe || 0);
    } else {
      summaryCountsMap[contextGroup.companyLocalKey] =
        (ac.unassigned || 0) + (ac.assignedToMe || 0) + (ac.assignedToOthers || 0) + (ac.pendingApproval || 0);
    }

    badgeTypesMap[contextGroup.companyLocalKey] = hasTasksForUser ? "alert" : "info";
  }

  summaryCountsMap.mine = mineActive;
  summaryCountsMap.unassigned = unassignedActive;
  summaryCountsMap.assigned = assignedActive;
  summaryCountsMap["waiting-for-response"] = waitingResponse;
  summaryCountsMap["pending-approval"] = pendingApproval;
  summaryCountsMap.snoozed = reminders;
  summaryCountsMap.activities = mineActive + unassignedActive;

  badgeTypesMap.mine = "alert";
  badgeTypesMap.unassigned = "alert";
  badgeTypesMap.assigned = "info";
  badgeTypesMap["waiting-for-response"] = "info";
  badgeTypesMap["pending-approval"] = "info";
  badgeTypesMap.snoozed = "info";
  badgeTypesMap.activities = "alert";
  badgeTypesMap.all = "info";

  return {
    summaryCounts: summaryCountsMap,
    badgeTypes: badgeTypesMap,
    displayActiveIndicator: summaryCountsMap.activities > 0
  };
};

export const fetchContextGroupsStats = () => (dispatch, getState) => {
  const store = getState();
  let companyPerspectives = ((store.accounts.selectedAccount || {}).companies || [])
    .map(company => dispatch(getCompanyPerspectives(company.companyId)))
    .flat();
  let displayPerspectives = [];

  companyPerspectives.forEach(perspective => {
    if (!isEmpty(perspective) && !find(displayPerspectives, i => perspective.perspectiveId === i.perspectiveId)) {
      displayPerspectives.push(perspective);
    }
  });
  if (!displayPerspectives.reduce(perspective => typeof perspective !== "function")) {
    return;
  }

  dispatch({
    type: actions.GETTING_CONTEXT_GROUPS_STATS
  });
  let promises = Promise.all(
    displayPerspectives.map(perspective => {
      if (perspective.companyId && perspective.perspectiveId) {
        return data
          .get(`v2/api/conversation/for/${perspective.companyId}/${perspective.perspectiveId}/contextgroups`)
          .then(response => {
            const { summaryCounts, badgeTypes, displayActiveIndicator } = computeStatsForContextGroups(response.data);
            return {
              [perspective.perspectiveId]: {
                summaryCounts,
                badgeTypes,
                displayActiveIndicator
              }
            };
          });
      } else {
        return true;
      }
    })
  );

  return promises
    .then(responses => {
      let dataMap = {};
      responses.forEach(response => (dataMap = { ...dataMap, ...response }));
      dispatch({ type: actions.GOT_CONTEXT_GROUPS_STATS, data: dataMap });
    })
    .catch(response => {
      dispatch({ type: actions.GET_CONTEXT_GROUPS_STATS_FAILED });
      handlePromiseError(
        response,
        "TODO: Getting the contextGroups stats has failed.  Please refresh the page. If this continues please let us know.",
        "context groups"
      );
    });
};

export const getContextGroupsMaps = (companyId, perspectiveId) => (dispatch, getState) => {
  let state = getState().contextGroups;
  if (state.companyId === companyId && state.perspectiveId === perspectiveId && state.gotContextGroups === true) {
    return state.contextGroupsMaps;
  }

  if (state.gettingContextGroups === false && state.failedGetContextGroups === false) {
    dispatch(fetchContextGroups(companyId, perspectiveId));
  }

  return null;
};

export const moveConversationToContextGroup = (
  companyId,
  perspectiveId,
  contextGroupId,
  conversationId,
  remapIdentifiers
) => (dispatch, getState) => {
  dispatch({
    type: actions.MOVING_CONVERSATION_TO_CONTEXT_GROUP,
    companyId,
    perspectiveId,
    contextGroupId,
    conversationId
  });
  return data
    .put(
      `v2/api/conversation/for/${companyId}/${perspectiveId}/contextgroups/${contextGroupId}/move`,
      { conversationId, remapIdentifiers },
      {
        headers: { "Content-Type": "application/json" }
      }
    )
    .then(response => {
      dispatch({
        type: actions.MOVED_CONVERSATION_TO_CONTEXT_GROUP,
        companyId,
        perspectiveId,
        contextGroupId,
        conversationId,
        data: response.data
      });
      dispatch({ type: actions.GOT_CONTEXT_GROUPS, companyId, perspectiveId, data: response.data });
    })
    .catch(response => {
      dispatch({
        type: actions.MOVE_CONVERSATION_TO_CONTEXT_GROUP_FAILED,
        companyId,
        perspectiveId,
        contextGroupId,
        conversationId
      });
      handlePromiseError(
        response,
        "TODO: Moving the conversation has failed.  Please try again. If this continues please let us know.",
        "context groups"
      );
    });
};

export const bulkMoveConversationsToContextGroup = (
  companyId,
  perspectiveId,
  contextGroupId,
  conversationIds,
  remapIdentifiers
) => (dispatch, getState) => {
  dispatch({
    type: actions.BULK_MOVING_CONVERSATIONS_TO_CONTEXT_GROUP
  });
  return data
    .put(
      `v2/api/conversation/for/${companyId}/${perspectiveId}/contextgroups/${contextGroupId}/move/bulk`,
      { conversationIds, remapIdentifiers },
      {
        headers: { "Content-Type": "application/json" }
      }
    )
    .then(response => {
      dispatch({
        type: actions.BULK_MOVED_CONVERSATIONS_TO_CONTEXT_GROUP
      });
      dispatch({ type: actions.GOT_CONTEXT_GROUPS, companyId, perspectiveId, data: response.data });
    })
    .catch(response => {
      dispatch({
        type: actions.BULK_MOVE_CONVERSATIONS_TO_CONTEXT_GROUP_FAILED
      });
      handlePromiseError(
        response,
        "TODO: Moving the conversation has failed.  Please try again. If this continues please let us know.",
        "context groups"
      );
    });
};

const getContextGroupsSortByDisplayName = () => (dispatch, getState) => {
  let sortBy = getState().contextGroups.contextGroupsSortBy;
  let sortDirection = getState().contextGroups.contextGroupsSortDirection;

  switch (sortBy) {
    case SortBy.Name:
      switch (sortDirection) {
        case SortDirection.Ascending:
          return Resources.NameAscending;
        case SortDirection.Descending:
          return Resources.NameDescending;
        default:
          return "";
      }
    case SortBy.ActiveCount:
      switch (sortDirection) {
        case SortDirection.Ascending:
          return Resources.SmallestActiveCount;
        case SortDirection.Descending:
          return Resources.LargestActiveCount;
        default:
          return "";
      }
    case SortBy.MostRecent:
      switch (sortDirection) {
        case SortDirection.Ascending:
          return Resources.OldestToNewest;
        case SortDirection.Descending:
          return Resources.NewestToOldest;
        default:
          return "";
      }
    case SortBy.Hidden:
      return Resources.Hidden;
    default:
      return "";
  }
};

export const postContextGroup = (contextGroupId, displayName, isHidden) => (dispatch, getState) => {
  let state = getState().contextGroups;
  dispatch({
    type: actions.POSTING_CONTEXT_GROUP
  });
  let postContent = {};
  if (!isEmpty(displayName)) {
    postContent.displayName = displayName;
  }
  if (!isEmpty(isHidden)) {
    postContent.isHidden = isHidden;
  }

  return data
    .put(`v2/api/contextgroup/${contextGroupId}`, postContent)
    .then(response => {
      dispatch({
        type: actions.POSTED_CONTEXT_GROUP
      });
      return dispatch(fetchContextGroups(state.companyId, state.perspectiveId));
    })
    .catch(response => {
      dispatch({
        type: actions.POST_CONTEXT_GROUP_FAILED
      });
      handlePromiseError(
        response,
        "Updating the context group has failed. Please try again. If this continues please let us know.",
        "context groups"
      );
      return false;
    });
};

export const getContextGroupName = (contextGroupId, includeId = false, unknownValue = Resources.Unknown) => (
  dispatch,
  getState
) => {
  let state = getState().contextGroups;
  if (includeId) {
    return (state.contextGroupsMaps[contextGroupId] || {}).companyName || unknownValue;
  } else {
    return (state.contextGroupsMaps[contextGroupId] || {}).displayName || unknownValue;
  }
};

export const getContextGroupNameFromCompanyId = companyId => (dispatch, getState) => {
  let state = getState().contextGroups;
  let contextGroup = find(state.value, contextGroup => companyId === contextGroup.companyId) || {};
  return contextGroup.displayName || Resources.Unknown;
};

export const isCompanyNew = (companyId, perspective, currentCompanyId) => (dispatch, getState) => {
  let state = getState();
  if (isEmpty(companyId)) {
    return false;
  }

  if (isEmpty(state.contextGroups.contextGroupsMaps) && !isEmpty(currentCompanyId)) {
    dispatch(fetchContextGroups(currentCompanyId, perspective));
    return null;
  }

  return (find(state.contextGroups.value, contextGroup => companyId === contextGroup.companyId) || {}).isNew;
};

export const isContextGroupNew = (contextGroupId, perspective, currentCompanyId) => (dispatch, getState) => {
  let state = getState();
  if (isEmpty(contextGroupId)) {
    return false;
  }

  if (isEmpty(state.contextGroups.contextGroupsMaps) && !isEmpty(currentCompanyId)) {
    dispatch(fetchContextGroups(currentCompanyId, perspective));
    return null;
  }

  if (isEmpty(state.contextGroups.contextGroupsMaps[contextGroupId])) {
    return false;
  }

  return (state.contextGroups.contextGroupsMaps[contextGroupId] || {}).isNew;
};

export const dispatchToProps = dispatch => ({
  fetchContextGroups: (companyId, perspectiveId) => {
    return dispatch(fetchContextGroups(companyId, perspectiveId));
  },
  fetchContextGroupsStats: () => {
    return dispatch(fetchContextGroupsStats());
  },
  getContextGroupsMaps: (companyId, perspectiveId) => {
    return dispatch(getContextGroupsMaps(companyId, perspectiveId));
  },
  refreshContextGroups: (companyId, perspectiveId) => {
    return dispatch(fetchContextGroups(companyId, perspectiveId));
  },
  setContextGroupsSortBy: sortBy => {
    return dispatch({
      type: actions.SET_CONTEXT_GROUPS_SORT_BY,
      sortBy
    });
  },
  setContextGroupsSortDirection: sortDirection => {
    return dispatch({
      type: actions.SET_CONTEXT_GROUPS_SORT_DIRECTION,
      sortDirection
    });
  },
  setContextGroupsSort: (sortBy, sortDirection) => {
    return dispatch({
      type: actions.SET_CONTEXT_GROUPS_SORT,
      sortBy,
      sortDirection
    });
  },
  moveConversationToContextGroup: (companyId, perspectiveId, contextGroupId, conversationId, remapIdentifiers) => {
    return dispatch(
      moveConversationToContextGroup(companyId, perspectiveId, contextGroupId, conversationId, remapIdentifiers)
    );
  },
  bulkMoveConversationsToContextGroup: (
    companyId,
    perspectiveId,
    contextGroupId,
    conversationIds,
    remapIdentifiers
  ) => {
    return dispatch(
      bulkMoveConversationsToContextGroup(companyId, perspectiveId, contextGroupId, conversationIds, remapIdentifiers)
    );
  },
  getContextGroupsSortByDisplayName: () => {
    return dispatch(getContextGroupsSortByDisplayName());
  },
  postContextGroup: (contextGroupId, displayName, isHidden) => {
    return dispatch(postContextGroup(contextGroupId, displayName, isHidden));
  },
  getContextGroupName: (contextGroupId, includeId, unknownValue) => {
    return dispatch(getContextGroupName(contextGroupId, includeId, unknownValue));
  },
  getContextGroupNameFromCompanyId: companyId => {
    return dispatch(getContextGroupNameFromCompanyId(companyId));
  },
  hideContextGroup: contextGroupId => {
    return dispatch(postContextGroup(contextGroupId, "", true));
  },
  unhideContextGroup: contextGroupId => {
    return dispatch(postContextGroup(contextGroupId, "", false));
  },
  isCompanyNew: (companyId, perspective, currentCompanyId) => {
    return dispatch(isCompanyNew(companyId, perspective, currentCompanyId));
  },
  isContextGroupNew: contextGroupId => {
    return dispatch(isContextGroupNew(contextGroupId));
  }
});
