import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getUserConfig } from "../../../../store/user-actions";
import { deleteCommunicationTemplate } from "../../../../store/manage-actions";
import Resources from "../../../../lib/resources";
import IconClose from "../../../library/icons/iconClose";
import IconAngleLeft from "../../../library/icons/iconAngleLeft";
import IconPlusCircle from "../../../library/icons/iconPlusCircle";
import { SettingsViews } from "./settingsControllerFlyout";
import { getPartnerType } from "../../../../lib/perspectives";
import TableData from "../../../library/tableData";
import ReplyTemplateForm from "../../../replyTemplateForm";
import { displayNotification } from "../../../../store/modal-actions";
import useSWR, { mutate } from "swr";
import { swrFetcher } from "../../../../lib/data";
import Card from "../../../library/card";

const getDefaultPerspective = store => {
  const perspectives = store.perspectives.companyPerspectives;

  return (
    perspectives.find(({ perspectiveId }) => perspectiveId === store.user.userConfig.defaultPerspective) ||
    perspectives[0]
  );
};

const TemplateViews = {
  LIST: "list",
  EDIT: "edit",
  NEW: "new"
};

const Templates = ({ hideFlyout, setSettingsView }) => {
  const companyId = useSelector(store => store.accounts.selectedCompany.companyId);
  const perspectives = useSelector(store => store.perspectives.companyPerspectives);
  const defaultPerspective = useSelector(getDefaultPerspective);
  const dispatch = useDispatch();

  const [[templatesView, currentTemplate], setTemplatesView] = useState([TemplateViews.LIST, null]);
  const [selectedPerspective, setSelectedPerspective] = useState(defaultPerspective);

  const perspectiveId = selectedPerspective.perspectiveId;

  const templateDataKey = `v2/api/sequence/templates/${companyId}/${perspectiveId}`;
  const templates = useSWR(templateDataKey, swrFetcher);
  const gettingTemplates = templates.data === undefined;

  useEffect(() => {
    dispatch(getUserConfig());
  }, [dispatch]);

  switch (templatesView) {
    case TemplateViews.LIST:
      return (
        <React.Fragment>
          <div className="settings-flyout__header flyout-heading">
            <IconAngleLeft onClick={() => setSettingsView(SettingsViews.SETTINGS)} className="settings-flyout__back" />
            <h2>{Resources.Templates}</h2>
            <div onClick={hideFlyout} className="flyout-heading-close">
              <IconClose />
            </div>
          </div>

          <div className="view-content-header-links">
            {perspectives.map((perspective, i) => (
              <div
                className={`content-header-link ${selectedPerspective === perspective ? "active" : "clickable"}`}
                onClick={() => setSelectedPerspective(perspective)}
                key={i}
              >
                {getPartnerType(perspective.perspectiveName, true)}
              </div>
            ))}
          </div>
          <div className="nav-tabs mb-4" />

          <h5 style={{ margin: "2rem 0" }}>{Resources.CreateTemplateToSendReplies}</h5>
          <button
            onClick={() => setTemplatesView([TemplateViews.NEW, null])}
            className="button-primary bg-white"
            style={{ marginBottom: "2rem" }}
          >
            <IconPlusCircle height="20" className="button-primary-icon" />
            {Resources.AddTemplate}
          </button>

          <Card type="table">
            <TableData
              noScroll
              data={templates.data || []}
              loading={gettingTemplates}
              pagination
              onRowClick={template => setTemplatesView([TemplateViews.EDIT, template])}
              columns={[
                {
                  header: Resources.TemplateName.toLocaleUpperCase(),
                  width: "75%",
                  content: template => template.emailTemplateDesc
                },
                {
                  header: Resources.LastEdited.toLocaleUpperCase(),
                  content: ({ updateDate }) => moment(updateDate).format("MMM D, YYYY"),
                  width: "15%"
                },
                {
                  content: ({ emailTemplateKey }) => (
                    <button
                      className="crud-list-actions-delete"
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(deleteCommunicationTemplate(companyId, emailTemplateKey)).then(() =>
                          mutate(templateDataKey)
                        );
                        dispatch(displayNotification("deletingTemplate"));
                      }}
                    >
                      {Resources.Delete}
                    </button>
                  )
                }
              ]}
              rowHeight="4rem"
            />
          </Card>
        </React.Fragment>
      );
    case TemplateViews.EDIT:
      return (
        <React.Fragment>
          <div className="settings-flyout__header flyout-heading">
            <IconAngleLeft
              onClick={() => setTemplatesView([TemplateViews.LIST, null])}
              className="settings-flyout__back"
            />
            <h2>{Resources.EditTemplate}</h2>
            <div onClick={hideFlyout} className="flyout-heading-close">
              <IconClose />
            </div>
          </div>
          <ReplyTemplateForm
            templateData={currentTemplate}
            perspectiveId={perspectiveId}
            companyId={companyId}
            closeForm={() => setTemplatesView([TemplateViews.LIST, null])}
          />
        </React.Fragment>
      );
    case TemplateViews.NEW:
      return (
        <React.Fragment>
          <div className="settings-flyout__header flyout-heading">
            <IconAngleLeft
              onClick={() => setTemplatesView([TemplateViews.LIST, null])}
              className="settings-flyout__back"
            />
            <h2>{Resources.AddTemplate}</h2>
            <div onClick={hideFlyout} className="flyout-heading-close">
              <IconClose />
            </div>
          </div>
          <ReplyTemplateForm
            templateData={currentTemplate}
            perspectiveId={perspectiveId}
            companyId={companyId}
            closeForm={() => {
              setTemplatesView([TemplateViews.LIST, null]);
              mutate(templateDataKey);
            }}
          />
        </React.Fragment>
      );
    default:
      break;
  }
};

export default Templates;
