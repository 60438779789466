import React, { Component } from "react";
import { Switch, Route, generatePath, NavLink, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { formatDate, getNoDocumentsGraphic, isEmpty } from "../../../lib/utils";

import { dispatchToProps as networkDP } from "../../../store/network-actions";

import Card from "../../library/card";
import ContactsTable from "../contactsTable";
import TableData from "../../library/tableData";

import IconArchive from "../../library/icons/iconArchive";
import IconPhone from "../../library/icons/iconPhone";
import IconMapPin from "../../library/icons/iconMapPin";
import IconLinkedIn from "../../library/icons/iconLinkedIn";
import IconClipboard from "../../library/icons/iconClipboard";
import IconMail from "../../library/icons/iconMail";
import IconCheckCircle from "../../library/icons/iconCheckCircle";
import Dropdown from "../../library/dropdown";

import Resources from "../../../lib/resources";
import Avatar from "../../library/avatar";
import UserAvatar from "../../library/userAvatar";
import MainLoader from "../../mainLoader";

const dispatchToProps = dispatch => ({
  ...networkDP(dispatch)
});

class ProfileShareView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAccount: {},
      accountContacts: {},
      loadedTable: false,
      documents: [
        {
          type: 1,
          typeTitle: "",
          files: [],
          lastUpdated: "N/A"
        },
        {
          type: 2,
          files: [],
          lastUpdated: "N/A"
        },
        {
          type: 3,
          files: [],
          lastUpdated: "N/A"
        }
      ]
    };
  }

  componentDidMount = () => {
    if (this.props.match.params.type === "account") {
      this.props.fetchHeaderInfo(this.props.match.params.accessId);

      this.props.fetchCustAccount(this.props.match.params.accessId, this.props.match.params.mode).then(response => {
        let companyDetailsToggle = this.props.match.params.mode === "ar" ? "companyARDetails" : "companyAPDetails";
        this.setState({ selectedAccount: response[companyDetailsToggle] });
      });
      this.props
        .fetchCustAccountDocuments(this.props.match.params.accessId, this.props.match.params.mode)
        .then(() => {
          this.getCurrentDocs();
        });
    } else {
      this.props.fetchCustContact(this.props.match.params.accessId).then(() => {
        this.setState({ selectedAccount: this.props.networkStore.custContact });
      });
    }
  }

  handleLastUpdated = (row) => {
    if (row.files.length === 0) {
      return "N/A";
    } else {
      return formatDate(row.files[row.files.length - 1].updateDate);
    }
  }

  getCurrentDocs = () => {
    if (!this.state.loadedTable) {
      const docs = (this.props.networkStore.custAccountDocuments || {}).documents || [];
      let docsArray = [[], [], []];

      docs.map(currentDoc => {
        let currentType = currentDoc.documentTypeId;
        docsArray[currentType - 1].push(currentDoc);
        return null;
      });

      let newDocuments = [
        {
          type: 1,
          typeTitle:
            this.props.match.params.mode === "ar" || this.props.mode === "ar"
              ? "Bank verification documents"
              : "Credit application",
          files: docsArray[0],
          lastUpdated: "N/A"
        },
        {
          type: 2,
          typeTitle: this.props.match.params.mode === "ar" || this.props.mode === "ar" ? "W9" : "Proof of insurance",
          files: docsArray[1],
          lastUpdated: "N/A"
        },
        {
          type: 3,
          typeTitle:
            this.props.match.params.mode === "ar" || this.props.mode === "ar" ? "Other" : "Tax exemption certificate",
          files: docsArray[2],
          lastUpdated: "N/A"
        }
      ];

      this.setState({ documents: newDocuments });
      this.setState({ loadedTable: true });
    }
  }

  getInfoCard = () => {
    return (
      <div className="profile-container">
        <Card className="account-profile__card share-profile-card-centered">
          <div className="account-profile__card__last-updated">
            {Resources.UpdatedDate(formatDate(this.state.selectedAccount.profileUpdateDate))}
          </div>
          <div className="">
            <div className="account-profile-item">
              <div className="account-profile-item__icon">
                <IconMail height={18} />
              </div>
              <div className="account-profile-item__content">
                {(
                  this.props.networkStore.custAccount.accountingGroupARDetails ||
                  this.props.networkStore.custAccount.accountingGroupAPDetails ||
                  {}
                ).emailAddr || this.props.networkStore.custContact.emailAddr}
              </div>
            </div>
            {!isEmpty(this.state.selectedAccount.phone) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconPhone height={22} />
                </div>
                <div className="account-profile-item__content">{this.state.selectedAccount.phone}</div>
              </div>
            )}
            {!isEmpty(this.state.selectedAccount.fax) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconArchive height={16} />
                </div>
                <div className="account-profile-item__content">{this.state.selectedAccount.fax}</div>
              </div>
            )}
            {!isEmpty(
              [
                this.state.selectedAccount.mailToAddrLn1,
                this.state.selectedAccount.mailToCity,
                this.state.selectedAccount.mailToState,
                this.state.selectedAccount.mailToPostalcode,
                this.state.selectedAccount.mailToCountry
              ].filter(value => !isEmpty(value))
            ) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconMapPin height={22} />
                </div>
                <div className="account-profile-item__content">
                  <div>{this.state.selectedAccount.mailToAddrLn1}</div>
                  <div>
                    {this.state.selectedAccount.mailToCity}, {this.state.selectedAccount.mailToState}{" "}
                    {this.state.selectedAccount.mailToPostalcode}
                  </div>
                  <div>{this.state.selectedAccount.mailToCountry}</div>
                </div>
              </div>
            )}
            {!isEmpty(
              [
                this.state.selectedAccount.billToAddrLn1,
                this.state.selectedAccount.billToCity,
                this.state.selectedAccount.billToState,
                this.state.selectedAccount.billToPostalcode,
                this.state.selectedAccount.billToCountry
              ].filter(value => !isEmpty(value))
            ) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconMapPin height={22} />
                </div>
                <div className="account-profile-item__content">
                  {!!this.state.selectedAccount.shipToAddrLn1 && !!this.state.selectedAccount.billToAddrLn1 ? (
                    <div className="account-profile-item__header">{Resources.BillTo}</div>
                  ) : null}
                  <div>{this.state.selectedAccount.billToAddrLn1}</div>
                  <div>
                    {this.state.selectedAccount.billToCity}, {this.state.selectedAccount.billToState}{" "}
                    {this.state.selectedAccount.billToPostalcode}
                  </div>
                  <div>{this.state.selectedAccount.billToCountry}</div>
                </div>
              </div>
            )}
            {!isEmpty(
              [
                this.state.selectedAccount.shipToAddrLn1,
                this.state.selectedAccount.shipToCity,
                this.state.selectedAccount.shipToState,
                this.state.selectedAccount.shipToPostalcode,
                this.state.selectedAccount.shipToCountry
              ].filter(value => !isEmpty(value))
            ) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon" />
                <div className="account-profile-item__content">
                  {!!this.state.selectedAccount.shipToAddrLn1 && !!this.state.selectedAccount.billToAddrLn1 ? (
                    <div className="account-profile-item__header">{Resources.ShipTo}</div>
                  ) : null}
                  <div>{this.state.selectedAccount.shipToAddrLn1}</div>
                  <div>
                    {this.state.selectedAccount.shipToCity}, {this.state.selectedAccount.shipToState}{" "}
                    {this.state.selectedAccount.shipToPostalcode}
                  </div>
                  <div>{this.state.selectedAccount.shipToCountry}</div>
                </div>
              </div>
            )}
            {!isEmpty(this.state.selectedAccount.linkedInProfile) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconLinkedIn height={19} />
                </div>
                <div className="account-profile-item__content">{this.state.selectedAccount.linkedInProfile}</div>
              </div>
            )}
            {!isEmpty(this.state.selectedAccount.notes) && (
              <div className="account-profile-item">
                <div className="account-profile-item__icon">
                  <IconClipboard height={20} />
                </div>
                <div className="account-profile-item__content">{this.state.selectedAccount.notes}</div>
              </div>
            )}
          </div>
        </Card>
      </div>
    );
  }

  getLockstepHeader = () => {
    return (
      <div className="profile-update-header-lockstep-box">
        <span className="mr-1">connected by</span>
        <svg viewBox="0 0 700.88 200.32" height="20" className="lockstep-logo ">
          <path
            fill="#7d4ed8"
            d="M52.002526 28.708259l36.07 36.16 24.02-24.02 12.32 12.31-36.34 36.34-48.39-48.48z"
          />
          <path
            fill="#ef3c6b"
            d="M51.892526 101.048259l-24.02-24.02 12.32-12.32 36.33 36.34-48.48 48.39-12.31-12.32z"
          />
          <path
            fill="#1fb6c1"
            d="M63.252526 160.588259l-12.32-12.31 36.34-36.34 48.39 48.48-12.32 12.31-36.07-36.16z"
          />
          <path
            fill="#f38923"
            d="M147.302526 51.998259l12.31 12.32-36.17 36.07 24.03 24.02-12.32 12.32-36.33-36.34z"
          />
          <g className="lockstep-logo-text ">
            <path
              fill="currentColor"
              d="M203.712526 76.658259h17.63v37.68h24.79v15.07h-42.42zM255.632526 103.188259v-.15c0-15.3 12.58-27.5 28.86-27.5s28.71 12.06 28.71 27.35v.15c0 15.3-12.58 27.5-28.86 27.5s-28.71-12.06-28.71-27.35zm39.79 0v-.15c0-6.25-4.3-11.91-11.08-11.91-6.71 0-10.93 5.58-10.93 11.76v.15c0 6.25 4.29 11.91 11.08 11.91 6.71-.01 10.93-5.58 10.93-11.76zM323.982526 103.188259v-.15c0-15.75 12.28-27.5 28.03-27.5 11.6 0 19.44 5.65 23.89 13.71l-14.54 8.52c-1.96-3.77-4.75-6.4-9.57-6.4-5.95 0-9.87 5.2-9.87 11.53v.15c0 6.86 4.07 11.68 9.87 11.68 4.97 0 7.76-2.71 9.95-6.63l14.54 8.21c-4.45 7.84-11.83 14.24-24.94 14.24-14.62-.01-27.36-11.01-27.36-27.36zM388.722526 76.658259h17.63v19.74l15-19.74h20.5l-18.39 22.91 18.76 29.84h-21.03l-10.32-16.73-4.52 5.5v11.23h-17.63zM449.912526 121.498259l9.42-11.23c5.73 4.37 12.28 6.18 18.46 6.18 3.17 0 4.52-.83 4.52-2.26v-.15c0-1.51-1.66-2.34-7.31-3.47-11.83-2.41-22.23-5.8-22.23-16.95v-.15c0-10.02 7.84-17.78 22.38-17.78 10.17 0 17.71 2.41 23.81 7.23l-8.59 11.91c-4.97-3.62-10.93-5.2-15.82-5.2-2.64 0-3.84.9-3.84 2.19v.15c0 1.43 1.43 2.34 7.01 3.39 13.49 2.49 22.53 6.41 22.53 17.03v.15c0 11.08-9.12 17.86-23.28 17.86-10.71-.01-20.35-3.02-27.06-8.9zM526.102526 91.578259h-15.52v-14.92h48.68v14.92h-15.52v37.83h-17.63zM572.522526 76.658259h44.99v14.47h-27.66v5.35h25.77v12.74h-25.77v5.73h28.03v14.47h-45.36zM632.812526 76.658259h23.74c14.02 0 23.59 6.25 23.59 18.76v.15c0 12.74-9.72 19.52-23.96 19.52h-5.73v14.32h-17.63v-52.75zm22.38 25.85c4.52 0 7.54-2.03 7.54-5.8v-.15c0-3.84-2.79-5.8-7.46-5.8h-4.82v11.76z"
            />
          </g>
        </svg>
      </div>
    );
  }

  render() {
    let links = [
      { subView: "profile", display: Resources.Profile.toLocaleUpperCase() },
      { subView: "documents", display: Resources.Documents.toLocaleUpperCase() }
    ];

    let documentColumns = [
      {
        header: Resources.DocumentType.toLocaleUpperCase(),
        content: row => (
          <div className="profile-update-card-table-documentpadding">
            <span className="profile-update-card-table-name">{row.typeTitle}</span>
          </div>
        ),
        width: "30%"
      },
      {
        header: Resources.File.toLocaleUpperCase(),
        content: row =>
          row.files.map((file, index) => (
            <p className="document-form-uploaded-file document-list" key={index}>
              <a href={file.documentURL}>{file.fileName}</a>
            </p>
          )),
        width: "30%"
      },
      {
        header: Resources.LastUpdated.toLocaleUpperCase(),
        content: row => this.handleLastUpdated(row),
        width: "10%"
      },
      { content: row => null, width: "0%" }
    ];

    let documents = [];

    if (this.props.networkStore.custAccountDocuments && this.props.networkStore.custAccountDocuments.documents) {
      documents = this.props.networkStore.custAccountDocuments.documents;
    }

    return (
      <React.Fragment>
        <Switch>
          <Route path="/v2/profile/contact/:mode/:accessId/">
            {this.props.networkStore.gettingCustContact ? (
              <MainLoader fullScreen={true} />
            ) : (
              <React.Fragment>
                <div className="profile-update-view-header __share-contacts">
                  <div className="profile-update-view-header-container">
                    <div className="profile-update-view-header-picture-container">
                      {1 > 0 ? (
                        <Avatar
                          height={"4rem"}
                          imgURL={this.props.networkStore.custContact.profilePictureURL}
                          objectName={"TODO"}
                          className={"avatar"}
                        />
                      ) : (
                        <UserAvatar height={25} />
                      )}
                    </div>

                    <div className="profile-update-header-text-box">
                      <h2>{this.props.networkStore.custContact.contactName}</h2>
                    </div>

                    {this.getLockstepHeader()}

                    <div className="profile-update-header-checkbox-container">
                      {this.props.networkStore.custContact.isLockstepVerified ? (
                        <React.Fragment>
                          <div className="lockstep-verified-checkmark-container">
                            <Dropdown
                              buttonContent={
                                <React.Fragment>
                                  <IconCheckCircle height={14} className="lockstep-verified-checkmark-icon" />
                                </React.Fragment>
                              }
                            >
                              <div className="lockstep-verified-dropdown-content">
                                {Resources.LockstepVerifiedInformation}
                              </div>
                            </Dropdown>
                          </div>
                        </React.Fragment>
                      ) : null}
                    </div>
                  </div>
                </div>

                {this.getInfoCard()}
              </React.Fragment>
            )}
          </Route>
          {this.props.networkStore.gotCustAccount ? (
            <Route path="/v2/profile/account/:mode/:accessId/">
              <div className="profile-update-view-header __nav-bar">
                <div className="profile-update-view-header-container">
                  <div className="profile-update-view-header-picture-container">
                    {1 > 0 ? (
                      <Avatar
                        height={"4rem"}
                        imgURL={this.props.networkStore.headerInfo.LogoURL}
                        objectName={"TODO"}
                        className={"avatar"}
                      />
                    ) : (
                      <UserAvatar height={25} />
                    )}
                  </div>

                  <div className="profile-update-header-text-box">
                    <h2>{this.props.networkStore.headerInfo.AccountName}</h2>
                  </div>
                </div>
                {this.getLockstepHeader()}
                <div className="profile-update-header-links">
                  {links && (
                    <div className="view-content-header-links">
                      {links.map(link => (
                        <NavLink
                          className="content-header-link"
                          to={generatePath("/v2/profile/:type/:mode/:accessId/:view", {
                            type: this.props.match.params.type,
                            mode: this.props.match.params.mode,
                            accessId: this.props.match.params.accessId,
                            view: link.subView
                          })}
                          key={link.subView}
                          replace
                        >
                          {link.display}
                        </NavLink>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <Redirect
                to={generatePath("/v2/profile/account/:mode/:accessId/profile", {
                  type: this.props.match.params.type,
                  mode: this.props.match.params.mode,
                  accessId: this.props.match.params.accessId
                })}
              />
              <Switch>
                <Route path="/v2/profile/account/:mode/:accessId/profile">{this.getInfoCard()}</Route>
                <Route path="/v2/profile/:type/:mode/:accessId/contacts">
                  <Card className={"first-time-admin-contacts-table-card"}>
                    <ContactsTable
                      name="shared-profile__contacts-table"
                      data={this.state.accountContacts.contacts}
                      loading={this.props.networkStore.gettingCustAccountContacts}
                      maxRows={this.props.networkStore.custAccountContacts.count}
                      selectedKeys={this.state.selectedContactKeys}
                      rowKey="entityGlobalKey"
                      noCompany
                      noClick
                      editMode={this.state.editMode}
                      pagination={false}
                      deleteContact={(key, contact) => this.deleteContact(key, contact)}
                      deletedContactKeys={this.state.deletedContactKeys}
                    />
                  </Card>
                </Route>
                <Route path="/v2/profile/:type/:mode/:accessId/documents">
                  <div className="profile-share-view-documents-container">
                    <Card className="card-table">
                      {
                        documents.length > 0 ?
                          <TableData
                            name="shared-profile__documents-table"
                            data={this.state.documents}
                            columns={documentColumns}
                            pagination
                          /> :
                          <Card className="documents-empty-state">
                            <svg viewBox="0 0 268 163" className="mt-4">
                              {getNoDocumentsGraphic()}
                            </svg>
                            <h2>{Resources.NothingToSeeHere}</h2>
                            <p>{Resources.ConnectionHasNoDocuments}</p>
                          </Card>
                      }
                    </Card>
                  </div>
                </Route>
              </Switch>
            </Route>
          ) : (
            <MainLoader fullScreen={true} />
          )}
        </Switch>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ProfileShareView));
