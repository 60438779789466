import data from "../lib/data";
import { actions as GeneralActions } from "./general-actions";
import { handlePromiseError } from "./error-actions";
import { isEmpty, find } from "../lib/utils";

export const actions = {
  ...GeneralActions,

  SET_SELECTED_PERSPECTIVE_ID: "SET_SELECTED_PERSPECTIVE_ID",
  FETCHING_PERSPECTIVES: "FETCHING_PERSPECTIVES",
  FETCHED_PERSPECTIVES: "FETCHED_PERSPECTIVES",
  FETCH_PERSPECTIVES_FAILED: "FETCH_PERSPECTIVES_FAILED",

  FETCHING_COMPANY_PERSPECTIVES: "FETCHING_COMPANY_PERSPECTIVES",
  FETCHED_COMPANY_PERSPECTIVES: "FETCHED_COMPANY_PERSPECTIVES",
  FETCH_COMPANY_PERSPECTIVES_FAILED: "FETCH_COMPANY_PERSPECTIVES_FAILED",

  CREATING_COMPANY_PERSPECTIVE: "CREATING_COMPANY_PERSPECTIVE",
  CREATED_COMPANY_PERSPECTIVE: "CREATED_COMPANY_PERSPECTIVE",
  CREATE_COMPANY_PERSPECTIVE_FAILED: "CREATE_COMPANY_PERSPECTIVE_FAILED",

  DELETING_COMPANY_PERSPECTIVE: "DELETING_COMPANY_PERSPECTIVE",
  DELETED_COMPANY_PERSPECTIVE: "DELETED_COMPANY_PERSPECTIVE",
  FAILED_DELETE_COMPANY_PERSPECTIVE: "FAILED_DELETE_COMPANY_PERSPECTIVE",

  UPDATED_COMPANY_PERSPECTIVE: "UPDATED_COMPANY_PERSPECTIVE"
};

const fetchPerspectives = () => dispatch => {
  dispatch({ type: actions.FETCHING_PERSPECTIVES });
  return data
    .get("v1/api/perspective")
    .then(response => {
      dispatch({
        type: actions.FETCHED_PERSPECTIVES,
        perspectives: response.data
      });
      return response.data;
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_PERSPECTIVES_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Fetching perspectives failed.  Please refresh the page and try again.",
        "perspectives"
      );
    });
};

const fetchCompanyPerspectives = companyId => (dispatch, getState) => {
  if (getState().perspectives.isFetchingCompanyPerspectives) {
    return;
  }
  dispatch({ type: actions.FETCHING_COMPANY_PERSPECTIVES });
  data
    .get(`v1/api/perspective/${companyId}`)
    .then(response => {
      dispatch({
        type: actions.FETCHED_COMPANY_PERSPECTIVES,
        companyPerspectives: response.data
      });
      // return response.data
    })
    .catch(rejection => {
      dispatch({ type: actions.FETCH_COMPANY_PERSPECTIVES_FAILED });
      handlePromiseError(
        rejection,
        "TODO: Fetching company perspectives failed.  Please refresh the page and try again.",
        "company workspaces"
      );
    });
};

const deleteCompanyPerspective = (companyId, perspectiveId) => (dispatch, getState) => {
  dispatch({ type: actions.DELETING_COMPANY_PERSPECTIVE });
  data
    .delete(`v1/api/perspective/${companyId}/${perspectiveId}`)
    .then(response => {
      dispatch({
        type: actions.DELETED_COMPANY_PERSPECTIVE,
        companyPerspectives: response.data
      });
    })
    .catch(rejection => {
      dispatch({ type: actions.FAILED_DELETE_COMPANY_PERSPECTIVE });
      handlePromiseError(
        rejection,
        "TODO: deleting company perspective failed.  Please refresh the page and try again.",
        "company workspaces"
      );
    });
};

export const getCompanyPerspectives = companyId => (dispatch, getState) => {
  const { fetchingCompanyPerspectivesFailed, companyPerspectives } = getState().perspectives;
  if (fetchingCompanyPerspectivesFailed === null) {
    dispatch(fetchCompanyPerspectives(companyId));
    return null;
  }
  return companyPerspectives;
};

const updateCompanyPerspective = (perspectiveId, payload) => dispatch => {
  dispatch({ type: actions.UPDATED_COMPANY_PERSPECTIVE, perspectiveId, payload });
};

const createCompanyPerspective = (companyId, payload) => (dispatch, getState) => {
  if (getState().isCreatingCompanyPerspective) {
    return;
  }

  dispatch({ type: actions.CREATING_COMPANY_PERSPECTIVE });
  return data
    .post(`v1/api/perspective/${companyId}`, payload)
    .then(response => {
      dispatch({
        type: actions.CREATED_COMPANY_PERSPECTIVE,
        companyPerspectives: response.data
      });
      return response.data;
    })
    .catch(error => {
      dispatch({ type: actions.CREATE_COMPANY_PERSPECTIVE_FAILED });
      if (
        (((error.response || {}).data || {}).PerspectiveName || [])[0] ===
        "The provided PerspectiveName already exists."
      ) {
        handlePromiseError(
          error,
          "TODO: The provided workspace name already exists. Please provide a unique workspace name and try again."
        );
      } else if (
        ((((error.response || {}).data || {}).errors || {}).PerspectiveName || [])[0] ===
        "The PerspectiveName field is required."
      ) {
        handlePromiseError(
          error,
          "TODO: The workspace name field is required. Please provide a unique workspace name and try again."
        );
      } else {
        handlePromiseError(
          error,
          "TODO: Creating the workspace failed. Please refresh the page and try again.",
          "company workspace"
        );
      }
    });
};

const getCompanyPerspectiveById = (companyPerspectiveId, companyId) => (dispatch, getState) => {
  const { fetchingCompanyPerspectivesFailed, companyPerspectives } = getState().perspectives;
  if (fetchingCompanyPerspectivesFailed === null) {
    dispatch(fetchCompanyPerspectives(companyId));
    return null;
  }
  if (isEmpty(companyPerspectiveId)) {
    return null;
  }
  return find(companyPerspectives, perspective => {
    return perspective.perspectiveId === companyPerspectiveId;
  });
};

const getPerspectiveById = perspectiveId => (dispatch, getState) => {
  const { perspectives, fetchingPerspectives } = getState().perspectives;
  if (isEmpty(perspectives) && fetchingPerspectives !== true) {
    dispatch(fetchPerspectives());
  }

  return find(perspectives, p => p.perspectiveId === perspectiveId) || {};
};

const getPerspectiveByName = perspectiveName => (dispatch, getState) => {
  const { perspectives, fetchingPerspectives } = getState().perspectives;
  if (isEmpty(perspectives) && fetchingPerspectives !== true) {
    dispatch(fetchPerspectives());
  }

  return find(perspectives, p => p.perspectiveName === perspectiveName) || {};
};

export const dispatchToProps = dispatch => ({
  setSelectedPerspectiveId: perspectiveId => {
    dispatch({ type: actions.SET_SELECTED_PERSPECTIVE_ID, perspectiveId });
  },
  fetchPerspectives: () => {
    return dispatch(fetchPerspectives());
  },
  deleteCompanyPerspective: (companyId, perspectiveId) => {
    return dispatch(deleteCompanyPerspective(companyId, perspectiveId));
  },
  fetchCompanyPerspectives: companyId => {
    dispatch(fetchCompanyPerspectives(companyId));
  },
  getCompanyPerspectives: companyId => {
    return dispatch(getCompanyPerspectives(companyId));
  },
  getCompanyPerspectiveById: (companyPerspectiveId, companyId) => {
    return dispatch(getCompanyPerspectiveById(companyPerspectiveId, companyId));
  },
  createCompanyPerspective: (companyId, payload) => {
    return dispatch(createCompanyPerspective(companyId, payload));
  },
  updateCompanyPerspective: (perspectiveId, payload) => {
    return dispatch(updateCompanyPerspective(perspectiveId, payload));
  },

  getPerspectiveById: perspectiveId => {
    return dispatch(getPerspectiveById(perspectiveId));
  },
  getPerspectiveByName: perspectiveName => {
    return dispatch(getPerspectiveByName(perspectiveName));
  }
});
