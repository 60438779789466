import React, { Component } from "react";
import Resources from "../../lib/resources";
import { isEmpty, getCurrencySymbol } from "../../lib/utils";
import IconAlertCircle from "./icons/iconAlertCircle";

export default class TextInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      isFocused: false
    };

  }

  toggleVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  onFocus = (e) => {
    this.setState({ isFocused: true });
    if (!isEmpty(this.props.onFocus)) {
      this.props.onFocus(e);
    }
  }

  onBlur = (e) => {
    this.setState({ isFocused: false });
    if (!isEmpty(this.props.onBlur)) {
      this.props.onBlur(e);
    }
  }

  render() {
    let {
      isPassword,
      id,
      inputOnChange,
      onPressEnter,
      textValue = "",
      label,
      labelInline = true,
      width,
      disabled,
      inline,
      inputRef,
      className,
      centerText,
      placeholder,
      fsObscure,
      isValid = true,
      invalidText = "",
      dollarAmountInput,
      style,
      autocomplete = true
    } = this.props;

    if (dollarAmountInput) {
      placeholder = "0.00";
    }

    let type = "text";

    if (this.props.isPassword && !this.state.showPassword) {
      type = "password";
    }
    if (this.props.isNumber) {
      type = "number";
    }

    return (
      <div
        className={`text-input-container ${inline ? " d-inline-block" : ""} ${className ? className : ""}`}
        style={style || {}}
      >
        <div style={{ width: width }} className={labelInline ? "flex-align-center relative" : "relative"}>
          {textValue && isPassword ? (
            <span className="pw-toggle" onClick={this.toggleVisibility}>
              {this.state.showPassword ? Resources.Hide : Resources.Show}
            </span>
          ) : null}
          {label ? (
            <label htmlFor={id} className={`input-label ${this.state.isFocused ? " focused" : ""}`}>
              {label}
            </label>
          ) : null}
          {dollarAmountInput && <div className="mr-1">{getCurrencySymbol(this.props.currencyId)}</div>}
          <input
            ref={inputRef || null}
            disabled={disabled}
            type={type}
            className={`text-input focusable ${dollarAmountInput ? "dollar-amount-input" : ""}${
              isPassword || fsObscure ? "fs-obscure" : ""
            }${centerText ? "text-center" : ""}${isValid || isEmpty(textValue) ? "" : "input-invalid"}`}
            id={id}            
            value={textValue !== undefined ? textValue || "" : textValue}
            onChange={inputOnChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            autoComplete={autocomplete ? "on" : "chrome-off"}
            onKeyDown={e => {
              if (e.key === "Enter" && typeof onPressEnter === "function") {
                onPressEnter();
              }
            }}
            placeholder={placeholder}
            name={id || ""}
          />
        </div>
        {!isValid && !isEmpty(textValue) && (
          <div className="input-invalid-text">
            <div>
              <IconAlertCircle height={14} className="input-invalid-icon" />
              {invalidText}
            </div>
          </div>
        )}
      </div>
    );
  }
}
