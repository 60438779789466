import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-modal";
import data from "../lib/data";

import { dispatchToProps } from "../store/modal-actions";

import Resources from "../lib/resources";

import TextInput from "./library/textInput";
import { generateGUID } from "../lib/utils";

class AffinipayModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ""
    };
  }
  redirectMe() {
    window.location.replace("http://www.google.com");
  }
  postAffinipay() {
    data.post(`v2/api/payments/providers/affinipay/onboarding`, {
      reference: generateGUID(),
      email: this.state.email,
      plan: "affinipay_std"
    });
    /*.then(response => {
        this.redirectMe();
      });*/
  }
  render() {
    return (
      <div>
        {"Please enter your email address to begin onboarding with Affinipay."}
        <TextInput
          className="mb-1"
          width="19em"
          label={Resources.EmailAddress}
          placeholder={Resources.EmailAddress}
          textValue={this.state.email}
          id="perspective-affinipay-email"
          labelInline={false}
          inputOnChange={e => {
            this.setState({ email: e.target.value });
          }}
        />
        <button
          className="button-primary-square mt-4"
          style={{ minWidth: "14rem" }}
          onClick={() => this.postAffinipay()}
        >
          {Resources.Submit}
        </button>
      </div>
    );
  }
}

const storeToProps = store => {
  return {
    modal: store.modal
  };
};

export default connect(storeToProps, dispatchToProps)(AffinipayModal);
