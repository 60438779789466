import React, { Component } from "react";
import { postUserUpdate } from "../../../../store/user-actions";
import { dispatchToProps as modDP } from "../../../../store/modal-actions";
import { useDispatch } from "react-redux";
import Resources from "../../../../lib/resources";
import { isEmpty } from "../../../../lib/utils";

import TextInput from "./../../../library/textInput";
import { connect } from "react-redux";

import IconAngleLeft from "../../../library/icons/iconAngleLeft";
import { SettingsViews } from "./settingsControllerFlyout";
import IconClose from "../../../library/icons/iconClose";
import MainLoader from "../../../mainLoader";

/*function SignInSecurity(props) {
  const dispatch = useDispatch();
  //return <button onClick={() => dispatch(postUserUpdate())}>test</button>;
}
export default SignInSecurity;*/

const dispatchToProps = dispatch => {
  return {
    postUserUpdate: config => dispatch(postUserUpdate(config)),
    ...modDP(dispatch)
  };
};

class SignInSecurity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      oldPassword: "",
      confirmPassword: ""
    };

    this.save = this.save.bind(this);
  }

  save() {
    const { oldPassword, newPassword } = this.state;
    this.props.postUserUpdate({ oldPassword, newPassword, userName: this.props.userName });
  }

  render() {
    return (
      <React.Fragment>
        <div className="settings-flyout__header flyout-heading">
          <IconAngleLeft
            onClick={() => this.props.setSettingsView(SettingsViews.SETTINGS)}
            className="settings-flyout__back"
          />
          <h2>{Resources.SignInAndSecurity}</h2>
          <div onClick={this.props.hideFlyout} className="flyout-heading-close">
            <IconClose />
          </div>
        </div>

        <div className="manage-content">
          <div>
            <label className="notifications-subtext">
              {Resources.EmailAddressRegistered}
              {this.props.userLoginEmail}. {Resources.ToUpdateEmail}
              <button
                className="button-info underline"
                onClick={() => {
                  this.props.displayFlyout("myProfileView", {
                    myProfile: this.props.accountsStore.selectedAccount,
                    companyId: this.props.modalStore.flyoutProps.companyId,
                    perspectiveId: this.props.modalStore.flyoutProps.perspectiveId
                  });
                }}
              >
                {Resources.MyProfile.toLocaleLowerCase()}.
              </button>
            </label>
            {this.props.updatingUserStatus === "fetching" && <MainLoader className="mt-5" fullScreen />}
            {this.props.updatingUserStatus !== "fetching" && (
              <React.Fragment>
                <TextInput
                  labelInline={false}
                  textValue={this.state.oldPassword}
                  fsObscure
                  isPassword={true}
                  className="mt-3"
                  width="19em"
                  label={Resources.Password}
                  id="security-current-password"
                  inputOnChange={e => {
                    this.setState({ oldPassword: e.target.value });
                  }}
                />
                <TextInput
                  labelInline={false}
                  textValue={this.state.newPassword}
                  fsObscure
                  isPassword={true}
                  className="mt-3"
                  width="19em"
                  label={Resources.NewPassword}
                  id="security-new-password"
                  inputOnChange={e => {
                    this.setState({ newPassword: e.target.value });
                  }}
                />
                <TextInput
                  labelInline={false}
                  textValue={this.state.confirmPassword}
                  fsObscure
                  isPassword={true}
                  className="mt-3"
                  width="19em"
                  label={Resources.ReenterPassword}
                  id="security-reenter-password"
                  inputOnChange={e => {
                    this.setState({ confirmPassword: e.target.value });
                  }}
                />

                <div className="flex-row mt-4">
                  <button
                    className="button-primary-square notifications-button"
                    onClick={this.save}
                    disabled={
                      isEmpty(this.state.oldPassword) ||
                      isEmpty(this.state.newPassword) ||
                      this.state.newPassword !== this.state.confirmPassword
                    }
                  >
                    {Resources.Save}
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const storeToProps = store => {
  return {
    accountsStore: store.accounts,
    modalStore: store.modal,
    userLoginEmail: store.user.decoded.email,
    userName: store.user.decoded.preferred_username,
    updatingUserStatus: store.user.updatingUserStatus
  };
};

export default connect(storeToProps, dispatchToProps)(SignInSecurity);
