import { Card } from "reactstrap";
import { generatePath, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import React, { Component } from "react";
import { dispatchToProps as networkDP } from "../../../store/network-actions";
import { dispatchToProps as moDP } from "../../../store/modal-actions";

import TableData from "../../library/tableData";
import IconUpload from "../../library/icons/iconUpload";
import Resources from "../../../lib/resources";
import IconTrash from "../../library/icons/iconTrash";
import MainLoader from "../../mainLoader";
import { formatDate } from "../../../lib/utils";

const dispatchToProps = dispatch => ({
  ...networkDP(dispatch),
  ...moDP(dispatch)
});

class DocumentUploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDocumentType: "",
      loadedTable: false,
      updated: true,
      editMode: true,
      documents: [
        {
          type: 1,
          typeTitle: "",
          files: [],
          lastUpdated: "N/A"
        },
        {
          type: 2,
          files: [],
          lastUpdated: "N/A"
        },
        {
          type: 3,
          files: [],
          lastUpdated: "N/A"
        }
      ]
    };
    this.uploadFile = this.uploadFile.bind(this);
    this.updateDocuments = this.updateDocuments.bind(this);
    this.renderDocumentName = this.renderDocumentName.bind(this);
    this.submitDocuments = this.submitDocuments.bind(this);
    this.getCurrentDocs = this.getCurrentDocs.bind(this);
  }

  renderUploadButton(row) {
    return (
      <div>
        <button onClick={() => this.uploadFile(row.type)} className="profile-update-contact-delete-button">
          <IconUpload height={18} className="delete-button-trash-icon" />
          <span>{Resources.Upload}</span>
        </button>
        <input
          className="hidden-input"
          type="file"
          id={row.type}
          style={{ display: "none" }}
          onChange={e => {
            let accessId = this.props.match.params.accessId;
            let accountKey = "23e31d6e-3f46-4b25-a038-ce96ef5a43da";
            if (this.props.admin) {
              let accountingGroupKey = this.props.match.params.accountingGroupKey;
              let companyGlobalKey = this.props.match.params.companyGlobalKey;
              let mode = this.props.match.params.mode;
              this.props
                .uploadAccountingGroupDocument(accountingGroupKey, companyGlobalKey, mode, e.target.files[0])
                .then(response => {
                  this.updateDocuments(response);
                });
              this.props.displayNotification("uploadingDocumentNotification");
            } else if (this.props.small) {
              let perspectiveId = this.props.perspectiveId;
              let companyId = this.props.companyId;
              let mode = this.props.mode;
              this.props
                .uploadAccountingGroupDocuments(perspectiveId, companyId, mode, e.target.files[0])
                .then(response => {
                  this.updateDocuments(response);
                  this.props.documentsOnChange(response);
                });
              this.props.displayNotification("uploadingDocumentNotification");
            } else {
              this.props.postCustAccountPicture(accessId, accountKey, e.target.files[0]).then(response => {
                this.updateDocuments(response);
              });
              this.props.displayNotification("uploadingDocumentNotification");
            }
            this.setState({ updated: false });
            this.setState({ currentDocumentType: row.type });
            if (this.props.small && this.props.editMode) {
              this.props.updateCurrentDocType(row.type);
            }
          }}
        />
      </div>
    );
  }

  componentDidMount() {
    if (this.props.admin) {
      let mode = this.props.match.params.mode;
      let accountingGroupKey = this.props.match.params.accountingGroupKey;
      let companyGlobalKey = this.props.match.params.companyGlobalKey;
      this.props.fetchAccountingGroupProfileDocuments(accountingGroupKey, companyGlobalKey, mode);
    } else if (this.props.small) {
    } else {
      let accessId = this.props.match.params.accessId;
      let mode = this.props.match.params.mode;
      this.props.fetchCustAccountDocuments(accessId, mode);
    }
  }

  uploadFile(type) {
    window.document.getElementById(type).click();
  }

  updateDocuments(response) {
    let document = response;
    let filePath = document;
    let fileName = document.split("/");
    let currentDate = moment().format();
    let currentDoc = {
      fileName: fileName[fileName.length - 1],
      documentURL: filePath,
      documentTypeId: this.state.currentDocumentType,
      updateDate: currentDate
    };
    const documentToAmendIndex = this.state.documents.findIndex(i => i.type === this.state.currentDocumentType);
    const documentToAmend = this.state.documents[documentToAmendIndex];
    const updatedDocument = { ...documentToAmend, files: [...documentToAmend.files, currentDoc] } || {};
    this.setState({
      documents: [
        ...this.state.documents.slice(0, documentToAmendIndex),
        updatedDocument,
        ...this.state.documents.slice(documentToAmendIndex + 1)
      ]
    });
  }

  renderDocumentName(fileName, row, index) {
    return (
      <p className="document-form-uploaded-file document-list" key={index}>
        {fileName}
        {this.props.viewOnly || !this.state.editMode ? null : (
          <button
            onClick={
              this.props.small
                ? () => {
                    this.props.removeFile(fileName, row.type);
                    this.removeFile(fileName, row.type);
                  }
                : () => this.removeFile(fileName, row.type)
            }
          >
            <IconTrash className="document-form-file-icon" height={13} />
          </button>
        )}
      </p>
    );
  }

  removeFile(fileName, type) {
    const documentToAmendIndex = this.state.documents.findIndex(i => i.type === type);
    const documentToAmend = this.state.documents[documentToAmendIndex];
    const updatedDocument = {
      ...documentToAmend,
      files: [...documentToAmend.files.filter(f => f.fileName !== fileName)]
    };
    this.setState({
      documents: [
        ...this.state.documents.slice(0, documentToAmendIndex),
        updatedDocument,
        ...this.state.documents.slice(documentToAmendIndex + 1)
      ]
    });
  }

  submitDocuments() {
    let accountData = { ...this.props.networkStore.custAccount, ...this.state.accountData };
    let accessId = this.props.match.params.accessId;
    let mode = this.props.match.params.mode;
    let accountingGroupKey = this.props.match.params.accountingGroupKey;
    let companyGlobalKey = this.props.match.params.companyGlobalKey;
    let documentsArray = [];
    this.state.documents.map(i => i.files.map(j => documentsArray.push(j)));
    let postRequest = {
      companyName: accountData.AccountName,
      emailAddr: accountData.Email,
      Documents: documentsArray
    };
    let valid = true;
    postRequest.Documents.forEach(i => {
      if (i.fileName.length >= 100) {
        valid = false;
        let errorMessage = Resources.PleaseEnsureAllFileNamesShorterThanOneHundred;
        this.props.displayModal("errorModal", { errorMessage });
      }
    });
    if (valid) {
      if (this.props.admin) {
        this.props
          .postAccountingGroupProfileDocuments(accountingGroupKey, companyGlobalKey, mode, postRequest)
          .then(response => {
            this.props.history.push(
              generatePath(this.props.route, {
                accountingGroupKey: this.props.match.params.accountingGroupKey,
                companyGlobalKey: this.props.match.params.companyGlobalKey,
                mode: this.props.match.params.mode
              })
            );
          });
      } else {
        this.props.postCustAccountKeyDocuments(accessId, mode, postRequest).then(response => {
          this.props.history.push(
            generatePath(this.props.route, {
              accessId: this.props.match.params.accessId,
              mode: this.props.match.params.mode,
              type: "success"
            })
          );
        });
      }
    }
  }

  getCurrentDocs() {
    if (!this.state.loadedTable) {
      const docs = (this.props.networkStore.custAccountDocuments || {}).documents || [];
      let docsArray = [[], [], []];
      docs.map(currentDoc => {
        let currentType = currentDoc.documentTypeId;
        docsArray[currentType - 1].push(currentDoc);
        return null;
      });
      let newDocuments = [
        {
          type: 1,
          typeTitle:
            this.props.match.params.mode === "ar" || this.props.mode === "ar"
              ? "Bank verification documents"
              : "Credit application",
          files: docsArray[0],
          lastUpdated: "N/A"
        },
        {
          type: 2,
          typeTitle: this.props.match.params.mode === "ar" || this.props.mode === "ar" ? "W9" : "Proof of insurance",
          files: docsArray[1],
          lastUpdated: "N/A"
        },
        {
          type: 3,
          typeTitle:
            this.props.match.params.mode === "ar" || this.props.mode === "ar" ? "Other" : "Tax exemption certificate",
          files: docsArray[2],
          lastUpdated: "N/A"
        }
      ];
      this.setState({ documents: newDocuments });
      if (this.props.profileUpdate) {
        this.setState({ editMode: !this.props.networkStore.custAccount.isLockstepVerified });
      }
      this.setState({ loadedTable: true });
    }
  }

  handleLastUpdated(row) {
    if (row.files.length === 0) {
      return "N/A";
    } else {
      return formatDate(row.files[row.files.length - 1].updateDate);
    }
  }

  render() {
    if (this.props.networkStore.gettingCustAccountDocuments || this.props.networkStore.gettingCustAccount) {
      return <MainLoader className="profile-update-form-mainloader" fullScreen={true} />;
    } else if (this.props.networkStore.gotCustAccountDocuments) {
      this.getCurrentDocs();
      if (this.props.small && this.props.editMode) {
        this.props.documentsUpdate();
      }
      let documentColumns = [
        {
          header: Resources.DocumentType.toLocaleUpperCase(),
          content: row => (
            <div className="profile-update-card-table-documentpadding">
              <span className="profile-update-card-table-name">{row.typeTitle}</span>
            </div>
          ),
          width: "30%"
        },
        {
          header: Resources.File.toLocaleUpperCase(),
          content: row => row.files.map((file, index) => this.renderDocumentName(file.fileName, row, index)),
          width: "30%"
        },
        this.props.viewOnly || !this.state.editMode
          ? {
              header: Resources.LastUpdated.toLocaleUpperCase(),
              content: row => this.handleLastUpdated(row),
              width: "10%"
            }
          : {
              header: Resources.LastUpdated.toLocaleUpperCase(),
              content: row => this.handleLastUpdated(row),
              width: "25%"
            },
        this.props.viewOnly || !this.state.editMode
          ? { content: row => null, width: "0%" }
          : { content: row => this.renderUploadButton(row), width: "12%" }
      ];

      return (
        <React.Fragment>
          {this.props.small ? null : (
            <h2 className="first-time-title-contact">
              {this.props.networkStore.custAccount.isLockstepVerified
                ? Resources.LastQuestionDocumentsOnFileUpToDate
                : Resources.DocumentUploadForm}
            </h2>
          )}
          <div className={this.props.small ? "company-profile-documents-card" : "profile-udpate-card-table-container"}>
            <Card className="card-table">
              <TableData
                name="document-upload__table"
                data={this.state.documents}
                columns={documentColumns}
                pagination
              />
            </Card>
          </div>
          {this.props.small ? null : !this.state.editMode ? (
            <div className="profile-update-form-button-container">
              <button onClick={() => this.setState({ editMode: true })} className="button-secondary-square large">
                {Resources.INeedToUpdateTheseDocuments}
              </button>
              <button onClick={this.submitDocuments} className="button-primary-square large">
                {Resources.YesTheyAre}
              </button>
            </div>
          ) : (
            <div className="profile-update-form-button-container">
              {this.props.networkStore.custAccount.isLockstepVerified ? (
                <button onClick={() => this.setState({ editMode: false })} className="button-secondary-square large">
                  {Resources.Cancel}
                </button>
              ) : null}
              <button onClick={this.submitDocuments} className="button-primary-square large">
                {Resources.Save}
              </button>
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

const storeToProps = store => {
  return {
    networkStore: store.network,
    modalStore: store.modal
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(DocumentUploadForm));
