import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { dispatchToProps as moDP } from "../../../store/modal-actions";
import { dispatchToProps as ledgDP } from "../../../store/ledger-actions";
import { dispatchToProps as statDP } from "../../../store/statements-actions";

import Resources from "../../../lib/resources";
import IconClose from "../../library/icons/iconClose";
import IconAttachment from "../../library/icons/iconAttachment";
import IconAlert from "../../library/icons/iconAlert";
import TableData from "../../library/tableData";
import Card from "../../library/card";
import TableDataSortableHeader from "../../library/tableDataSortableHeader";

import moment from "moment";
import Modal from "react-modal";
import { isEmpty, updateSelectedRows, formatDate, formatCurrency, find } from "../../../lib/utils";

const dispatchToProps = dispatch => ({
  ...moDP(dispatch),
  ...ledgDP(dispatch),
  ...statDP(dispatch)
});

class ApplyPaymentFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRows: [],

      showFlyout: true
    };

    this.applyPayment = this.applyPayment.bind(this);
    this.hideFlyout = this.hideFlyout.bind(this);
  }

  componentDidMount() {}

  toggleSelectRow(i) {
    let newSelectedRows = updateSelectedRows(
      i,
      this.state.selectedRows,
      this.props.statementsStore.openInvoices.length
    );

    this.setState({ selectedRows: newSelectedRows });
  }

  hideFlyout() {
    this.setState({ showFlyout: false });
  }

  applyPayment() {
    console.log("apply the payment now");
  }

  getColumns() {
    const { statementsStore } = this.props;

    const selectCol = {
      type: "rowSelect",
      width: "9%"
    };

    const invoiceDateCol = {
      header: noSort =>
        noSort ? (
          Resources.InvoiceDate.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.InvoiceDate.toLocaleUpperCase()}
            sortKey={"IssueDate"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => <span className="fw-500">{formatDate(row.issueDate, true, false)}</span>,
      width: "17%"
    };
    const invoiceNumCol = {
      header: noSort =>
        noSort ? (
          Resources.InvoiceNumber.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.InvoiceNumber.toLocaleUpperCase()}
            sortKey={"Id"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => {
        let hasAttachment = true || !isEmpty(row.attachments);

        return (
          <div className={`statements-invoice-id d-flex align-items-center ${hasAttachment ? "link" : ""}`}>
            <div className="flex-even overflow-ellipsis">
              {hasAttachment ? (
                <React.Fragment>
                  <button
                    data-test-id={"apply-payments-flyout__attachments__" + row.id}
                    className="invoice-num-attachment"
                    onClick={() => {
                      this.downloadAttachments(row.attachments, Resources.InvoiceNAttachments(row.id) + ".zip");
                    }}
                  >
                    <span className="invoice-num-attachment-link overflow-ellipsis" title={row.id}>
                      {row.id}
                    </span>
                    <IconAttachment height={15} style={{ marginLeft: "0.33rem", minWidth: "1rem" }} />
                  </button>
                </React.Fragment>
              ) : (
                <span className="overflow-ellipsis" title={row.id}>
                  {row.id}
                </span>
              )}
            </div>
            {(row.isDisputed || row.hasPromissoryNote) && (
              <div className="d-flex flex-column justify-content-start flex-even">
                {row.isDisputed && (
                  <div>
                    <button
                      data-test-id={"apply-paymentsflyout__disputed-button__" + row.id}
                      className="button-indicator"
                      onClick={() => this.displayRelatedConversation(row.activeDispute)}
                      style={{ marginBottom: row.hasPromissoryNote ? "0.4rem" : "" }}
                    >
                      {Resources.Disputed}
                    </button>
                  </div>
                )}
                {row.hasPromissoryNote && (
                  <div>
                    <button
                      data-test-id={"apply-payments-flyout__promised-button-" + row.id}
                      className="button-indicator"
                      onClick={() =>
                        this.displayRelatedConversation(
                          find(row.payments, payment => payment.paymentType === "Promise to Pay")
                        )
                      }
                    >
                      {Resources.Promised}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      },
      width: "22%"
    };

    const amountDueCol = {
      header: noSort =>
        noSort ? (
          Resources.AmountDue.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.AmountDue.toLocaleUpperCase()}
            sortKey={"Amount"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => (
        <div className="statements-currency-row">
          <span className="mr-2">$</span>
          <span>{formatCurrency(row.amount, "")}</span>
        </div>
      ),
      width: "9rem"
    };

    const fillerCol = { width: "8%", content: row => null };

    const dueDateCol = {
      header: noSort =>
        noSort ? (
          Resources.Due_Date.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.Due_Date.toLocaleUpperCase()}
            sortKey={"DueDate"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => <span>{formatDate(row.dueDate, true, false)}</span>,
      width: "14%"
    };

    const statusCol = {
      header: noSort =>
        noSort ? (
          Resources.Status.toLocaleUpperCase()
        ) : (
          <TableDataSortableHeader
            sortBy={statementsStore.openInvoicesSortBy}
            sortDirection={statementsStore.openInvoicesSortDirection}
            updateSort={(sortBy, sortDirection) => {
              this.onSortChange();
              this.props.updateOpenInvoicesSort(sortBy, sortDirection);
            }}
            text={Resources.Status.toLocaleUpperCase()}
            sortKey={"DueDate"}
          />
        ),
      sortable: noSort => noSort === false,
      content: row => {
        let isPastDue = moment(row.dueDate).isBefore();
        return (
          <React.Fragment>
            <span>{isPastDue ? Resources.PastDue : row.status}</span>
            {isPastDue ? <IconAlert className="pl-2" height={15} /> : ""}
          </React.Fragment>
        );
      },
      width: "18%"
    };

    return [selectCol, invoiceDateCol, invoiceNumCol, amountDueCol, fillerCol, dueDateCol, statusCol];
  }

  handleLoadMore(pageToLoad) {
    let { companyId, perspectiveId, withCompanyId, pageRowCount } = this.props;
    let top = pageRowCount;
    let skip = pageToLoad * pageRowCount - pageRowCount;
    this.props.fetchOpenInvoices(companyId, perspectiveId, withCompanyId, null, null, top, skip);
  }

  render() {
    const columns = this.getColumns();
    const { payment, statementsStore } = this.props;

    const data = statementsStore.openInvoices;

    return (
      <Modal
        isOpen={this.state.showFlyout}
        onRequestClose={this.hideFlyout}
        onAfterClose={this.props.hideFlyout}
        contentLabel="Example Modal"
        className="flyout dispute-flyout"
        overlayClassName="flyout-overlay"
        closeTimeoutMS={400}
      >
        <div className="flyout-heading">
          <div>{Resources.SelectInvoicesForPaymentApplication(payment.id)}</div>
          <div onClick={this.hideFlyout} className="flyout-heading-close">
            <IconClose />
          </div>
        </div>
        <div className="flyout-content">
          <div className="flyout-table-container">
            <Card type="table" className="portal-table">
              <TableData
                rowHeight="4em"
                pagination
                data={data}
                columns={columns}
                maxRows={data.length}
                onLoadMore={pageSelected => this.handleLoadMore(pageSelected)}
                rowClassName="statements-view-row no-hover"
                localSelectedRows={this.state.selectedRows}
                onRowSelectToggle={i => this.toggleSelectRow(i)}
              />
            </Card>
          </div>

          <button
            data-test-id="apply-payments-flyout__button"
            className="portal-button-green"
            onClick={this.applyPayment}
          >
            {Resources.Apply}
          </button>
        </div>
      </Modal>
    );
  }
}

const storeToProps = store => {
  return {
    modalStore: store.modal,
    statementsStore: store.statements
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ApplyPaymentFlyout));
