import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

import Resources from "../../lib/resources";
import { getConversationSubject, htmlToText, isEmpty, updateSelectedRows, formatDate, find, isCustomerPortal } from "../../lib/utils";

import { dispatchToProps as uaDP } from "../../store/user-actions";
import { dispatchToProps as convDP } from "../../store/conversations-actions";
import { dispatchToProps as compDP } from "../../store/company-actions";
import { dispatchToProps as connDP } from "../../store/connector-actions";
import { dispatchToProps as congDP } from "../../store/contextGroups-actions";
import { dispatchToProps as moDP } from "../../store/modal-actions";
import { dispatchToProps as saDP } from "../../store/search-actions";

import TableData from "../library/tableData";
import TableDataSortableHeader from "../library/tableDataSortableHeader";
import Card from "../library/card";
import AutoCompleteInput from "../library/autoCompleteInput";
import MainLoader from "../mainLoader";
import IconMessage from "../library/icons/iconMessage";
import IconRead from "../library/icons/iconRead";
import IconUnread from "../library/icons/iconUnread";
import IconArchive from "../library/icons/iconArchive";
import IconAttachment from "../library/icons/iconAttachment";
import IconInbox from "../library/icons/iconInbox";

const dispatchToProps = dispatch => ({
  ...uaDP(dispatch),
  ...convDP(dispatch),
  ...connDP(dispatch),
  ...congDP(dispatch),
  ...moDP(dispatch),
  ...saDP(dispatch),
  ...compDP(dispatch)
});

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
      selectedKeys: [],
      searchTerm: ""
    };
  }

  componentDidMount = () => {
    this.props.ensureConversationStatuses(this.props.companyId);
    this.tryUpdate();
  }

  componentDidUpdate = (prevProps) => {
    this.tryUpdate(prevProps);
  }

  componentWillUnmount = () => {
    this.props.clearConversations();
  }

  tryUpdate = (prevProps = {}) => {
    const {
      perspectiveId,
      withContextGroupId,
      conversationsStore,
      match: { params },
      pageRowCount
    } = this.props;
    if (isEmpty(perspectiveId) || isEmpty(withContextGroupId)) {
      return;
    }

    const folderChanged = ((prevProps.match || {}).params || {}).folder !== params.folder;

    if (
      !isEmpty(pageRowCount) &&
      (isEmpty(conversationsStore.fetchedConversations[perspectiveId]) ||
        folderChanged ||
        this.sortHasChanged(prevProps))
    ) {
      this.fetchFolderConversations(pageRowCount);
    }

    if (folderChanged) {
      this.props.clearSearchResults();
      this.setState({ searchTerm: "" });
    }
  }

  sortHasChanged = (prevProps) => {
    if (isEmpty(prevProps)) {
      return false;
    }
    if (
      this.props.conversationsStore.sortDirection !== prevProps.conversationsStore.sortDirection ||
      this.props.conversationsStore.sortBy !== prevProps.conversationsStore.sortBy
    ) {
      return true;
    }
    return false;
  }

  onSortChange() {}

  getFolderLabel = () => {
    switch (this.props.match.params.folder) {
      case "archive":
        return "closed";
      case "sent":
        return "waiting-for-response";
      case "inbox":
      default:
        return "unassigned";
    }
  }

  fetchFolderConversations = (top, skip) => {
    const { companyId, perspectiveId, withContextGroupId } = this.props;

    const label = this.getFolderLabel();

    this.props.getLabeledWithCompanyConversations(companyId, perspectiveId, withContextGroupId, label, top, skip);
  }

  toggleSelectRow = (key, row) => {
    let newSelectedKeys = updateSelectedRows(key, this.state.selectedKeys);
    let newSelectedRows = updateSelectedRows(row, this.state.selectedRows);
    this.setState({ selectedKeys: newSelectedKeys, selectedRows: newSelectedRows });
  }

  changeConversationSort = (sortBy, sortDirection) => {
    let currentSortBy = this.props.conversations.sortBy;
    let currentSortDirection = this.props.conversations.sortDirection;

    if (isEmpty(sortDirection)) {
      if (currentSortBy !== sortBy) {
        this.props.setConversationSort(sortBy, "desc");
      } else if (currentSortDirection === "desc") {
        this.props.setConversationSort(sortBy, "asc");
      } else {
        this.props.setConversationSort(sortBy, "desc");
      }
    } else {
      this.props.setConversationSort(sortBy, sortDirection);
    }
  }

  handleLoadMore = (pageToLoad) => {
    let { pageRowCount } = this.props;
    let top = pageRowCount;
    let skip = pageToLoad * pageRowCount - pageRowCount;
    this.fetchFolderConversations(top, skip);
  }

  getMessagesColumns = (hover) => {
    let {
      conversationsStore,
      companyId,
      perspectiveId,
      match: { params }
    } = this.props;

    const statuses = this.props.conversationsStore.statuses || [];
    const waitingResponseStatus = find(
      statuses,
      status => status.conversationStatusName.toLowerCase() === "waiting for response"
    );
    const activeStatus = find(statuses, status => status.conversationStatusName.toLowerCase() === "active");
    const closedStatus = find(statuses, status => status.conversationStatusName.toLowerCase() === "closed");

    const companyInfo = this.props.getCompanyInfo(companyId);
    const myAddress = (((companyInfo || {}).connectorEmail || {}).synthetic || {})[perspectiveId];

    const selectCol = {
      type: "rowSelect",
      width: "5%"
    };

    const fromCol = {
      header: params.folder === "sent" ? Resources.To.toLocaleUpperCase() : Resources.From.toLocaleUpperCase(),
      sortable: noSort => noSort === false,
      content: row => {
        const participants = (params.folder === "sent" ? row.toEmailAddresses : row.fromEmailAddresses) || [];
        const participantNames = participants
          .filter(participant => participant.name)
          .map(participant => participant.name);
        const participantAddresses = participants
          .filter(participant => participant.address)
          .map(participant => participant.address);
        const contextGroupNames = row.contextGroupIds
          .map(id => this.props.getContextGroupName(id, false, ""))
          .filter(name => name);
        let content;
        if (!isEmpty(participantNames)) {
          content = participantNames.join();
        } else if (!isEmpty(contextGroupNames)) {
          content = contextGroupNames.join();
        } else {
          content = participantAddresses.join();
        }

        return (
          <div className={`conversation-table-row-partner overflow-ellipsis ${row.hasUnreads ? "highlight" : ""}`}>
            {content}
          </div>
        );
      },
      width: "25%"
    };

    const dateCol = {
      header: (
        <TableDataSortableHeader
          sortBy={conversationsStore.sortBy}
          sortDirection={conversationsStore.sortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.setConversationSort(sortBy, sortDirection);
          }}
          text={Resources.Date.toLocaleUpperCase()}
          sortKey={"ModifiedDate"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => <div>{formatDate(row.updatedDate, false, false)}</div>,
      width: "15%",
      name: "date"
    };

    let subjectCol = {
      header: (
        <TableDataSortableHeader
          sortBy={conversationsStore.sortBy}
          sortDirection={conversationsStore.sortDirection}
          updateSort={(sortBy, sortDirection) => {
            this.onSortChange();
            this.props.setConversationSort(sortBy, sortDirection);
          }}
          text={Resources.Subject.toLocaleUpperCase()}
          sortKey={"Subject"}
        />
      ),
      sortable: noSort => noSort === false,
      content: row => (
        <div>
          <div className={`customer-portal-messages-table-subject ${row.hasUnreads ? "highlight" : ""}`}>
            <div className="customer-portal-messages-table-subject-text">{row.displaySubject}</div>
            {row.hasAttachments && (
              <div className="customer-portal-messages-table-attachment-icon">
                <IconAttachment height="16" />
              </div>
            )}
            {!isEmpty(row.message) && (
              <div className={`overflow-ellipsis customer-portal-messages-table-subject-body`}>
                {!isEmpty(row.displaySubject) && <span className="mr-2 ml-2">-</span>}
                <span>{htmlToText(row.message)}</span>
              </div>
            )}
          </div>
        </div>
      ),
      width: "55%",
      name: "subject"
    };

    let columns = [selectCol, fromCol, subjectCol, dateCol];
    const hoverColumns = [
      selectCol,
      fromCol,
      subjectCol,
      {
        width: "15%",
        content: row => (
          <div className="flex-end flex-align-center">
            {row.hasUnreads ? (
              <button
                data-test-id="messages__action-icon-read"
                className="button-action-icon data-Tooltip"
                data-tooltip={Resources.MarkAsRead}
                onClick={e => {
                  e.stopPropagation();
                  this.props.updateConversationReadStatuses([row.conversationId], true);
                }}
              >
                <IconRead height="21" />
              </button>
            ) : (
              <button
                data-test-id="messages__action-icon-unread"
                className="button-action-icon data-Tooltip"
                data-tooltip={Resources.MarkAsUnread}
                onClick={e => {
                  e.stopPropagation();
                  this.props.updateConversationReadStatuses([row.conversationId], false);
                }}
              >
                <IconUnread height="16" />
              </button>
            )}
            {params.folder === "archive" ? (
              <button
                data-test-id="messages_action-icon-inbox"
                className="button-action-icon data-Tooltip"
                data-tooltip="Inbox"
                onClick={e => {
                  if (
                    isEmpty(row) ||
                    isEmpty(row.latestEntry) ||
                    isEmpty(row.latestEntry.asJson) ||
                    isEmpty(row.latestEntry.asJson.from)
                  ) {
                    return;
                  }
                  const toStatus =
                    (row || {}).latestEntry.asJson.from[0].address === myAddress ? waitingResponseStatus : activeStatus;

                  e.stopPropagation();
                  this.props.setConversationStatusesTo([row], toStatus.conversationStatusId).then(res => {
                    this.setState({ selectedRows: [], selectedKeys: [] });
                    this.props.fetchContextGroups(companyId, perspectiveId);
                    this.fetchFolderConversations();
                  });
                }}
              >
                <IconInbox height="21" />
              </button>
            ) : (
              <button
                data-test-id="messages__action-icon-archive"
                className="button-action-icon data-Tooltip"
                data-tooltip={Resources.Archive}
                onClick={e => {
                  e.stopPropagation();
                  this.props.setConversationStatusesTo([row], closedStatus.conversationStatusId).then(res => {
                    this.setState({ selectedRows: [], selectedKeys: [] });
                    this.props.fetchContextGroups(companyId, perspectiveId);
                    this.fetchFolderConversations();
                  });
                }}
              >
                <IconArchive height="16" />
              </button>
            )}
          </div>
        )
      }
    ];

    if (hover) {
      return hoverColumns;
    }
    return columns;
  }

  renderEmptyState() {
    return (
      <div className="messages-empty-state">
        <svg viewBox="0 0 308 164">
          <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M-566-274H874V750H-566z" />
            <path fill="#F8FBFC" d="M-566-122H874v872H-566z" />
            <rect
              width="1027"
              height="379"
              x=".5"
              y=".5"
              fill="#FFF"
              stroke="#DBE0E3"
              rx="2"
              transform="translate(-360 -38)"
            />
            <path
              fill="#F4F3FF"
              d="M294.545455 20.4090909c7.116942 0 12.886363 5.7694215 12.886363 12.8863636 0 7.1169422-5.769421 12.8863637-12.886363 12.8863637h-73.636364c7.116942 0 12.886364 5.7694215 12.886364 12.8863636 0 7.1169421-5.769422 12.8863637-12.886364 12.8863637h40.5c7.116942 0 12.886364 5.7694215 12.886364 12.8863636 0 7.1169421-5.769422 12.8863636-12.886364 12.8863636h-18.729249c-8.973536 0-16.248024 5.7694213-16.248024 12.8863633 0 4.744628 3.681818 9.040083 11.045455 12.886364 7.116942 0 12.886363 5.769422 12.886363 12.886364 0 7.116942-5.769421 12.886363-12.886363 12.886363H84.6818182c-7.1169421 0-12.8863637-5.769421-12.8863637-12.886363 0-7.116942 5.7694216-12.886364 12.8863637-12.886364H12.8863636C5.76942152 123.5 0 117.730578 0 110.613636c0-7.116942 5.76942152-12.8863633 12.8863636-12.8863633h73.6363637c7.1169421 0 12.8863636-5.7694215 12.8863636-12.8863636 0-7.1169421-5.7694215-12.8863636-12.8863636-12.8863636H40.5c-7.1169421 0-12.8863636-5.7694216-12.8863636-12.8863637S33.3830579 46.1818182 40.5 46.1818182h73.636364c-7.116942 0-12.886364-5.7694215-12.886364-12.8863637 0-7.1169421 5.769422-12.8863636 12.886364-12.8863636h180.409091zm0 51.5454546c7.116942 0 12.886363 5.7694215 12.886363 12.8863636 0 7.1169421-5.769421 12.8863636-12.886363 12.8863636-7.116943 0-12.886364-5.7694215-12.886364-12.8863636 0-7.1169421 5.769421-12.8863636 12.886364-12.8863636z"
            />
            <path
              fill="#FFF"
              d="M130.922787 109.337201c-.143846 1.018442-.218242 2.059205-.218242 3.117344 0 12.200473 9.890437 22.09091 22.09091 22.09091 12.200472 0 22.090909-9.890437 22.090909-22.09091 0-1.058139-.074396-2.098902-.218242-3.117344h53.604605v49.140072c0 3.050118-2.472609 5.522727-5.522727 5.522727H82.8409091c-3.0501181 0-5.5227273-2.472609-5.5227273-5.522727v-49.140072h53.6046052z"
            />
            <path
              fill="#FFF"
              d="M176.727273 108.772727c0 13.217178-10.71464 23.931818-23.931818 23.931818-13.217179 0-23.931819-10.71464-23.931819-23.931818 0-.428198.011246-.853769.033458-1.276435H77.3181818l17.5999005-52.0384031c.7587435-2.2434104 2.8633707-3.7533434 5.2316147-3.7533434h105.291515c2.368244 0 4.472871 1.509933 5.231615 3.7533434l17.5999 52.0384031h-51.578912c.022212.422666.033458.848237.033458 1.276435z"
            />
            <path
              fill="#DEDAFF"
              d="M173.225055 110.529959c0 10.213274-9.146643 20.333677-20.4296 20.333677-11.282957 0-20.429601-10.120403-20.429601-20.333677 0-.330881.0096-2.50064.028561-2.827246H93.8863636l15.0243054-36.5296749c.647708-1.7335444 2.444341-2.9003108 4.466013-2.9003108h78.837545c2.021672 0 3.818305 1.1667664 4.466013 2.9003108l15.024305 36.5296749h-38.508051c.018961.326606.028561 2.496365.028561 2.827246z"
            />
            <path
              stroke="#BEB6FF"
              strokeWidth="3.5"
              d="M207.251104 53.4545455H98.3398048c-.2680112 0-.5211058.0854386-.7286641.2343481-.2075583.1489096-.3695803.3612901-.4554463.615174L79.0681818 107.784215v51.374876c0 .345178.139911.657678.3661165.883883.2262055.226206.5387055.366117.8838835.366117H225.272727c.345178 0 .657678-.139911.883884-.366117.226205-.226205.366116-.538705.366116-.883883v-51.374876l-18.087512-53.4801474c-.085866-.2538839-.247888-.4662644-.455447-.615174-.207558-.1489095-.460652-.2343481-.728664-.2343481z"
            />
            <path
              stroke="#BEB6FF"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3.5"
              d="M89.419822 106.931818h4.4665416-4.4665416zm13.671087 0h23.462223c3.454061 0 3.454061 2.427378 3.454061 3.681818 0 12.200473 10.117987 22.090909 22.599156 22.090909 12.48117 0 22.599157-9.890436 22.599157-22.090909 0-1.25444 0-3.681818 3.454061-3.681818h45.931342M151.137917 2v30.8460138M103.090909 9.92094283l20.433372 22.92507097m75.477273-22.92507097L178.568182 32.8460138"
            />
          </g>
        </svg>
        <h2>{Resources.YourInboxIsEmpty}</h2>
        {this.renderSendMessageButton()}
      </div>
    );
  }

  renderSendMessageButton() {
    let { companyId, perspectiveId, withContextGroupId, statementsStore } = this.props;
    const isPortal = isCustomerPortal(this.props.history.location.pathname);

    return (
      <button
        data-test-id="messages__button-send"
        className="button-primary"
        onClick={() =>
          this.props.displayFlyout("messageFlyout", {
            companyId,
            perspectiveId,
            withContextGroupId,
            withCompanyId: statementsStore.withCompanyId,
            isPortal,
            refetchConversations: this.fetchFolderConversations
          })
        }
      >
        <IconMessage height="20" className="button-primary-icon" />
        {Resources.SendMessage}
      </button>
    );
  }

  renderCardContent() {
    let {
      statementsStore,
      conversationsStore,
      searchStore,
      companyId,
      perspectiveId,
      withContextGroupId,
      withCompanyName,
      match: { params }
    } = this.props;
    let { selectedRows } = this.state;

    let perspectiveContent = conversationsStore.conversations[perspectiveId] || {};
    let data = perspectiveContent.value || [];

    if (data.length > 0) {
      data.forEach(d => {
        d.status =
          this.props.getConversationStatus(d.conversationStatusId || "65d7d5d6-3615-4710-bce7-7920fd12d0d0") || {};
        d.updatedDate = <div className="original-date-line">{(formatDate(d.modifiedDate), false, false)}</div>;

        d.channelName = this.props.getContextGroupName(d.contextGroupIds[0]);
        d.age = moment(d.modifiedDate).fromNow(true);

        let content = (d.latestEntry || {}).asJson || {};
        let textBody = content.textBody;
        if (textBody === "Text body not available.") {
          textBody = null;
        }
        if (isEmpty(textBody)) {
          textBody = htmlToText(content.htmlBody);
        }
        d.message = textBody;

        d.displaySubject = getConversationSubject(d.subject);
        d.highlight = d.hasUnreads;
      });
    }

    const loading =
      conversationsStore.gettingConversations[perspectiveId] || conversationsStore.settingConversationStatuses;

    if (conversationsStore.settingConversationStatuses === true) {
      data = [];
    }

    const statuses = this.props.conversationsStore.statuses || [];
    const waitingResponseStatus = find(
      statuses,
      status => status.conversationStatusName.toLowerCase() === "waiting for response"
    );
    const activeStatus = find(statuses, status => status.conversationStatusName.toLowerCase() === "active");
    const closedStatus = find(statuses, status => status.conversationStatusName.toLowerCase() === "closed");

    const companyInfo = this.props.getCompanyInfo(companyId);
    const myAddress = (((companyInfo || {}).connectorEmail || {}).synthetic || {})[perspectiveId];

    const isPortal = isCustomerPortal(this.props.history.location.pathname);

    return (
      <React.Fragment>
        <div className="table-data-card-header">
          <div className="table-data-card-header-buttons">
            {this.renderSendMessageButton()}
            <button
              data-test-id="messages__button-mark-read"
              className="button-primary"
              disabled={isEmpty(selectedRows) || isEmpty(data) || selectedRows.every(row => row.hasUnreads === false)}
              onClick={() => {
                const conversationIds = selectedRows.map(row => row.conversationId);
                this.props
                  .updateConversationReadStatuses(conversationIds, true)
                  .then(res => this.setState({ selectedRows: [], selectedKeys: [] }));
              }}
            >
              <IconRead height="21" className="button-primary-icon" />
              {Resources.MarkAsRead}
            </button>
            <button
              data-test-id="messages__button-mark-unread"
              className="button-primary"
              disabled={isEmpty(selectedRows) || isEmpty(data) || selectedRows.every(row => row.hasUnreads === true)}
              onClick={() => {
                const conversationIds = selectedRows.map(row => row.conversationId);
                this.props
                  .updateConversationReadStatuses(conversationIds, false)
                  .then(res => this.setState({ selectedRows: [], selectedKeys: [] }))
                  .then(res => this.fetchFolderConversations());
              }}
            >
              <IconUnread height="16" className="button-primary-icon" />
              {Resources.MarkAsUnread}
            </button>
            {params.folder === "archive" ? (
              <button
                data-test-id="messages__button-move"
                className="button-primary"
                disabled={isEmpty(selectedRows)}
                onClick={() => {
                  const conversations = selectedRows.map(index => data[index]);
                  const conversationsToInbox = [];
                  const conversationsToSent = [];

                  conversations.forEach(conversation => {
                    if (
                      isEmpty(conversation) ||
                      isEmpty(conversation.latestEntry) ||
                      isEmpty(conversation.latestEntry.asJson) ||
                      isEmpty(conversation.latestEntry.asJson.from)
                    ) {
                      return;
                    } else if ((conversation || {}).latestEntry.asJson.from[0].address === myAddress) {
                      conversationsToSent.push(conversation);
                    } else {
                      conversationsToInbox.push(conversation);
                    }
                  });

                  const promises = [];

                  if (!isEmpty(conversationsToInbox)) {
                    promises.push(
                      this.props.setConversationStatusesTo(conversationsToInbox, activeStatus.conversationStatusId)
                    );
                  }
                  if (!isEmpty(conversationsToSent)) {
                    this.props.setConversationStatusesTo(
                      conversationsToSent,
                      waitingResponseStatus.conversationStatusId
                    );
                  }

                  Promise.all(promises).then(res => {
                    this.setState({ selectedRows: [], selectedKeys: [] });
                    this.fetchFolderConversations();
                    this.props.fetchContextGroups(companyId, perspectiveId);
                  });
                }}
              >
                <IconInbox height="21" className="button-primary-icon" />
                {Resources.MoveToInbox}
              </button>
            ) : (
              <button
                data-test-id="messages__button-archive"
                className="button-primary"
                disabled={isEmpty(selectedRows)}
                onClick={() => {
                  const conversations = selectedRows;
                  this.props.setConversationStatusesTo(conversations, closedStatus.conversationStatusId).then(res => {
                    this.setState({ selectedRows: [], selectedKeys: [] });
                    this.fetchFolderConversations();
                    this.props.fetchContextGroups(companyId, perspectiveId);
                  });
                }}
              >
                <IconArchive height="16" className="button-primary-icon" />
                {Resources.Archive}
              </button>
            )}
          </div>
          <div className="table-data-card-header-search">
            <AutoCompleteInput
              data-test-id="messages__search"
              className="auto-complete-input__table-data-search"
              placeholder={Resources.Search}
              onChange={e => {
                const searchTerm = e.target.value;

                const request = {
                  request: searchTerm,
                  perspectiveId,
                  resourceIds: [],
                  withCompanyIds: [this.props.withCompanyId],
                  label: this.getFolderLabel()
                };
                this.setState({ searchTerm });

                this.props.debouncedFetchSearchResults(this.props.companyId, request);
              }}
              isShowingSearchResult={conversationsStore.isShowingSearchResult}
              handleClearResult={() => {
                this.setState({ searchTerm: "" });
                this.props.clearSearchResults();
                this.fetchFolderConversations();
              }}
              text={this.state.searchTerm}
              noResultsMessage={Resources.NoMessageSearchResultsMessage}
              showNoResultsMessage={searchStore.fetchingSearchResultsFailed === false}
              maxOptions={7}
              loading={searchStore.isFetchingSearchResults}
              handleSelectOption={option => {
                this.setState({ selectedRows: [] });
                this.props.setConversationsToSearchResult([option], this.props.perspectiveId);
                this.props.displayFlyout("messageFlyout", {
                  conversationId: option.conversationId,
                  companyId,
                  perspectiveId,
                  withCompanyId: statementsStore.withCompanyId,
                  withCompanyName,
                  withContextGroupId,
                  conversationSubject: option.subject,
                  isPortal
                });
              }}
              callToActionButton={
                <div
                  className="dropdown-item-clean dropdown-call-to-action"
                  onClick={() =>
                    this.props.setConversationsToSearchResult(searchStore.searchResults, this.props.perspectiveId)
                  }
                >
                  {Resources.SeeAllMessageResults(this.state.searchTerm)}
                </div>
              }
              renderOption={option => {
                return (
                  <div className="flex-split">
                    <span style={{ maxWidth: "70%" }} className="overflow-ellipsis">
                      {option.subject || Resources.NoSubject}
                    </span>
                    <span className="dropdown-item-secondary-label">{formatDate(option.updatedDate)}</span>
                  </div>
                );
              }}
              options={searchStore.searchResults}
              width="300px"
            />
          </div>
        </div>

        <TableData
          pagination
          data={data}
          columns={this.getMessagesColumns(false)}
          hoverColumns={this.getMessagesColumns(true)}
          rowHeight="4em"
          rowClassName="hover-pointer"
          onLoadMore={pageSelected => this.handleLoadMore(pageSelected)}
          maxRows={perspectiveContent.count || 0}
          loading={loading}
          offsetHeight="40"
          localSelectedKeys={this.state.selectedKeys}
          showSelectAll
          pageName="messages"
          selectedType="messages"
          rowKey="conversationId"
          onRowClick={row =>
            this.props.displayFlyout("messageFlyout", {
              conversationId: row.conversationId,
              companyId,
              perspectiveId,
              withCompanyId: statementsStore.withCompanyId,
              withCompanyName,
              withContextGroupId,
              conversationSubject: row.displaySubject,
              isPortal
            })
          }
          onRowSelectToggle={key =>
            this.toggleSelectRow(
              key,
              (Array.isArray(key) ? [...key] : [key]).map(k => find(data, row => row.conversationId === k))
            )
          }
          emptyRender={
            <div className="table-data-empty-render" data-test-id="messages__empty">
              <h4 className="mt-5">{Resources.EmptyInboxMessages}</h4>
            </div>
          }
        />
      </React.Fragment>
    );
  }

  render() {
    const { conversationsStore, perspectiveId, withCompanyName } = this.props;

    const perspectiveContent = conversationsStore.conversations[perspectiveId] || {};
    const data = perspectiveContent.value || [];
    const loading =
      conversationsStore.gettingConversations[perspectiveId] || conversationsStore.settingConversationStatuses;

    const helmet = (
      <Helmet>
        <title>
          {withCompanyName} | {Resources.Communications}
        </title>
      </Helmet>
    );

    const renderEmptyInboxState = isEmpty(data) && !conversationsStore.gettingConversations[perspectiveId];
    const activeCounts = conversationsStore.unassignedUnreads || 0;

    let cardContent;

    if (renderEmptyInboxState) {
      cardContent = this.renderEmptyState();
    } else if (loading && data.length === 0) {
      cardContent = <MainLoader fullScreen className="portal-page-loader messages" />;
    } else {
      cardContent = this.renderCardContent();
    }

    return (
      <React.Fragment>
        {helmet}
        <div className="flex mb-3">
          <NavLink
            data-test-id="messages__nav__inbox"
            className="folder-link"
            to={"/customer-portal/communications/inbox"}
          >
            {Resources.InboxCount(activeCounts)}
          </NavLink>
          <NavLink
            data-test-id="messages__nav__sent"
            className="folder-link"
            to={"/customer-portal/communications/sent"}
          >
            {Resources.Sent}
          </NavLink>
          <NavLink
            data-test-id="messages__nav__archive"
            className="folder-link"
            to={"/customer-portal/communications/archive"}
          >
            {Resources.Archive}
          </NavLink>
        </div>
        <Card type="table" className="portal-table customer-portal-messages-card">
          {cardContent}
        </Card>
      </React.Fragment>
    );
  }
}

const storeToProps = store => {
  return {
    contextGroupsStore: store.contextGroups,
    conversationsStore: store.conversations,
    statementsStore: store.statements,
    searchStore: store.search,
    pageRowCount: store.general.pageRowCount
  };
};

export default connect(storeToProps, dispatchToProps)(Messages);
