import React from "react";

import Card from "../../../library/card";
import IconInbox from "../../../library/icons/iconInbox";
import IconList from "../../../library/icons/iconList";
import IconLock from "../../../library/icons/iconLock";
import IconApps from "../../../library/icons/iconApps";
import IconMail from "../../../library/icons/iconMail";
import IconClose from "../../../library/icons/iconClose";
import IconFile from "../../../library/icons/iconFile";
import IconUsers from "../../../library/icons/iconUsers";
import { SettingsViews } from "./settingsControllerFlyout";
import Resources from "../../../../lib/resources";
import { useFlags } from "launchdarkly-react-client-sdk";

const SettingsNavigator = ({ setSettingsView, hideFlyout, flags }) => {
  const { teamInboxTemplates } = useFlags();

  return (
    <React.Fragment>
      <div className="settings-flyout__header flyout-heading">
        <h2>{Resources.Settings}</h2>
        <div onClick={hideFlyout} className="flyout-heading-close">
          <IconClose />
        </div>
      </div>

      <Card onClick={() => setSettingsView(SettingsViews.SIGN_IN_SECURITY)} className="settings-flyout__card">
        <div className="settings-flyout__icon-wrapper">
          <IconLock />
        </div>
        <div>
          <h5>{Resources.SignInAndSecurity}</h5>
          <div className="settings-flyout__subtitle">{Resources.ChangeYourPassword}</div>
        </div>
      </Card>

      <Card onClick={() => setSettingsView(SettingsViews.SUMMARY_EMAIL_PREFERENCES)} className="settings-flyout__card">
        <div className="settings-flyout__icon-wrapper">
          <IconMail />
        </div>
        <div>
          <h5>{Resources.ActivitySummaryEmails}</h5>
          <div className="settings-flyout__subtitle">{Resources.ChooseActivitySummmaryEmailsFrequency}</div>
        </div>
      </Card>

      <Card onClick={() => setSettingsView(SettingsViews.WORKSPACE_PREFERENCES)} className="settings-flyout__card">
        <div className="settings-flyout__icon-wrapper">
          <IconList />
        </div>
        <div>
          <h5>{Resources.WorkspacePreferences}</h5>
          <div className="settings-flyout__subtitle">{Resources.CustomizeWorkspaceNames}</div>
        </div>
      </Card>

      <Card onClick={() => setSettingsView(SettingsViews.EMAIL_SETTINGS)} className="settings-flyout__card">
        <div className="settings-flyout__icon-wrapper">
          <IconInbox />
        </div>
        <div>
          <h5>{Resources.EmailSettingsV2}</h5>
          <div className="settings-flyout__subtitle">{Resources.ManageEmailSetupAndDelivery}</div>
        </div>
      </Card>

      {teamInboxTemplates && (
        <Card onClick={() => setSettingsView(SettingsViews.TEMPLATES)} className="settings-flyout__card">
          <div className="settings-flyout__icon-wrapper">
            <IconFile />
          </div>
          <div>
            <h5>{Resources.Templates}</h5>
            <div className="settings-flyout__subtitle">{Resources.ManageEmailSetupAndDelivery}</div>
          </div>
        </Card>
      )}

      <Card onClick={() => setSettingsView(SettingsViews.USER_PERMISSION_SETTINGS)} className="settings-flyout__card">
        <div className="settings-flyout__icon-wrapper">
          <IconUsers />
        </div>
        <div>
          <h5>{Resources.CompanyUsersAndPermissions}</h5>
          <div className="settings-flyout__subtitle">{Resources.ManageUsersAndPermissions}</div>
        </div>
      </Card>

      {/* 
      // TODO: Implement connector settings
      <Card
        onClick={() => setSettingsView(SettingsViews.ACCOUNTING_SOFTWARE_SETTINGS)}
        className="settings-flyout__card"
      >
        <div className="settings-flyout__icon-wrapper">
          <IconApps />
        </div>
        <div>
          <h5>{Resources.AccountingSoftwareSettings}</h5>
          <div className="settings-flyout__subtitle">{Resources.ManageConnectionsToAccountingSoftware}</div>
        </div>
      </Card> */}
    </React.Fragment>
  );
};

export default SettingsNavigator;
