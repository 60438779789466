import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import Modal from "react-modal";

import Resources from "../../../../lib/resources";
import Icons from "../../../../lib/icons";
import { getPartnerType } from "../../../../lib/perspectives";
import { isEmailValid, isEmpty } from "../../../../lib/utils";

import { resetInviteCompanyUser, inviteCompanyUser } from "../../../../store/invite-actions";
import { raiseError } from "../../../../store/error-actions";
import { getCompanyPerspectives } from "../../../../store/perspectives-actions";
import { hideModal } from "../../../../store/modal-actions";

function InviteUserModal({ perspective, existingEmails, companyId }) {
  const invite = useSelector(store => store.invite);

  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [customMessage, setCustomMessage] = useState(Resources.InviteToCompanyMessage);
  const [defaultPerspective, setDefaultPerspective] = useState(perspective);

  const dispatch = useDispatch();

  useEffect(() => {
    if (invite.invitedUser === true) {
      onClosing();
    }
  }, [invite.invitedUser]);

  function onClosing(closing) {
    dispatch(resetInviteCompanyUser());
    dispatch(hideModal());
  }

  function validateAndInvite() {
    if (isEmpty(fullName.trim())) {
      dispatch(raiseError(Resources.ErrorFullNameRequired));
    } else if (isEmailValid(emailAddress) === false) {
      dispatch(raiseError(Resources.ErrorEmailFormat));
    } else if (existingEmails[emailAddress.toLocaleLowerCase()] === true) {
      dispatch(raiseError(Resources.ErrorExistingUserInviteEmail));
    } else {
      dispatch(inviteCompanyUser(companyId, fullName, emailAddress, defaultPerspective.perspectiveId, customMessage));
    }
  }

  const labelClass = "w-25";
  const inputClass = "form-control pr-2 pl-2 plain thin w-75";
  const { invitingUser } = invite;

  const companyPerspectives = dispatch(getCompanyPerspectives(companyId));
  const showModal = useSelector(store => store.modal.showModal);

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => onClosing()}
      className="custom-modal"
      overlayClassName="custom-modal-overlay"
    >
      <div className="custom-modal-header">{Resources.InviteUser}</div>
      <div className="custom-modal-divider" />
      <div className="custom-modal-content">
        <div>
          <div>
            <div className="mb-2 flex-align-start">
              <span className={labelClass}>{Resources.Name}</span>
              <input
                className={inputClass}
                type="text"
                value={fullName}
                disabled={invitingUser}
                onChange={e => {
                  setFullName(e.target.value);
                }}
              />
            </div>
            <div className="mb-2 flex-align-start">
              <span className={labelClass}>{Resources.EmailAddress}</span>
              <input
                className={inputClass}
                type="text"
                value={emailAddress}
                disabled={invitingUser}
                onChange={e => {
                  setEmailAddress(e.target.value);
                }}
              />
            </div>
            <div className="mb-2 flex-align-start">
              <span className={labelClass}>{Resources.Perspective}</span>
              <span className={`dropdown ${""}`}>
                <span role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {getPartnerType(defaultPerspective.perspectiveName, true)}
                  <span className={Icons.dropdown} />
                </span>
                {invitingUser === false && (
                  <span className="dropdown-menu">
                    {companyPerspectives.map((perspective, i) => {
                      return (
                        <div
                          key={i}
                          className="dropdown-item"
                          style={{ cursor: "default" }}
                          onClick={() => {
                            setDefaultPerspective(perspective);
                          }}
                        >
                          {getPartnerType(perspective.perspectiveName, true)}
                        </div>
                      );
                    })}
                  </span>
                )}
              </span>
            </div>
            <div className="flex-align-start">
              <span className={labelClass}>{Resources.Message}</span>
              <textarea
                className={`${inputClass}`}
                style={{ height: "250px" }}
                disabled={invitingUser}
                value={customMessage}
                onChange={e => {
                  setCustomMessage(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div>
          {invitingUser === true && (
            <span className="col-6">
              <span className="mt-2 float-left">
                <span>{`Inviting user to company...`}</span>
                <span className={Icons.spinner} />
              </span>
            </span>
          )}
          <span className="col-6">
            <span className="float-right">
              <button className="cancel-button" disabled={invitingUser} onClick={onClosing}>
                {Resources.Cancel}
              </button>
              <button className="send-button" disabled={invitingUser} onClick={validateAndInvite}>
                {Resources.Invite}
              </button>
            </span>
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default InviteUserModal;
