import React, { Component } from "react";
import { generatePath, withRouter } from "react-router-dom";

import Resources from "../../../lib/resources";

class SimpleForwardThreadItem extends Component {
  render() {
    const { posterName, dateText, item = {} } = this.props;

    let recipient = ((item.data || {}).to || []).map(recipient => (recipient || {}).address).join(", ");
    let titleNote = Resources.ForwardedByTo(posterName, recipient);
    let taskKey = item.data.forwardedTask;

    return (
      <React.Fragment>
        <div className="conversation-thread-item">
          <div className="conversation-thread-item__body">
            <div className="conversation-thread-item__body__header">
              <div className="conversation-thread-forward-badge-container">
                <span className="conversation-thread-item__body__badge conversation-thread-item__body__badge--forward">
                  {titleNote}
                </span>
                <span
                  className="view-link"
                  onClick={() =>
                    this.props.history.push(
                      generatePath("/v2/:companyId/:perspectiveId/activities/assigned/:conversationId", {
                        companyId: this.props.match.params.companyId,
                        perspectiveId: this.props.match.params.perspectiveId,
                        selectedContextGroupId: this.props.match.params.selectedContextGroupId,
                        conversationId: taskKey
                      })
                    )
                  }
                >
                  {Resources.GoToForwardedItem}
                </span>
              </div>
              <span className="thread-item-age">{dateText}</span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SimpleForwardThreadItem);
