import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Resources from "../../../lib/resources";
import Icons from "../../../lib/icons";
import { createNewEvent, find } from "../../../lib/utils";

import { dispatchToProps as lgrDP } from "../../../store/ledger-actions";
import { dispatchToProps as convDP } from "../../../store/conversations-actions";

const dispatchToProps = dispatch => ({
  ...lgrDP(dispatch),
  ...convDP(dispatch)
});

class ReminderThreadItem extends Component {
  constructor(props) {
    super(props);

    this.unsetReminder = this.unsetReminder.bind(this);
  }

  unsetReminder(companyId, ledgerHash) {
    this.props
      .unsetReminder(companyId, ledgerHash)
      .then(() => {
        this.props.getConversations(
          this.props.match.params.companyId,
          this.props.match.params.perspectiveId,
          this.props.match.params.withCompanyId
        );
        window.dispatchEvent(createNewEvent("refreshData"));
        this.props.history.push(
          this.props.location.pathname.replace(this.props.match.params.selectedItemId, "").slice(0, -1)
        );
      })
      .catch(err => {
        return;
      });
  }

  render() {
    let moment = require("moment-timezone");

    if (((this.props.entry || {}).asJson || {}).ShouldRemind) {
      return null;
    }

    let reminderResource = find(this.props.ledger.resources, resource => resource.resourceName === "Reminder") || {};
    let action = find(reminderResource.actions, action => action.actionId === this.props.entry.actionId) || {};
    let dueOn = moment.utc(this.props.item.dueDate[0]).local();

    let reminderContent;
    switch (action.actionName) {
      case "Unset":
        reminderContent = (
          <React.Fragment>
            <span>
              {/* <span className={Icons.bell + " reminder-thread-item-icon pl-2"} /> */}
              <span className="ml-2">{Resources.ReminderCanceled}</span>
            </span>
            <span className="float-right" style={{ textAlign: "right" }}>
              <div className="thread-item-age">{moment(this.props.entry.dateCreated).fromNow()}</div>
            </span>
          </React.Fragment>
        );
        break;
      case "Remind":
        reminderContent = (
          <React.Fragment>
            <span>
              {/* <span className={Icons.bell + " reminder-thread-item-icon pl-2"} /> */}
              <span className="ml-2">
                {Resources.ReminderNameForDateReminded(this.props.item.reminderName, dueOn.format("lll"))}
              </span>
            </span>
            <span className="float-right" style={{ textAlign: "right" }}>
              <div className="thread-item-age">{moment(this.props.entry.dateCreated).fromNow()}</div>
            </span>
          </React.Fragment>
        );
        break;
      default:
        reminderContent = (
          <React.Fragment>
            {/*<span>
               <span className={Icons.bell + " reminder-thread-item-icon pl-2"} /> 
              <span className="ml-2">
                {Resources.ReminderNameSetForDate(this.props.item.reminderName, dueOn.format("lll"))}
              </span>
               {this.props.showCancel ? (
                <span
                  className="reminder-item-cancel"
                  onClick={() => this.unsetReminder(this.props.companyId, this.props.entry.ledgerHash)}
                >
                  {Resources.Cancel}
                </span>
              ) : null}
            </span>
            <span className="float-right" style={{ textAlign: "right" }}>
              <div className="thread-item-age">{moment(this.props.entry.dateCreated).fromNow()}</div>
            </span> */}
            <div className="conversation-thread-item__body">
              <div className="conversation-thread-item__body__header">
                <span className="conversation-thread-item__body__badge conversation-thread-item__body__badge--snoozed">
                  {/* {Resources.ReminderNameSetForDate(this.props.item.reminderName, dueOn.format("lll"))} */}
                  {Resources.ActivitySnoozed(dueOn.format("lll"))}
                </span>
                <span className="thread-item-age">
                  <div className="thread-item-age">{moment(this.props.entry.dateCreated).fromNow()}</div>
                </span>
              </div>
            </div>
          </React.Fragment>
        );
    }

    return <div className="conversation-thread-item"> {reminderContent}</div>;
  }
}

const storeToProps = store => {
  return {
    workflows: store.manage.workflows,
    ledger: store.ledger
  };
};

export default withRouter(connect(storeToProps, dispatchToProps)(ReminderThreadItem));
