import React from 'react';

import Dropdown from './dropdown';
import IconAngleDown from '../library/icons/iconAngleDown';

const DropdownSearchSort = (props) => {
  const { longestCustomerName, selectedCompany, onSetVisible } = props;

  return (
    <Dropdown
      buttonContent={
          <React.Fragment>
            {
              selectedCompany.displayName
            }
            <IconAngleDown height={8} className="select-input-arrow-down" />
          </React.Fragment>
        }
      children = {props.children}
      buttonWidth= {longestCustomerName}
      menuWidth={longestCustomerName}
      matchButtonMenuWidth={false}
      menuClassName={'multi-customer-dropdown dropdown-max-height-scroll'}
      buttonClassName='button-select-input dropdown-button-border h36 table-padding-left font-weight-medium fs-15'
      aria-labelledby={`dropdown-${longestCustomerName}`}
      isForm={true}
      onSetVisible={onSetVisible}
    />
  )
}

export default DropdownSearchSort 