import React from "react";
import { useSelector, shallowEqual } from "react-redux";

function SummaryCountBadge({ contextGroupId, label }) {
  //TODO: Need to use a memoized selector factory for this so we dont have to deal with nested reducer state in here
  const contextGroupsMaps = useSelector(store => store.contextGroups.contextGroupsMaps);
  const contextGroupsStatsForSelectedPerspective = useSelector(
    store => store.contextGroups.contextGroupsStats[store.perspectives.selectedPerspectiveId] || {}
  );

  const badgeType = (contextGroupsStatsForSelectedPerspective.badgeTypes || {})[label] || "info";

  let contextGroupLabel;
  switch (label) {
    case "mine":
      contextGroupLabel = "assignedToMe";
      break;
    case "assigned":
      contextGroupLabel = "assignedToOthers";
      break;
    case "waiting-for-response":
      contextGroupLabel = "waitingForResponse";
      break;
    default:
      contextGroupLabel = label;
      break;
  }

  const badgeCount =
    label === "all"
      ? (contextGroupsStatsForSelectedPerspective.summaryCounts || {})[
          (contextGroupsMaps[contextGroupId] || {}).companyLocalKey
        ]
      : ((contextGroupsMaps[contextGroupId] || {}).activeCounts || {})[contextGroupLabel] || 0;

  if (!badgeCount) {
    return null;
  }

  return <div className={"summary-count-badge" + " summary-count-badge--" + badgeType}>{badgeCount}</div>;
}

export default SummaryCountBadge;
